const LABELS_RATIO = 68;
const NEAR_POINT_OFFSET = 5;

export const getBottomOffset = (
  points: any[],
  index: number,
  maxValue: number
) => {
  const point = points[index];

  const maxNearPoint = Math.max(
    points[index - 1] || point,
    points[index + 1] || point
  );

  const labelPoint =
    (point + maxNearPoint / NEAR_POINT_OFFSET) / (1 + 1 / NEAR_POINT_OFFSET);

  let bottomOffset = (LABELS_RATIO * labelPoint) / maxValue;

  return `calc(${bottomOffset}% + 2.5rem)`;
};
