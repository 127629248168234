import { getIsSecret } from "SRC/pages/Summary/common/SummaryComplexGrid/utils";
import { ICustomTableProps } from "SRC/types";

export const getEventsRisk = (tableProps: ICustomTableProps, isOM: boolean) => {
  const { eventsRisk, eventsOMRisk } = tableProps.row.original;

  return {
    eventsRisk: isOM ? eventsOMRisk : eventsRisk,
    isSecret: getIsSecret(tableProps),
  };
};
