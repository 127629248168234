import React, { FC, useState } from "react";
import { ExtendedTabs, ITab } from "SRC/components/ExtendedTabs/ExtendedTabs";

import { ValueTab } from "./ValueTab/ValueTab";

export const Content: FC = () => {
  const [activeTab, setActiveTab] = useState<number>(0);

  const tabs: ITab<number>[] = [
    {
      value: 0,
      label: "Значение показателя",
      component: <ValueTab />,
    },
    {
      value: 1,
      label: "Реализация в субъектах РФ",
      component: null,
      wip: true,
    },
    {
      value: 2,
      label: "Связанные мероприятия",
      component: null,
      wip: true,
    },
    {
      value: 3,
      label: "Методика расчёта",
      component: null,
      wip: true,
    },
  ];

  const onChangeTab = (value: number): void => setActiveTab(value);

  return (
    <ExtendedTabs
      value={activeTab}
      onChange={onChangeTab}
      tabs={tabs}
      stretch
      borderStyle="solid"
      style={{
        fontSize: "1.2rem",
        fontWeight: "bold",
      }}
    />
  );
};
