import { styled } from "@mui/material";
import Tooltip, { tooltipClasses, TooltipProps } from "@mui/material/Tooltip";
import React from "react";
import theme from "SRC/theme";

const MIN_WIDTH = "20rem";

export const TooltipWrapper = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  )
)({
  zIndex: 1,
  [`& .${tooltipClasses.tooltip}`]: {
    minWidth: MIN_WIDTH,

    "& .MuiTooltip-arrow": {
      color: theme.palette.text.secondary,
    },
  },
});

export const ItemList = styled("div")(({ theme }) => {
  const {
    text: { onAccent, primary },
  } = theme.palette;

  return {
    width: "100%",
    minWidth: MIN_WIDTH,
    display: "flex",
    flexDirection: "column",
    gap: "1rem",

    "& .item": {
      display: "flex",
      flexDirection: "row",
      gap: "1rem",

      "& .label": {
        flex: "1 0 25%",
        fontWeight: 300,
        color: primary,
      },

      "& .value": {
        flex: "2 1 65%",
        color: onAccent,
      },
    },
  };
});
