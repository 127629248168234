import { Box, Typography } from "@mui/material";
import React, { Fragment } from "react";
import { WIPTag } from "SRC/components/WIP";

import { PrioritiesComplexGrid } from "../../common/PrioritiesComplexGrid/PrioritiesComplexGrid";

interface ICenterLayoutProps {
  isLargeSize: boolean;
  setSortBy: (sortBy: any) => void;
}
export const LeftLayout = ({ isLargeSize, setSortBy }: ICenterLayoutProps) => {
  return (
    <Fragment>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h1" component={"h1"}>
          Ключевые приоритеты
          <WIPTag
            sx={{
              verticalAlign: "middle",
              display: "inline-block",
              marginLeft: "1rem",
            }}
          />
        </Typography>
      </Box>
      <PrioritiesComplexGrid setSortBy={setSortBy} />
    </Fragment>
  );
};
