import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { isContainedArray } from "SRC/helpers/dataTesters";
import { getProgramIndicators } from "SRC/redux/slices/gosprogram/api/client";
import { ICodeItem } from "SRC/types";

// TODO [переделать]: интерфейс моковый, т.к. апи ещё не было на момент написания
export interface IProgramIndicator {
  NAME: string;
  R_ID: string;
  code: string;
  data: ICodeItem[];
  "Наименование проекта": string;
  "Ед. измерения результата": string;
}

export interface IIndicatorsState {
  items: IProgramIndicator[];
  fetching: boolean;
  fetched: boolean;
  error: string;
}

export interface IParams {
  id: string | number;
  fp_code: string | null;
  dp_code: string | null;
  cpm_code: string | null;
  gp_code?: string;
  period_code?: string;
}

export const extraActions = {
  get: createAsyncThunk(
    "indicators/get",
    async (params: IParams) => await getProgramIndicators(params)
  ),
};

const initialState: IIndicatorsState = {
  items: [],
  fetching: false,
  fetched: false,
  error: "",
};

const slice = createSlice({
  name: "indicators",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActions.get.pending, (state) => {
        state.items = [];
        state.fetching = true;
        state.fetched = false;
      })
      .addCase(
        extraActions.get.fulfilled,
        (state: IIndicatorsState, action) => {
          state.items = (
            isContainedArray(action.payload.data) ? action.payload.data : []
          ) as IProgramIndicator[];
          state.fetching = false;
          state.fetched = true;
        }
      )
      .addCase(extraActions.get.rejected, (state, action) => {
        state.fetching = false;
      });
  },
});

export const actions = {
  ...slice.actions,
  ...extraActions,
};

export default slice;
