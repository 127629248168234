import { Box } from "@mui/material";
import React, { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { IncidentReport } from "SRC/components/IncidentReport";
import { OverlayBlock } from "SRC/components/OverlayBlock";
import { ProgramTile } from "SRC/components/ProgramTile/ProgramTile";
import {
  IProgram,
  useFilteredPrograms,
  useGetPrograms,
} from "SRC/redux/stores/ProgramsBoard";
import { PROGRAM_VIEW, setParamsToUrl } from "SRC/urls";

import { css, Wrapper } from "./Tiles.styled";

export default function Tiles() {
  const navigate = useNavigate();
  const { data, fetching } = useGetPrograms();
  const filteredPrograms = useFilteredPrograms();
  const handleProgramClick = useCallback(
    ({ info }: IProgram) => {
      navigate(setParamsToUrl(PROGRAM_VIEW, { programId: info.code }));
    },
    [navigate]
  );

  const programs = useMemo(
    (): IProgram[] =>
      data.filter(({ info }: IProgram) => filteredPrograms.includes(info.code)),
    [data, filteredPrograms]
  );

  return (
    <Wrapper>
      <OverlayBlock isFetching={fetching} hasData={Boolean(data)}>
        <Box sx={css.container}>
          {programs.map((program) => (
            <IncidentReport className="incident-container">
              <ProgramTile
                key={program.info.code}
                className="program-tile"
                program={program}
                onClick={handleProgramClick}
              />
            </IncidentReport>
          ))}
        </Box>
      </OverlayBlock>
    </Wrapper>
  );
}
