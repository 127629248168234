import { SxProps } from "@mui/material";
import React, { useMemo } from "react";
import SelectWithSearch from "SRC/components/SelectWithSearch/SelectWithSearch";

type TOptionValue = string | number;

export interface IOption {
  value: TOptionValue;
  label: string;
  disabled?: boolean;
}

interface IStyle {
  labelStyle?: SxProps;
  selectStyle?: SxProps;
  optionStyle?: SxProps;
}

interface ISelect {
  value?: string;
  onChange(value: TOptionValue): void;
  options: IOption[];
  label?: string;
  staticLabel?: boolean;
  colorizeActiveOption?: boolean;
  style: IStyle;
}

const SelectWithSearchIncidentType = ({
  value,
  onChange,
  options,
  staticLabel = true,
  label,
  colorizeActiveOption = true,
  style,
}: ISelect) => {
  const customOptions = useMemo(
    () => [
      {
        value: "",
        label: label || "Все",
      },
      ...options,
    ],
    [label, options]
  );

  const getOptionLabel = (option: IOption) => {
    return option.label;
  };

  return (
    <SelectWithSearch
      value={value}
      onChange={onChange}
      options={customOptions}
      getOptionLabel={getOptionLabel}
      staticLabel={staticLabel}
      label={label}
      colorizeActiveOption={colorizeActiveOption}
      style={style}
      hasCloseIcon={Boolean(value)}
    />
  );
};

export default SelectWithSearchIncidentType;
