import { Grid } from "@mui/material";
import classnames from "classnames";
import React, { useCallback, useMemo } from "react";
import { CheckSecretData } from "SRC/components/CheckSecretData";
import { OverlayStackedProgress } from "SRC/components/ProgressBar";
import { IOPValue } from "SRC/components/ProgressBar";
import { StatusLabel } from "SRC/components/StatusLabel/StatusLabel";
import { convertSourceValue, ESources } from "SRC/constants/globals";
import {
  IGlobalFilters,
  useGlobalFilters,
} from "SRC/redux/slices/global/hooks/useFilters";
import { useFilters } from "SRC/redux/slices/main/hooks/useFilters";
import { useStats } from "SRC/redux/slices/main/hooks/useStats";
import { IStructureElements, TSourcedValue } from "SRC/types/analiticsNew";

import { StructureElemetsWrapper } from "./StructureElements.styled";

export const StructureElements = () => {
  const { items: stats } = useStats();
  const data = useMemo(() => stats[0]?.data || {}, [stats]);
  const { isSphereSecret } = useFilters();

  const { sources }: IGlobalFilters = useGlobalFilters();

  const structureData = useMemo<IStructureElements>(
    () => ({
      departmentProjects: {
        notDone: data["Мероприятий не выполнено ВП 2, %"] || 0,
        notDoneValue: {
          [ESources.OM]: data.dp_res_uncomplete_om_cnt,
          [ESources.FOIV]: data["Мероприятий не выполнено ВП"] || 0,
        },
        fact: {
          [ESources.OM]: data.dp_res_complete_om_cnt,
          [ESources.FOIV]: data["Выполнение мероприятий ВП, факт"] || 0,
        },
        plan: {
          [ESources.OM]: data.dp_res_om_cnt,
          [ESources.FOIV]: data["Выполнение мероприятий ВП, план"] || 0,
        },
        done: data["Выполнение мероприятий ВП, %"] || 0,
        total: data["Ведомственных проектов, кол-во"] || 0,
      },
      federalProjects: {
        notDone: data["Мероприятий не выполнено ФП 2, %"] || 0,
        notDoneValue: {
          [ESources.OM]: data.fp_res_uncomplete_om_cnt,
          [ESources.FOIV]: data["Мероприятий не выполнено ФП"] || 0,
        },
        fact: {
          [ESources.OM]: data.fp_res_complete_om_cnt,
          [ESources.FOIV]: data["Выполнение мероприятий ФП, факт"] || 0,
        },
        plan: {
          [ESources.OM]: data.fp_res_om_cnt,
          [ESources.FOIV]: data["Выполнение мероприятий ФП, план"] || 0,
        },
        done: data["Количество выполненных мероприятий ФП 2, %"] || 0,
        total: data["Федеральных проектов, кол-во"] || 0,
      },
      complexesProcessMeasures: {
        notDone: data["Мероприятий не выполнено КПМ 2, %"] || 0,
        notDoneValue: {
          [ESources.OM]: data.cpm_res_uncomplete_om_cnt,
          [ESources.FOIV]: data["Мероприятий не выполнено КПМ"] || 0,
        },
        fact: {
          [ESources.OM]: data.cpm_res_complete_om_cnt,
          [ESources.FOIV]: data["Выполнение мероприятий КПМ, факт"] || 0,
        },
        plan: {
          [ESources.OM]: data.cpm_res_om_cnt,
          [ESources.FOIV]: data["Выполнение мероприятий КПМ, план"] || 0,
        },
        done: data["Выполнение мероприятий КПМ, %"] || 0,
        total: data["Комплексы процессных мероприятий, кол-во"] || 0,
      },
      events: {
        notDone: data["Мероприятий не выполнено, %"] || 0,
        notDoneValue: {
          [ESources.OM]: data.results_overall_om_uncomplete_cnt,
          [ESources.FOIV]: data["Мероприятий не выполнено"] || 0,
        },
        fact: {
          [ESources.OM]: data.results_overall_om_complete_cnt,
          [ESources.FOIV]: data["Мероприятий выполнено"] || 0,
        },
        done: data["Мероприятий выполнено, %"] || 0,
        total: {
          [ESources.OM]: data.results_overall_om_cnt,
          [ESources.FOIV]: data["Мероприятий всего"] || 0,
        },
      },
      totalProjects: {
        plan:
          data["Выполнение мероприятий ВП, план"] +
          data["Выполнение мероприятий ФП, план"] +
          data["Выполнение мероприятий КПМ, план"],
        fact:
          data["Выполнение мероприятий ВП, факт"] +
          data["Выполнение мероприятий ФП, факт"] +
          data["Выполнение мероприятий КПМ, факт"],
        total: data["Структурные элементы, всего"] || 0,
      },
    }),
    [data]
  );
  const eventsChart: Record<string, IOPValue[]> = useMemo(
    () => ({
      success: convertSourceValue(structureData.events.fact, sources),
      fail: convertSourceValue(structureData.events.notDoneValue, sources),
    }),
    [sources, structureData]
  );

  const fpChart: Record<string, IOPValue[]> = useMemo(
    () => ({
      success: convertSourceValue(structureData.federalProjects.fact, sources),
      fail: convertSourceValue(
        structureData.federalProjects.notDoneValue,
        sources
      ),
    }),
    [sources, structureData]
  );

  const dpChart: Record<string, IOPValue[]> = useMemo(
    () => ({
      success: convertSourceValue(
        structureData.departmentProjects.fact,
        sources
      ),
      fail: convertSourceValue(
        structureData.departmentProjects.notDoneValue,
        sources
      ),
    }),
    [sources, structureData]
  );

  const cpmChart: Record<string, IOPValue[]> = useMemo(
    () => ({
      success: convertSourceValue(
        structureData.complexesProcessMeasures.fact,
        sources
      ),
      fail: convertSourceValue(
        structureData.complexesProcessMeasures.notDoneValue,
        sources
      ),
    }),
    [sources, structureData]
  );

  const getValueBySource = useCallback(
    (value: TSourcedValue): number => {
      return convertSourceValue(value, sources)[0]?.value || 0;
    },
    [sources]
  );

  return (
    <StructureElemetsWrapper
      className={classnames({
        "is-secret": isSphereSecret,
      })}
    >
      <CheckSecretData isSecret={isSphereSecret}>
        <Grid container>
          <Grid item xs={3}>
            <div className="text-container">
              <p className="gray-blue-text">Всего</p>
              <p className="white-text big-text">
                {structureData?.totalProjects.total}
              </p>
            </div>
          </Grid>
          <Grid item xs={9}>
            <div className="progressbar-head">
              <p>Мероприятия</p>
              <div className="legend">
                <StatusLabel
                  label="done"
                  name="Выполнено"
                  sources={sources.OM}
                  className="status-name"
                />
                <StatusLabel
                  label="fail"
                  name="Не выполнено"
                  sources={sources.OM}
                  className="status-name"
                />
              </div>
            </div>
            <OverlayStackedProgress
              successValues={eventsChart.success}
              failValues={eventsChart.fail}
              total={getValueBySource(structureData.events.total)}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={3}>
            <div className="text-container">
              <p className="gray-blue-text">ФП</p>
              <p className="white-text">
                {structureData?.federalProjects.total}
              </p>
            </div>
          </Grid>
          <Grid item xs={9}>
            <OverlayStackedProgress
              successValues={fpChart.success}
              failValues={fpChart.fail}
              total={getValueBySource(structureData.federalProjects.plan)}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={3}>
            <div className="text-container">
              <p className="gray-blue-text">ВП</p>
              <p className="white-text">
                {structureData?.departmentProjects.total}
              </p>
            </div>
          </Grid>
          <Grid item xs={9}>
            <OverlayStackedProgress
              successValues={dpChart.success}
              failValues={dpChart.fail}
              total={getValueBySource(structureData.departmentProjects.plan)}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={3}>
            <div className="text-container">
              <p className="gray-blue-text">КПМ</p>
              <p className="white-text">
                {structureData?.complexesProcessMeasures.total}
              </p>
            </div>
          </Grid>
          <Grid item xs={9}>
            <OverlayStackedProgress
              successValues={cpmChart.success}
              failValues={cpmChart.fail}
              total={getValueBySource(
                structureData.complexesProcessMeasures.plan
              )}
            />
          </Grid>
        </Grid>
      </CheckSecretData>
    </StructureElemetsWrapper>
  );
};
