import React from "react";
import { TableProps, UseExpandedRowProps, UseTableRowProps } from "react-table";
import NavigateToProgram from "SRC/components/NavigateToProgram/NavigateToProgram";
import { PremierInfo } from "SRC/components/PremierInfo";
import { TableCell } from "SRC/components/TableCell";
import { COLUMNS } from "SRC/components/TableColumnTemplates/columns";
import { IProgramTableItem } from "SRC/hooks/summaryTable/interfaces";

type CustomRowProps = UseTableRowProps<any> & UseExpandedRowProps<any>;

interface CustomTableProps extends TableProps {
  row: CustomRowProps;
  rows?: UseTableRowProps<IProgramTableItem>[];
}

const getGeneralInfoColumns = (isOM: boolean) => {
  return [
    { ...COLUMNS.program.status() },
    {
      Header: "",
      sticky: "left",
      accessor: "id",
      width: 90,
      columns: [
        {
          Header: "Код ГП",
          width: 90,
          sticky: "left",
          accessor: "id",
          Cell: (tableProps: CustomTableProps) => (
            <TableCell color="gray" align="center">
              {tableProps.row.original.id}
            </TableCell>
          ),
          Footer: "",
        },
      ],
      Footer: "",
    },
    {
      Header: "",
      sticky: "left",
      accessor: "govProgram",
      columns: [
        {
          Header: "Госпрограмма",
          width: 250,
          sticky: "left",
          accessor: "govProgram",
          Cell: (tableProps: CustomTableProps) => (
            <NavigateToProgram program={tableProps.row.original} />
          ),
          Footer: <TableCell>Итого</TableCell>,
        },
      ],
      Footer: "",
    },
    {
      Header: "",
      sticky: "left",
      accessor: "vicePremier",
      columns: [
        {
          Header: "Вице-премьер",
          width: 200,
          sticky: "left",
          accessor: "vicePremier",
          Cell: (tableProps: CustomTableProps) => {
            return (
              <PremierInfo
                short={true}
                premierCode={tableProps.row.original.vp}
              />
            );
          },
          Footer: "",
        },
      ],
      Footer: "",
    },
    {
      Header: "",
      sticky: "left",
      accessor: "ministry",
      columns: [
        {
          Header: "Министерства",
          width: 180,
          sticky: "left",
          accessor: "ministry",
          Cell: (tableProps: CustomTableProps) => (
            <TableCell color="gray">
              {tableProps.row.original.ministry}
            </TableCell>
          ),
          Footer: "",
        },
      ],
      Footer: "",
    },
    {
      Header: "",
      width: 180,
      accessor: isOM ? "performanceOM" : "performance",
      columns: [
        {
          ...COLUMNS.performance.fact(isOM),
        },
      ],
      Footer: "",
    },
    {
      Header: "Кассовое исполнение",
      accessor: "cash",
      columns: [{ ...COLUMNS.cash.plan() }, { ...COLUMNS.cash.fact() }],
      Footer: "",
    },
    {
      Header: "Показатели ГП",
      accessor: "indicators",
      columns: [
        { ...COLUMNS.indicators.plan(isOM) },
        { ...COLUMNS.indicators.fact(isOM) },
        { ...COLUMNS.indicators.fail(isOM) },
      ],
      Footer: "",
    },
    {
      Header: "Структурные элементы",
      accessor: "structure",
      columns: [
        { ...COLUMNS.structureElements.plan(isOM) },
        { ...COLUMNS.structureElements.fact(isOM) },
        { ...COLUMNS.structureElements.fail(isOM) },
      ],
      Footer: "",
    },
    {
      Header: "Показатели СЭ",
      accessor: "indicatorsSE",
      columns: [
        { ...COLUMNS.indicatorsSE.plan(isOM) },
        { ...COLUMNS.indicatorsSE.fact(isOM) },
        { ...COLUMNS.indicatorsSE.fail(isOM) },
      ],
      Footer: "",
    },
    {
      Header: "Мероприятия",
      accessor: "events",
      columns: [
        { ...COLUMNS.events.plan(isOM) },
        { ...COLUMNS.events.fact(isOM) },
        { ...COLUMNS.events.fail(isOM) },
      ],
      Footer: "",
    },
    {
      Header: "Контрольные точки",
      accessor: "checkpoints",
      columns: [
        { ...COLUMNS.checkpoints.plan(isOM) },
        { ...COLUMNS.checkpoints.fact(isOM) },
        { ...COLUMNS.checkpoints.fail(isOM) },
      ],
      Footer: "",
    },
  ];
};

export { getGeneralInfoColumns };
