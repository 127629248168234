import { styled, Theme } from "@mui/material";
import moment from "moment";

type CalendarWrapperProps = {
  isYearView: boolean;
  isMonthView: boolean;
  minYear: number;
  minMonthIndex: number;
};
export const CalendarWrapper = styled("div")<CalendarWrapperProps>(
  ({ theme, isYearView, isMonthView, minYear, minMonthIndex }) => {
    const {
      background: { paper },
      complementary: { lightShadow, darkGrayBlue },
      primary: { contrastText, main },
      charts: { blueAreaOpacity },
      text: { secondary },
    } = theme.palette;

    const [currentYear, currentQuarter] = moment().format("YYYY Q").split(" ");

    const minQuarterIndex = Math.ceil(minMonthIndex / 3);
    const quarterMonthIndex = ((minMonthIndex - 1) % 3) + 1;

    const disabledItemStyles = {
      pointerEvents: "none",
      color: secondary,
      cursor: "default",
    };

    const enabledItemStyles = {
      pointerEvents: "auto",
      color: contrastText,
      cursor: "pointer",
    };

    return {
      padding: "1rem 1.5rem 1rem",
      background: paper,
      borderRadius: "8px",
      boxShadow: `0px 2px 10px 2px ${lightShadow}`,
      border: `1px solid ${darkGrayBlue}`,
      color: contrastText,
      width: "26rem",
      "& .react-datepicker": {
        backgroundColor: paper,
        color: contrastText,
        border: "none",
        width: "100%",
      },
      "& .react-datepicker__header": {
        display: isYearView ? "none" : "flex",
        backgroundColor: "transparent",
        borderBottom: "none",
      },
      "& .react-datepicker__year-wrapper, .react-datepicker__month-wrapper": {
        maxWidth: "unset",
        justifyContent: "space-evenly",
        gap: "1rem",
      },
      "& .react-datepicker__month-wrapper": {
        display: "flex",
        textTransform: "capitalize",
      },
      "& .react-datepicker__quarter-wrapper": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      },
      "& .react-datepicker__year-text, .react-datepicker__month-text, .react-datepicker__quarter-text":
        {
          width: isMonthView ? "5.4rem" : "3.4rem",
          height: "3.4rem",
          display: "flex !important",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "1rem",

          "&:hover": {
            borderRadius: "4px",
            background: blueAreaOpacity,
          },
        },
      "& .react-datepicker__quarter-text": {
        width: "6rem !important",
      },
      [`
      & .react-datepicker__day--keyboard-selected,
      & .react-datepicker__month-text--keyboard-selected, 
      & .react-datepicker__quarter-text--keyboard-selected,
      & .react-datepicker__year-text--keyboard-selected,
      & .react-datepicker__year-text--selected,
      & .react-datepicker__month--selected,
      & .react-datepicker__quarter--selected
      `]: {
        borderRadius: "4px",
        color: contrastText,
        background: main,

        "&:hover": {
          color: contrastText,
          background: main,
        },
      },

      "& .react-datepicker__month-container": {
        float: "unset",
      },

      "& .react-datepicker__month-text--today": {
        fontWeight: "400",
      },

      "& .react-datepicker__year-text--today": {
        fontWeight: "400",
      },

      [`
        & .react-datepicker__month-text--disabled,
        & .react-datepicker__quarter-text--disabled,
        & .react-datepicker__year-text--disabled
      `]: {
        ...disabledItemStyles,
      },

      "& .react-datepicker__quarterPicker": {
        [`&[aria-label*=${currentYear}]`]: {
          "& .react-datepicker__quarter-text--disabled": {
            [`&:not(.react-datepicker__quarter-${currentQuarter})`]: {
              ...disabledItemStyles,
            },
          },
        },
      },

      [`& [aria-label*="${minYear}"]`]: {
        "& .react-datepicker__quarter-text": {
          [`&:not(:nth-of-type(n+${minQuarterIndex}))`]: {
            ...disabledItemStyles,
          },

          [`&:nth-of-type(n+${minQuarterIndex})`]: {
            ...enabledItemStyles,
          },
        },

        "& .react-datepicker__month-wrapper": {
          [`&:not(:nth-of-type(n+${minQuarterIndex}))`]: {
            ...disabledItemStyles,
          },

          [`&:nth-of-type(n+${minQuarterIndex})`]: {
            ...enabledItemStyles,
            cursor: "default",
          },

          [`&:nth-of-type(${minQuarterIndex})`]: {
            [`& .react-datepicker__month-text:not(:nth-of-type(n+${quarterMonthIndex}))`]:
              {
                ...disabledItemStyles,
              },

            [`& .react-datepicker__month-text:nth-of-type(n+${quarterMonthIndex})`]:
              {
                ...enabledItemStyles,
              },
          },
        },
      },
    };
  }
);

export const css = {
  calendarContainer: {
    position: "relative",
  },
  buttonsContainer: {
    width: "100%",
    display: "flex",
    background: (theme: Theme) => theme.palette.complementary.darkGrayBlue,
    border: (theme: Theme) => `1px solid ${theme.palette.text.secondary}`,
    borderRadius: "8px",
    padding: "4px",
    justifyContent: "space-between",
  },
  button: (isActive: boolean) => ({
    flex: "0 0 32%",
    color: (theme: Theme) =>
      isActive ? theme.palette.primary.contrastText : theme.palette.main.gray,
    fontSize: "1rem",
    fontWeight: isActive ? "bold" : "normal",
    background: (theme: Theme) =>
      isActive ? theme.palette.primary.main : "transparent",
    borderRadius: "2px",
    textAlign: "center",
    lineHeight: "0.9rem",
    padding: "5px",
    cursor: "pointer",
  }),
  datePickerContainer: {
    "& .react-datepicker-wrapper": {
      display: "flex",
      width: "100%",
    },
  },
  datePickerHeaderContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "baseline",
    width: "100%",
  },
  headerBtn: {
    cursor: "pointer",
    width: "5rem",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  headerText: {
    fontSize: "1.25rem",
    lineHeight: "2rem",
    fontWeight: "bold",
    color: (theme: Theme) => theme.palette.primary.contrastText,
  },
  textField: (theme: Theme) => ({
    backgroundColor: theme.palette.background.headerControl,
    borderRadius: "0.5rem",
    overflow: "hidden",

    "& .MuiFilledInput-root": {
      cursor: "pointer",

      "& svg": {
        fill: theme.palette.text.onAccent,
        opacity: 0.5,
        width: "1.25rem",
        height: "1.125rem",
      },

      "& .MuiCircularProgress-root": {
        width: "1.25rem !important",
        aspectRatio: "1",

        "& svg": {
          width: "100%",
          height: "100%",
        },
      },

      "&::before": {
        display: "none",
      },

      "&::after": {
        display: "none",
      },
    },

    "& .MuiInputBase-input": {
      padding: "0.625rem",
      fontSize: "1rem",
      color: theme.palette.text.onAccent,
      cursor: "pointer",

      "&::selection": {
        background: "transparent",
      },
    },

    "& .Mui-focused": {
      borderColor: theme.palette.primary.contrastText,
      borderRadius: "8px",
    },
  }),
  arrowContainer: {
    width: "1rem",
    height: "2.5rem",
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    cursor: "pointer",
  },
  arrow: {
    cursor: "pointer",
  },
};
