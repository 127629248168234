import { Box } from "@mui/material";
import React, { useMemo } from "react";
import { CheckSecretData } from "SRC/components/CheckSecretData";
import { Percent } from "SRC/components/Percent/Percent";
import { PerformanceValue } from "SRC/components/PerformanceValue/PerformanceValue";
import { SlimProgressBar } from "SRC/components/ProgressBar";
import { TableCell } from "SRC/components/TableCell";
import { sortPerformanceColumn } from "SRC/helpers";
import { getPercentValue } from "SRC/helpers/getPercentValue";
import theme from "SRC/theme";
import { ICustomTableProps } from "SRC/types";

import { css } from "../../TableColumnTemplates.styled";

export const performanceMain = (isOM: boolean) => ({
  Header: "Уровень достижения",
  accessor: isOM ? "performanceOM" : "performance",
  sortType: sortPerformanceColumn(isOM),
  width: 240,
  Cell: (tableProps: ICustomTableProps) => {
    const { performance, subRows } = useMemo(() => {
      const { performance, performanceOM, subRows } = tableProps.row.original;

      return {
        performance: (isOM ? performanceOM : performance) || 0,
        subRows: subRows ?? [],
      };
    }, [tableProps.row.original]);

    let isSecret = tableProps.row.original.secretProgram;
    if (subRows.length) {
      isSecret = subRows.every(({ secretProgram }: any) => secretProgram);
    }

    return (
      <Box sx={css.centered} style={{ gap: 0 }}>
        <CheckSecretData isSecret={isSecret}>
          <TableCell align="center">
            <PerformanceValue
              value={getPercentValue(performance)}
              sameSize={true}
            />
            <Percent />
          </TableCell>
          <SlimProgressBar
            value={performance}
            progressColor={isOM ? theme.palette.text.opinion : "white"}
            isOM={isOM}
          />
        </CheckSecretData>
      </Box>
    );
  },
  Footer: (tableProps: ICustomTableProps) => {
    const total = useMemo(() => {
      const { performanceTotal, performanceOMTotal } =
        tableProps?.rows?.[0]?.original || {};
      return (isOM ? performanceOMTotal : performanceTotal) || 0;
    }, [tableProps?.rows]);

    return (
      <Box sx={css.centered}>
        <TableCell color={isOM ? "blue" : "white"} align="center">
          {getPercentValue(total)}
          <Percent />
        </TableCell>
      </Box>
    );
  },
});
