import { Tooltip } from "@mui/material";
import React from "react";
import { CheckSecretData } from "SRC/components/CheckSecretData";
import { TableCell } from "SRC/components/TableCell";
import { sortStructureElementsPlanColumn } from "SRC/helpers";
import { ICustomTableProps } from "SRC/types";

import {
  getStructureElementsPlan,
  getStructureElementsPlanTotal,
} from "./utils";

export const structureElementsPlan = (isOM: boolean) => ({
  Header: "План",
  sortType: sortStructureElementsPlanColumn(isOM),
  width: 130,
  accessor: isOM ? "structureElementsOMPlan" : "structureElementsPlan",
  Cell: (tableProps: ICustomTableProps) => {
    const { structureElementsPlan, isSecret } = getStructureElementsPlan(
      tableProps,
      isOM
    );

    return (
      <TableCell color={isOM ? "blue" : "white"}>
        <CheckSecretData isSecret={isSecret}>
          {structureElementsPlan}
        </CheckSecretData>
      </TableCell>
    );
  },
  Footer: (tableProps: ICustomTableProps) => {
    const { structureElementsPlanTotal } = getStructureElementsPlanTotal(
      tableProps,
      isOM
    );

    return (
      <Tooltip title="В расчете участвуют уникальные элементы">
        <TableCell color={isOM ? "blue" : "white"}>
          {structureElementsPlanTotal}
        </TableCell>
      </Tooltip>
    );
  },
});
