import {
  ETypeCashData,
  ICashItem,
} from "SRC/pages/Event/common/EventTabs/CashTab/CashTab";

export const getMonthCashData = (
  sortedCash: ICashItem[],
  typeData: string
): number[] => {
  let cashData = new Array(12).fill(0);
  sortedCash.map((item, monthIndex) => {
    if (item.month) {
      cashData[parseInt(item.month) - 101] =
        typeData === ETypeCashData.PLAN ? item.plan : item.fact;
    }
  });
  return cashData;
};
