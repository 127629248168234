import { Typography } from "@mui/material";
import React from "react";
import { TableProps, UseTableRowProps } from "react-table";
import {
  css as tableCss,
  TableCellBigText,
  TableFooterText,
} from "SRC/components/Table/BaseTable/BaseTable.styled";
import { css } from "SRC/pages/Summary/common/SummaryComplexGrid/SummaryComplexGrid.styled";

interface CustomTableProps extends TableProps {
  row: UseTableRowProps<any>;
  rows?: UseTableRowProps<any>;
}

const getGoalsColumns = () => {
  return [
    {
      Header: "План",
      width: css.goalsColumn.plan.width,
      accessor: "goalsPlan",
      Cell: (tableProps: CustomTableProps) => (
        <TableCellBigText align="center">
          {tableProps.row.original.goalsPlan}
        </TableCellBigText>
      ),
      Footer: (tableProps: CustomTableProps) => {
        const total = React.useMemo(() => {
          const rowsArray = Array(tableProps.rows || []).flat();
          return rowsArray.reduce(
            (sum: number, row: UseTableRowProps<any>) =>
              row.original.goalsPlan + sum,
            0
          );
        }, [tableProps.rows]);

        return (
          <TableFooterText sx={css.goalsColumn.plan.footerWrapper}>
            {total}
          </TableFooterText>
        );
      },
    },
    {
      Header: "Выполнено",
      width: css.goalsColumn.done.width,
      accessor: "goalsDone",
      Cell: (tableProps: CustomTableProps) => (
        <TableCellBigText align="center" sx={css.goalsColumn.done.cellWrapper}>
          {tableProps.row.original.goalsDone}
        </TableCellBigText>
      ),
      Footer: (tableProps: CustomTableProps) => {
        const total = React.useMemo(() => {
          const rowsArray = Array(tableProps.rows || []).flat();
          return rowsArray.reduce(
            (sum: number, row: UseTableRowProps<any>) =>
              row.original.goalsDone + sum,
            0
          );
        }, [tableProps.rows]);

        return (
          <TableFooterText
            align="center"
            sx={css.goalsColumn.done.footerWrapper}
          >
            {total}
          </TableFooterText>
        );
      },
    },
    {
      Header: "Не выполнено",
      width: css.goalsColumn.fail.width,
      accessor: "goalsFail",
      Cell: (tableProps: CustomTableProps) => (
        <Typography
          align="center"
          sx={tableCss.tableCellText()}
          className="bigText with-right-border redText"
        >
          {tableProps.row.original.goalsFail}
        </Typography>
      ),
      Footer: (tableProps: CustomTableProps) => {
        const total = React.useMemo(() => {
          const rowsArray = Array(tableProps.rows || []).flat();
          return rowsArray.reduce(
            (sum: number, row: UseTableRowProps<any>) =>
              row.original.goalsFail + sum,
            0
          );
        }, [tableProps.rows]);

        return (
          <Typography
            align="center"
            sx={tableCss.tableCellText()}
            className="with-right-border redText"
          >
            {total}
          </Typography>
        );
      },
    },
  ];
};

export { getGoalsColumns };
