import { CloseOutlined, DehazeOutlined } from "@mui/icons-material";
import { Box, Button, Drawer } from "@mui/material";
import React, { useState } from "react";
import {
  css,
  ExtLink,
  SidemenuLink,
} from "SRC/components/SidebarMenu/SidebarMenu.styled";
import { WIPTag } from "SRC/components/WIP";
import { routeList } from "SRC/urls";

import { DownloadReportsModal } from "../DownloadReportsModal";

const SidebarMenu = () => {
  const [state, setState] = useState<boolean>(false);
  const toggleDrawer = (bool?: boolean) => () =>
    setState((state: boolean) => (typeof bool === "boolean" ? bool : !state));
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleClose = () => {
    setState(false);
  };
  const handleModalOpen = () => {
    setOpen(true);
  };

  const handleModalClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button onClick={toggleDrawer()} sx={css.button}>
        {!state ? <DehazeOutlined /> : <CloseOutlined />}
      </Button>
      <Drawer
        anchor="left"
        open={state}
        onClose={toggleDrawer(false)}
        style={{ zIndex: 4 }}
      >
        <Box component="ul" sx={css.sidemenu}>
          {routeList.map(
            (route) =>
              ((Array.from(route.path).filter((c: string) => c === "/")
                .length === 1 &&
                route.nav !== false) ||
                route.external) && (
                <Box component="li" key={route.path + route.title}>
                  {!route.external ? (
                    <SidemenuLink
                      to={route.path}
                      end
                      onClick={route.download ? handleModalOpen : handleClose}
                    >
                      {route.title}
                    </SidemenuLink>
                  ) : (
                    <ExtLink
                      href={route.path}
                      target="_blank"
                      onClick={handleClose}
                    >
                      {route.title}
                    </ExtLink>
                  )}
                  {route.wip && <WIPTag />}
                  {route.title === "Другие отчеты" && (
                    <DownloadReportsModal
                      isOpen={isOpen}
                      handleClose={handleModalClose}
                    />
                  )}
                </Box>
              )
          )}
        </Box>
      </Drawer>
    </>
  );
};

export default SidebarMenu;
