import { Box, SxProps } from "@mui/material";
import React from "react";
import { TableProps, UseTableRowProps } from "react-table";
import { PerformanceValue } from "SRC/components/PerformanceValue/PerformanceValue";
import { css } from "SRC/components/Table/Table.styled";
import { TableCell } from "SRC/components/TableCell";
import { TooltipText } from "SRC/components/TooltipText";
import { UnderProgram } from "SRC/components/UnderProgram/UnderProgram";
import { ETypeCalcPerformance, statusIcons } from "SRC/constants/globals";
import { sortPlan, sortPlanYear } from "SRC/helpers";
import { getPercentValue } from "SRC/helpers/getPercentValue";
import { sortInPerformanceColumn } from "SRC/helpers/sort-column/sort-in-performance-column";

interface CustomTableProps extends TableProps {
  row: UseTableRowProps<any>;
  rows?: UseTableRowProps<any>[];
}

export const getIndicatorsColumns = (isOM: boolean) => [
  {
    Header: " ",
    accessor: "status",
    width: css.statusIcon.width,
    Cell: (tableProps: CustomTableProps) => (
      <Box sx={css.statusIcon as SxProps}>
        {statusIcons[tableProps.row.original.status]}
      </Box>
    ),
  },
  {
    Header: "№",
    width: 100,
    accessor: " ",
    Cell: (tableProps: CustomTableProps) => (
      <Box sx={css.centered}>
        <TableCell align="center" color="gray">
          {tableProps.row.index}
        </TableCell>
      </Box>
    ),
  },
  {
    Header: "ИД показателя СЭ",
    accessor: "id",
    width: 140,
    Cell: (tableProps: CustomTableProps) => (
      <TableCell color="gray">{tableProps.row.original.id}</TableCell>
    ),
  },
  {
    Header: "Тип CЭ",
    accessor: "se_gp_type",
    width: 80,
    Cell: (tableProps: CustomTableProps) => (
      <TableCell color="gray">{tableProps.row.original.se_gp_type}</TableCell>
    ),
  },
  {
    Header: "Наименование показателя",
    accessor: "name",
    width: "auto",
    Cell: (tableProps: CustomTableProps) => {
      const { name } = tableProps.row.original;
      return <TableCell>{name}</TableCell>;
    },
  },
  {
    Header: "Ед.изм.",
    accessor: "unit",
    width: 100,
    Cell: (tableProps: CustomTableProps) => (
      <TableCell color="gray">{tableProps.row.original.unit}</TableCell>
    ),
  },
  {
    Header: "Период достижения",
    accessor: "implementation_period",
    width: 160,
    Cell: (tableProps: CustomTableProps) => {
      const { implementation_period } = tableProps.row.original;
      return <TableCell>{implementation_period}</TableCell>;
    },
  },
  {
    Header: "План",
    width: 100,
    sortType: sortPlan(),
    accessor: "plan",
    Cell: (tableProps: CustomTableProps) => {
      const { plan } = tableProps.row.original;
      return <TableCell>{plan}</TableCell>;
    },
  },
  {
    Header: "План на год",
    sortType: sortPlanYear(),
    accessor: "planYear",
    width: 160,
    Cell: (tableProps: CustomTableProps) => {
      const { plan_year } = tableProps.row.original;
      return <TableCell>{plan_year}</TableCell>;
    },
  },
  {
    Header: "Факт",
    accessor: isOM ? "fact_om" : "fact",
    width: 100,
    Cell: (tableProps: CustomTableProps) => {
      const { fact, fact_om } = tableProps.row.original;

      return (
        <TableCell color={isOM ? "blue" : "white"}>
          {isOM ? fact_om : fact}
        </TableCell>
      );
    },
  },
  {
    Header: "Уровень достижения, %",
    sortType: sortInPerformanceColumn(isOM),
    accessor: isOM ? "performanceOM" : "performance",
    width: 200,
    Cell: (tableProps: CustomTableProps) => {
      const {
        achievement,
        achievement_om,
        inPerformance,
        typeCalcPerformance,
      } = tableProps.row.original;
      const achievementNumber = (isOM ? achievement_om : achievement) || 0;

      const typeCalc =
        typeCalcPerformance === 0
          ? ETypeCalcPerformance.FOR_PLAN
          : ETypeCalcPerformance.FOR_POINTS;

      const hint = inPerformance
        ? typeCalc
        : ETypeCalcPerformance.NOT_PARTICIPATE;

      return (
        <TableCell>
          <PerformanceValue
            value={getPercentValue(achievementNumber)}
            sameSize={true}
          />
        </TableCell>
      );
    },
  },
  {
    Header: " ",
    accessor: isOM ? "is_under_gp_om" : "is_under_gp_foiv",
    width: 100,
    Cell: (tableProps: CustomTableProps) => {
      const isUnder = isOM
        ? Boolean(tableProps.row.original.is_under_gp_om)
        : Boolean(tableProps.row.original.is_under_gp_foiv);
      return (
        <TableCell>
          {isUnder && (
            <TooltipText
              hint="Спущен с уровня госпрограммы"
              variant="h3"
              placement="left"
            >
              <UnderProgram />
            </TooltipText>
          )}
        </TableCell>
      );
    },
  },
];
