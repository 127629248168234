import { styled } from "@mui/material";

export const Wrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "1.2rem",
  ".border": {
    paddingBottom: "0.8rem",
    borderBottom: `1px solid ${theme.palette.text.secondary}`,
  },
  ".title": {
    fontSize: "0.8rem",
    color: theme.palette.main.gray,
  },
  ".main .title": {
    fontSize: "1rem",
  },
}));
