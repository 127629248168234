import { Grid, styled } from "@mui/material";

export const GridWrapper = styled(Grid)(({ theme }) => {
  const lgSize = theme.breakpoints.up("lg");

  return {
    flex: 1,

    "> .center": {
      flex: 1,
    },

    "> .right": {
      flex: 0,

      [lgSize]: {
        flex: 1,
      },
    },

    "> .left": {
      flex: 0,

      [lgSize]: {
        flex: 1,
        order: -1,
      },
    },
  };
});
