import { EDateType } from "SRC/constants/globals";

export const getPeriodValue = (value: string) => {
  const periodValue = value.slice(4);

  if (periodValue === "00") {
    return EDateType.YEAR;
  } else if (periodValue.length === 1) {
    return EDateType.QUARTER;
  } else {
    return EDateType.MONTH;
  }
};
