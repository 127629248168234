import { BaseQueryApi } from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import { FetchBaseQueryArgs } from "@reduxjs/toolkit/dist/query/fetchBaseQuery";
import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from "@reduxjs/toolkit/query";

const fetchOptions: FetchBaseQueryArgs = {
  baseUrl: "./",
};

export const setAuthToken = (access_token: string): void => {
  fetchOptions.prepareHeaders = (headers): Headers => {
    headers.set("authorization", `Bearer ${access_token}`);
    return headers;
  };
};

const baseQuery = (
  path: string
): BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError,
  {},
  FetchBaseQueryMeta
> => fetchBaseQuery({ ...fetchOptions, baseUrl: fetchOptions.baseUrl + path });

// type: "print" | "find" = "find") =>
export const baseQueryWithInterceptors =
  (path: string) => async (args: any, api: BaseQueryApi, extraOptions: {}) => {
    const baseQueryFn = baseQuery(path); // type === "find" ? baseQuery(path) : baseQueryTest(path);
    const result = await baseQueryFn(args, api, extraOptions);

    // TODO [написать]: аналогичную функцию под RTK
    // checkResponseStatus(result);

    return result;
  };
