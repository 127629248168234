import { SxProps, Tooltip } from "@mui/material";
import { IOPValue, OverlayStackedProgress } from "SRC/components/ProgressBar";

import { css, Wrapper, WrapperDetailed } from "./DetailedTooltip.styled";

interface IDetailedTooltip {
  data: Record<string, IOPValue[]>;
}

export const Detailed = ({ data }: IDetailedTooltip) => {
  return (
    <WrapperDetailed>
      <OverlayStackedProgress
        failValues={data.fail}
        successValues={data.success}
        total={240}
        title="ФП в НП"
      />
      <OverlayStackedProgress
        failValues={data.fail}
        successValues={data.success}
        total={240}
        title="ФП вне НП"
      />
    </WrapperDetailed>
  );
};

export const DetailedTooltip = ({ data }: IDetailedTooltip) => {
  return (
    <Wrapper>
      <Tooltip
        placement="right"
        title={<Detailed data={data} />}
        PopperProps={{
          sx: css.hintContainer as SxProps,
        }}
      >
        <div className="tooltip-content">Подробнее</div>
      </Tooltip>
    </Wrapper>
  );
};
