import { Theme } from "@mui/material";

export const css = {
  buttonsContainer: {
    display: "flex",
    gap: "0.5rem",
    justifyContent: "flex-end",
    alignItems: "center",
    height: "fit-content",
  },
  button: (theme: Theme) => ({
    background: theme.palette.complementary.darkGrayBlue,
    width: "2.5rem",
    height: "2.5rem",
    minWidth: "auto",
  }),
};
