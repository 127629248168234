import { Box } from "@mui/material";
import React from "react";
import { TableProps, UseTableRowProps } from "react-table";
import { TableCell } from "SRC/components/TableCell";

interface CustomTableProps extends TableProps {
  row: UseTableRowProps<any>;
  rows?: UseTableRowProps<any>[];
}

export const getGeneralColumns = () => [
  {
    Header: "",
    width: 0,
    accessor: "arrow-button",
    Cell: (tableProps: CustomTableProps) => <Box></Box>,
  },
  {
    Header: "Госпрограмма",
    width: 150,
    accessor: "program",
    Cell: (tableProps: CustomTableProps) => (
      <TableCell color="gray">0</TableCell>
    ),
  },
  {
    Header: "Тип СЭ",
    width: 100,
    accessor: "typeStructure",
    Cell: (tableProps: CustomTableProps) => (
      <TableCell color="gray">0</TableCell>
    ),
  },
  {
    Header: "Наименование СЭ",
    width: "50%",
    accessor: "nameStructure",
    Cell: (tableProps: CustomTableProps) => <TableCell>0</TableCell>,
  },
  {
    Header: "Количество показателей",
    width: "auto",
    accessor: "countIndicators",
    Cell: (tableProps: CustomTableProps) => (
      <TableCell color="gray">0</TableCell>
    ),
  },
  {
    Header: "Количество мероприятий",
    width: "auto",
    accessor: "countEvents",
    Cell: (tableProps: CustomTableProps) => (
      <TableCell color="gray">0</TableCell>
    ),
  },
];
