import { UnderArrow } from "ASSETS/svg";
import { Wrapper } from "SRC/components/UnderProgram/UnderProgram.styled";

export const UnderProgram = () => {
  return (
    <Wrapper>
      <UnderArrow />
      <span>ГП</span>
    </Wrapper>
  );
};
