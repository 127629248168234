import { useCallback, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { isSecret } from "SRC/helpers/dataTesters";
import { IProgram } from "SRC/redux/list/programs";
import { EDataProgram, TTypesOFGP } from "SRC/redux/list/programs-main";
import { useGlobalFilters } from "SRC/redux/slices/global/hooks/useFilters";
import { IGoals, useGoals } from "SRC/redux/slices/main/hooks/useGoals";
import {
  IMainPrograms,
  useMainPrograms,
} from "SRC/redux/slices/main/hooks/useMainPrograms";
import {
  IPremiers,
  usePremiers,
} from "SRC/redux/slices/main/hooks/usePremiers";
import {
  IPrograms,
  usePrograms,
} from "SRC/redux/slices/main/hooks/usePrograms";
import slice, { IFiltersState } from "SRC/redux/slices/main/slices/filters";
import { IGoal } from "SRC/redux/slices/main/slices/goals";
import { EGridTabs } from "SRC/redux/slices/summary/slices/filters";

import { useAppSelector } from "../../../hooks";
import { IMinistries, useMinistries } from "./useMinistries";

export interface IFilters extends IFiltersState {
  toggleChart(viewId: number): void;
  clear(): void;
  setSelectedProgram(code: string): void;
  setSelectTypeProgram(code: string): void;
  setSelectedVP(code: string): void;
  setSelectedMinistry(code: string): void;
  setSelectedSphere(sphere: string): void;
  setSecretSphere(value: boolean): void;
  setSelectedGoal(code: string): void;
  setSelectedFoiv(code: string): void;
  filteredGoals: (string | number)[];
  filteredVPs: (string | number | null)[];
  filteredMinistries: (string | number)[];
  filteredPrograms: (string | number | null)[];
  filteredMainPrograms: (string | number | null)[];
  setSelectedCenterTab(tab: string): void;
  setSelectedComplexGridTab(tab?: EGridTabs): void;
  setSearchRows(rows: Record<string, any>[]): void;
}

type TTypesKeys = "secret" | "industry" | "complex";

const SPLIT_REGEXP = /,|;/;

const renameKeys = (object: Record<TTypesOFGP, number>) => {
  const typesGP = {
    secret: 0,
    industry: 0,
    complex: 0,
  };
  typesGP["secret"] = object["Секретная"];
  typesGP["industry"] = object["Отраслевая"];
  typesGP["complex"] = object["Комплексная"];

  return typesGP;
};

const getProgramsByGoal = (programs: IProgram[], goal: IGoal) => {
  const goalProgramsCodes = goal.GP_LIST.split(",") || [];

  return programs.filter((program) => {
    return goalProgramsCodes.includes(program.info.code);
  });
};

const getMinistriesByPrograms = (programs: IProgram[]) => {
  const ministries = programs.map((program) => {
    return program.info.ministry;
  });

  return Array.from(new Set(ministries));
};

export const useFilters = (): IFilters => {
  const dispatch = useDispatch();
  const filters = useAppSelector((state) => state.main.filters);
  const { goal, vp, typeProgram, program, ministry, sphere } = filters;
  const { withSecret } = useGlobalFilters();
  const { items: premiers }: IPremiers = usePremiers();
  const { items: programs }: IPrograms = usePrograms();
  const { items: mainPrograms }: IMainPrograms = useMainPrograms();
  const { items: ministries }: IMinistries = useMinistries();
  const { items: goals, getItemByCode: getGoalByCode }: IGoals = useGoals();
  // TODO: переделать на поиск внутри вице-премьера, когда будет список целей
  const currentGoal = getGoalByCode(goal);

  const selectedProgram = useMemo(() => {
    const [result] = programs.filter(({ info }) => {
      return info.code === program;
    });

    return result || null;
  }, [program, programs]);

  const selectedGoalPrograms = useMemo(() => {
    if (currentGoal) {
      return getProgramsByGoal(programs, currentGoal);
    }

    return programs;
  }, [currentGoal, programs]);

  const selectedGoalMinistries = useMemo(() => {
    return getMinistriesByPrograms(selectedGoalPrograms);
  }, [selectedGoalPrograms]);

  // TODO: сделать выбор ФОИВ'ов
  const setSelectedFoiv = useCallback(
    (id: string) => {
      dispatch(slice.actions.selectFoiv(id));
    },
    [dispatch]
  );

  const filteredVPs = useMemo(() => {
    const { vpCode: selectedProgramVpCode } = selectedProgram?.info || {};

    return premiers
      .filter((premier) => {
        return (
          !selectedProgramVpCode ||
          premier.info.VP_CODE === selectedProgramVpCode
        );
      })
      .filter((premier) => {
        return (
          !currentGoal || currentGoal?.VP_LIST.includes(premier.info.VP_CODE)
        );
      })
      .filter((premier) => {
        return !ministry || premier.info.VP_FOIV?.split(";").includes(ministry);
      })
      .map((premier) => premier.info.VP_CODE);
  }, [selectedProgram, premiers, currentGoal, ministry]);

  const filteredMinistries: string[] = useMemo(() => {
    const { ministry: selectedProgramMinistry } = selectedProgram?.info || {};

    return ministries
      .filter((ministry) => {
        return (
          !selectedProgramMinistry ||
          ministry.info.code === selectedProgramMinistry
        );
      })
      .filter((ministry) => {
        return (
          !currentGoal || selectedGoalMinistries.includes(ministry.info.code)
        );
      })
      .map((ministry) => ministry.info.code);
  }, [currentGoal, ministries, selectedGoalMinistries, selectedProgram?.info]);

  const filteredGoals = useMemo(() => {
    return goals
      .filter(
        (goal) =>
          (!vp || goal.VP_LIST.includes(vp)) &&
          (!program || goal.GP_LIST.includes(program)) &&
          (!ministry ||
            getMinistriesByPrograms(getProgramsByGoal(programs, goal)).includes(
              ministry
            ))
      )
      .map((goal) => goal.NT_CODE);
  }, [goals, vp, program, programs, ministry]);

  const filteredPrograms = useMemo(
    () =>
      programs
        .filter(({ info, data }) => {
          const isGoal =
            !goal ||
            (data[1769]?.sum || "").toString().split(";")?.includes(goal);
          const isSphere = !sphere || info.sphere === sphere;
          const isMinistry =
            !ministry || info.ministry === ministry.toUpperCase();
          const isVp = !vp || info.vpCode === vp;
          const isSecretProgram = withSecret || !isSecret(info);
          const isTyped =
            !typeProgram ||
            !!Number(info[typeProgram as keyof IProgram["info"]]);

          return (
            isSecretProgram &&
            isGoal &&
            isSphere &&
            isMinistry &&
            isVp &&
            isTyped
          );
        })
        .map(({ info: { code } }) => code),
    [programs, vp, goal, ministry, sphere, withSecret, typeProgram]
  );

  const filteredMainPrograms = useMemo(
    () =>
      mainPrograms
        .filter(
          ({
            list_nt,
            [EDataProgram.MINISTRY]: programMinistry,
            vp_code,
            [EDataProgram.TYPES_OF_GP]: gp_types,
            gp_code,
          }) => {
            const isGoal =
              !goal || (list_nt || "").split(SPLIT_REGEXP).includes(goal);
            const isMinistry =
              !ministry || programMinistry[0] === ministry.toUpperCase();
            const isVp = !vp || vp_code === vp;
            const isSecretProgram = withSecret || !gp_types["Секретная"];
            const typesOfProgram = renameKeys(gp_types);
            const isTyped =
              !typeProgram ||
              !!Number(typesOfProgram[typeProgram as TTypesKeys]);

            return isSecretProgram && isGoal && isMinistry && isVp && isTyped;
          }
        )
        .map(({ gp_code }) => gp_code),
    [mainPrograms, vp, goal, ministry, withSecret, typeProgram]
  );

  const setSelectedProgram = (id: string) => {
    return dispatch(slice.actions.selectProgram(program === id ? "" : id));
  };

  const setSelectTypeProgram = (type: string) =>
    dispatch(slice.actions.selectTypeProgram(type));

  const setSelectedVP = (id: string) => {
    return dispatch(slice.actions.selectVP(vp === id ? "" : id));
  };

  const setSelectedMinistry = (id: string) => {
    return dispatch(slice.actions.selectMinistry(ministry === id ? "" : id));
  };

  const setSelectedSphere = (sphere: string) => {
    dispatch(slice.actions.selectSphere(sphere));
  };

  const setSecretSphere = (value: boolean) => {
    if (sphere) {
      dispatch(slice.actions.setSphereSecret(false));
    } else {
      dispatch(slice.actions.setSphereSecret(value));
    }
  };

  const setSelectedGoal = (id: string) => {
    dispatch(slice.actions.selectGoal(goal === id ? "" : id));
    if (id) {
      setSelectedProgram("");
      if (!getGoalByCode(id)?.VP_LIST.includes(vp)) {
        dispatch(slice.actions.selectVP(""));
      }
    }
  };

  const clear = useCallback(() => dispatch(slice.actions.clear()), [dispatch]);
  const toggleChart = (activeId: number) =>
    dispatch(slice.actions.toggleChart(activeId));

  const setSelectedCenterTab = (tab: string) =>
    dispatch(slice.actions.setCenterTab(tab));

  const setSelectedComplexGridTab = (tab?: EGridTabs) => {
    dispatch(slice.actions.setComplexGridTab(tab || EGridTabs.PROGRAMS));
  };

  const setSearchRows = (rows: Record<string, any>[]) => {
    dispatch(slice.actions.selectSearchRows(rows));
  };

  useEffect(() => {
    if (ministry) {
      if (!filteredMinistries.includes(ministry)) {
        setSelectedMinistry(filteredMinistries[0] || "");
      }
    }
  }, [filteredMinistries, ministry, setSelectedMinistry]);

  useEffect(() => {
    if (vp) {
      if (!filteredVPs.includes(vp)) {
        setSelectedVP(filteredVPs[0] || "");
      }
    }
  }, [filteredVPs, vp, setSelectedVP]);

  return {
    ...filters,
    setSelectedProgram,
    setSelectTypeProgram,
    setSelectedMinistry,
    setSelectedSphere,
    setSecretSphere,
    setSelectedVP,
    setSelectedGoal,
    clear,
    toggleChart,
    filteredVPs,
    filteredMinistries,
    filteredGoals,
    filteredPrograms,
    filteredMainPrograms,
    setSelectedFoiv,
    setSelectedCenterTab,
    setSelectedComplexGridTab,
    setSearchRows,
  };
};
