import { styled, Theme } from "@mui/material";
import { NavLink } from "react-router-dom";

export const css = {
  button: {
    display: "block",
    height: "100%",
    minWidth: "0",
    aspectRatio: "1",
    borderRadius: "50%",
    color: (theme: Theme) => theme.palette.primary.contrastText,

    "& > svg": {
      verticalAlign: "middle",
      width: "1.3em",
      height: "1.3em",
    },
  },
  sidemenu: {
    padding: "6rem 4rem",
    display: "flex",
    flexDirection: "column",
    listStyleType: "none",

    "& :hover": {
      textDecoration: "underline",
    },
  },
};

// @ts-ignore
const sidelink: TemplateStringsArray = <T extends Theme>({
  theme,
}: {
  theme: T;
}) => ({
  color: theme.palette.primary.contrastText,
  opacity: 0.8,
  textDecoration: "none",
  position: "relative",
  left: "-1rem",
  padding: "0.5rem 1rem",
  margin: "0.5rem 0",
  display: "inline-block",

  '&[aria-current="page"]': {
    color: theme.palette.primary.contrastText,
    opacity: 1,
  },

  [theme.breakpoints.up("lg")]: {
    padding: "1.3rem 1.3rem 2.3rem 1.3rem",
  },
});

export const SidemenuLink = styled(NavLink)(sidelink);
export const ExtLink = styled("a")(sidelink);
