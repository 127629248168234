import { getIsSecret } from "SRC/pages/Summary/common/SummaryComplexGrid/utils";
import { EVENT_STATUS, ICustomTableProps, PROGRAM_TABS } from "SRC/types";
import { createProgramCardUrl, PROGRAM_VIEW_TAB } from "SRC/urls";

export const getEventsFail = (tableProps: ICustomTableProps, isOM: boolean) => {
  const { id, eventsFail, eventsOMFail } = tableProps.row.original;

  const url = createProgramCardUrl(
    PROGRAM_VIEW_TAB,
    id,
    PROGRAM_TABS.EVENTS,
    EVENT_STATUS.NOT_DONE
  );

  return {
    url,
    eventsFail: isOM ? eventsOMFail : eventsFail,
    isSecret: getIsSecret(tableProps),
  };
};
