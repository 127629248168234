import { Checkbox } from "@mui/material";
import React from "react";

import { FormControlLabelStyled } from "./CustomCheckbox.styled";

interface ICustomCheckbox {
  checked: boolean;
  label: string;
  onChange: () => void;
}
export const CustomCheckbox = ({
  checked,
  label,
  onChange,
}: ICustomCheckbox) => {
  return (
    <FormControlLabelStyled
      control={<Checkbox onChange={onChange} checked={checked} />}
      label={label}
    />
  );
};
