import { olapDimensionRequestData } from "CORE/api/core";
import { MODEL_UUID } from "CORE/scheme/olap";
import { IPeriod } from "SRC/redux/slices/global/slices/filters";

const GET_PREMIERS_MAIN = "get-premiers-main";

export const getPremiersMain = (period: IPeriod) => {
  return olapDimensionRequestData(
    [
      {
        requestId: "cd9f0aca-4c83-415d-863a-c14bccc1de94",
        type: "data2",
        params: {
          modelUuid: MODEL_UUID,
          dimensions: [],
          indicators: {},
          section_name: "1.0.5 Вице-премьеры",
          filters: {
            period_code: period.filterValue,
            show_finals: true,
          },
        },
      },
    ],
    GET_PREMIERS_MAIN
  );
};
