import { IAchievementData } from "SRC/components/AchievementsTile/interfaces";
import { isSecret } from "SRC/helpers/dataTesters";

import {
  IBestProgram2,
  ITopMinistries,
  ITopVP,
  IWorstProgram2,
} from "./interfaces";

export const createTopMinistriesData = (
  data: ITopMinistries[]
): IAchievementData[] =>
  data.map((item: ITopMinistries) => ({
    name: item?.code,
    sum: item?.data[0].sum,
    url: item?.icon_url,
  }));

export const createBestGP2Data = (data: IBestProgram2[]): IAchievementData[] =>
  data.map((item: IBestProgram2) => ({
    url: item?.gp_icon_url,
    name: item?.gp_name,
    code: item?.gp_code,
    sum: item?.data.find((el) => el.code === "1796")?.sum || 0,
    secret: isSecret(item),
  }));

export const createBestGpOMData = (data: IBestProgram2[]): IAchievementData[] =>
  data.map((item: IBestProgram2) => ({
    url: item?.gp_icon_url,
    name: item?.gp_name,
    code: item?.gp_code,
    sum: item?.data.find((el) => el.code === "5265")?.sum || 0,
    secret: isSecret(item),
  }));

export const createWorstGpOMData = (
  data: IBestProgram2[]
): IAchievementData[] =>
  data.map((item: IBestProgram2) => ({
    url: item?.gp_icon_url,
    name: item?.gp_name,
    code: item?.gp_code,
    sum: item?.data.find((el) => el.code === "5265")?.sum || 0,
    secret: isSecret(item),
  }));

export const createWorstGP2Data = (
  data: IWorstProgram2[]
): IAchievementData[] =>
  data.map((item: IWorstProgram2) => ({
    url: item?.gp_icon_url,
    name: item?.gp_name,
    code: item?.gp_code,
    sum: item?.data.find((el) => el.code === "1796")?.sum || 0,
    secret: isSecret(item),
  }));

export const createBestVP2Data = (data: ITopVP[]): IAchievementData[] =>
  data.map((item: ITopVP) => ({
    url: item?.VP_PHOTO_URL,
    name: item?.VP_NAME,
    sum: item?.sum,
  }));

export const createWorstVP2Data = (data: ITopVP[]): IAchievementData[] =>
  data.map((item: ITopVP) => ({
    url: item?.VP_PHOTO_URL,
    name: item?.VP_NAME,
    sum: item?.sum,
  }));

export const sortRatingDataBySum = (data: IAchievementData[]) => {
  return data.sort(({ sum: sumA }, { sum: sumB }) => {
    return sumA > sumB ? -1 : 1;
  });
};
