import { Button } from "@mui/material";

import { Wrapper } from "./IncidentFileDefault.styled";

interface IIncidentFileDefaultProps {
  uploadInputRef: React.MutableRefObject<HTMLInputElement | null>;
  onChangeFiles: (e: any) => void;
}

export const IncidentFileDefault = ({
  uploadInputRef,
  onChangeFiles,
}: IIncidentFileDefaultProps) => {
  const handleOnClick = () => {
    uploadInputRef.current && uploadInputRef.current.click();
  };

  return (
    <Wrapper>
      <input
        ref={uploadInputRef}
        type="file"
        accept="image/*"
        style={{ display: "none" }}
        onChange={onChangeFiles}
      />
      <span className="file-name">Прикрепить файл</span>
      <Button onClick={handleOnClick} variant="contained">
        Загрузить файл
      </Button>
    </Wrapper>
  );
};
