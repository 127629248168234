import FileSaver from "file-saver";
import { useCallback, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { EExtensions } from "SRC/constants";
import { globalStyles } from "SRC/hooks/use-excel-creator/excelStyles";
import { isFullReport } from "SRC/hooks/use-excel-creator/tableReportCreator";
import slice from "SRC/redux/slices/global/slices/notification";
import { IExportData, IWorkbookData, TConfig } from "SRC/types";
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
import XLSXWorker from "worker-loader!./xlsx.worker";

interface ICreateWorkerParams {
  ext?: EExtensions;
  callback?: VoidFunction;
  fileName: string;
}

interface IOutputFileData {
  blob: Blob;
  name: string;
  extension: EExtensions;
}

interface IFileName {
  [word: string]: string;
}

const FILE_NAME: IFileName = {
  tab_impl_lvl: "Подложка по УД мнение",
  xls_control_points: "Сводные данные по КТ",
  xls_structure_elements: "Сводные данные по СЭ",
  xls_events: "Сводные данные по мероприятиям",
};

type TDataWorker = ["saved", IOutputFileData] | ["error", Error | void];

const saveFile = ({ blob, extension, name }: IOutputFileData) =>
  new Promise((resolve) => {
    const normalizeExtension = extension === EExtensions.ODS ? "ods" : "xlsx";
    FileSaver.saveAs(blob, `${name}.${normalizeExtension}`);

    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = resolve;
  });

const useFullReportCreator = (
  data: IWorkbookData | IExportData<object> | null,
  config: TConfig | void = globalStyles
) => {
  const saving = useRef<boolean>(false);
  const extRef = useRef<EExtensions>(EExtensions.ODS);
  const workerRef = useRef<Worker | null>(null);
  const callbackRef = useRef<VoidFunction>(() => {});
  // const { isCanceled } = useAppSelector((state) => state.global.notification);

  const dispatch = useDispatch();

  const createWorker = useCallback(
    ({ ext, callback, fileName }: ICreateWorkerParams) => {
      if (ext) {
        extRef.current = ext;
      }

      dispatch(slice.actions.message(FILE_NAME[fileName] ?? fileName));
      dispatch(slice.actions.open(true));

      if (callback) {
        callbackRef.current = callback;
      }
      workerRef.current = new XLSXWorker() as Worker;
    },
    []
  );

  useEffect(() => {
    if (data && workerRef.current) {
      const worker = workerRef.current;

      // if (isCanceled) {
      //   worker?.terminate();
      // }

      worker.onmessage = (e: MessageEvent<TDataWorker>) => {
        const [message, payload] = e.data;

        if (message === "error") {
          console.error("При сохранении файла произошла ошибка", payload);
          dispatch(slice.actions.error("При загрузке файла произошла ошибка"));
        }

        if (message === "saved") {
          saveFile(payload).then(() => {
            worker?.terminate();
            callbackRef.current();
            dispatch(slice.actions.open(false));
            saving.current = false;
          });
        }
      };

      if (isFullReport(data)) {
        worker.postMessage([
          "fullReport",
          { config, data, ext: extRef.current },
        ]);
      } else {
        worker.postMessage(["tableReport", { data, ext: extRef.current }]);
      }
    }
  }, [data, config]);

  return createWorker;
};

export type TExcelCreator = ReturnType<typeof useFullReportCreator>;

export default useFullReportCreator;
