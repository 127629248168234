import { useMemo } from "react";
import { useGlobalFilters } from "SRC/redux/slices/global/hooks/useFilters";
import {
  IFilters,
  useFilters,
  useStructureElementsIndicators,
} from "SRC/redux/slices/gosprogram/hooks";
import { ISEIndicatorsData } from "SRC/types";

import {
  createSeIndicatorsTableData,
  getIndicatorStatuses,
  structureElementTypes,
  structureElementTypesMap,
} from "../utils";

type TSEIndicatorsTableData = {
  indicatorsStatuses: any;
  indicatorsTableData: any;
  uniqueTypeProjects: string[];
  uniqueStructureNames: string[];
  uniqueTaskOptions: string[];
};

export const useSEIndicatorsTableData = (): TSEIndicatorsTableData => {
  const { items } = useStructureElementsIndicators();
  const {
    selectedStructureElementType,
    selectedStructureElementTask,
    selectedStructureElementName,
    selectedStatus,
  }: IFilters = useFilters();
  const { inPerformance, sources } = useGlobalFilters();

  // Исходные данные преобразованные в табличный вид
  const tableData = useMemo(() => {
    return createSeIndicatorsTableData(items);
  }, [items]);

  // Применение фильтров к преобразованным данным
  const { indicatorsTableData, indicatorsStatuses } = useMemo(() => {
    let filteredData = tableData.filter((row: any) => {
      const structureElementTypeFilter =
        selectedStructureElementType === "Федеральные проекты"
          ? row.se_gp_type === "ФП"
          : selectedStructureElementType === "Ведомственные проекты"
          ? row.se_gp_type === "ВП"
          : selectedStructureElementType === "Комплексы процессных мероприятий"
          ? row.se_gp_type === "КПМ"
          : true;
      const structureTaskFilter =
        !selectedStructureElementTask ||
        row.se_task === selectedStructureElementTask;
      const structureNameFilter =
        !selectedStructureElementName ||
        row.name === selectedStructureElementName;
      const inPerformanceFilterFOIV =
        !inPerformance || Boolean(row.in_performance_foiv);
      const inPerformanceFilterOM =
        (!inPerformance && sources.OM) || Boolean(row.in_performance_om);
      const statusFilter =
        !selectedStatus.length || selectedStatus.includes(row.status);
      return (
        structureElementTypeFilter &&
        structureTaskFilter &&
        structureNameFilter &&
        statusFilter &&
        (inPerformanceFilterFOIV || inPerformanceFilterOM)
      );
    });
    return {
      indicatorsTableData: filteredData,
      indicatorsStatuses: getIndicatorStatuses(filteredData),
    };
  }, [
    tableData,
    selectedStructureElementType,
    selectedStructureElementTask,
    selectedStructureElementName,
    inPerformance,
    sources.OM,
    selectedStatus,
  ]);

  // уникальный список Типов структурных элементов (ФП ВП КПМ)
  const uniqueTypeProjects: string[] = useMemo(() => {
    return Array.from(
      new Set(
        tableData
          .filter((item: ISEIndicatorsData) => {
            const structureElementNameFilter =
              !selectedStructureElementName ||
              selectedStructureElementName === item.name;
            const structureNameFilter =
              !selectedStructureElementTask ||
              item.se_task === selectedStructureElementTask;
            return structureElementNameFilter && structureNameFilter;
          })
          .map((item: ISEIndicatorsData) => {
            return structureElementTypesMap[item.se_gp_type];
          })
      )
    );
  }, [tableData, selectedStructureElementName, selectedStructureElementTask]);

  const uniqueStructureNames: string[] = useMemo(() => {
    return Array.from(
      new Set(
        tableData
          .filter((item: ISEIndicatorsData) => {
            const structureElementTypeFilter =
              !selectedStructureElementType ||
              item.se_gp_type ===
                structureElementTypes[selectedStructureElementType];
            const structureNameFilter =
              !selectedStructureElementTask ||
              item.se_task === selectedStructureElementTask;
            return structureElementTypeFilter && structureNameFilter;
          })
          .map((item: ISEIndicatorsData) => {
            return item.name;
          })
      )
    );
  }, [tableData, selectedStructureElementType, selectedStructureElementTask]);

  const uniqueTaskOptions: string[] = useMemo(() => {
    return Array.from(
      new Set(
        tableData
          .filter((item: ISEIndicatorsData) => {
            const structureElementNameFilter =
              !selectedStructureElementName ||
              selectedStructureElementName === item.name;
            const structureNameFilter =
              !selectedStructureElementTask ||
              item.se_task === selectedStructureElementTask;
            return structureElementNameFilter && structureNameFilter;
          })
          .map((item: ISEIndicatorsData) => {
            return item.se_task;
          })
      )
    );
  }, [selectedStructureElementName, selectedStructureElementTask, tableData]);

  return {
    indicatorsStatuses,
    indicatorsTableData,
    uniqueTypeProjects,
    uniqueStructureNames,
    uniqueTaskOptions,
  };
};
