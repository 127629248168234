import { Box } from "@mui/material";
import React from "react";
import { Checkpoints } from "SRC/pages/Event/common/Checkpoints/Checkpoints";
import { EventCorrection } from "SRC/pages/Event/common/EventCorrection/EventCorrection";
import { useFilters } from "SRC/redux/slices/event/hooks/useFilters";

import { EFootView } from "../../interfaces";

export const Footer: React.FC = () => {
  const { footerView } = useFilters();

  switch (footerView) {
    case EFootView.POINTS:
      return <Checkpoints />;
    case EFootView.CORRECTION:
      return <EventCorrection />;
    case EFootView.REASON:
      return <Box>reason</Box>;
    default:
      return null;
  }
};
