import { styled, Theme } from "@mui/material";

export const Wrapper = styled("div")(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
    flex: "0 0 53%",
    flexWrap: "nowrap",
    borderRadius: "1rem",
    padding: "1.5rem 1.5rem",
    alignItems: "center",
    backgroundColor: theme.palette.complementary.darkBlueLight,

    "& .incident-progress": {
      display: "flex",
      flexDirection: "column",
      gap: "0.5rem",
    },

    "& .incident-lineprogress": {
      display: "flex",
      flexDirection: "column",
      gap: "1.25rem",
      flex: "0 0 30%",
    },
  };
});

export const css = {
  effectiveHeader: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",
  },
  effectiveContent: {
    display: "flex",
    width: "100%",
    gap: "1.5vw",
    justifyContent: "space-between",
  },
  achievement: {
    display: "flex",
    flex: "0 0 27%",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "left",
    gap: "2rem",
    "& .item": {
      display: "flex",
      flexDirection: "column",
      gap: "0.5rem",
    },
  },
  title: {
    position: "relative",
    display: "flex",
    alignItems: "flex-start",
    fontSize: "1.5rem",
    lineHeight: "1.5rem",
  },
  download: {
    position: "absolute",
    left: "100%",
    bottom: 0,
  },
  lines: {
    display: "flex",
    flexDirection: "column",
  },
  chartContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
  },
  chart: {
    width: "80%",
    aspectRatio: "1 / 1",
    margin: "auto",
    display: "flex",
  },
  titleAchievement: {
    fontSize: "1.375rem",
  },
  progressAchievement: {
    display: "flex",
    flexDirection: "column",
  },
  value: {
    display: "inline-flex",
    alignItems: "center",
    fontSize: "2.5rem",

    "&.indicators > div::after": {
      content: '"/"',
      color: (theme: Theme) => theme.palette.primary.contrastText,
    },
  },
  progressBarStyle: {
    background: (theme: Theme) => theme.palette.background.progressDarkBlue,
  },
};
