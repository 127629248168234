import { Modal } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { OverlayBlock } from "SRC/components/OverlayBlock";
import { ModalWrapper } from "SRC/pages/Event/common/EventTabs/ObjectTab/ObjectTab.styled";
import { PhotosModal } from "SRC/pages/Event/common/EventTabs/ObjectTab/PhotosModal/PhotosModal";
import { useFilters } from "SRC/redux/slices/event/hooks/useFilters";
import { useObjectPhotos } from "SRC/redux/slices/event/hooks/useObjectPhotos";
import { actions as objectPhotosActions } from "SRC/redux/slices/event/slices/objectPhotos";
import {
  IGlobalFilters,
  useGlobalFilters,
} from "SRC/redux/slices/global/hooks/useFilters";

interface IPhotosModalWrapperProps {
  isPhotoModalOpen: boolean;
  handlePhotoModalClose(): void;
}

export const PhotosModalWrapper = ({
  isPhotoModalOpen,
  handlePhotoModalClose,
}: IPhotosModalWrapperProps) => {
  const { period }: IGlobalFilters = useGlobalFilters();
  const { fetching, fetched, load, error, items } = useObjectPhotos();
  const dispatch = useDispatch();
  const { selectedObjectId } = useFilters();

  useEffect(() => {
    if (!fetching && !fetched && !error && selectedObjectId) {
      load({
        id: selectedObjectId,
        period,
      });
    }
  }, [fetching, fetched, error, period, selectedObjectId]);

  useEffect(() => {
    dispatch(
      objectPhotosActions.get({
        id: selectedObjectId,
        period,
      })
    );
  }, [dispatch, selectedObjectId, period]);

  return (
    <Modal
      open={isPhotoModalOpen}
      onClose={handlePhotoModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalWrapper>
        <OverlayBlock isFetching={fetching} hasData={Boolean(items.length)}>
          <PhotosModal handlePhotoModalClose={handlePhotoModalClose} />
        </OverlayBlock>
      </ModalWrapper>
    </Modal>
  );
};
