import { Box, styled, Theme } from "@mui/material";

export const ModalContent = styled(Box)(({ theme }) => {
  const {
    background: { default: defaultColor },
    primary: { contrastText },
    divider,
  } = theme.palette;

  return {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    margin: "auto",
    width: "calc(100% - 4rem)",
    borderRadius: "0.75rem",
    border: `1px solid ${divider}`,
    overflow: "hidden",
    padding: "2rem",
    height: "calc(100% - 2rem)",
    boxSizing: "border-box",
    background: defaultColor,
    display: "flex",
    flexDirection: "column",

    [theme.breakpoints.up("md")]: {
      maxWidth: theme.breakpoints.values.md,
      minWidth: "unset",
      width: "calc(100% - 5rem)",
    },

    ".header": {
      justifyContent: "space-between",
      alignItems: "baseline",
      marginBottom: "1rem",
    },

    ".title": {
      fontSize: "2.5rem",
      color: contrastText,
      fontWeight: "bold",
    },

    ".close-button": {
      cursor: "pointer",
    },
  };
});
