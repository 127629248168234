export const css = {
  switch: {
    display: "flex",
    position: "relative",
    height: "2.5rem",
    alignItem: "center",

    "& .controlLabel": {
      fontSize: "1.2rem",
      lineHeight: "1.2rem",
      fontWeight: 400,
    },
  },
};
