export type TStatuses =
  | "doneItems"
  | "notDoneItems"
  | "noDataItems"
  | "plannedItems"
  | "atRiskItems"
  | "reliasedItems"
  | "inReliaseItems"
  | "totalItems"
  | "notStartedItems"
  | "notAchievedItems"
  | "criticalItems"
  | "outDateItems";

export enum EVENT_STATUS {
  NOT_STARTED = "notStartedItems",
  NOT_ACHIEVED = "notAchievedItems",
  CRITICAL = "criticalItems",
  OUT_DATE = "outDateItems",
  IN_PROGRESS = "inReliaseItems",
  DONE = "doneItems",
  AT_RISK = "atRiskItems",
  NOT_DONE = "notDoneItems",
}

export const statusesConverter: Partial<Record<TStatuses, string>> = {
  [EVENT_STATUS.IN_PROGRESS]: "В реализации",
  [EVENT_STATUS.DONE]: "Выполнено",
  [EVENT_STATUS.AT_RISK]: "Под риском",
  [EVENT_STATUS.NOT_STARTED]: "Не начато",
  [EVENT_STATUS.NOT_ACHIEVED]: "Не достигнуто",
  [EVENT_STATUS.NOT_DONE]: "Не выполнено",
  [EVENT_STATUS.CRITICAL]: "ЧС",
  [EVENT_STATUS.OUT_DATE]: "Просрочено",
};

export const indicatorsStatusesConverter: Record<string, TStatuses> = {
  Выполнено: EVENT_STATUS.DONE,
  "Не начато": EVENT_STATUS.NOT_STARTED,
  "Не достигнуто": EVENT_STATUS.NOT_ACHIEVED,
};

export enum EVENT_CODE {
  IN_PROGRESS = "14",
  DONE = "16",
  AT_RISK = "17",
  NOT_STARTED = "18",
  NOT_DONE = "51",
  CRITICAL = "117",
}

// Важен порядок, он соответствует порядку отображения кнопок в карточке ГП
export const EVENT_STATUSES_DEFAULT: IEventStatuses = {
  [EVENT_STATUS.DONE]: 0,
  [EVENT_STATUS.NOT_DONE]: 0,
  [EVENT_STATUS.IN_PROGRESS]: 0,
  [EVENT_STATUS.NOT_STARTED]: 0,
  [EVENT_STATUS.AT_RISK]: 0,
  [EVENT_STATUS.CRITICAL]: 0,
};

export interface IEventStatuses {
  [EVENT_STATUS.IN_PROGRESS]: number;
  [EVENT_STATUS.DONE]: number;
  [EVENT_STATUS.AT_RISK]: number;
  [EVENT_STATUS.NOT_STARTED]: number;
  [EVENT_STATUS.NOT_DONE]: number;
  [EVENT_STATUS.CRITICAL]: number;
}

export type TEventStatus = keyof IEventStatuses;

export interface IIndicatorStatuses {
  [EVENT_STATUS.DONE]: number;
  [EVENT_STATUS.NOT_DONE]: number;
  [EVENT_STATUS.NOT_STARTED]: number;
}

export type TIndicatorStatus = keyof IIndicatorStatuses;

// TODO: свести всё к EVENT_STATUSES_DEFAULT после доработки бэка
export type ISEIndicatorStatuses = {
  [EVENT_STATUS.DONE]: number;
  [EVENT_STATUS.NOT_ACHIEVED]: number;
  [EVENT_STATUS.NOT_STARTED]: number;
};

// Важен порядок, он соответствует порядку отображения кнопок в карточке ГП
export const INDICATOR_STATUSES_DEFAULT = {
  [EVENT_STATUS.DONE]: {
    status: EVENT_STATUS.DONE,
    amount: 0,
  },
  [EVENT_STATUS.NOT_ACHIEVED]: {
    status: EVENT_STATUS.NOT_ACHIEVED,
    amount: 0,
  },
  [EVENT_STATUS.NOT_STARTED]: {
    status: EVENT_STATUS.NOT_STARTED,
    amount: 0,
  },
};

export type TSEIndicatorStatus = keyof ISEIndicatorStatuses;

export enum SUBJECT_STATUS {
  IN_PROGRESS = "inReliaseItems",
  DONE = "doneItems",
  AT_RISK = "atRiskItems",
  NOT_DONE = "notDoneItems",
  PLANNED = "plannedItems",
  IMPLEMENTED = "reliasedItems",
  NO_REPORTING = "noDataItems",
}

export type ISubjectStatuses = {
  [SUBJECT_STATUS.IN_PROGRESS]: number;
  [SUBJECT_STATUS.DONE]: number;
  [SUBJECT_STATUS.AT_RISK]: number;
  [SUBJECT_STATUS.NOT_DONE]: number;
  [SUBJECT_STATUS.PLANNED]: number;
  [SUBJECT_STATUS.IMPLEMENTED]: number;
  [SUBJECT_STATUS.NO_REPORTING]: number;
};

export const SUBJECT_STATUSES_DEFAULT = {
  [SUBJECT_STATUS.IN_PROGRESS]: 0,
  [SUBJECT_STATUS.DONE]: 0,
  [SUBJECT_STATUS.AT_RISK]: 0,
  [SUBJECT_STATUS.NOT_DONE]: 0,
  [SUBJECT_STATUS.PLANNED]: 0,
  [SUBJECT_STATUS.IMPLEMENTED]: 0,
  [SUBJECT_STATUS.NO_REPORTING]: 0,
};
export type TSubjectStatus = keyof ISubjectStatuses;
