import { useMemo } from "react";
import { getInfoRowsSEIndicators } from "SRC/helpers/getInfoRows";
import { useTableExportParams } from "SRC/hooks";
import { selectors, useAppSelector } from "SRC/redux/hooks";
import { IIndicator } from "SRC/redux/slices/structureElement/api";
import { useIndicators } from "SRC/redux/slices/structureElement/hooks";
import { IIndicators } from "SRC/redux/slices/structureElement/hooks/useIndicators";
import { IExportData } from "SRC/types";

export interface ITableDataItem {
  name?: string;
  id: number | string;
}

export interface IIndicatorTableItem extends ITableDataItem {
  unit?: string;
  level?: number;
  performance?: number;
  plan?: number;
  fact?: number;
}

export type TIndicatorExportItem = Omit<IIndicatorTableItem, "id">;

export const createIndicatorRow = ({
  info,
  data,
}: IIndicator): IIndicatorTableItem => ({
  id: info.code,
  name: info.name,

  unit: info.type,
  level: data[5471]?.sum || 0,
  performance: data[5244]?.sum || 0,
  plan: data[5240]?.sum || 0,
  fact: data[5241]?.sum || 0,
});

export const createIndicatorsTableData = (
  data: IIndicator[]
): IIndicatorTableItem[] =>
  data.map((elem: IIndicator) => createIndicatorRow(elem));

export interface IUseExtendedIndicators extends IIndicators {
  filtered: string[];
  rows: IIndicatorTableItem[];
  exports: IExportData<TIndicatorExportItem>;
}

export const useExtendedIndicators = (
  id: string | number
): IUseExtendedIndicators => {
  const createExportParams = useTableExportParams;
  const indicator = useIndicators(id);
  const filters = useAppSelector(selectors.seeFilters);

  const tableData = useMemo(
    () => createIndicatorsTableData(indicator.data),
    [indicator]
  );

  const filtered = useMemo(
    () =>
      indicator.data
        .filter((item) => {
          if (
            filters.selectedStructureElementType &&
            filters.selectedStructureElementType !== item.info.type
          )
            return false;
          return true;
        })
        .map((item) => item.info.code),
    [indicator, filters]
  );

  const rows = useMemo(
    () =>
      tableData
        .filter(({ id }) => filtered.includes(String(id)))
        .sort((a, b) => Number(a.id) - Number(b.id)),
    [tableData, filtered]
  );

  const exports = useMemo(() => {
    const download: TIndicatorExportItem[] = rows.map(
      ({ id, ...item }) => item
    );

    return {
      csvData: download,
      fileName: "Структурный элемент. Показатели",
      infoRows: getInfoRowsSEIndicators(),
      ...createExportParams(download, "seIndicators"),
    };
  }, [rows]);

  return {
    ...indicator,
    filtered,
    rows,
    exports,
  };
};
