import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getReports } from "SRC/redux/slices/gosprogram/api/client";
import { IWorkbookData } from "SRC/types";

export interface IReportParams {
  tabName: string;
  periodCode: string;
  isOm: boolean;
}

export interface IReportState {
  items: IWorkbookData | null;
  fetching: boolean;
  fetched: boolean;
  error: string;
}

export const extraActions = {
  get: createAsyncThunk(
    "report-file/get",
    async ({ tabName, periodCode, isOm }: IReportParams) =>
      await getReports(tabName, periodCode, isOm)
  ),
};

const initialState: IReportState = {
  items: null,
  fetching: false,
  fetched: false,
  error: "",
};

export const slice = createSlice({
  name: "reports",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActions.get.pending, (state) => {
        state.items = null;
        state.fetching = true;
        state.fetched = false;
      })
      .addCase(extraActions.get.fulfilled, (state: IReportState, action) => {
        // @ts-ignore
        state.items = action.payload.data.data?.[0]
          ?.data as IWorkbookData | null;
        state.fetching = false;
        state.fetched = true;
      })
      .addCase(extraActions.get.rejected, (state, action) => {
        state.fetching = false;
        // state.error = action.error;
      });
  },
});

export const actions = {
  ...slice.actions,
  ...extraActions,
};

export default slice;
