import { SxProps } from "@mui/material";
import Box from "@mui/material/Box";
import React, { useMemo } from "react";
import ProgressDoughnutFinancing from "SRC/components/Charts/ProgressDoughnutFinancing/ProgressDoughnutFinancing";
import { IncidentReport } from "SRC/components/IncidentReport";
import { OverlayBlock } from "SRC/components/OverlayBlock";
import { getPercentValue } from "SRC/helpers/getPercentValue";
import { getRoundValue } from "SRC/helpers/getRoundValue";
import { parseFloatNumber } from "SRC/helpers/parseFloatNumber";
import { useStatistics } from "SRC/redux/slices/gosprogram/hooks/useStatistics";

import { css, Wrapper } from "./FinancingInfo.styled";

interface IFinancingData {
  performance: string;
  fact: string;
  plan: string;
  reportData: string;
  budget: {
    total: string;
    done: string;
  };
  extrabudget: {
    total: string;
    done: string;
  };
  units: string;
}

export const FinancingInfo = () => {
  const { items: statistics, fetching } = useStatistics();

  const financingData: IFinancingData = useMemo(
    () => ({
      performance: getPercentValue(statistics["Финансирование УД"]),
      fact: getRoundValue(statistics["Финансирование факт"] || 0, 1),
      plan: getRoundValue(statistics["Финансирование план"] || 0, 1),
      reportData: statistics["Дата состояния финансирования"] || "",
      budget: {
        total: getRoundValue(statistics["Финансирование бюджет план"] || 0, 1),
        done: getRoundValue(statistics["Финансирование бюджет факт"] || 0, 1),
      },
      extrabudget: {
        total: getRoundValue(
          statistics["Финансирование внебюджет план"] || 0,
          1
        ),
        done: getRoundValue(
          statistics["Финансирование внебюджет факт"] || 0,
          1
        ),
      },
      units: "млрд. ₽",
    }),
    [statistics]
  );

  return (
    <Wrapper>
      <IncidentReport className="incident-container">
        <OverlayBlock isFetching={fetching} hasData={Boolean(statistics)}>
          <>
            <Box sx={css.title}>
              <div>Финансирование</div>
              <span>
                {financingData.reportData
                  ? `на ${financingData.reportData}`
                  : ""}
              </span>
            </Box>
            <Box sx={css.content}>
              <Box sx={css.contentLeft}>
                <Box sx={css.doughnut}>
                  <ProgressDoughnutFinancing
                    value={parseFloatNumber(financingData.fact)}
                    total={parseFloatNumber(financingData.plan)}
                    withDarkBgr={true}
                  />
                </Box>
                <Box sx={css.doughnutInfo}>
                  <Box sx={css.infoTop}>
                    <Box sx={css.point("fact")} />
                    {financingData.fact} <span>млрд ₽ /</span>
                    {financingData.performance !== "0" && (
                      <>
                        {financingData.performance}
                        <span>%</span>
                      </>
                    )}
                    {financingData.performance === "0" && (
                      <span className="no-value">0</span>
                    )}
                  </Box>
                  <Box sx={css.infoBottom}>
                    <Box sx={css.infoName}>
                      <Box sx={css.point("plan")} />
                      <Box>План</Box>
                    </Box>
                    <Box sx={css.infoName}>
                      <Box sx={css.point("fact")} />
                      <Box>Факт</Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box sx={css.contentRight as SxProps}>
                <Box sx={css.rightItem}>
                  <Box sx={css.itemTitle as SxProps}>
                    Бюджетных средств<span>{financingData.units}</span>
                  </Box>
                  <Box sx={css.itemInfoContainer}>
                    <Box sx={css.itemInfo}>
                      <Box sx={css.itemName}>План</Box>
                      <Box sx={css.itemValue}>{financingData.budget.total}</Box>
                    </Box>
                    <Box sx={css.itemInfo}>
                      <Box sx={css.itemName}>Факт</Box>
                      <Box sx={css.itemValue}>{financingData.budget.done}</Box>
                    </Box>
                  </Box>
                </Box>
                <Box sx={css.rightItem}>
                  <Box sx={css.itemTitle as SxProps}>
                    Внебюджетных средств<span>{financingData.units}</span>
                  </Box>
                  <Box sx={css.itemInfoContainer}>
                    <Box sx={css.itemInfo}>
                      <Box sx={css.itemName}>План</Box>
                      <Box sx={css.itemValue}>
                        {financingData.extrabudget.total}
                      </Box>
                    </Box>
                    <Box sx={css.itemInfo}>
                      <Box sx={css.itemName}>Факт</Box>
                      <Box sx={css.itemValue}>
                        {financingData.extrabudget.done}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </>
        </OverlayBlock>
      </IncidentReport>
    </Wrapper>
  );
};
