import React from "react";
import { CustomCheckbox } from "SRC/components/CustomCheckbox/CustomCheckbox";
import { useGlobalFilters } from "SRC/redux/slices/global/hooks/useFilters";

const LABEL = "Участвует в УД";

export const InAchievementCheckbox = () => {
  const { inPerformance } = useGlobalFilters();
  const { toggleInAchievement } = useGlobalFilters();

  return (
    <CustomCheckbox
      onChange={toggleInAchievement}
      label={LABEL}
      checked={inPerformance}
    />
  );
};
