import { Theme } from "@mui/material";

export const cssModal = {
  modalBox: (theme: Theme) => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "30rem",
    borderRadius: "0.75rem",
    border: `1px solid ${theme.palette.divider}`,
    overflow: "hidden",
    boxShadow: `0px 0px 5rem 5rem ${theme.palette.background.defaultTransparent}`,
    cursor: "move",
    [theme.breakpoints.up("md")]: {
      maxWidth: theme.breakpoints.values.md,
      minWidth: "unset",
      width: "30rem",
    },
    background: theme.palette.background.default,
  }),
  modalHeader: {
    justifyContent: "space-between",
    alignItems: "baseline",
    padding: "1.5rem 2rem 1rem 2rem",
    background: (theme: Theme) => theme.palette.background.paper,
  },
  modalName: {
    fontSize: "1.5rem",
    color: (theme: Theme) => theme.palette.primary.contrastText,
    fontWeight: 400,
  },
  closeButton: {
    cursor: "pointer",
  },
  modalFooter: {
    justifyContent: "flex-end",
    alignItems: "baseline",
    gap: "1rem",
    padding: "1.5rem 2rem 1.5rem 2rem",
    background: (theme: Theme) => theme.palette.background.paper,
  },
  button: {
    fontSize: "1rem",
    lineHeight: "1rem",
    color: (theme: Theme) => theme.palette.primary.contrastText,
    background: (theme: Theme) => theme.palette.charts.grayBlue,
    textTransform: "none",
    padding: "0.8rem 1.2rem",
    fontWeight: "0rem !important",
  },
  backdrop: { backgroundColor: "transparent" },
  selectorsContainer: {
    padding: "24px",
  },
};
