import { styled, Theme } from "@mui/material";
import { selectorMixin } from "SRC/components/FilterForms/MainHeaderFilter/MainHeaderFilter.styled";

export const Wrapper = styled("div")(() => {
  return {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    overflowY: "hidden",
    overflowX: "visible",
    padding: "0 1rem",
    gap: "1rem",

    "& > .controls-panel": {
      display: "flex",
      gap: "1rem",
      alignItems: "baseline",

      "& > .filters": {
        display: "flex",
        flex: "1",
        justifyContent: "flex-end",
      },
    },

    ".events-tab-incident": {
      button: {
        right: "1rem",
      },
    },
  };
});

export const css = {
  selector: selectorMixin,
  searchSelectContainer: {
    display: "flex",
    gap: "1rem",
  },
  statusCardContainer: {
    display: "flex",
    gap: "1rem",
    flex: "0 1 auto",
  },
  filterCheckboxWrapper: {
    height: "2.5rem",
    userSelect: "none",
  },
  filterButton: {
    color: (theme: Theme) => theme.palette.primary.contrastText,
    background: (theme: Theme) => theme.palette.complementary.darkGrayBlue,
    borderRadius: "0.5rem",
    fontSize: "1rem !important",
    fontWeight: "400",
    textTransform: "none",
    height: "2.5rem",
    padding: "0.6rem 1.125rem",
  },
  modalContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    overflowY: "hidden",
    overflowX: "visible",
    padding: "0 1rem",
    gap: "1rem",

    "& > .controls-panel": {
      display: "flex",
      gap: "1rem",
      alignItems: "baseline",

      "& > .filters": {
        display: "flex",
        flex: "1",
        justifyContent: "flex-end",
      },
    },
  },
};
