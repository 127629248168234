import React from "react";
import { Wrapper } from "SRC/components/NavigateToPriorities/NavigateToPriorities.styled";
import { useFilters } from "SRC/redux/slices/priorities/hooks/useFilters";
import { EGridTabs } from "SRC/redux/slices/priorities/slices/filters";

interface ICellPriorities {
  children: React.ReactNode;
  isSmall?: boolean;
}

const NavigateToPriorities = ({ children }: ICellPriorities) => {
  const { setSelectedComplexGridTab } = useFilters();
  const changeTab = () => {
    setSelectedComplexGridTab(EGridTabs.PRIORITIES);
  };
  return <Wrapper onClick={changeTab}>{children}</Wrapper>;
};

export default NavigateToPriorities;
