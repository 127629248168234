import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createPeriodDims,
  IParsedItem,
  parseOlapdata,
  TDecoder,
} from "CORE/utils";
import { isContainedArray } from "SRC/helpers/dataTesters";
import { getSubjects } from "SRC/redux/slices/event/api/client";
import { IEventParams } from "SRC/types";

const decoder: TDecoder = {
  period: createPeriodDims(1405),
  info: {
    code: 1414,
    dimensions: {
      name: "NAME",
      code: "DICT_CODE",
      region: "REGION_CODE",
      url: "URL",
    },
  },
};

export interface ISubjectInfo {
  name: string;
  code: string;
  region: string;
  url?: string;
}

export interface ISubject extends IParsedItem {
  info: ISubjectInfo;
}

export interface ISubjectsState {
  items: ISubject[];
  fetching: boolean;
  fetched: boolean;
  error: string;
}

export const extraActions = {
  get: createAsyncThunk(
    "subjects/get",
    async (params: IEventParams) => await getSubjects(params)
  ),
};

const initialState: ISubjectsState = {
  items: [],
  fetching: false,
  fetched: false,
  error: "",
};

const slice = createSlice({
  name: "subjects",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActions.get.pending, (state) => {
        state.items = [];
        state.fetching = true;
        state.fetched = false;
      })
      .addCase(extraActions.get.fulfilled, (state: ISubjectsState, action) => {
        const parsed = parseOlapdata(
          // @ts-ignore
          action.payload.data,
          decoder
        ) as unknown as ISubject[];
        state.items = isContainedArray(parsed) ? parsed : [];
        state.fetching = false;
        state.fetched = true;
      })
      .addCase(extraActions.get.rejected, (state, action) => {
        state.fetching = false;
      });
  },
});

export const actions = {
  ...slice.actions,
  ...extraActions,
};

export default slice;
