import { styled } from "@mui/material";

export const Wrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  " > .title": {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "2rem",
    marginBottom: "1.75rem",
  },
  ".head": {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: "1.5rem",
  },
  ".title-text": {
    marginBottom: "1.5rem",
    "& > span": {
      verticalAlign: "top",
      fontSize: "1.25rem",
      color: theme.palette.divider,
    },
  },
  ".buttons-container": {
    flex: "0 0 auto",
    height: "2.5rem",
    width: "fit-content",
    margin: "0.5rem 0 1rem 0",
    background: theme.palette.complementary.darkBlue,
    borderRadius: "0.5rem",

    "& > button": {
      flex: "0 0 auto",
      height: "2rem",
      padding: "0.5rem 1rem 0.5rem 1rem !important",
      fontWeight: "normal",
      fontSize: "1rem",
      textTransform: "none",
      color: theme.palette.text.onAccent,
      borderRadius: "0.5rem",
    },
  },
}));
