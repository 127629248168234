import { Grid, Stack, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { DescriptionItem } from "SRC/components/DescriptionItem/DescriptionItem";
import { OverlayBlock } from "SRC/components/OverlayBlock";
import { useIndicatorData } from "SRC/redux/slices/indicator/hooks/useIndicatorData";

import { css } from "./Description.styled";

interface ICol {
  label: string;
  value?: number | string;
  flex?: boolean;
}

export const Description = () => {
  const { items: data, fetching: loading } = useIndicatorData();

  const cols: JSX.Element[] = useMemo(() => {
    const typeIndicator = Boolean(data?.data[5277]?.text)
      ? "Возрастающий"
      : "Убывающий";
    return [
      {
        label: "Ответственный",
        value: "–",
        flex: true,
      },
      {
        label: "Федеральный проект",
        value: data?.seGpType.name || "–",
        flex: true,
      },
      {
        label: "Уровень показателя",
        value: data?.status.name || "–",
        flex: true,
      },
      {
        label: "Тип показателя",
        value: typeIndicator || "–",
      },
      {
        label: "Единица измерения",
        value: data?.description.unit || "–",
      },
    ].map(({ flex, label, value }: ICol) => (
      <DescriptionItem label={label} value={value} flex={flex} />
    ));
  }, [data]);
  return (
    <OverlayBlock isFetching={loading} hasData={Boolean(cols.length)}>
      <Grid container sx={css.container}>
        {cols}
      </Grid>
    </OverlayBlock>
  );
};
