import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { isContainedArray } from "SRC/helpers/dataTesters";
import { getStatistics } from "SRC/redux/slices/gosprogram/api/client";
import { IEventParams } from "SRC/types";

export interface IStatistics extends Record<string, number | string> {
  fp_res_complete_om_perc: number;
  dp_res_complete_om_perc: number;
  cpm_res_complete_om_perc: number;
  res_complete_om_perc: number;
  "Количество ГП": number;
  "Мероприятий всего": number;
  "Создано объектов": number;
  "Уровень достижения": number;
  "Уровень достижения 2": number;
  "Объектов под риском": number;
  "Опросы населения, да": number;
  "Опросы населения, нет": number;
  "Мероприятий выполнено": number;
  "Мероприятий выполнено, %": number;
  "Создано объектов, план": number;
  "Создано объектов, факт": number;
  "Создано объектов, %": number;
  "Мероприятий под риском": number;
  "Мероприятий не выполнено": number;
  "Количество показателей": number;
  "Количество показателей, выполнено": number;
  "Количество показателей, выполнено %": number;
  "Количество показателей 2, выполнено %": number;
  "Мероприятия под риском, %": number;
  "Нормативно правовые акты, %": number;
  "Нормативно правовые акты, под риском": number;
  "Структурные элементы, всего": number;
  "Федеральных проектов, кол-во": number;
  "Ведомственных проектов, кол-во": number;
  "Нормативно-правовые акты, план": number;
  "Нормативно-правовые акты, факт": number;
  "Выполнение мероприятий ВП, план": number;
  "Выполнение мероприятий ФП, план": number;
  "Выполнение мероприятий КПМ, план": number;
  "Выполнение мероприятий ВП, факт": number;
  "Выполнение мероприятий ФП, факт": number;
  "Выполнение мероприятий КПМ, факт": number;
  "Выполнение мероприятий ВП, %": number;
  "Выполнение мероприятий ВП 2, %": number;
  "Выполнение мероприятий ФП, %": number;
  "Выполнение мероприятий ФП 2, %": number;
  "Выполнение мероприятий КПМ, %": number;
  "Выполнение мероприятий КПМ 2, %": number;
  "Комплексы процессных мероприятий, кол-во": number;
  "Структурные элементы, %": number;
  "Структурные элементы 2, %": number;
  "Количество выполненных мероприятий ФП 2, %": number;
  "Количество показателей 2 ОМ, выполнено %": number;
  "Количество выполненных мероприятий ФП, %": number;
  "Дата состояния финансирования": string;
  "Финансирование УД": number;
  "Финансирование план": number;
  "Финансирование факт": number;
  "Финансирование под риском": number;
  "Финансирование бюджет план": number;
  "Финансирование бюджет факт": number;
  "Финансирование под риском, %": number;
  "Финансирование внебюджет план": number;
  "Финансирование внебюджет факт": number;
}

export interface IStatisticsState {
  items: IStatistics;
  fetching: boolean;
  fetched: boolean;
  error: string;
}

export const extraActions = {
  get: createAsyncThunk(
    "statistics/get",
    async (params: IEventParams) => await getStatistics(params)
  ),
};

const initialState: IStatisticsState = {
  items: {} as IStatistics,
  fetching: false,
  fetched: false,
  error: "",
};

const slice = createSlice({
  name: "statistics",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActions.get.pending, (state) => {
        state.items = {} as IStatistics;
        state.fetching = true;
        state.fetched = false;
      })
      .addCase(
        extraActions.get.fulfilled,
        (state: IStatisticsState, action) => {
          const data = action.payload.data;
          // TODO [пофиксить]: проблема с интерфейсами запросов
          state.items =
            // @ts-ignore
            (isContainedArray(data) ? data[0]?.data || {} : {}) as IStatistics;
          state.fetching = false;
          state.fetched = true;
        }
      )
      .addCase(extraActions.get.rejected, (state, action) => {
        state.fetching = false;
        // state.error = action.error;
      });
  },
});

export const actions = {
  ...slice.actions,
  ...extraActions,
};

export default slice;
