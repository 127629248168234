import classnames from "classnames";
import React from "react";
import { getColorTextGreen } from "SRC/components/PerformanceValue/utils";
import { getPercentValue } from "SRC/helpers/getPercentValue";
import { useGlobalFilters } from "SRC/redux/slices/global/hooks/useFilters";

import { WrapperPerformance } from "./PerformanceValue.styled";

type TPerformanceValueProps = {
  value: string;
  sameSize: boolean;
  className?: string;
};

export const PerformanceValue = ({
  value,
  sameSize,
  className = "",
}: TPerformanceValueProps) => {
  const { sources } = useGlobalFilters();

  const [integerPart, fractionPart] = String(getPercentValue(value)).split(
    /\D/
  );

  return (
    <WrapperPerformance
      className={classnames(
        "performance-info",
        getColorTextGreen(
          Number(`${integerPart}.${fractionPart ?? 0}`),
          sources.OM
        ),
        {
          [className]: !!className,
        }
      )}
    >
      {integerPart}
      {fractionPart && (
        <span className={classnames({ "fraction-part": !sameSize })}>
          ,{fractionPart}
        </span>
      )}
    </WrapperPerformance>
  );
};
