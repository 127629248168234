import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { isContainedArray } from "SRC/helpers/dataTesters";
import {
  INationalGoals,
  IStateProgramIndicators,
} from "SRC/pages/Program/common/GoalsTab/common/NationalGoalsConnect/interfaces";
import { getGoalConnect } from "SRC/redux/slices/gosprogram/api/client";

export interface IGoalConnect {
  Mapping: null;
  NationalGoals: INationalGoals[] | null;
  StateProgramIndicators: IStateProgramIndicators[] | null;
}

export interface IGoalConnectState {
  items: IGoalConnect;
  fetching: boolean;
  fetched: boolean;
  error: string;
}

export const extraActions = {
  get: createAsyncThunk(
    "goalConnect/get",
    async (id: string | number) => await getGoalConnect(id)
  ),
};

const initItems = {
  Mapping: null,
  NationalGoals: null,
  StateProgramIndicators: null,
};

const initialState: IGoalConnectState = {
  items: initItems,
  fetching: false,
  fetched: false,
  error: "",
};

const slice = createSlice({
  name: "goalConnect",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActions.get.pending, (state) => {
        state.items = initItems;
        state.fetching = true;
        state.fetched = false;
      })
      .addCase(
        extraActions.get.fulfilled,
        (state: IGoalConnectState, action) => {
          const data = action.payload.data?.[0]?.data?.data;
          state.items = isContainedArray(data)
            ? data[0] || initItems
            : initItems;
          state.fetching = false;
          state.fetched = true;
        }
      )
      .addCase(extraActions.get.rejected, (state, action) => {
        state.fetching = false;
      });
  },
});

export const actions = {
  ...slice.actions,
  ...extraActions,
};

export default slice;
