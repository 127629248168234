import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import {
  IGlobalFilters,
  useGlobalFilters,
} from "SRC/redux/slices/global/hooks/useFilters";
import { useMethods } from "SRC/redux/slices/global/hooks/useMethods";
import { usePremiers } from "SRC/redux/slices/summary/hooks/usePremiers";

export interface IMainState {
  loading: boolean;
  loaded: boolean;
  isPrepared: boolean;
  init(): void;
  hasError: any;
}

export const useGlobalState = (): IMainState => {
  const [isPrepared, setIsPrepared] = useState(false);
  const { period, setSelectedPeriod }: IGlobalFilters = useGlobalFilters();
  const {
    fetching: loadingMethods,
    load: loadMethods,
    fetched: loadedMethods,
    error: errorMethods,
    items,
  } = useMethods();

  const {
    load: loadVP,
    fetching: loadingVP,
    fetched: loadedVP,
    error: errorVP,
  } = usePremiers();

  const loading = loadingMethods && loadingVP;
  const loaded = loadedMethods && loadedVP;
  const hasError = errorMethods || errorVP;

  const init = useCallback(() => {
    if (!loading) {
      loadMethods();
      loadVP(period);
    }
  }, [loading, period]);

  useEffect(() => {
    if (!loading && loaded && !isPrepared) {
      let year: number = Number(items?.max_year || 0);

      if (!year) {
        year = moment().year() - 1;

        console.error("Ошибка при получении года по умолчанию");
      }

      setSelectedPeriod(moment().year(year).toDate());
      setTimeout(() => setIsPrepared(true), 0);
    }
  }, [loading, loaded]);

  return {
    loading,
    loaded,
    init,
    hasError,
    isPrepared,
  };
};
