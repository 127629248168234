export interface IPrioritiesData {
  class_kpi: string;
  attribute: string;
  kpi_guid: string;
  group: string;
  programm_guid: string;
  programm_name: string;
  kpi_section: string;
  kpi: string;
  kpi_measure: string;
  name: string;
  r1?: string | number;
  r2?: string | number;
  r3?: string | number;
  r4?: string | number;
  r5?: string | number;
  r6?: string | number;
  r13?: string | number;
  r14?: string | number;
  r15?: string | number;
  calc_period: string;
}

export interface IPrioritiesDataMock {
  name: string;
  nationalGoal: string;
  govProgram: string;
  vicePremier: string;
  unit: string;
  resultPlan: number;
  resultFact: number;
  performance: number;
  resPeriodPlan: number;
  resPeriodFact: number;
  resAchievement: number;
}

export const prioritiesData2: IPrioritiesDataMock[] = [
  {
    name: "Уровень регистрируемой безработицы",
    nationalGoal: "Сохранение населения, здоровья и благополучия людей",
    govProgram: "Содействие занятости населения",
    vicePremier: "Голикова Т.А.",
    unit: "%",
    resultPlan: 100,
    resultFact: 35,
    performance: 56,
    resPeriodPlan: 12,
    resPeriodFact: 24,
    resAchievement: 13,
  },
  {
    name: "Уровень регистрируемой безработицы",
    nationalGoal: "Сохранение населения, здоровья и благополучия людей",
    govProgram: "Содействие занятости населения",
    vicePremier: "Голикова Т.А.",
    unit: "%",
    resultPlan: 46,
    resultFact: 23,
    performance: 23,
    resPeriodPlan: 12,
    resPeriodFact: 78,
    resAchievement: 23,
  },
  {
    name: "Уровень регистрируемой безработицы",
    nationalGoal: "Сохранение населения, здоровья и благополучия людей",
    govProgram: "Содействие занятости населения",
    vicePremier: "Голикова Т.А.",
    unit: "%",
    resultPlan: 56,
    resultFact: 14,
    performance: 76,
    resPeriodPlan: 35,
    resPeriodFact: 3,
    resAchievement: 24,
  },
];

// {
//   class_kpi: "Показатель",
//       attribute: "ФП",
//     kpi_guid: "8689493801",
//     group: "ГП",
//     programm_guid: "90",
//     programm_name: "Развитие Калининградской области", //govProgram
//     kpi_section: "ЭКОНОМИЧЕСКОЕ РАЗВИТИЕ",
//     kpi: "Созданные и сохраненные рабочие места по соглашениям с предпринимателями", //name
//     kpi_measure: "тыс. ед.", //unit
//     name: "Татьяна Алексеевна Голикова", //vicePremier
//     r1: 6302, //resultPlan
//     r2: "-", //resultFact
//     r3: "-", //performance
//     r4: 6078, //resPeriodPlan
//     r5: "13727", //resPeriodFact
//     r6: "100", //resPeriodAchievement
//     r13: 6369,
//     r14: "12112",
//     r15: "100",
//     calc_period: "2023-02",
// },

export const prioritiesData: IPrioritiesData[] = [
  {
    class_kpi: "Показатель",
    attribute: "ФП",
    kpi_guid: "8694811800",
    group: "ГП",
    programm_guid: "97",
    programm_name: "Комплексное развитие сельских территорий",
    kpi_section: "ЧЕЛОВЕЧЕСКИЙ ПОТЕНЦИАЛ СЕЛЬСКИХ ТЕРРИТОРИЙ",
    kpi: "Меньше безработицы в селах",
    kpi_measure: "%",
    name: "Абрамченко В.В.",
    r1: 52.900001525878906,
    r2: "-",
    r3: "-",
    r4: 52.599998474121094,
    r5: "53.2000007629394531",
    r6: "100",
    r13: 52.70000076293945,
    r14: "0",
    r15: "0",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ФП",
    kpi_guid: "8831363311",
    group: "ГП",
    programm_guid: "116",
    programm_name: "Развитие рыбохозяйственного комплекса",
    kpi_section: "ПРОИЗВОДСТВО РЫБНОЙ ПРОДУКЦИИ",
    kpi: "Производство рыбной продукции глубокой степени переработки",
    kpi_measure: "тыс. т",
    name: "Абрамченко В.В.",
    r1: 512.280029296875,
    r2: "-",
    r3: "-",
    r4: 0,
    r5: "-",
    r6: "-",
    r13: 504.010009765625,
    r14: "0",
    r15: "0",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ФП",
    kpi_guid: "8498614671",
    group: "ГП",
    programm_guid: "93",
    programm_name: "Развитие лесного хозяйства",
    kpi_section: "ВОВЛЕЧЕННОСТЬ НАСЕЛЕНИЯ",
    kpi: "Вовлечено в мероприятия по охране лесов",
    kpi_measure: "млн. чел.",
    name: "Абрамченко В.В.",
    r1: 1.5,
    r2: "-",
    r3: "-",
    r4: 1,
    r5: "1.12650001049041748",
    r6: "100",
    r13: 1.5,
    r14: "0",
    r15: "0",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ФП",
    kpi_guid: "8503073524",
    group: "ГП",
    programm_guid: "118",
    programm_name: "Воспроизводство и использование природных ресурсов",
    kpi_section: "ВОССТАНОВЛЕНИЕ ВОДНЫХ ОБЪЕКТОВ",
    kpi: "Загрязняющие сбросы в Волгу",
    kpi_measure: "км3",
    name: "Абрамченко В.В.",
    r1: 1.0499999523162842,
    r2: "-",
    r3: "-",
    r4: 2.423099994659424,
    r5: "2.20099997520446777",
    r6: "100",
    r13: 1.8688000440597534,
    r14: "2.20099997520446777",
    r15: "74.4697261823519199",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ФП",
    kpi_guid: "8502676517",
    group: "ГП",
    programm_guid: "118",
    programm_name: "Воспроизводство и использование природных ресурсов",
    kpi_section: "ВОССТАНОВЛЕНИЕ ВОДНЫХ ОБЪЕКТОВ",
    kpi: "Расчистка водных объектов",
    kpi_measure: "тыс. га",
    name: "Абрамченко В.В.",
    r1: 23.5,
    r2: "17.2189999",
    r3: "73.2723414897918701",
    r4: 1.570399284362793,
    r5: "4.38939952850341797",
    r6: "100",
    r13: 0.08099937438964844,
    r14: "0",
    r15: "0",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ФП",
    kpi_guid: "8674164857",
    group: "ГП",
    programm_guid: "118",
    programm_name: "Воспроизводство и использование природных ресурсов",
    kpi_section: "НОВЫЕ ИСТОЧНИКИ ПРИРОДНЫХ РЕСУРСОВ",
    kpi: "Новые перспективные площади с полезными ископаемыми",
    kpi_measure: "ед.",
    name: "Абрамченко В.В.",
    r1: 22,
    r2: "-",
    r3: "-",
    r4: 0,
    r5: "0",
    r6: "100",
    r13: 0,
    r14: "0",
    r15: "100",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ФП",
    kpi_guid: "8674241011",
    group: "ГП",
    programm_guid: "118",
    programm_name: "Воспроизводство и использование природных ресурсов",
    kpi_section: "НОВЫЕ ИСТОЧНИКИ ПРИРОДНЫХ РЕСУРСОВ",
    kpi: "Новые запасы питьевых подземных вод",
    kpi_measure: "тыс. м3 в сутки",
    name: "Абрамченко В.В.",
    r1: 10,
    r2: "-",
    r3: "-",
    r4: 0,
    r5: "0",
    r6: "100",
    r13: 0,
    r14: "0",
    r15: "100",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ФП",
    kpi_guid: "8705186282",
    group: "ГП",
    programm_guid: "110",
    programm_name:
      "Государственная программа развития сельского хозяйства и регулирования рынков сельскохозяйственной продукции, сырья и продовольствия",
    kpi_section: "ПРОИЗВОДСТВО",
    kpi: "Импортозамещение в АПК",
    kpi_measure: "%",
    name: "Абрамченко В.В.",
    r1: 102.0999984741211,
    r2: "-",
    r3: "-",
    r4: 100.9000015258789,
    r5: "100.900001525878906",
    r6: "100",
    r13: 101.30000305175781,
    r14: "0",
    r15: "0",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ФП",
    kpi_guid: "8685740797",
    group: "ГП",
    programm_guid: "103",
    programm_name:
      "Государственная программа эффективного вовлечения в оборот земель сельскохозяйственного назначения и развития мелиоративного комплекса Российской Федерации",
    kpi_section: "РАЗВИТИЕ И СОХРАНЕНИЕ ЗЕМЕЛЬНОГО ФОНДА",
    kpi: "Вовлеченные в оборот с/х земли за счет культуртехники",
    kpi_measure: "%",
    name: "Абрамченко В.В.",
    r1: 7.382999897003174,
    r2: "0",
    r3: "0",
    r4: 3.2669999599456787,
    r5: "3.26699995994567871",
    r6: "100",
    r13: 2.05400013923645,
    r14: "-3.26699995994567871",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ФП",
    kpi_guid: "8700353529",
    group: "ГП",
    programm_guid: "115",
    programm_name: "Развитие транспортной системы",
    kpi_section: "ОБЕСПЕЧЕНИЕ ТРАНСПОРТНОЙ ДОСТУПНОСТИ",
    kpi: "Обеспечение авиационной связности территорий: доля регулярных пассажирских авиарейсов, минуя Москву",
    kpi_measure: "%",
    name: "Белоусов А.Р.",
    r1: 51.349998474121094,
    r2: "-",
    r3: "-",
    r4: 46.5,
    r5: "50",
    r6: "100",
    r13: 48.900001525878906,
    r14: "0",
    r15: "0",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ФП",
    kpi_guid: "8685740798",
    group: "ГП",
    programm_guid: "103",
    programm_name:
      "Государственная программа эффективного вовлечения в оборот земель сельскохозяйственного назначения и развития мелиоративного комплекса Российской Федерации",
    kpi_section: "РАЗВИТИЕ И СОХРАНЕНИЕ ЗЕМЕЛЬНОГО ФОНДА",
    kpi: "С/х земли с проведенными гидромелиоративными мероприятиями",
    kpi_measure: "%",
    name: "Абрамченко В.В.",
    r1: 7.875999927520752,
    r2: "0",
    r3: "0",
    r4: 3.7690000534057617,
    r5: "3.76900005340576172",
    r6: "100",
    r13: 2.059000015258789,
    r14: "-3.76900005340576172",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ФП",
    kpi_guid: "8681924805",
    group: "ГП",
    programm_guid: "115",
    programm_name: "Развитие транспортной системы",
    kpi_section: "ОБЕСПЕЧЕНИЕ ТРАНСПОРТНОЙ ДОСТУПНОСТИ",
    kpi: "Количество пассажиров перевезенных железнодорожным транспортом в дальнем следовании в регулируемом сегменте",
    kpi_measure: "млн чел.",
    name: "Белоусов А.Р.",
    r1: 60.70000076293945,
    r2: "-",
    r3: "-",
    r4: 55.70000076293945,
    r5: "55.4300003051757812",
    r6: "99.5152592658996582",
    r13: 57.29999923706055,
    r14: "9.02000045776367188",
    r15: "15.7417118549346924",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ФП",
    kpi_guid: "8497084403",
    group: "ГП",
    programm_guid: "115",
    programm_name: "Развитие транспортной системы",
    kpi_section: "ОБЕСПЕЧЕНИЕ ТРАНСПОРТНОЙ ДОСТУПНОСТИ",
    kpi: "Провозная способность Восточного полигона железных дорог",
    kpi_measure: "млн т",
    name: "Белоусов А.Р.",
    r1: 180,
    r2: "158",
    r3: "87.7777755260467529",
    r4: 14,
    r5: "14",
    r6: "100",
    r13: 15,
    r14: "0",
    r15: "0",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ФП",
    kpi_guid: "8506197001",
    group: "ГП",
    programm_guid: "115",
    programm_name: "Развитие транспортной системы",
    kpi_section: "ОБЕСПЕЧЕНИЕ ТРАНСПОРТНОЙ ДОСТУПНОСТИ",
    kpi: "Обновление общественного транспорта",
    kpi_measure: "%",
    name: "Белоусов А.Р.",
    r1: 11.390000343322754,
    r2: "3.79999995",
    r3: "33.3625972270965576",
    r4: 0.8599998950958252,
    r5: "1.59999990463256836",
    r6: "100",
    r13: 3.330000162124634,
    r14: "0",
    r15: "0",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ФП",
    kpi_guid: "8496695343",
    group: "ГП",
    programm_guid: "115",
    programm_name: "Развитие транспортной системы",
    kpi_section: "ОБЕСПЕЧЕНИЕ ТРАНСПОРТНОЙ ДОСТУПНОСТИ",
    kpi: "Прирост производственной мощности морских портов",
    kpi_measure: "млн т",
    name: "Белоусов А.Р.",
    r1: 212.89999389648438,
    r2: "88.4100037",
    r3: "41.5265411138534546",
    r4: 69.31000328063965,
    r5: "69.3100032806396484",
    r6: "100",
    r13: 59.28999328613281,
    r14: "0",
    r15: "0",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ФП",
    kpi_guid: "8498654489",
    group: "ГП",
    programm_guid: "127",
    programm_name: "Экономическое развитие и инновационная экономика",
    kpi_section: "ПРОИЗВОДИТЕЛЬНОСТЬ ТРУДА",
    kpi: "Предприятия, получившие поддержку по совершенствованию производственного процесса",
    kpi_measure: "шт.",
    name: "Белоусов А.Р.",
    r1: 6057,
    r2: "4487",
    r3: "74.0795791149139404",
    r4: 537,
    r5: "1269",
    r6: "100",
    r13: 310,
    r14: "61",
    r15: "19.6774193548387117",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ФП",
    kpi_guid: "8741819079",
    group: "ГП",
    programm_guid: "127",
    programm_name: "Экономическое развитие и инновационная экономика",
    kpi_section: "РАЗВИТИЕ МСП",
    kpi: "Численность занятых в МСП",
    kpi_measure: "млн чел.",
    name: "Белоусов А.Р.",
    r1: 24.5,
    r2: "29113474",
    r3: "100",
    r4: 3.1099987030029297,
    r5: "27312116.7999992371",
    r6: "100",
    r13: 0,
    r14: "1801338",
    r15: "100",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ФП",
    kpi_guid: "8744370975",
    group: "ГП",
    programm_guid: "87",
    programm_name: "Информационное общество",
    kpi_section: "ДОСТУП К ИНТЕРНЕТУ",
    kpi: "Покрытие федеральных дорог сетями LTE",
    kpi_measure: "%",
    name: "Чернышенко Д.Н.",
    r1: 80,
    r2: "64",
    r3: "80.0000011920928955",
    r4: 10,
    r5: "24",
    r6: "100",
    r13: 1,
    r14: "0",
    r15: "0",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ФП",
    kpi_guid: "8689493801",
    group: "ГП",
    programm_guid: "90",
    programm_name: "Развитие Калининградской области", //govProgram
    kpi_section: "ЭКОНОМИЧЕСКОЕ РАЗВИТИЕ",
    kpi: "Созданные и сохраненные рабочие места по соглашениям с предпринимателями", //name
    kpi_measure: "тыс. ед.", //unit
    name: "Татьяна Алексеевна Голикова", //vicePremier
    r1: 6302, //resultPlan
    r2: "-", //resultFact
    r3: "-", //performance
    r4: 6078, //resPeriodPlan
    r5: "13727", //resPeriodFact
    r6: "100", //resPeriodAchievement
    r13: 6369,
    r14: "12112",
    r15: "100",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ФП",
    kpi_guid: "8699816122",
    group: "ГП",
    programm_guid: "90",
    programm_name: "Развитие Калининградской области",
    kpi_section: "БЕРЕГОУКРЕПЛЕНИЕ",
    kpi: "Протяженность защищаемого берега",
    kpi_measure: "км",
    name: "Татьяна Алексеевна Голикова",
    r1: 20230.900390625,
    r2: "15998",
    r3: "79.0770530700683594",
    r4: 2277,
    r5: "2277",
    r6: "100",
    r13: 1656,
    r14: "58",
    r15: "3.50241545893719852",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ФП",
    kpi_guid: "8743306369",
    group: "ГП",
    programm_guid: "123",
    programm_name: "Социальная поддержка граждан",
    kpi_section: "СОЦИАЛЬНОЕ ОБЕСПЕЧЕНИЕ ГРАЖДАН",
    kpi: "Доля граждан, выразивших свое согласие, получающих информирование о доступных мерах поддержки проактивно",
    kpi_measure: "%",
    name: "Татьяна Алексеевна Голикова",
    r1: 50,
    r2: "-",
    r3: "-",
    r4: 40,
    r5: "92",
    r6: "100",
    r13: 45,
    r14: "0",
    r15: "0",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ФП",
    kpi_guid: "8674104268",
    group: "ГП",
    programm_guid: "106",
    programm_name: "Доступная среда",
    kpi_section: "РЕАБИЛИТАЦИЯ И АБИЛИТАЦИЯ ИНВАЛИДОВ",
    kpi: "Охват нуждающихся взрослых-инвалидов реабилитацией",
    kpi_measure: "%",
    name: "Татьяна Алексеевна Голикова",
    r1: 90,
    r2: "-",
    r3: "-",
    r4: 80,
    r5: "80",
    r6: "100",
    r13: 85,
    r14: "0",
    r15: "0",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ФП",
    kpi_guid: "8674104269",
    group: "ГП",
    programm_guid: "106",
    programm_name: "Доступная среда",
    kpi_section: "РЕАБИЛИТАЦИЯ И АБИЛИТАЦИЯ ИНВАЛИДОВ",
    kpi: "Охват нуждающихся детей-инвалидов реабилитацией",
    kpi_measure: "%",
    name: "Татьяна Алексеевна Голикова",
    r1: 94,
    r2: "-",
    r3: "-",
    r4: 84,
    r5: "84",
    r6: "100",
    r13: 89,
    r14: "0",
    r15: "0",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ФП",
    kpi_guid: "8684922769",
    group: "ГП",
    programm_guid: "100",
    programm_name: "Развитие культуры",
    kpi_section: "РАЗВИТИЕ ТВОРЧЕСКОГО ПОТЕНЦИАЛА",
    kpi: "Мероприятия, направленные на духовно-нравственное и патриотическое воспитание и укрепление единства нации",
    kpi_measure: "ед.",
    name: "Татьяна Алексеевна Голикова",
    r1: 289,
    r2: "178",
    r3: "61.5916967391967773",
    r4: 16,
    r5: "16",
    r6: "100",
    r13: 105,
    r14: "49",
    r15: "46.6666666666666643",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ФП",
    kpi_guid: "8508173432",
    group: "ГП",
    programm_guid: "100",
    programm_name: "Развитие культуры",
    kpi_section: "ЦИФРОВИЗАЦИЯ",
    kpi: "Число обращений к цифровым ресурсам в сфере культуры",
    kpi_measure: "млн у.е.",
    name: "Татьяна Алексеевна Голикова",
    r1: 300,
    r2: "-",
    r3: "-",
    r4: 190,
    r5: "264.230010986328125",
    r6: "100",
    r13: 240,
    r14: "55.4399986267089844",
    r15: "23.0999991297721863",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ФП",
    kpi_guid: "8498303776",
    group: "ГП",
    programm_guid: "84",
    programm_name: "Развитие здравоохранения",
    kpi_section: "КВАЛИЦИФИЦИРОВАННЫЕ КАДРЫ",
    kpi: "Укомплектованность врачами первичного звена",
    kpi_measure: "%",
    name: "Татьяна Алексеевна Голикова",
    r1: 95,
    r2: "-",
    r3: "-",
    r4: 89,
    r5: "84.6299972534179688",
    r6: "95.0898826122283936",
    r13: 92,
    r14: "76.8899993896484375",
    r15: "83.5760891437530518",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ФП",
    kpi_guid: "8498303787",
    group: "ГП",
    programm_guid: "84",
    programm_name: "Развитие здравоохранения",
    kpi_section: "КВАЛИЦИФИЦИРОВАННЫЕ КАДРЫ",
    kpi: "Укомплектованность средними медицинскими работниками первичного звена",
    kpi_measure: "%",
    name: "Татьяна Алексеевна Голикова",
    r1: 95,
    r2: "-",
    r3: "-",
    r4: 84.4000015258789,
    r5: "85.660003662109375",
    r6: "100",
    r13: 94,
    r14: "76.0500030517578125",
    r15: "80.9042572975158691",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ФП",
    kpi_guid: "8674549122",
    group: "ГП",
    programm_guid: "84",
    programm_name: "Развитие здравоохранения",
    kpi_section: "МЕДПОМОЩЬ ОТДЕЛЬНЫМ ГРАЖДАНАМ",
    kpi: "Оказана медицинская реабилитация за счет средств ОМС",
    kpi_measure: "%",
    name: "Татьяна Алексеевна Голикова",
    r1: 95,
    r2: "-",
    r3: "-",
    r4: 95,
    r5: "109.599998474121094",
    r6: "100",
    r13: 95,
    r14: "5.30000019073486328",
    r15: "5.57894743978977203",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ФП",
    kpi_guid: "8498826288",
    group: "ГП",
    programm_guid: "84",
    programm_name: "Развитие здравоохранения",
    kpi_section: "ИННОВАЦИОННОЕ И ЦИФРОВОЕ РАЗВИТИЕ",
    kpi: "Высокотехнологичная помощь в рамках ОМС",
    kpi_measure: "тыс. ед.",
    name: "Татьяна Алексеевна Голикова",
    r1: 1350,
    r2: "-",
    r3: "-",
    r4: 1250,
    r5: "1431.5",
    r6: "100",
    r13: 1300,
    r14: "135.399993896484375",
    r15: "10.4153841733932495",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ФП",
    kpi_guid: "8498791174",
    group: "ГП",
    programm_guid: "84",
    programm_name: "Развитие здравоохранения",
    kpi_section: "ИННОВАЦИОННОЕ И ЦИФРОВОЕ РАЗВИТИЕ",
    kpi: "Дистанционная запись гражданами на прием к врачу",
    kpi_measure: "тыс. чел.",
    name: "Татьяна Алексеевна Голикова",
    r1: 38000,
    r2: "-",
    r3: "-",
    r4: 24000,
    r5: "32795.02734375",
    r6: "100",
    r13: 32000,
    r14: "8826.06640625",
    r15: "27.5814563035964966",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ФП",
    kpi_guid: "8498289504",
    group: "ГП",
    programm_guid: "84",
    programm_name: "Развитие здравоохранения",
    kpi_section: "БОРЬБА С ОНКОЗАБОЛЕВАНИЯМИ",
    kpi: "Граждане с выявленным онкологическим диагнозом на ранних стадиях развития",
    kpi_measure: "%",
    name: "Татьяна Алексеевна Голикова",
    r1: 59.099998474121094,
    r2: "-",
    r3: "-",
    r4: 57.900001525878906,
    r5: "59.2000007629394531",
    r6: "100",
    r13: 58.20000076293945,
    r14: "59.4000015258789062",
    r15: "100",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ФП",
    kpi_guid: "8216407449",
    group: "ГП",
    programm_guid: "84",
    programm_name: "Развитие здравоохранения",
    kpi_section: "БОРЬБА С ОНКОЗАБОЛЕВАНИЯМИ",
    kpi: "Доля граждан, живущих более пяти лет с момента установления онкологического диагноза",
    kpi_measure: "%",
    name: "Татьяна Алексеевна Голикова",
    r1: 60,
    r2: "-",
    r3: "-",
    r4: 56.70000076293945,
    r5: "58.0999984741210938",
    r6: "100",
    r13: 57.20000076293945,
    r14: "58.7999992370605469",
    r15: "100",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ФП",
    kpi_guid: "8495896604",
    group: "ГП",
    programm_guid: "84",
    programm_name: "Развитие здравоохранения",
    kpi_section: "РАЗВИТИЕ ДЕТСКОГО ЗДРАВООХРАНЕНИЯ",
    kpi: "Снижение младенческой смертности",
    kpi_measure: "‰",
    name: "Татьяна Алексеевна Голикова",
    r1: 4.5,
    r2: "-",
    r3: "-",
    r4: 4.699999809265137,
    r5: "0",
    r6: "0",
    r13: 4.599999904632568,
    r14: "0",
    r15: "0",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ФП",
    kpi_guid: "8495900179",
    group: "ГП",
    programm_guid: "84",
    programm_name: "Развитие здравоохранения",
    kpi_section: "РАЗВИТИЕ ДЕТСКОГО ЗДРАВООХРАНЕНИЯ",
    kpi: "Дети, охваченные профилактическими мероприятиями",
    kpi_measure: "%",
    name: "Татьяна Алексеевна Голикова",
    r1: 51,
    r2: "-",
    r3: "-",
    r4: 50,
    r5: "54.5",
    r6: "100",
    r13: 50.5,
    r14: "52.5999984741210938",
    r15: "100",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ФП",
    kpi_guid: "8498835971",
    group: "ГП",
    programm_guid: "84",
    programm_name: "Развитие здравоохранения",
    kpi_section: "МАТЕРИАЛЬНО-ТЕХНИЧЕСКОЕ ОБЕСПЕЧЕНИЕ",
    kpi: "Доля малых населенных пунктов с доступной первой помощью",
    kpi_measure: "%",
    name: "Татьяна Алексеевна Голикова",
    r1: 99.19000244140625,
    r2: "-",
    r3: "-",
    r4: 97.36000061035156,
    r5: "99.6699981689453125",
    r6: "100",
    r13: 98.33999633789062,
    r14: "99.6699981689453125",
    r15: "100",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ФП",
    kpi_guid: "8640678069",
    group: "ГП",
    programm_guid: "125",
    programm_name: "Содействие занятости населения",
    kpi_section: "СОДЕЙСТВИЕ ЗАНЯТОСТИ",
    kpi: "Занятость после переобучения",
    kpi_measure: "тыс. чел.",
    name: "Татьяна Алексеевна Голикова",
    r1: 453.3739929199219,
    r2: "306.152008",
    r3: "67.527472972869873",
    r4: 124.90199279785156,
    r5: "160.236007690429688",
    r6: "100",
    r13: 68.89199829101562,
    r14: "0",
    r15: "0",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ФП",
    kpi_guid: "8497848082",
    group: "ГП",
    programm_guid: "92",
    programm_name: "Развитие образования",
    kpi_section: "ДОСТУПНОСТЬ КАЧЕСТВЕННОГО ОБРАЗОВАНИЯ",
    kpi: "Новые места в российских школах",
    kpi_measure: "тыс. ед.",
    name: "Татьяна Алексеевна Голикова",
    r1: 1062267,
    r2: "501278",
    r3: "47.1894532442092896",
    r4: 297385,
    r5: "301828",
    r6: "100",
    r13: 283051,
    r14: "2826",
    r15: "0.998406647565279859",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ФП",
    kpi_guid: "8497386816",
    group: "ГП",
    programm_guid: "92",
    programm_name: "Развитие образования",
    kpi_section: "СОВРЕМЕННЫЕ ПЕДАГОГИЧЕСКИЕ КАДРЫ",
    kpi: "Доля педагогических работников, использующих цифровую образовательную среду",
    kpi_measure: "%",
    name: "Татьяна Алексеевна Голикова",
    r1: 45,
    r2: "-",
    r3: "-",
    r4: 10,
    r5: "49.6800003051757812",
    r6: "100",
    r13: 40,
    r14: "55.4300003051757812",
    r15: "100",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ФП",
    kpi_guid: "8701892644",
    group: "ГП",
    programm_guid: "92",
    programm_name: "Развитие образования",
    kpi_section: "СОЗДАНИЕ УСЛОВИЙ ДЛЯ РАЗВИТИЯ",
    kpi: "Включенность молодежи в молодежные проекты и программы",
    kpi_measure: "%",
    name: "Татьяна Алексеевна Голикова",
    r1: 5.099999904632568,
    r2: "-",
    r3: "-",
    r4: 3.799999952316284,
    r5: "4.80000019073486328",
    r6: "100",
    r13: 4.5,
    r14: "4.80000019073486328",
    r15: "100",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ФП",
    kpi_guid: "8700571934",
    group: "ГП",
    programm_guid: "109",
    programm_name: "Развитие Северо-Кавказского федерального округа",
    kpi_section: "ТУРИЗМ",
    kpi: "Прибывшие туристы",
    kpi_measure: "тыс. чел.",
    name: "Александр Валентинович Новак",
    r1: 1525336,
    r2: "-",
    r3: "-",
    r4: 1036679,
    r5: "1316700",
    r6: "100",
    r13: 1416837,
    r14: "0",
    r15: "0",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ФП",
    kpi_guid: "8681684760",
    group: "ГП",
    programm_guid: "108",
    programm_name: "Развитие энергетики",
    kpi_section: "ЭКОЛОГИЧНЫЙ ТРАНСПОРТ",
    kpi: "Газозаправочные станции",
    kpi_measure: "ед.",
    name: "Александр Валентинович Новак",
    r1: 1273,
    r2: "0",
    r3: "0",
    r4: 159,
    r5: "163",
    r6: "100",
    r13: 176,
    r14: "-896",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ФП",
    kpi_guid: "8746365368",
    group: "ГП",
    programm_guid: "108",
    programm_name: "Развитие энергетики",
    kpi_section: "УСТОЙЧИВОЕ РАЗВИТИЕ ЭНЕРГЕТИКИ",
    kpi: "Доля выработки электроэнергии на ВИЭ",
    kpi_measure: "%",
    name: "Александр Валентинович Новак",
    r1: 1.2000000476837158,
    r2: "1.00999999",
    r3: "84.1666638851165771",
    r4: 0.19999998807907104,
    r5: "0.310000002384185791",
    r6: "100",
    r13: 0.09000003337860107,
    r14: "0",
    r15: "0",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ФП",
    kpi_guid: "8701360941",
    group: "ГП",
    programm_guid: "108",
    programm_name: "Развитие энергетики",
    kpi_section: "УСТОЙЧИВОЕ РАЗВИТИЕ ЭНЕРГЕТИКИ",
    kpi: "Введенные объекты генерации в удаленных и изолированных энергорайонах, включая Арктику",
    kpi_measure: "МВт",
    name: "Александр Валентинович Новак",
    r1: 42.70000076293945,
    r2: "-",
    r3: "-",
    r4: 27.600000381469727,
    r5: "106",
    r6: "100",
    r13: 35.29999923706055,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ФП",
    kpi_guid: "8699069656",
    group: "ГП",
    programm_guid: "99",
    programm_name:
      "Социально-экономическое развитие Дальневосточного федерального округа",
    kpi_section: "ИНВЕСТИЦИИ",
    kpi: "Инвестиционные проекты с участием ВЭБ.РФ",
    kpi_measure: "ед.",
    name: "Юрий Петрович Трутнев",
    r1: 18,
    r2: "6",
    r3: "33.3333343267440796",
    r4: 6,
    r5: "6",
    r6: "100",
    r13: 6,
    r14: "0",
    r15: "0",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ФП",
    kpi_guid: "8699069649",
    group: "ГП",
    programm_guid: "99",
    programm_name:
      "Социально-экономическое развитие Дальневосточного федерального округа",
    kpi_section: "ИНВЕСТИЦИИ",
    kpi: "Сумма экспортных сделок при поддержке КРДВ",
    kpi_measure: "млрд руб.",
    name: "Юрий Петрович Трутнев",
    r1: 170,
    r2: "138.910004",
    r3: "81.7117691040039062",
    r4: 10,
    r5: "99.910003662109375",
    r6: "100",
    r13: 11.089996337890625,
    r14: "0",
    r15: "0",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ФП",
    kpi_guid: "8699069650",
    group: "ГП",
    programm_guid: "99",
    programm_name:
      "Социально-экономическое развитие Дальневосточного федерального округа",
    kpi_section: "ИНВЕСТИЦИИ",
    kpi: "Предоставлен Дальневосточный гектар",
    kpi_measure: "тыс. единиц",
    name: "Юрий Петрович Трутнев",
    r1: 92.54000091552734,
    r2: "87.75",
    r3: "94.8238611221313477",
    r4: 5.9199981689453125,
    r5: "11.30999755859375",
    r6: "100",
    r13: 0.6100006103515625,
    r14: "1.06999969482421875",
    r15: "100",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ФП",
    kpi_guid: "8699664137",
    group: "ГП",
    programm_guid: "91",
    programm_name:
      "Социально-экономическое развитие Арктической зоны Российской Федерации",
    kpi_section: "РЕЗИДЕНТЫ\nАРКТИЧЕСКОЙ ЗОНЫ РОССИЙСКОЙ ФЕДЕРАЦИИ",
    kpi: "Резиденты Арктической зоны и ее ТОР",
    kpi_measure: "ед.",
    name: "Юрий Петрович Трутнев",
    r1: 750,
    r2: "573",
    r3: "76.3999998569488525",
    r4: 157,
    r5: "448",
    r6: "100",
    r13: 96,
    r14: "19",
    r15: "19.7916666666666643",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ФП",
    kpi_guid: "8699716039",
    group: "ГП",
    programm_guid: "91",
    programm_name:
      "Социально-экономическое развитие Арктической зоны Российской Федерации",
    kpi_section: "РЕЗИДЕНТЫ\nАРКТИЧЕСКОЙ ЗОНЫ РОССИЙСКОЙ ФЕДЕРАЦИИ",
    kpi: "Проекты, реализуемые с привлечением льготных кредитов",
    kpi_measure: "ед.",
    name: "Юрий Петрович Трутнев",
    r1: 4,
    r2: "4",
    r3: "100",
    r4: 1,
    r5: "1",
    r6: "100",
    r13: 0,
    r14: "0",
    r15: "100",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ФП",
    kpi_guid: "8711569970",
    group: "ГП",
    programm_guid: "128",
    programm_name: "Развитие промышленности",
    kpi_section: "ПОДДЕРЖКА ЭКСПОРТА",
    kpi: "Объем экспорта товаров отраслей промышленности",
    kpi_measure: "млрд долл.",
    name: "Денис Валентинович Мантуров",
    r1: 94,
    r2: "-",
    r3: "-",
    r4: 119.19999694824219,
    r5: "119.199996948242188",
    r6: "100",
    r13: 90,
    r14: "12.75",
    r15: "14.166666567325592",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ФП",
    kpi_guid: "8703588970",
    group: "ГП",
    programm_guid: "128",
    programm_name: "Развитие промышленности",
    kpi_section: "ИМПОРТОЗАМЕЩЕНИЕ И ТЕХНОЛОГИЧЕСКИЙ СУВЕРЕНИТЕТ",
    kpi: "Российские автомобили на внутреннем рынке",
    kpi_measure: "%",
    name: "Денис Валентинович Мантуров",
    r1: 78,
    r2: "-",
    r3: "-",
    r4: 84.0999984741211,
    r5: "84.0999984741210938",
    r6: "100",
    r13: 77,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ФП",
    kpi_guid: "8703588971",
    group: "ГП",
    programm_guid: "128",
    programm_name: "Развитие промышленности",
    kpi_section: "ИМПОРТОЗАМЕЩЕНИЕ И ТЕХНОЛОГИЧЕСКИЙ СУВЕРЕНИТЕТ",
    kpi: "Российская продукция ж/д машиностроения на внутреннем рынке",
    kpi_measure: "%",
    name: "Денис Валентинович Мантуров",
    r1: 89.4000015258789,
    r2: "-",
    r3: "-",
    r4: 89.19999694824219,
    r5: "89.1999969482421875",
    r6: "100",
    r13: 89.30000305175781,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ФП",
    kpi_guid: "8711569973",
    group: "ГП",
    programm_guid: "128",
    programm_name: "Развитие промышленности",
    kpi_section: "ИМПОРТОЗАМЕЩЕНИЕ И ТЕХНОЛОГИЧЕСКИЙ СУВЕРЕНИТЕТ",
    kpi: "Производство спецтехники (дорожных, строительных и других спецмашин и тракторов)",
    kpi_measure: "шт.",
    name: "Денис Валентинович Мантуров",
    r1: 13816,
    r2: "-",
    r3: "-",
    r4: 13280,
    r5: "13280",
    r6: "100",
    r13: 13545,
    r14: "0",
    r15: "0",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ФП",
    kpi_guid: "8711774093",
    group: "ГП",
    programm_guid: "128",
    programm_name: "Развитие промышленности",
    kpi_section: "ИМПОРТОЗАМЕЩЕНИЕ И ТЕХНОЛОГИЧЕСКИЙ СУВЕРЕНИТЕТ",
    kpi: "Новые производства мало- и среднетоннажной химии",
    kpi_measure: "шт.",
    name: "Денис Валентинович Мантуров",
    r1: 8,
    r2: "-",
    r3: "-",
    r4: 1,
    r5: "1",
    r6: "100",
    r13: 4,
    r14: "0",
    r15: "0",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ФП",
    kpi_guid: "8778537519",
    group: "ГП",
    programm_guid: "128",
    programm_name: "Развитие промышленности",
    kpi_section: "ИМПОРТОЗАМЕЩЕНИЕ И ТЕХНОЛОГИЧЕСКИЙ СУВЕРЕНИТЕТ",
    kpi: "Станки и оборудование российского производства",
    kpi_measure: "ед.",
    name: "Денис Валентинович Мантуров",
    r1: 245,
    r2: "-",
    r3: "-",
    r4: 354,
    r5: "354",
    r6: "100",
    r13: 252,
    r14: "0",
    r15: "0",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ФП",
    kpi_guid: "8691860936",
    group: "ГП",
    programm_guid: "128",
    programm_name: "Развитие промышленности",
    kpi_section: "ИМПОРТОЗАМЕЩЕНИЕ И ТЕХНОЛОГИЧЕСКИЙ СУВЕРЕНИТЕТ",
    kpi: "Количество созданных и модернизированных производств",
    kpi_measure: "ед.",
    name: "Денис Валентинович Мантуров",
    r1: 12,
    r2: "-",
    r3: "-",
    r4: 39,
    r5: "39",
    r6: "100",
    r13: 24,
    r14: "0",
    r15: "0",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ФП",
    kpi_guid: "8687930997",
    group: "ГП",
    programm_guid: "128",
    programm_name: "Развитие промышленности",
    kpi_section: "СОЗДАНИЕ РАБОЧИХ МЕСТ",
    kpi: "Новые рабочие места в регионах",
    kpi_measure: "ед.",
    name: "Денис Валентинович Мантуров",
    r1: 784,
    r2: "-",
    r3: "-",
    r4: 604,
    r5: "604",
    r6: "100",
    r13: 586,
    r14: "0",
    r15: "0",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ФП",
    kpi_guid: "8700054460",
    group: "ГП",
    programm_guid: "112",
    programm_name:
      "Развитие судостроения и техники для освоения шельфовых месторождений",
    kpi_section: "СУДОСТРОЕНИЕ",
    kpi: "Объем выпуска судов, водоизмещением более 80 тонн",
    kpi_measure: "ед.",
    name: "Денис Валентинович Мантуров",
    r1: 34,
    r2: "-",
    r3: "-",
    r4: 31,
    r5: "31",
    r6: "100",
    r13: 32,
    r14: "0",
    r15: "0",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ФП",
    kpi_guid: "8774750780",
    group: "ГП",
    programm_guid: "112",
    programm_name:
      "Развитие судостроения и техники для освоения шельфовых месторождений",
    kpi_section: "СУДОСТРОЕНИЕ",
    kpi: "Индекс изменения производительности труда в судостроительных организациях",
    kpi_measure: "%",
    name: "Денис Валентинович Мантуров",
    r1: 222,
    r2: "-",
    r3: "-",
    r4: 207,
    r5: "207",
    r6: "100",
    r13: 214,
    r14: "0",
    r15: "0",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ФП",
    kpi_guid: "8699664109",
    group: "ГП",
    programm_guid: "112",
    programm_name:
      "Развитие судостроения и техники для освоения шельфовых месторождений",
    kpi_section: "СУДОСТРОЕНИЕ",
    kpi: "Товарный выпуск программы лизинга гражданских судов",
    kpi_measure: "млрд руб.",
    name: "Денис Валентинович Мантуров",
    r1: 17960,
    r2: "-",
    r3: "-",
    r4: 15280,
    r5: "15280",
    r6: "100",
    r13: 16620,
    r14: "0",
    r15: "0",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ФП",
    kpi_guid: "8699664110",
    group: "ГП",
    programm_guid: "112",
    programm_name:
      "Развитие судостроения и техники для освоения шельфовых месторождений",
    kpi_section: "СУДОСТРОЕНИЕ",
    kpi: "Количество судов, построенных или приобретенных с помощью господдержки",
    kpi_measure: "ед.",
    name: "Денис Валентинович Мантуров",
    r1: 254,
    r2: "-",
    r3: "-",
    r4: 195,
    r5: "195",
    r6: "100",
    r13: 229,
    r14: "0",
    r15: "0",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ФП",
    kpi_guid: "8766782715",
    group: "ГП",
    programm_guid: "112",
    programm_name:
      "Развитие судостроения и техники для освоения шельфовых месторождений",
    kpi_section: "СУДОСТРОЕНИЕ",
    kpi: "Количество вновь созданных современных судостроительных комплексов",
    kpi_measure: "ед.",
    name: "Денис Валентинович Мантуров",
    r1: 0,
    r2: "-",
    r3: "-",
    r4: 0,
    r5: "-",
    r6: "-",
    r13: 0,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ФП",
    kpi_guid: "8688413969",
    group: "ГП",
    programm_guid: "96",
    programm_name:
      "Социально-экономическое развитие Республики Крым и г. Севастополя",
    kpi_section: "СОЦИАЛЬНАЯ СФЕРА",
    kpi: "Новые места в общеобразовательных организациях",
    kpi_measure: "тыс. мест",
    name: "Марат Шакирзянович Хуснуллин",
    r1: 7484,
    r2: "4574",
    r3: "61.1170470714569092",
    r4: 450,
    r5: "0",
    r6: "0",
    r13: 2410,
    r14: "0",
    r15: "0",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ФП",
    kpi_guid: "8688413970",
    group: "ГП",
    programm_guid: "96",
    programm_name:
      "Социально-экономическое развитие Республики Крым и г. Севастополя",
    kpi_section: "СОЦИАЛЬНАЯ СФЕРА",
    kpi: "Новые места в дошкольном образовании",
    kpi_measure: "тыс. мест",
    name: "Марат Шакирзянович Хуснуллин",
    r1: 13450,
    r2: "7820",
    r3: "58.1412613391876221",
    r4: 2310,
    r5: "1410",
    r6: "61.038961038961034",
    r13: 3030,
    r14: "240",
    r15: "7.92079207920792072",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ФП",
    kpi_guid: "8743271701",
    group: "ГП",
    programm_guid: "104",
    programm_name: "Национальная система пространственных данных",
    kpi_section: "АУДИТ ЗЕМЕЛЬНЫХ УЧАСТКОВ",
    kpi: "Прирост количества земельных участков с зарегистрированными в ЕГРН правами физ. лиц и юр. лиц",
    kpi_measure: "%",
    name: "Марат Шакирзянович Хуснуллин",
    r1: 5,
    r2: "-",
    r3: "-",
    r4: 2.5,
    r5: "4.90000009536743164",
    r6: "100",
    r13: 4,
    r14: "4.90000009536743164",
    r15: "100",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ФП",
    kpi_guid: "8674651496",
    group: "ГП",
    programm_guid: "104",
    programm_name: "Национальная система пространственных данных",
    kpi_section: "ЦИФРОВИЗАЦИЯ",
    kpi: "Доля оцифрованных материалов по землеустройству",
    kpi_measure: "%",
    name: "Марат Шакирзянович Хуснуллин",
    r1: 90,
    r2: "70",
    r3: "77.7777791023254395",
    r4: 5,
    r5: "34.55999755859375",
    r6: "100",
    r13: 0.44000244140625,
    r14: "0.44000244140625",
    r15: "100",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ФП",
    kpi_guid: "8504389772",
    group: "ГП",
    programm_guid: "87",
    programm_name: "Информационное общество",
    kpi_section: "ДОСТУП К ИНТЕРНЕТУ",
    kpi: "Количество поселков с широкополосным доступом к интернету",
    kpi_measure: "тыс. ед.",
    name: "Чернышенко Д.Н.",
    r1: 25.399999618530273,
    r2: "24.7700005",
    r3: "97.5196897983551025",
    r4: 1.2299995422363281,
    r5: "1.22999954223632812",
    r6: "100",
    r13: 0.8999996185302734,
    r14: "0.270000457763671875",
    r15: "30.0000635783147054",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ФП",
    kpi_guid: "8744365676",
    group: "ГП",
    programm_guid: "87",
    programm_name: "Информационное общество",
    kpi_section: "ДОСТУП К ИНТЕРНЕТУ",
    kpi: "Покрытие сельских поселений (с численностью населения более 2 000 человек) сетями LTE",
    kpi_measure: "%",
    name: "Чернышенко Д.Н.",
    r1: 100,
    r2: "93.3000031",
    r3: "93.3000028133392334",
    r4: 5,
    r5: "13.3000030517578125",
    r6: "100",
    r13: 1.6999969482421875,
    r14: "0",
    r15: "0",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ФП",
    kpi_guid: "8495964773",
    group: "ГП",
    programm_guid: "87",
    programm_name: "Информационное общество",
    kpi_section: "ГОСУДАРСТВЕННЫЕ УСЛУГИ",
    kpi: "Удовлетворенность качеством услуг в электронном виде на ЕПГУ",
    kpi_measure: "балл",
    name: "Чернышенко Д.Н.",
    r1: 4.400000095367432,
    r2: "-",
    r3: "-",
    r4: 3.9000000953674316,
    r5: "4.11999988555908203",
    r6: "100",
    r13: 4,
    r14: "4.23999977111816406",
    r15: "100",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ФП",
    kpi_guid: "8700353531",
    group: "ГП",
    programm_guid: "87",
    programm_name: "Информационное общество",
    kpi_section: "ДОСТУП К ИНФОРМАЦИИ",
    kpi: "Доступность контента в международном информационном пространстве",
    kpi_measure: "%",
    name: "Чернышенко Д.Н.",
    r1: 100,
    r2: "-",
    r3: "-",
    r4: 100,
    r5: "102.099998474121094",
    r6: "100",
    r13: 100,
    r14: "106.699996948242188",
    r15: "100",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ФП",
    kpi_guid: "8691295932",
    group: "ГП",
    programm_guid: "87",
    programm_name: "Информационное общество",
    kpi_section: "ПОДДЕРЖКА БИЗНЕСА",
    kpi: "Количество проектов стартапов, получивших гос. поддержку",
    kpi_measure: "ед.",
    name: "Чернышенко Д.Н.",
    r1: 908,
    r2: "706",
    r3: "77.7533054351806641",
    r4: 354,
    r5: "500",
    r6: "100",
    r13: 34,
    r14: "12",
    r15: "35.294117647058826",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ФП",
    kpi_guid: "8638296313",
    group: "ГП",
    programm_guid: "105",
    programm_name: "Развитие туризма",
    kpi_section: "ТУРИСТИЧЕСКАЯ ИНФРАСТРУКТУРА",
    kpi: "Количество номеров в классифицированных средствах размещения",
    kpi_measure: "тыс. ед.",
    name: "Чернышенко Д.Н.",
    r1: 783.9000244140625,
    r2: "-",
    r3: "-",
    r4: 749.2000122070312,
    r5: "745.677001953125",
    r6: "99.5297610759735107",
    r13: 769.2000122070312,
    r14: "777.86102294921875",
    r15: "100",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ФП",
    kpi_guid: "8624274212",
    group: "ГП",
    programm_guid: "105",
    programm_name: "Развитие туризма",
    kpi_section: "ТУРИСТИЧЕСКАЯ ИНФРАСТРУКТУРА",
    kpi: "Рабочие места в сфере туризма",
    kpi_measure: "млн чел.",
    name: "Чернышенко Д.Н.",
    r1: 2.9000000953674316,
    r2: "-",
    r3: "-",
    r4: 2.3499999046325684,
    r5: "2.4281001091003418",
    r6: "100",
    r13: 2.700000047683716,
    r14: "0",
    r15: "0",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ФП",
    kpi_guid: "8681389650",
    group: "ГП",
    programm_guid: "89",
    programm_name: "Реализация государственной национальной политики",
    kpi_section: "ОБЩЕРОССИЙСКАЯ ГРАЖДАНСКАЯ ИДЕНТИЧНОСТЬ",
    kpi: "Доля граждан из числа коренных малочисленных народов Севера, Сибири и Дальнего Востока, удовлетворенных гос. поддержкой",
    kpi_measure: "%",
    name: "Чернышенко Д.Н.",
    r1: 61.099998474121094,
    r2: "-",
    r3: "-",
    r4: 60.900001525878906,
    r5: "60.9000015258789062",
    r6: "100",
    r13: 61,
    r14: "0",
    r15: "0",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ФП",
    kpi_guid: "8683392129",
    group: "ГП",
    programm_guid: "126",
    programm_name: "Развитие физической культуры и спорта",
    kpi_section: "ЗДОРОВЫЙ ОБРАЗ ЖИЗНИ",
    kpi: "Вовлеченность инвалидов в систематические занятия спортом",
    kpi_measure: "%",
    name: "Чернышенко Д.Н.",
    r1: 24,
    r2: "-",
    r3: "-",
    r4: 22,
    r5: "22",
    r6: "100",
    r13: 23,
    r14: "22.9899997711181641",
    r15: "99.9565184116363525",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ФП",
    kpi_guid: "8500369605",
    group: "ГП",
    programm_guid: "94",
    programm_name: "Научно-технологическое развитие Российской Федерации",
    kpi_section: "КАДРЫ ДЛЯ НАУКИ",
    kpi: "Доля защитивших кандидатские диссертации и оставшихся в секторе",
    kpi_measure: "%",
    name: "Чернышенко Д.Н.",
    r1: 80,
    r2: "-",
    r3: "-",
    r4: 77,
    r5: "77",
    r6: "100",
    r13: 79,
    r14: "77",
    r15: "97.4683523178100586",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ФП",
    kpi_guid: "8507980255",
    group: "ГП",
    programm_guid: "94",
    programm_name: "Научно-технологическое развитие Российской Федерации",
    kpi_section: "ДОСТУПНОЕ КАЧЕСТВЕННОЕ ВЫСШЕЕ ОБРАЗОВАНИЕ",
    kpi: "Доступность бесплатного высшего образования",
    kpi_measure: "тыс. мест",
    name: "Чернышенко Д.Н.",
    r1: 365.3999938964844,
    r2: "-",
    r3: "-",
    r4: 363.5,
    r5: "363.5",
    r6: "100",
    r13: 365.3999938964844,
    r14: "365.399993896484375",
    r15: "100",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Мероприятие",
    attribute: "ФП",
    kpi_guid: "8681942184",
    group: "ГП",
    programm_guid: "108",
    programm_name: "Развитие энергетики",
    kpi_section: "СОЦИАЛЬНАЯ ПОДДЕРЖКА",
    kpi: "Переселено семей шахтеров из ветхого жилья",
    kpi_measure: "ед.",
    name: "Александр Валентинович Новак",
    r1: 1091,
    r2: "-",
    r3: "-",
    r4: 1856,
    r5: "1190",
    r6: "64.1163766384124756",
    r13: 998,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Мероприятие",
    attribute: "ФП",
    kpi_guid: "8692452786",
    group: "ГП",
    programm_guid: "97",
    programm_name: "Комплексное развитие сельских территорий",
    kpi_section: "ИНФРАСТРУКТУРА СЕЛЬСКИХ ТЕРРИТОРИЙ",
    kpi: "Благоустройство общественных пространств на селе",
    kpi_measure: "ед.",
    name: "Абрамченко В.В.",
    r1: 740,
    r2: "-",
    r3: "-",
    r4: 890,
    r5: "979",
    r6: "100",
    r13: 785,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Мероприятие",
    attribute: "ФП",
    kpi_guid: "8695604636",
    group: "ГП",
    programm_guid: "97",
    programm_name: "Комплексное развитие сельских территорий",
    kpi_section: "ИНФРАСТРУКТУРА СЕЛЬСКИХ ТЕРРИТОРИЙ",
    kpi: "Комплексные проекты по созданию ключевых социальных и инженерных объектов в селах",
    kpi_measure: "ед.",
    name: "Абрамченко В.В.",
    r1: 113,
    r2: "-",
    r3: "-",
    r4: 83,
    r5: "83",
    r6: "100",
    r13: 84,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Мероприятие",
    attribute: "ФП",
    kpi_guid: "8696190217",
    group: "ГП",
    programm_guid: "97",
    programm_name: "Комплексное развитие сельских территорий",
    kpi_section: "ЧЕЛОВЕЧЕСКИЙ ПОТЕНЦИАЛ СЕЛЬСКИХ ТЕРРИТОРИЙ",
    kpi: "Больше комфортного жилья для сельского населения",
    kpi_measure: "тыс. кв. м",
    name: "Абрамченко В.В.",
    r1: 19.399999618530273,
    r2: "-",
    r3: "-",
    r4: 21.5,
    r5: "65.5",
    r6: "100",
    r13: 22.600000381469727,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Мероприятие",
    attribute: "ФП",
    kpi_guid: "8696190219",
    group: "ГП",
    programm_guid: "97",
    programm_name: "Комплексное развитие сельских территорий",
    kpi_section: "ЧЕЛОВЕЧЕСКИЙ ПОТЕНЦИАЛ СЕЛЬСКИХ ТЕРРИТОРИЙ",
    kpi: "Количество семей, воспользовавшихся сельской ипотекой",
    kpi_measure: "тыс. ед.",
    name: "Абрамченко В.В.",
    r1: 118.5999984741211,
    r2: "-",
    r3: "-",
    r4: 7.3000030517578125,
    r5: "12.0999984741210938",
    r6: "100",
    r13: 9.5,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Мероприятие",
    attribute: "ФП",
    kpi_guid: "8719281718",
    group: "ГП",
    programm_guid: "92",
    programm_name: "Развитие образования",
    kpi_section: "СОЗДАНИЕ УСЛОВИЙ ДЛЯ РАЗВИТИЯ",
    kpi: "Количество отремонтированных школ",
    kpi_measure: "ед.",
    name: "Татьяна Алексеевна Голикова",
    r1: 4920,
    r2: "-",
    r3: "-",
    r4: 1298,
    r5: "1663",
    r6: "100",
    r13: 1757,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "773",
    group: "ГП",
    programm_guid: "93",
    programm_name: "Развитие лесного хозяйства",
    kpi_section: "СОХРАНЕНИЕ ЛЕСОВ",
    kpi: "Доля площади страны, покрытой лесом ",
    kpi_measure: "%",
    name: "Абрамченко В.В.",
    r1: 46.400001525878906,
    r2: "-",
    r3: "-",
    r4: 1.5258789076710855e-6,
    r5: "1.52587890767108547e-06",
    r6: "100",
    r13: 0,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "771",
    group: "ГП",
    programm_guid: "92",
    programm_name: "Развитие образования",
    kpi_section: "ВОЗМОЖНОСТИ ДЛЯ ПРОФЕССИОНАЛЬНОГО И КАРЬЕРНОГО РОСТА",
    kpi: "Граждане, занимающиеся добровольчеством (волонтерством)",
    kpi_measure: "млн чел.",
    name: "Татьяна Алексеевна Голикова",
    r1: 13.100000381469727,
    r2: "-",
    r3: "-",
    r4: 10.699999809265137,
    r5: "13.6999998092651367",
    r6: "100",
    r13: 11.899999618530273,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "769",
    group: "ГП",
    programm_guid: "92",
    programm_name: "Развитие образования",
    kpi_section: "ВОЗМОЖНОСТИ ДЛЯ ПРОФЕССИОНАЛЬНОГО И КАРЬЕРНОГО РОСТА",
    kpi: "Трудоустройство выпускников среднего профессионального образования",
    kpi_measure: "%",
    name: "Татьяна Алексеевна Голикова",
    r1: 62.70000076293945,
    r2: "-",
    r3: "-",
    r4: 62.5,
    r5: "75.0999984741210938",
    r6: "100",
    r13: 62.599998474121094,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "770",
    group: "ГП",
    programm_guid: "92",
    programm_name: "Развитие образования",
    kpi_section: "ДОСТУПНОСТЬ КАЧЕСТВЕННОГО ОБРАЗОВАНИЯ",
    kpi: "Доступность дополнительного образования",
    kpi_measure: "%",
    name: "Татьяна Алексеевна Голикова",
    r1: 80,
    r2: "-",
    r3: "-",
    r4: 77,
    r5: "83.4000015258789062",
    r6: "100",
    r13: 79,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "768",
    group: "ГП",
    programm_guid: "92",
    programm_name: "Развитие образования",
    kpi_section: "ДОСТУПНОСТЬ КАЧЕСТВЕННОГО ОБРАЗОВАНИЯ",
    kpi: "Доступность дошкольного образования",
    kpi_measure: "%",
    name: "Татьяна Алексеевна Голикова",
    r1: 99.9000015258789,
    r2: "-",
    r3: "-",
    r4: 99.55999755859375,
    r5: "99.6999969482421875",
    r6: "100",
    r13: 99.80000305175781,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "1088",
    group: "ГП",
    programm_guid: "123",
    programm_name: "Социальная поддержка граждан",
    kpi_section: "ПОЖИЛЫЕ И ИНВАЛИДЫ",
    kpi: "Пожилые люди и инвалиды получают долговременный уход",
    kpi_measure: "%",
    name: "Татьяна Алексеевна Голикова",
    r1: 9.899999618530273,
    r2: "-",
    r3: "-",
    r4: 9.699999809265137,
    r5: "23.7000007629394531",
    r6: "100",
    r13: 9.899999618530273,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "1080",
    group: "ГП",
    programm_guid: "123",
    programm_name: "Социальная поддержка граждан",
    kpi_section: "СОЦИАЛЬНОЕ ОБЕСПЕЧЕНИЕ ГРАЖДАН",
    kpi: "Доля граждан, реализовавших социальный контракт и преодолевших бедность",
    kpi_measure: "%",
    name: "Татьяна Алексеевна Голикова",
    r1: 22,
    r2: "-",
    r3: "-",
    r4: 16,
    r5: "25.3999996185302734",
    r6: "100",
    r13: 19,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "1000",
    group: "ГП",
    programm_guid: "113",
    programm_name:
      "Обеспечение общественного порядка и противодействие преступности",
    kpi_section: "ПРОТИВОДЕЙСТВИЕ ПРЕСТУПНОСТИ",
    kpi: "Неразысканные пропавшие граждане к 2021 г.",
    kpi_measure: "%",
    name: "Денис Валентинович Мантуров",
    r1: 97,
    r2: "-",
    r3: "-",
    r4: 0,
    r5: "-2.40000152587890625",
    r6: "-",
    r13: 0,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "1009",
    group: "ГП",
    programm_guid: "114",
    programm_name: "Защита населения и территорий от чрезвычайных ситуаций",
    kpi_section: " СОКРАЩЕНИЕ ЧИСЛА ЧС",
    kpi: "Количество происшествий на водных объектах",
    kpi_measure: "тыс. ед.",
    name: "Денис Валентинович Мантуров",
    r1: 5.199999809265137,
    r2: "-",
    r3: "-",
    r4: 5.5,
    r5: "3.59999990463256836",
    r6: "65.4545426368713379",
    r13: 5.400000095367432,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "910",
    group: "ГП",
    programm_guid: "106",
    programm_name: "Доступная среда",
    kpi_section: "ДОСТУПНАЯ ИНФРАСТРУКТУРА",
    kpi: "Доступность объектов инфраструктуры для инвалидов",
    kpi_measure: "%",
    name: "Татьяна Алексеевна Голикова",
    r1: 70.19999694824219,
    r2: "-",
    r3: "-",
    r4: 68.80000305175781,
    r5: "76.5999984741210938",
    r6: "100",
    r13: 69.30000305175781,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "912",
    group: "ГП",
    programm_guid: "106",
    programm_name: "Доступная среда",
    kpi_section: "РЕАБИЛИТАЦИЯ И АБИЛИТАЦИЯ ИНВАЛИДОВ",
    kpi: "Обеспечено техническими средствами реабилитации",
    kpi_measure: "%",
    name: "Татьяна Алексеевна Голикова",
    r1: 98,
    r2: "-",
    r3: "-",
    r4: 98,
    r5: "98",
    r6: "100",
    r13: 98,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "911",
    group: "ГП",
    programm_guid: "106",
    programm_name: "Доступная среда",
    kpi_section: "ИНФОРМАЦИОННАЯ ДОСТУПНОСТЬ ДЛЯ ИНВАЛИДОВ",
    kpi: "Телевизионные программы для инвалидов по слуху",
    kpi_measure: "час",
    name: "Татьяна Алексеевна Голикова",
    r1: 16000,
    r2: "-",
    r3: "-",
    r4: 16000,
    r5: "16000",
    r6: "100",
    r13: 16000,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "1005",
    group: "ГП",
    programm_guid: "114",
    programm_name: "Защита населения и территорий от чрезвычайных ситуаций",
    kpi_section: " СОКРАЩЕНИЕ ЧИСЛА ЧС",
    kpi: "Количество чрезвычайных ситуаций",
    kpi_measure: "ед.",
    name: "Денис Валентинович Мантуров",
    r1: 0.25200000405311584,
    r2: "-",
    r3: "-",
    r4: 0.2680000066757202,
    r5: "0.200000002980232239",
    r6: "74.6268630027770996",
    r13: 0.25999999046325684,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "1104",
    group: "ГП",
    programm_guid: "124",
    programm_name: "Жилье и ЖКХ",
    kpi_section: " УЛУЧШЕНИЕ ЖИЛИЩНЫХ УСЛОВИЙ",
    kpi: "Расселение жителей из аварийного фонда",
    kpi_measure: "тыс. чел.",
    name: "Марат Шакирзянович Хуснуллин",
    r1: 536.5599975585938,
    r2: "-",
    r3: "-",
    r4: 113.24498779296874,
    r5: "257.319999999999993",
    r6: "100",
    r13: 0,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "1103",
    group: "ГП",
    programm_guid: "124",
    programm_name: "Жилье и ЖКХ",
    kpi_section: " УЛУЧШЕНИЕ ЖИЛИЩНЫХ УСЛОВИЙ",
    kpi: "Улучшение жилищных условий семей",
    kpi_measure: "млн семей",
    name: "Марат Шакирзянович Хуснуллин",
    r1: 4.199999809265137,
    r2: "-",
    r3: "-",
    r4: 3.5999999046325684,
    r5: "3.70000004768371582",
    r6: "100",
    r13: 3.700000047683716,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "1102",
    group: "ГП",
    programm_guid: "124",
    programm_name: "Жилье и ЖКХ",
    kpi_section: " УЛУЧШЕНИЕ ЖИЛИЩНЫХ УСЛОВИЙ",
    kpi: "Ввод жилья",
    kpi_measure: "млн м2 в год",
    name: "Марат Шакирзянович Хуснуллин",
    r1: 90,
    r2: "-",
    r3: "-",
    r4: 80,
    r5: "102.699996948242188",
    r6: "100",
    r13: 85,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "1106",
    group: "ГП",
    programm_guid: "124",
    programm_name: "Жилье и ЖКХ",
    kpi_section: "КОМФОРТНАЯ ГОРОДСКАЯ СРЕДА",
    kpi: "Прирост индекса качества городской среды",
    kpi_measure: "%",
    name: "Марат Шакирзянович Хуснуллин",
    r1: 22,
    r2: "-",
    r3: "-",
    r4: 5,
    r5: "5",
    r6: "100",
    r13: 4,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "1002",
    group: "ГП",
    programm_guid: "113",
    programm_name:
      "Обеспечение общественного порядка и противодействие преступности",
    kpi_section: "СНИЖЕНИЕ СМЕРТНОСТИ В ДТП",
    kpi: "Смерти на дорогах",
    kpi_measure: "чел. на 10 тыс. транспорт. ср. ",
    name: "Денис Валентинович Мантуров",
    r1: 2.119999885559082,
    r2: "-",
    r3: "-",
    r4: 2.4200000762939453,
    r5: "2.29999995231628418",
    r6: "95.0413167476654053",
    r13: 2.25,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "1109",
    group: "ГП",
    programm_guid: "125",
    programm_name: "Содействие занятости населения",
    kpi_section: "БЕЗРАБОТИЦА",
    kpi: "Уровень регистрируемой безработицы",
    kpi_measure: "%",
    name: "Татьяна Алексеевна Голикова",
    r1: 1.100000023841858,
    r2: "-",
    r3: "-",
    r4: 1.2999999523162842,
    r5: "0.89999997615814209",
    r6: "69.2307710647583008",
    r13: 1.100000023841858,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "1003",
    group: "ГП",
    programm_guid: "113",
    programm_name:
      "Обеспечение общественного порядка и противодействие преступности",
    kpi_section: "УДОВЛЕТВОРЕННОСТЬ ГРАЖДАН КАЧЕСТВОМ ПРЕДОСТАВЛЕНИЯ ГОСУСЛУГ",
    kpi: "В области безопасности дорожного движения",
    kpi_measure: "%",
    name: "Денис Валентинович Мантуров",
    r1: 90,
    r2: "-",
    r3: "-",
    r4: 90,
    r5: "98.5",
    r6: "100",
    r13: 90,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "1004",
    group: "ГП",
    programm_guid: "113",
    programm_name:
      "Обеспечение общественного порядка и противодействие преступности",
    kpi_section: "УДОВЛЕТВОРЕННОСТЬ ГРАЖДАН КАЧЕСТВОМ ПРЕДОСТАВЛЕНИЯ ГОСУСЛУГ",
    kpi: "В сфере миграции",
    kpi_measure: "%",
    name: "Денис Валентинович Мантуров",
    r1: 90,
    r2: "-",
    r3: "-",
    r4: 90,
    r5: "99.5999984741210938",
    r6: "100",
    r13: 90,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "1011",
    group: "ГП",
    programm_guid: "114",
    programm_name: "Защита населения и территорий от чрезвычайных ситуаций",
    kpi_section: " СОКРАЩЕНИЕ ЧИСЛА ЧС",
    kpi: "Количество аварий на опасных производственных объектах (на 1000 опасных производственных объектов)",
    kpi_measure: "ед.",
    name: "Денис Валентинович Мантуров",
    r1: 0.9399999976158142,
    r2: "-",
    r3: "-",
    r4: 1.0099999904632568,
    r5: "0.5",
    r6: "49.5049506425857544",
    r13: 1,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "1007",
    group: "ГП",
    programm_guid: "114",
    programm_name: "Защита населения и территорий от чрезвычайных ситуаций",
    kpi_section: " СОКРАЩЕНИЕ ЧИСЛА ЧС",
    kpi: "Количество зарегистрированных пожаров в зданиях и сооружениях",
    kpi_measure: "тыс. ед.",
    name: "Денис Валентинович Мантуров",
    r1: 169.39999389648438,
    r2: "-",
    r3: "-",
    r4: 172.60000610351562,
    r5: "145.300003051757812",
    r6: "84.1830790042877197",
    r13: 171,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "1010",
    group: "ГП",
    programm_guid: "114",
    programm_name: "Защита населения и территорий от чрезвычайных ситуаций",
    kpi_section: "СНИЖЕНИЕ ПОГИБШИХ В ЧС",
    kpi: "Погибшие в происшествиях на водных объектах",
    kpi_measure: "%",
    name: "Денис Валентинович Мантуров",
    r1: 10.699999809265137,
    r2: "-",
    r3: "-",
    r4: 8.199999809265137,
    r5: "37.7999992370605469",
    r6: "100",
    r13: 9.399999618530273,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "1006",
    group: "ГП",
    programm_guid: "114",
    programm_name: "Защита населения и территорий от чрезвычайных ситуаций",
    kpi_section: "СНИЖЕНИЕ ПОГИБШИХ В ЧС",
    kpi: "Погибшие в чрезвычайных ситуациях (по отношению к плановому значению показателя 2019 года)",
    kpi_measure: "%",
    name: "Денис Валентинович Мантуров",
    r1: 12.199999809265137,
    r2: "-",
    r3: "-",
    r4: 7.400000095367432,
    r5: "67.3000030517578125",
    r6: "100",
    r13: 9.899999618530273,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "1008",
    group: "ГП",
    programm_guid: "114",
    programm_name: "Защита населения и территорий от чрезвычайных ситуаций",
    kpi_section: "СНИЖЕНИЕ ПОГИБШИХ В ЧС",
    kpi: "Погибшие на пожарах",
    kpi_measure: "%",
    name: "Денис Валентинович Мантуров",
    r1: 12.5,
    r2: "-",
    r3: "-",
    r4: 7.5,
    r5: "14.6000003814697266",
    r6: "100",
    r13: 10,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "967",
    group: "ГП",
    programm_guid: "111",
    programm_name: "Охрана окружающей среды",
    kpi_section: "ОТХОДЫ",
    kpi: "Больше обрабатываемого мусора",
    kpi_measure: "%",
    name: "Абрамченко В.В.",
    r1: 50.20000076293945,
    r2: "-",
    r3: "-",
    r4: 32.70000076293945,
    r5: "50.2000007629394531",
    r6: "100",
    r13: 39.70000076293945,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "860",
    group: "ГП",
    programm_guid: "100",
    programm_name: "Развитие культуры",
    kpi_section: "ДОСТУПНОСТЬ И КАЧЕСТВО ",
    kpi: "Доля зрителей российских фильмов в отечественном прокате",
    kpi_measure: "%",
    name: "Татьяна Алексеевна Голикова",
    r1: 1132,
    r2: "-",
    r3: "-",
    r4: 27.5,
    r5: "55.5",
    r6: "100",
    r13: 857,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "983",
    group: "ГП",
    programm_guid: "111",
    programm_name: "Охрана окружающей среды",
    kpi_section: "ВОДА",
    kpi: "Утилизация затонувших кораблей",
    kpi_measure: "шт.",
    name: "Абрамченко В.В.",
    r1: 213,
    r2: "-",
    r3: "-",
    r4: 43,
    r5: "43",
    r6: "100",
    r13: 130,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "859",
    group: "ГП",
    programm_guid: "100",
    programm_name: "Развитие культуры",
    kpi_section: "РАЗВИТИЕ ТВОРЧЕСКОГО ПОТЕНЦИАЛА",
    kpi: "Поддержанные и реализованные творческие инициативы и проекты в культуре",
    kpi_measure: "ед.",
    name: "Татьяна Алексеевна Голикова",
    r1: 15559,
    r2: "-",
    r3: "-",
    r4: 11687,
    r5: "11716",
    r6: "100",
    r13: 12656,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "977",
    group: "ГП",
    programm_guid: "111",
    programm_name: "Охрана окружающей среды",
    kpi_section: "ПРИРОДА",
    kpi: "Участие в восстановлении редких видов",
    kpi_measure: "у.е.",
    name: "Абрамченко В.В.",
    r1: 1,
    r2: "-",
    r3: "-",
    r4: 1,
    r5: "1",
    r6: "100",
    r13: 1,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "987",
    group: "ГП",
    programm_guid: "111",
    programm_name: "Охрана окружающей среды",
    kpi_section: "ПРОЦЕССНЫЕ МЕРОПРИЯТИЯ",
    kpi: "Точность прогнозов погоды",
    kpi_measure: "%",
    name: "Абрамченко В.В.",
    r1: 96,
    r2: "-",
    r3: "-",
    r4: 96,
    r5: "96.6999969482421875",
    r6: "100",
    r13: 96,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "972",
    group: "ГП",
    programm_guid: "111",
    programm_name: "Охрана окружающей среды",
    kpi_section: "ВОЗДУХ",
    kpi: "Чистый воздух в городах",
    kpi_measure: "город",
    name: "Абрамченко В.В.",
    r1: 5,
    r2: "-",
    r3: "-",
    r4: 11,
    r5: "11",
    r6: "100",
    r13: 11,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "980",
    group: "ГП",
    programm_guid: "111",
    programm_name: "Охрана окружающей среды",
    kpi_section: "ОТХОДЫ",
    kpi: "Ликвидация опасных объектов",
    kpi_measure: "шт.",
    name: "Абрамченко В.В.",
    r1: 88,
    r2: "-",
    r3: "-",
    r4: 8,
    r5: "8",
    r6: "100",
    r13: 4,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "981",
    group: "ГП",
    programm_guid: "111",
    programm_name: "Охрана окружающей среды",
    kpi_section: "ОТХОДЫ",
    kpi: "Уборка городских свалок",
    kpi_measure: "шт.",
    name: "Абрамченко В.В.",
    r1: 191,
    r2: "-",
    r3: "-",
    r4: 15,
    r5: "16",
    r6: "100",
    r13: 53,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "976",
    group: "ГП",
    programm_guid: "111",
    programm_name: "Охрана окружающей среды",
    kpi_section: "ПРИРОДА",
    kpi: "Посетители заповедных территорий",
    kpi_measure: "млн. чел.",
    name: "Абрамченко В.В.",
    r1: 12,
    r2: "-",
    r3: "-",
    r4: 8.600000381469727,
    r5: "14",
    r6: "100",
    r13: 11.100000381469727,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "970",
    group: "ГП",
    programm_guid: "111",
    programm_name: "Охрана окружающей среды",
    kpi_section: "ВОДА",
    kpi: "Загрязняющие сбросы в оз. Байкал",
    kpi_measure: "км3",
    name: "Абрамченко В.В.",
    r1: 145644,
    r2: "-",
    r3: "-",
    r4: 247560,
    r5: "247560",
    r6: "100",
    r13: 247012,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "1117",
    group: "ГП",
    programm_guid: "126",
    programm_name: "Развитие физической культуры и спорта",
    kpi_section: "ЗДОРОВЫЙ ОБРАЗ ЖИЗНИ",
    kpi: "Доля населения, занимающегося физкультурой и спортом",
    kpi_measure: "%",
    name: "Чернышенко Д.Н.",
    r1: 55,
    r2: "-",
    r3: "-",
    r4: 51.29999923706055,
    r5: "53",
    r6: "100",
    r13: 53.099998474121094,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "1118",
    group: "ГП",
    programm_guid: "126",
    programm_name: "Развитие физической культуры и спорта",
    kpi_section: "УСЛОВИЯ ДЛЯ ЗАНЯТИЙ ФИЗИЧЕСКОЙ КУЛЬТУРОЙ И СПОРТОМ",
    kpi: "Обеспеченность спортивными сооружениями",
    kpi_measure: "%",
    name: "Чернышенко Д.Н.",
    r1: 62,
    r2: "-",
    r3: "-",
    r4: 59,
    r5: "60.2000007629394531",
    r6: "100",
    r13: 60,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "1119",
    group: "ГП",
    programm_guid: "126",
    programm_name: "Развитие физической культуры и спорта",
    kpi_section: "УСЛОВИЯ ДЛЯ ЗАНЯТИЙ ФИЗИЧЕСКОЙ КУЛЬТУРОЙ И СПОРТОМ",
    kpi: "Удовлетворенность условиями для занятий физкультурой и спортом",
    kpi_measure: "%",
    name: "Чернышенко Д.Н.",
    r1: 64,
    r2: "-",
    r3: "-",
    r4: 56,
    r5: "61.7000007629394531",
    r6: "100",
    r13: 63,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "1130",
    group: "ГП",
    programm_guid: "127",
    programm_name: "Экономическое развитие и инновационная экономика",
    kpi_section: "ИНВЕСТИЦИОННЫЙ ЦИКЛ ",
    kpi: "Темп роста инвестиций к 2020 г.",
    kpi_measure: "%",
    name: "Белоусов А.Р.",
    r1: 108.5,
    r2: "-",
    r3: "-",
    r4: 105.5,
    r5: "105.5",
    r6: "100",
    r13: 104.4000015258789,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "774",
    group: "ГП",
    programm_guid: "93",
    programm_name: "Развитие лесного хозяйства",
    kpi_section: "СОХРАНЕНИЕ ЛЕСОВ",
    kpi: "Восстановление лесов ",
    kpi_measure: "%",
    name: "Абрамченко В.В.",
    r1: 100,
    r2: "-",
    r3: "-",
    r4: 85.5999984741211,
    r5: "118.400001525878906",
    r6: "100",
    r13: 92.19999694824219,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "1134",
    group: "ГП",
    programm_guid: "127",
    programm_name: "Экономическое развитие и инновационная экономика",
    kpi_section: "ТЕХНОЛОГИЧЕСКОЕ РАЗВИТИЕ",
    kpi: "Выручка технологических компаний в экосистеме",
    kpi_measure: "млрд руб.",
    name: "Белоусов А.Р.",
    r1: 285.79998779296875,
    r2: "-",
    r3: "-",
    r4: 224.3000030517578,
    r5: "251.600006103515625",
    r6: "100",
    r13: 249.10000610351562,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "1137",
    group: "ГП",
    programm_guid: "127",
    programm_name: "Экономическое развитие и инновационная экономика",
    kpi_section: "ЭФФЕКТИВНОСТЬ ГОС. УПРАВЛЕНИЯ",
    kpi: "Удовлетворенность граждан качеством государственных и муниципальных услуг",
    kpi_measure: "%",
    name: "Белоусов А.Р.",
    r1: 92,
    r2: "-",
    r3: "-",
    r4: 90,
    r5: "92.6999969482421875",
    r6: "100",
    r13: 91,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "734",
    group: "ГП",
    programm_guid: "88",
    programm_name: "Развитие авиационной промышленности",
    kpi_section: " РАЗВИТИЕ ОТРАСЛИ",
    kpi: "Валовая добавленная стоимость отрасли авиастроения",
    kpi_measure: "млрд руб.",
    name: "Денис Валентинович Мантуров",
    r1: 658620032,
    r2: "-",
    r3: "-",
    r4: 469685664,
    r5: "437274912",
    r6: "93.0994808673858643",
    r13: 521849984,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "744",
    group: "ГП",
    programm_guid: "88",
    programm_name: "Развитие авиационной промышленности",
    kpi_section: "ИННОВАЦИИ",
    kpi: "Доля инновационных продуктов в авиастроении",
    kpi_measure: "%",
    name: "Денис Валентинович Мантуров",
    r1: 36,
    r2: "-",
    r3: "-",
    r4: 32,
    r5: "31",
    r6: "96.875",
    r13: 36,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "745",
    group: "ГП",
    programm_guid: "88",
    programm_name: "Развитие авиационной промышленности",
    kpi_section: "ИННОВАЦИИ",
    kpi: "Доля организаций с инновациями в авиастроении",
    kpi_measure: "%",
    name: "Денис Валентинович Мантуров",
    r1: 51,
    r2: "-",
    r3: "-",
    r4: 50,
    r5: "55",
    r6: "100",
    r13: 51,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "739",
    group: "ГП",
    programm_guid: "88",
    programm_name: "Развитие авиационной промышленности",
    kpi_section: " РАЗВИТИЕ ОТРАСЛИ",
    kpi: "Доля самолетов российского производства в парке крупнейших российских авиаперевозчиков",
    kpi_measure: "%",
    name: "Денис Валентинович Мантуров",
    r1: 18,
    r2: "-",
    r3: "-",
    r4: 14,
    r5: "17.5",
    r6: "100",
    r13: 15,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "736",
    group: "ГП",
    programm_guid: "88",
    programm_name: "Развитие авиационной промышленности",
    kpi_section: " РАЗВИТИЕ ОТРАСЛИ",
    kpi: "Количество поставленных самолетов государственной авиации",
    kpi_measure: "ед.",
    name: "Денис Валентинович Мантуров",
    r1: 113,
    r2: "-",
    r3: "-",
    r4: 75,
    r5: "72",
    r6: "95.9999978542327881",
    r13: 84,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "735",
    group: "ГП",
    programm_guid: "88",
    programm_name: "Развитие авиационной промышленности",
    kpi_section: " РАЗВИТИЕ ОТРАСЛИ",
    kpi: "Количество поставленных самолетов гражданской авиации",
    kpi_measure: "ед.",
    name: "Денис Валентинович Мантуров",
    r1: 38,
    r2: "-",
    r3: "-",
    r4: 6,
    r5: "7",
    r6: "100",
    r13: 5,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "743",
    group: "ГП",
    programm_guid: "88",
    programm_name: "Развитие авиационной промышленности",
    kpi_section: "ИНВЕСТИЦИИ",
    kpi: "Объем инвестиций в основной капитал организаций отрасли авиастроения",
    kpi_measure: "млрд руб.",
    name: "Денис Валентинович Мантуров",
    r1: 125000000,
    r2: "-",
    r3: "-",
    r4: 110000000,
    r5: "124252568",
    r6: "100",
    r13: 115000000,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "995",
    group: "ГП",
    programm_guid: "112",
    programm_name:
      "Развитие судостроения и техники для освоения шельфовых месторождений",
    kpi_section: "СУДОСТРОЕНИЕ",
    kpi: "Прирост выпуска гражданских судов и морской техники отечественными организациями",
    kpi_measure: "%",
    name: "Денис Валентинович Мантуров",
    r1: 347,
    r2: "-",
    r3: "-",
    r4: 277,
    r5: "277",
    r6: "100",
    r13: 313,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "997",
    group: "ГП",
    programm_guid: "112",
    programm_name:
      "Развитие судостроения и техники для освоения шельфовых месторождений",
    kpi_section: "СУДОСТРОЕНИЕ",
    kpi: "Уровень локализации продукции судостроения по кооперации 1 уровня",
    kpi_measure: "%",
    name: "Денис Валентинович Мантуров",
    r1: 53,
    r2: "-",
    r3: "-",
    r4: 49,
    r5: "49",
    r6: "100",
    r13: 51,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "1022",
    group: "ГП",
    programm_guid: "116",
    programm_name: "Развитие рыбохозяйственного комплекса",
    kpi_section: "ОБЕСПЕЧЕНИЕ РЫБОЙ",
    kpi: "Самообеспечение рыбой",
    kpi_measure: "%",
    name: "Абрамченко В.В.",
    r1: 85,
    r2: "-",
    r3: "-",
    r4: 85,
    r5: "85",
    r6: "100",
    r13: 85,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "916",
    group: "ГП",
    programm_guid: "107",
    programm_name: "Развитие фармацевтической и медицинской промышленности",
    kpi_section:
      "РАЗВИТИЕ ПРОИЗВОДСТВА ОТЕЧЕСТВЕННЫХ ЛЕКАРСТВЕННЫХ СРЕДСТВ И МЕДИЦИНСКИХ ИЗДЕЛИЙ",
    kpi: "Стоимостная доля лексредств локального производства в объеме рынка",
    kpi_measure: "%",
    name: "Татьяна Алексеевна Голикова",
    r1: 37,
    r2: "-",
    r3: "-",
    r4: 36.29999923706055,
    r5: "36",
    r6: "99.1735577583312988",
    r13: 36.599998474121094,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "917",
    group: "ГП",
    programm_guid: "107",
    programm_name: "Развитие фармацевтической и медицинской промышленности",
    kpi_section:
      "РАЗВИТИЕ ПРОИЗВОДСТВА ОТЕЧЕСТВЕННЫХ ЛЕКАРСТВЕННЫХ СРЕДСТВ И МЕДИЦИНСКИХ ИЗДЕЛИЙ",
    kpi: "Стоимостная доля медизделий локального производства в объеме рынка",
    kpi_measure: "%",
    name: "Татьяна Алексеевна Голикова",
    r1: 30,
    r2: "-",
    r3: "-",
    r4: 29,
    r5: "24.8999996185302734",
    r6: "85.8620703220367432",
    r13: 29.5,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "1060",
    group: "ГП",
    programm_guid: "121",
    programm_name: "Юстиция",
    kpi_section: "УГОЛОВНО\nИСПОЛНИТЕЛЬНАЯ СИСТЕМА",
    kpi: "Соответствие условий содержания в СИЗО",
    kpi_measure: "%",
    name: "Григоренко Д.Ю.",
    r1: 80.80000305175781,
    r2: "-",
    r3: "-",
    r4: 0.49999847412109943,
    r5: "1.59999694824219318",
    r6: "100",
    r13: 0.600006103515625,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "914",
    group: "ГП",
    programm_guid: "107",
    programm_name: "Развитие фармацевтической и медицинской промышленности",
    kpi_section:
      "РАЗВИТИЕ ПРОИЗВОДСТВА ОТЕЧЕСТВЕННЫХ ЛЕКАРСТВЕННЫХ СРЕДСТВ И МЕДИЦИНСКИХ ИЗДЕЛИЙ",
    kpi: "Объем производства отечественных лексредств",
    kpi_measure: "млрд руб.",
    name: "Татьяна Алексеевна Голикова",
    r1: 732,
    r2: "-",
    r3: "-",
    r4: 623,
    r5: "607.9000244140625",
    r6: "97.5762486457824707",
    r13: 676,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "913",
    group: "ГП",
    programm_guid: "107",
    programm_name: "Развитие фармацевтической и медицинской промышленности",
    kpi_section:
      "РАЗВИТИЕ ПРОИЗВОДСТВА ОТЕЧЕСТВЕННЫХ ЛЕКАРСТВЕННЫХ СРЕДСТВ И МЕДИЦИНСКИХ ИЗДЕЛИЙ",
    kpi: "Объем производства отечественных лексредств и медизделий",
    kpi_measure: "млрд руб.",
    name: "Татьяна Алексеевна Голикова",
    r1: 835,
    r2: "-",
    r3: "-",
    r4: 712,
    r5: "715.29998779296875",
    r6: "100",
    r13: 771,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "915",
    group: "ГП",
    programm_guid: "107",
    programm_name: "Развитие фармацевтической и медицинской промышленности",
    kpi_section:
      "РАЗВИТИЕ ПРОИЗВОДСТВА ОТЕЧЕСТВЕННЫХ ЛЕКАРСТВЕННЫХ СРЕДСТВ И МЕДИЦИНСКИХ ИЗДЕЛИЙ",
    kpi: "Объем производства отечественных медизделий",
    kpi_measure: "млрд руб.",
    name: "Татьяна Алексеевна Голикова",
    r1: 103,
    r2: "-",
    r3: "-",
    r4: 89,
    r5: "107.400001525878906",
    r6: "100",
    r13: 95,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "781",
    group: "ГП",
    programm_guid: "93",
    programm_name: "Развитие лесного хозяйства",
    kpi_section: "СОХРАНЕНИЕ ЛЕСОВ",
    kpi: "Площадь погибших лесов",
    kpi_measure: "млн. га",
    name: "Абрамченко В.В.",
    r1: 220,
    r2: "-",
    r3: "-",
    r4: 245,
    r5: "71.6999969482421875",
    r6: "100",
    r13: 230,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "918",
    group: "ГП",
    programm_guid: "107",
    programm_name: "Развитие фармацевтической и медицинской промышленности",
    kpi_section:
      "РАЗВИТИЕ ПРОИЗВОДСТВА ОТЕЧЕСТВЕННЫХ ЛЕКАРСТВЕННЫХ СРЕДСТВ И МЕДИЦИНСКИХ ИЗДЕЛИЙ",
    kpi: "Объем экспорта лекарственных средств и медизделий",
    kpi_measure: "млрд руб.",
    name: "Татьяна Алексеевна Голикова",
    r1: 180,
    r2: "-",
    r3: "-",
    r4: 107,
    r5: "90",
    r6: "84.1121494770050049",
    r13: 136,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "720",
    group: "ГП",
    programm_guid: "87",
    programm_name: "Информационное общество",
    kpi_section: "ДОСТУП К ИНТЕРНЕТУ",
    kpi: "Домохозяйства с широкополосным доступом к интернету",
    kpi_measure: "%",
    name: "Чернышенко Д.Н.",
    r1: 90,
    r2: "-",
    r3: "-",
    r4: 3,
    r5: "9.09999847412109375",
    r6: "100",
    r13: 0,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "1016",
    group: "ГП",
    programm_guid: "115",
    programm_name: "Развитие транспортной системы",
    kpi_section: "ОБЕСПЕЧЕНИЕ ТРАНСПОРТНОЙ ДОСТУПНОСТИ",
    kpi: "Доля автомобильных дорог регионального и межмуниципального значения, соответствующих нормативным требованиям",
    kpi_measure: "%",
    name: "Белоусов А.Р.",
    r1: 53,
    r2: "-",
    r3: "-",
    r4: 2.200000000000003,
    r5: "4.29999847412109659",
    r6: "100",
    r13: 0.9000015258789062,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "959",
    group: "ГП",
    programm_guid: "110",
    programm_name:
      "Государственная программа развития сельского хозяйства и регулирования рынков сельскохозяйственной продукции, сырья и продовольствия",
    kpi_section: "ПРОИЗВОДСТВО",
    kpi: "Индекс производства пищевых продуктов",
    kpi_measure: "%",
    name: "Абрамченко В.В.",
    r1: 103.0999984741211,
    r2: "-",
    r3: "-",
    r4: 100.9000015258789,
    r5: "104.599998474121094",
    r6: "100",
    r13: 101.9000015258789,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "958",
    group: "ГП",
    programm_guid: "110",
    programm_name:
      "Государственная программа развития сельского хозяйства и регулирования рынков сельскохозяйственной продукции, сырья и продовольствия",
    kpi_section: "ПРОИЗВОДСТВО",
    kpi: "Индекс производства продукции сельского хозяйства",
    kpi_measure: "%",
    name: "Абрамченко В.В.",
    r1: 102.5999984741211,
    r2: "-",
    r3: "-",
    r4: 99.30000305175781,
    r5: "109.800003051757812",
    r6: "100",
    r13: 100.9000015258789,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "809",
    group: "ГП",
    programm_guid: "94",
    programm_name: "Научно-технологическое развитие Российской Федерации",
    kpi_section: "СОВРЕМЕННАЯ ИНФРАСТРУКТУРА ДЛЯ НАУКИ",
    kpi: "Балансовая стоимость машин и оборудования в расчете на одного исследователя",
    kpi_measure: "тыс. руб.",
    name: "Чернышенко Д.Н.",
    r1: 1183,
    r2: "-",
    r3: "-",
    r4: 1118.199951171875,
    r5: "1118.199951171875",
    r6: "100",
    r13: 1144.5999755859375,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "960",
    group: "ГП",
    programm_guid: "110",
    programm_name:
      "Государственная программа развития сельского хозяйства и регулирования рынков сельскохозяйственной продукции, сырья и продовольствия",
    kpi_section: "КАДРЫ",
    kpi: "Средняя з/п работников с/х",
    kpi_measure: "руб.",
    name: "Абрамченко В.В.",
    r1: 43473,
    r2: "-",
    r3: "-",
    r4: 39022,
    r5: "46270",
    r6: "100",
    r13: 41168,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "1024",
    group: "ГП",
    programm_guid: "116",
    programm_name: "Развитие рыбохозяйственного комплекса",
    kpi_section: "ОБЕСПЕЧЕНИЕ РЫБОЙ",
    kpi: "Объем вылова рыбы и добычи биоресурсов",
    kpi_measure: "млн т",
    name: "Абрамченко В.В.",
    r1: 5070,
    r2: "-",
    r3: "-",
    r4: 5050,
    r5: "4882.5",
    r6: "96.6831684112548828",
    r13: 5060,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "1041",
    group: "ГП",
    programm_guid: "118",
    programm_name: "Воспроизводство и использование природных ресурсов",
    kpi_section: "ВОССТАНОВЛЕНИЕ ВОДНЫХ ОБЪЕКТОВ",
    kpi: "Вовлечено в мероприятия по очистке берегов",
    kpi_measure: "млн чел.",
    name: "Абрамченко В.В.",
    r1: 0.800000011920929,
    r2: "-",
    r3: "-",
    r4: 0.800000011920929,
    r5: "1.29999995231628418",
    r6: "100",
    r13: 0.800000011920929,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "1037",
    group: "ГП",
    programm_guid: "118",
    programm_name: "Воспроизводство и использование природных ресурсов",
    kpi_section: "НОВЫЕ ИСТОЧНИКИ ПРИРОДНЫХ РЕСУРСОВ",
    kpi: "Геологическая изученность территории Российской Федерации",
    kpi_measure: "%",
    name: "Абрамченко В.В.",
    r1: 68,
    r2: "-",
    r3: "-",
    r4: 1.0000015258789006,
    r5: "1.00000152587890057",
    r6: "100",
    r13: 1.2999954223632812,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "1039",
    group: "ГП",
    programm_guid: "118",
    programm_name: "Воспроизводство и использование природных ресурсов",
    kpi_section: "ЗАЩИТА ОТ НАВОДНЕНИЙ",
    kpi: "Количество защищенного населения от наводнений",
    kpi_measure: "тыс. чел.",
    name: "Абрамченко В.В.",
    r1: 358.8380126953125,
    r2: "-",
    r3: "-",
    r4: 31.425003784179694,
    r5: "49.0430000000000064",
    r6: "100",
    r13: 107.5570068359375,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "757",
    group: "ГП",
    programm_guid: "90",
    programm_name: "Развитие Калининградской области",
    kpi_section: "ЭКОНОМИЧЕСКОЕ РАЗВИТИЕ",
    kpi: "Численность безработных",
    kpi_measure: "тыс. чел.",
    name: "Татьяна Алексеевна Голикова",
    r1: 7,
    r2: "-",
    r3: "-",
    r4: 6,
    r5: "4.19999980926513672",
    r6: "100",
    r13: 7,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "929",
    group: "ГП",
    programm_guid: "108",
    programm_name: "Развитие энергетики",
    kpi_section: "ЭКОЛОГИЧНЫЙ ТРАНСПОРТ",
    kpi: "Объем потребления метана на транспорте",
    kpi_measure: "млрд м3",
    name: "Александр Валентинович Новак",
    r1: 2.700000047683716,
    r2: "-",
    r3: "-",
    r4: 0.35000003814697256,
    r5: "0.340000047683715723",
    r6: "97.1428601790444333",
    r13: 0.4700000286102295,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "922",
    group: "ГП",
    programm_guid: "108",
    programm_name: "Развитие энергетики",
    kpi_section: "ОБЕСПЕЧЕНИЕ ВНУТРЕННЕГО РЫНКА ЭНЕРГЕТИЧЕСКИМИ РЕСУРСАМИ",
    kpi: "Доля сырья, направляемого на нефтегазохимию",
    kpi_measure: "%",
    name: "Александр Валентинович Новак",
    r1: 27.399999618530273,
    r2: "-",
    r3: "-",
    r4: 26,
    r5: "26",
    r6: "100",
    r13: 27.200000762939453,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "1064",
    group: "ГП",
    programm_guid: "121",
    programm_name: "Юстиция",
    kpi_section: "ОРГАНЫ ПРИНУДИТЕЛЬНОГО ИСПОЛНЕНИЯ",
    kpi: "Качество госконтроля за коллекторами",
    kpi_measure: "%",
    name: "Григоренко Д.Ю.",
    r1: 96,
    r2: "-",
    r3: "-",
    r4: 95,
    r5: "96.4000015258789062",
    r6: "100",
    r13: 95.5,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "758",
    group: "ГП",
    programm_guid: "91",
    programm_name:
      "Социально-экономическое развитие Арктической зоны Российской Федерации",
    kpi_section: "РЕЗИДЕНТЫ\nАРКТИЧЕСКОЙ ЗОНЫ РОССИЙСКОЙ ФЕДЕРАЦИИ",
    kpi: "Создано рабочих мест",
    kpi_measure: "тыс. ед.",
    name: "Юрий Петрович Трутнев",
    r1: 10.600000381469727,
    r2: "-",
    r3: "-",
    r4: 5.3,
    r5: "6.40000038146972638",
    r6: "100",
    r13: 0.2999992370605469,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "925",
    group: "ГП",
    programm_guid: "108",
    programm_name: "Развитие энергетики",
    kpi_section: "ОБЕСПЕЧЕНИЕ ВНУТРЕННЕГО РЫНКА ЭНЕРГЕТИЧЕСКИМИ РЕСУРСАМИ",
    kpi: "Уровень газификации субъектов Российской Федерации",
    kpi_measure: "%",
    name: "Александр Валентинович Новак",
    r1: 74.69999694824219,
    r2: "-",
    r3: "-",
    r4: 0.7999999999999972,
    r5: "0.799999999999997158",
    r6: "100",
    r13: 0.8000030517578125,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "845",
    group: "ГП",
    programm_guid: "99",
    programm_name:
      "Социально-экономическое развитие Дальневосточного федерального округа",
    kpi_section: "СОЦИАЛЬНО\nЭКОНОМИЧЕСКОЕ РАЗВИТИЕ",
    kpi: "Новые рабочие места в ДФО",
    kpi_measure: "тыс. мест",
    name: "Юрий Петрович Трутнев",
    r1: 117.30000305175781,
    r2: "-",
    r3: "-",
    r4: 32.19999694824219,
    r5: "37.3000030517578125",
    r6: "100",
    r13: 4.799995422363281,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "846",
    group: "ГП",
    programm_guid: "99",
    programm_name:
      "Социально-экономическое развитие Дальневосточного федерального округа",
    kpi_section: "ИНВЕСТИЦИИ",
    kpi: "Инвестиции в ДФО",
    kpi_measure: "млрд. руб.",
    name: "Юрий Петрович Трутнев",
    r1: 2306.39990234375,
    r2: "-",
    r3: "-",
    r4: 596.999951171875,
    r5: "1131.99995117187495",
    r6: "100",
    r13: 0,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "852",
    group: "ГП",
    programm_guid: "99",
    programm_name:
      "Социально-экономическое развитие Дальневосточного федерального округа",
    kpi_section: "СОЦИАЛЬНО\nЭКОНОМИЧЕСКОЕ РАЗВИТИЕ",
    kpi: "Безработица",
    kpi_measure: "%",
    name: "Юрий Петрович Трутнев",
    r1: 5.5,
    r2: "-",
    r3: "-",
    r4: 5.900000095367432,
    r5: "5.90000009536743164",
    r6: "100",
    r13: 5.599999904632568,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "847",
    group: "ГП",
    programm_guid: "99",
    programm_name:
      "Социально-экономическое развитие Дальневосточного федерального округа",
    kpi_section: "СОЦИАЛЬНО\nЭКОНОМИЧЕСКОЕ РАЗВИТИЕ",
    kpi: "Постоянное население Дальнего Востока",
    kpi_measure: "тыс. чел.",
    name: "Юрий Петрович Трутнев",
    r1: 8184.5,
    r2: "-",
    r3: "-",
    r4: 36.39990234375,
    r5: "36.39990234375",
    r6: "100",
    r13: 5.10009765625,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "946",
    group: "ГП",
    programm_guid: "109",
    programm_name: "Развитие Северо-Кавказского федерального округа",
    kpi_section: "ГОРОДСКАЯ СРЕДА",
    kpi: "Доля качественных дорог в городских агломерациях",
    kpi_measure: "%",
    name: "Александр Валентинович Новак",
    r1: 86.5999984741211,
    r2: "-",
    r3: "-",
    r4: 4.5,
    r5: "7.3000030517578125",
    r6: "100",
    r13: 1,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "947",
    group: "ГП",
    programm_guid: "109",
    programm_name: "Развитие Северо-Кавказского федерального округа",
    kpi_section: "ГОРОДСКАЯ СРЕДА",
    kpi: "Доступ населения к качественной питьевой воде",
    kpi_measure: "%",
    name: "Александр Валентинович Новак",
    r1: 84.30000305175781,
    r2: "-",
    r3: "-",
    r4: 80.5999984741211,
    r5: "82.8000030517578125",
    r6: "100",
    r13: 81.5999984741211,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "941",
    group: "ГП",
    programm_guid: "109",
    programm_name: "Развитие Северо-Кавказского федерального округа",
    kpi_section: "СОЦИАЛЬНАЯ СФЕРА",
    kpi: "Доступность дошкольного образования для детей в возрасте от 2 месяцев до 3 лет",
    kpi_measure: "%",
    name: "Александр Валентинович Новак",
    r1: 100,
    r2: "-",
    r3: "-",
    r4: 86.80000305175781,
    r5: "90.0999984741210938",
    r6: "100",
    r13: 99.9000015258789,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "944",
    group: "ГП",
    programm_guid: "109",
    programm_name: "Развитие Северо-Кавказского федерального округа",
    kpi_section: "СОЦИАЛЬНО\nЭКОНОМИЧЕСКОЕ РАЗВИТИЕ",
    kpi: "Индекс реальных доходов населения к 2020 г.",
    kpi_measure: "%",
    name: "Александр Валентинович Новак",
    r1: 108.30000305175781,
    r2: "-",
    r3: "-",
    r4: 100.0999984741211,
    r5: "100.099998474121094",
    r6: "100",
    r13: 105.5999984741211,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "938",
    group: "ГП",
    programm_guid: "109",
    programm_name: "Развитие Северо-Кавказского федерального округа",
    kpi_section: "СОЦИАЛЬНАЯ СФЕРА",
    kpi: "Снижение младенческой смертности на 1 тыс. родившихся, промилле (убывающий)",
    kpi_measure: "промилле",
    name: "Александр Валентинович Новак",
    r1: 5.300000190734863,
    r2: "-",
    r3: "-",
    r4: 5.800000190734863,
    r5: "6",
    r6: "-",
    r13: 5.5,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "1054",
    group: "ГП",
    programm_guid: "121",
    programm_name: "Юстиция",
    kpi_section: "ЭЛЕКТРОННЫЕ ПРАВОВЫЕ УСЛУГИ",
    kpi: "Бесплатная юридическая помощь онлайн",
    kpi_measure: "%",
    name: "Григоренко Д.Ю.",
    r1: 15,
    r2: "-",
    r3: "-",
    r4: 3,
    r5: "0",
    r6: "0",
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "953",
    group: "ГП",
    programm_guid: "109",
    programm_name: "Развитие Северо-Кавказского федерального округа",
    kpi_section: "СОЦИАЛЬНО\nЭКОНОМИЧЕСКОЕ РАЗВИТИЕ",
    kpi: "Частные инвестиции на реализацию проектов ГП",
    kpi_measure: "млрд руб.",
    name: "Александр Валентинович Новак",
    r1: 18886.599609375,
    r2: "-",
    r3: "-",
    r4: 5948.7998046875,
    r5: "9352.2998046875",
    r6: "100",
    r13: 12876,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "937",
    group: "ГП",
    programm_guid: "109",
    programm_name: "Развитие Северо-Кавказского федерального округа",
    kpi_section: "СОЦИАЛЬНАЯ СФЕРА",
    kpi: "Ожидаемая продолжительность жизни при рождении, год",
    kpi_measure: "год",
    name: "Александр Валентинович Новак",
    r1: 76.69999694824219,
    r2: "-",
    r3: "-",
    r4: 74.9000015258789,
    r5: "77",
    r6: "100",
    r13: 76.30000305175781,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "1048",
    group: "ГП",
    programm_guid: "121",
    programm_name: "Юстиция",
    kpi_section: "ЭЛЕКТРОННЫЕ ПРАВОВЫЕ УСЛУГИ",
    kpi: "Доступность электронных госуслуг Минюста",
    kpi_measure: "%",
    name: "Григоренко Д.Ю.",
    r1: 13,
    r2: "-",
    r3: "-",
    r4: 5,
    r5: "5",
    r6: "100",
    r13: 2,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "936",
    group: "ГП",
    programm_guid: "109",
    programm_name: "Развитие Северо-Кавказского федерального округа",
    kpi_section: "СОЦИАЛЬНО\nЭКОНОМИЧЕСКОЕ РАЗВИТИЕ",
    kpi: "Рост численности населения (на 1 янв.)",
    kpi_measure: "тыс. чел.",
    name: "Александр Валентинович Новак",
    r1: 10040.599609375,
    r2: "-",
    r3: "-",
    r4: 24.200000000000728,
    r5: "236.499804687500728",
    r6: "100",
    r13: 0,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "955",
    group: "ГП",
    programm_guid: "109",
    programm_name: "Развитие Северо-Кавказского федерального округа",
    kpi_section: "СОЦИАЛЬНО\nЭКОНОМИЧЕСКОЕ РАЗВИТИЕ",
    kpi: "Созданные рабочие места в рамках проектов ГП",
    kpi_measure: "ед.",
    name: "Александр Валентинович Новак",
    r1: 6414,
    r2: "-",
    r3: "-",
    r4: 747,
    r5: "1304",
    r6: "100",
    r13: 2024,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "1174",
    group: "ГП",
    programm_guid: "131",
    programm_name: "Развитие федеративных отношений",
    kpi_section: "БЮДЖЕТНЫЕ ИНВЕСТИЦИИ",
    kpi: "Доля инвестиционных расходов в бюджетах регионов",
    kpi_measure: "%",
    name: "Марат Шакирзянович Хуснуллин",
    r1: 15,
    r2: "-",
    r3: "-",
    r4: 14.5,
    r5: "17.5",
    r6: "100",
    r13: 14.699999809265137,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "1175",
    group: "ГП",
    programm_guid: "131",
    programm_name: "Развитие федеративных отношений",
    kpi_section: "КАЧЕСТВО УПРАВЛЕНИЯ ФИНАНСАМИ",
    kpi: "Доля регионов, имеющих высокое качество управления финансами",
    kpi_measure: "%",
    name: "Марат Шакирзянович Хуснуллин",
    r1: 83.5,
    r2: "-",
    r3: "-",
    r4: 82.30000305175781,
    r5: "76.5",
    r6: "92.9526090621948242",
    r13: 82.30000305175781,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "1288",
    group: "ГП",
    programm_guid: "121",
    programm_name: "Юстиция",
    kpi_section: "УГОЛОВНО\nИСПОЛНИТЕЛЬНАЯ СИСТЕМА",
    kpi: "Соответствие условий содержания осужденных в лечебницах",
    kpi_measure: "%",
    name: "Григоренко Д.Ю.",
    r1: 72.69999694824219,
    r2: "-",
    r3: "-",
    r4: 0.49999847412109943,
    r5: "1.30000152587891193",
    r6: "100",
    r13: 0,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "1171",
    group: "ГП",
    programm_guid: "131",
    programm_name: "Развитие федеративных отношений",
    kpi_section: "ОБЕСПЕЧЕННОСТЬ РЕГИОНАЛЬНЫХ БЮДЖЕТОВ",
    kpi: "Разрыв между 10 наименее и наиболее обеспеченными регионами",
    kpi_measure: "единиц",
    name: "Марат Шакирзянович Хуснуллин",
    r1: 2.4000000953674316,
    r2: "-",
    r3: "-",
    r4: 2.5,
    r5: "2.29999995231628418",
    r6: "100",
    r13: 2.5,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "1173",
    group: "ГП",
    programm_guid: "131",
    programm_name: "Развитие федеративных отношений",
    kpi_section: "КАЧЕСТВО УПРАВЛЕНИЯ ФИНАНСАМИ",
    kpi: "Уровень долговой нагрузки по рыночным заимствованиям регионов",
    kpi_measure: "%",
    name: "Марат Шакирзянович Хуснуллин",
    r1: 25,
    r2: "-",
    r3: "-",
    r4: 25,
    r5: "5.80000019073486328",
    r6: "100",
    r13: 25,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "754",
    group: "ГП",
    programm_guid: "90",
    programm_name: "Развитие Калининградской области",
    kpi_section: "ТРАНСПОРТНАЯ ИНФРАСТРУКТУРА",
    kpi: "Протяженность дорог городских агломераций в нормативном состоянии",
    kpi_measure: "%",
    name: "Татьяна Алексеевна Голикова",
    r1: 85,
    r2: "-",
    r3: "-",
    r4: 5,
    r5: "6",
    r6: "100",
    r13: 4,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "756",
    group: "ГП",
    programm_guid: "90",
    programm_name: "Развитие Калининградской области",
    kpi_section: "ЭКОНОМИЧЕСКОЕ РАЗВИТИЕ",
    kpi: "Доходы населения",
    kpi_measure: "тыс. руб. ",
    name: "Татьяна Алексеевна Голикова",
    r1: 37,
    r2: "-",
    r3: "-",
    r4: 33,
    r5: "34.9000015258789062",
    r6: "100",
    r13: 34.900001525878906,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "753",
    group: "ГП",
    programm_guid: "90",
    programm_name: "Развитие Калининградской области",
    kpi_section: "ЭКОНОМИЧЕСКОЕ РАЗВИТИЕ",
    kpi: "Численность населения",
    kpi_measure: "тыс. чел.",
    name: "Татьяна Алексеевна Голикова",
    r1: 1034.5999755859375,
    r2: "-",
    r3: "-",
    r4: 5,
    r5: "10.9000244140625",
    r6: "100",
    r13: 0,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "1167",
    group: "ГП",
    programm_guid: "130",
    programm_name:
      "Управление государственными финансами и регулирование финансовых рынков",
    kpi_section: "ПРОЦЕССНЫЕ МЕРОПРИЯТИЯ",
    kpi: "Госдолг по отношению к ВВП",
    kpi_measure: "%",
    name: "Григоренко Д.Ю.",
    r1: 20,
    r2: "-",
    r3: "-",
    r4: 20,
    r5: "15.1000003814697266",
    r6: "75.4999995231628418",
    r13: 20,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "749",
    group: "ГП",
    programm_guid: "89",
    programm_name: "Реализация государственной национальной политики",
    kpi_section: " МЕЖНАЦИОНАЛЬНЫЕ ОТНОШЕНИЯ",
    kpi: "Доля граждан, не испытывающих в отношении себя дискриминации по признаку национальной, языковой или религиозной принадлежности",
    kpi_measure: "%",
    name: "Чернышенко Д.Н.",
    r1: 69,
    r2: "-",
    r3: "-",
    r4: 67.4000015258789,
    r5: "78.4000015258789062",
    r6: "100",
    r13: 68,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "1168",
    group: "ГП",
    programm_guid: "130",
    programm_name:
      "Управление государственными финансами и регулирование финансовых рынков",
    kpi_section: "ГОСФИНАНСЫ И ДОСТУПНОСТЬ ФИНРЫНКА",
    kpi: "Доля инвестиционных, страховых и пенсионных продуктов в сбережениях граждан",
    kpi_measure: "%",
    name: "Григоренко Д.Ю.",
    r1: 24,
    r2: "-",
    r3: "-",
    r4: 21,
    r5: "21.1000003814697266",
    r6: "100",
    r13: 22,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "1164",
    group: "ГП",
    programm_guid: "130",
    programm_name:
      "Управление государственными финансами и регулирование финансовых рынков",
    kpi_section: "ГОСФИНАНСЫ И ДОСТУПНОСТЬ ФИНРЫНКА",
    kpi: "Дефицит фед. бюджета по отношению к ВВП",
    kpi_measure: "%",
    name: "Григоренко Д.Ю.",
    r1: 1,
    r2: "-",
    r3: "-",
    r4: 5,
    r5: "4.59999990463256836",
    r6: "91.9999957084655762",
    r13: 2,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "1166",
    group: "ГП",
    programm_guid: "130",
    programm_name:
      "Управление государственными финансами и регулирование финансовых рынков",
    kpi_section: "ГОСФИНАНСЫ И ДОСТУПНОСТЬ ФИНРЫНКА",
    kpi: "Рост собираемости налогов, ввозной таможенной пошлины и страховых взносов к уровню 2020 г.",
    kpi_measure: "%",
    name: "Григоренко Д.Ю.",
    r1: 102.5999984741211,
    r2: "-",
    r3: "-",
    r4: 102.19999694824219,
    r5: "102.400001525878906",
    r6: "100",
    r13: 102.4000015258789,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "1165",
    group: "ГП",
    programm_guid: "130",
    programm_name:
      "Управление государственными финансами и регулирование финансовых рынков",
    kpi_section: "ПРОЦЕССНЫЕ МЕРОПРИЯТИЯ",
    kpi: "Качество управления бюджетным процессом",
    kpi_measure: "%",
    name: "Григоренко Д.Ю.",
    r1: 87,
    r2: "-",
    r3: "-",
    r4: 86,
    r5: "86",
    r6: "100",
    r13: 86.5,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "1169",
    group: "ГП",
    programm_guid: "130",
    programm_name:
      "Управление государственными финансами и регулирование финансовых рынков",
    kpi_section: "ГОСФИНАНСЫ И ДОСТУПНОСТЬ ФИНРЫНКА",
    kpi: "Рост доходов от управления фед. имуществом",
    kpi_measure: "%",
    name: "Григоренко Д.Ю.",
    r1: 126.5,
    r2: "-",
    r3: "-",
    r4: 131.8000030517578,
    r5: "125.300003051757812",
    r6: "95.0682878494262695",
    r13: 93.30000305175781,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "1062",
    group: "ГП",
    programm_guid: "121",
    programm_name: "Юстиция",
    kpi_section: "ОРГАНЫ ПРИНУДИТЕЛЬНОГО ИСПОЛНЕНИЯ",
    kpi: "Доля завершенных исполнительных производств",
    kpi_measure: "%",
    name: "Григоренко Д.Ю.",
    r1: 59.29999923706055,
    r2: "-",
    r3: "-",
    r4: 57,
    r5: "63.2999992370605469",
    r6: "100",
    r13: 58.20000076293945,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "1058",
    group: "ГП",
    programm_guid: "121",
    programm_name: "Юстиция",
    kpi_section: "УГОЛОВНО\nИСПОЛНИТЕЛЬНАЯ СИСТЕМА",
    kpi: "Привлечение осужденных к труду",
    kpi_measure: "%",
    name: "Григоренко Д.Ю.",
    r1: 55,
    r2: "-",
    r3: "-",
    r4: 50,
    r5: "63.0999984741210938",
    r6: "100",
    r13: 52.5,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "1056",
    group: "ГП",
    programm_guid: "121",
    programm_name: "Юстиция",
    kpi_section: "СИСТЕМА СУДЕБНО\nЭКСПЕРТНЫХ УЧРЕЖДЕНИЙ",
    kpi: "Объем своевременно выполненных экспертиз",
    kpi_measure: "%",
    name: "Григоренко Д.Ю.",
    r1: 94.5,
    r2: "-",
    r3: "-",
    r4: 93.5,
    r5: "99",
    r6: "100",
    r13: 94,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "759",
    group: "ГП",
    programm_guid: "91",
    programm_name:
      "Социально-экономическое развитие Арктической зоны Российской Федерации",
    kpi_section: "РЕЗИДЕНТЫ\nАРКТИЧЕСКОЙ ЗОНЫ РОССИЙСКОЙ ФЕДЕРАЦИИ",
    kpi: "Инвестиции резидентов Арктической зоны и ее ТОР",
    kpi_measure: "млрд руб.",
    name: "Юрий Петрович Трутнев",
    r1: 328.0465087890625,
    r2: "-",
    r3: "-",
    r4: 119.44000244140625,
    r5: "121.100006103515625",
    r6: "100",
    r13: 60.8699951171875,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "837",
    group: "ГП",
    programm_guid: "96",
    programm_name:
      "Социально-экономическое развитие Республики Крым и г. Севастополя",
    kpi_section: "ТУРИЗМ",
    kpi: "Прибывшие туристы",
    kpi_measure: "тыс. чел.",
    name: "Марат Шакирзянович Хуснуллин",
    r1: 10040,
    r2: "-",
    r3: "-",
    r4: 6980,
    r5: "7015.5",
    r6: "100",
    r13: 8720,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "838",
    group: "ГП",
    programm_guid: "96",
    programm_name:
      "Социально-экономическое развитие Республики Крым и г. Севастополя",
    kpi_section: "ИНВЕСТИЦИОННЫЙ КЛИМАТ",
    kpi: "Инвестиции на душу населения",
    kpi_measure: "тыс. руб. ",
    name: "Марат Шакирзянович Хуснуллин",
    r1: 144.5,
    r2: "-",
    r3: "-",
    r4: 105.80000305175781,
    r5: "0",
    r6: "0",
    r13: 125.19999694824219,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "834",
    group: "ГП",
    programm_guid: "96",
    programm_name:
      "Социально-экономическое развитие Республики Крым и г. Севастополя",
    kpi_section: "СОЦИАЛЬНАЯ СФЕРА",
    kpi: "Численность населения Республики Крым и г. Севастополь",
    kpi_measure: "тыс. чел.",
    name: "Марат Шакирзянович Хуснуллин",
    r1: 2550.199951171875,
    r2: "-",
    r3: "-",
    r4: 76,
    r5: "-2415",
    r6: "-",
    r13: 2518.10009765625,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "750",
    group: "ГП",
    programm_guid: "89",
    programm_name: "Реализация государственной национальной политики",
    kpi_section: " МЕЖНАЦИОНАЛЬНЫЕ ОТНОШЕНИЯ",
    kpi: "Доля граждан, не испытывающих негативного отношения к иностранным гражданам",
    kpi_measure: "%",
    name: "Чернышенко Д.Н.",
    r1: 75.0999984741211,
    r2: "-",
    r3: "-",
    r4: 74.69999694824219,
    r5: "80.9000015258789062",
    r6: "100",
    r13: 75,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "747",
    group: "ГП",
    programm_guid: "89",
    programm_name: "Реализация государственной национальной политики",
    kpi_section: " МЕЖНАЦИОНАЛЬНЫЕ ОТНОШЕНИЯ",
    kpi: "Доля граждан, положительно оценивающих состояние межнациональных (межэтнических) отношений",
    kpi_measure: "%",
    name: "Чернышенко Д.Н.",
    r1: 81.4000015258789,
    r2: "-",
    r3: "-",
    r4: 81.19999694824219,
    r5: "80.6999969482421875",
    r6: "99.3842363357543945",
    r13: 81.30000305175781,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "752",
    group: "ГП",
    programm_guid: "89",
    programm_name: "Реализация государственной национальной политики",
    kpi_section: "ОБЩЕРОССИЙСКАЯ ГРАЖДАНСКАЯ ИДЕНТИЧНОСТЬ",
    kpi: "Доля членов казачьих обществ на государственной и иной службе",
    kpi_measure: "%",
    name: "Чернышенко Д.Н.",
    r1: 77,
    r2: "-",
    r3: "-",
    r4: 75.5,
    r5: "80.5",
    r6: "100",
    r13: 76.5,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "748",
    group: "ГП",
    programm_guid: "89",
    programm_name: "Реализация государственной национальной политики",
    kpi_section: " МЕЖНАЦИОНАЛЬНЫЕ ОТНОШЕНИЯ",
    kpi: "Количество выявленных межэтнических и межрелигиозных противоречий",
    kpi_measure: "единиц",
    name: "Чернышенко Д.Н.",
    r1: 414,
    r2: "-",
    r3: "-",
    r4: 473,
    r5: "128",
    r6: "100",
    r13: 444,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "751",
    group: "ГП",
    programm_guid: "89",
    programm_name: "Реализация государственной национальной политики",
    kpi_section: "ОБЩЕРОССИЙСКАЯ ГРАЖДАНСКАЯ ИДЕНТИЧНОСТЬ",
    kpi: "Уровень общероссийской гражданской идентичности",
    kpi_measure: "%",
    name: "Чернышенко Д.Н.",
    r1: 84,
    r2: "-",
    r3: "-",
    r4: 82,
    r5: "94.1999969482421875",
    r6: "100",
    r13: 83,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "799",
    group: "ГП",
    programm_guid: "94",
    programm_name: "Научно-технологическое развитие Российской Федерации",
    kpi_section: "КАДРЫ ДЛЯ НАУКИ",
    kpi: "Кадровое обновление науки",
    kpi_measure: "%",
    name: "Чернышенко Д.Н.",
    r1: 47.5,
    r2: "-",
    r3: "-",
    r4: 45.5,
    r5: "43.9000015258789062",
    r6: "96.4835226535797119",
    r13: 46.5,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "823",
    group: "ГП",
    programm_guid: "94",
    programm_name: "Научно-технологическое развитие Российской Федерации",
    kpi_section: "РЕЗУЛЬТАТИВНОСТЬ НИ ДЕЯТЕЛЬНОСТИ",
    kpi: "Рост количества зарегистрированных патентов",
    kpi_measure: "тыс. ед.",
    name: "Чернышенко Д.Н.",
    r1: 9454,
    r2: "-",
    r3: "-",
    r4: 8666,
    r5: "8666",
    r6: "100",
    r13: 9060,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "807",
    group: "ГП",
    programm_guid: "94",
    programm_name: "Научно-технологическое развитие Российской Федерации",
    kpi_section: "СОВРЕМЕННАЯ ИНФРАСТРУКТУРА ДЛЯ НАУКИ",
    kpi: "Рост внебюджетного финансирования исследований и разработок",
    kpi_measure: "ед.",
    name: "Чернышенко Д.Н.",
    r1: 0.5799999833106995,
    r2: "-",
    r3: "-",
    r4: 0.5600000023841858,
    r5: "0.60000002384185791",
    r6: "100",
    r13: 0.5699999928474426,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "880",
    group: "ГП",
    programm_guid: "104",
    programm_name: "Национальная система пространственных данных",
    kpi_section: "АУДИТ ЗЕМЕЛЬНЫХ УЧАСТКОВ",
    kpi: "Доля решений о приостановлении осуществления государственной регистрации прав",
    kpi_measure: "%",
    name: "Марат Шакирзянович Хуснуллин",
    r1: 1.5,
    r2: "-",
    r3: "-",
    r4: 2.5,
    r5: "1.60000002384185791",
    r6: "100",
    r13: 2,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "881",
    group: "ГП",
    programm_guid: "104",
    programm_name: "Национальная система пространственных данных",
    kpi_section: "ГОСУСЛУГИ ПО КАДАСТРОВОМУ УЧЕТУ И РЕГИСТРАЦИИ ПРАВ",
    kpi: "Удовлетворенность граждан госуслугами по государственному кадастровому учету и регистрации прав",
    kpi_measure: "%",
    name: "Марат Шакирзянович Хуснуллин",
    r1: 99,
    r2: "-",
    r3: "-",
    r4: 99,
    r5: "99.8000030517578125",
    r6: "100",
    r13: 99,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "893",
    group: "ГП",
    programm_guid: "104",
    programm_name: "Национальная система пространственных данных",
    kpi_section: "АУДИТ ЗЕМЕЛЬНЫХ УЧАСТКОВ",
    kpi: "Площадь выявленных неиспользуемых или используемых не по назначению земельных участков в целях их вовлечения в экономический оборот",
    kpi_measure: "тыс. гектар",
    name: "Марат Шакирзянович Хуснуллин",
    r1: 310.5,
    r2: "-",
    r3: "-",
    r4: 27.099998474121094,
    r5: "205.399993896484375",
    r6: "100",
    r13: 0,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "885",
    group: "ГП",
    programm_guid: "104",
    programm_name: "Национальная система пространственных данных",
    kpi_section: "ЦИФРОВИЗАЦИЯ",
    kpi: "Создана единая электронная картографическая основа",
    kpi_measure: "%",
    name: "Марат Шакирзянович Хуснуллин",
    r1: 71.4000015258789,
    r2: "-",
    r3: "-",
    r4: 13.799998474121097,
    r5: "13.7999984741210966",
    r6: "100",
    r13: 11.900001525878906,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "1210",
    group: "ГП",
    programm_guid: "104",
    programm_name: "Национальная система пространственных данных",
    kpi_section: "ГОСУСЛУГИ ПО КАДАСТРОВОМУ УЧЕТУ И РЕГИСТРАЦИИ ПРАВ",
    kpi: "Сокращение срока государственного кадастрового учета и регистрации прав",
    kpi_measure: "день",
    name: "Марат Шакирзянович Хуснуллин",
    r1: 3,
    r2: "-",
    r3: "-",
    r4: 1,
    r5: "3.59999990463256836",
    r6: "100",
    r13: 0,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "902",
    group: "ГП",
    programm_guid: "105",
    programm_name: "Развитие туризма",
    kpi_section: "ТУРИСТИЧЕСКАЯ ИНФРАСТРУКТУРА",
    kpi: "Гранты на развитие туризма",
    kpi_measure: "у.е.",
    name: "Чернышенко Д.Н.",
    r1: 2765,
    r2: "-",
    r3: "-",
    r4: 813,
    r5: "930",
    r6: "100",
    r13: 533,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "903",
    group: "ГП",
    programm_guid: "105",
    programm_name: "Развитие туризма",
    kpi_section: "ДОСТУПНОСТЬ ТУРИСТИЧЕСКИХ ПРОДУКТОВ",
    kpi: "Туристы путешествуют по стране",
    kpi_measure: "млн чел.",
    name: "Чернышенко Д.Н.",
    r1: 75.48999786376953,
    r2: "-",
    r3: "-",
    r4: 59.45000076293945,
    r5: "60.7000007629394531",
    r6: "100",
    r13: 68.69999694824219,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "53136",
    group: "ГП",
    programm_guid: "104",
    programm_name: "Национальная система пространственных данных",
    kpi_section: "ЦИФРОВИЗАЦИЯ",
    kpi: "Доля госуслуг по государственному кадастровому учету и регистрации прав в электронном виде",
    kpi_measure: "%",
    name: "Марат Шакирзянович Хуснуллин",
    r1: 60,
    r2: "-",
    r3: "-",
    r5: "-",
    r6: "-",
    r13: 57,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "61363",
    group: "ГП",
    programm_guid: "94",
    programm_name: "Научно-технологическое развитие Российской Федерации",
    kpi_section: "ДОСТУПНОЕ КАЧЕСТВЕННОЕ ВЫСШЕЕ ОБРАЗОВАНИЕ",
    kpi: "Кадровое обновление профессорско-преподавательского состава",
    kpi_measure: "%",
    name: "Чернышенко Д.Н.",
    r1: 33,
    r2: "-",
    r3: "-",
    r5: "-",
    r6: "-",
    r13: 31.5,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "53088",
    group: "ГП",
    programm_guid: "96",
    programm_name:
      "Социально-экономическое развитие Республики Крым и г. Севастополя",
    kpi_section: "ИНФРАСТРУКТУРА",
    kpi: "Доля качественных дорог в городских агломерациях",
    kpi_measure: "%",
    name: "Марат Шакирзянович Хуснуллин",
    r1: 85,
    r2: "-",
    r3: "-",
    r5: "-",
    r6: "-",
    r13: 12.700000000000003,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "53207",
    group: "ГП",
    programm_guid: "110",
    programm_name:
      "Государственная программа развития сельского хозяйства и регулирования рынков сельскохозяйственной продукции, сырья и продовольствия",
    kpi_section: "ПРОИЗВОДСТВО",
    kpi: "Экспорт продукции АПК",
    kpi_measure: "млрд долл",
    name: "Абрамченко В.В.",
    r1: 29,
    r2: "-",
    r3: "-",
    r5: "-",
    r6: "-",
    r13: 28,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "59376",
    group: "ГП",
    programm_guid: "114",
    programm_name: "Защита населения и территорий от чрезвычайных ситуаций",
    kpi_section: "ПОВЫШЕНИЕ ЭФФЕКТИВНОСТИ СЛУЖБ ЧС",
    kpi: "Снижение риска возникновения аварий на опасных производственных объектах",
    kpi_measure: "%",
    name: "Денис Валентинович Мантуров",
    r1: 85,
    r2: "-",
    r3: "-",
    r5: "-",
    r6: "-",
    r13: 91,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "53137",
    group: "ГП",
    programm_guid: "104",
    programm_name: "Национальная система пространственных данных",
    kpi_section: "ЦИФРОВИЗАЦИЯ",
    kpi: "Площадь земельных участков, в отношении которых дистанционно проведены надзорные мероприятия",
    kpi_measure: "тыс. гектар",
    name: "Марат Шакирзянович Хуснуллин",
    r1: 2544.60009765625,
    r2: "-",
    r3: "-",
    r5: "-",
    r6: "-",
    r13: 2444.60009765625,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "53074",
    group: "ГП",
    programm_guid: "116",
    programm_name: "Развитие рыбохозяйственного комплекса",
    kpi_section: "ПРОИЗВОДСТВО РЫБНОЙ ПРОДУКЦИИ",
    kpi: "Объем производства товарной аквакультуры",
    kpi_measure: "тыс. т",
    name: "Абрамченко В.В.",
    r1: 400,
    r2: "-",
    r3: "-",
    r5: "-",
    r6: "-",
    r13: 383,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "53087",
    group: "ГП",
    programm_guid: "96",
    programm_name:
      "Социально-экономическое развитие Республики Крым и г. Севастополя",
    kpi_section: "ИНФРАСТРУКТУРА",
    kpi: "Улучшение жилищных условий семей",
    kpi_measure: "тыс. семей",
    name: "Марат Шакирзянович Хуснуллин",
    r1: 73.69999694824219,
    r2: "-",
    r3: "-",
    r5: "-",
    r6: "-",
    r13: 65.19999694824219,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "61361",
    group: "ГП",
    programm_guid: "94",
    programm_name: "Научно-технологическое развитие Российской Федерации",
    kpi_section: "ДОСТУПНОЕ КАЧЕСТВЕННОЕ ВЫСШЕЕ ОБРАЗОВАНИЕ",
    kpi: "Трудоустройство выпускников вузов",
    kpi_measure: "%",
    name: "Чернышенко Д.Н.",
    r1: 76,
    r2: "-",
    r3: "-",
    r5: "-",
    r6: "-",
    r13: 75,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ВП",
    kpi_guid: "1891",
    group: "ГП",
    programm_guid: "114",
    programm_name: "Защита населения и территорий от чрезвычайных ситуаций",
    kpi_section: "ПРОЦЕССНЫЕ МЕРОПРИЯТИЯ",
    kpi: "Укомплектованность МЧС России средствами ведения аварийно-спасательных работ",
    kpi_measure: "%",
    name: "Денис Валентинович Мантуров",
    r1: 86,
    r2: "-",
    r3: "-",
    r4: 0,
    r5: "-1",
    r6: "100",
    r13: 1,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ВП",
    kpi_guid: "1894",
    group: "ГП",
    programm_guid: "114",
    programm_name: "Защита населения и территорий от чрезвычайных ситуаций",
    kpi_section: "ПРОЦЕССНЫЕ МЕРОПРИЯТИЯ",
    kpi: "Уровень обеспечения МЧС России современными средствами ИКТ",
    kpi_measure: "%",
    name: "Денис Валентинович Мантуров",
    r1: 28.5,
    r2: "-",
    r3: "-",
    r4: 0,
    r5: "-0.5",
    r6: "100",
    r13: 7,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ВП",
    kpi_guid: "1499",
    group: "ГП",
    programm_guid: "112",
    programm_name:
      "Развитие судостроения и техники для освоения шельфовых месторождений",
    kpi_section: "СУДОСТРОЕНИЕ",
    kpi: "Патенты и другие документы на новые технологические решения",
    kpi_measure: "ед.",
    name: "Денис Валентинович Мантуров",
    r1: 25,
    r2: "-",
    r3: "-",
    r4: 14,
    r5: "23",
    r6: "100",
    r13: 12,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ВП",
    kpi_guid: "1409",
    group: "ГП",
    programm_guid: "115",
    programm_name: "Развитие транспортной системы",
    kpi_section: "ПРОЦЕССНЫЕ МЕРОПРИЯТИЯ",
    kpi: "Увеличение пропускной способности модернизируемых пунктов пропуска через границу страны",
    kpi_measure: "тыс. ед.",
    name: "Белоусов А.Р.",
    r1: 16455.80078125,
    r2: "-",
    r3: "-",
    r4: 11220.19970703125,
    r5: "0",
    r6: "0",
    r13: 11220.19970703125,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ВП",
    kpi_guid: "2342",
    group: "ГП",
    programm_guid: "89",
    programm_name: "Реализация государственной национальной политики",
    kpi_section: "ПРОЦЕССНЫЕ МЕРОПРИЯТИЯ",
    kpi: "Количество деструктивных информационных поводов в сфере межнациональных отношений",
    kpi_measure: "ед. ",
    name: "Чернышенко Д.Н.",
    r1: 2000,
    r2: "-",
    r3: "-",
    r4: 1000,
    r5: "2932",
    r6: "100",
    r13: 2000,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "КПМ",
    kpi_guid: "51572",
    group: "ГП",
    programm_guid: "106",
    programm_name: "Доступная среда",
    kpi_section: "ПРОЦЕССНЫЕ МЕРОПРИЯТИЯ",
    kpi: "Удовлетворенность медико-социальной экспертизой",
    kpi_measure: "%",
    name: "Татьяна Алексеевна Голикова",
    r1: 90,
    r2: "-",
    r3: "-",
    r5: "-",
    r6: "-",
    r13: 90,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "КПМ",
    kpi_guid: "1353",
    group: "ГП",
    programm_guid: "113",
    programm_name:
      "Обеспечение общественного порядка и противодействие преступности",
    kpi_section: "ПРОЦЕССНЫЕ МЕРОПРИЯТИЯ",
    kpi: "Преступления в сфере незаконного оборота наркотиков, совершенные организованными группами",
    kpi_measure: "%",
    name: "Денис Валентинович Мантуров",
    r1: 17,
    r2: "-",
    r3: "-",
    r4: 16,
    r5: "18.7000007629394531",
    r6: "100",
    r13: 16.5,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "КПМ",
    kpi_guid: "1861",
    group: "ГП",
    programm_guid: "100",
    programm_name: "Развитие культуры",
    kpi_section: "ПРОЦЕССНЫЕ МЕРОПРИЯТИЯ",
    kpi: "Число посетителей федеральных музеев",
    kpi_measure: "тыс. чел.",
    name: "Татьяна Алексеевна Голикова",
    r1: 333917.5,
    r2: "-",
    r3: "-",
    r4: 32628.30078125,
    r5: "39255.80078125",
    r6: "100",
    r13: 324260.1875,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "КПМ",
    kpi_guid: "2040",
    group: "ГП",
    programm_guid: "100",
    programm_name: "Развитие культуры",
    kpi_section: "ПРОЦЕССНЫЕ МЕРОПРИЯТИЯ",
    kpi: "Число зрителей на мероприятиях федеральных театров, концертных и иных организаций исполнительских искусств",
    kpi_measure: "тыс. чел.",
    name: "Татьяна Алексеевна Голикова",
    r1: 2500,
    r2: "-",
    r3: "-",
    r4: 2500,
    r5: "4856.5",
    r6: "100",
    r13: 2500,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "КПМ",
    kpi_guid: "17977",
    group: "ГП",
    programm_guid: "111",
    programm_name: "Охрана окружающей среды",
    kpi_section: "ПРОЦЕССНЫЕ МЕРОПРИЯТИЯ",
    kpi: "Площадь заповедных территорий",
    kpi_measure: "%",
    name: "Абрамченко В.В.",
    r1: 14,
    r2: "-",
    r3: "-",
    r4: 0,
    r5: "-",
    r6: "-",
    r13: 0.5,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "КПМ",
    kpi_guid: "1448",
    group: "ГП",
    programm_guid: "116",
    programm_name: "Развитие рыбохозяйственного комплекса",
    kpi_section: "ПРОЦЕССНЫЕ МЕРОПРИЯТИЯ",
    kpi: "Выращено молоди",
    kpi_measure: "млн. шт.",
    name: "Абрамченко В.В.",
    r1: 1706.2900390625,
    r2: "-",
    r3: "-",
    r4: 1724.43994140625,
    r5: "1964.4000244140625",
    r6: "100",
    r13: 1703.8699951171875,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "КПМ",
    kpi_guid: "1358",
    group: "ГП",
    programm_guid: "116",
    programm_name: "Развитие рыбохозяйственного комплекса",
    kpi_section: "ПРОЦЕССНЫЕ МЕРОПРИЯТИЯ",
    kpi: "Уровень цифровизации госуслуг",
    kpi_measure: "%",
    name: "Абрамченко В.В.",
    r1: 70,
    r2: "-",
    r3: "-",
    r4: 70,
    r5: "76.6999969482421875",
    r6: "100",
    r13: 70,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "КПМ",
    kpi_guid: "2009",
    group: "ГП",
    programm_guid: "108",
    programm_name: "Развитие энергетики",
    kpi_section: "СОЦИАЛЬНАЯ ПОДДЕРЖКА",
    kpi: "Распространение модели альтернативной котельной",
    kpi_measure: "муниципальные образования",
    name: "Александр Валентинович Новак",
    r1: 38,
    r2: "-",
    r3: "-",
    r4: 24,
    r5: "36",
    r6: "100",
    r13: 36,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "КПМ",
    kpi_guid: "2209",
    group: "ГП",
    programm_guid: "131",
    programm_name: "Развитие федеративных отношений",
    kpi_section: "ПРОЦЕССНЫЕ МЕРОПРИЯТИЯ",
    kpi: "Доля просроченной кредиторской задолженности в расходах консолидированных бюджетов регионов",
    kpi_measure: "%",
    name: "Марат Шакирзянович Хуснуллин",
    r1: 0.11999999731779099,
    r2: "-",
    r3: "-",
    r4: 0.14000000059604645,
    r5: "0.100000001490116119",
    r6: "100",
    r13: 0.12999999523162842,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "КПМ",
    kpi_guid: "2210",
    group: "ГП",
    programm_guid: "131",
    programm_name: "Развитие федеративных отношений",
    kpi_section: "ПРОЦЕССНЫЕ МЕРОПРИЯТИЯ",
    kpi: "Исполнение регионами полномочий в целях выполнения субвенций",
    kpi_measure: "%",
    name: "Марат Шакирзянович Хуснуллин",
    r1: 100,
    r2: "-",
    r3: "-",
    r4: 100,
    r5: "100",
    r6: "100",
    r13: 100,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "КПМ",
    kpi_guid: "15029",
    group: "ГП",
    programm_guid: "130",
    programm_name:
      "Управление государственными финансами и регулирование финансовых рынков",
    kpi_section: "ПРОЦЕССНЫЕ МЕРОПРИЯТИЯ",
    kpi: "Исполнение фед. бюджета",
    kpi_measure: "%",
    name: "Григоренко Д.Ю.",
    r1: 97.80000305175781,
    r2: "-",
    r3: "-",
    r4: 97.5999984741211,
    r5: "97.5",
    r6: "99.8975396156311035",
    r13: 97.69999694824219,
    r14: "-",
    r15: "-",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "-",
    group: "ГП",
    programm_guid: "100",
    programm_name: "Развитие культуры",
    kpi_section: "ПРОЦЕССНЫЕ МЕРОПРИЯТИЯ",
    kpi: "Количество зрителей на мероприятиях организаций циркового искусства",
    kpi_measure: "тыс. чел.",
    name: "Татьяна Алексеевна Голикова",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "НП",
    kpi_guid: "718",
    group: "ГП",
    programm_guid: "87",
    programm_name: "Информационное общество",
    kpi_section: "УРОВЕНЬ ЦИФРОВИЗАЦИИ",
    kpi: "Цифровая зрелость социальной и экономической сфер",
    kpi_measure: "%",
    name: "Чернышенко Д.Н.",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ФП",
    kpi_guid: "999",
    group: "ГП",
    programm_guid: "113",
    programm_name:
      "Обеспечение общественного порядка и противодействие преступности",
    kpi_section: "ПРОТИВОДЕЙСТВИЕ ПРЕСТУПНОСТИ",
    kpi: "Тяжкие и особо тяжкие преступления в общественных местах",
    kpi_measure: "%",
    name: "Денис Валентинович Мантуров",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "826",
    group: "ГП",
    programm_guid: "94",
    programm_name: "Научно-технологическое развитие Российской Федерации",
    kpi_section: "РЕЗУЛЬТАТИВНОСТЬ НИ ДЕЯТЕЛЬНОСТИ",
    kpi: "Отечественные технологии для бизнеса",
    kpi_measure: "тыс. ед.",
    name: "Чернышенко Д.Н.",
    calc_period: "2023-02",
  },
  {
    class_kpi: "Показатель",
    attribute: "ГП",
    kpi_guid: "282774",
    group: "ГП",
    programm_guid: "123",
    programm_name: "Социальная поддержка граждан",
    kpi_section: "СЕМЬИ С ДЕТЬМИ",
    kpi: "Нуждающиеся семьи получили ежемесячное пособие в связи с рождением и воспитанием ребенка",
    kpi_measure: "тыс. детей",
    name: "Татьяна Алексеевна Голикова",
    calc_period: "2023-02",
  },
];
