import { ISEParams } from "./index";

export const getDataSE = (params: ISEParams) => [
  {
    requestId: "8756bac8-222c-4322-b5e7-70228d2854be",
    type: "data2",
    params: {
      modelUuid: "2c254539-3926-46df-86c8-70768c7465f8",
      dashboardUuid: "",
      includeGaps: false,
      dimensions: [
        {
          id: "5379",
          includeItems: true,
          includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
        },
        {
          id: "5380",
          includeItems: true,
          includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
        },
        {
          id: "5381",
          includeItems: true,
          includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
        },
      ],
      indicators: {
        id: "5383",
        items: [
          {
            id: "5384",
            aggregationFunction: "sum",
          },
          {
            id: "5385",
            aggregationFunction: "sum",
          },
          {
            id: "5386",
            aggregationFunction: "sum",
          },
          {
            id: "5486",
            aggregationFunction: "sum",
          },
          {
            id: "5487",
            aggregationFunction: "sum",
          },
        ],
      },
      dataRequestCaching: true,
      dataFilter: {
        type: "AND",
        version: 1,
        operands: [
          {
            type: "EQ",
            version: 1,
            operands: [
              {
                type: "DIM",
                version: 1,
                id: "5379",
              },
              {
                type: "CONST",
                version: 1,
                values: [params.period],
              },
            ],
          },
          {
            type: "EQ",
            version: 1,
            operands: [
              {
                type: "DIM",
                version: 2,
                id: "5380",
                attributeCode: "gp_code",
              },
              {
                type: "TEXT",
                version: 2,
                values: [params.programId],
              },
            ],
          },
          {
            type: "EQ",
            version: 1,
            operands: [
              {
                type: "DIM",
                version: 2,
                id: "5381",
                attributeCode: "VersionMetaId",
              },
              {
                type: "TEXT",
                version: 2,
                values: [params.id],
              },
            ],
          },
        ],
      },
    },
  },
];

export const getTasksSE = (params: ISEParams) => [
  {
    requestId: "be15a321-8f4f-4d6c-971e-d9d846b26f92",
    type: "data2",
    params: {
      modelUuid: "40d45cfc-6b16-407b-a1c4-f90532b17eaa",
      dashboardUuid: "",
      includeGaps: false,
      dimensions: [
        {
          id: "5395",
          includeItems: true,
          includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
        },
        {
          id: "5396",
          includeItems: true,
          includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
        },
        {
          id: "5397",
          includeItems: true,
          includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
        },
      ],
      indicators: {
        id: "5398",
        items: [
          {
            id: "5399",
            aggregationFunction: "sum",
          },
          {
            id: "5400",
            aggregationFunction: "sum",
          },
        ],
      },
      dataRequestCaching: true,
      dataFilter: {
        type: "AND",
        version: 1,
        operands: [
          {
            type: "AND",
            version: 1,
            operands: [
              {
                type: "STARTER",
                version: 1,
                dim_id: "5395",
              },
              {
                type: "STARTER",
                version: 1,
                dim_id: "5397",
              },
            ],
          },
          {
            type: "EQ",
            version: 1,
            operands: [
              {
                type: "DIM",
                version: 2,
                id: "5396",
                attributeCode: "SE_CODE",
              },
              {
                type: "TEXT",
                version: 2,
                values: [params.id],
              },
            ],
          },
        ],
      },
    },
  },
];

export const getEventsSE = (params: ISEParams) => [
  {
    requestId: "ccdaaa22-5ca8-426b-832e-4b09d34a4b45",
    type: "data2",
    params: {
      modelUuid: "f0c00f6b-d2fa-4a9a-9f57-258a0e88806d",
      dashboardUuid: "",
      includeGaps: false,
      dimensions: [
        {
          id: "1344",
          includeItems: true,
          includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
        },
        {
          id: "1348",
          includeItems: true,
          includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
        },
        {
          id: "5467",
          includeItems: true,
          includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
        },
      ],
      indicators: {
        id: "1351",
        items: [
          {
            id: "1352",
            aggregationFunction: "sum",
          },
          {
            id: "1358",
            aggregationFunction: "sum",
          },
          {
            id: "1359",
            aggregationFunction: "sum",
          },
          {
            id: "1366",
            stringAggregation: true,
            separator: ";",
          },
          {
            id: "1367",
            stringAggregation: true,
            separator: ";",
          },
          {
            id: "5307",
            aggregationFunction: "sum",
          },
          {
            id: "5308",
            aggregationFunction: "sum",
          },
          {
            id: "5468",
            aggregationFunction: "sum",
          },
          {
            id: "5469",
            aggregationFunction: "sum",
          },
        ],
      },
      dataRequestCaching: true,
      dataFilter: {
        type: "AND",
        version: 1,
        operands: [
          {
            type: "STARTER",
            version: 1,
            dim_id: "1348",
          },
          {
            type: "AND",
            version: 1,
            operands: [
              {
                type: "EQ",
                version: 1,
                operands: [
                  {
                    type: "DIM",
                    version: 1,
                    id: "1344",
                  },
                  {
                    type: "CONST",
                    version: 1,
                    values: [params.period],
                  },
                ],
              },
              {
                type: "EQ",
                version: 1,
                operands: [
                  {
                    type: "DIM",
                    version: 2,
                    id: "5467",
                    attributeCode: "SE_CODE",
                  },
                  {
                    type: "TEXT",
                    version: 2,
                    values: [params.id],
                  },
                ],
              },
            ],
          },
        ],
      },
    },
  },
];

export const getIndicatorsSE = (params: ISEParams) => [
  {
    requestId: "aa3f5d2a-620c-4c0f-a8b2-0bd322f19248",
    type: "data2",
    params: {
      modelUuid: "dbd43734-dc29-4fc4-a16a-144efa1a7d62",
      dashboardUuid: "",
      includeGaps: false,
      dimensions: [
        {
          id: "5211",
          includeItems: true,
          includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
        },
        {
          id: "5219",
          includeItems: true,
          includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
        },
      ],
      indicators: {
        id: "5239",
        items: [
          {
            id: "5240",
            aggregationFunction: "sum",
          },
          {
            id: "5241",
            aggregationFunction: "sum",
          },
          {
            id: "5244",
            aggregationFunction: "sum",
          },
          {
            id: "5470",
            aggregationFunction: "sum",
          },
          {
            id: "5471",
            aggregationFunction: "sum",
          },
        ],
      },
      dataRequestCaching: true,
      dataFilter: {
        type: "AND",
        version: 1,
        operands: [
          {
            type: "EQ",
            version: 1,
            operands: [
              {
                type: "DIM",
                version: 1,
                id: "5211",
              },
              {
                type: "CONST",
                version: 1,
                values: [params.period],
              },
            ],
          },
          {
            type: "EQ",
            version: 1,
            operands: [
              {
                type: "DIM",
                version: 2,
                id: "5219",
                attributeCode: "SE_CODE",
              },
              {
                type: "TEXT",
                version: 2,
                values: [params.id],
              },
            ],
          },
        ],
      },
    },
  },
];
