export enum MINISTRY_TABS {
  INDICATORS,
  EVENTS,
  STRUCTURE,
}

export type MinistryTabValue =
  `${MINISTRY_TABS}` extends `${infer V extends number}` ? V : never;

export enum performanceColumn {
  ACHIEVE_FOIV = "performance",
  ACHIEVE_OM = "performanceOM",
}
