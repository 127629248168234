import { getDataSumByCodeGP } from "SRC/constants";
import { isSecret } from "SRC/helpers/dataTesters";
import { IProgramTableItem } from "SRC/hooks/summaryTable/interfaces";
import { checkRow } from "SRC/hooks/summaryTable/utils/utils";
import { generalRowsSummaryTableData } from "SRC/hooks/summaryTable/utils/utils";
import { TProgram } from "SRC/redux/slices/summary/hooks/usePrograms";
import { CustomRowProps } from "SRC/types/data/data";

export const createProgramRow = (data: TProgram): IProgramTableItem => {
  const { info } = data;
  const elem = { ...data, data: Object.values(data.data) };

  return {
    id: info.code,
    icon: info.icon,
    name: info.shortName,
    govProgram: info.shortName,
    isProgram: true,
    secretProgram: isSecret(elem),
    // Структурные элементы
    structureElementsPlan: getDataSumByCodeGP(elem, 1572) ?? null,
    structureElementsPlanTotal: getDataSumByCodeGP(elem, 1575) ?? null,
    structureElementsOMPlan: getDataSumByCodeGP(elem, 5278) ?? null,
    structureElementsOMPlanTotal: getDataSumByCodeGP(elem, 5281) ?? null,
    structureElementsFact: getDataSumByCodeGP(elem, 1573) ?? null,
    structureElementsFactTotal: getDataSumByCodeGP(elem, 1576) ?? null,
    structureElementsOMFact: getDataSumByCodeGP(elem, 5279) ?? null,
    structureElementsOMFactTotal: getDataSumByCodeGP(elem, 5282) ?? null,
    structureElementsFail: getDataSumByCodeGP(elem, 1574) ?? null,
    structureElementsFailTotal: getDataSumByCodeGP(elem, 5479) ?? null,
    structureElementsOMFail: getDataSumByCodeGP(elem, 5280) ?? null,
    structureElementsOMFailTotal: getDataSumByCodeGP(elem, 1577) ?? null,
    eventsRisk: getDataSumByCodeGP(elem, 1680) ?? null,
    vp: info.vpCode,
    premier: info.vp,
    ministry: info.ministry,
    performance: getDataSumByCodeGP(elem, 1796) ?? null,
    performanceTotal: getDataSumByCodeGP(elem, 5246) ?? null,
    populationSatisfaction: getDataSumByCodeGP(elem, 1765) ?? null,
    cashPlan: getDataSumByCodeGP(elem, 1590) ?? null,
    cashPlanTotal: getDataSumByCodeGP(elem, 1593) ?? null,
    cashFact: getDataSumByCodeGP(elem, 1591) ?? null,
    cashFactTotal: getDataSumByCodeGP(elem, 1594) ?? null,
    goalsPlan: getDataSumByCodeGP(elem, 1755) ?? null,
    // goalsPlanTotal: getDataSumByCodeGP(elem, 1755) ?? null,
    goalsDone: getDataSumByCodeGP(elem, 1756) ?? null,
    // goalsDoneTotal: getDataSumByCodeGP(elem, 1756) ?? null,
    goalsFail: getDataSumByCodeGP(elem, 1757) ?? null,
    // goalsFailTotal: getDataSumByCodeGP(elem, 1757) ?? null,
    // Показатели
    indicatorsPlan: getDataSumByCodeGP(elem, 1578) ?? null,
    indicatorsPlanTotal: getDataSumByCodeGP(elem, 1581) ?? null,
    indicatorsOMPlan: getDataSumByCodeGP(elem, 5283) ?? null,
    indicatorsOMPlanTotal: getDataSumByCodeGP(elem, 5286) ?? null,
    indicatorsFact: getDataSumByCodeGP(elem, 1579) ?? null,
    indicatorsFactTotal: getDataSumByCodeGP(elem, 1582) ?? null,
    indicatorsOMFact: getDataSumByCodeGP(elem, 5284) ?? null,
    indicatorsOMFactTotal: getDataSumByCodeGP(elem, 5287) ?? null,
    indicatorsFail: getDataSumByCodeGP(elem, 1580) ?? null,
    indicatorsFailTotal: getDataSumByCodeGP(elem, 1583) ?? null,
    indicatorsOMFail: getDataSumByCodeGP(elem, 5285) ?? null,
    indicatorsOMFailTotal: getDataSumByCodeGP(elem, 5288) ?? null,
    // Показатели СЭ Факт
    indicatorsSEFact: getDataSumByCodeGP(elem, 5296) ?? null,
    indicatorsSEFactTotal: getDataSumByCodeGP(elem, 5297) ?? null,
    indicatorsSEOMFact: getDataSumByCodeGP(elem, 5494) ?? null,
    indicatorsSEOMFactTotal: getDataSumByCodeGP(elem, 5495) ?? null,
    // Показатели СЭ план
    indicatorsSEPlan: getDataSumByCodeGP(elem, 5204) ?? null,
    indicatorsSEPlanTotal: getDataSumByCodeGP(elem, 5295) ?? null,
    indicatorsSEOMPlan: getDataSumByCodeGP(elem, 5492) ?? null,
    indicatorsSEOMPlanTotal: getDataSumByCodeGP(elem, 5493) ?? null,
    // Показатели СЭ не выполнено
    indicatorsSEFail: getDataSumByCodeGP(elem, 5298) ?? null,
    indicatorsSEFailTotal: getDataSumByCodeGP(elem, 5299) ?? null,
    indicatorsSEOMFail: getDataSumByCodeGP(elem, 5496) ?? null,
    indicatorsSEOMFailTotal: getDataSumByCodeGP(elem, 5497) ?? null,

    eventsPlan: getDataSumByCodeGP(elem, 1584) ?? null,
    eventsPlanTotal: getDataSumByCodeGP(elem, 1587) ?? null,
    eventsFact: getDataSumByCodeGP(elem, 1585) ?? null,
    eventsFactTotal: getDataSumByCodeGP(elem, 1588) ?? null,
    eventsFail: getDataSumByCodeGP(elem, 1586) ?? null,
    eventsFailTotal: getDataSumByCodeGP(elem, 1589) ?? null,
    eventsOMPlan: getDataSumByCodeGP(elem, 5289) ?? null,
    eventsOMPlanTotal: getDataSumByCodeGP(elem, 5292) ?? null,
    eventsOMFact: getDataSumByCodeGP(elem, 5290) ?? null,
    eventsOMFactTotal: getDataSumByCodeGP(elem, 5293) ?? null,
    eventsOMFail: getDataSumByCodeGP(elem, 5291) ?? null,
    eventsOMFailTotal: getDataSumByCodeGP(elem, 5294) ?? null,
    checkpointsPlan: getDataSumByCodeGP(elem, 5207) ?? null,
    checkpointsPlanTotal: getDataSumByCodeGP(elem, 5250) ?? null,
    checkpointsFact: getDataSumByCodeGP(elem, 5208) ?? null,
    checkpointsFactTotal: getDataSumByCodeGP(elem, 5251) ?? null,
    checkpointsFail: getDataSumByCodeGP(elem, 5209) ?? null,
    checkpointsFailTotal: getDataSumByCodeGP(elem, 5252) ?? null,
    //Контрольные точки ОМ
    checkpointsOMFact: getDataSumByCodeGP(elem, 5480) ?? null,
    checkpointsOMFactTotal: getDataSumByCodeGP(elem, 5481) ?? null,
    checkpointsOMPlan: getDataSumByCodeGP(elem, 5482) ?? null,
    checkpointsOMPlanTotal: getDataSumByCodeGP(elem, 5483) ?? null,
    checkpointsOMFail: getDataSumByCodeGP(elem, 5484) ?? null,
    checkpointsOMFailTotal: getDataSumByCodeGP(elem, 5485) ?? null,
    // Уровень достижения особое мнение
    performanceOM: getDataSumByCodeGP(elem, 5265) ?? null,
    // Уровень достижения особое мнение итого
    performanceOMTotal: getDataSumByCodeGP(elem, 5269) ?? null,
  };
};

export const createProgramsTableData = (
  data: TProgram[]
): IProgramTableItem[] => data.map((elem: TProgram) => createProgramRow(elem));

export const getGovProgramsValue = (elem: CustomRowProps) => {
  const { govPrograms } = elem.original;
  const { isSecret } = checkRow(elem);
  return isSecret ? "" : govPrograms;
};

export const createGpRowsTableData = (
  data: CustomRowProps[],
  isOM: boolean
) => {
  return data.map((elem: CustomRowProps) => {
    return {
      id: elem.original.id,
      govProgram: elem.original.govProgram,
      premier: elem.original.premier,
      ministry: elem.original.ministry,
      ...generalRowsSummaryTableData(elem, isOM),
    };
  });
};
