import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createPeriodDims, parseOlapdata, TDecoder } from "CORE/utils";
import { isContainedArray } from "SRC/helpers/dataTesters";
import { IPeriod } from "SRC/redux/slices/global/slices/filters";
import { getPremiers } from "SRC/redux/slices/main/api/client";
import { ICodeItem } from "SRC/types";

export interface IPremier {
  info: {
    VP_NAME: string;
    VP_CODE: string;
    VP_PHOTO_URL: string;
    VP_SHORT_NAME: string;
    VP_FOIV: string;
    code: string;
  };
  data: ICodeItem[];
}

export interface IPremiersState {
  items: IPremier[];
  elems: IPremier[];
  fetching: boolean;
  fetched: boolean;
  error: string;
}

export const extraActions = {
  get: createAsyncThunk(
    "premiers/get",
    async (period: IPeriod) => await getPremiers(period)
  ),
};

const initialState: IPremiersState = {
  items: [],
  elems: [],
  fetching: false,
  fetched: false,
  error: "",
};

export const slice = createSlice({
  name: "premiers",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActions.get.pending, (state) => {
        state.items = [];
        state.fetching = true;
        state.fetched = false;
      })
      .addCase(extraActions.get.fulfilled, (state: IPremiersState, action) => {
        const parsed = parseOlapdata(
          // @ts-ignore
          action.payload.data,
          decoder
        ) as unknown as IPremier[];
        state.items = (isContainedArray(parsed) ? parsed : []) as IPremier[];
        // TODO [пофиксить]: проблема с интерфейсами запросов
        // @ts-ignore
        // state.items = (isContainedArray(data) ? data : []) as IPremier[];
        state.fetching = false;
        state.fetched = true;
      })
      .addCase(extraActions.get.rejected, (state, action) => {
        state.fetching = false;
        // state.error = action.error;
      });
  },
});

export const actions = {
  ...slice.actions,
  ...extraActions,
};

const decoder: TDecoder = {
  period: createPeriodDims(1683),
  info: {
    code: 1686,
    dimensions: {
      NAME: "NAME",
      VP_CODE: "VP_CODE",
      VP_FOIV: "VP_FOIV",
      VP_NAME: "VP_NAME",
      VP_PHOTO_URL: "VP_PHOTO_URL",
      VP_POST: "VP_POST",
      VP_SHORT_NAME: "VP_SHORT_NAME",
    },
  },
};
