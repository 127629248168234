import { useMemo } from "react";
import {
  IPremiersData,
  premiersData as premiers,
} from "SRC/pages/Priorities/utils/premiersData";
import {
  IPrioritiesData,
  prioritiesData,
} from "SRC/pages/Priorities/utils/prioritiesData";
import {
  IProgramData,
  programData as programs,
} from "SRC/pages/Priorities/utils/programData";
import {
  IGlobalFilters,
  useGlobalFilters,
} from "SRC/redux/slices/global/hooks/useFilters";
import {
  IFilters,
  useFilters,
} from "SRC/redux/slices/priorities/hooks/useFilters";

interface IUseTableData {
  priorityData: IPrioritiesData[];
  programsData: IProgramData[];
  premiersData: IPremiersData[];
}

export const usePrioritiesTableData = (): IUseTableData => {
  const { sources }: IGlobalFilters = useGlobalFilters();
  const { vp, program }: IFilters = useFilters();

  const priorityData = useMemo(() => {
    return prioritiesData.filter((item) => {
      const isProgram = !program || program === item.programm_name;
      const isVp = !vp || vp === item.name;

      return isProgram && isVp;
    });
  }, [vp, program, sources.OM]);

  const programsData = useMemo(() => {
    return programs.filter((item) => {
      const isVp = !vp || vp === item.vicePremier;

      return isVp;
    });
  }, [vp, sources.OM]);

  const premiersData = useMemo(() => {
    return premiers.filter((item) => {
      const isVp = !vp || vp === item.name;

      return isVp;
    });
  }, [vp, sources.OM]);

  return {
    priorityData,
    programsData,
    premiersData,
  };
};
