import { SecretProgram } from "ASSETS/svg";
import React from "react";
import { OverlayBlock } from "SRC/components/OverlayBlock";
import { PerformanceValue } from "SRC/components/PerformanceValue/PerformanceValue";
import { getRoundValue } from "SRC/helpers/getRoundValue";

import { TooltipText } from "../TooltipText/TooltipText";
import { IAchievementData } from "./interfaces";

interface IAchievementsTileProps {
  data: IAchievementData;
  withDarkBgr?: boolean | undefined;
  fetching: boolean;
}

export const AchievementsTile = ({
  data,
  fetching,
}: IAchievementsTileProps) => {
  return (
    <OverlayBlock isFetching={fetching} hasData={Boolean(data)}>
      <>
        <div className="icon-container">
          <div className="icon">
            <img src={data.url} aria-hidden alt="icon" />
          </div>
          {data.secret && (
            <div className="secret-program">
              <SecretProgram />
            </div>
          )}
        </div>
        <div className="info-container">
          <div className="title">
            {data.code && <span> {data.code || ""}.</span>}
            <TooltipText hint={data.name} variant="h3">
              {data.name}
            </TooltipText>
          </div>
          <div className="percent-wrapper">
            <div className="percents">
              <PerformanceValue
                value={getRoundValue(data.sum || 0, 2)}
                sameSize={true}
                className="performance-value"
              />
              <span> %</span>
            </div>
          </div>
        </div>
      </>
    </OverlayBlock>
  );
};
