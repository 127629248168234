import { Search } from "@mui/icons-material";
import {
  FilledInput,
  FormControl,
  InputAdornment,
  useTheme,
} from "@mui/material";
import React, { useCallback, useEffect, useRef } from "react";
import { TableInstance } from "react-table";
import { css } from "SRC/pages/Main/common/ComplexGrid/ComplexGrid.styled";

import {
  NumOfResultsContainer,
  NumOfResultsLabel,
  NumOfResultsText,
  SearchContainer,
  tableSearchStyled,
} from "./TableSearch.styled";

interface IProps {
  instance: TableInstance | undefined;
  dataLength: number;
}

export const TableSearch = ({ instance, dataLength }: IProps) => {
  const inputRef = useRef<HTMLInputElement>();
  const theme = useTheme();

  useEffect(() => {
    if (instance && inputRef?.current) {
      inputRef.current.value = instance.state.globalFilter;
    }
  }, [instance?.state, inputRef]);

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      instance?.setGlobalFilter(event.target.value);
    },
    [instance]
  );

  return (
    <SearchContainer>
      <FormControl
        sx={css.uiSearch}
        variant="filled"
        style={{ width: "100%", maxWidth: "100%" }}
      >
        <FilledInput
          startAdornment={
            <InputAdornment position="start">
              <Search
                sx={{
                  color: theme.palette.main.gray,
                  width: "1.25rem",
                  height: "1.25rem",
                  ml: "10px",
                }}
              />
            </InputAdornment>
          }
          className="filledInput"
          sx={tableSearchStyled.filledInput}
          placeholder="Поиск мероприятий"
          ref={inputRef}
          onChange={onChange}
        />
      </FormControl>
      <NumOfResultsContainer>
        <NumOfResultsText>{dataLength}</NumOfResultsText>
        <NumOfResultsLabel>
          Всего
          <br />
          мероприятий
        </NumOfResultsLabel>
      </NumOfResultsContainer>
    </SearchContainer>
  );
};
