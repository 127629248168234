import { FormControlLabel, Switch } from "@mui/material";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { ESources } from "SRC/constants";
import { useAppSelector } from "SRC/redux/hooks";
import {
  IGlobalFilters,
  useGlobalFilters,
} from "SRC/redux/slices/global/hooks/useFilters";

import { TooltipText } from "../TooltipText";
import { Wrapper } from "./OMSwitcher.styled";

interface IOMSwitcher {
  variant?: "white" | "";
}

const currentYear = Number(moment().year());
const periodReg = RegExp("^\\d{4}(?:00|03|06|09|12|\\d)$");

export const OMSwitcher = ({ variant = "" }: IOMSwitcher) => {
  const {
    sources,
    toggleSource,
    period,
    resetInAchievement,
    setSelectedDate,
  }: IGlobalFilters = useGlobalFilters();
  const [disabled, setDisabled] = useState<boolean>(false);
  const handleChange = useCallback(() => {
    toggleSource(sources.OM ? ESources.FOIV : ESources.OM);
  }, [toggleSource, sources]);
  const { value } = useAppSelector((state) => state.global.filters.date);
  const compareDatesIndex = moment(new Date()).isBefore(value);
  useEffect(() => {
    setSelectedDate(period.value);
  }, [period.value]);

  const lastMonthWithAchievement = moment(value).format("YYYYMM") === "202212";
  const noAchievementYear = period.filterValue === currentYear + 1 + "00";

  const noDataPeriod = noAchievementYear > lastMonthWithAchievement;

  useEffect(() => {
    if (
      !periodReg.test(period.filterValue) ||
      Number(period.year) > currentYear ||
      noDataPeriod ||
      compareDatesIndex
    ) {
      setDisabled(true);
      resetInAchievement();
      toggleSource(ESources.FOIV);
    } else {
      setDisabled(false);
    }
  }, [noDataPeriod, period, compareDatesIndex]);

  useEffect(() => {
    if (disabled) {
      toggleSource(ESources.FOIV);
    }
  }, [disabled]);

  return (
    <Wrapper
      className={variant}
      isDisabled={disabled}
      specialOpinion={sources.OM}
    >
      <span onClick={handleChange}>ФОИВ</span>
      <TooltipText
        hint="Значения МЭР рассчитываются ежеквартально"
        variant="h5"
      >
        <FormControlLabel
          control={
            <Switch checked={sources.OM} onChange={handleChange} size="small" />
          }
          label="МЭР"
          disabled={disabled}
        />
      </TooltipText>
    </Wrapper>
  );
};
