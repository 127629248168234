import React from "react";
import { TableProps, UseExpandedRowProps, UseTableRowProps } from "react-table";
import NavigateToPriorities from "SRC/components/NavigateToPriorities/NavigateToPriorities";
import { PremierInfo } from "SRC/components/PremierInfo";
import { TableCell } from "SRC/components/TableCell";
import { IPremierTableItem } from "SRC/hooks/summaryTable/interfaces";

type CustomRowProps = UseTableRowProps<any> & UseExpandedRowProps<any>;

interface CustomTableProps extends TableProps {
  row: CustomRowProps;
  rows?: UseTableRowProps<IPremierTableItem>[];
}

const getVicePremierKPColumns = (isOM: boolean) => [
  {
    Header: "",
    accessor: "number",
    width: 120,
    columns: [
      {
        Header: "No п/п",
        width: 120,
        accessor: "number",
        Cell: (tableProps: CustomTableProps) => (
          <TableCell align="center">{tableProps.row.original.number}</TableCell>
        ),
      },
    ],
    Footer: "",
  },
  {
    Header: "",
    accessor: "vicePremier",
    columns: [
      {
        Header: "Вице-премьер",
        width: "auto",
        accessor: "vicePremier",
        Cell: (tableProps: CustomTableProps) => (
          <TableCell>
            <PremierInfo premierCode={tableProps.row.original.vpCode} />
          </TableCell>
        ),
        Footer: "",
      },
    ],
    Footer: "",
  },
  {
    Header: "",
    accessor: "countGP",
    columns: [
      {
        Header: "Кол-во ГП",
        width: 180,
        accessor: "countGP",
        Cell: (tableProps: CustomTableProps) => (
          <TableCell>{tableProps.row.original.countGP || 0}</TableCell>
        ),
        Footer: "",
      },
    ],
    Footer: "",
  },
  {
    Header: "",
    accessor: "performanceGP",
    columns: [
      {
        Header: "Уровень достижения ГП, %",
        width: 180,
        accessor: "performanceGP",
        Cell: (tableProps: CustomTableProps) => (
          <TableCell>{tableProps.row.original.performanceGP || 0}</TableCell>
        ),
        Footer: "",
      },
    ],
    Footer: "",
  },
  {
    Header: "",
    accessor: "performanceKP",
    columns: [
      {
        Header: "Уровень достижения ГП, %",
        width: 180,
        accessor: "performanceKP",
        Cell: (tableProps: CustomTableProps) => (
          <TableCell>{tableProps.row.original.performanceKP}</TableCell>
        ),
      },
    ],
    Footer: "",
  },
  {
    Header: "Ключевые приоритеты",
    accessor: "keyPriorities",
    width: "auto",
    columns: [
      {
        Header: "Всего",
        accessor: "prioritiesTotal",
        Cell: (tableProps: CustomTableProps) => (
          <TableCell>
            <NavigateToPriorities>
              {tableProps.row.original.prioritiesTotal}
            </NavigateToPriorities>
          </TableCell>
        ),
      },
      {
        Header: "Достигнуто",
        accessor: "prioritiesDone",
        Cell: (tableProps: CustomTableProps) => (
          <TableCell>{tableProps.row.original.prioritiesDone}</TableCell>
        ),
      },
      {
        Header: "Не достигнуто",
        accessor: "prioritiesFail",
        Cell: (tableProps: CustomTableProps) => (
          <TableCell>{tableProps.row.original.prioritiesFail}</TableCell>
        ),
      },
    ],
    Footer: "",
  },
];

export { getVicePremierKPColumns };
