import React from "react";
import { Column, ColumnGroup } from "react-table";
import { TableCell } from "SRC/components/TableCell";
import { IEventTableItem } from "SRC/redux/stores/StructureElement/useExtendedEvents";

const columns: ColumnGroup<IEventTableItem>[] = [
  {
    Header: "",
    id: "id",
    width: 200,
    columns: [
      {
        Header: "ID",
        width: 200,
        accessor: "id",
        Cell: ({ value }) => <TableCell color="gray">{value}</TableCell>,
      },
    ],
  },
  {
    Header: "",
    id: "name",
    width: "auto",
    columns: [
      {
        Header: "Наименование",
        width: "auto",
        accessor: "name",
        Cell: ({ value }) => <TableCell>{value}</TableCell>,
      },
    ],
  },
  {
    Header: "",
    id: "unit",
    width: 170,
    columns: [
      {
        Header: "Ед. измерения",
        width: 170,
        accessor: "unit",
        Cell: ({ value }) => <TableCell>{value}</TableCell>,
      },
    ],
  },
  {
    Header: "Дата окончания мероприятия",
    id: "date",
    columns: [
      {
        Header: "План",
        width: 135,
        accessor: "datePlan",
        Cell: ({ value }) => <TableCell>{value}</TableCell>,
      },
      {
        Header: "Факт",
        width: 135,
        accessor: "dateFact",
        Cell: ({ value }) => <TableCell>{value}</TableCell>,
      },
    ],
  },
  {
    Header: "",
    id: "performance",
    columns: [
      {
        Header: "УД показателя, %",
        accessor: "performance",
        width: 200,
        Cell: ({ value }) => <TableCell>{value}</TableCell>,
      },
    ],
  },
  {
    Header: "Значение мероприятия",
    id: "value",
    columns: [
      {
        Header: "План",
        width: 110,
        accessor: "valuePlan",
        Cell: ({ value }) => <TableCell>{value}</TableCell>,
      },
      {
        Header: "Факт",
        width: 110,
        accessor: "valueFact",
        Cell: ({ value }) => <TableCell>{value}</TableCell>,
      },
    ],
  },
  {
    Header: "Контрольные точки, ед.",
    id: "cp",
    columns: [
      {
        Header: "План",
        width: 110,
        accessor: "cpPlan",
        Cell: ({ value }) => <TableCell>{value}</TableCell>,
      },
      {
        Header: "Факт",
        width: 110,
        accessor: "cpFact",
        Cell: ({ value }) => <TableCell>{value}</TableCell>,
      },
    ],
  },
];

export const tableColumns = columns as Column<object>[];
