import { SxProps } from "@mui/material";
import {
  CriticalIcon,
  DoneIcon,
  InProgressIcon,
  NotStartedIcon,
  OutOfDateIcon,
  WarningTriangle,
} from "ASSETS/svg";
import React from "react";
import { useFilters } from "SRC/redux/slices/gosprogram/hooks/useFilters";

import {
  CardContainer,
  StatusAmount,
  StatusIcon,
  StatusText,
} from "./TableStatusCard.styled";

const statusIcons: Record<string, JSX.Element> = {
  Выполнено: <DoneIcon />,
  "В реализации": <InProgressIcon />,
  "Не начато": <NotStartedIcon />,
  Просрочено: <OutOfDateIcon />,
  "Под риском": <WarningTriangle />,
  ЧС: <CriticalIcon />,
};

interface IProps {
  status: string;
  amount: number;
  sxContainer?: SxProps;
}

const TableStatusCard = ({ status, amount, sxContainer }: IProps) => {
  const { selectedStatus, setSelectedStatus } = useFilters();

  const isDisabled = amount === 0;

  const handleClick = () => {
    if (!isDisabled) {
      setSelectedStatus(status);
    }
  };

  return (
    <CardContainer
      sx={sxContainer}
      isSelected={selectedStatus.includes(status)}
      isDisabled={isDisabled}
      onClick={handleClick}
    >
      <StatusIcon>{statusIcons[status]}</StatusIcon>
      <StatusText>{status}</StatusText>
      <StatusAmount>{amount}</StatusAmount>
    </CardContainer>
  );
};

export default TableStatusCard;
