import React, { useCallback, useMemo, useState } from "react";
import { ExtendedTabs, ITab } from "SRC/components/ExtendedTabs/ExtendedTabs";
import { EventsTab } from "SRC/pages/Ministry/common/EventsTab/EventsTab";
import { IndicatorsSETab } from "SRC/pages/Ministry/common/IndicatorsSETab/IndicatorsSETab";
import { IndicatorsTab } from "SRC/pages/Ministry/common/IndicatorsTab/IndicatorsTab";
import { MINISTRY_TABS } from "SRC/types";

export const MinistriesTable = () => {
  const [activeTab, setActiveTab] = useState<MINISTRY_TABS>(
    MINISTRY_TABS.INDICATORS
  );

  const tabs: ITab<MINISTRY_TABS>[] = useMemo(() => {
    return [
      {
        value: MINISTRY_TABS.INDICATORS,
        wip: true,
        label: "Показатели ГП",
        component: <IndicatorsTab />,
      },
      {
        value: MINISTRY_TABS.EVENTS,
        wip: true,
        label: "Мероприятия",
        component: <EventsTab />,
      },
      {
        value: MINISTRY_TABS.STRUCTURE,
        wip: true,
        label: "Показатели СЭ",
        component: <IndicatorsSETab />,
      },
    ];
  }, []);

  const handleChangeTab = useCallback(
    (newValue: MINISTRY_TABS) => setActiveTab(newValue),
    []
  );

  return (
    <ExtendedTabs
      value={activeTab}
      onChange={handleChangeTab}
      tabs={tabs}
      style={{ fontSize: "2rem", fontWeight: 700 }}
    />
  );
};
