import { styled, Theme } from "@mui/material";

export const Wrapper = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  // overflowY: "hidden",
  // overflowX: "visible",
  // padding: "0 1rem",
  gap: "1rem",
  ".filter-container": {
    display: "flex",
    gap: "1rem",
  },
}));

export const selectorMixin = (theme: Theme) => ({
  optionStyle: {
    '&[aria-selected="true"]': {
      backgroundColor: theme.palette.primary.main,
    },
  },
});

export const css = {
  selector: selectorMixin,
  filterButton: {
    color: (theme: Theme) => theme.palette.primary.contrastText,
    background: (theme: Theme) => theme.palette.complementary.darkGrayBlue,
    borderRadius: "0.5rem",
    fontSize: "1rem !important",
    fontWeight: "400",
    textTransform: "none",
    height: "2.5rem",
    padding: "0.6rem 1.125rem",
  },
};
