import { WarningTriangle } from "ASSETS/svg";
import React, { FC, useMemo } from "react";
import { SourceValue } from "SRC/components/SourceValue/SourceValue";
import { getPercentValue } from "SRC/helpers/getPercentValue";
import { roundNumbersToFixed } from "SRC/helpers/roundNumbersToFixed";

import { IOPValue, OverlayProgressBar } from "../index";
import {
  ProgressLabel,
  ProgressLine,
  Wrapper,
} from "./OverlayStackedProgress.styled";

interface IProps {
  successValues: IOPValue[];
  failValues: IOPValue[];
  failIcon?: boolean;
  total: number;
  header?: JSX.Element;
  title?: string;
  titleValue?: string | number | JSX.Element;
  className?: string;
  isFit?: boolean;
  showFailPercents?: boolean;
}

interface IValidValue extends IOPValue {
  value: number;
}

export const OverlayStackedProgress: FC<IProps> = ({
  successValues = [],
  failValues = [],
  failIcon,
  total,
  header,
  className,
  title,
  titleValue,
  isFit = false,
  showFailPercents = false,
}) => {
  const filteredSV: IValidValue[] = useMemo(
    () =>
      successValues.filter(({ value }) =>
        Number.isFinite(value)
      ) as IValidValue[],
    [successValues]
  );
  const filteredFV: IValidValue[] = useMemo(
    () =>
      failValues.filter(({ value }) => Number.isFinite(value)) as IValidValue[],
    [failValues]
  );
  const sv: IValidValue[] = useMemo(
    () =>
      [...filteredSV]
        .sort(({ value: v1 }, { value: v2 }) => v2 - v1)
        .map(
          (item, _, array): IValidValue => ({
            ...item,
            value: Math.round((item.value / (array[0].value || 1)) * 100),
          })
        ),
    [filteredSV]
  );

  const fv: IValidValue[] = useMemo(
    () =>
      [...filteredFV]
        .sort(({ value: v1 }, { value: v2 }) => v2 - v1)
        .map(
          (item, _, array): IValidValue => ({
            ...item,
            value: Math.round((item.value / (array[0].value || 1)) * 100),
          })
        ),
    [filteredFV]
  );

  const { percent, percentFail, percentAtRisk } = useMemo(() => {
    const percentTotal = filteredSV.reduce((acc, { value }) => {
      return acc + value;
    }, 0);
    const percentFailTotal = filteredFV.reduce((acc, { value }) => {
      return acc + value;
    }, 0);

    // Сумма percentTotal и percentFailTotal может быть больше 100%
    // Для корректнго отображения нужно использовать максимальное значение "всего"
    const barTotal = Math.max(percentTotal + percentFailTotal, total);

    return {
      percent: (percentTotal / (filteredSV.length * barTotal)) * 100,
      percentFail: (percentFailTotal / (filteredFV.length * barTotal)) * 100,
      percentAtRisk: getPercentValue(
        ((filteredSV[0]?.value ?? 0) * 100) / total,
        2
      ),
    };
  }, [filteredFV, filteredSV, total]);

  return (
    <Wrapper className={className} isFit={isFit}>
      <div className="title">
        {title && <div>{title}</div>}
        {titleValue && <div className="title-value">{titleValue}</div>}
      </div>

      <div className="progress-lines-container">
        <ProgressLine percent={percent}>
          <OverlayProgressBar values={sv} valueType="success" />
        </ProgressLine>
        <ProgressLine percent={percentFail}>
          <OverlayProgressBar values={fv} valueType="fail" />
        </ProgressLine>
      </div>

      <div className="labels-container">
        <ProgressLabel percent={percent} className="left-label">
          {filteredSV.map(({ value, settings }, index) => (
            <SourceValue
              key={`${value}-${index}`}
              rounded
              value={roundNumbersToFixed(value || 0, 0)}
              settings={settings}
            />
          ))}
        </ProgressLabel>

        <ProgressLabel className="right-label-wrapper" percent={percentFail}>
          <div className="label">
            {filteredFV.map(({ value, settings }, index) => (
              <SourceValue
                key={`${value}-${index}`}
                rounded
                value={roundNumbersToFixed(value || 0, 0)}
                settings={settings}
              />
            ))}
            {showFailPercents && <span>/ {percentAtRisk} %</span>}
            {failIcon && filteredFV[0]?.value > 0 && (
              <WarningTriangle width={15} height={15} />
            )}
          </div>

          <span className="total">{total}</span>
        </ProgressLabel>
      </div>
    </Wrapper>
  );
};
