import moment from "moment";
import { IPeriod } from "SRC/redux/slices/global/slices/filters";

export enum EReportType {
  INDICATORS_GP = "indicatorsGP",
  LIST_SE = "listSE",
  TASKS_SE = "tasksSE",
  INDICATORS_SE = "indicatorsSE",
  EVENTS = "events",
  INDICATORS_ANALYTICS_GP = "indicatorsAnalyticsGP",
}

const valueTable = {
  [EReportType.INDICATORS_GP]: [
    "Показатели Государственной программы",
    "Перечень показателей Государственной программы",
  ],

  [EReportType.LIST_SE]: [
    "Список структурных элементов Государственной программы",
    "Перечень структурных элементов Государственной программы",
  ],

  [EReportType.TASKS_SE]: [
    "Задачи структурных элементов Государственной программы",
    "Перечень задач структурных элементов Государственной программы",
  ],

  [EReportType.INDICATORS_SE]: [
    "Показатели структурных элементов Государственной программы",
    "Перечень показателей структурных элементов Государственной программы",
  ],
  [EReportType.EVENTS]: [
    "Мероприятия структурных элементов Государственной программы",
    "Перечень мероприятий (результатов) структурных элементов Государственных программ, включающий основные параметры выполнения мероприятия (плановое значение, фактическое значение, выполнение)",
  ],

  [EReportType.INDICATORS_ANALYTICS_GP]: [
    "Аналитические показатели Государственной программы",
    "Перечень аналитических показателей Государственной программы",
  ],
};

export const staticInfoRows = [
  [
    {
      t: "s",
      v: "Наименование отчета:",
    },
    {
      t: "s",
      v: "Госпрограмма:",
    },
    {
      t: "s",
      v: "Тип госпрограммы:",
    },
    {
      t: "s",
      v: "Структурный элемент:",
    },
    {
      t: "s",
      v: "Содержимое выгрузки:",
    },
    {
      t: "s",
      v: "Данные отчета по состоянию на:",
    },
    {
      t: "s",
      v: "Дата выгрузки отчета:",
    },
  ],
];

export const getInfoRowsEvents = (
  period: IPeriod,
  program: string,
  programType: string,
  reportType: EReportType,
  structureElement?: string
) => {
  return [
    [
      {
        t: "s",
        v: valueTable[reportType][0],
      },
      {
        t: "s",
        v: program,
      },
      {
        t: "s",
        v: programType,
      },
      {
        t: "s",
        v: structureElement ? structureElement : "Все",
      },
      {
        t: "s",
        v: valueTable[reportType][1],
      },
      {
        t: "s",
        v: period.reportValue,
      },
      {
        t: "s",
        v: moment().format("DD.MM.YYYY") || "",
      },
    ],
  ];
};

export const getInfoRowsSummaryPrograms = (
  period: IPeriod,
  program?: string
) => {
  return [
    [
      {
        t: "s",
        v: "Наименование отчета:",
      },
      {
        t: "s",
        v: "Госпрограмма:",
      },
      {
        t: "s",
        v: "Содержимое выгрузки:",
      },
      {
        t: "s",
        v: "Данные отчета по состоянию на:",
      },
      {
        t: "s",
        v: "Дата выгрузки отчета:",
      },
    ],
    [
      {
        t: "s",
        v: "Сводная таблица по Государственным программам",
      },
      {
        t: "s",
        v: program || "Все",
      },
      {
        t: "s",
        v: "Сводная таблица сведений о реализации Государственных программ в разрезе Государственных программ",
      },
      {
        t: "s",
        v: period.reportValue,
      },
      {
        t: "s",
        v: moment().format("DD.MM.YYYY") || "",
      },
    ],
  ];
};

export const getInfoRowsSummaryVicePremiers = (period: IPeriod) => {
  return [
    [
      {
        t: "s",
        v: "Наименование отчета:",
      },
      {
        t: "s",
        v: "Содержимое выгрузки:",
      },
      {
        t: "s",
        v: "Данные отчета по состоянию на:",
      },
      {
        t: "s",
        v: "Дата выгрузки отчета:",
      },
    ],
    [
      {
        t: "s",
        v: "Сводная таблица по вице-премьерам",
      },
      {
        t: "s",
        v: "Сводная таблица сведений о реализации Государственных программ в разрезе вице-премьеров",
      },
      {
        t: "s",
        v: period.reportValue,
      },
      {
        t: "s",
        v: moment().format("DD.MM.YYYY") || "",
      },
    ],
  ];
};

export const getInfoRowsSummaryMinistries = (period: IPeriod) => {
  return [
    [
      {
        t: "s",
        v: "Наименование отчета:",
      },
      {
        t: "s",
        v: "Содержимое выгрузки:",
      },
      {
        t: "s",
        v: "Данные отчета по состоянию на:",
      },
      {
        t: "s",
        v: "Дата выгрузки отчета:",
      },
    ],
    [
      {
        t: "s",
        v: "Сводная таблица по министерствам",
      },
      {
        t: "s",
        v: "Сводная таблица сведений о реализации Государственных программ в разрезе министерств",
      },
      {
        t: "s",
        v: period.reportValue,
      },
      {
        t: "s",
        v: moment().format("DD.MM.YYYY") || "",
      },
    ],
  ];
};

export const getInfoRowsSETasks = () => undefined;
export const getInfoRowsSEEvents = () => undefined;
export const getInfoRowsSEIndicators = () => undefined;
