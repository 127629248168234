import { Box } from "@mui/material";
import React, { FC, useMemo } from "react";
import { ISourceValue } from "SRC/constants/globals";

import { css } from "./OverlayProgressBar.styled";

export interface IOPValue extends ISourceValue {
  label?: React.ReactNode;
}

interface IValidValue extends IOPValue {
  value: number;
}

interface IProps {
  values: IOPValue[];
  valueType?: "value" | "success" | "fail";
  withPercentage?: boolean;
  withLabel?: boolean;
  startValue?: React.ReactNode;
  endValue?: React.ReactNode;
  hasContrastBG?: boolean;
}

export const OverlayProgressBar: FC<IProps> = ({
  values,
  withPercentage,
  withLabel,
  startValue,
  endValue,
  valueType,
  hasContrastBG,
}) => {
  const progresses: IValidValue[] = useMemo(
    () =>
      [...values]
        .filter((item) => Number.isFinite(item?.value))
        .map(
          (item): IValidValue => ({
            ...item,
            value: (item.value as number) > 100 ? 100 : (item.value as number),
          })
        )
        .sort(({ value: v1 }, { value: v2 }) => v2 - v1),
    [values]
  );

  return (
    <Box sx={css.progressBar}>
      <Box sx={css.uiProgressBarStyle(hasContrastBG)}>
        {progresses.map(({ value, label, settings }, index) => (
          <Box
            key={`${label}-${value}-${index}`}
            sx={css.progressLine(
              value,
              valueType
                ? settings[`${valueType}Color`]
                : value !== 100
                ? settings.valueColor
                : settings.successColor
            )}
          >
            {withLabel && (
              <Box sx={css.circleLabel}>
                {withPercentage && (
                  <Box sx={css.percentLabel}>
                    {value}
                    <span>%</span>
                  </Box>
                )}
                {label ?? value}
              </Box>
            )}
          </Box>
        ))}
      </Box>
      {(startValue || endValue) && (
        <Box sx={css.bottomLabelContainer}>
          {startValue && <Box sx={css.bottomLabel}>{startValue}</Box>}
          {endValue && <Box sx={css.bottomLabel}>{endValue}</Box>}
        </Box>
      )}
    </Box>
  );
};
