import { createSlice } from "@reduxjs/toolkit";
import { IPeriod } from "SRC/redux/slices/global/slices/filters";

export interface IIndicatorParams {
  id: number | string;
  period: IPeriod;
}

export interface IFiltersState {
  selectedIndicatorId: string | number;
  indicatorModalOpen: boolean;
}

const initialState: IFiltersState = {
  selectedIndicatorId: "",
  indicatorModalOpen: false,
};

const slice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    clear: () => initialState,
    selectIndicatorId: (state, action: { payload: string | number }) => {
      state.selectedIndicatorId = action.payload;
    },
    setIndicatorModalOpen: (state, action: { payload: boolean }) => {
      state.indicatorModalOpen = action.payload;
    },
  },
});

export default slice;
