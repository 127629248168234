import { Tooltip } from "@mui/material";
import React from "react";
import { CheckSecretData } from "SRC/components/CheckSecretData";
import { TableCell } from "SRC/components/TableCell";
import { sortCheckpointsFactColumn } from "SRC/helpers";
import { ICustomTableProps } from "SRC/types";

import { getCheckpointsFact, getCheckpointsFactTotal } from "./utils";

export const checkpointsFact = (isOM: boolean) => ({
  Header: "Факт",
  sortType: sortCheckpointsFactColumn(isOM),
  width: 130,
  accessor: isOM ? "checkpointsOMFact" : "checkpointsFact",
  Cell: (tableProps: ICustomTableProps) => {
    const { checkpointsFact, isSecret } = getCheckpointsFact(tableProps, isOM);

    return (
      <TableCell color={isOM ? "paleGreen" : "green"}>
        <CheckSecretData isSecret={isSecret}>{checkpointsFact}</CheckSecretData>
      </TableCell>
    );
  },
  Footer: (tableProps: ICustomTableProps) => {
    const { checkpointsFactTotal } = getCheckpointsFactTotal(tableProps, isOM);

    return (
      <Tooltip title="В расчете участвуют уникальные элементы">
        <TableCell color={isOM ? "paleGreen" : "green"}>
          {checkpointsFactTotal}
        </TableCell>
      </Tooltip>
    );
  },
});
