import { useQuery } from "react-query";
import { IPeriod } from "SRC/redux/slices/global/slices/filters";

import { getMainStats } from "./api-main-stats";
import { QUERY_KEY } from "./consts";

interface IParams {
  gp_code: string | null;
  vp_code: string | null;
  period: IPeriod;
  sphere: string | null;
  foiv: string | null;
}

interface IFetchStatsProps {
  params: IParams;
}

export const useFetchStats = ({ params }: IFetchStatsProps) => {
  const {
    data: stats,
    error,
    isFetched,
    isFetching,
  } = useQuery([QUERY_KEY, params], async () => await getMainStats(params));

  return {
    stats,
    isFetched,
    isFetching,
    error,
  };
};
