import { Box } from "@mui/material";
import React, { useMemo } from "react";
import { ProgramExportButton } from "SRC/components/Buttons";
import { IPRChartElement, PieRadialChart } from "SRC/components/Charts";
import { IncidentReport } from "SRC/components/IncidentReport";
import { OverlayBlock } from "SRC/components/OverlayBlock";
import { SourceValue } from "SRC/components/SourceValue/SourceValue";
import { getDataSumByCodeGP } from "SRC/constants";
import { convertSourceValue, ESources } from "SRC/constants/globals";
import useFullReportCreator from "SRC/hooks/use-excel-creator/useFullReportCreator";
import { LineProgress } from "SRC/pages/Program/common/GovProgramInfoWidget/common/LineProgress/LineProgress";
import {
  IGlobalFilters,
  useGlobalFilters,
} from "SRC/redux/slices/global/hooks/useFilters";
import { useProgram } from "SRC/redux/slices/gosprogram/hooks/useProgram";
import { useStatistics } from "SRC/redux/slices/gosprogram/hooks/useStatistics";
import { useWorkbook } from "SRC/redux/slices/gosprogram/hooks/useWorkbook";
import { IWorkbookData } from "SRC/types";

import { LineProgressComponent } from "./components/LineProgressComponent";
import { ProgressComponent } from "./components/ProgressComponent";
import { css, Wrapper } from "./EffectiveInfo.styled";

export type TPercentValue = Record<ESources, number>;

export interface IStructureElement {
  name: string;
  amount: number;
  total: number;
  done: number;
  percentage: TPercentValue;
}

interface IEffectiveData {
  performance: {
    percent: TPercentValue;
    units: string;
  };
  indicators: {
    total: number;
    done: TPercentValue;
    percentage: TPercentValue;
    units: string;
  };
  structureElements: {
    elements: IStructureElement[];
    total: number;
    done: number;
    units: string;
    percentage: TPercentValue;
    percentageRadial: TPercentValue;
    name: string;
  };
}

export const EffectiveInfo = () => {
  const { sources }: IGlobalFilters = useGlobalFilters();
  const { items: statistics, fetching } = useStatistics();
  const { items: program } = useProgram();
  const { items: workbook, config } = useWorkbook();
  const createWorker = useFullReportCreator(workbook as IWorkbookData, config);
  const hasData = Boolean(program && statistics);

  const { structureElements, performance, indicators }: IEffectiveData =
    useMemo(() => {
      const data = program?.data || [];

      return {
        performance: {
          percent: {
            [ESources.FOIV]: statistics["Уровень достижения 2"] || 0,
            [ESources.OM]: getDataSumByCodeGP({ data }, 5265) || 0,
          },
          units: "%",
        },
        indicators: {
          total: statistics["Количество показателей"] || 0,
          done: {
            [ESources.FOIV]:
              statistics["Количество показателей, выполнено"] || 0,
            [ESources.OM]:
              Number(statistics["Количество показателей ОМ, выполнено"]) || 0,
          },
          percentage: {
            [ESources.FOIV]:
              statistics["Количество показателей 2, выполнено %"] || 0,
            [ESources.OM]:
              statistics["Количество показателей 2 ОМ, выполнено %"] || 0,
          },
          units: "ед.",
        },
        structureElements: {
          elements: [
            {
              name: "ФП",
              amount: statistics["Федеральных проектов, кол-во"] || 0,
              total: statistics["Выполнение мероприятий ФП, план"] || 0,
              done: statistics["Выполнение мероприятий ФП, факт"] || 0,
              percentage: {
                [ESources.FOIV]:
                  statistics["Выполнение мероприятий ФП 2, %"] || 0,
                [ESources.OM]: statistics.fp_res_complete_om_perc || 0,
              },
            },
            {
              name: "ВП",
              amount: statistics["Ведомственных проектов, кол-во"] || 0,
              total: statistics["Выполнение мероприятий ВП, план"] || 0,
              done: statistics["Выполнение мероприятий ВП, факт"] || 0,
              percentage: {
                [ESources.FOIV]:
                  statistics["Выполнение мероприятий ВП 2, %"] || 0,
                [ESources.OM]: statistics.dp_res_complete_om_perc || 0,
              },
            },
            {
              name: "КПМ",
              amount:
                statistics["Комплексы процессных мероприятий, кол-во"] || 0,
              total: statistics["Выполнение мероприятий КПМ, план"] || 0,
              done: statistics["Выполнение мероприятий КПМ, факт"] || 0,
              percentage: {
                [ESources.FOIV]:
                  statistics["Выполнение мероприятий КПМ 2, %"] || 0,
                [ESources.OM]: statistics.cpm_res_complete_om_perc || 0,
              },
            },
          ],
          name: "Всего",
          total: statistics["Структурные элементы, всего"] || 0,
          done: statistics["Мероприятий выполнено"] || 0,
          units: "ед.",
          percentage: {
            [ESources.FOIV]: statistics["Структурные элементы 2, %"] || 0,
            [ESources.OM]: statistics.res_complete_om_perc || 0,
          },
          percentageRadial: {
            [ESources.FOIV]:
              statistics["Количество показателей 2, выполнено %"] || 0,
            [ESources.OM]:
              statistics["Количество показателей 2 ОМ, выполнено %"] || 0,
          },
        },
      };
    }, [program, statistics]);

  const values = useMemo(() => {
    return convertSourceValue(performance.percent, sources);
  }, [sources, performance]);

  const indicatorValues = useMemo(
    () => convertSourceValue(indicators.done, sources),
    [sources, indicators]
  );

  const structureValues = useMemo(
    () => convertSourceValue(structureElements.percentage, sources),
    [sources, structureElements]
  );

  const structureProgresses = useMemo(
    () =>
      structureElements.elements.map((elem: IStructureElement) => (
        <LineProgress
          key={elem.name}
          name={elem.name}
          value={elem.amount}
          percents={convertSourceValue(elem.percentage, sources)}
        />
      )),
    [sources, structureElements]
  );

  const chartData: IPRChartElement[] = useMemo(() => {
    const values = [
      convertSourceValue(structureElements.percentageRadial, sources),
      convertSourceValue(structureElements.elements[0].percentage, sources),
      convertSourceValue(structureElements.elements[1].percentage, sources),
      convertSourceValue(structureElements.elements[2].percentage, sources),
    ];

    const categories = ["Показатели", "ФП", "ВП", "КПМ"];
    const totals = [
      structureElements.total,
      structureElements.elements[0].amount,
      structureElements.elements[1].amount,
      structureElements.elements[2].amount,
    ];

    return Object.values(sources)
      .filter(Boolean)
      .map((_, index) => values.map((item) => item[index]))
      .flat()
      .map((value, i) => ({ value, name: categories[i], total: totals[i] }))
      .filter(({ total }) => Boolean(total));
  }, [structureElements, indicators, sources]);

  return (
    <Wrapper>
      <OverlayBlock isFetching={fetching} hasData={hasData}>
        <>
          <Box sx={css.effectiveHeader}>
            <Box sx={css.title}>
              Уровень достижения
              <Box>
                <ProgramExportButton saveFile={createWorker} />
              </Box>
            </Box>
          </Box>
          <Box sx={css.effectiveContent}>
            <Box sx={css.achievement}>
              <IncidentReport className="incident-progress">
                <ProgressComponent values={values} />
              </IncidentReport>
              <Box>
                <Box sx={css.titleAchievement}>Показатели уровня ГП</Box>
                <Box sx={css.progressAchievement}>
                  <Box sx={css.value} className="indicators">
                    {indicatorValues.map((value, index) => (
                      <SourceValue key={index} {...value} rounded />
                    ))}
                    <span>{indicators.total}</span>
                    <span className="unit">{indicators.units}</span>
                  </Box>
                </Box>
              </Box>
            </Box>
            <IncidentReport className="incident-lineprogress">
              <LineProgressComponent
                structureElements={structureElements}
                structureProgresses={structureProgresses}
                structureValues={structureValues}
              />
            </IncidentReport>
            <Box sx={css.chartContainer}>
              <Box sx={css.chart}>
                <PieRadialChart values={chartData} />
              </Box>
            </Box>
          </Box>
        </>
      </OverlayBlock>
    </Wrapper>
  );
};
