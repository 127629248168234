import { Paper, useMediaQuery, useTheme } from "@mui/material";
import React, { MutableRefObject, useMemo, useState } from "react";
import { TableInstance } from "react-table";
import { ExtendedTabs, ITab } from "SRC/components/ExtendedTabs/ExtendedTabs";
import Select, { IOption } from "SRC/components/Select/Select";
import { KeyPrioritiesTab } from "SRC/pages/Priorities/common/PrioritiesComplexGrid/KeyPrioritiesTab/KeyPrioritiesTab";
import { VicepremiersTab } from "SRC/pages/Priorities/common/PrioritiesComplexGrid/VicepremiersTab/VicepremiersTab";
import {
  IFilters,
  useFilters,
} from "SRC/redux/slices/priorities/hooks/useFilters";
import { EGridTabs } from "SRC/redux/slices/priorities/slices/filters";
import themeConfig from "SRC/theme";

import { GovProgramsTab } from "./GovProgramsTab/GovProgramsTab";
import { css, Wrapper } from "./PrioritiesComplexGrid.styled";

interface ISummaryComplexGrid {
  forwardRef?: MutableRefObject<HTMLElement | undefined>;
  onScroll?: (scroll: any) => void;
  scrollX?: boolean;
  setSortBy: (sortBy: any) => void;
}

export const PrioritiesComplexGrid: React.FC<ISummaryComplexGrid> = (props) => {
  const theme = useTheme<typeof themeConfig>();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));
  const { clear, complexGridTab, setSelectedComplexGridTab }: IFilters =
    useFilters();
  const [modalOpen, setModalOpen] = useState(false);
  const [tableInstance, setTableInstance] = useState<TableInstance | undefined>(
    undefined
  );

  const tabsHandler = (value: EGridTabs) => {
    setModalOpen(false);
    setTableInstance(undefined);
    setSelectedComplexGridTab(value);
  };

  const tabs: ITab<EGridTabs>[] = [
    {
      label: "Вицепремьеры",
      value: EGridTabs.PREMIERS,
      wip: true,
      component: (
        <VicepremiersTab
          instance={tableInstance}
          setInstance={setTableInstance}
        />
      ),
    },
    {
      label: "Госпрограммы",
      value: EGridTabs.PROGRAMS,
      wip: true,
      component: (
        <GovProgramsTab
          instance={tableInstance}
          setInstance={setTableInstance}
        />
      ),
    },
    {
      label: "Все КП",
      value: EGridTabs.PRIORITIES,
      wip: true,
      component: (
        <KeyPrioritiesTab
          setInstance={setTableInstance}
          instance={tableInstance}
        />
      ),
    },
  ];

  const selectStyle = useMemo(() => css.selector(theme), [theme]);

  return (
    <Wrapper>
      {matches ? (
        <>
          <div className="table-header-wrapper">
            <Paper
              sx={css.tableTitle}
              component="h1"
              onClick={clear}
              elevation={0}
            >
              Ключевые приоритеты
            </Paper>
          </div>
          <div className="divider" />
          <div className="selectors-wrapper">
            <Select
              value={complexGridTab}
              onChange={setSelectedComplexGridTab}
              options={tabs as IOption<EGridTabs>[]}
              label="Вид"
              hasAllOption={false}
              colorizeActiveOption={false}
              style={selectStyle}
            />
          </div>
        </>
      ) : (
        <ExtendedTabs
          value={complexGridTab}
          onChange={tabsHandler}
          tabs={tabs}
          style={{ fontSize: "1.5rem", fontWeight: "bold" }}
        />
      )}
    </Wrapper>
  );
};
