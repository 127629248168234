import { ICodeItem } from "SRC/types";

export const formatNumber = (
  value?: number,
  unit?: string,
  fractionDigits = 1,
  keepSign = false
) => {
  let textValue = "";
  if (typeof value === "number") {
    textValue = new Intl.NumberFormat(navigator.language, {
      maximumFractionDigits: fractionDigits,
      minimumFractionDigits: fractionDigits,
    }).format(value);

    if (keepSign && value > 0) {
      textValue = `+${textValue}`;
    }
  } else {
    textValue = "-";
  }

  return unit ? `${textValue}${unit === "%" ? unit : ` ${unit}`}` : textValue;
};

export const prepareUnit = (val: string) =>
  val.replace(/(кв\.\s*м\.|м2)+/, "м²");

// ПАРСИНГ ДАННЫХ ДЛЯ Olapdata запросов

interface IDimItem {
  attributeVals: Record<string | number, any>;
  code?: string;
}

interface IDataItem {
  dims: Record<string | number, string>;
  indicatorVals: Record<string | number, ICodeItem>;
}

export interface IOlapdataResponse {
  data: IDataItem[];
  dimensionItems: Record<string | number, Record<string | number, IDimItem>>;
}

export interface IDecryptParam<D = Record<string, string>> {
  code: string | number;
  dimensions: D;
}

interface IPeriodValues {
  code?: string;
  year?: string;
  date?: string;
  periodStart?: string;
  periodEnd?: string;
  type?: string;
  period?: string;
}

export const createPeriodDims = (code: number | string): IDecryptParam => ({
  code,
  dimensions: {
    type: "DatePart",
    date: "PERIOD2",
    period: "PERIOD_CODE",
    periodStart: "PeriodStart",
    periodEnd: "PeriodEnd",
  },
});

export type TDecoder = Record<string | "period", IDecryptParam>;

export interface IParsedItem
  extends Partial<Record<string, Record<string, any> | IPeriodValues>> {
  data: Record<string | number, ICodeItem>;
  period: IPeriodValues;
}

type TOlapdataIndicator = {
  sum: number;
};

type TOlapdataAttrVal = {
  NAME: string;
  sort_order: number;
};

type TOlapdataDimItem = {
  code: string;
  attributeVals: TOlapdataAttrVal;
};

type TOlapdataDims = {
  dims: Record<string, string>;
  indicatorVals: Record<string, TOlapdataIndicator>;
};

type TOlapdataDataData = {
  data: TOlapdataDims[];
  dimensionItems: Record<string, Record<string, TOlapdataDimItem>>;
};

export const parseOlapdata2 = (
  response: TOlapdataDataData,
  decoder: TDecoder
): IParsedItem[] => {
  if (!response) {
    return [];
  }

  const { data, dimensionItems } = response;
  const attributeSortOrder: Record<string, number> = {};

  const parsedData = data?.map((item, itemIndex): IParsedItem => {
    const indicators = Object.entries(item.indicatorVals).map(
      ([code, indicator]) => {
        return [
          code,
          {
            ...indicator,
            code,
          },
        ];
      }
    );

    return {
      data: Object.fromEntries(indicators),
      period: {},
      ...Object.entries(decoder).reduce((acc, [key, value]) => {
        const { code: dimCode, dimensions } = value;
        const { attributeVals, code } =
          dimensionItems[dimCode][item.dims[dimCode]];

        const sortOrder = attributeVals?.sort_order;
        if (sortOrder) {
          attributeSortOrder[sortOrder] = itemIndex;
        }

        return {
          ...acc,
          [key]: Object.entries(dimensions).reduce(
            (accum, [dimKey, dimValue]) => ({
              ...accum,
              code,
              [dimKey]: attributeVals[dimValue as keyof TOlapdataAttrVal],
            }),
            {}
          ),
        };
      }, {}),
    };
  });

  const sortIndexes = Object.keys(attributeSortOrder).sort();

  if (sortIndexes.length === parsedData.length) {
    const sortedParsedData: IParsedItem[] = [];

    sortIndexes.forEach((sortIndex) => {
      sortedParsedData.push(parsedData[attributeSortOrder[sortIndex]]);
    });

    return sortedParsedData;
  }

  return parsedData;
};

export const parseOlapdata = (
  response: IOlapdataResponse,
  decoder: TDecoder
): IParsedItem[] => {
  if (!response) {
    return [];
  }

  const { data, dimensionItems } = response;
  const attributeSortOrder: Record<string, number> = {};

  const parsedData = data?.map((item, itemIndex): IParsedItem => {
    const indicators = Object.entries(item.indicatorVals).map(
      ([code, indicator]) => {
        return [
          code,
          {
            ...indicator,
            code,
          },
        ];
      }
    );

    return {
      data: Object.fromEntries(indicators),
      period: {},
      ...Object.entries(decoder).reduce((acc, [key, value]) => {
        const { code: dimCode, dimensions } = value;
        const { attributeVals, code } =
          dimensionItems[dimCode][item.dims[dimCode]];
        const sortOrder = attributeVals?.sort_order;
        if (sortOrder) {
          attributeSortOrder[sortOrder] = itemIndex;
        }

        return {
          ...acc,
          [key]: Object.entries(dimensions).reduce(
            (accum, [dimKey, dimValue]) => ({
              ...accum,
              code,
              [dimKey]: attributeVals[dimValue],
            }),
            {}
          ),
        };
      }, {}),
    };
  });

  const sortIndexes = Object.keys(attributeSortOrder).sort();

  if (sortIndexes.length === parsedData.length) {
    const sortedParsedData: IParsedItem[] = [];

    sortIndexes.forEach((sortIndex) => {
      sortedParsedData.push(parsedData[attributeSortOrder[sortIndex]]);
    });

    return sortedParsedData;
  }

  return parsedData;
};
