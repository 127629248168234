const tokenInfo = localStorage.getItem("tokenInfo");
const { access_token } = JSON.parse(tokenInfo || "{}");

export const PLANETA_QUERY_CONFIG = (() => {
  return {
    baseUrl: `api/data_api/v1.0/olapdata`,
    prepareHeaders: (headers: Headers) => {
      headers.set("authorization", `Bearer ${access_token}`);

      return headers;
    },
  };
})();
