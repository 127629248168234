import InsertLinkIcon from "@mui/icons-material/InsertLink";
import { InputLabel, TextField } from "@mui/material";

import { Wrapper } from "./IncidentLink.styled";

export const IncidentLink = () => {
  const copyToClipboard = () => {
    navigator.clipboard.writeText(window.location.href);
  };
  return (
    <Wrapper>
      <InputLabel>Ссылка на панель</InputLabel>
      <TextField
        value={window.location.href}
        InputProps={{
          endAdornment: <InsertLinkIcon onClick={copyToClipboard} />,
        }}
      />
    </Wrapper>
  );
};
