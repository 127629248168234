import { Stack, styled } from "@mui/material";

export const StackWrapper = styled(Stack)(() => {
  return {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",

    ".title": {
      fontWeight: 500,
      fontSize: "2rem",
    },
    ".tables": {
      padding: 0,
    },
    ".border": {
      borderBottom: "1px solid #363E53",
    },
    ".structure": {
      paddingBottom: "1.25rem",
    },
    ".progress": {
      paddingBottom: "0.6rem",
    },
  };
});

export const css = {
  tabs: {
    fontSize: "1.2rem",
    fontWeight: "normal",
  },
};
