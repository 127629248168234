import { SerializedError } from "@reduxjs/toolkit";
import { useCallback, useState } from "react";
import { IProgramEvent } from "SRC/redux/slices/gosprogram/slices/events";
import { IParams } from "SRC/redux/slices/gosprogram/slices/events";

import { useAppDispatch, useAppSelector } from "../../../hooks";
import actions from "../actions";

export interface IEvents {
  fetching: boolean;
  fetched: boolean;
  items: IProgramEvent[];
  total: number;
  load(params: IParams): void;
  getItemByCode(code: string): IProgramEvent | undefined;
  error: SerializedError | null;
}

export const useEvents = (): IEvents => {
  const dispatch = useAppDispatch();
  const {
    fetching,
    items: events = [],
    total: totalItems,
    fetched,
  } = useAppSelector((state) => state.gosprogram.events);
  const [error, setError] = useState<SerializedError | null>(null);

  const load = useCallback(
    (params: IParams) => {
      if (!fetching) {
        dispatch(actions.events.get(params)).then((action) => {
          if (actions.events.get.rejected.match(action)) {
            setError(action.error);
          }
          return action;
        });
      }
    },
    [dispatch, fetching]
  );

  const getItemByCode = (code: string): IProgramEvent | undefined =>
    events.find((programEvent) => programEvent.code === code);

  return {
    fetching,
    fetched,
    items: events,
    total: totalItems,
    getItemByCode,
    load,
    error,
  };
};
