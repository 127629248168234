import { Box, Stack, useMediaQuery, useTheme } from "@mui/material";
import React, {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useCallback,
  useMemo,
} from "react";
import { Column, TableInstance } from "react-table";
import { TableModal } from "SRC/components/FullPageTableModal/FullPageTableModal";
import { IncidentReport } from "SRC/components/IncidentReport";
import { SecretSwitcher } from "SRC/components/SecretSwitcher";
import Table, { ColumnsController } from "SRC/components/Table";
import { TableActionsButtons } from "SRC/components/TableActionsButtons/TableActionsButtons";
import { useTableData } from "SRC/hooks/summaryTable/useSummaryPrograms";
import { createGpRowsTableData } from "SRC/hooks/summaryTable/utils/summaryData/summaryDataGP";
import { RatingTabs } from "SRC/pages/Summary/common/Rating/RatingTabs/RatingTabs";
import { GeneralInfoTabFilters } from "SRC/pages/Summary/common/SummaryComplexGrid/GeneralFullInfoTab/GeneralInfoTabFilters/GeneralInfoTabFilters";
import { getGeneralInfoColumns } from "SRC/pages/Summary/common/SummaryComplexGrid/GeneralFullInfoTab/tableColumns";
import { css } from "SRC/pages/Summary/common/SummaryComplexGrid/SummaryComplexGrid.styled";
import { useRatingData } from "SRC/pages/Summary/useRatingData";
import {
  IGlobalFilters,
  useGlobalFilters,
} from "SRC/redux/slices/global/hooks/useFilters";
import { useFilters } from "SRC/redux/slices/summary/hooks/useFilters";
import { usePrograms } from "SRC/redux/slices/summary/hooks/usePrograms";
import { CustomRowProps } from "SRC/types/data/data";

interface IGeneralInfoTabProps {
  posTable?: string;
  forwardRef?: MutableRefObject<HTMLElement | undefined>;
  onScroll?: (scroll: any) => void;
  isModalOpen?: boolean;
  setModalOpen?: (value: boolean) => void;
  instance: TableInstance | undefined;
  setInstance: Dispatch<SetStateAction<TableInstance<{}> | undefined>>;
}

export const staticColumns = [
  "status-icon",
  "id",
  "icon",
  "govProgram",
  "vicePremier",
  "foiv",
];
const sortedColumns = [
  "Код ГП",
  "Госпрограмма",
  "Факт",
  "Факт, %",
  "План, млрд ₽",
  "План",
  "Факт",
  "Не выполнено",
  "Уровень достижения, %",
];

const RenderTable: React.FC<IGeneralInfoTabProps> = ({
  forwardRef,
  onScroll,
  isModalOpen,
  setModalOpen,
  instance,
  setInstance,
}) => {
  const { sources }: IGlobalFilters = useGlobalFilters();
  const {
    programsSummaryData: programsData,
    programsSummaryExport: programsExport,
  } = useTableData();
  const { setSearchRows } = useFilters();
  const { fetching } = usePrograms();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));

  const columns: Column[] = useMemo(() => {
    return getGeneralInfoColumns(sources.OM);
  }, [sources.OM]);

  const { allColumns } = instance || {};

  const tableHeight: string | undefined = useMemo(() => {
    if (isModalOpen) return;
    return matches ? css.tableHeightLong : css.tableHeight;
  }, [isModalOpen, matches]);

  const onChangeFilterRows = useCallback(
    (rows: CustomRowProps[]) => {
      setSearchRows(createGpRowsTableData(rows, sources.OM));
    },
    [setSearchRows, sources.OM]
  );

  return (
    <>
      {isModalOpen && (
        <Box sx={css.modalActionButtons}>
          {(matches || isModalOpen) && <GeneralInfoTabFilters />}
          <Box sx={css.actionButtonsContainer(isModalOpen)}>
            <SecretSwitcher />
            <TableActionsButtons
              open={isModalOpen}
              setOpen={setModalOpen}
              {...programsExport}
            />
            {allColumns && (
              <ColumnsController
                allColumns={allColumns}
                statics={staticColumns}
              />
            )}
          </Box>
        </Box>
      )}
      <Box sx={css.tablesWrapper} style={{ rowGap: "5rem" }}>
        <IncidentReport>
          <Table
            data={programsData}
            columns={columns}
            loading={fetching}
            sortedColumns={sortedColumns}
            tableHeight={tableHeight}
            forwardRef={forwardRef}
            onScroll={onScroll}
            noDataCondition={programsData?.length === 0}
            sticky
            getInstance={setInstance}
            classes={{
              tr: "table-row",
              tbody: "table-body",
              tfoot: "table-footer",
            }}
            onFilterRows={onChangeFilterRows}
          />
        </IncidentReport>
      </Box>
    </>
  );
};

export const GeneralFullInfoTab = (props: IGeneralInfoTabProps) => {
  const { isModalOpen, setModalOpen } = props;

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));
  const { sources }: IGlobalFilters = useGlobalFilters();

  const {
    bestGpOMData,
    worstGpOMData,
    bestGPData,
    worstGPData,
    bestGPFetching,
    worstGPFetching,
    bestGpOMFetching,
    worstGpOMFetching,
  } = useRatingData();

  const { dataBestGP, dataWorstGP } = useMemo(() => {
    if (sources.OM) {
      return {
        dataBestGP: bestGpOMData,
        dataWorstGP: worstGpOMData,
      };
    }

    return {
      dataBestGP: bestGPData,
      dataWorstGP: worstGPData,
    };
  }, [sources.OM, bestGpOMData, bestGPData, worstGpOMData, worstGPData]);

  return (
    <Stack style={css.tabContainer}>
      <Box sx={{ pb: "1rem" }}>
        <RatingTabs
          dataBest={dataBestGP}
          dataWorst={dataWorstGP}
          bestFetching={bestGpOMFetching || bestGPFetching}
          worstFetching={worstGpOMFetching || worstGPFetching}
        />
      </Box>
      <RenderTable {...props} />
      {!matches && (
        <TableModal
          open={isModalOpen}
          setOpen={setModalOpen}
          tableName="Сводная таблица. Госпрограммы"
        >
          <RenderTable {...props} />
        </TableModal>
      )}
    </Stack>
  );
};
