import { Box } from "@mui/material";
import React from "react";
import { TableProps, UseTableRowProps } from "react-table";
import { Percent } from "SRC/components/Percent/Percent";
import { SlimProgressBar } from "SRC/components/ProgressBar";
import { css } from "SRC/components/Table/Table.styled";
import { TableCell } from "SRC/components/TableCell";
import { getRoundValue } from "SRC/helpers/getRoundValue";
import theme from "SRC/theme";

import { css as nationalCss } from "./NationalGoalsTable.styled";

interface CustomTableProps extends TableProps {
  row: UseTableRowProps<any>;
}

const getNationalGoalsColumns = () => {
  return [
    {
      Header: " ",
      accessor: "icon",
      width: "4rem",
      Cell: (tableProps: CustomTableProps) => (
        <Box sx={css.centered}>
          <img
            style={{ ...css.contained, borderRadius: "0.5rem" }}
            src={tableProps.row.original.icon}
            alt="govProgram"
          />
        </Box>
      ),
    },
    {
      Header: "Наименование цели",
      accessor: "goalTitle",
      width: "auto",
      Cell: (tableProps: CustomTableProps) => (
        <TableCell>{tableProps.row.original.goalTitle}</TableCell>
      ),
    },
    {
      Header: "Уровень достижения Национальной цели",
      accessor: "performance",
      width: "30%",
      Cell: (tableProps: CustomTableProps) => (
        <Box sx={nationalCss.achievementContainer}>
          <Box
            sx={{
              display: "flex",
              alignItems: "baseline",
              justifyContent: "center",
            }}
          >
            <TableCell align="center">
              {`${getRoundValue(tableProps.row.original.performance || 0, 1)}`}
              <Percent />
            </TableCell>
          </Box>
          <SlimProgressBar
            progressColor={theme.palette.primary.contrastText}
            value={tableProps.row.original.performance || 0}
          />
        </Box>
      ),
    },
    {
      Header: "Количество Госпрограмм",
      accessor: "numOfPrograms",
      width: "20%",
      Cell: (tableProps: CustomTableProps) => (
        <TableCell align="center">
          {tableProps.row.original.numOfPrograms}
        </TableCell>
      ),
    },
  ];
};

export { getNationalGoalsColumns };
