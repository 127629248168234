import { styled } from "@mui/material";
import THEME from "SRC/theme";

const COLORS = {
  BLUE: "blue",
  GREEN: "green",
  GRAY: "gray",
  PALE_GREEN: "paleGreen",
  PALE_RED: "paleRed",
  RED: "red",
  WHITE: "white",
} as const;

const COLOR_VALUES = {
  [COLORS.BLUE]: THEME.palette.text.opinion,
  [COLORS.GREEN]: THEME.palette.success.main,
  [COLORS.GRAY]: THEME.palette.main.gray,
  [COLORS.PALE_GREEN]: THEME.palette.pale.paleGreen,
  [COLORS.PALE_RED]: THEME.palette.pale.paleRed,
  [COLORS.RED]: THEME.palette.error.main,
  [COLORS.WHITE]: THEME.palette.text.onAccent,
};

interface IColors {
  [COLORS.BLUE]: string;
  [COLORS.GREEN]: string;
  [COLORS.GRAY]: string;
  [COLORS.PALE_GREEN]: string;
  [COLORS.PALE_RED]: string;
  [COLORS.RED]: string;
  [COLORS.WHITE]: string;
}
type TColor = keyof IColors;

type ITableCellProps = {
  color?: TColor;
  align?: "left" | "center";
};

export const TableCell = styled("span")<ITableCellProps>(
  ({ color = COLORS.WHITE, align = "left" }) => {
    return {
      color: COLOR_VALUES[color],
      display: "inline-block",
      width: "100%",
      textAlign: align,
    };
  }
);
