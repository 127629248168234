import { styled } from "@mui/material";

export const Wrapper = styled("div")(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  border: "1px solid #2E364A",
  borderRadius: "1rem",
  margin: "0 1.3rem",
  marginBottom: "1rem",

  label: {
    color: "#ffffff",
    fontSize: "1rem",
  },

  button: {
    textTransform: "none",
    fontSize: "1rem",
  },

  "& .file-name": {
    marginLeft: "1rem",
    borderBottom: "1px dashed #ffffff",
  },
}));
