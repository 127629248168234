import { TEventStatus } from "SRC/types";

export const indicatorsStatuses = [
  { status: "Выполнено", amount: 0 },
  { status: "Не достигнуто", amount: 0 },
  { status: "Не начато", amount: 0 },
];

export const eventsStatuses = [
  { status: "Выполнено", amount: 0 },
  { status: "В реализации", amount: 0 },
  { status: "Не начато", amount: 0 },
  { status: "Под риском", amount: 0 },
  { status: "ЧС", amount: 0 },
  { status: "Не выполнено", amount: 0 },
];

export const indicatorsSEStatuses = [
  { status: "Выполнено", amount: 0 },
  { status: "Не достигнуто", amount: 0 },
  { status: "Не начато", amount: 0 },
];

export const isStatusMatch = (selectedStatus: string[], item: any) => {
  return selectedStatus.every((selectedStatus) => {
    return item.status[selectedStatus as TEventStatus];
  });
};
