import { setAuthHeader } from "CORE/api/client";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "SRC/redux/hooks";
import { actions as tokenActions } from "SRC/redux/slices/auth/slices/tokenSlice";
import { actions as userActions } from "SRC/redux/slices/auth/slices/userSlice";
import { useGlobalState } from "SRC/redux/slices/global/hooks/useGlobalState";

const GRANTED_GROUP_PREFIX = ["gp_", "arm_gosprog", "customer"];

export const useAppInit = () => {
  const dispatch = useAppDispatch();
  const [isInit, setIsInit] = useState(false);
  const [isAccessGranted, setIsAccessGranted] = useState(false);
  const { isPrepared, init } = useGlobalState();
  const {
    fetching: tokenFetching,
    fetched: isTokenFetched,
    error: tokenError,
    access_token,
  } = useAppSelector((state) => state.auth.token);
  const {
    fetching: userFetching,
    fetched: isUserFetched,
    error: userError,
    user,
    groups,
    groupsFetching,
    groupsFetched,
  } = useAppSelector((state) => state.auth.user);

  const error = tokenError || userError;
  const isLoading = groupsFetching || tokenFetching || userFetching || !isInit;
  const tokenInfo = localStorage.getItem("tokenInfo");

  useEffect(() => {
    const username = user?.login || "";

    if (tokenInfo && username) {
      const { refresh_token, access_token } = JSON.parse(tokenInfo);

      const accessTokenInfo = atob(access_token.split(".")[1]);
      const { exp } = JSON.parse(accessTokenInfo);
      const expDate = new Date(exp * 1000);

      const updateTimeOffset = expDate.getTime() - Date.now();

      setTimeout(
        () => dispatch(tokenActions.refreshToken({ username, refresh_token })),
        updateTimeOffset
      );
    } else {
      dispatch(tokenActions.getToken());
    }
  }, [dispatch, tokenInfo]);

  useEffect(() => {
    if (isTokenFetched && !tokenFetching) {
      if (access_token) {
        setAuthHeader(access_token);
      }

      if (!user) {
        dispatch(userActions.getAuthorized());
      } else {
        dispatch(userActions.getUserGroups());
        setIsInit(true);
      }
    }
  }, [access_token, dispatch, tokenFetching, isTokenFetched, user]);

  useEffect(() => {
    if (isUserFetched && !userFetching && groupsFetched) {
      setIsAccessGranted(
        Array.from(groups).some((group) => {
          return GRANTED_GROUP_PREFIX.some((prefix) =>
            group.startsWith(prefix)
          );
        })
      );

      setIsInit(true);
    }
  }, [isUserFetched, userFetching, groupsFetched]);

  useEffect(() => {
    if (isInit && !isLoading && user) {
      init();
    }
  }, [isInit, isLoading, user]);

  return {
    user,
    isAccessGranted,
    isLoading: (isLoading || !isPrepared) && !error,
    error,
  };
};
