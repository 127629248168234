import { Box, SxProps } from "@mui/material";
import { SortIcon, SortIconDefault } from "ASSETS/svg";
import React, {
  BaseSyntheticEvent,
  CSSProperties,
  MutableRefObject,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Cell,
  Column,
  ColumnInstance,
  FooterProps,
  HeaderGroup,
  IdType,
  Renderer,
  Row,
  SortingRule,
  TableCellProps,
  TableInstance,
  TableState,
  useExpanded,
  useFlexLayout,
  useGlobalFilter,
  useSortBy,
  UseSortByColumnProps,
  useTable,
} from "react-table";
import { useSticky } from "react-table-sticky";
import { OverlayBlock } from "SRC/components/OverlayBlock";
import { createRadiusCornersSVGPath } from "SRC/helpers/ui-helpers";
import { CustomRowProps } from "SRC/types/data/data";

import { css, cssSortingArrows } from "../Base/Base.styled";
import { BaseDefault } from "./BaseDefault";

interface IDefaultData {
  id?: string | number;
  in_performance_foiv?: boolean | undefined;
  inPerformance?: boolean | undefined;
}

export type TDefaultData<D> = IDefaultData & D & object;

interface IStickyTableCell extends TableCellProps {
  "data-sticky-last-left-td": boolean;
}

export enum ETableEl {
  table = "table",
  thead = "thead",
  tbody = "tbody",
  tfoot = "tfoot",
  tr = "tr",
  th = "th",
  td = "td",
  cell = "cell",
  tf = "tf",
}

export type TClasses = Partial<Record<keyof typeof ETableEl, string>>;

// @ts-ignore
interface IProps<DATA extends object> extends Partial<TableInstance<DATA>> {
  data: DATA[];
  columns: ReadonlyArray<Column<DATA>>;
  loading?: boolean;
  noDataCondition?: boolean;
  onFilterRows?: (rows: CustomRowProps[]) => void;
  sortedColumns?: string[];
  tableHeight?: string | number;
  onClick?(id: number | string, nested?: boolean): void;
  onDoubleClick?(id: number | string, nested?: boolean): void;
  selectable?: boolean;
  selectedRow?: string | number;
  setSelectedRow?(id: number | string, nested?: boolean): void;
  scrollHide?: boolean;
  forwardRef?: MutableRefObject<HTMLElement | undefined>;
  onScroll?: (scroll: BaseSyntheticEvent) => void;
  inlineFooter?: boolean;

  initialState?: Partial<TableState<DATA>>;
  getLocalState?: (state: Partial<TableState<DATA>>) => void;
  getInstance?(tableInstance: TableInstance<DATA>): void;
  setSorting?: (sortBy: SortingRule<DATA>) => void;
  sticky?: boolean;
  expandable?: boolean;
  classes?: TClasses;
  sources?: boolean;
  inPerformance?: boolean;
  sortedByPerformance?: string;
}

const getStickyWidth = (cell: IStickyTableCell): number => {
  const lastStickyStyles: CSSProperties | false | undefined =
    cell["data-sticky-last-left-td"] && cell.style;
  return lastStickyStyles
    ? ["left", "width"].reduce(
        (acc: number, style: string) =>
          // @ts-ignore
          (+lastStickyStyles[style]?.split("px")[0] || 0) + acc,
        0
      )
    : 0;
};

interface TTestCol<D extends object> {
  Footer?: Renderer<FooterProps<D>>;
  columns?: TTestCol<D>[];
}

const testOnFooter = <D extends object>({
  Footer,
  columns,
}: TTestCol<D>): boolean =>
  Boolean(Footer) || Boolean(columns?.some(testOnFooter));

interface ISortingArrows {
  isSorted: boolean;
  isSortedDesc: boolean;
}

const equalParentHead = (column: ColumnInstance): boolean => {
  const { parent } = column;
  return !!parent && !parent.Header && column.id === parent.id;
};

const SortingIcons: React.FC<ISortingArrows> = ({ isSorted, isSortedDesc }) => {
  return (
    <Box sx={cssSortingArrows(isSortedDesc)}>
      {isSorted ? <SortIcon /> : <SortIconDefault />}
    </Box>
  );
};

const Base = <D extends object>({
  columns,
  data,
  loading,
  noDataCondition,
  noDataText = "Нет данных",
  onFilterRows,
  sortedColumns,
  onClick,
  onDoubleClick,
  selectedRow,
  setSelectedRow,
  selectable = false,

  scrollHide,
  onScroll,
  forwardRef,
  tableHeight,
  inlineFooter = false,

  setSorting,
  initialState,
  getLocalState,
  getInstance,
  sticky = false,
  expandable = false,
  classes,
  sources,
  inPerformance,
  sortedByPerformance,
}: IProps<D>): JSX.Element => {
  const [clipPath, setClipPath] = useState<string>("");
  const wrapperRef = useRef<HTMLElement>();
  const table = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          {
            id: sortedByPerformance || "",
            desc: false,
          },
        ],
      },
    },
    useGlobalFilter,
    useSortBy,
    useFlexLayout,
    sticky ? useSticky : () => null,
    expandable ? useExpanded : () => null
  );

  const {
    initialState: initState,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    toggleRowExpanded,
    state: { sortBy, expanded },
  } = table;

  const handleTableClip = () => {
    const tableNode = wrapperRef?.current?.firstChild;
    if (tableNode) {
      const path = createRadiusCornersSVGPath(tableNode as HTMLDivElement);
      setClipPath(path);
    }
  };

  useEffect(() => {
    handleTableClip();
  }, [rows]);

  useLayoutEffect(() => {
    handleTableClip();
    window.addEventListener("resize", handleTableClip);
    return () => window.removeEventListener("resize", handleTableClip);
  }, []);

  useEffect(() => {
    if (onFilterRows && rows.length) {
      onFilterRows(rows);
    }
  }, [rows]);

  useEffect(() => {
    getInstance?.(table);
  }, [table]);

  const [expandedRowsState, setExpandedRowsState] = useState<
    Partial<Record<IdType<D>, boolean>>
  >({});

  useEffect(() => {
    setExpandedRowsState(expanded);
  }, [expanded]);

  useEffect(() => {
    const rowIds = expandedRowsState && Object.keys(expandedRowsState);
    if (toggleRowExpanded && rowIds.length) {
      toggleRowExpanded(rowIds, true);
    }
  }, [data]);

  const hasFooter = useMemo(() => columns.some(testOnFooter), [columns]);

  const [selected, setSelected] = useState<undefined | string | number>("");
  const setLocalSelection = useCallback(
    (v: string | number = ""): void => {
      setSelected(selectable ? v : "");
    },
    [selectable, setSelected]
  );

  useEffect(() => {
    if (typeof selectedRow !== undefined) setSelected(selectedRow);
  }, [selectedRow]);

  const handleMultiSortBy = useCallback(
    (column: UseSortByColumnProps<D> & Column) => () => {
      if (column && sortedColumns?.includes(String(column?.Header))) {
        if (column.isSorted && !column.isSortedDesc) {
          return column.clearSortBy();
        }
        column.toggleSortBy(!column.isSortedDesc);
      }
    },
    [sortedColumns, initState]
  );

  const handleClick = (id: number | string, depth: number) => () => {
    onClick?.(id);
    if (setSelectedRow) setSelectedRow(id, Boolean(depth));
    else setLocalSelection(id);
  };

  const headers = useMemo(
    () =>
      headerGroups.map((headerGroup: HeaderGroup<D>) => (
        <Box
          className={`${ETableEl.tr} ${classes?.tr}`}
          {...headerGroup.getHeaderGroupProps()}
          sx={css.tr as SxProps}
        >
          {/* TODO: any !!!!!!!!!!!!!!!!! */}
          {headerGroup?.headers.map((column: any, index: number) => {
            const Tooltip = column.Tooltip || React.Fragment;

            return (
              <Box
                {...column.getHeaderProps(
                  column.getSortByToggleProps({ title: undefined })
                )}
                sx={css.th(
                  !!sortedColumns?.includes(column.Header),
                  column.align,
                  column.width
                )}
                width={column.width || "auto"}
                maxWidth={column.totalWidth}
                key={index}
                onClick={handleMultiSortBy(column)}
                className={`${ETableEl.th} ${classes?.th}`}
              >
                <Tooltip>
                  <Box sx={css.thContent(equalParentHead(column))}>
                    <Box sx={{ flex: 1 }}>{column.render("Header")}</Box>
                    {sortedColumns?.includes(column.Header) && (
                      <SortingIcons {...column} />
                    )}
                  </Box>
                </Tooltip>
              </Box>
            );
          })}
        </Box>
      )),
    [
      headerGroups,
      classes,
      sortedColumns,
      handleMultiSortBy,
      sortBy,
      initialState?.sortBy,
    ]
  );

  const footer = useMemo(
    () =>
      hasFooter &&
      footerGroups
        ?.filter(({ headers }) => headers.some((item) => Boolean(item.Footer)))
        ?.map((group: HeaderGroup<D>) => (
          <Box
            className={`${ETableEl.tr} ${classes?.tr}`}
            sx={css.tr as SxProps}
            {...group?.getFooterGroupProps()}
          >
            {group?.headers.map((column) => (
              <Box
                className={`${ETableEl.tf} ${classes?.tf}`}
                {...column.getHeaderProps()}
                sx={css.tf(column.width)}
                maxWidth={column.totalWidth}
              >
                {column.render("Footer")}
              </Box>
            ))}
          </Box>
        )),
    [hasFooter, footerGroups, classes]
  );

  return (
    <Box ref={wrapperRef} sx={css.wrapper(tableHeight) as SxProps}>
      <Box
        className={`${ETableEl.table} sticky ${classes?.table}`}
        {...getTableProps()}
        sx={css.table(scrollHide, clipPath) as SxProps}
        ref={forwardRef}
        onScroll={onScroll}
      >
        <Box
          className={`${ETableEl.thead} ${classes?.thead}`}
          sx={css.thead as SxProps}
        >
          {headers}
        </Box>

        <OverlayBlock
          hasData={Boolean(rows.length)}
          isFetching={Boolean(loading)}
        >
          <Box
            className={`${ETableEl.tbody} ${classes?.tbody}`}
            {...getTableBodyProps()}
          >
            {rows.map((row: Row<TDefaultData<D>>, index: number) => {
              prepareRow(row);
              row.index = index + 1;

              const {
                subRows,
                allCells,
                getRowProps,
                // @ts-ignore
                originalSubRows,
                isExpanded,
                canExpand,
                toggleRowExpanded,
                getToggleRowExpandedProps,
                ...otherRowProps
              } = row;

              return (
                <Box
                  {...otherRowProps}
                  className={`${ETableEl.tr} ${classes?.tr}`}
                  sx={
                    css.tableRow(
                      Boolean(onClick) || Boolean(setSelectedRow),
                      !!row.original?.id && row.original?.id === selected,
                      !selected,
                      row.original?.in_performance_foiv ||
                        row.original?.inPerformance,
                      inPerformance
                    ) as SxProps
                  }
                  key={row.id}
                  onClick={handleClick(row.original.id || "", row.depth)}
                >
                  {row.cells.map((cell: Cell<D>, index) => {
                    const props: TableCellProps = cell.getCellProps();
                    // @ts-ignore
                    const { width = "auto" } = cell.column;

                    return (
                      <Box
                        {...props}
                        className={`${ETableEl.td} ${classes?.td}`}
                        sx={
                          css.td(
                            width,
                            getStickyWidth(props as IStickyTableCell)
                          ) as SxProps
                        }
                      >
                        <BaseDefault
                          classes={classes}
                          cell={cell}
                          index={index}
                        />
                      </Box>
                    );
                  })}
                </Box>
              );
            })}
          </Box>
        </OverlayBlock>
        {hasFooter && (
          <Box
            className={`${ETableEl.tfoot} ${classes?.tfoot}`}
            sx={css.tfoot(inlineFooter) as SxProps}
          >
            {footer}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Base;
