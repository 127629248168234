import { SerializedError } from "@reduxjs/toolkit";
import { useCallback, useState } from "react";
import { IEventParams } from "SRC/types";

import { useAppDispatch, useAppSelector } from "../../../hooks";
import actions from "../../event/actions";
import { IObject } from "../slices/listOfObjects";

export interface IListOfObjectsData {
  fetching: boolean;
  fetched: boolean;
  items: IObject[];
  load(params: IEventParams): void;
  error: SerializedError | null;
}

export const useListOfObjects = (): IListOfObjectsData => {
  const dispatch = useAppDispatch();
  const {
    fetching,
    items: listOfObjects = [],
    fetched,
  } = useAppSelector((state) => state.event.listOfObjects);
  const [error, setError] = useState<SerializedError | null>(null);

  const load = useCallback(
    (params: IEventParams) => {
      if (!fetching) {
        dispatch(actions.listOfObjects.get(params)).then((action) => {
          if (actions.listOfObjects.get.rejected.match(action)) {
            setError(action.error);
          }
          return action;
        });
      }
    },
    [dispatch, fetching]
  );

  return {
    fetching,
    fetched,
    items: listOfObjects,
    load,
    error,
  };
};

export type { IObject };
