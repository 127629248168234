import { Tooltip } from "@mui/material";
import React from "react";
import { CheckSecretData } from "SRC/components/CheckSecretData";
import { TableCell } from "SRC/components/TableCell";
import { sortIndicatorsSEPlanColumn } from "SRC/helpers";
import { ICustomTableProps } from "SRC/types";

import { getIndicatorsSEPlan, getIndicatorsSEPlanTotal } from "./utils";

export const indicatorsSEPlan = (isOM: boolean) => ({
  Header: "План",
  sortType: sortIndicatorsSEPlanColumn(isOM),
  width: 130,
  accessor: isOM ? "indicatorsSEOMPlan" : "indicatorsSEPlan",
  Cell: (tableProps: ICustomTableProps) => {
    const { indicatorsSEPlan, isSecret } = getIndicatorsSEPlan(
      tableProps,
      isOM
    );

    return (
      <TableCell color={isOM ? "blue" : "white"}>
        <CheckSecretData isSecret={isSecret}>
          {indicatorsSEPlan}
        </CheckSecretData>
      </TableCell>
    );
  },
  Footer: (tableProps: ICustomTableProps) => {
    const { indicatorsSEPlanTotal } = getIndicatorsSEPlanTotal(
      tableProps,
      isOM
    );

    return (
      <Tooltip title="В расчете участвуют уникальные элементы">
        <TableCell color={isOM ? "blue" : "white"}>
          {indicatorsSEPlanTotal}
        </TableCell>
      </Tooltip>
    );
  },
});
