import classnames from "classnames";
import React, { useMemo } from "react";
import { CheckSecretData } from "SRC/components/CheckSecretData";
import { SlimProgressBar } from "SRC/components/ProgressBar";
import { WIPTag } from "SRC/components/WIP";
import { getRoundValue } from "SRC/helpers/getRoundValue";
import { roundNumbersToFixed } from "SRC/helpers/roundNumbersToFixed";
import { FinancingWrapper } from "SRC/pages/Main/common/Analitics/Financing/Financing.styled";
import { useFilters } from "SRC/redux/slices/main/hooks/useFilters";
import { useStats } from "SRC/redux/slices/main/hooks/useStats";
import theme from "SRC/theme";
import { IFinancingData } from "SRC/types/analitics";

export const Financing = () => {
  const { items: stats } = useStats();
  const data = useMemo(() => stats[0]?.data || {}, [stats]);
  const { isSphereSecret } = useFilters();

  const financingData = useMemo<IFinancingData>(
    () => ({
      reportData: data["Дата состояния финансирования"],
      extrabudgetary: {
        plan: data["Финансирование внебюджет план"] || 0,
        fact: data["Финансирование внебюджет факт"] || 0,
        performance: data["Финансирование внебюджет УД"] || 0,
      },
      budget: {
        plan: data["Финансирование бюджет план"],
        fact: data["Финансирование бюджет факт"],
        performance: data["Финансирование бюджет УД"] || 0,
      },
      totalFinancing: {
        plan: data["Финансирование план"],
        fact: data["Финансирование факт"],
      },
    }),
    [data]
  );

  return (
    <FinancingWrapper
      className={classnames({
        "is-secret": isSphereSecret,
      })}
    >
      <CheckSecretData isSecret={isSphereSecret}>
        <div className="item">
          <div className="title">
            Бюджет
            <div className="unit-title">млрд ₽</div>
          </div>
          <SlimProgressBar
            value={roundNumbersToFixed(
              financingData?.budget.performance || 0,
              2
            )}
            circleLabel={financingData?.budget.fact || 0}
            bottomLeftLabel={0}
            bottomRightLabel={
              getRoundValue(financingData?.budget.plan, 2) || " "
            }
            bottomLabelColorRight={theme.palette.charts.whiteTransparent}
            bottomLabelColorLeft={theme.palette.primary.contrastText}
            withLabel
            progressColor={theme.palette.pale.blue}
          />
        </div>
        <div className="item">
          <div className="title">
            Внебюджет
            <div className="unit-title">млрд ₽</div>
          </div>
          <SlimProgressBar
            value={roundNumbersToFixed(
              financingData?.extrabudgetary.performance || 0,
              2
            )}
            circleLabel={financingData?.extrabudgetary.fact}
            bottomLeftLabel={0}
            bottomRightLabel={
              getRoundValue(financingData?.extrabudgetary.plan, 2) || " "
            }
            bottomLabelColorRight={theme.palette.charts.whiteTransparent}
            bottomLabelColorLeft={theme.palette.primary.contrastText}
            withLabel
            progressColor={theme.palette.pale.blue}
          />
        </div>
      </CheckSecretData>
    </FinancingWrapper>
  );
};
