import { Box } from "@mui/material";
import React from "react";
import { IOPValue, OverlayProgressBar } from "SRC/components/ProgressBar";
import { SourceValue } from "SRC/components/SourceValue/SourceValue";

import { css } from "./LineProgress.styled";

export interface ILineProgressProps {
  name: string;
  value: number;
  percents: IOPValue[];
  isMain?: boolean;
}

export const LineProgress = ({
  name,
  value,
  percents,
  isMain = false,
}: ILineProgressProps) => {
  const labels = percents.map(({ value, settings }) => (
    <SourceValue
      rounded
      value={Math.trunc(value as number)}
      settings={settings}
      key={settings.title}
    />
  ));

  return (
    <Box sx={css.progressBar}>
      <Box sx={css.nameAndPercent}>
        <Box sx={css.name(isMain)}>{name}</Box>
        <Box sx={css.percent(isMain)}>{value}</Box>
      </Box>
      <Box sx={css.bar}>
        <OverlayProgressBar
          values={percents}
          startValue={<Box sx={css.barValue}>{labels}</Box>}
          endValue={100}
          hasContrastBG
        />
      </Box>
    </Box>
  );
};
