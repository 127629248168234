import { createSlice } from "@reduxjs/toolkit";

export interface IFiltersState {
  selectStructureElement: string;
  selectGosprogram: string;
}

const initialState: IFiltersState = {
  selectGosprogram: "",
  selectStructureElement: "",
};

const slice = createSlice({
  name: "structureFilters",
  initialState,
  reducers: {
    clear: () => initialState,
    setStructureElement: (state, action: { payload: string }) => {
      state.selectStructureElement = action.payload;
    },
    setGosprogram: (state, action: { payload: string }) => {
      state.selectGosprogram = action.payload;
    },
  },
});

export default slice;
