import { Box, Button, SxProps, useMediaQuery, useTheme } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Column, TableInstance, UseTableRowProps } from "react-table";
import { TableModal } from "SRC/components/FullPageTableModal/FullPageTableModal";
import { IncidentReport } from "SRC/components/IncidentReport";
import { OMSwitcher } from "SRC/components/OMSwitcher";
import Table from "SRC/components/Table";
import TableStatusCardNew from "SRC/components/TableStatusCardNew/TableStatusCardNew";
import { UnderGpCheckbox } from "SRC/components/UnderGpCheckbox/UnderGpCheckbox";
import { TableSearch } from "SRC/pages/Program/common/EventsTab/TableSearch/TableSearch";
import { FilterNameStructure } from "SRC/pages/Program/common/filters/FilterNameStructureSEIndicators";
import { FilterTaskStructures } from "SRC/pages/Program/common/filters/FilterTasksStructure";
import { FilterTypeStructure } from "SRC/pages/Program/common/filters/FilterTypeStructure";
import { getIndicatorsColumns } from "SRC/pages/Program/common/IndicatorsTab/tableColumns";
import { IStatus } from "SRC/pages/Program/common/ProgramSummary/interfaces";
import { useSEIndicatorsTableData } from "SRC/pages/Program/tableData";
import { createIndicatorsSERowsTableData } from "SRC/pages/Program/utils";
import {
  IGlobalFilters,
  useGlobalFilters,
} from "SRC/redux/slices/global/hooks/useFilters";
import {
  useFilters,
  useIndicators,
  useProgram,
} from "SRC/redux/slices/gosprogram/hooks";
import { PROGRAM_INDICATOR_VIEW, setParamsToUrl } from "SRC/urls";

import { css, Wrapper } from "./IndicatorsTab.styled";

interface IIndicatorsTabProps {
  isTableModalOpen?: boolean;
  setTableModalOpen?: (value: boolean) => void;
  infoRows?: Record<string, string>[][];
  getData?: (data: any) => void;
}

const Statuses = () => {
  const { indicatorsStatuses: statuses } = useSEIndicatorsTableData();
  const { selectedStatus, setSelectedStatus, clearSelectedStatus } =
    useFilters();

  useEffect(() => () => clearSelectedStatus(), [clearSelectedStatus]);

  const handleClick =
    ({ status }: IStatus) =>
    () => {
      setSelectedStatus(status);
    };

  const statusesNodes: JSX.Element[] = useMemo(
    () =>
      statuses.map((item: IStatus) => (
        <TableStatusCardNew
          key={item.status}
          status={item.status}
          amount={item.amount}
          onClick={handleClick(item)}
          selected={selectedStatus.includes(item.status)}
        />
      )),
    [statuses, selectedStatus]
  );

  return <Box sx={css.statusCardContainer}>{statusesNodes}</Box>;
};

const RenderTable: React.FC<IIndicatorsTabProps> = ({
  isTableModalOpen,
  getData,
}: IIndicatorsTabProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { items: program } = useProgram();
  const [searchParams] = useSearchParams();
  const filterStatus = searchParams.get("filter");
  const [searchValue, setSearchValue] = useState<string>("");
  const [instance, setInstance] = useState<TableInstance | undefined>(
    undefined
  );
  const { sources, isUnder }: IGlobalFilters = useGlobalFilters();

  const { fetching: indicatorsDataFetched } = useIndicators();
  const { indicatorsTableData } = useSEIndicatorsTableData();

  const columns: Column[] = useMemo(
    () => getIndicatorsColumns(sources.OM),
    [sources.OM]
  );
  const {
    setSelectedStructureElementName,
    setSelectedStructureElementType,
    setSelectedStructureElementTask,
    setSelectedEventFeature,
    selectedStructureElementType,
    setSelectedStatus,
    clearSelectedStatus,
    setSearchRows,
  } = useFilters();

  useEffect(() => {
    getData && getData(indicatorsTableData);
  }, [indicatorsTableData]);

  const selectStyle = useMemo(() => css.selector(theme), [theme]);

  const setTableFilter = useCallback(
    (value: string) => {
      instance?.setGlobalFilter(value);
      setSearchValue(value);
    },
    [instance]
  );

  const handleClearFilters = () => {
    setSelectedStructureElementType("");
    setSelectedStructureElementName("");
    setSelectedStructureElementTask("");
    setSelectedEventFeature("");
    setTableFilter("");
    clearSelectedStatus();
  };

  useEffect(() => {
    if (filterStatus && indicatorsDataFetched) {
      setSelectedStatus(filterStatus);
    }
  }, [filterStatus, indicatorsDataFetched]);

  const filteredIndicatorsTableData = useMemo(() => {
    if (isUnder) {
      return indicatorsTableData.filter(
        ({ is_under_gp_foiv = false, is_under_gp_om = false }) => {
          return sources.OM ? is_under_gp_om : is_under_gp_foiv;
        }
      );
    } else {
      return indicatorsTableData;
    }
  }, [isUnder, indicatorsTableData, sources.OM]);

  const onChangeFilterRows = useCallback(
    (rows: UseTableRowProps<any>[]) => {
      setSearchRows(createIndicatorsSERowsTableData(rows, sources.OM));
    },
    [sources.OM]
  );

  const handleOnClick = useCallback(
    (indicatorId: number | string) =>
      program &&
      navigate(
        setParamsToUrl(PROGRAM_INDICATOR_VIEW, {
          indicatorId,
          programId: program.gp_code,
        })
      ),
    [program, navigate]
  );

  return (
    <Wrapper>
      <Box sx={css.filtersContainer(isTableModalOpen) as SxProps}>
        <Box sx={css.searchSelectContainer}>
          <Box sx={{ flex: "1", minWidth: "20rem" }}>
            <TableSearch value={searchValue} onChange={setTableFilter} />
          </Box>
          <FilterTypeStructure
            key={Math.random()}
            label="Тип структурного элемента"
            staticLabel={false}
            style={selectStyle}
            onChange={setSelectedStructureElementType}
            value={selectedStructureElementType}
          />
          <FilterNameStructure
            label="Наименование структурного элемента"
            staticLabel={false}
            style={selectStyle}
            onChange={setSelectedStructureElementName}
          />
          <FilterTaskStructures
            label="Наименование задачи"
            staticLabel={false}
            style={selectStyle}
            onChange={setSelectedStructureElementTask}
          />
          <Button sx={css.filterButton as SxProps} onClick={handleClearFilters}>
            Сбросить фильтры
          </Button>
          {isTableModalOpen && <OMSwitcher />}
        </Box>
        <Statuses />
        <UnderGpCheckbox checked={isUnder} />
      </Box>
      <IncidentReport className="indicators-tab-incident">
        <Table
          loading={indicatorsDataFetched}
          // @ts-ignore
          columns={columns}
          data={filteredIndicatorsTableData}
          sortedByPerformance={sources.OM ? "performanceOM" : "performance"}
          getInstance={setInstance}
          sortedColumns={[
            "Период достижения",
            "Факт",
            "План",
            "План на год",
            "Уровень достижения, %",
          ]}
          tableHeight={!isTableModalOpen ? "47.5rem" : undefined}
          onClick={handleOnClick}
          onFilterRows={onChangeFilterRows}
          inPerformance
        />
      </IncidentReport>
    </Wrapper>
  );
};

export const IndicatorsTab: React.FC<IIndicatorsTabProps> = (props) => {
  const { isTableModalOpen, setTableModalOpen } = props;
  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.up("lg"));

  if (!match && isTableModalOpen) {
    return (
      <TableModal
        open={isTableModalOpen}
        setOpen={setTableModalOpen}
        tableName="Показатели СЭ"
      >
        <RenderTable {...props} />
      </TableModal>
    );
  }

  return <RenderTable {...props} />;
};
