import { actions as cashExecution } from "../event/slices/cashExecution";
import { actions as controlPoints } from "../event/slices/controlPoints";
import { actions as listOfObjects } from "../event/slices/listOfObjects";
import { actions as objectPhotos } from "../event/slices/objectPhotos";
import { actions as objectVideos } from "../event/slices/objectVideos";
import { actions as subjects } from "../event/slices/subjects";
import { actions as data } from "./slices/data";

export interface IEventActions {
  controlPoints: typeof controlPoints;
  cashExecution: typeof cashExecution;
  subjects: typeof subjects;
  listOfObjects: typeof listOfObjects;
  data: typeof data;
  objectPhotos: typeof objectPhotos;
  objectVideos: typeof objectVideos;
}

const actions: IEventActions = {
  controlPoints,
  cashExecution,
  subjects,
  listOfObjects,
  data,
  objectPhotos,
  objectVideos,
};

export default actions;
