import { combineReducers } from "@reduxjs/toolkit";
import {
  IMethodsState,
  slice as methodsSlice,
} from "SRC/redux/slices/global/slices/methods";

import filtersSlice, { IFiltersState } from "./slices/filters";
import notification, { INotification } from "./slices/notification";

export interface IGlobalReducer {
  filters: IFiltersState;
  methods: IMethodsState;
  notification: INotification;
}

const reducer = combineReducers<IGlobalReducer>({
  filters: filtersSlice.reducer,
  methods: methodsSlice.reducer,
  notification: notification.reducer,
});

export default reducer;
