import { getDataSumByCodeGP } from "SRC/constants";
import { isSecret } from "SRC/helpers/dataTesters";
import { IProgramTableItem } from "SRC/hooks/summaryTable/interfaces";
import { generalRowsMainTableData } from "SRC/hooks/summaryTable/utils/utils";
import { IProgram } from "SRC/redux/list/programs";
import { CustomRowProps } from "SRC/types/data/data";

export const createProgramRow = (data: IProgram): IProgramTableItem => {
  const { info } = data;
  const isSecretProgram = isSecret(info);
  const elem = { ...data, data: Object.values(data.data) };

  return {
    id: info.code,
    isProgram: true,
    name: info.shortName,
    indicatorsPlan: getDataSumByCodeGP(elem, 1578) || 0,
    indicatorsFact: getDataSumByCodeGP(elem, 1579) || 0,
    indicatorsOMPlan: getDataSumByCodeGP(elem, 5283) || 0,
    indicatorsOMPlanTotal: getDataSumByCodeGP(elem, 5286) || 0,
    indicatorsOMFact: getDataSumByCodeGP(elem, 5284) || 0,
    indicatorsOMFactTotal: getDataSumByCodeGP(elem, 5287) || 0,
    eventsPlan: getDataSumByCodeGP(elem, 1584) || 0,
    eventsFact: getDataSumByCodeGP(elem, 1585) || 0,
    eventsRisk: getDataSumByCodeGP(elem, 1680) || 0,
    eventsOMPlan: getDataSumByCodeGP(elem, 5289) || 0,
    eventsOMPlanTotal: getDataSumByCodeGP(elem, 5292) || 0,
    eventsOMFact: getDataSumByCodeGP(elem, 5290) || 0,
    eventsOMRisk: getDataSumByCodeGP(elem, 1680) || 0,
    eventsOMRiskTotal: getDataSumByCodeGP(elem, 5306) || 0,
    eventsOMFactTotal: getDataSumByCodeGP(elem, 5293) || 0,
    eventsPlanTotal: getDataSumByCodeGP(elem, 1587) || 0,
    eventsFactTotal: getDataSumByCodeGP(elem, 1588) || 0,
    eventsRiskTotal: getDataSumByCodeGP(elem, 5306) || 0,
    secretProgram: isSecretProgram,
    performance: getDataSumByCodeGP(elem, 1796) || 0,
    performanceTotal: getDataSumByCodeGP(elem, 5246) || 0,
    performanceOM: getDataSumByCodeGP(elem, 5265) || 0,
    performanceOMTotal: getDataSumByCodeGP(elem, 5269) || 0,
    vp: info.vpCode,
    ministry: info.ministry,
    icon: info.icon,
    indicatorsPlanTotal: getDataSumByCodeGP(elem, 1581) || 0,
    indicatorsFactTotal: getDataSumByCodeGP(elem, 1582) || 0,
  };
};

export const createProgramsTableData = (
  data: IProgram[]
): IProgramTableItem[] => data.map((elem: IProgram) => createProgramRow(elem));

export const createGpRowsTableData = (data: CustomRowProps[], isOM: boolean) =>
  data.map((elem: CustomRowProps) => ({
    id: elem.original.id,
    icon: elem.original.icon,
    name: elem.original.name,
    ...generalRowsMainTableData(elem, isOM),
  }));
