import {
  IGovProgramDataItemWithoutIcon,
  IMinistriesTableItem,
  IPremierTableItem,
  IProgramTableItem,
} from "SRC/hooks/summaryTable/interfaces";
import { IEventExportItem } from "SRC/pages/Program/common/EventsSection/util/interfaces";
import {
  IIndicatorSEExportItem,
  IIndicatorsGPExportItem,
  IStructureGPExportItem,
  ITasksSEExportItem,
} from "SRC/pages/Program/common/ProgramSummary/interfaces";

const getGovProgramsWscols = (
  data: IGovProgramDataItemWithoutIcon[],
  govProgramsHeadings: Record<string, string>[]
) => [
  {
    wch: Math.max(govProgramsHeadings[8].id.length),
  },
  {
    wch: Math.max(...data.map((item) => String(item.name).length)),
  },
  {
    wch: Math.max(govProgramsHeadings[9].indicatorsPlan.length),
  },
  {
    wch: Math.max(govProgramsHeadings[9].indicatorsDone.length),
  },
  {
    wch: Math.max(govProgramsHeadings[9].events.length),
  },
  {
    wch: Math.max(govProgramsHeadings[9].total.length),
  },
  {
    wch: Math.max(govProgramsHeadings[9].atRisk.length),
  },
  {
    wch: Math.max(govProgramsHeadings[8].performance.length),
  },
];

const getVicePremiersWscols = (
  data: IPremierTableItem[],
  vicePremiersHeadings: Record<string, string>[]
) => [
  {
    wch: Math.max(...data.map((item) => String(item.name).length)),
  },
  {
    wch: Math.max(...data.map((item) => String(item.vicePremierPhoto).length)),
  },
  {
    wch: Math.max(vicePremiersHeadings[8].numOfPrograms.length),
  },
  {
    wch: Math.max(vicePremiersHeadings[9].indicatorsPlan.length),
  },
  {
    wch: Math.max(vicePremiersHeadings[9].indicatorsDone.length),
  },
  {
    wch: Math.max(vicePremiersHeadings[9].events.length),
  },
  {
    wch: Math.max(vicePremiersHeadings[9].plan.length),
  },
  {
    wch: Math.max(vicePremiersHeadings[9].fact.length),
  },
  {
    wch: Math.max(vicePremiersHeadings[8].performance.length),
  },
];

const getMinistriesWscols = (
  data: IMinistriesTableItem[],
  ministriesHeadings: Record<string, string>[]
) => [
  {
    wch: Math.max(...data.map((item) => String(item.name).length)),
  },
  {
    wch: Math.max(ministriesHeadings[8].numOfPrograms.length) * 2.5,
  },
  {
    wch: Math.max(ministriesHeadings[9].indicatorsPlan.length) * 2,
  },
  {
    wch: Math.max(ministriesHeadings[9].indicatorsFact.length) * 2,
  },
  {
    wch: Math.max(ministriesHeadings[9].plan.length) * 2,
  },
  {
    wch: Math.max(ministriesHeadings[9].fact.length) * 2,
  },
  {
    wch: Math.max(ministriesHeadings[9].atRisk.length),
  },
  {
    wch: Math.max(ministriesHeadings[8].performance.length),
  },
];

const getProgramEventsWscols = (
  data: IEventExportItem[],
  programEventsHeadings: Record<string, string>[]
) => [
  {
    wch: Math.max(...data.map((item) => String(item.id).length)),
  },
  {
    wch: Math.max(programEventsHeadings[9].structureElement.length) * 5,
  },
  {
    wch:
      Math.max(
        ...data.map((item) => String(item.structureElementName).length)
      ) / 7,
  },
  {
    wch: Math.max(...data.map((item) => String(item.name).length)) / 10,
  },
  {
    wch: Math.max(programEventsHeadings[9].eventTypeNameFull.length) * 2,
  },
  {
    wch: Math.max(...data.map((item) => String(item.units).length)),
  },
  {
    wch: Math.max(programEventsHeadings[9].plan.length) * 2,
  },
  {
    wch: Math.max(programEventsHeadings[9].fact.length),
  },
  {
    wch: Math.max(programEventsHeadings[9].done.length),
  },
  {
    wch: Math.max(programEventsHeadings[9].effect.length),
  },
];

const getSummaryProgramsWscols = (
  data: IProgramTableItem[],
  summaryProgramsHeadings: Record<string, string>[]
) => [
  {
    wch: Math.max(summaryProgramsHeadings[8].id.length),
  },
  {
    wch: Math.max(...data.map((item) => String(item.govProgram).length)),
  },
  {
    wch: Math.max(summaryProgramsHeadings[8].vicePremier.length) * 2.5,
  },
  {
    wch: Math.max(summaryProgramsHeadings[8].foiv.length) * 2.025,
  },
  {
    wch: Math.max(summaryProgramsHeadings[8].performance.length),
  },
  {
    wch: Math.max(summaryProgramsHeadings[9].cashPlan.length) * 2,
  },
  {
    wch: Math.max(summaryProgramsHeadings[9].cashFact.length) * 2,
  },
  {
    wch: Math.max(summaryProgramsHeadings[9].indicatorsPlan.length),
  },
  {
    wch: Math.max(summaryProgramsHeadings[9].indicatorsFact.length),
  },
  {
    wch: Math.max(summaryProgramsHeadings[9].indicatorsFail.length),
  },
  {
    wch: Math.max(summaryProgramsHeadings[9].structureElementsPlan.length),
  },
  {
    wch: Math.max(summaryProgramsHeadings[9].structureElementsFact.length),
  },
  {
    wch: Math.max(summaryProgramsHeadings[9].structureElementsFail.length),
  },
  {
    wch: Math.max(summaryProgramsHeadings[9].eventsPlan.length),
  },
  {
    wch: Math.max(summaryProgramsHeadings[9].eventsFact.length),
  },
  {
    wch: Math.max(summaryProgramsHeadings[9].eventsFail.length),
  },
  {
    wch: Math.max(summaryProgramsHeadings[9].checkpointsPlan.length),
  },
  {
    wch: Math.max(summaryProgramsHeadings[9].checkpointsFact.length),
  },
  {
    wch: Math.max(summaryProgramsHeadings[9].checkpointsFail.length),
  },
];

const getSummaryVicePremiersWscols = (
  data: IPremierTableItem[],
  summaryVicePremiersHeadings: Record<string, string>[]
) => [
  {
    wch: Math.max(...data.map((item) => String(item.name).length)) * 2,
  },
  {
    wch: Math.max(summaryVicePremiersHeadings[8].numOfPrograms.length),
  },
  {
    wch: Math.max(summaryVicePremiersHeadings[8].performance.length),
  },
  {
    wch: Math.max(summaryVicePremiersHeadings[8].cashPlan.length),
  },
  {
    wch: Math.max(summaryVicePremiersHeadings[9].cashPlan.length),
  },
  {
    wch: Math.max(summaryVicePremiersHeadings[9].cashPlan.length),
  },
  {
    wch: Math.max(summaryVicePremiersHeadings[8].indicatorsPlan.length),
  },
  {
    wch: Math.max(summaryVicePremiersHeadings[9].indicatorsPlan.length),
  },
  {
    wch: Math.max(summaryVicePremiersHeadings[9].indicatorsFact.length),
  },
  {
    wch: Math.max(summaryVicePremiersHeadings[9].indicatorsFail.length),
  },
  {
    wch: Math.max(summaryVicePremiersHeadings[8].structureElementsPlan.length),
  },
  {
    wch: Math.max(summaryVicePremiersHeadings[9].structureElementsPlan.length),
  },
  {
    wch: Math.max(summaryVicePremiersHeadings[9].structureElementsFact.length),
  },
  {
    wch: Math.max(summaryVicePremiersHeadings[9].structureElementsFail.length),
  },
  {
    wch: Math.max(summaryVicePremiersHeadings[8].eventsPlan.length),
  },
  {
    wch: Math.max(summaryVicePremiersHeadings[9].eventsPlan.length),
  },
  {
    wch: Math.max(summaryVicePremiersHeadings[9].eventsFact.length),
  },
  {
    wch: Math.max(summaryVicePremiersHeadings[9].eventsFail.length),
  },
  {
    wch: Math.max(summaryVicePremiersHeadings[8].checkpointsPlan.length),
  },
  {
    wch: Math.max(summaryVicePremiersHeadings[9].checkpointsPlan.length),
  },
  {
    wch: Math.max(summaryVicePremiersHeadings[9].checkpointsFact.length),
  },
  {
    wch: Math.max(summaryVicePremiersHeadings[9].checkpointsFail.length),
  },
];

const getSummaryMinistriesWscols = (
  data: IMinistriesTableItem[],
  summaryMinistriesHeadings: Record<string, string>[]
) => [
  {
    wch: Math.max(...data.map((item) => String(item.name).length)),
  },
  {
    wch: Math.max(summaryMinistriesHeadings[8].numOfPrograms.length),
  },
  {
    wch: Math.max(summaryMinistriesHeadings[8].performance.length),
  },
  {
    wch: Math.max(summaryMinistriesHeadings[8].cashPlan.length),
  },
  {
    wch: Math.max(summaryMinistriesHeadings[9].cashPlan.length),
  },
  {
    wch: Math.max(summaryMinistriesHeadings[9].cashPlan.length),
  },
  {
    wch: Math.max(summaryMinistriesHeadings[8].indicatorsPlan.length),
  },
  {
    wch: Math.max(summaryMinistriesHeadings[9].indicatorsPlan.length),
  },
  {
    wch: Math.max(summaryMinistriesHeadings[9].indicatorsFact.length),
  },
  {
    wch: Math.max(summaryMinistriesHeadings[9].indicatorsFail.length),
  },
  {
    wch: Math.max(summaryMinistriesHeadings[8].structureElementsPlan.length),
  },
  {
    wch: Math.max(summaryMinistriesHeadings[9].structureElementsPlan.length),
  },
  {
    wch: Math.max(summaryMinistriesHeadings[9].structureElementsFact.length),
  },
  {
    wch: Math.max(summaryMinistriesHeadings[9].structureElementsFail.length),
  },
  {
    wch: Math.max(summaryMinistriesHeadings[8].eventsPlan.length),
  },
  {
    wch: Math.max(summaryMinistriesHeadings[9].eventsPlan.length),
  },
  {
    wch: Math.max(summaryMinistriesHeadings[9].eventsFact.length),
  },
  {
    wch: Math.max(summaryMinistriesHeadings[9].eventsFail.length),
  },
  {
    wch: Math.max(summaryMinistriesHeadings[8].checkpointsPlan.length),
  },
  {
    wch: Math.max(summaryMinistriesHeadings[9].checkpointsPlan.length),
  },
  {
    wch: Math.max(summaryMinistriesHeadings[9].checkpointsFact.length),
  },
  {
    wch: Math.max(summaryMinistriesHeadings[9].checkpointsFail.length),
  },
];

const getProgramIndicatorsSEWscols = (
  data: IIndicatorSEExportItem[],
  programIndicatorsSEHeadings: Record<string, string>[]
) => [
  {
    wch: Math.max(...data.map((item) => String(item.id).length)),
  },
  {
    wch: Math.max(programIndicatorsSEHeadings[9].se_gp_type.length) * 5,
  },
  {
    wch: Math.max(...data.map((item) => String(item.name).length)) / 10,
  },
  {
    wch: Math.max(...data.map((item) => String(item.unit).length)),
  },
  {
    wch:
      Math.max(programIndicatorsSEHeadings[9].implementation_period.length) * 2,
  },
  {
    wch: Math.max(programIndicatorsSEHeadings[9].plan.length) * 2,
  },
  {
    wch: Math.max(programIndicatorsSEHeadings[9].planYear.length) * 2,
  },
  {
    wch: Math.max(programIndicatorsSEHeadings[9].fact.length),
  },
  {
    wch: Math.max(programIndicatorsSEHeadings[9].achievement.length),
  },
];

const getStructureGPWscols = (
  data: IStructureGPExportItem[],
  programIndicatorsSEHeadings: Record<string, string>[]
) => [
  {
    wch: Math.max(programIndicatorsSEHeadings[9].type.length),
  },
  {
    wch: Math.max(programIndicatorsSEHeadings[9].name.length) * 3,
  },
  {
    wch: Math.max(programIndicatorsSEHeadings[9].performance.length),
  },
  {
    wch: Math.max(programIndicatorsSEHeadings[9].cash.length),
  },
];

const getProgramTasksSEWscols = (
  data: ITasksSEExportItem[],
  programTasksSEHeadings: Record<string, string>[]
) => [
  {
    wch: Math.max(...data.map((item) => String(item.name).length)) / 5,
  },
  {
    wch: Math.max(programTasksSEHeadings[9].indicatorsCount.length),
  },
  {
    wch: Math.max(programTasksSEHeadings[9].eventsCount.length),
  },
];

const getProgramIndicatorsGPWscols = (
  data: IIndicatorsGPExportItem[],
  programIndicatorsGPHeadings: Record<string, string>[]
) => [
  {
    wch: Math.max(...data.map((item) => String(item.goal).length)) / 2,
  },
  {
    wch: Math.max(...data.map((item) => String(item.indicator).length)) / 5,
  },
  {
    wch: Math.max(programIndicatorsGPHeadings[9].performance.length),
  },
  {
    wch: Math.max(programIndicatorsGPHeadings[9].plan.length),
  },
  {
    wch: Math.max(programIndicatorsGPHeadings[9].fact.length),
  },
];

const getProgramIndicatorsAnalyticsGPWscols = (
  data: IIndicatorsGPExportItem[],
  programIndicatorsAnalyticsGPHeadings: Record<string, string>[]
) => [
  {
    wch: Math.max(...data.map((item) => String(item.indicator).length)) / 2,
  },
  {
    wch: Math.max(programIndicatorsAnalyticsGPHeadings[9].plan.length),
  },
  {
    wch: Math.max(programIndicatorsAnalyticsGPHeadings[9].fact.length),
  },
  {
    wch: Math.max(programIndicatorsAnalyticsGPHeadings[9].unit.length),
  },
];

const getSETasksWscols = (
  data: IMinistriesTableItem[],
  headings: Record<string, string>[]
) =>
  Object.entries(headings[0]).map((title) => ({
    wch: Math.max(title.length),
  }));

export {
  getGovProgramsWscols,
  getMinistriesWscols,
  getProgramEventsWscols,
  getProgramIndicatorsAnalyticsGPWscols,
  getProgramIndicatorsGPWscols,
  getProgramIndicatorsSEWscols,
  getProgramTasksSEWscols,
  getSETasksWscols,
  getStructureGPWscols,
  getSummaryMinistriesWscols,
  getSummaryProgramsWscols,
  getSummaryVicePremiersWscols,
  getVicePremiersWscols,
};
