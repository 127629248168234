import { styled } from "@mui/material";

export const Wrapper = styled("div")(() => {
  return {
    position: "relative",

    ".incident-btn": {
      position: "absolute",
      display: "none",
      right: 0,
      top: 0,
      cursor: "pointer",
      maxWidth: "20px",
      maxHeight: "20px",
      minWidth: "20px",
      minHeight: "20px",
      zIndex: 10,
    },

    "&:hover": {
      ".incident-btn": {
        //display: "flex",
        display: "none",
      },
    },
  };
});
