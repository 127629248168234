import { SxProps, Theme } from "@mui/material";

const inlineForm = {
  flexDirection: "row",
  alignItems: "center",
  gap: "0.25rem",
};

export const css = {
  select: (value: unknown, colorize: boolean, style: SxProps = {}) => ({
    "& .MuiSelect-select": {
      ...style,
      backgroundColor: (theme: Theme) =>
        value && colorize && theme.palette.background.header,
      color: (theme: Theme) => value && colorize && theme.palette.text.onAccent,
    },
    // Для стилей к варианту "Все"
    "& .MuiSelect-select.MuiInputBase-input.MuiInput-input": {
      ...style,
    },
  }),
  element: (inline: boolean) => ({
    flex: 1,
    width: "100%",
    ...(inline ? inlineForm : {}),
  }),
  label: {
    fontSize: "0.8rem",
    lineHeight: "2em",
    color: "text.secondary",
  },
  allItem: {
    marginBottom: "1rem",
    position: "relative",
    "&:after": {
      content: "''",
      position: "absolute",
      bottom: "-0.5rem",
      left: "0.5rem",
      width: "calc(100% - 1rem)",
      height: "1px",
      backgroundColor: (theme: Theme) => theme.palette.divider,
    },
    '&[aria-selected="true"]': {
      backgroundColor: (theme: Theme) => theme.palette.primary.main,
    },
  },
  closeButton: {
    width: "0.7rem",
    opacity: "0.5",
    marginTop: "0.35rem",
    cursor: "pointer",
  },
  arrowButton: {
    width: "1rem",
    cursor: "pointer",
  },
  iconComponent: {
    position: "absolute",
    right: "1rem",
    display: "flex",
    alignItems: "center",
    gap: "0.5rem",
    "& :first-of-type path": {
      fill: "#fff",
    },
  },
};
