import { Search } from "@mui/icons-material";
import {
  FilledInput,
  FormControl,
  InputAdornment,
  useTheme,
} from "@mui/material";
import React, { useCallback, useEffect, useRef } from "react";
import { TableInstance } from "react-table";
import { css } from "SRC/pages/Ministry/common/MinistriesTabsSearch/MinistriesTabsSearch.styled";

import { inputStyled, SearchContainer } from "./MinistriesTabsSearch.styled";

interface IProps {
  value?: string;
  onChange: (value?: string) => void;
}

export const MinistriesTabsSearch = ({ value, onChange }: IProps) => {
  const handleOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange(event.target.value);
    },
    [onChange]
  );

  return (
    <SearchContainer>
      <FormControl sx={css.uiSearch} variant="filled">
        <FilledInput
          key={(value || "").length}
          startAdornment={
            <InputAdornment position="start">
              <Search sx={css.inputSearch} />
            </InputAdornment>
          }
          sx={inputStyled.filledInput}
          placeholder="Поиск"
          value={value}
          autoFocus={Boolean(value)}
          onChange={handleOnChange}
        />
      </FormControl>
    </SearchContainer>
  );
};
