import { olapDimensionRequestData } from "CORE/api/core";
import { IPeriod } from "SRC/redux/slices/global/slices/filters";

export const GET_PROGRAMS_MAIN = "get-programs-main";

const getProgramsMainRequest = (period: IPeriod["filterValue"]) => ({
  requestId: "cd9f0aca-4c83-415d-863a-c14bccc1de94",
  type: "data2",
  params: {
    modelUuid: "2d1cc9ce-87da-40b3-80b6-37679e0357fd",
    dimensions: [],
    indicators: {},
    section_name: "1.0.9 ГосПрограммы",
    filters: {
      period_code: period,
      show_finals: true,
    },
  },
});

export const getProgramsMain = (period: IPeriod) => {
  return olapDimensionRequestData(
    [getProgramsMainRequest(period.filterValue)],
    GET_PROGRAMS_MAIN
  );
};
