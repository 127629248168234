import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getStructureElementsIndicators } from "SRC/redux/slices/gosprogram/api/structure-elements";
import { TStateDefault, TStructureElementsIndicatorsRequest } from "SRC/types";

export interface IStructureElementsIndicatorsState extends TStateDefault {
  items: Record<any, any>;
}

export const extraActions = {
  get: createAsyncThunk(
    "structureElements/indicators/get",
    async (params: TStructureElementsIndicatorsRequest) => {
      return await getStructureElementsIndicators(params);
    }
  ),
};

const initialState: IStructureElementsIndicatorsState = {
  items: {},
  fetching: false,
  fetched: false,
  error: null,
};

const slice = createSlice({
  name: "structureElements/indicators",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActions.get.pending, (state) => {
        state.fetching = true;
        state.fetched = false;
      })
      .addCase(extraActions.get.fulfilled, (state, action) => {
        state.items = action.payload.data;
        state.fetching = false;
        state.fetched = true;
      })
      .addCase(extraActions.get.rejected, (state, action) => {
        state.error = action.error;
        state.fetching = false;
        state.fetched = true;
      });
  },
});

export const actions = {
  ...slice.actions,
  ...extraActions,
};

export default slice;
