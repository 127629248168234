import { useTheme } from "@mui/material";
import { useMemo } from "react";
import { ExtendedTabs, ITab } from "SRC/components/ExtendedTabs/ExtendedTabs";
import { StructureElementsTab } from "SRC/pages/Program/common/StructureCard/StructureElementsTab/StructureElementsTab";
import { useStructureElements } from "SRC/redux/slices/gosprogram/hooks/structureElements";
import themeConfig from "SRC/theme";
import { IStructureElement } from "SRC/types";

import { getStructureColumns } from "../util/columns";

interface IStructureTabs {
  activeTab: number;
  onChange: (value: number | ((prevVar: number) => number)) => void;
  // setStructureElementsCode: Dispatch<SetStateAction<string>>;
}

export const StructureTabs = ({ activeTab, onChange }: IStructureTabs) => {
  const theme = useTheme<typeof themeConfig>();
  const structureTableColumns = useMemo(() => getStructureColumns(), []);
  const { items: structures } = useStructureElements();

  const dataFederal: IStructureElement[] = useMemo(() => {
    return structures.filter((item) => item["Признак, ФП"] === 1);
  }, [structures]);
  const dataDepartment: IStructureElement[] = useMemo(() => {
    return structures.filter((item) => item["Признак, ВП"] === 1);
  }, [structures]);
  const dataComplex: IStructureElement[] = useMemo(() => {
    return structures.filter((item) => item["Признак, КПМ"] === 1);
  }, [structures]);

  const tabs: ITab<number>[] = [
    {
      value: 0,
      label: "Все",
      numberOfItems: structures.length,
      component: (
        <StructureElementsTab
          // @ts-ignore
          columns={structureTableColumns}
          dataStructure={structures}
        />
      ),
    },
    {
      value: 1,
      label: "Федеральные проекты",
      numberOfItems: dataFederal.length,
      borderColor: theme.palette.labelTab.violet,
      component: (
        <StructureElementsTab
          // @ts-ignore
          columns={structureTableColumns}
          dataStructure={dataFederal}
        />
      ),
    },
    {
      value: 2,
      label: "Ведомственные проекты",
      numberOfItems: dataDepartment.length,
      borderColor: theme.palette.labelTab.blue,
      component: (
        <StructureElementsTab
          // @ts-ignore
          columns={structureTableColumns}
          dataStructure={dataDepartment}
        />
      ),
    },
    {
      value: 3,
      label: "Комплексы процессных мероприятий",
      numberOfItems: dataComplex.length,
      borderColor: theme.palette.labelTab.pink,
      component: (
        <StructureElementsTab
          // @ts-ignore
          columns={structureTableColumns}
          dataStructure={dataComplex}
        />
      ),
    },
  ];

  const onChangeTab = (value: number): void => onChange(value);

  return (
    <ExtendedTabs
      value={activeTab}
      onChange={onChangeTab}
      tabs={tabs}
      borderStyle="solid"
    />
  );
};
