import { actions as programs } from "SRC/redux/list/programs";
import { actions as mainPrograms } from "SRC/redux/list/programs-main";

import { actions as goals } from "./slices/goals";
import { actions as mainPremiers } from "./slices/mainPremiers";
import { actions as ministries } from "./slices/ministries";
import { actions as premiers } from "./slices/premiers";
import { actions as spheres } from "./slices/spheres";
import { actions2 as sumSpheres } from "./slices/spheres";
import { actions as stats } from "./slices/stats";

export interface IMainActions {
  mainPrograms: typeof mainPrograms;
  ministries: typeof ministries;
  programs: typeof programs;
  goals: typeof goals;
  premiers: typeof premiers;
  mainPremiers: typeof mainPremiers;
  stats: typeof stats;
  spheres: typeof spheres;
  sumSpheres: typeof sumSpheres;
}

const actions: IMainActions = {
  mainPrograms,
  ministries,
  programs,
  goals,
  premiers,
  mainPremiers,
  stats,
  spheres,
  sumSpheres,
};

export default actions;
