import { Box } from "@mui/material";
import { DownArrow } from "ASSETS/svg/arrow";
import React from "react";
import { UseExpandedRowProps, UseTableRowProps } from "react-table";

import { css } from "../../Table.styled";
import { buttonCss } from "./ExpanderCell.styles";

interface IExpanderCell<T extends object> {
  row: UseExpandedRowProps<T>;
}

export const ExpanderCell = <T extends object>({ row }: IExpanderCell<T>) => {
  return row.canExpand ? (
    <Box
      sx={[css.contained, css.centered]}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <Box
        {...row.getToggleRowExpandedProps({
          style: {
            transform: `rotate(${row.isExpanded ? "180deg" : "0"})`,
          },
        })}
        sx={[css.centered, buttonCss]}
      >
        <DownArrow />
      </Box>
    </Box>
  ) : null;
};

export const expandedColumn = {
  Header: "",
  width: 56,
  sticky: "left",
  accessor: "expander",
  Cell: ExpanderCell,
  Footer: "",
};

export const expandedGroupingColumn = {
  Header: "",
  sticky: "left",
  accessor: "expander",
  columns: [expandedColumn],
  Footer: "",
};

export const nestingTest = <T extends object>(row: UseTableRowProps<T>) =>
  Boolean(String(row.id).includes("."));
