import { useTheme } from "@mui/material";
import ReactECharts from "echarts-for-react";
import React, { useMemo } from "react";
import { getRoundValue } from "SRC/helpers/getRoundValue";
import themeConfig from "SRC/theme";

import { getOption } from "./options";
import { Wrapper } from "./ProgressDoughnutFinancing.styled";

interface IProps {
  total: number | string;
  value: number | string;
  chartSmall?: boolean;
  withDarkBgr?: boolean | undefined;
  children?: React.ReactNode;
  forAchievements?: boolean | undefined;
  title?: React.ReactNode;
}

const ProgressDoughnutFinancing = ({
  total,
  value,
  withDarkBgr,
  children,
  chartSmall,
  forAchievements,
  title,
}: IProps) => {
  const theme = useTheme<typeof themeConfig>();

  const option = useMemo(
    () =>
      getOption(value, total, withDarkBgr, chartSmall, theme, forAchievements),
    [theme, withDarkBgr, value, total, chartSmall, forAchievements]
  );
  const budgetTitle = (
    <>
      Бюджет, <br /> план
    </>
  );

  return (
    <Wrapper withDarkBgr={withDarkBgr}>
      <div className="textContainer">
        <div className="symbol">{forAchievements ? "%" : "млрд ₽"}</div>
        <div className="text">{getRoundValue(total || 0, 1)}</div>
        <div className="symbol">{title || budgetTitle}</div>
        {children}
      </div>
      <ReactECharts option={option} style={{ width: "100%", height: "100%" }} />
    </Wrapper>
  );
};

export default ProgressDoughnutFinancing;
