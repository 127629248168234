import { styled } from "@mui/material";

export const Wrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: "0.5rem 0 0 0.5rem",

  button: {
    background: theme.palette.complementary.darkGrayBlue,
    color: theme.palette.primary.contrastText,
    fontSize: "1rem",
    width: "29rem",
    height: "2.7rem",
    minWidth: "auto",
    textTransform: "capitalize",
    borderRadius: "0.5rem",
    padding: "0.5rem 1rem",
    marginBottom: "1rem",

    "&:not(:disabled)": {
      background: theme.palette.main.blue,
    },

    "&:disabled": {
      position: "relative",

      "&::after": {
        position: "absolute",
        top: "3px",
        right: "2px",
        padding: "0 0.5em",
        fontSize: "0.75rem",
        color: theme.palette.background?.default,
        backgroundColor: theme.palette.pale?.yellow,
        borderRadius: "0.25em",
        whiteSpace: "nowrap",
        content: "'В РАБОТЕ'",
      },
    },
  },
}));
