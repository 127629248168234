import React from "react";
import { CheckSecretData } from "SRC/components/CheckSecretData";
import { NavigateCell, nestingTest } from "SRC/components/Table";
import { TableCell } from "SRC/components/TableCell";
import {
  getEventsRisk,
  getEventsRiskTotal,
} from "SRC/components/TableColumnTemplates/columns/events/utils";
import { sortEventsRiskColumn } from "SRC/helpers";
import { ICustomTableProps, PROGRAM_TABS } from "SRC/types";
import { createProgramCardUrl, PROGRAM_VIEW_TAB } from "SRC/urls";

import { Value } from "./components/Value";

export const eventsRisk = (isOM: boolean) => ({
  Header: "Под риском",
  sortType: sortEventsRiskColumn(isOM),
  accessor: isOM ? "eventsOMRisk" : "eventsRisk",
  Cell: (tableProps: ICustomTableProps) => {
    const { eventsRisk, isSecret } = getEventsRisk(tableProps, isOM);
    if (nestingTest(tableProps.row)) {
      return (
        <NavigateCell
          url={createProgramCardUrl(
            PROGRAM_VIEW_TAB,
            tableProps.row.original.id,
            PROGRAM_TABS.EVENTS,
            ""
          )}
        >
          <TableCell color={isOM ? "paleRed" : "red"}>
            <CheckSecretData isSecret={isSecret}>
              <Value value={eventsRisk} />
            </CheckSecretData>
          </TableCell>
        </NavigateCell>
      );
    } else {
      return (
        <TableCell color={isOM ? "paleRed" : "red"}>
          <CheckSecretData isSecret={isSecret}>
            <Value value={eventsRisk} />
          </CheckSecretData>
        </TableCell>
      );
    }
  },
  Footer: (tableProps: ICustomTableProps) => {
    const { eventsRiskTotal } = getEventsRiskTotal(tableProps, isOM);

    return (
      <TableCell color={isOM ? "paleRed" : "red"}>{eventsRiskTotal}</TableCell>
    );
  },
});
