import { Theme } from "@mui/material";

export const css = {
  programListContainer: {
    maxHeight: "16.5rem",
    overflowY: "scroll",
    margin: "0 -1rem -1rem -1rem",
    height: "100%",
  },
  titleRightContainer: {
    display: "flex",
    flex: "1 1 auto",
    gap: "1rem",
    justifyContent: "flex-end",
  },
  titleRight: {
    display: "flex",
    flex: "1 1 auto",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "0.25rem",

    ".date": {
      flex: "1",
      fontSize: "1rem",
      color: (theme: Theme) => theme.palette.text.primary,
    },

    ".title": {
      flex: "0",
      fontSize: "1rem",
    },
  },
  title: {
    fontSize: "1.25rem",
    display: "flex",
    alignItems: "end",
  },
  unit: {
    fontSize: "1rem",
    color: (theme: Theme) => theme.palette.text.primary,
    display: "flex",
    alignItems: "end",
    mb: "0.1rem",
  },
  detailBtn: (isActiveButton: boolean | undefined) => ({
    display: "flex",
    background: (theme: Theme) => theme.palette.background.buttonDarkBlue,
    border: "1px solid rgba(0, 0, 0, 0.1)",
    padding: "0.35rem 0.6rem",
    borderRadius: "0.2rem",
    cursor: "pointer",
    "& svg": {
      width: "0.5rem",
      height: "0.875rem",
    },
    pointerEvents: !isActiveButton ? "none" : "",
  }),
};
