import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { isContainedArray } from "SRC/helpers/dataTesters";
import { ITopMinistries } from "SRC/pages/Summary/interfaces";
import { IPeriod } from "SRC/redux/slices/global/slices/filters";

import { getTopWorstMinistriesOM } from "../api/getTopWorstMinistriesOM";

export interface IMinistriesTopWorstOMState {
  items: ITopMinistries[];
  fetching: boolean;
  fetched: boolean;
  error: string;
}

export const extraActions = {
  get: createAsyncThunk(
    "topWorstMinistriesOM/get",
    async (period: IPeriod) => await getTopWorstMinistriesOM(period)
  ),
};

const initialState: IMinistriesTopWorstOMState = {
  items: [],
  fetching: false,
  fetched: false,
  error: "",
};

const slice = createSlice({
  name: "topWorstMinistriesOM",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActions.get.pending, (state) => {
        state.items = [];
        state.fetching = true;
        state.fetched = false;
      })
      .addCase(
        extraActions.get.fulfilled,
        (state: IMinistriesTopWorstOMState, action) => {
          const data = action.payload.data?.[0]?.data;
          state.items = isContainedArray(action.payload.data) ? data : [];
          state.fetching = false;
          state.fetched = true;
        }
      )
      .addCase(extraActions.get.rejected, (state, action) => {
        state.fetching = false;
      });
  },
});

export const actions = {
  ...slice.actions,
  ...extraActions,
};

export default slice;
