import { Box, SxProps } from "@mui/material";
import React, { CSSProperties, FC } from "react";
import { getPercentValue } from "SRC/helpers/getPercentValue";

import { css } from "./index.styled";

interface IProps {
  value: number;
  progressColor?: string;
  style?: CSSProperties | SxProps;
  circleLabel?: number | string;
  percentLabel?: string | number;
  bottomLeftLabel?: number | React.ReactNode;
  bottomRightLabel?: number | React.ReactNode;
  bottomLabelColorRight?: string;
  bottomLabelColorLeft?: string;
  withLabel?: boolean;
  isDarkBg?: boolean;
  percentOnCircle?: boolean;
  numOfDigits?: number;
  isOM?: boolean;
}

export const SlimProgressBar: FC<IProps> = ({
  value = 0,
  progressColor,
  style,
  circleLabel,
  bottomLeftLabel,
  bottomRightLabel,
  bottomLabelColorRight,
  bottomLabelColorLeft,
  withLabel,
  isDarkBg,
  percentOnCircle = true,
  numOfDigits = 2,
  isOM,
}) => {
  const val = value > 100 ? 100 : value;

  return (
    <Box sx={css.progressBar}>
      <Box
        sx={
          {
            ...css.uiProgressBarStyle(
              val,
              progressColor,
              circleLabel,
              isDarkBg
            ),
            ...style,
          } as SxProps
        }
      >
        <Box sx={css.progressLine(val, progressColor, isOM) as SxProps}>
          {withLabel && (
            <Box
              sx={
                css.circleLabel(Number(circleLabel), progressColor) as SxProps
              }
            >
              {percentOnCircle && (
                <Box sx={css.percentLabel as SxProps}>
                  {getPercentValue(val, numOfDigits)}
                  <span>%</span>
                </Box>
              )}
              {circleLabel}
            </Box>
          )}
        </Box>
      </Box>
      {(bottomLeftLabel || bottomRightLabel) && (
        <Box sx={css.bottomLabelContainer}>
          <Box sx={css.bottomLabel(bottomLabelColorLeft)}>
            {bottomLeftLabel}
          </Box>
          <Box sx={css.bottomLabel(bottomLabelColorRight)}>
            {bottomRightLabel}
          </Box>
        </Box>
      )}
    </Box>
  );
};
