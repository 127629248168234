import { useMemo } from "react";
import {
  IFilters,
  useFilters,
  useStructureElementsTasks,
} from "SRC/redux/slices/gosprogram/hooks";

import {
  createSeTasksTableData,
  ISETaskTableElement,
  structureElementTypes,
  structureElementTypesMap,
} from "../utils";

type TSETasksTableData = {
  tasksTableData: ISETaskTableElement[];
  uniqueStructureNames: string[];
  uniqueTypeProjects: string[];
  uniqueTaskOptions: string[];
};

export const useSETasksTableData = (): TSETasksTableData => {
  const { items } = useStructureElementsTasks();
  const {
    selectedStructureElementType,
    selectedStructureElementName,
    selectedStructureElementTask,
  }: IFilters = useFilters();

  // Исходные данные преобразованные в табличный вид
  const tableData = useMemo(() => createSeTasksTableData(items), [items]);

  // Применение фильтров к преобразованным данным
  const tasksTableData: ISETaskTableElement[] = useMemo(
    () =>
      tableData.filter((row) => {
        const structureElementTypeFilter =
          !selectedStructureElementType ||
          row.se_type === structureElementTypes[selectedStructureElementType];
        const structureNameFilter =
          !selectedStructureElementName ||
          row.se_name === selectedStructureElementName;
        return structureElementTypeFilter && structureNameFilter;
      }),
    [selectedStructureElementType, selectedStructureElementName, tableData]
  );

  const uniqueTaskOptions = useMemo(() => {
    return Array.from(new Set(tasksTableData.map((item) => item.name)));
  }, [tasksTableData]);

  //уникальный список Типов структурных элементов (ФП ВП КПМ)
  const uniqueTypeProjects = useMemo(() => {
    return Array.from(
      new Set(
        tableData
          .filter((item) => {
            const structureElementNameFilter =
              !selectedStructureElementName ||
              selectedStructureElementName === item.se_name;
            const structureNameFilter =
              !selectedStructureElementTask ||
              item.name === selectedStructureElementTask;
            return structureElementNameFilter && structureNameFilter;
          })
          .map((item) => {
            return structureElementTypesMap[item.se_type];
          })
      )
    );
  }, [tableData, selectedStructureElementName, selectedStructureElementTask]);

  const uniqueStructureNames = useMemo(() => {
    return Array.from(
      new Set(
        tableData
          .filter((item) => {
            const structureElementTypeFilter =
              !selectedStructureElementType ||
              item.se_type ===
                structureElementTypes[selectedStructureElementType];
            const structureNameFilter =
              !selectedStructureElementTask ||
              item.name === selectedStructureElementTask;
            return structureElementTypeFilter && structureNameFilter;
          })
          .map((item) => {
            return item.se_name;
          })
      )
    );
  }, [tableData, selectedStructureElementType, selectedStructureElementTask]);
  return {
    tasksTableData,
    uniqueTypeProjects,
    uniqueStructureNames,
    uniqueTaskOptions,
  };
};
