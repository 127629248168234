import { styled } from "@mui/material";

export const Wrapper = styled("div")(({ theme }) => ({
  display: "flex",
  gap: "1rem",
  marginTop: "1rem",
  marginBottom: "1.5rem",
  ".widget": {
    display: "flex",
    flexDirection: "column",
    height: "15rem",
    gap: "1rem",
    padding: "1rem 1.5rem",
    borderRadius: "0.8rem",
    background: theme.palette.complementary.darkBlueLight,
  },
  ".count": {
    minWidth: "19rem",
  },
  ".indicators": {
    gap: "1rem",
    width: "100%",
  },
  ".events": {
    width: "100%",
  },
  ".instances": {
    display: "flex",
    justifyContent: "space-between",
    padding: "0.5rem 0rem",
    div: {
      width: "100%",
    },
  },
  ".progress-lines": {
    display: "flex",
    gap: "1rem",
    width: "100%",
  },
  ".progress-lines > div": {
    flex: 1,
  },
}));
