import { Box, Paper } from "@mui/material";
import React from "react";
import { RatingTab } from "SRC/pages/Summary/common/Rating/RatingTab/RatingTab";
import { css } from "SRC/pages/Summary/layouts/right-layout/right-layout.styled";

export const RightLayout = () => {
  return (
    <Box sx={css.rightLayout}>
      <Box sx={css.tableHeaderWrapper}>
        <Paper sx={css.tableTitle} component="h1" elevation={0}>
          Рейтинг
        </Paper>
      </Box>
      <Box sx={css.divider} />
      <Box sx={css.ratingTabs}>
        //TODO вернуть как будет дизайн на 8к
        {/*<RatingTab withDarkBgr={true} typeTop={"best"} />*/}
        {/*<RatingTab withDarkBgr={true} typeTop={"worst"} />*/}
      </Box>
    </Box>
  );
};
