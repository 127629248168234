import client from "CORE/api/client";
import { UserGroup, UserInfo } from "SRC/types";

export const getUserGroups = async (): Promise<Set<string>> => {
  const { data: userInfo } = await client.get<UserInfo>(
    "api-gasu/planeta/userInfo"
  );
  const { data: userGroups } = await client.get<UserGroup[]>(
    `api-gasu/public/users/${userInfo.userId}/groups`
  );

  return new Set(userGroups.map(({ actualCode }) => actualCode));
};
