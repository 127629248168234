import { createSlice } from "@reduxjs/toolkit";
import { EGridTabs } from "SRC/redux/slices/summary/slices/filters";

export interface IFiltersState {
  program: string;
  vp: string;
  goal: string;
  foiv: string;
  ministry: string;
  sphere: string;
  isSphereSecret: boolean;
  viewChart: number;
  centerTab: string;
  complexGridTab: EGridTabs;
  typeProgram: string;
  selectedSearchRows: Record<string, any>[];
}

const initialState: IFiltersState = {
  program: "",
  vp: "",
  goal: "",
  foiv: "",
  ministry: "",
  sphere: "",
  isSphereSecret: false,
  viewChart: 1,
  centerTab: "analytics",
  complexGridTab: EGridTabs.PROGRAMS,
  typeProgram: "",
  selectedSearchRows: [],
};

const slice = createSlice({
  name: "filtersMain",
  initialState,
  reducers: {
    clear: () => initialState,
    selectProgram: (state, action: { payload: string }) => {
      state.program = action.payload;
    },
    selectVP: (state, action: { payload: string }) => {
      state.vp = action.payload;
    },
    selectMinistry: (state, action: { payload: string }) => {
      state.ministry = action.payload;
    },
    selectSphere: (state, action: { payload: string }) => {
      state.sphere = action.payload;
    },
    setSphereSecret: (state, action: { payload: boolean }) => {
      state.isSphereSecret = action.payload;
    },
    selectGoal: (state, action: { payload: string }) => {
      state.goal = action.payload;
    },
    selectFoiv: (state, action: { payload: string }) => {
      state.foiv = action.payload;
    },
    selectTypeProgram: (state, action: { payload: string }) => {
      state.typeProgram = action.payload;
    },
    toggleChart: (state, action: { payload: number }) => {
      state.viewChart = action.payload;
    },
    setCenterTab: (state, action: { payload: string }) => {
      state.centerTab = action.payload;
    },
    setComplexGridTab: (state, action: { payload: EGridTabs }) => {
      state.complexGridTab = action.payload;
    },
    selectSearchRows: (
      state,
      action: {
        payload: Record<string, any>[];
      }
    ) => {
      state.selectedSearchRows = action.payload;
    },
  },
});

export default slice;
