import { Box, useMediaQuery, useTheme } from "@mui/material";
import React, { Dispatch, SetStateAction, useCallback, useMemo } from "react";
import { Column, TableInstance, useTable } from "react-table";
import { TableModal } from "SRC/components/FullPageTableModal/FullPageTableModal";
import { OMSwitcher } from "SRC/components/OMSwitcher";
import { SecretSwitcher } from "SRC/components/SecretSwitcher";
import Table from "SRC/components/Table";
import { TableActionsButtons } from "SRC/components/TableActionsButtons/TableActionsButtons";
import { useTableData } from "SRC/hooks/summaryTable/useSummaryMinistries";
import {
  createMinistryRowsTableData,
  createSortedMinistriesData,
} from "SRC/hooks/summaryTable/utils/mainData/mainDataMinistries";
import { css } from "SRC/pages/Main/common/ComplexGrid/ComplexGrid.styled";
import { getMinistriesColumns } from "SRC/pages/Main/common/ComplexGrid/MinistriesTab/tableData";
import {
  IGlobalFilters,
  useGlobalFilters,
} from "SRC/redux/slices/global/hooks/useFilters";
import { IFilters, useFilters } from "SRC/redux/slices/main/hooks/useFilters";
import { useMinistries } from "SRC/redux/slices/main/hooks/useMinistries";
import { CustomRowProps } from "SRC/types/data/data";
import { performanceColumn } from "SRC/types/ministry/ministryTab";

import { MinistriesFilters } from "../filterComponents/MinistriesFilters";

interface IMinistriesTabProps {
  isModalOpen?: boolean;
  setModalOpen?: (value: boolean) => void;
  instance: TableInstance | undefined;
  setInstance: Dispatch<SetStateAction<TableInstance<{}> | undefined>>;
}

const sortedColumns = [
  "Министерства",
  "План",
  "Факт",
  "Под риском",
  "Уровень достижения",
];

const RenderTable: React.FC<IMinistriesTabProps> = ({
  isModalOpen,
  setModalOpen,
  instance,
  setInstance,
}) => {
  const {
    ministry,
    setSelectedMinistry,
    setSearchRows,
    setSelectedProgram,
    setSelectedVP,
  }: IFilters = useFilters();
  const { fetching } = useMinistries();
  const {
    ministriesMainData: ministriesData,
    ministriesMainExport: ministriesExport,
  } = useTableData();
  const { sources, withSecret }: IGlobalFilters = useGlobalFilters();

  const columns: Column[] = useMemo(
    () => getMinistriesColumns(sources.OM, withSecret),
    [sources.OM, withSecret]
  );

  const onChangeFilterRows = useCallback(
    (rows: CustomRowProps[]) => {
      setSearchRows(createMinistryRowsTableData(rows, sources.OM));
    },
    [sources.OM]
  );

  const onSelect = useCallback(
    (id: string | number, nested: boolean) => {
      if (nested) {
        setSelectedProgram(String(id));
        setSelectedVP("");
      } else {
        setSelectedMinistry(String(id));
      }
    },
    [setSelectedMinistry, setSelectedProgram, setSelectedVP]
  );

  const { sortedData, sortColumn } = useMemo(() => {
    return {
      sortedData: createSortedMinistriesData(sources.OM, ministriesData),
      sortColumn: sources.OM
        ? performanceColumn.ACHIEVE_OM
        : performanceColumn.ACHIEVE_FOIV,
    };
  }, [sources.OM, ministriesData]);

  const { initialState } = useTable({
    columns,
    data: sortedData,
    initialState: {
      sortBy: [
        {
          id: sortColumn,
          desc: true,
        },
      ],
    },
  });

  return (
    <Box sx={css.tabContainer}>
      {isModalOpen && (
        <Box sx={css.actionsContainer}>
          <MinistriesFilters instance={instance} />
          <Box sx={css.leftBlock}>
            <OMSwitcher />
            <SecretSwitcher />
            <TableActionsButtons
              open={isModalOpen}
              setOpen={setModalOpen}
              {...ministriesExport}
            />
          </Box>
        </Box>
      )}
      <Table
        loading={fetching}
        columns={columns}
        data={sortedData}
        sortedColumns={sortedColumns}
        selectedRow={ministry}
        setSelectedRow={onSelect}
        noDataCondition={sortedData?.length === 0}
        getInstance={setInstance}
        initialState={initialState}
        onFilterRows={onChangeFilterRows}
        expandable
        classes={{
          thead: "table-header",
          tbody: "table-body",
          tfoot: "table-footer",
        }}
      />
    </Box>
  );
};

export const MinistriesTab: React.FC<IMinistriesTabProps> = (props) => {
  const { isModalOpen, setModalOpen } = props;
  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <>
      <RenderTable {...props} key={Number(isModalOpen)} />
      {!match && (
        <TableModal
          open={isModalOpen}
          setOpen={setModalOpen}
          tableName="Министерства"
        >
          <RenderTable {...props} />
        </TableModal>
      )}
    </>
  );
};
