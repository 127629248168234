import { Search } from "@mui/icons-material";
import {
  FilledInput,
  FormControl,
  Grid,
  InputAdornment,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useCallback, useEffect, useRef } from "react";
import { TableInstance } from "react-table";
import { css } from "SRC/pages/Main/common/ComplexGrid/ComplexGrid.styled";

interface IMinistriesFiltersProps {
  instance: TableInstance | undefined;
}

export const MinistriesFilters: React.FC<IMinistriesFiltersProps> = ({
  instance,
}) => {
  const inputRef = useRef<HTMLInputElement>();
  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.up("lg"));

  useEffect(() => {
    if (instance && inputRef?.current) {
      inputRef.current.value = instance.state.globalFilter;
    }
  }, [instance?.state, inputRef]);

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      instance?.setGlobalFilter(event.target.value);
    },
    [instance]
  );

  return (
    <Grid
      container
      sx={{
        marginBottom: match ? "1rem" : "0rem",
        alignSelf: "end",
        maxWidth: match ? "100%" : "50%",
      }}
    >
      <Grid item sm={7} lg={4} sx={{ textAlign: "left" }}>
        <FormControl
          sx={css.uiSearch}
          variant="filled"
          style={{ width: "100%", maxWidth: "100%" }}
        >
          <FilledInput
            startAdornment={
              <InputAdornment
                position="start"
                placeholder="Поиск министерства.."
              >
                <Search
                  sx={{
                    color: theme.palette.main.gray,
                    width: "1.25rem",
                    height: "1.25rem",
                    ml: "10px",
                  }}
                />
              </InputAdornment>
            }
            placeholder="Поиск министерства"
            style={{ fontSize: "1rem" }}
            ref={inputRef}
            onChange={onChange}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};
