import { styled } from "@mui/material";

export const Wrapper = styled("div")(() => {
  return {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "1.5rem",

    ".icon": {
      flex: "0 0 2.5rem",
      aspectRatio: "1",
      backgroundPosition: "center",
      backgroundSize: "cover",
      borderRadius: "50%",
    },
  };
});
