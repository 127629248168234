import React, { useContext, useMemo } from "react";
import { IEvents } from "SRC/redux/slices/structureElement/hooks/useEvents";
import { useFilters } from "SRC/redux/slices/structureElement/hooks/useFilters";
import {
  ISEDatas,
  useSEData,
} from "SRC/redux/slices/structureElement/hooks/useSEData";
import { ITasks } from "SRC/redux/slices/structureElement/hooks/useTasks";

import { IUseExtendedEvents, useExtendedEvents } from "./useExtendedEvents";
import {
  IUseExtendedIndicators,
  useExtendedIndicators,
} from "./useExtendedIndicators";
import { IUseExtendedTasks, useExtendedTasks } from "./useExtendedTasks";

type TExportsData = IUseExtendedTasks["exports"];

interface IStatus {
  hasError: boolean;
  loading: boolean;
}

export interface IController {
  data: ISEDatas;
  tasks: IUseExtendedTasks;
  events: IUseExtendedEvents;
  indicators: IUseExtendedIndicators;
  status: IStatus;
}

const ControllerApi = React.createContext<IController>({
  // @ts-ignore
  programs: {},
  // @ts-ignore
  tasks: {},
  // @ts-ignore
  events: {},
  // @ts-ignore
  indicators: {},
  status: {
    loading: false,
    hasError: false,
  },
});

export const useGetData = (): ISEDatas => useContext(ControllerApi).data;
export const useGetTasks = (): IUseExtendedTasks =>
  useContext(ControllerApi).tasks;
export const useGetEvents = (): IUseExtendedEvents =>
  useContext(ControllerApi).events;
export const useGetIndicators = (): IUseExtendedIndicators =>
  useContext(ControllerApi).indicators;

interface IControllerProps {
  id: string | number;
  pId: string;
  children?: React.ReactNode;
}

const Controller = ({ id, pId, children }: IControllerProps) => {
  const data = useSEData(id, pId);
  const { fetching: loadingData, error: errorData } = data;
  const tasks: IUseExtendedTasks = useExtendedTasks(id);
  const { fetching: loadingTasks, error: errorTasks } = tasks;
  const events: IUseExtendedEvents = useExtendedEvents(id);
  const { fetching: loadingEvents, error: errorEvents } = events;
  const indicators: IUseExtendedIndicators = useExtendedIndicators(id);
  const { fetching: loadingIndicators, error: errorIndicators } = indicators;

  const status = useMemo(
    () => ({
      loading:
        loadingTasks || loadingData || loadingEvents || loadingIndicators,
      hasError: errorTasks || errorData || errorEvents || errorIndicators,
    }),
    [
      loadingTasks,
      loadingData,
      errorTasks,
      errorData,
      loadingEvents,
      errorEvents,
      loadingIndicators,
      errorIndicators,
    ]
  );

  const context = useMemo(
    () => ({
      indicators,
      tasks,
      events,
      data,
      status,
    }),
    [tasks, data, status, events, indicators]
  );

  return (
    <ControllerApi.Provider value={context}>{children}</ControllerApi.Provider>
  );
};

export default Controller;

export type { IEvents, ISEDatas, ITasks, TExportsData };
export { useFilters };
