import { Box } from "@mui/material";
import React from "react";
import { TableProps, UseTableRowProps } from "react-table";
import { css } from "SRC/components/Table/BaseTable/BaseTable.styled";
import { TableCell } from "SRC/components/TableCell";

interface CustomTableProps extends TableProps {
  row: UseTableRowProps<any>;
  rows?: UseTableRowProps<any>;
}

const relatedIndicatorsColumns = [
  {
    Header: "Название показателя",
    accessor: "indicatorName",
    width: "30%",
    Cell: (tableProps: CustomTableProps) => (
      <div style={{ position: "relative", padding: "0 20px" }}>
        <Box
          sx={css.tableCellText()}
          className="with-right-border withMarginLeft"
        >
          <TableCell>{tableProps.row.original.indicatorName}</TableCell>
        </Box>
      </div>
    ),
  },
  {
    Header: "Уровень достижения, %",
    width: "30%",
    accessor: "performance",
    Cell: (tableProps: CustomTableProps) => (
      <Box
        sx={{
          display: "flex",
          alignItems: "baseline",
          gap: "0.7rem",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: "0.8rem",
            height: "0.8rem",
            borderRadius: "50%",
            background:
              tableProps.row.original.performance < 100 ? "#cc0c31" : "#4cd961",
          }}
        />
        <TableCell align="center">
          {tableProps.row.original.performance}
        </TableCell>
      </Box>
    ),
  },
  {
    Header: "Степень влияния, %",
    accessor: "effect",
    width: "30%",
    Cell: (tableProps: CustomTableProps) => (
      <TableCell align="center">{tableProps.row.original.effect}</TableCell>
    ),
  },
];

export { relatedIndicatorsColumns };
