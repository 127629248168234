import React from "react";
import { OverlayBlock } from "SRC/components/OverlayBlock";
import { IUser } from "SRC/redux/slices/auth/api/client";

type PageContentProps = {
  isLoading: boolean;
  user: IUser | undefined;
  children: JSX.Element;
};

export const PageContent = ({
  isLoading,
  user,
  children,
}: PageContentProps) => {
  if (isLoading || !user) {
    return (
      <OverlayBlock
        isFetching={isLoading}
        hasData={Boolean(user)}
        noData="Ошибка авторизации"
      />
    );
  }

  return <>{children}</>;
};
