import { SerializedError } from "@reduxjs/toolkit";
import { useCallback, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "SRC/redux/hooks";
import { TDataProgram } from "SRC/redux/list/programs-main";
import { IPeriod } from "SRC/redux/slices/global/slices/filters";
import actions from "SRC/redux/slices/main/actions";

export interface IMainPrograms {
  fetching: boolean;
  fetched: boolean;
  items: TDataProgram[];
  load(period: IPeriod): void;
  getItemByCode(code: string): TDataProgram | undefined;
  error: SerializedError | null;
}

export const useMainPrograms = (): IMainPrograms => {
  const dispatch = useAppDispatch();
  const {
    fetching,
    items = [],
    fetched,
  } = useAppSelector((state) => state.main.mainPrograms);
  const [error, setError] = useState<SerializedError | null>(null);

  const programs: TDataProgram[] = useMemo(
    () => [...items].sort((p1, p2) => Number(p1.gp_code) - Number(p2.gp_code)),
    [items]
  );

  const load = useCallback(
    (params: IPeriod) => {
      if (!fetching) {
        dispatch(actions.mainPrograms.get(params)).then((action) => {
          if (actions.mainPrograms.get.rejected.match(action)) {
            setError(action.error);
          }
          return action;
        });
      }
    },
    [dispatch, fetching]
  );

  const getItemByCode = useCallback(
    (code: string): TDataProgram | undefined =>
      programs.find(({ gp_code }) => gp_code === code),
    [programs]
  );

  return {
    fetching,
    fetched,
    items: programs,
    getItemByCode,
    load,
    error,
  };
};
