import { styled } from "@mui/material";

export const Wrapper = styled("div")(({ theme }) => {
  return {
    width: "100%",

    ".incident-container": {
      width: "100%",

      "& > .cash-tab-wrapper": {
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        padding: "1rem 2rem",
        minHeight: "100%",
        flex: 1,
        gap: "1rem",

        "& > .cash-tab-container": {
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flex: "0 0 auto",

          "& > .legend-container": {
            display: "flex",
            flex: "0 0 auto",
            gap: "2rem",
            alignItems: "center",
            fontSize: "1rem",
            color: theme.palette.main.gray,

            "& > .main-text": {
              fontSize: "1.25rem",
              lineHeight: "1.3rem",
              color: theme.palette.text.onAccent,
              whiteSpace: "nowrap",
              textAlign: "center",
            },

            "& > .legend-item-container": {
              display: "flex",
              gap: "0.5rem",
              alignItems: "baseline",

              "& p.white": {
                color: theme.palette.text.onAccent,
              },

              "&.center": {
                alignItems: "center",
              },

              "& > .legend-item": {
                width: "2rem",
                height: "0.3rem",
                borderRadius: "1px",

                "&.white": {
                  background: theme.palette.primary.contrastText,
                },

                "&.blue": {
                  background: theme.palette.main.blue,
                },
              },

              "& > .styled-point": {
                width: "0.7rem",
                height: "0.7rem",
                borderRadius: "50%",

                "&.green": {
                  background: theme.palette.success.main,
                },
                "&.red": {
                  background: theme.palette.error.main,
                },
                "&.gray": {
                  background: theme.palette.main.gray,
                },
                "&.dark": {
                  background: theme.palette.background.default,
                },
              },
            },
          },
        },
      },
    },
  };
});
