import { ICustomTableProps } from "SRC/types";

export const getEventsRiskTotal = (
  tableProps: ICustomTableProps,
  isOM: boolean
) => {
  let { eventsRiskTotal, eventsOMRiskTotal } =
    tableProps?.rows?.[0]?.original || {};

  return {
    eventsRiskTotal: isOM ? eventsOMRiskTotal : eventsRiskTotal,
  };
};
