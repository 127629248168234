import { createSlice } from "@reduxjs/toolkit";
import { TableState } from "react-table";

export interface IProgramFiltersState {
  filtersForGP: number | string;
  selectedStatus: string[];
  selectedStructureId: string;
  selectedStructureRow: string;
  selectedStructureCodes: string[];
  selectedEventFeature: string;
  selectedEventTypeName: string;
  selectedStructureElementType: string;
  selectedStructureElementName: string; // TODO для фильтра Наименование СЭ
  selectedStructureElementTask: string;
  tableState: Partial<TableState> | undefined;
  selectedSearchRows: Record<string, any>[];
}

const initialState: IProgramFiltersState = {
  filtersForGP: "",
  selectedStatus: [],
  selectedStructureRow: "",
  selectedStructureId: "",
  selectedStructureCodes: [],
  selectedEventFeature: "",
  selectedEventTypeName: "",
  selectedStructureElementType: "",
  selectedStructureElementName: "", // TODO для фильтра Наименование СЭ
  selectedStructureElementTask: "",
  tableState: undefined,
  selectedSearchRows: [],
};

const slice = createSlice({
  name: "programFilters",
  initialState,
  reducers: {
    clear: () => initialState,
    setFiltersForGP: (state, action: { payload: number | string }) => {
      state.filtersForGP = action.payload;
    },
    selectStatus: (state, action: { payload: string }) => {
      const actionStatus = action.payload;
      const selectedStatuses = [...state.selectedStatus];

      if (selectedStatuses.includes(actionStatus)) {
        state.selectedStatus = selectedStatuses.filter(
          (status) => status !== actionStatus
        );
      } else {
        state.selectedStatus = selectedStatuses.concat(actionStatus);
      }
    },
    clearStatus: (state) => {
      state.selectedStatus = [];
    },
    selectStructureRow: (state, action: { payload: string }) => {
      state.selectedStructureRow = action.payload;
    },
    selectStructureId: (state, action: { payload: string }) => {
      state.selectedStructureId = action.payload;
    },
    selectStructureCodes: (state, action: { payload: string[] }) => {
      state.selectedStructureCodes = action.payload;
    },
    selectEventFeature: (state, action: { payload: string }) => {
      state.selectedEventFeature = action.payload;
    },
    selectEventTypeName: (state, action: { payload: string }) => {
      state.selectedEventTypeName = action.payload;
    },
    selectStructureElementType: (state, action: { payload: string }) => {
      state.selectedStructureElementType = action.payload;
    },
    selectStructureElementName: (state, action: { payload: string }) => {
      state.selectedStructureElementName = action.payload;
    },
    selectStructureElementTask: (state, action: { payload: string }) => {
      state.selectedStructureElementTask = action.payload;
    },
    selectSearchRows: (state, action: { payload: Record<string, any>[] }) => {
      state.selectedSearchRows = action.payload;
    },
    setTableInstance: (
      state,
      action: { payload: Partial<TableState> | undefined }
    ) => {
      state.tableState = action.payload;
    },
  },
});

export default slice;
