import { oldApi } from "SRC/redux/api";
import { IPeriod } from "SRC/redux/slices/global/slices/filters";
import { getSummaryRTK } from "SRC/redux/slices/programsBoard/api/client";

const GET_SUMMARY = "get-summary";

export const gpbApi = oldApi.injectEndpoints({
  // overrideExisting: false,
  endpoints: (builder) => ({
    getProgramsSummary: builder.query({
      query: (period: IPeriod["apiV2"]) => ({
        url: "?",
        params: { name: GET_SUMMARY },
        method: "POST",
        body: {
          requests: getSummaryRTK(period),
        },
      }),
      transformResponse: (response: { responses: any[] }) =>
        response.responses?.[0].data,
    }),
  }),
});
