import { styled } from "@mui/material";

export const Wrapper = styled("div")(() => ({
  width: "100%",
  marginBottom: "1rem",
  padding: "0 1.3rem",

  "& .MuiInputBase-adornedEnd": {
    height: "3rem",
  },

  svg: {
    cursor: "pointer",
  },

  label: {
    color: "#ffffff",
  },
}));
