import { Box, SxProps } from "@mui/material";
import React from "react";
import { IncidentReport } from "SRC/components/IncidentReport";

import { css, Wrapper } from "./HeaderInfoWidget.styled";

interface IHeaderData {
  header: {
    id?: string;
    name?: string;
    programType?: string;
    icon?: string;
    programSecret?: boolean;
  };
  curator: {
    name?: string | number;
    photo?: string;
    executorPhoto?: string;
    executorName?: string;
  };
}

interface IHeaderInfoWidget {
  headerData: IHeaderData;
}

export const HeaderInfoWidget = ({ headerData }: IHeaderInfoWidget) => {
  const { header, curator } = headerData;
  return (
    <Wrapper>
      <Box sx={css.title}>
        <Box sx={css.numProgram as SxProps}>{header.id}</Box>
        <Box>
          <Box sx={css.mainTitle as SxProps}>{header.name}</Box>
          <Box sx={css.programType}>{header.programType}</Box>
        </Box>
      </Box>
      <IncidentReport className="incident-container">
        <Box sx={css.curatorContainer}>
          <Box sx={css.curatorPhoto as SxProps}>
            <img alt="photo" src={curator.photo} />
          </Box>
          <Box sx={css.curatorNameContainer}>
            <Box sx={css.curatorText}>Куратор</Box>
            <Box sx={css.curatorName}>{curator.name}</Box>
          </Box>
        </Box>
        <Box sx={css.curatorContainer}>
          <Box sx={css.curatorPhoto as SxProps}>
            <img alt="photo" src={curator.executorPhoto} />
          </Box>
          <Box sx={css.curatorNameContainer}>
            <Box sx={css.curatorText}>Ответственный исполнитель</Box>
            <Box sx={css.curatorName}>{curator.executorName}</Box>
          </Box>
        </Box>
      </IncidentReport>
    </Wrapper>
  );
};
