import { styled, Theme } from "@mui/material";

export const Wrapper = styled("div")(({ theme }) => {
  return {
    width: "100%",

    ".incident-container": {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      gap: "0.5rem",
      flex: 1,
      flexWrap: "nowrap",
      borderRadius: "1rem",
      padding: "1.5rem 2rem",
      backgroundColor: theme.palette.complementary.darkBlueLight,
    },
  };
});

export const css = {
  title: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "baseline",
    fontSize: "1.25rem",

    span: {
      fontSize: "1rem",
      color: (theme: Theme) => theme.palette.text.primary,
    },
  },
  content: {
    display: "flex",
    gap: "1rem",
  },
  contentLeft: {
    display: "flex",
    flex: "0 0 50%",
    flexDirection: "column",
    gap: "0.5rem",
    justifyContent: "space-between",
  },
  contentRight: {
    display: "flex",
    flex: 1,
    borderRadius: "1rem",
    background: (theme: Theme) =>
      `linear-gradient(180deg, ${theme.palette.complementary.linearDarkBlue} 0%, ${theme.palette.complementary.linearDarkBlue2} 100%)`,
    flexDirection: "column",
    padding: "1rem",
    gap: "1rem",
  },
  doughnut: (theme: Theme) => ({
    background: theme.palette.charts.darkBlueBackground,
    borderRadius: "50%",
    display: "flex",
    height: "11.25rem",
    width: "11.25rem",
    padding: "0.3rem",
    margin: "0 auto",
    [theme.breakpoints.down("md")]: {
      height: "11rem",
      width: "11rem",
    },
  }),
  doughnutInfo: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },
  infoTop: {
    display: "flex",
    alignItems: "baseline",
    fontSize: "1.25rem",

    "& span:not(.no-value)": {
      color: (theme: Theme) => theme.palette.complementary.lightWhite,
      fontSize: "0.875rem",
      marginLeft: "0.25rem",
      whiteSpace: "nowrap",
    },

    "& .no-value": {
      color: (theme: Theme) => theme.palette.complementary.lightWhite,
    },

    "& :nth-of-type(2), & :nth-of-type(1)": {
      marginRight: "0.25rem",
    },
  },
  point: (type: string) => ({
    width: "0.75rem",
    height: "0.75rem",
    borderRadius: "50%",
    backgroundColor: (theme: Theme) =>
      type === "fact"
        ? theme.palette.pale.blue
        : theme.palette.background.progressDarkBlue,
    marginRight: "0.375rem",
  }),
  infoBottom: {
    display: "flex",
    justifyContent: "flex-start",
    gap: "1rem",
  },
  infoName: {
    display: "flex",
    alignItems: "baseline",
  },
  rightItem: {
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
  },
  itemTitle: {
    display: "flex",
    flexDirection: "column",
    "& span": {
      fontSize: "0.875rem",
      color: (theme: Theme) => theme.palette.complementary.lightWhite,
    },
  },
  itemInfoContainer: {
    display: "flex",
    gap: "0.5rem",
  },
  itemInfo: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  itemName: {
    fontSize: "0.875rem",
    color: (theme: Theme) => theme.palette.complementary.lightWhite,
  },
  itemValue: {
    fontSize: "1.5rem",
  },
};
