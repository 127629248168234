import { olapDimensionRequestData } from "CORE/api/core";
import { PROGRAMS_MODEL_UUID } from "CORE/scheme/olap";
import { getGpIndicators } from "SRC/constants";
import { IPeriod } from "SRC/redux/slices/global/slices/filters";

const GET_ALL_PROGRAMS_INFO = "get-all-programs-info";

export const getAllProgramsInfo = (period: IPeriod) => {
  return olapDimensionRequestData(
    [
      {
        requestId: "9999c038-5b70-4081-a47f-49018e1ce85f",
        type: "data2",
        params: {
          modelUuid: PROGRAMS_MODEL_UUID,
          dashboardUuid: "",
          includeGaps: false,
          dimensions: [
            {
              id: "1562",
              includeItems: true,
              includeAttributesByCodes: [
                "NAME",
                "SHORT_NAME",
                "SORT_ORDER",
                "PERIOD_CODE",
              ],
            },
            {
              id: "1565",
              includeItems: true,
              includeAttributesByCodes: [
                "NAME",
                "SHORT_NAME",
                "SORT_ORDER",
                "gp_code",
                "gp_short_name",
                "gp_icon_url",
                "gp_name",
                "Вице-премьер",
                "Министерство",
                "Вице-премьер, код",
                "Вице-премьер, фото",
                "Секретная",
                "Комплексная",
                "Отраслевая",
                "Сфера",
              ],
            },
          ],
          indicators: {
            id: "1571",
            items: getGpIndicators(
              1547,
              1548,
              1572,
              1573,
              1574,
              1575,
              1576,
              1577,
              1578,
              1579,
              1580,
              1581,
              1582,
              1583,
              1584,
              1585,
              1586,
              1587,
              1588,
              1589,
              1590,
              1591,
              1592,
              1593,
              1594,
              1595,
              1596,
              1597,
              1598,
              1599,
              1600,
              1601,
              1602,
              1603,
              1604,
              1605,
              1652,
              1653,
              1678,
              1679,
              1680,
              1681,
              1682,
              1730,
              1731,
              1753,
              1754,
              1755,
              1756,
              1757,
              1769,
              1796,
              1838,
              1839,
              5204,
              5207,
              5208,
              5209,
              5246,
              5250,
              5251,
              5252,
              5265,
              5269,
              5278,
              5279,
              5280,
              5281,
              5282,
              5283,
              5284,
              5285,
              5286,
              5287,
              5288,
              5289,
              5290,
              5291,
              5292,
              5293,
              5294,
              5295,
              5296,
              5297,
              5298,
              5299,
              5479,
              5480,
              5481,
              5482,
              5483,
              5484,
              5485,
              5492,
              5493,
              5494,
              5495,
              5496,
              5497
            ),
          },
          dataRequestCaching: true,
          dataSort: [
            {
              dim_id: "1562",
              sortField: "name",
              sortOrder: "asc",
            },
            {
              dim_id: "1565",
              sortField: "name",
              sortOrder: "asc",
            },
          ],
          dataFilter: {
            type: "AND",
            version: 1,
            operands: [
              {
                type: "STARTER",
                version: 1,
                dim_id: "1565",
              },
              {
                type: "EQ",
                version: 1,
                operands: [
                  {
                    type: "DIM",
                    version: 1,
                    id: "1562",
                  },
                  {
                    type: "CONST",
                    version: 1,
                    values: [period.apiV2],
                  },
                ],
              },
            ],
          },
        },
      },
    ],
    GET_ALL_PROGRAMS_INFO
  );
};
