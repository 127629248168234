import { InputLabel } from "@mui/material";
import { Calendar } from "SRC/components/Calendar/Calendar";

import { Wrapper } from "./IncidentPeriod.styled";

export const IncidentPeriod = () => {
  return (
    <Wrapper>
      <InputLabel>Отчетный период</InputLabel>
      <div>
        <Calendar />
      </div>
    </Wrapper>
  );
};
