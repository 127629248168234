import { useCallback } from "react";
import { useAppSelector } from "SRC/redux/hooks";
import { useGlobalFilters } from "SRC/redux/slices/global/hooks/useFilters";
import { useGpStructure } from "SRC/redux/slices/gosprogram/hooks/useGpStructure";
import { useProgram } from "SRC/redux/slices/gosprogram/hooks/useProgram";

import { periodYearMonth } from "../utils";
import {
  useStructureElements,
  useStructureElementsIndicators,
  useStructureElementsTasks,
} from "./structureElements";
import { useEvents } from "./useEvents";
import { useFilters } from "./useFilters";
import { useGoalConnect } from "./useGoalConnect";
import { useIndicators } from "./useIndicators";
import { useProgramGoals } from "./useProgramGoals";
import { useStatistics } from "./useStatistics";

interface IDefaultParams {
  programId: string;
  fp_code?: string | null;
  dp_code?: string | null;
  cpm_code?: string | null;
}

export interface IMainState {
  loading: boolean;
  loaded: boolean;
  init(params: IDefaultParams): void;
  hasError: any;
}

const defaultParams = {
  id: "",
  fp_code: "*",
  dp_code: "*",
  cpm_code: "*",
};

export const useGosprogram = (): IMainState => {
  const { period } = useGlobalFilters();

  const { map } = useAppSelector((state) => state.gosprogram.program);

  const {
    fetching: loadingProgram,
    load: loadProgram,
    getCached: getCachedProgram,
    fetched: loadedProgram,
    error: errorProgram,
  } = useProgram();
  const {
    fetching: loadingGoals,
    load: loadProgramGoals,
    fetched: loadedGoals,
    error: errorProgramGoals,
  } = useProgramGoals();
  const {
    fetching: loadingEvents,
    load: loadProgramEvents,
    fetched: loadedEvents,
    error: errorEvents,
  } = useEvents();
  const {
    fetching: loadingIndicators,
    load: loadProgramIndicators,
    fetched: loadedIndicators,
    error: errorIndicators,
  } = useIndicators();
  const {
    fetching: loadingStatistics,
    load: loadStatistics,
    fetched: loadedStatistics,
    error: errorStatistics,
  } = useStatistics();
  const {
    fetching: loadingGoalConnect,
    load: loadGoalConnect,
    fetched: loadedGoalConnect,
    error: errorGoalConnect,
  } = useGoalConnect();
  const {
    fetching: loadingStructureElements,
    load: loadStructureElements,
    fetched: loadedStructureElements,
    error: errorStructureElements,
  } = useStructureElements();
  const {
    fetching: loadingGpStructure,
    load: loadGpStructure,
    fetched: loadedGpStructure,
    error: errorGpStructure,
  } = useGpStructure();
  const { setFiltersForGP, clearSelectedStatus } = useFilters();
  const {
    fetching: loadingStructureElementsIndicators,
    load: loadStructureElementsIndicators,
    fetched: loadedStructureElementsIndicators,
    error: errorStructureElementsIndicators,
  } = useStructureElementsIndicators();
  const {
    fetching: loadingStructureElementsTasks,
    load: loadStructureElementsTasks,
    fetched: loadedStructureElementsTasks,
    error: errorStructureElementsTasks,
  } = useStructureElementsTasks();

  const loading =
    loadingProgram ||
    loadingGoals ||
    loadingEvents ||
    loadingIndicators ||
    loadingStatistics ||
    loadingGoalConnect ||
    loadingStructureElements ||
    loadingStructureElementsIndicators ||
    loadingStructureElementsTasks ||
    loadingGpStructure;
  const loaded =
    loadedProgram &&
    loadedGoals &&
    loadedEvents &&
    loadedIndicators &&
    loadedStatistics &&
    loadedGoalConnect &&
    loadedStructureElements &&
    loadedStructureElementsIndicators &&
    loadedStructureElementsTasks &&
    loadedGpStructure;
  const hasError =
    errorProgram ||
    errorEvents ||
    errorIndicators ||
    errorProgramGoals ||
    errorStatistics ||
    errorGoalConnect ||
    errorStructureElements ||
    errorStructureElementsIndicators ||
    errorStructureElementsTasks ||
    errorGpStructure;

  const init = useCallback(
    (params: IDefaultParams) => {
      const { programId } = params;
      const period_code = period.filterValue;

      const { year, month } = periodYearMonth(period_code);
      setFiltersForGP(programId);

      const loadParams = {
        ...defaultParams,
        ...params,
        id: programId,
        gp_code: programId,
        period,
        period_code,
      };

      const isProgramCached = !!map[year]?.[month]?.[programId];

      if (isProgramCached) {
        getCachedProgram({ year, month, programId });
      } else if (!loading) {
        loadProgram(loadParams);
      }

      loadProgramGoals(loadParams);
      loadProgramEvents(loadParams);
      loadProgramIndicators(loadParams);
      loadStatistics(loadParams);
      loadGoalConnect(loadParams);
      loadStructureElements(programId);
      loadStructureElementsIndicators(loadParams);
      loadStructureElementsTasks(programId);
      loadGpStructure(loadParams);
    },
    [
      period,
      loading,
      clearSelectedStatus,
      loadProgram,
      loadProgramGoals,
      loadProgramEvents,
      loadProgramIndicators,
      loadStatistics,
      loadGoalConnect,
      loadStructureElements,
      loadStructureElementsIndicators,
      loadGpStructure,
    ]
  );

  return {
    loading,
    loaded,
    init,
    hasError,
  };
};
