import React from "react";
import { TableProps, UseExpandedRowProps, UseTableRowProps } from "react-table";
import { TableCell } from "SRC/components/TableCell";
import { getRoundValue } from "SRC/helpers/getRoundValue";

type CustomRowProps = UseTableRowProps<any> & UseExpandedRowProps<any>;

interface CustomTableProps extends TableProps {
  row: CustomRowProps;
  rows?: UseTableRowProps<any>[];
}

const getKeyPrioritiesColumns = (isOM: boolean) => {
  return [
    {
      Header: "",
      sticky: "left",
      accessor: "kpi",
      columns: [
        {
          Header: "Наименование",
          sticky: "left",
          width: 300,
          accessor: "kpi",
          Cell: (tableProps: CustomTableProps) => (
            <TableCell>{tableProps.row.original.kpi}</TableCell>
          ),
          Footer: "",
        },
      ],
      Footer: "",
    },
    {
      Header: "",
      sticky: "left",
      accessor: "programm_name",
      columns: [
        {
          Header: "Государственная программа",
          sticky: "left",
          width: 230,
          accessor: "programm_name",
          Cell: (tableProps: CustomTableProps) => (
            <TableCell color="gray">
              {tableProps.row.original.programm_name}
            </TableCell>
          ),
        },
      ],
      Footer: "",
    },
    {
      Header: "",
      sticky: "left",
      accessor: "name",
      columns: [
        {
          Header: "Вице-премьер",
          sticky: "left",
          width: 180,
          accessor: "name",
          Cell: (tableProps: CustomTableProps) => (
            <TableCell color="gray">{tableProps.row.original.name}</TableCell>
          ),
          Footer: "",
        },
      ],
      Footer: "",
    },
    {
      Header: "",
      accessor: "kpi_measure",
      columns: [
        {
          Header: "Ед. изм.",
          width: 150,
          accessor: "kpi_measure",
          Cell: (tableProps: CustomTableProps) => (
            <TableCell color="gray">
              {tableProps.row.original.kpi_measure}
            </TableCell>
          ),
        },
      ],
      Footer: "",
    },
    {
      Header: "Результаты реализации за весь период",
      accessor: "result",
      columns: [
        {
          Header: "План на 2024",
          width: 150,
          accessor: "r1",
          Cell: (tableProps: CustomTableProps) => (
            <TableCell>
              {getRoundValue(tableProps.row.original.r1, 2)}
            </TableCell>
          ),
        },
        {
          Header: "Факт накопленным итогом",
          accessor: "r2",
          Cell: (tableProps: CustomTableProps) => {
            const { r2 } = tableProps.row.original;
            return (
              <TableCell color={r2 > 0 ? "white" : "gray"}>
                {getRoundValue(r2, 2)}
              </TableCell>
            );
          },
        },
        {
          Header: "Уровень достижения ГП",
          width: 180,
          accessor: "r3",
          Cell: (tableProps: CustomTableProps) => {
            const { r3 } = tableProps.row.original;
            const achievement = getRoundValue(r3, 2);
            const color =
              achievement === "100"
                ? "green"
                : achievement === "0"
                ? "gray"
                : "white";
            return <TableCell color={color}>{achievement}</TableCell>;
          },
        },
      ],
      Footer: "",
    },
    {
      Header: "Результаты реализации за выбранный период",
      accessor: "resPeriod",
      columns: [
        {
          Header: "План на 2022",
          width: 180,
          accessor: "r4",
          Cell: (tableProps: CustomTableProps) => {
            const { r4 } = tableProps.row.original;
            return (
              <TableCell color={r4 > 0 ? "white" : "gray"}>
                {getRoundValue(r4, 2)}
              </TableCell>
            );
          },
        },
        {
          Header: "Факт накопленным итогом",
          width: 180,
          accessor: "r5",
          Cell: (tableProps: CustomTableProps) => {
            const { r5 } = tableProps.row.original;
            return (
              <TableCell color={r5 > 0 ? "white" : "gray"}>
                {getRoundValue(r5, 2)}
              </TableCell>
            );
          },
        },
        {
          Header: "Уровень достижения КП",
          width: 180,
          accessor: "r6",
          Cell: (tableProps: CustomTableProps) => {
            const { r6 } = tableProps.row.original;
            const color = Number(r6) === 100 ? "green" : "white";
            return <TableCell color={color}>{getRoundValue(r6, 2)}</TableCell>;
          },
        },
      ],
      Footer: "",
    },
  ];
};

export { getKeyPrioritiesColumns };
