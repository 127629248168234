import { Search } from "@mui/icons-material";
import {
  FilledInput,
  FormControl,
  Grid,
  InputAdornment,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { TableInstance } from "react-table";
import Select from "SRC/components/Select/Select";
import SelectWithSearchTypeProgramFilter from "SRC/components/SelectWithSearch/SelectWithSearchTypeProgramFilter";
import { css } from "SRC/pages/Main/common/ComplexGrid/ComplexGrid.styled";
import { useGlobalFilters } from "SRC/redux/slices/global/hooks/useFilters";
import { IFilters, useFilters } from "SRC/redux/slices/main/hooks/useFilters";

import { govProgramsTabStyled } from "../GovProgramsTab/GovProgramsTab.styled";

interface IGovProgramsFiltersProps {
  instance: TableInstance | undefined;
}

const selectOptions = [
  {
    value: "complex",
    label: "Комплексная",
  },
  {
    value: "industry",
    label: "Отраслевая",
  },
  {
    value: "secret",
    label: "Скрытая",
  },
];

export const GovProgramsFilters: React.FC<IGovProgramsFiltersProps> = ({
  instance,
}) => {
  const inputRef = useRef<HTMLInputElement>();
  const theme = useTheme();
  const selectStyle = useMemo(() => css.selector(theme), [theme]);
  const match = useMediaQuery(theme.breakpoints.up("lg"));
  const { typeProgram, setSelectTypeProgram }: IFilters = useFilters();
  const { withSecret, toggleSecret } = useGlobalFilters();

  const setType = (value: string | number = "") => {
    setSelectTypeProgram(value.toString());
    if (value === "secret") {
      toggleSecret(withSecret);
    }
  };

  useEffect(() => {
    if (instance && inputRef?.current) {
      inputRef.current.value = instance.state.globalFilter;
    }
  }, [instance?.state, inputRef]);

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      instance?.setGlobalFilter(event.target.value);
    },
    [instance]
  );

  return (
    <Grid
      container
      justifyContent={match ? "space-between" : "flex-start"}
      sx={{
        marginBottom: match ? "1rem" : "0rem",
        maxWidth: "100%",
      }}
    >
      <Grid item sm={5} lg={4} sx={govProgramsTabStyled.gpWrapper}>
        <SelectWithSearchTypeProgramFilter
          style={selectStyle}
          options={selectOptions}
          onChange={setType}
          label="Тип госпрограммы"
          value={typeProgram}
        />
      </Grid>
      <Grid
        item
        sm={5}
        lg={7}
        sx={{
          textAlign: "right",
          pl: 10,
          alignSelf: "flex-end",
        }}
      >
        <FormControl
          sx={{ ...css.uiSearch, ...govProgramsTabStyled.searchInputWrapper }}
          variant="filled"
        >
          <FilledInput
            startAdornment={
              <InputAdornment position="start">
                <Search sx={govProgramsTabStyled.search} />
              </InputAdornment>
            }
            placeholder="Поиск госпрограммы"
            sx={govProgramsTabStyled.searchInput}
            ref={inputRef}
            onChange={onChange}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};
