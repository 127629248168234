import { combineReducers } from "@reduxjs/toolkit";

import tokenSlice, { ITokenState } from "./slices/tokenSlice";
import userSlice, { IUserState } from "./slices/userSlice";

export interface IAuthReducer {
  token: ITokenState;
  user: IUserState;
}

const reducer = combineReducers<IAuthReducer>({
  token: tokenSlice.reducer,
  user: userSlice.reducer,
});

export default reducer;
