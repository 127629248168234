import { Theme } from "@mui/material";
import { graphic } from "echarts";
import { EChartsOption } from "echarts/types/dist/echarts";
import { EChartsInstance } from "echarts-for-react";

import { IPRChartElement } from "./PieRadialChart";

const defaultValue = [
  {
    value: 100,
    itemStyle: {
      color: "transparent",
    },
  },
];

const getFormattedValue = (
  el: IPRChartElement,
  chart: EChartsInstance | null,
  centered = false
) => {
  let chartWidth = 0;
  let chartHeight = 0;

  try {
    chartWidth = chart?.getWidth() || 0;
    chartHeight = chart?.getHeight() || 0;
  } catch (e) {
    // tslint:disable-next-line:no-empty
  }

  const relativeValue = ((el.value.value || 0) / 100 + 0.2) * 0.85;
  const value = relativeValue > 1 ? 1 : relativeValue;
  const color: string =
    Number(el.value.value) < 100
      ? el.value.settings.failColor
      : el.value.settings.successColor;
  return {
    value: el.total,
    name: el.name,
    label: centered
      ? {
          position: "outer" as "outer",
          padding: -30,
          rotate: -90,
          edgeDistance: "9%",
          alignTo: "edge" as "edge",
        }
      : {
          padding:
            el.name === "ФП" && el.total <= 3 && el.value.value === 100
              ? [0, 10]
              : -15,
        },
    itemStyle: {
      color: new graphic.RadialGradient(
        chartWidth / 2,
        chartHeight / 2,
        chartHeight * 0.48,
        [
          ...(value > 0.3
            ? [
                {
                  offset: 0.28,
                  color: color + "00",
                },
                {
                  offset: value - 0.02,
                  color: color + "60",
                },
              ]
            : [
                {
                  offset: value - 0.02,
                  color: "#0000",
                },
              ]),
          ...(el.value.value
            ? [
                {
                  offset: value - 0.02,
                  color,
                },
                {
                  offset: value,
                  color,
                },
              ]
            : []),
          {
            offset: value,
            color: "#0000",
          },
        ],
        true
      ),
    },
  };
};

export const getOption = (
  values: IPRChartElement[],
  theme: Theme,
  chart: EChartsInstance | null
): EChartsOption => ({
  grid: {
    borderWidth: 0,
    width: "100%",
    height: "100%",
    left: "0",
    bottom: "0",
  },
  polar: {
    center: ["50%", "50%"],
    radius: ["15%", "96%"],
  },
  radiusAxis: {
    min: 0,
    max: 100,
    interval: 20,
    axisLine: {
      show: false,
    },
    axisTick: {
      show: false,
    },
    splitLine: {
      show: true,
      lineStyle: {
        type: "dashed",
        color: theme.palette.charts.grayTransparent,
      },
    },
    axisLabel: {
      color: theme.palette.main.gray,
      formatter: "{value} %",
      showMinLabel: false,
      showMaxLabel: false,
      fontSize: 6,
    },
    z: 3,
  },
  angleAxis: {
    startAngle: 90,
    axisLine: {
      show: false,
    },
    z: 4,
  },
  tooltip: {},
  series: {
    z: 2,
    type: "pie",
    radius: ["15%", "96%"],
    data: values.length
      ? values.map((v, i) => getFormattedValue(v, chart, !i))
      : defaultValue,
    tooltip: {
      formatter: ({ dataIndex }) => `${values[dataIndex]?.value?.value || 0} %`,
    },
    label: {
      position: "outside",
      color: theme.palette.primary.contrastText,
    },
    itemStyle: {
      borderColor: theme.palette.complementary.grayBorder,
      borderWidth: 2,
    },
  },
  backgroundColor: "transparent",
  animation: false,
});
