import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createPeriodDims,
  IParsedItem,
  parseOlapdata,
  TDecoder,
} from "CORE/utils";
import { isContainedArray } from "SRC/helpers/dataTesters";
import { getEventData } from "SRC/redux/slices/event/api/client";
import { IEventParams } from "SRC/types";

export const eventInfoDims = {
  name: "NAME",
  code: "R_ID",
  budgetUnit: "Ед. измерения бюджета",
  resultUnit: "Ед. измерения результата",
  project: "Наименование проекта",
  curator: "Ответственный исполнитель",
  type: "Тип мероприятия",
  characteristic: "Характеристика результата",
};

const eventDecrypt: TDecoder = {
  period: createPeriodDims(1344),
  info: {
    code: 1348,
    dimensions: eventInfoDims,
  },
};

export interface IEventDataInfo {
  name: string;
  code: string;
  budgetUnit: string;
  resultUnit: string;
  project: string;
  curator: string;
  type: string;
  characteristic: string;
}

export interface IEventData extends IParsedItem {
  info: IEventDataInfo;
}

export interface IEventDataState {
  items: IEventData | null;
  fetching: boolean;
  fetched: boolean;
  error: string;
}

export const extraActions = {
  get: createAsyncThunk(
    "data/get",
    async (params: IEventParams) => await getEventData(params)
  ),
};

const initialState: IEventDataState = {
  items: null,
  fetching: false,
  fetched: false,
  error: "",
};

const slice = createSlice({
  name: "data",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActions.get.pending, (state) => {
        state.items = null;
        state.fetching = true;
        state.fetched = false;
      })
      .addCase(extraActions.get.fulfilled, (state: IEventDataState, action) => {
        const parsed = parseOlapdata(
          // @ts-ignore
          action.payload.data,
          eventDecrypt
        ) as unknown as IEventData[];
        state.items = isContainedArray(parsed) ? parsed[0] : null;
        state.fetching = false;
        state.fetched = true;
      })
      .addCase(extraActions.get.rejected, (state, action) => {
        state.fetching = false;
        state.fetched = false;
      });
  },
});

export const actions = {
  ...slice.actions,
  ...extraActions,
};

export default slice;
