import { SerializedError } from "@reduxjs/toolkit";
import { useCallback, useState } from "react";
import { IReportParams } from "SRC/redux/slices/gosprogram/slices/reports";
import { IWorkbookData, TConfig, txtColors } from "SRC/types";

import { useAppDispatch, useAppSelector } from "../../../hooks";
import actions from "../actions";

export interface IReport {
  fetching: boolean;
  fetched: boolean;
  load(params: IReportParams): void;
  config: TConfig;
  error: SerializedError | null;
  getReportFile(params: IReportParams): Promise<unknown>;
  items: IWorkbookData | null;
}

const config: TConfig = {
  title: {
    bold: true,
    color: "blue",
    size: 14,
    alignment: {
      horizontal: "center",
      vertical: "center",
      wrapText: true,
    },
  },
  cell: {
    size: 11,
    alignment: {
      horizontal: "left",
      vertical: "center",
    },
    border: {
      top: { style: "thin", color: { rgb: txtColors.default } },
      bottom: { style: "thin", color: { rgb: txtColors.default } },
      left: { style: "thin", color: { rgb: txtColors.default } },
      right: { style: "thin", color: { rgb: txtColors.default } },
    },
  },
  header: {
    fill: "lightblue",
    size: 12,
    alignment: {
      horizontal: "center",
      vertical: "center",
      wrapText: true,
    },
    border: {
      top: { style: "thin", color: { rgb: txtColors.default } },
      bottom: { style: "thin", color: { rgb: txtColors.default } },
      left: { style: "thin", color: { rgb: txtColors.default } },
      right: { style: "thin", color: { rgb: txtColors.default } },
    },
  },
};

export const useReports = (): IReport => {
  const dispatch = useAppDispatch();
  const {
    fetching,
    fetched,
    items: reports = null,
  } = useAppSelector((state) => state.gosprogram.reports);
  const [error, setError] = useState<SerializedError | null>(null);

  const load = useCallback(
    (params: IReportParams) => {
      return dispatch(actions.reports.get(params)).then((action) => {
        if (actions.reports.get.rejected.match(action)) {
          setError(action.error);
        }

        return action;
      });
    },
    [dispatch, fetching]
  );

  const getReportFile = useCallback(
    (params: IReportParams) => {
      return load(params);
    },
    [load]
  );

  return {
    fetching,
    fetched,
    load,
    config,
    error,
    getReportFile,
    items: reports,
  };
};
