import React from "react";

type TRostelecomVideo = {
  url: string;
};
export const RostelecomVideo = ({ url }: TRostelecomVideo) => {
  return (
    <iframe
      src={url}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowFullScreen
      title="Embedded"
    />
  );
};
