import { useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { ExtendedTabs, ITab } from "SRC/components/ExtendedTabs/ExtendedTabs";
import { TableModal } from "SRC/components/FullPageTableModal/FullPageTableModal";
import { Indicators } from "SRC/pages/Program/common/GoalsTab/common/Indicators/Indicators";
import { NationalGoalsConnect } from "SRC/pages/Program/common/GoalsTab/common/NationalGoalsConnect/NationalGoalsConnect";
import { useProgramIndicators } from "SRC/pages/Program/common/IndicatorsTab/useIndicatorsData";
import { useFilters } from "SRC/redux/slices/gosprogram/hooks";
import { INDICATORS_TYPE } from "SRC/types/program/program-tab";

import { css } from "./GoalsTab.styled";

interface IGoalsTabProps {
  isTableModalOpen?: boolean;
  setTableModalOpen?: (value: boolean) => void;
  setGoalsCurrTab?: React.Dispatch<React.SetStateAction<number>>;
  getData?: (data: any) => void;
}

interface IIndicatorsLength {
  indicators: number;
  analytics: number;
}

const tabs = ({ indicators, analytics }: IIndicatorsLength): ITab<number>[] => [
  {
    value: 0,
    label: "Показатели",
    component: <Indicators type={INDICATORS_TYPE.SIMPLE} />,
    numberOfItems: indicators,
    isSmall: true,
  },
  {
    value: 1,
    label: "Связи с НЦ",
    component: <NationalGoalsConnect />,
    isSmall: true,
  },
  {
    value: 2,
    label: "Аналитические показатели",
    component: <Indicators type={INDICATORS_TYPE.ANALYTICS} />,
    numberOfItems: analytics,
    isSmall: true,
  },
];

const GoalsTab: React.FC<IGoalsTabProps> = (props) => {
  const { isTableModalOpen, setTableModalOpen, setGoalsCurrTab, getData } =
    props;
  const [activeTab, setActiveTab] = useState<number>(0);
  const { indicators, analyticIndicators } = useProgramIndicators();
  const {
    setSelectedStructureElementName,
    setSelectedStructureElementType,
    setSelectedStructureElementTask,
    setSelectedEventFeature,
    clearSelectedStatus,
  } = useFilters();

  const indicatorsLength = useMemo(() => {
    return {
      indicators: indicators.length,
      analytics: analyticIndicators.length,
    };
  }, [indicators, analyticIndicators]);

  const currData = useMemo(() => {
    return activeTab === 0 ? indicators : analyticIndicators;
  }, [activeTab, indicators, analyticIndicators]);

  const handleClearFilters = () => {
    setSelectedStructureElementType("");
    setSelectedStructureElementName("");
    setSelectedStructureElementTask("");
    setSelectedEventFeature("");
    clearSelectedStatus();
  };

  useEffect(() => {
    handleClearFilters();
  }, []);

  useEffect(() => {
    getData && getData(currData);
  }, [currData]);

  const handleTabChange = (value: number) => {
    setActiveTab(value);
    if (setGoalsCurrTab) {
      setGoalsCurrTab(value);
    }
  };
  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.up("lg"));

  if (!match && isTableModalOpen) {
    return (
      <TableModal
        open={isTableModalOpen}
        setOpen={setTableModalOpen}
        tableName="Показатели ГП"
      >
        <ExtendedTabs
          value={activeTab}
          onChange={handleTabChange}
          tabs={tabs(indicatorsLength)}
          borderStyle="none"
          contained
          style={css.tabs}
          innerTab
        />
      </TableModal>
    );
  }

  return (
    <ExtendedTabs
      value={activeTab}
      onChange={handleTabChange}
      tabs={tabs(indicatorsLength)}
      borderStyle="none"
      contained
      style={css.tabs}
      innerTab
    />
  );
};

export default GoalsTab;
