import { Box, debounce } from "@mui/material";
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { AnalyticsCardItems } from "SRC/components/CarouselNew/AnalyticsCardItems";
import { CarouselButton } from "SRC/components/CarouselNew/CarouselButton";

import { Wrapper } from "./Carousel.styled";

interface ICarouselProps {
  children: any[];
  visibleItems: number;
  isWheel?: boolean;
}

const Carousel = ({
  children,
  visibleItems,
  isWheel = false,
}: ICarouselProps) => {
  const wait = 100;
  const timeTransition = 0.3;
  const indexPrevTransport = useMemo(() => {
    return children.length - 1;
  }, [children]);
  const indexTransportOnStart = children.length;
  const [activeIndex, setActiveIndex] = useState(0);
  const [cards, setCards] = useState(children);
  const [carouselCopyStop, setCarouselCopyStop] = useState(false);
  const [transition, setTransition] = useState(timeTransition);
  const carouselRef = useRef<HTMLElement>();

  useEffect(() => {
    setCards((prevCards) => {
      return [...prevCards, prevCards[prevCards.length - 1]];
    });
  }, []);

  const offset = useMemo(() => {
    return visibleItems ? 100 / visibleItems : 25;
  }, [visibleItems]);

  const cardsCopy = useCallback(() => {
    const cardsMove: any = cards.slice(
      1,
      children.length - (children.length - visibleItems)
    );
    setCards([...cards, ...cardsMove]);
  }, [cards]);

  const activeIndexTransport = useCallback(
    (index: number) => {
      setTransition(0);
      setActiveIndex(activeIndex === 0 ? index + 1 : index - 1);
      setTimeout(() => {
        setTransition(timeTransition);
        setActiveIndex(index);
      }, wait);
    },
    [activeIndex]
  );

  const handleSlideNext = debounce(() => {
    if (!carouselCopyStop) {
      cardsCopy();
      setCarouselCopyStop(true);
    }
    if (activeIndex === indexTransportOnStart) {
      activeIndexTransport(1);
    }
    if (activeIndex <= indexTransportOnStart - 1) {
      setActiveIndex(activeIndex + 1);
      setTransition(timeTransition);
    }
  }, wait);

  const handleSlidePrev = debounce(() => {
    if (!carouselCopyStop) {
      cardsCopy();
      setCarouselCopyStop(true);
    }
    if (activeIndex > 0) {
      setActiveIndex(activeIndex - 1);
      setTransition(timeTransition);
    } else if (activeIndex === 0) {
      activeIndexTransport(indexPrevTransport);
    }
  }, wait);

  const handleOnWheel = useCallback(
    (event: WheelEvent) => {
      if (isWheel && !(event.target as HTMLElement).closest("[data-scroll]")) {
        event.preventDefault();
        setTimeout(() => {
          let delta = event.deltaY || event.detail;
          if (delta > 0) {
            handleSlideNext();
          } else {
            handleSlidePrev();
          }
        }, wait);
      }
    },
    [handleSlideNext, handleSlidePrev, isWheel]
  );
  useLayoutEffect(() => {
    if (isWheel) {
      const carouselElement = carouselRef.current;
      if (carouselElement) {
        carouselElement.addEventListener("wheel", handleOnWheel, {
          passive: false,
        });
        return () => {
          carouselElement.removeEventListener("wheel", handleOnWheel);
        };
      }
    }
  }, [handleOnWheel, isWheel]);

  return (
    <Wrapper transform={activeIndex * offset} transition={transition}>
      <Box className="carousel" ref={carouselRef}>
        <div className="carousel-container">
          <CarouselButton variant="prev" onClick={handleSlidePrev} />
          <div className="inner-container">
            <AnalyticsCardItems children={cards} />
          </div>
          <CarouselButton variant="next" onClick={handleSlideNext} />
        </div>
      </Box>
    </Wrapper>
  );
};

export default Carousel;
