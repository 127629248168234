import { Box } from "@mui/material";

import { css } from "./CounterBox.styled";

interface ICounterBoxProps {
  position: string;
  count: number;
}

export const CounterBox = ({ position, count }: ICounterBoxProps) => {
  if (!count) {
    return null;
  }

  return <Box sx={css.counterBox(position)}>{count}</Box>;
};
