import { useEffect, useMemo } from "react";
import { getInfoRowsSummaryVicePremiers } from "SRC/helpers/getInfoRows";
import { useTableExportParams } from "SRC/hooks";
import { IPremierTableItem } from "SRC/hooks/summaryTable/interfaces";
import { createPremiersMainTableData } from "SRC/hooks/summaryTable/utils/mainData/mainDataVPNew";
import { getPremiersData } from "SRC/hooks/summaryTable/utils/utils";
import { useGlobalFilters } from "SRC/redux/slices/global/hooks/useFilters";
import {
  IFilters as IMainFilters,
  useFilters as useMainFilters,
} from "SRC/redux/slices/main/hooks/useFilters";
import { useMainPremiers } from "SRC/redux/slices/main/hooks/useMainPremiers";
import { usePrograms as useMainPrograms } from "SRC/redux/slices/main/hooks/usePrograms";
import { IExportData } from "SRC/types";

import { createProgramsTableData as createProgramsMainTableData } from "./utils/mainData/mainDataGP";

interface IUseTableData {
  premiersMainData: IPremierTableItem[];
  premiersMainExport: IExportData<Record<string, any>>;
}

const TOTAL_ID = "Итого";

export const useMainTableData = (): IUseTableData => {
  const { period, withSecret } = useGlobalFilters();

  const { selectedSearchRows: exportMainData, filteredVPs }: IMainFilters =
    useMainFilters();

  const { items: mainPrograms } = useMainPrograms();
  const { load: loadMainPremiers, items: mainPremiers } = useMainPremiers();
  const createExportParams = useTableExportParams;

  useEffect(() => {
    loadMainPremiers(period);
  }, [period.value]);

  const programsMainTableData = useMemo(
    () => createProgramsMainTableData(mainPrograms),
    [mainPrograms]
  );

  const premiersMainTableData = useMemo(() => {
    const premiers = createPremiersMainTableData(mainPremiers);

    const premiersTotalIndex = premiers.findIndex(({ id }) => id === TOTAL_ID);
    const [premiersTotal] = premiers.splice(premiersTotalIndex, 1);

    return premiers.map((premier) => {
      return {
        ...premier,
        indicatorsPlanTotal: premiersTotal?.indicatorsPlan,
        indicatorsOMPlanTotal: premiersTotal?.indicatorsOMPlan,
        indicatorsFactTotal: premiersTotal?.indicatorsFact,
        indicatorsOMFactTotal: premiersTotal?.indicatorsOMFact,
        eventsPlanTotal: premiersTotal?.eventsPlan,
        eventsOMPlanTotal: premiersTotal?.eventsOMPlan,
        eventsFactTotal: premiersTotal?.eventsFact,
        eventsOMFactTotal: premiersTotal?.eventsOMFact,
        eventsRiskTotal: premiersTotal?.eventsRisk,
        eventsOMRiskTotal: premiersTotal?.eventsOMRisk,
        performanceTotal: premiersTotal?.performance,
        performanceOMTotal: premiersTotal?.performanceOM,
      };
    });
  }, [mainPremiers]);

  const premiersMainData: IPremierTableItem[] = useMemo(() => {
    const premiers = getPremiersData(
      premiersMainTableData,
      programsMainTableData,
      withSecret
    );

    return premiers
      .filter((premier) => {
        return filteredVPs.includes(premier.id);
      })
      .sort((a, b) => Number(a.id) - Number(b.id));
  }, [premiersMainTableData, programsMainTableData, withSecret, filteredVPs]);

  const getSummaryExport = (data: Record<string, any>[], tableName: string) => {
    return {
      csvData: data,
      fileName: "Сводная таблица. Вице-премьеры",
      infoRows: getInfoRowsSummaryVicePremiers(period),
      ...createExportParams(data, tableName),
    };
  };

  const premiersMainExport = useMemo(() => {
    const download = exportMainData.map(({ id, isSecret, ...item }) => item);

    return getSummaryExport(download, "vicePremiers");
  }, [exportMainData, getSummaryExport]);

  return {
    premiersMainData,
    premiersMainExport,
  };
};
