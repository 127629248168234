import { olapDimensionRequestData } from "CORE/api/core";
import { MODEL_UUID } from "CORE/scheme/olap";
const GET_METHODS = "get-methods";

export const getMethods = () => {
  return olapDimensionRequestData(
    [
      {
        requestId: "cd9f0aca-4c83-415d-863a-c14bccc1de94",
        type: "data2",
        params: {
          modelUuid: MODEL_UUID,
          dimensions: [],
          indicators: {},
          section_name: "Инфо методов расчёта УД",
          filters: {},
        },
      },
    ],
    GET_METHODS
  );
};
