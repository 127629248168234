export const getRoundValue = (
  value: number | string | React.ReactNode,
  numOfDigits: number = 2
): string => {
  const valueAsNumber = Number(value) || 0;

  if (!valueAsNumber) {
    return "0";
  }

  return valueAsNumber.toFixed(numOfDigits).replace(".", ",");
};
