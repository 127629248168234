import {
  Box,
  CircularProgress,
  SxProps,
  Typography,
  useTheme,
} from "@mui/material";
import {
  SmallArrowDownActive,
  SmallArrowDownInActive,
  SmallArrowUpActive,
  SmallArrowUpInActive,
} from "ASSETS/svg/arrow";
import { SmallCloseGray } from "ASSETS/svg/close";
import React, { MutableRefObject, SyntheticEvent } from "react";
import {
  Column,
  HeaderGroup,
  Row,
  SortingRule,
  TableBodyPropGetter,
  TableBodyProps,
  TablePropGetter,
  TableProps,
} from "react-table";
import { Overlay } from "SRC/components/Overlay";

import { css } from "./BaseTable.styled";

interface IProps<DATA, ROW extends object> {
  columns: Column[];
  data: DATA[];
  sortedColumns?: string[];
  getTableProps: (propGetter?: TablePropGetter<any>) => TableProps;
  getTableBodyProps: (propGetter?: TableBodyPropGetter<any>) => TableBodyProps;
  headerGroups: Array<HeaderGroup<any>>;
  footerGroups?: Array<HeaderGroup<any>>;
  rows: Row<ROW>[];
  prepareRow: (row: Row<ROW>) => void;
  setSortBy?: (sortBy: Array<SortingRule<any>>) => void;
  isSmall?: boolean;
  loading?: boolean;
  withBigRows?: boolean;
  tableHeight?: string | number;
  onClick?(id: number | string | undefined): void;
  onDoubleClick?(id: number | string): void;
  selectedRow?: undefined | string | number;
  setSelectedRow?(id: number | string | undefined): void;
  wrapSort?: boolean;
  scrollHide?: boolean;
  positionTable?: string;
  forwardRef?: MutableRefObject<HTMLElement | undefined>;
  onScroll?: (scroll: any) => void;
  singleTable?: boolean;
  scrollX?: boolean;
  openTableModal?: boolean;
  withoutRowSelection?: boolean;
  noDataCondition?: boolean;
  withExpandingRows?: boolean;
  isHeaderSmall?: boolean;
}

//TODO Используется только в index
const BaseTable = <D, R extends object>({
  data,
  getTableProps,
  getTableBodyProps,
  headerGroups,
  footerGroups,
  rows,
  prepareRow,
  setSortBy,
  sortedColumns,
  isSmall,
  withBigRows,
  tableHeight,
  onClick,
  onDoubleClick,
  selectedRow,
  setSelectedRow,
  wrapSort,
  scrollHide,
  forwardRef,
  onScroll,
  singleTable,
  scrollX,
  withoutRowSelection,
  noDataCondition,
  loading,
  withExpandingRows,
  isHeaderSmall,
}: IProps<D, R>): JSX.Element => {
  const handleMultiSortBy = (column: any, setSortBy: any) => {
    const desc =
      column.isSortedDesc === true ? undefined : column.isSortedDesc === false;
    setSortBy([{ id: desc != null ? column.id : desc, desc }]);
  };

  const theme = useTheme();

  const multilineHeader = headerGroups.length > 1;

  const getHeaders = () => {
    const firstHeader = headerGroups[0].headers;
    let secondHeader = headerGroups[1].headers;

    const sortedHeader = firstHeader.map((firstHeaderColumn: any) => {
      const findColumn = secondHeader.find(
        (secondHeaderColumn: any) =>
          firstHeaderColumn.placeholderOf &&
          firstHeaderColumn.placeholderOf === secondHeaderColumn
      );

      if (findColumn) {
        secondHeader = secondHeader.map((secondHeader: any) => {
          if (secondHeader !== findColumn) {
            return secondHeader;
          } else {
            return firstHeaderColumn;
          }
        });
        return findColumn;
      }

      return firstHeaderColumn;
    });

    return [
      { ...headerGroups[0], headers: sortedHeader },
      { ...headerGroups[1], headers: secondHeader },
    ];
  };

  const headersToRender = multilineHeader ? getHeaders() : headerGroups;

  const handleDoubleClick = (id: number | string, canExpand: boolean) => () => {
    onDoubleClick?.(id);
    canExpand && setSelectedRow?.(id);
  };
  const handleClick = (id: number | string) => () => {
    if (!withoutRowSelection) {
      onClick?.(id);
    }
  };
  const handleResetSelection = (e: SyntheticEvent) => {
    e.stopPropagation();
    setSelectedRow?.("");
  };

  return (
    <Box sx={css.wrapper(tableHeight, scrollHide, scrollX)}>
      <Box sx={css.headerBack(isHeaderSmall) as SxProps} />
      {!isHeaderSmall && (
        <Box sx={css.headerBackHideRiskIcon as SxProps}>
          <Box
            sx={css.headerBackHideRiskIcon as SxProps}
            className="hideHeaderIcon"
          />
          <Box
            sx={css.headerBackHideRiskIcon as SxProps}
            className="auxBlock"
          />
        </Box>
      )}
      <Box
        sx={css.container(
          multilineHeader,
          singleTable,
          withExpandingRows,
          isHeaderSmall
        )}
        onScroll={onScroll}
        ref={forwardRef}
      >
        <table {...getTableProps()}>
          <thead>
            {headersToRender.map((headerGroup: any, i: number) => (
              <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any, index: number) => {
                  return sortedColumns?.includes(column.Header) ? (
                    <th
                      style={css.tableHeadCell}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      width={column.width !== 150 ? column.width : null}
                      key={index}
                      onClick={() => handleMultiSortBy(column, setSortBy)}
                    >
                      <Box
                        sx={css.tableHeadInnerContainer(
                          column.render("Header").length,
                          withBigRows,
                          wrapSort
                        )}
                      >
                        {column.render("Header")}
                        <Box sx={css.tableHeadSortContainer}>
                          {column.isSorted && !column.isSortedDesc ? (
                            <SmallArrowUpActive />
                          ) : (
                            <SmallArrowUpInActive />
                          )}{" "}
                          {column.isSorted && column.isSortedDesc ? (
                            <SmallArrowDownActive />
                          ) : (
                            <SmallArrowDownInActive />
                          )}
                        </Box>
                      </Box>
                    </th>
                  ) : (
                    <th
                      style={css.tableHeadCell}
                      width={column.width !== 150 ? column.width : null}
                      key={index}
                      {...column.getHeaderProps()}
                    >
                      {column.render("Header")}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          {loading ? (
            <tbody style={{ height: "10rem" }}>
              <Overlay sx={css.loader}>
                <CircularProgress />
              </Overlay>
            </tbody>
          ) : (
            <tbody {...getTableBodyProps()}>
              {rows.map((row: any, i: number) => {
                prepareRow(row);
                return (
                  <tr
                    style={css.tableRow(
                      theme,
                      isSmall,
                      withBigRows,
                      multilineHeader,
                      Boolean(onClick) ||
                        Boolean(onDoubleClick) ||
                        Boolean(setSelectedRow),
                      row.original?.id === selectedRow,
                      !selectedRow
                    )}
                    key={i}
                    onClick={handleClick(row.original.id)}
                    onDoubleClick={handleDoubleClick(
                      row.original.id,
                      row.canExpand
                    )}
                    {...row.getRowProps()}
                  >
                    {row.cells.map((cell: any, index: number) => {
                      return (
                        <td
                          style={css.tableData}
                          key={index}
                          {...cell.getCellProps()}
                        >
                          {cell.render("Cell")}
                          {row.original.id &&
                            row.original.id === selectedRow &&
                            index === row.cells.length - 1 && (
                              <Box
                                sx={css.resetSelection}
                                onClick={handleResetSelection}
                              >
                                <SmallCloseGray style={css.closeIcon} />
                              </Box>
                            )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          )}
          {footerGroups && (
            <tfoot>
              <Box sx={css.footerBackHideRiskIcon as SxProps}>
                <Box
                  sx={css.footerBackHideRiskIcon as SxProps}
                  className="hideFooterIcon"
                />
              </Box>
              {footerGroups?.map((group: any, i: number) => (
                <tr key={i} {...group.getFooterGroupProps()}>
                  {group.headers.map((column: any, index: number) => (
                    <th
                      style={css.tableHeadCell}
                      key={index}
                      {...column.getFooterProps()}
                    >
                      {column.render("Footer")}
                    </th>
                  ))}
                </tr>
              ))}
            </tfoot>
          )}
        </table>
      </Box>
      {footerGroups && <Box sx={css.footerBack as SxProps} />}
      {noDataCondition && (
        <Box sx={css.emptyTextContainer}>
          <Typography sx={css.emptyText}>Нет данных</Typography>
        </Box>
      )}
    </Box>
  );
};

export default BaseTable;
