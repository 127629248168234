import { Theme } from "@mui/material";

export const css = {
  progressBar: {
    position: "relative",
    width: "100%",
  },
  uiProgressBarStyle: (
    value: number,
    progressColor?: string,
    circleLabel?: string | number,
    isDarkBg?: boolean
  ) => ({
    backgroundColor:
      circleLabel || isDarkBg
        ? (theme: Theme) => theme.palette.background.progressDarkBlue
        : (theme: Theme) => theme.palette.background.default,
    borderRadius: 10,
    margin: "auto",
    maxWidth: "100%",
    width: "100%",
    minHeight: "0.75rem",
    padding: "0.125rem",
    position: "relative",
  }),
  progressLine: (
    value: number,
    progressColor?: string,
    isOM?: boolean | undefined
  ) => ({
    display: "flex",
    flexDirection: "column",
    minHeight: "0.5rem",
    width: `${value}%`,
    borderRadius: 4,
    backgroundColor: (theme: Theme) => {
      if (isOM && value === 100) {
        return theme.palette.pale.paleGreen;
      } else if (value && value === 100) {
        return theme.palette.success.main;
      } else {
        return progressColor;
      }
    },
    position: "relative",
  }),
  circleLabel: (circleLabel?: number, progressColor?: string) => ({
    position: "absolute",
    textAlign: "center",
    width: circleLabel && circleLabel < 100 ? "1.8rem" : "fit-content",
    minWidth: circleLabel && circleLabel > 100 ? "0.75rem" : "fit-content",
    minHeight: "0.75rem",
    borderRadius: "1rem",
    top: "-0.425rem",
    right: "-0.7rem",
    background: progressColor,
    padding: "0.1rem 0.325rem",
    fontSize: "0.875rem",
    lineHeight: "-0.775rem",
    color: (theme: Theme) => theme.palette.background.paper,
  }),
  percentLabel: (theme: Theme) => ({
    display: "flex",
    flexDirection: "row",
    gap: "0.1rem",
    position: "absolute",
    width: "100%",
    justifyContent: "center",
    bottom: "1.5rem",
    fontSize: "1rem",
    color: theme.palette.primary.contrastText,
    alignItems: "baseline",
    "& span": {
      fontSize: "0.875rem",
    },
  }),
  percentLabelContainer: {
    width: "100%",
  },
  bottomLabelContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    mt: "0.5rem",
  },
  bottomLabel: (color?: string) => ({
    fontWeight: 400,
    color: color,
  }),
};
