import {
  StepConnector,
  stepConnectorClasses,
  StepLabel,
  Stepper,
  styled,
  Theme,
} from "@mui/material";

interface IProps {
  titlePosition?: string;
  bg?: string;
  status?: string;
  activeStep?: number;
}

export const BoxStepper = styled(Stepper)({
  position: "absolute",
  width: "100%",
  top: "0.85rem",
  transition: "0.5s ease-out",

  "& .MuiStep-root": {
    flex: "0 0 33.33%",
  },

  "& .MuiStepper-root": {
    position: "relative",
  },

  "& .MuiStepLabel-root": {
    position: "relative",
    top: "2.1rem",
  },

  "& .MuiStepConnector-root": {
    left: "-50%",
    right: "50%",
  },

  "& .MuiStepLabel-labelContainer": {
    "& span": {
      position: "absolute",
      right: 0,
      left: "2rem",
      fontSize: "1rem",
      marginTop: "0rem",
    },
  },
});

export const BoxConnector = styled(StepConnector)(() => ({
  top: "1px",
  left: "0rem",
  right: "0rem",
  position: "unset",
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: (theme: Theme) => theme.palette.error.main,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: (theme: Theme) => theme.palette.success.main,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: "0.5rem",
    border: 0,
    backgroundColor: "none",
    borderRadius: 1,
    position: "relative",
    top: "0.15rem",
    left: "0.18rem",
    margin: "0 0.4rem",
    background: (theme: Theme) => theme.palette.error.main,
  },
}));

export const StepLabelCheckpoint = styled(StepLabel)(({ status }: IProps) => ({
  marginTop: 0,
  "& .MuiStepLabel-iconContainer .colorStep": {
    background: (theme: Theme) =>
      status === "R"
        ? theme.palette.error.main
        : status === "G"
        ? theme.palette.success.main
        : status === "Gr"
        ? theme.palette.main.gray
        : theme.palette.background.default,
  },
}));

export const CheckpointWrapper = styled("div")(({ theme }) => {
  const {
    background: { default: color, paper },
    main: { gray },
  } = theme.palette;

  return {
    display: "flex",
    flexDirection: "row",
    flex: 1,
    gap: "0.5rem",
    padding: "1.5rem",
    zIndex: 0,
    position: "relative",
    [theme.breakpoints.up("xl")]: { padding: "1.5rem" },

    "& .inner-wrapper": {
      width: "100%",
      paddingLeft: "5rem",
    },

    "& .stepper-container": {
      position: "relative",
      overflow: "hidden",
      minHeight: "10rem",

      "&.is-open": {
        minHeight: "18rem",
      },

      "@media(min-width: 1500px)": {
        minHeight: "10rem",

        "&.is-open": {
          minHeight: "17rem",
        },
      },

      "& .button": {
        position: "absolute",
        top: "2.5rem",
        bottom: 0,
        background: color,
        maxHeight: "4rem",
        minWidth: "4rem",
        borderRadius: "50%",
        border: `3px solid ${paper}`,
        "&:hover": {
          background: color,
        },
        zIndex: 1,
      },

      "& .connector-wrapper": {
        left: "-50%",
        right: "50%",
        top: "3.3rem",
        height: "0.85rem",
        position: "absolute",
      },

      "& .arrow-left": {
        width: "1.5rem",
        opacity: 1,

        "&.is-disabled": {
          opacity: 0.4,
        },
      },

      "& .arrow-right": {
        width: "1.5rem",
        transform: "rotate(180deg)",
        opacity: 1,

        "&.is-disabled": {
          opacity: 0.4,
        },
      },

      "& .checkpoint-stepper-line": {
        position: "absolute",
        left: 0,
        top: "4rem",
        zIndex: -1,
        width: "100%",
        height: "1rem",
        borderRadius: "0.25rem",
        background: gray,

        "&.control-points": {
          background: color,
        },
      },
    },
  };
});

export const css = {
  title: {
    fontSize: "1.25rem",
    color: (theme: Theme) => theme.palette.text.primary,
  },
  expandText: {
    position: "absolute",
    bottom: 0,
    right: 0,
    color: (theme: Theme) => theme.palette.main.gray,
    cursor: "pointer",
    lineHeight: "1.6rem",
  },
  legendContainer: {
    fontSize: "1rem",
    gap: "2rem",
    color: (theme: Theme) => theme.palette.main.gray,
  },
  legendItemContainer: {
    display: "flex",
    gap: "0.5rem",
    alignItems: "baseline",
  },
};
