import { Grid } from "@mui/material";
import React from "react";

import { GridWrapper } from "./LayoutMain.styles";

interface ILayoutMainProps {
  left: React.ReactNode;
  center: React.ReactNode;
  right: React.ReactNode;
}
export const LayoutMain = ({ left, center, right }: ILayoutMainProps) => {
  return (
    <GridWrapper container>
      <Grid item className="center" xs={12} lg={4} xl={6}>
        {center}
      </Grid>
      <Grid item className="right" xs={12} lg={4} xl={3}>
        {right}
      </Grid>
      <Grid item className="left" xs={12} lg={4} xl={3}>
        {left}
      </Grid>
    </GridWrapper>
  );
};
