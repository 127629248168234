import { Modal } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { OverlayBlock } from "SRC/components/OverlayBlock";
import { ModalWrapper } from "SRC/pages/Event/common/EventTabs/ObjectTab/ObjectTab.styled";
import { VideosModal } from "SRC/pages/Event/common/EventTabs/ObjectTab/VideosModal/VideosModal";
import { useFilters } from "SRC/redux/slices/event/hooks/useFilters";
import { useObjectVideos } from "SRC/redux/slices/event/hooks/useObjectVideos";
import { actions as objectVideosActions } from "SRC/redux/slices/event/slices/objectVideos";
import {
  IGlobalFilters,
  useGlobalFilters,
} from "SRC/redux/slices/global/hooks/useFilters";

interface IVideosModalWrapperProps {
  isVideoModalOpen: boolean;
  handleVideoModalClose(): void;
}

export const VideosModalWrapper = ({
  isVideoModalOpen,
  handleVideoModalClose,
}: IVideosModalWrapperProps) => {
  const { period }: IGlobalFilters = useGlobalFilters();
  const { fetching, fetched, load, error, items } = useObjectVideos();
  const { selectedObjectId } = useFilters();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!fetching && !fetched && !error && selectedObjectId) {
      load({
        id: selectedObjectId,
        period,
      });
    }
  }, [fetching, fetched, error, selectedObjectId]);

  useEffect(() => {
    dispatch(
      objectVideosActions.get({
        id: selectedObjectId,
        period,
      })
    );
  }, [dispatch, selectedObjectId, period]);

  return (
    <Modal
      open={isVideoModalOpen}
      onClose={handleVideoModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalWrapper>
        <OverlayBlock isFetching={fetching} hasData={Boolean(items.length)}>
          <VideosModal handleVideoModalClose={handleVideoModalClose} />
        </OverlayBlock>
      </ModalWrapper>
    </Modal>
  );
};
