import { PROGRAMS_MODEL_UUID } from "CORE/scheme/olap";
import { getGpIndicators } from "SRC/constants/dataCodes";
import { olapRequestData3 } from "SRC/core/api/core";
import { IPeriod } from "SRC/redux/slices/global/slices/filters";

const GET_SUMMARY = "get-summary";

export const getSummary = (period: IPeriod) => {
  return olapRequestData3(
    [
      {
        requestId: "7d93addc-4f47-4340-8695-08c07c1f2a74",
        type: "data2",
        params: {
          modelUuid: PROGRAMS_MODEL_UUID,
          dashboardUuid: "",
          includeGaps: false,
          dimensions: [
            {
              id: "1562",
              includeItems: true,
              includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
            },
          ],
          indicators: {
            id: "1571",
            items: getGpIndicators(1753, 1796, 1837, 1843, 1844, 5246, 5269),
          },
          dataRequestCaching: true,
          dataFilter: {
            type: "AND",
            version: 1,
            operands: [
              {
                type: "EQ",
                version: 1,
                operands: [
                  {
                    type: "DIM",
                    version: 1,
                    id: "1562",
                  },
                  {
                    type: "CONST",
                    version: 1,
                    values: [period.apiV2],
                  },
                ],
              },
              {
                type: "EQ",
                version: 1,
                operands: [
                  {
                    type: "DIM",
                    version: 2,
                    id: "1565",
                    attributeCode: "Секретная",
                  },
                  {
                    type: "TEXT",
                    version: 2,
                    values: ["0"],
                  },
                ],
              },
            ],
          },
        },
      },
    ],
    GET_SUMMARY
  );
};
export const getSummaryRTK = (period: IPeriod["apiV2"]) => [
  {
    requestId: "7d93addc-4f47-4340-8695-08c07c1f2a74",
    type: "data2",
    params: {
      modelUuid: PROGRAMS_MODEL_UUID,
      dashboardUuid: "",
      includeGaps: false,
      dimensions: [
        {
          id: "1562",
          includeItems: true,
          includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
        },
      ],
      indicators: {
        id: "1571",
        items: getGpIndicators(1753, 1796, 1837, 1843, 1844, 5246, 5269),
      },
      dataRequestCaching: true,
      dataFilter: {
        type: "AND",
        version: 1,
        operands: [
          {
            type: "EQ",
            version: 1,
            operands: [
              {
                type: "DIM",
                version: 1,
                id: "1562",
              },
              {
                type: "CONST",
                version: 1,
                values: [period],
              },
            ],
          },
          {
            type: "EQ",
            version: 1,
            operands: [
              {
                type: "DIM",
                version: 2,
                id: "1565",
                attributeCode: "Секретная",
              },
              {
                type: "TEXT",
                version: 2,
                values: ["0"],
              },
            ],
          },
        ],
      },
    },
  },
];
