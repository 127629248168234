import "react-datepicker/dist/react-datepicker.css";

import { Box, CircularProgress, SxProps, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import { CalendarIcon } from "ASSETS/svg";
import {
  BlueArrowLeft,
  BlueArrowRight,
  SmallWhiteArrowDown,
  SmallWhiteArrowUp,
} from "ASSETS/svg/arrow";
import ruLocale from "date-fns/locale/ru";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import DatePicker, { CalendarContainer } from "react-datepicker";
import { EDateType } from "SRC/constants/globals";
import { getPeriodValue } from "SRC/helpers";
import {
  IGlobalFilters,
  useGlobalFilters,
} from "SRC/redux/slices/global/hooks/useFilters";
import { useGosprogram } from "SRC/redux/slices/gosprogram/hooks";

import { CalendarWrapper, css } from "./Calendar.styled";

const START_DATE = "2022-06-01";

const MIN_DATE = ((date) => {
  const parsedDate = moment(date);
  const year = Number(parsedDate.format("YYYY"));

  return {
    minDate: moment(`${year}-01-01`).toDate(),
    actualMinDate: parsedDate,
    year,
    monthIndex: Number(parsedDate.format("MM")),
  };
})(START_DATE);

interface ICalendarContainerProps {
  className?: string | undefined;
  children?: React.ReactNode | undefined;
}

interface IDatePickerCustomHeaderProps {
  date: Date;
  decreaseYear(): void;
  increaseYear(): void;
  prevYearButtonDisabled?: boolean;
  nextYearButtonDisabled?: boolean;
}

export const Calendar = () => {
  const { period, setSelectedPeriod, isSamePeriod }: IGlobalFilters =
    useGlobalFilters();
  const { loading } = useGosprogram();

  const [value, setValue] = useState<Date | null>(period.value);
  const [filterValue, setFilterValue] = useState("");
  const [calendarView, setCalendarView] = useState<EDateType>(EDateType.YEAR);
  const [dateFormat, setDateFormat] = useState<string>("yyyy");
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleToggleCalendar = useCallback(() => {
    setOpen(!loading && !isOpen);
  }, [isOpen, loading]);

  const handleCalendarClose = () => {
    setOpen(false);
  };

  const setActualValue = (date: Date | null) => {
    let minDate = moment
      .max(moment(START_DATE), moment(date))
      .endOf(calendarView)
      .startOf("day")
      .toDate();

    if (minDate > period.maxDate) {
      minDate = period.maxDate;
    }

    const isEqualDate = moment(value).isSame(minDate);

    if (!isEqualDate) {
      setValue(minDate);
    } else if (getPeriodValue(period.filterValue) !== calendarView) {
      setSelectedPeriod(date, calendarView);
    }
  };

  const onChange = (date: Date) => {
    setActualValue(date);
  };

  const onChangeView = (view: EDateType) => () => {
    setCalendarView(view);
  };

  const isNotSamePeriod = useMemo(() => {
    return filterValue === period.filterValue;
  }, [filterValue, period.filterValue, calendarView]);

  useEffect(() => {
    if (!isSamePeriod(value)) {
      setSelectedPeriod(value, calendarView);
    }
  }, [value, calendarView]);

  useEffect(() => {
    if (isNotSamePeriod) {
      setActualValue(period.value);
      setFilterValue(period.filterValue);
    }

    const periodValue = getPeriodValue(period.filterValue);
    switch (periodValue) {
      case EDateType.YEAR:
        setDateFormat("yyyy");
        break;
      case EDateType.QUARTER:
        setDateFormat("yyyy, QQQQ");
        break;
      default:
        setDateFormat("yyyy, QQQQ, MM");
    }
  }, [period]);

  const DatePickerContainer = ({ children }: ICalendarContainerProps) => {
    return (
      <CalendarWrapper
        isYearView={calendarView === EDateType.YEAR}
        isMonthView={calendarView === EDateType.MONTH}
        minYear={MIN_DATE.year}
        minMonthIndex={MIN_DATE.monthIndex}
      >
        <CalendarContainer>
          <Box sx={css.calendarContainer}>{children}</Box>
          <Box sx={css.buttonsContainer}>
            <Box
              onClick={onChangeView(EDateType.YEAR)}
              sx={css.button(calendarView === EDateType.YEAR) as SxProps}
            >
              Год
            </Box>
            <Box
              onClick={onChangeView(EDateType.QUARTER)}
              sx={css.button(calendarView === EDateType.QUARTER) as SxProps}
            >
              Квартал
            </Box>
            <Box
              onClick={onChangeView(EDateType.MONTH)}
              sx={css.button(calendarView === EDateType.MONTH) as SxProps}
            >
              Месяц
            </Box>
          </Box>
        </CalendarContainer>
      </CalendarWrapper>
    );
  };

  return (
    <Box sx={css.datePickerContainer}>
      <DatePicker
        disabled={loading}
        renderCustomHeader={({
          date,
          decreaseYear,
          increaseYear,
        }: IDatePickerCustomHeaderProps) =>
          calendarView === EDateType.YEAR ? null : (
            <Box sx={css.datePickerHeaderContainer}>
              <Box sx={css.headerBtn} onClick={decreaseYear}>
                <BlueArrowLeft />
              </Box>
              <Typography sx={css.headerText}>
                {moment(date).format("YYYY")}
              </Typography>
              <Box
                sx={css.headerBtn}
                style={{
                  justifyContent: "flex-end",
                }}
                onClick={increaseYear}
              >
                <BlueArrowRight />
              </Box>
            </Box>
          )
        }
        minDate={MIN_DATE.minDate}
        maxDate={period.maxDate}
        autoComplete="off"
        open={isOpen}
        selected={value}
        locale={ruLocale}
        onChange={onChange}
        dateFormat={dateFormat}
        showQuarterYearPicker={calendarView === EDateType.QUARTER}
        showYearPicker={calendarView === EDateType.YEAR}
        showMonthYearPicker={calendarView === EDateType.MONTH}
        showFullMonthYearPicker={calendarView === EDateType.MONTH}
        calendarContainer={DatePickerContainer}
        shouldCloseOnSelect
        portalId="calendar-root-portal"
        popperClassName="calendar-popper"
        onCalendarClose={handleCalendarClose}
        customInput={
          <TextField
            variant="filled"
            helperText={null}
            sx={css.textField}
            InputProps={{
              readOnly: true,
              disabled: loading,
              startAdornment: loading ? (
                <CircularProgress className="calendar-disabled-icon" />
              ) : (
                <CalendarIcon />
              ),
              onClick: handleToggleCalendar,
              endAdornment: isOpen ? (
                <SmallWhiteArrowUp style={css.arrow} />
              ) : (
                <SmallWhiteArrowDown style={css.arrow} />
              ),
            }}
          />
        }
      />
    </Box>
  );
};
