import { Typography } from "@mui/material";
import React, { CSSProperties } from "react";
import { TableProps, UseTableRowProps } from "react-table";
import { Percent } from "SRC/components/Percent/Percent";
import { SlimProgressBar } from "SRC/components/ProgressBar";
import {
  css as tableCss,
  TableCellBigText,
  TableFooterText,
} from "SRC/components/Table/BaseTable/BaseTable.styled";
import { getRoundValue } from "SRC/helpers/getRoundValue";
import { roundNumbersToFixed } from "SRC/helpers/roundNumbersToFixed";
import { css } from "SRC/pages/Summary/common/SummaryComplexGrid/SummaryComplexGrid.styled";

interface CustomTableProps extends TableProps {
  row: UseTableRowProps<any>;
  rows?: UseTableRowProps<any>;
}

const getCashColumns = () => {
  return [
    {
      Header: "План",
      width: css.cashColumn.plan.width,
      accessor: "cashPlan",
      Cell: (tableProps: CustomTableProps) => (
        <TableCellBigText
          align="center"
          sx={css.cashColumn.plan.cellWrapper(tableProps.row.original.cashPlan)}
        >
          {tableProps.row.original.cashPlan}
          <Percent />
        </TableCellBigText>
      ),
      Footer: (tableProps: CustomTableProps) => {
        const total = React.useMemo(() => {
          const rowsArray = Array(tableProps.rows || []).flat();
          const avarenge = rowsArray.reduce(
            (sum: number, row: UseTableRowProps<any>) =>
              row.original.cashPlan + sum,
            0
          );

          return getRoundValue(avarenge / rowsArray.length, 0) || 0;
        }, [tableProps.rows]);

        return (
          <TableFooterText
            sx={css.cashColumn.plan.footerWrapper(Number(total))}
          >
            {total}
            <Percent />
          </TableFooterText>
        );
      },
    },
    {
      Header: "Факт",
      width: css.cashColumn.fact.width,
      accessor: "cashFact",
      Cell: (tableProps: CustomTableProps) => (
        <div style={css.cashColumn.fact.cellWrapper as CSSProperties}>
          <Typography
            sx={tableCss.tableCellText(tableProps.row.original.statusLabel)}
            className="bigText with-right-border cashColumn-fact-cellText"
            align="center"
          >
            {tableProps.row.original.cashFact}
            <Percent />
          </Typography>
          <SlimProgressBar value={tableProps.row.original.cashFact || 0} />
        </div>
      ),
      Footer: (tableProps: CustomTableProps) => {
        const total = React.useMemo(() => {
          const rowsArray = Array(tableProps.rows || []).flat();
          const avarenge = rowsArray.reduce(
            (sum: number, row: UseTableRowProps<any>) =>
              row.original.cashFact + sum,
            0
          );

          return roundNumbersToFixed(avarenge / rowsArray.length, 0) || 0;
        }, [tableProps.rows]);

        return (
          <div style={css.cashColumn.fact.footerWrapper}>
            <Typography
              sx={tableCss.tableCellText()}
              align="center"
              className="with-right-border whiteText tableFooterText"
            >
              {getRoundValue(total, 2)}
              <Percent />
            </Typography>
            <SlimProgressBar value={total} />
          </div>
        );
      },
    },
  ];
};

export { getCashColumns };
