import { IMinistriesTableItem } from "SRC/hooks/summaryTable/interfaces";

import { Wrapper } from "./StructureCount.styled";

interface IMinistriesTableItemProps {
  ministryData: IMinistriesTableItem[];
}

export const StructureCount = ({ ministryData }: IMinistriesTableItemProps) => {
  return (
    <Wrapper>
      <div className="structure-item structure-item-main">
        Всего
        <div>2796</div>
      </div>
      <div className="structure-item">
        ФП
        <div>{ministryData[0]?.structureElementsFP || 0}</div>
      </div>
      <div className="structure-item">
        ВП
        <div>{ministryData[0]?.structureElementsVP || 0}</div>
      </div>
      <div className="structure-item">
        КПМ
        <div>{ministryData[0]?.structureElementsKPM || 0}</div>
      </div>
    </Wrapper>
  );
};
