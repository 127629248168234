import { SerializedError } from "@reduxjs/toolkit";
import { useCallback, useState } from "react";
import { IStat, IStatParams } from "SRC/redux/slices/main/slices/stats";

import { useAppDispatch, useAppSelector } from "../../../hooks";
import actions from "../actions";

export interface IStats {
  fetching: boolean;
  fetched: boolean;
  items: IStat[];
  load(params: Partial<IStatParams>): void;
  error: SerializedError | null;
}

export const useStats = (): IStats => {
  const dispatch = useAppDispatch();
  const {
    fetching,
    items: stats,
    fetched,
  } = useAppSelector((state) => state.main.stats);
  const [error, setError] = useState<SerializedError | null>(null);
  const load = useCallback(
    (params: any) => {
      if (!fetching) {
        dispatch(actions.stats.get(params)).then((action) => {
          if (actions.stats.get.rejected.match(action)) {
            setError(action.error);
          }
          return action;
        });
      }
    },
    [dispatch, fetching]
  );

  return {
    fetching,
    fetched,
    items: stats || [],
    load,
    error,
  };
};
