import { Box, SxProps } from "@mui/material";
import React, { CSSProperties } from "react";
import { TableProps, UseTableRowProps } from "react-table";
import { Percent } from "SRC/components/Percent/Percent";
import { SlimProgressBar } from "SRC/components/ProgressBar";
import {
  TableCellBigText,
  TableCellText,
  TableFooterText,
  TableImage,
} from "SRC/components/Table/BaseTable/BaseTable.styled";
import { getRoundValue } from "SRC/helpers/getRoundValue";
import { roundNumbersToFixed } from "SRC/helpers/roundNumbersToFixed";
import { css } from "SRC/pages/Summary/common/SummaryComplexGrid/SummaryComplexGrid.styled";

interface CustomTableProps extends TableProps {
  row: UseTableRowProps<any>;
  rows?: UseTableRowProps<any>;
}

const getGeneralInfoPremiersColumns = () => {
  return [
    {
      Header: "№",
      accessor: "id",
      width: css.idColumn.width,
      Cell: (tableProps: CustomTableProps) => (
        <div style={css.idColumn.cellWrapper as CSSProperties}>
          <TableCellText align="center" sx={css.idColumn.tableCell}>
            {String(tableProps.row.original.id).padStart(2, "0")}
          </TableCellText>
        </div>
      ),
      Footer: "",
    },
    {
      Header: "",
      accessor: "vicePremierPhoto",
      width: css.iconColumn.width,
      Cell: (tableProps: CustomTableProps) => (
        <div style={css.iconColumn.cellWrapper as CSSProperties}>
          <TableImage
            src={tableProps.row.original.vicePremierPhoto}
            alt="photo"
            style={css.iconColumn.image}
          />
        </div>
      ),
      Footer: "",
    },
    {
      Header: <Box sx={css.nameColumn.headerWrapper}>Вице - премьер</Box>,
      accessor: "vicePremier",
      width: css.nameColumn.width,
      Cell: (tableProps: CustomTableProps) => (
        <TableCellText sx={css.nameColumn.cellWrapper}>
          {tableProps.row.original.vicePremier}
        </TableCellText>
      ),
      Footer: (
        <TableFooterText align="left" sx={css.nameColumn.footerWrapper}>
          Итого
        </TableFooterText>
      ),
    },
    {
      Header: "Кол-во госпрограмм",
      accessor: "govPrograms",
      Cell: (tableProps: CustomTableProps) => (
        <div style={css.cashColumn.fact.cellWrapper as CSSProperties}>
          <TableCellBigText sx={css.foivColumn.cellText as SxProps}>
            {tableProps.row.original.govPrograms}
          </TableCellBigText>
        </div>
      ),
      Footer: (tableProps: CustomTableProps) => {
        const total = React.useMemo(() => {
          const rowsArray = Array(tableProps.rows || []).flat();
          return rowsArray.reduce(
            (sum: number, row: UseTableRowProps<any>) =>
              row.original.govPrograms + sum,
            0
          );
        }, [tableProps.rows]);

        return (
          <div style={css.govProgramsColumn.footerWrapper}>
            <TableFooterText
              sx={css.performanceColumn.footerText(total) as SxProps}
            >
              {`${total}`}
            </TableFooterText>
          </div>
        );
      },
    },
    {
      Header: "Уровень достижения",
      accessor: "performance",
      Cell: (tableProps: CustomTableProps) => (
        <div style={css.performanceColumn.cellWrapper as CSSProperties}>
          <TableCellBigText
            sx={css.performanceColumn.text(tableProps.row.original.performance)}
          >
            {`${tableProps.row.original.performance}`}
            <Percent />
          </TableCellBigText>
          <SlimProgressBar value={tableProps.row.original.performance || 0} />
        </div>
      ),
      Footer: (tableProps: CustomTableProps) => {
        const average = React.useMemo(() => {
          const rowsArray = Array(tableProps.rows || []).flat();
          const total = rowsArray.reduce(
            (sum: number, row: UseTableRowProps<any>) =>
              row.original.performance + sum,
            0
          );
          return roundNumbersToFixed(total / rowsArray.length, 2) || 0;
        }, [tableProps.rows]);

        return (
          <div style={css.govProgramsColumn.footerWrapper}>
            <TableFooterText sx={css.performanceColumn.footerText(average)}>
              {`${getRoundValue(average, 2)}`}
              <Percent />
            </TableFooterText>
            <SlimProgressBar value={average} />
          </div>
        );
      },
    },
    {
      Header: "Удовлетворенность населения",
      accessor: "populationSatisfaction",
      Cell: (tableProps: CustomTableProps) => (
        <div style={css.populationSatisfactionColumn.cellWrapper}>
          <TableCellBigText
            sx={css.populationSatisfactionColumn.cellText(
              tableProps.row.original.populationSatisfaction
            )}
          >
            {`${tableProps.row.original.populationSatisfaction}`}
            <Percent />
          </TableCellBigText>
          <SlimProgressBar
            value={tableProps.row.original.populationSatisfaction || 0}
          />
        </div>
      ),
      Footer: (tableProps: CustomTableProps) => {
        const average = React.useMemo(() => {
          const rowsArray = Array(tableProps.rows || []).flat();
          const total = rowsArray.reduce(
            (sum: number, row: UseTableRowProps<any>) =>
              row.original.populationSatisfaction + sum,
            0
          );

          return roundNumbersToFixed(total / rowsArray.length, 1) || 0;
        }, [tableProps.rows]);

        return (
          <div style={css.populationSatisfactionColumn.footerWrapper}>
            <TableFooterText sx={css.performanceColumn.footerText(average)}>
              {`${getRoundValue(average)}`}
              <Percent />
            </TableFooterText>
            <SlimProgressBar value={average} />
          </div>
        );
      },
    },
  ];
};

export { getGeneralInfoPremiersColumns };
