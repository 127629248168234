import { useMemo } from "react";
import { IGpStructureItem } from "SRC/pages/Program/common/StructureTab/interface";
import {
  createGpStructureTableData,
  structureElementTypesMap,
} from "SRC/pages/Program/utils";
import {
  IGlobalFilters,
  useGlobalFilters,
} from "SRC/redux/slices/global/hooks/useFilters";
import {
  IFilters,
  useFilters,
  useStructureElements,
} from "SRC/redux/slices/gosprogram/hooks";
import { useGpStructure } from "SRC/redux/slices/gosprogram/hooks/useGpStructure";

interface IUseTableData {
  gpStructuresTableData: IGpStructureItem[];
  gpStructuresData: IGpStructureItem[];
  uniqueTypeProjects: string[];
}

export const useGpStructuresTableData = (): IUseTableData => {
  const { items: gpStructures = [] } = useGpStructure();
  const { items: structures } = useStructureElements();
  const { sources, inPerformance }: IGlobalFilters = useGlobalFilters();

  const {
    selectedStatus,
    selectedEventFeature,
    selectedStructureRow,
    selectedStructureElementType,
    selectedStructureElementName,
  }: IFilters = useFilters();

  const structureCodes = useMemo<string[] | undefined>(
    () =>
      structures.find((item) => item.code === selectedStructureRow)?.eventsCode,
    [selectedStructureRow, structures]
  );

  const gpStructuresTableData = useMemo(() => {
    return createGpStructureTableData(gpStructures);
  }, [gpStructures]);

  const gpStructuresData = useMemo(() => {
    return gpStructuresTableData
      .filter((item) => {
        const structureCodeFilter =
          !structureCodes || structureCodes.includes(String(item?.id));
        const structureElementTypeFilter =
          selectedStructureElementType === "Федеральные проекты"
            ? item.type === "ФП"
            : selectedStructureElementType === "Ведомственные проекты"
            ? item.type === "ВП"
            : selectedStructureElementType ===
              "Комплексы процессных мероприятий"
            ? item.type === "КПМ"
            : true;

        const itemInPerformance = sources.OM
          ? Boolean(item.inPerformanceOM)
          : Boolean(item.inPerformance);

        const inPerformanceFilter =
          !inPerformance || (inPerformance && itemInPerformance);

        return (
          structureCodeFilter &&
          structureElementTypeFilter &&
          inPerformanceFilter
        );
      })
      .sort((a, b) => Number(a.id) - Number(b.id))
      .map((item, index) => ({
        ...item,
        index: index + 1,
      }));
  }, [
    gpStructuresTableData,
    structureCodes,
    selectedStatus,
    selectedEventFeature,
    selectedStructureElementType,
    sources.OM,
    inPerformance,
  ]);

  //уникальный список Типов структурных элементов (ФП ВП КПМ)
  const uniqueTypeProjects = useMemo(() => {
    return Array.from(
      new Set(
        gpStructuresTableData
          .filter((item) => {
            return (
              !selectedStructureElementName ||
              selectedStructureElementName === item.name
            );
          })
          .map((item) => {
            return structureElementTypesMap[item.type];
          })
      )
    );
  }, [gpStructuresTableData, selectedStructureElementName]);

  return { gpStructuresTableData, gpStructuresData, uniqueTypeProjects };
};
