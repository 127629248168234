import { styled } from "@mui/material";

export const Wrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  gap: "1rem",
  ".structures-info": {
    display: "flex",
    gap: "1rem",
    " > div": {
      width: "100%",
      padding: "0.75rem 1.5rem 1.5rem 1.5rem",
      gap: "0.75rem",
      backgroundColor: "#2E364A",
      borderRadius: "0.5rem",
    },
  },
  ".structures-count": {
    display: "flex",
    flexDirection: "column",
    flex: "0 0 25%",
  },
  ".structures-events": {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    flex: 1,
  },
  ".structures-header": {
    display: "flex",
    justifyContent: "space-between",
    "> div": {
      display: "flex",
      gap: "0.5rem",
    },
  },
}));
