import { MutableRefObject } from "react";

export const setFocus = (
  htmlElement: MutableRefObject<
    HTMLDivElement | HTMLSpanElement | HTMLInputElement | null
  >
): void => {
  setTimeout((): void => {
    const element: HTMLDivElement | HTMLSpanElement | undefined | null =
      htmlElement?.current;

    if (element) {
      element.tabIndex = -1;
      element.style.outline = "none";
      element.focus({ preventScroll: true });
    }
  });
};

const loremPaths =
  "M0,0 $OW,0 L$OW,0 $OW,$OH L$OW,$OH 0,$OH L0,$OH 0,0" +
  " M0,$RAD C0,$HR $HR,0 $RAD,0 L$RAD,0 0,0 L0,0 0,$RAD" +
  " M$CWwR,0 C$CWwHR,0 $CW,$HR $CW,$RAD L$CW,$RAD $CW,0 L$CW,0 $CWwR,0" +
  " M$CW,$CHwR C$CW,$CHwHR $CWwHR,$CH $CWwR,$CH L$CWwR,$CH $CW,$CH L$CW,$CH $CW,$CHwR" +
  " M$RAD,$CH C$HR,$CH 0,$CHwHR 0,$CHwR L0,$CHwR 0,$CH L0,$CH $RAD,$CH";

export const createRadiusCornersSVGPath = (node: HTMLDivElement): string => {
  const $RAD = 16;
  const $HR = 16 / 2;
  const $CW = node.clientWidth;
  const $CH = node.clientHeight;
  const $OW = node.offsetWidth;
  const $OH = node.offsetHeight;

  const $CWwR = $CW - $RAD;
  const $CHwR = $CH - $RAD;

  const $CWwHR = $CW - $HR;
  const $CHwHR = $CH - $HR;

  const path = loremPaths
    .replaceAll("$RAD", String($RAD))
    .replaceAll("$HR", String($HR))
    .replaceAll("$OW", String($OW))
    .replaceAll("$OH", String($OH))
    .replaceAll("$CWwR", String($CWwR))
    .replaceAll("$CHwR", String($CHwR))
    .replaceAll("$CWwHR", String($CWwHR))
    .replaceAll("$CHwHR", String($CHwHR))
    .replaceAll("$CW", String($CW))
    .replaceAll("$CH", String($CH));

  return path;
};
