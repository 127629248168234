import { Box } from "@mui/material";
import { TooltipText } from "SRC/components/TooltipText/TooltipText";
import { IDataProgramGoal } from "SRC/pages/Program/common/GoalsTab/common/NationalGoalsConnect/NationalGoalsConnect";

import { css } from "./ProgramGoalItem.styled";

interface IProgramGoalItemProps {
  item: IDataProgramGoal;
  active: boolean;
  onClick(): void;
}

export const ProgramGoalItem = ({
  item,
  active,
  onClick,
}: IProgramGoalItemProps) => (
  <Box id={`programItem-${item.id}`} sx={css.item} onClick={onClick}>
    <Box sx={css.name(active)}>
      <TooltipText hint={item.title} variant="h2" lines={2}>
        {item.title}
      </TooltipText>
    </Box>
    <Box sx={css.unit}>{item.unit}</Box>
    <Box sx={css.point(active)} />
  </Box>
);
