import { Tooltip } from "@mui/material";
import React from "react";
import { CheckSecretData } from "SRC/components/CheckSecretData";
import { TableCell } from "SRC/components/TableCell";
import { sortIndicatorsPlanColumn } from "SRC/helpers";
import { ICustomTableProps } from "SRC/types";

import { getIndicatorsPlan, getIndicatorsPlanTotal } from "./utils";

export const indicatorsPlan = (isOM: boolean) => ({
  Header: "План",
  sortType: sortIndicatorsPlanColumn(isOM),
  width: 130,
  accessor: isOM ? "indicatorsOMPlan" : "indicatorsPlan",
  Cell: (tableProps: ICustomTableProps) => {
    const { indicatorsPlan, isSecret } = getIndicatorsPlan(tableProps, isOM);

    return (
      <TableCell color={isOM ? "blue" : "white"}>
        <CheckSecretData isSecret={isSecret}>{indicatorsPlan}</CheckSecretData>
      </TableCell>
    );
  },
  Footer: (tableProps: ICustomTableProps) => {
    const { indicatorsPlanTotal } = getIndicatorsPlanTotal(tableProps, isOM);

    return (
      <Tooltip title="В расчете участвуют уникальные элементы">
        <TableCell color={isOM ? "blue" : "white"}>
          {indicatorsPlanTotal}
        </TableCell>
      </Tooltip>
    );
  },
});
