import { ESources } from "SRC/constants";
import { ICashExecution } from "SRC/redux/slices/event/slices/cashExecution";
import { ICodeItem } from "SRC/types";

export interface IStepsData {
  stepActive: number;
  stepsInfo: IStepsInfo[];
}

export interface IStepsInfo {
  datePlan: string;
  dateFinish: string;
  namePoint: string;
  statusPoint: string;
  SORT_ORDER?: string | number;
  deviation?: number | string;
  deviationColor?: string;
}

export interface IDataCodeHeader {
  NAME: string;
  SHORT_NAME: string;
  SORT_ORDER: string;
  code: string;
  data: ICodeItem[];
}

export interface IDataHeader extends ICashExecution {}

export interface IHeaderData {
  code: string;
  name: string;
  headerIndicators: IHeaderIndicators[];
  realization: {
    total: number;
    done: number;
    notDone: number;
    noData: number;
  };
  atRisk: number;
}

export interface IHeaderIndicators {
  indicator: string;
  value: string | number;
}

export type TSourcedValue = Record<ESources, number | undefined>;

export interface IDataSideBar {
  performance: TSourcedValue;
  corrections: {
    value: {
      worse: number | undefined;
      better: number | undefined;
    };
    time: {
      worse: number | undefined;
      better: number | undefined;
    };
  };
  event: {
    plan: number | undefined;
    fact: TSourcedValue;
    forecast: number | undefined;
    atRisk: number;
  };
  realization: {
    plan: string | number | undefined;
    fact: string | number | undefined;
  };
  financing: {
    plan: number | undefined;
    fact: number | undefined;
  };
}

export interface IDataCheckpoints {
  COMMENT: string;
  NAME: string;
  R_ID: string;
  SORT_ORDER: string;
  code: string;
  data: ICodeItem[];
}

export interface ISubjectItem {
  NAME: string;
  REGION_CODE: string;
  URL: string;
  code: string;
  data: ICodeItem[];
}

export interface IRelatedIndicatorsDataItem {
  id: number | string;
  indicatorName: string;
  performance: number | string;
  effect: number | string;
}

export interface IObjectDataDataItem {
  sum: string | number;
  code: string;
}

export interface IObjectItemData {
  NAME: string;
  R_ID: string;
  code: string;
  data: IObjectDataDataItem[];
  Денежный: string;
  "Ед. измерения бюджета": string;
  "Ед. измерения результата": string;
  "Отвественеый исполнитель": string;
  "Тип мероприятия": string;
  "Характеристика результата": string;
}

export interface IObject {
  NAME: string;
  R_ID: string;
  code: string;
  data: IObjectItemData[];
  "Дата начала": string;
  "Дата окончания": string;
  "Код ОКС": string;
  "Местонахождение объекта": string;
  Мощность: string;
  SORT_ORDER: string;
}

export enum EFootView {
  POINTS,
  REASON,
  CORRECTION,
}
