import { styled } from "@mui/material";

export const Wrapper = styled("div")(() => ({
  display: "flex",
  width: "100%",
  gap: "1rem",
  "& .title": {
    fontWeight: 400,
    fontSize: "1.2rem",
    lineHeight: "1.5rem",
  },
  "& .value": {
    display: "flex",
    gap: "0.25rem",
    alignItems: "baseline",
    fontWeight: 400,
    fontSize: "1.5rem",
    lineHeight: "2.5rem",
    "& span": {
      color: "rgba(255, 255, 255, 0.5)",
      fontSize: "1rem",
    },
  },
  "& .section": {
    padding: "1rem 1.5rem 1.2rem 1.5rem",
    backgroundColor: "#2E364A",
    borderRadius: "1rem",
  },
  "& > .section-left": {
    flexDirection: "column",
    flex: "0 0 25%",
  },
  "& .section-right": {
    display: "flex",
    flex: 1,
    gap: "2.5rem",
  },
  "& .rows-container": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    flex: "0 0 25%",
    height: "100%",
  },
  "& .row": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "0.5rem",
  },
  "& .info-container": {
    display: "flex",
    justifyContent: "space-between",
    flex: 1,
    gap: "0.5rem",
    "& > .row": {
      flex: 1,
      padding: "1.5rem 1.2rem",
      borderRadius: "1rem",
      background: "#262D3E",
    },
  },
}));
