import { isContainedArray } from "SRC/helpers/dataTesters";
import { selectors, useAppSelector } from "SRC/redux/hooks";
import { ICodeItem } from "SRC/types";

import { gpbApi } from "../api";

export interface ISummary {
  data: ICodeItem[];
  code: string;
  PERIOD_CODE?: string;
}

export interface ISummaryProps {
  fetching: boolean;
  data: ISummary;
  error: boolean;
}

export const useSummary = (): ISummaryProps => {
  const { period } = useAppSelector(selectors.globalFilters);

  const { data, isFetching, isError } = gpbApi.useGetProgramsSummaryQuery(
    period.apiV2
  );
  const summary = isContainedArray(data) ? data[0] : ({} as ISummary);

  return {
    fetching: isFetching,
    data: summary,
    error: isError,
  };
};
