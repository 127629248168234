import { createSlice } from "@reduxjs/toolkit";

export interface INotification {
  open: boolean;
  message: string;
  error: string;
  // isCanceled: boolean;
}

const initialState: INotification = {
  open: false,
  message: "",
  error: "",
  // isCanceled: false,
};

const slice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    clear: () => initialState,
    open: (state, action: { payload: boolean }) => {
      state.open = action.payload;
    },
    message: (state, action: { payload: string }) => {
      state.message = action.payload;
    },
    error: (state, action: { payload: string }) => {
      state.error = action.payload;
    },
    //реализация прекращения загрузки
    // isCanceled: (state, action: { payload: boolean }) => {
    //   state.isCanceled = action.payload;
    // },
  },
});

export default slice;
