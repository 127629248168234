import { getDataSumByCodeVP } from "SRC/constants";
import { IPremierTableItem } from "SRC/hooks/summaryTable/interfaces";
import { generalRowsMainTableData } from "SRC/hooks/summaryTable/utils/utils";
import { TPremier } from "SRC/redux/slices/main/hooks/usePremiers";
import { CustomRowProps } from "SRC/types/data/data";

export const createPremierRow = (data: TPremier): IPremierTableItem => {
  const { info } = data;
  const elem = { ...data, data: Object.values(data.data) };

  return {
    id: info.VP_CODE,
    isPremier: true,
    code: info.VP_CODE,
    name: info.VP_NAME,
    vicePremier: info.VP_NAME,
    vicePremierPhoto: info.VP_PHOTO_URL,
    govPrograms: getDataSumByCodeVP(elem, 1742) || 0,

    //Показатели
    indicatorsPlan: getDataSumByCodeVP(elem, 1699) || 0,
    indicatorsPlanTotal: getDataSumByCodeVP(elem, 1702) || 0,
    indicatorsOMPlan: getDataSumByCodeVP(elem, 5392) || 0,
    indicatorsOMPlanTotal: getDataSumByCodeVP(elem, 5455) || 0,

    indicatorsFact: getDataSumByCodeVP(elem, 1700) || 0,
    indicatorsFactTotal: getDataSumByCodeVP(elem, 1703) || 0,
    indicatorsOMFact: getDataSumByCodeVP(elem, 5391) || 0,
    indicatorsOMFactTotal: getDataSumByCodeVP(elem, 5456) || 0,

    //Мероприятия
    eventsPlan: getDataSumByCodeVP(elem, 1705) || 0,
    eventsPlanTotal: getDataSumByCodeVP(elem, 1708) || 0,
    eventsOMPlanTotal: getDataSumByCodeVP(elem, 1708) || 0,
    eventsOMPlan: getDataSumByCodeVP(elem, 5394) || 0,

    eventsFact: getDataSumByCodeVP(elem, 1706) || 0,
    eventsFactTotal: getDataSumByCodeVP(elem, 1709) || 0,
    eventsOMFactTotal: getDataSumByCodeVP(elem, 5462) || 0,
    eventsOMFact: getDataSumByCodeVP(elem, 5393) || 0,

    eventsRisk: getDataSumByCodeVP(elem, 1749) || 0,
    eventsRiskTotal: getDataSumByCodeVP(elem, 5461) || 0,
    // TODO: Дублирование данных
    eventsOMRiskTotal: getDataSumByCodeVP(elem, 5461) || 0,
    // TODO: Дублирование данных
    eventsOMRisk: getDataSumByCodeVP(elem, 1749) || 0,

    //УД
    performance: getDataSumByCodeVP(elem, 1797) || 0,
    performanceOM: getDataSumByCodeVP(elem, 5266) || 0,
    performanceTotal: getDataSumByCodeVP(elem, 5248) || 0,
    performanceOMTotal: getDataSumByCodeVP(elem, 5272) || 0,
  };
};

export const createPremiersTableData = (
  data: TPremier[]
): IPremierTableItem[] => data.map((elem: TPremier) => createPremierRow(elem));

export const createPremierRowsTableData = (
  data: CustomRowProps[],
  isOM: boolean
) =>
  data.map((row: CustomRowProps) => {
    return {
      id: row.original.id,
      name: row.original.name,
      vicePremierPhoto: row.original.vicePremierPhoto,
      numOfPrograms: row.original.subRows?.length,
      ...generalRowsMainTableData(row, isOM),
    };
  });
