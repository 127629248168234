import { Modal } from "@mui/material";
import React from "react";

import { IncidentModal } from "./components/IncidentModal/IncidentModal";

interface IIncidentModalWrapperProps {
  isIncidentModalOpen: boolean;
  handleIncidentModalClose(): void;
  handleScreenshot: () => void;
}

export const IncidentModalContainer = ({
  isIncidentModalOpen,
  handleIncidentModalClose,
  handleScreenshot,
}: IIncidentModalWrapperProps) => {
  return (
    <Modal
      open={isIncidentModalOpen}
      onClose={handleIncidentModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <>
        <IncidentModal
          handleIncidentModalClose={handleIncidentModalClose}
          handleScreenshot={handleScreenshot}
        />
      </>
    </Modal>
  );
};
