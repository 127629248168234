import React, { useEffect, useMemo } from "react";
import { useParams } from "react-router";
import Breadcrumbs from "SRC/components/Breadcrumbs/Breadcrumbs";
import { Card } from "SRC/layouts";
import { useEvent } from "SRC/redux/slices/event/hooks/useEvent";
import { useEventData } from "SRC/redux/slices/event/hooks/useEventData";
import { useFilters } from "SRC/redux/slices/event/hooks/useFilters";
import {
  IGlobalFilters,
  useGlobalFilters,
} from "SRC/redux/slices/global/hooks/useFilters";
import { useGosprogram, useProgram } from "SRC/redux/slices/gosprogram/hooks";
import { useStats } from "SRC/redux/slices/main/hooks/useStats";
import { PROGRAM_VIEW, PROGRAMS, setParamsToUrl } from "SRC/urls";

import { Description } from "./common/Description/Description";
import { EventTabs } from "./common/EventTabs/EventTabs";
import { Footer } from "./common/Footer/Footer";
import { Header } from "./common/Header/Header";
import { Sidebar } from "./common/Sidebar/Sidebar";

interface IProps {
  modalId?: string | number | null;
  close?(): void;
}

export const Event: React.FC<IProps> = ({ modalId, close }) => {
  const { eventId, programId = "" } = useParams();
  const { init } = useGosprogram();
  const { fetching, items: program } = useProgram();
  const { period }: IGlobalFilters = useGlobalFilters();
  const { load, fetching: statsFetching } = useStats();

  const { items: data } = useEventData();
  const { setSelectedEventId, clear } = useFilters();

  useEvent();

  useEffect(() => {
    if (modalId) {
      setSelectedEventId(modalId);
    } else if (eventId) {
      setSelectedEventId(eventId);
    } else {
      clear();
    }
  }, [eventId, modalId]);

  useEffect(() => {
    if (programId && !fetching) {
      init({ programId });
    }
  }, [programId]);

  useEffect(() => {
    if (programId && !statsFetching) {
      load({ gp_code: programId ?? null, period });
    }
  }, [programId, period]);

  useEffect(() => () => clear(), []);

  const links = useMemo(
    () => [
      {
        title: "Госпрограммы",
        link: PROGRAMS,
      },
      {
        title: program?.gp_name || "",
        link: setParamsToUrl(PROGRAM_VIEW, { programId }),
      },
      {
        title: data?.info.name || "",
      },
    ],
    [program, programId, data?.info.name]
  );

  return (
    <>
      {!Boolean(modalId) && <Breadcrumbs links={links} />}
      <Card
        head={<Header />}
        description={<Description />}
        summary={<Sidebar />}
        content={<EventTabs />}
        foot={<Footer />}
      />
    </>
  );
};
