import { ICustomTableProps } from "SRC/types";

export const getIndicatorsFailTotal = (
  tableProps: ICustomTableProps,
  isOM: boolean
) => {
  let { indicatorsFailTotal, indicatorsOMFailTotal } =
    tableProps?.rows?.[0]?.original || {};

  return {
    indicatorsFailTotal: isOM ? indicatorsOMFailTotal : indicatorsFailTotal,
  };
};
