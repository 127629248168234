import { actions as data } from "./slices/data";
import { actions as graph } from "./slices/graph";

export interface IIndicatorGPActions {
  data: typeof data;
  graph: typeof graph;
}

const actions: IIndicatorGPActions = {
  data,
  graph,
};

export default actions;
