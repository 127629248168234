import * as htmlToImage from "html-to-image";
import React, { useEffect, useRef, useState } from "react";
import { CreateIncidentBtn } from "SRC/components/CreateIncidentBtn";
import { IncidentModalContainer } from "SRC/components/IncidentModalContainer";

import { Wrapper } from "./IncidentReport.styled";

type TIncidentReportProps = {
  children: React.ReactNode;
  className?: string;
  onClose?: () => void;
  open?: boolean;
  handleOpen?: () => void;
};

export const IncidentReport = ({
  children,
  className = "",
  onClose: onCloseCallback,
  open: isTooltipOpen,
  handleOpen,
}: TIncidentReportProps) => {
  const [isIncidentModalOpen, setIncidentModalOpen] = useState(false);

  const elementRef = useRef<any>(null);
  const handleScreenshot = async () => {
    const dataUrl = await htmlToImage.toPng(elementRef.current);
    const link = document.createElement("a");
    link.download = "html-to-img.png";
    link.href = dataUrl;
    link.click();
  };

  const handleIncidentModalClose = () => {
    if (onCloseCallback) {
      onCloseCallback();
    }
    setIncidentModalOpen(false);
  };
  const handleIncidentModalOpen = (
    e?: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e?.stopPropagation();
    setIncidentModalOpen(true);
  };
  useEffect(() => {
    if (isIncidentModalOpen && onCloseCallback) {
      onCloseCallback();
    }
    if (isIncidentModalOpen && handleOpen) {
      handleOpen();
    }
    if (isIncidentModalOpen && !isTooltipOpen) {
      handleIncidentModalOpen();
    }
  }, [isIncidentModalOpen, isTooltipOpen]);

  return (
    <Wrapper className={className} ref={elementRef}>
      <CreateIncidentBtn handleIncidentModalOpen={handleIncidentModalOpen} />
      {children}
      <IncidentModalContainer
        handleScreenshot={handleScreenshot}
        isIncidentModalOpen={isIncidentModalOpen}
        handleIncidentModalClose={handleIncidentModalClose}
      />
    </Wrapper>
  );
};
