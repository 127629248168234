import { SerializedError } from "@reduxjs/toolkit";
import { useCallback, useEffect } from "react";
import { useGlobalFilters } from "SRC/redux/slices/global/hooks/useFilters";
import { useMinistries } from "SRC/redux/slices/ministry/hooks/useMinistries";
import { usePrograms } from "SRC/redux/slices/ministry/hooks/usePrograms";

export interface IMinistryState {
  loading: boolean;
  loaded: boolean;
  init(): void;
  hasError: SerializedError | null;
}

export const useMinistry = (): IMinistryState => {
  const { period } = useGlobalFilters();
  const {
    fetching: loadingPrograms,
    load: loadPrograms,
    fetched: loadedPrograms,
    error: errorPrograms,
  } = usePrograms();
  const {
    fetching: loadingMinistries,
    load: loadMinistries,
    fetched: loadedMinistries,
    error: errorMinistries,
  } = useMinistries();

  const loading = loadingPrograms || loadingMinistries;
  const loaded = loadedPrograms || loadedMinistries;
  const hasError = errorPrograms || errorMinistries;

  const init = useCallback(() => {
    if (!loading) {
      loadPrograms(period);
      loadMinistries(period);
    }
  }, [loading, period, loadPrograms, loadMinistries]);

  useEffect(init, [period]);

  return {
    loading,
    loaded,
    init,
    hasError,
  };
};
