export interface IWidgetPrioritiesData {
  performanceGP: number;
  countGP: number;
  performanceKP: number;
  countKP: number;
  performancePercentKP: number;
  doneCountKP: number;
  failCountKP: number;
}

export const widgetPrioritiesData: IWidgetPrioritiesData = {
  performanceGP: 86.45,
  countGP: 37,
  performanceKP: 76.23,
  countKP: 120,
  performancePercentKP: 45.1,
  doneCountKP: 55,
  failCountKP: 65,
};
