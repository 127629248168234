import React, { useMemo } from "react";
import { AtRiskCounter } from "SRC/components/AtRiskCounter/AtRiskCounter";
import { IOPValue, OverlayStackedProgress } from "SRC/components/ProgressBar";
import { StatusLabel } from "SRC/components/StatusLabel/StatusLabel";
import { ESources, sourceSettings } from "SRC/constants";
import { DetailedTooltip } from "SRC/pages/Structure/common/DetailedTooltip/DetailedTooltip";
import { Header } from "SRC/pages/Structure/common/StructureAnalytics/Header/Header";
import { StructureCount } from "SRC/pages/Structure/common/StructureCount/StructureCount";

import { Wrapper } from "./StructureAnalytics.styled";

export const StructureAnalytics = () => {
  const data: Record<string, IOPValue[]> = useMemo(
    () => ({
      success: [
        {
          value: 10,
          settings: sourceSettings[ESources.FOIV],
        },
      ].filter(Boolean) as IOPValue[],
      fail: [
        {
          value: 23,
          settings: sourceSettings[ESources.FOIV],
        },
      ].filter(Boolean) as IOPValue[],
    }),
    []
  );

  const widgetStatuses = {
    indicators: [
      <StatusLabel name="Достигнуто" label="done" className="status-name" />,
      <StatusLabel name="Не достигнуто" label="fail" className="status-name" />,
    ],
    events: [
      <StatusLabel name="Достигнуто" label="done" className="status-name" />,
      <StatusLabel name="Не достигнуто" label="fail" className="status-name" />,
      <StatusLabel name="Под риском" label="risk" className="status-name" />,
    ],
  };
  return (
    <Wrapper>
      <div className="widget count">
        <Header title="Количество СЭ" unit="ед." />
        <StructureCount name="Всего" value={500} className="border structure" />
        <div className="instances">
          <StructureCount name="ФП" value={534} />
          <StructureCount name="ВП" value={231} />
          <StructureCount name="КПМ" value={164} />
        </div>
      </div>
      <div className="widget indicators">
        <Header
          title="Показатели"
          unit="ед."
          statuses={widgetStatuses.indicators}
        />
        <OverlayStackedProgress
          className="border progress"
          failValues={data.success}
          successValues={data.fail}
          total={240}
          title="Всего"
        />
        <div className="progress-lines">
          <OverlayStackedProgress
            failValues={data.success}
            successValues={data.fail}
            total={240}
            title="ВП"
            titleValue={<DetailedTooltip data={data} />}
          />
          <OverlayStackedProgress
            failValues={data.success}
            successValues={data.fail}
            total={240}
            title="ФП"
          />
          <OverlayStackedProgress
            failValues={data.success}
            successValues={data.fail}
            total={240}
            title="КПМ"
          />
        </div>
      </div>
      <div className="widget events">
        <Header
          title="Мероприятия"
          unit="ед."
          statuses={widgetStatuses.events}
        />
        <OverlayStackedProgress
          failValues={data.success}
          successValues={data.fail}
          total={240}
          title="Всего"
          titleValue={<AtRiskCounter value={52} />}
          className="border progress"
        />
        <div className="progress-lines">
          <OverlayStackedProgress
            failValues={data.success}
            successValues={data.fail}
            total={240}
            title="ВП"
            titleValue={<AtRiskCounter value={52} />}
          />
          <OverlayStackedProgress
            failValues={data.success}
            successValues={data.fail}
            total={240}
            title="ФП"
            titleValue={<AtRiskCounter value={52} />}
          />
          <OverlayStackedProgress
            failValues={data.success}
            successValues={data.fail}
            total={240}
            title="КПМ"
            titleValue={<AtRiskCounter value={52} />}
          />
        </div>
      </div>
    </Wrapper>
  );
};
