import { Theme } from "@mui/material";
import { EChartsOption, graphic } from "echarts";
import { roundNumbersToFixed } from "SRC/helpers/roundNumbersToFixed";

export const getOption = (
  theme: Theme,
  codes: string[],
  values: number[],
  actives: string[],
  selected: string,
  isOM: boolean
): EChartsOption => ({
  title: {},
  grid: {
    containLabel: true,
    show: true,
    borderWidth: 0,
    width: "99%",
    left: "0",
    bottom: "0",
  },
  responsive: true,
  tooltip: {
    show: false,
  },
  legend: {
    show: false,
  },
  xAxis: {
    data: codes,
    boundaryGap: false,
    splitLine: {
      show: true,
      lineStyle: {
        // type: "line",
        color: new graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0,
            color: theme.palette.charts.opacityGray,
          },
          {
            offset: 0.37,
            color: theme.palette.charts.lightBlue,
          },
          {
            offset: 0.64,
            color: theme.palette.charts.lightBlue,
          },
          {
            offset: 1,
            color: theme.palette.charts.opacityGray,
          },
        ]),
      },
    },
    axisLabel: {
      inside: false,
    },
    axisLine: {
      show: false,
    },
    axisTick: {
      show: false,
    },
  },
  yAxis: [
    {
      type: "value",
      min: 0,
      max: 100,
      splitLine: {
        show: true,
        lineStyle: {
          opacity: 0.1,
          type: [5, 10],
        },
      },
      axisLabel: {
        inside: false,
        margin: 30,
      },
    },
  ],
  series: [
    {
      type: "scatter",
      symbol: "roundRect",
      symbolSize: (value: number) => 12 + roundNumbersToFixed(value / 10, 0),
      data: values,
      itemStyle: {
        color: ({ name, value }) => {
          if (selected === name) {
            return theme.palette.charts.white;
          } else if (isOM && value === 100) {
            return theme.palette.pale.paleGreen;
          } else {
            return value === 100
              ? actives.includes(name)
                ? theme.palette.charts.green
                : theme.palette.charts.greenTransparent
              : actives.includes(name)
              ? theme.palette.main.gray
              : theme.palette.charts.grayBlue;
          }
        },
      },
      label: {
        position: "top",
        show: true,
        color: theme.palette.primary.contrastText,
        fontSize: 8,
        distance: 25,
      },
    },
    {
      data: values,
      type: "scatter",
      symbol: "triangle",
      symbolRotate: 180,
      symbolOffset: [0, -20],
      symbolSize: 6,
      symbolKeepAspect: false,
      itemStyle: {
        color: theme.palette.primary.contrastText,
      },
      label: {
        show: false,
      },
      tooltip: {
        show: false,
      },
    },
  ],
});
