import { Theme } from "@mui/material";

export const css = {
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    flex: "0 0 53%",
    flexWrap: "nowrap",
    alignItems: "center",
    padding: "1.5rem 1.5rem",
    backgroundColor: (theme: Theme) =>
      theme.palette.complementary.darkBlueLight,
    borderRadius: "1rem",
  },
  infoWidgetContainer: (theme: Theme) => ({
    background: theme.palette.complementary.darkBlue,
    borderRadius: "2.5rem",
    width: "100%",
    maxWidth: theme.breakpoints.values.md,
    margin: "auto",
    position: "relative",
    padding: "1.5rem 2rem",
    gap: "2rem",
    display: "flex",
    flexDirection: "column",
  }),
  programIcon: (theme: Theme) => ({
    width: "3rem",
    height: "3rem",
    position: "absolute",
    left: "-1.75rem",
    top: "1.75rem",
    background: theme.palette.background.default,
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& img": {
      width: "2.125rem",
      height: "2.125rem",
    },
  }),
  bodyInfoWidget: {
    display: "flex",
    gap: "1rem",
  },
};
