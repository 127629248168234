import { InputAdornment, InputLabel, TextField } from "@mui/material";

import { Wrapper } from "./IncidentDescription.styled";

interface IIncidentDescriptionProps {
  descriptionValue: string;
  onChangeDescriptionValue: (e: any) => void;
}

const MAX_DESCRIPTION_LENGTH = 5000;

export const IncidentDescription = ({
  descriptionValue,
  onChangeDescriptionValue,
}: IIncidentDescriptionProps) => {
  return (
    <Wrapper>
      <InputLabel>Описание</InputLabel>
      <TextField
        placeholder="Введите описание"
        error={descriptionValue.length > MAX_DESCRIPTION_LENGTH}
        helperText={
          descriptionValue.length > MAX_DESCRIPTION_LENGTH
            ? "Превышено ограничение в 5000 символов"
            : null
        }
        value={descriptionValue}
        onChange={onChangeDescriptionValue}
        multiline
        rows={3}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {descriptionValue.length}/ {MAX_DESCRIPTION_LENGTH}
            </InputAdornment>
          ),
        }}
      />
    </Wrapper>
  );
};
