import { Tooltip } from "@mui/material";
import { TooltipProps } from "@mui/material/Tooltip/Tooltip";
import React from "react";
import { CheckSecretData } from "SRC/components/CheckSecretData";
import { TableCell } from "SRC/components/TableCell";
import { ETitle } from "SRC/components/TableColumnTemplates/columns/indicators/indicatorsFact";
import { sortStructureElementsFactColumn } from "SRC/helpers";
import { ICustomTableProps } from "SRC/types";

import {
  getStructureElementsFact,
  getStructureElementsFactTotal,
} from "./utils";

export const structureElementsFact = (isOM: boolean) => ({
  Header: "Факт",
  Tooltip: ({ children, ...props }: TooltipProps) => (
    <Tooltip {...props} title={ETitle.FACT_TITLE}>
      {children}
    </Tooltip>
  ),
  sortType: sortStructureElementsFactColumn(isOM),
  width: 130,
  accessor: isOM ? "structureElementsOMFact" : "structureElementsFact",
  Cell: (tableProps: ICustomTableProps) => {
    const { structureElementsFact, isSecret } = getStructureElementsFact(
      tableProps,
      isOM
    );

    return (
      <TableCell color={isOM ? "paleGreen" : "green"}>
        <CheckSecretData isSecret={isSecret}>
          {structureElementsFact}
        </CheckSecretData>
      </TableCell>
    );
  },
  Footer: (tableProps: ICustomTableProps) => {
    const { structureElementsFactTotal } = getStructureElementsFactTotal(
      tableProps,
      isOM
    );

    return (
      <Tooltip title="В расчете участвуют уникальные элементы">
        <TableCell color={isOM ? "paleGreen" : "green"}>
          {structureElementsFactTotal}
        </TableCell>
      </Tooltip>
    );
  },
});
