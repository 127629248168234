import { styled, Theme } from "@mui/material";

interface IWrapper {
  isDisabled: boolean;
  specialOpinion?: boolean;
}

export const Wrapper = styled("div")<IWrapper>(
  ({ isDisabled, specialOpinion, theme }: IWrapper & { theme: Theme }) => {
    const FONT = {
      fontSize: "1rem",
      lineHeight: "1.2rem",
    };

    const {
      background: { headerControl, headerControlActive },
      primary: { contrastText },
      complementary: { unitsGray },
      text: { primary, opinion },
    } = theme.palette;

    const getStyles = (disabledState: boolean, specialOpinion?: boolean) => {
      return disabledState
        ? {
            pointerEvents: "none",
            color: unitsGray,
            cursor: "default",
          }
        : {
            pointerEvents: "auto",
            color: specialOpinion ? opinion : contrastText,
            cursor: "pointer",
          };
    };

    return {
      display: "flex",
      position: "relative",
      alignItems: "center",
      userSelect: "none",

      "> span": {
        ...FONT,
        ...getStyles(isDisabled),
      },

      "& .MuiFormControlLabel-root": {
        marginLeft: "0",
        marginRight: "0",

        "> .MuiFormControlLabel-label": {
          ...FONT,
          ...getStyles(isDisabled, specialOpinion),
        },

        "& .Mui-disabled": {
          ...getStyles(isDisabled),
        },
      },

      "&.white": {
        ".MuiSwitch-switchBase": {
          "+ .MuiSwitch-track": {
            backgroundColor: headerControl,
            opacity: "1",
          },

          "&.Mui-checked": {
            color: contrastText,

            "+ .MuiSwitch-track": {
              backgroundColor: headerControlActive,
              opacity: "1",
            },
          },
        },
      },
    };
  }
);
