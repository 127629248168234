import { Tooltip } from "@mui/material";
import { TooltipProps } from "@mui/material/Tooltip/Tooltip";
import React from "react";
import { CheckSecretData } from "SRC/components/CheckSecretData";
import { NavigateCell } from "SRC/components/Table";
import { TableCell } from "SRC/components/TableCell";
import { ETitle } from "SRC/components/TableColumnTemplates/columns/indicators/indicatorsFact";
import { sortIndicatorsFailColumn } from "SRC/helpers";
import { ICustomTableProps } from "SRC/types";

import { getIndicatorsFail, getIndicatorsFailTotal } from "./utils";

export const indicatorsFail = (isOM: boolean) => ({
  Header: "Не выполнено",
  Tooltip: ({ children, ...props }: TooltipProps) => (
    <Tooltip {...props} title={ETitle.FAIL_TITLE}>
      {children}
    </Tooltip>
  ),
  sortType: sortIndicatorsFailColumn(isOM),
  width: 130,
  accessor: isOM ? "indicatorsOMFail" : "indicatorsFail",
  Cell: (tableProps: ICustomTableProps) => {
    const { url, indicatorsFail, isSecret } = getIndicatorsFail(
      tableProps,
      isOM
    );

    return (
      <NavigateCell url={url}>
        <TableCell color={isOM ? "paleRed" : "red"}>
          <CheckSecretData isSecret={isSecret}>
            {indicatorsFail}
          </CheckSecretData>
        </TableCell>
      </NavigateCell>
    );
  },
  Footer: (tableProps: ICustomTableProps) => {
    const { indicatorsFailTotal } = getIndicatorsFailTotal(tableProps, isOM);

    return (
      <Tooltip title="В расчете участвуют уникальные элементы">
        <TableCell color={isOM ? "paleRed" : "red"}>
          {indicatorsFailTotal}
        </TableCell>
      </Tooltip>
    );
  },
});
