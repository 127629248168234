import { CircularProgress } from "@mui/material";
import React from "react";
import { Overlay } from "SRC/components/Overlay";

interface IOverlayBlock {
  isFetching: boolean;
  hasData: boolean;
  noData?: React.ReactNode;
  children?: JSX.Element;
}

export const OverlayBlock = ({
  isFetching,
  hasData,
  noData = "Нет данных",
  children,
}: IOverlayBlock) => {
  if (isFetching) {
    return (
      <Overlay>
        <CircularProgress />
      </Overlay>
    );
  }

  return <>{hasData ? children : <Overlay>{noData}</Overlay>}</>;
};
