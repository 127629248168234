import { Box, Button, useMediaQuery, useTheme } from "@mui/material";
import React, { CSSProperties } from "react";
import { OpenInFull } from "SRC/assets/svg/index";
import TableExportButton from "SRC/components/Buttons/TableExportButton";
import { IExportData } from "SRC/types";

import { css } from "./TableActionsButtons.styled";

interface ITableActionsButtonsProps<DD> extends IExportData<DD> {
  setOpen?: (value: boolean) => void;
  open?: boolean;
  buttonsStyle?: CSSProperties;
}

export const TableActionsButtons = <T extends object>({
  setOpen,
  open,
  csvData,
  fileName,
  wscols,
  headings,
  mergeCells,
  buttonsStyle,
  infoRows,
}: ITableActionsButtonsProps<T>) => {
  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <Box sx={{ ...css.buttonsContainer, ...buttonsStyle }}>
      <TableExportButton
        csvData={csvData}
        fileName={fileName}
        wscols={wscols}
        headings={headings}
        mergeCells={mergeCells}
        infoRows={infoRows}
      />
      {!match && !open && (
        <Button sx={css.button} onClick={() => setOpen?.(true)}>
          <OpenInFull />
        </Button>
      )}
    </Box>
  );
};
