import { TIndicatorMap } from "../types";

export const PROGRAM_INDICATORS: TIndicatorMap = {
  1547: {
    id: "1547",
    desc: "Уровень достижения",
    aggregationFunction: "sum",
  },
  1548: {
    id: "1548",
    desc: "Уровень достижения, цвет",
    stringAggregation: true,
    separator: ";",
  },
  1562: {
    id: "1562",
    desc: "Период",
  },
  1565: {
    id: "1565",
    desc: "Госпрограмма",
  },
  1571: {
    id: "1571",
    desc: "Показатели",
  },
  1572: {
    id: "1572",
    desc: "СЭ, план",
    aggregationFunction: "sum",
  },
  1573: {
    id: "1573",
    desc: "СЭ, выполнено",
    aggregationFunction: "sum",
  },
  1574: {
    id: "1574",
    desc: "СЭ, невыполнено",
    aggregationFunction: "sum",
  },
  1575: {
    id: "1575",
    desc: "СЭ, план, итого",
    aggregationFunction: "sum",
  },
  1576: {
    id: "1576",
    desc: "СЭ, выполнено, итого",
    aggregationFunction: "sum",
  },
  1577: {
    id: "1577",
    desc: "СЭ ОМ, невыполнено, итого",
    aggregationFunction: "sum",
  },
  1578: {
    id: "1578",
    desc: "Показателей, план",
    aggregationFunction: "sum",
  },
  1579: {
    id: "1579",
    desc: "Показателей, выполнено",
    aggregationFunction: "sum",
  },
  1580: {
    id: "1580",
    desc: "Показателей, невыполнено",
    aggregationFunction: "sum",
  },
  1581: {
    id: "1581",
    desc: "Показателей, план, итого",
    aggregationFunction: "sum",
  },
  1582: {
    id: "1582",
    desc: "Показателей, выполнено, итого",
    aggregationFunction: "sum",
  },
  1583: {
    id: "1583",
    desc: "Показателей, невыполнено, итого",
    aggregationFunction: "sum",
  },
  1584: {
    id: "1584",
    desc: "Мероприятий, план",
    aggregationFunction: "sum",
  },
  1585: {
    id: "1585",
    desc: "Мероприятий, выполнено",
    aggregationFunction: "sum",
  },
  1586: {
    id: "1586",
    desc: "Мероприятий, невыполнено",
    aggregationFunction: "sum",
  },
  1587: {
    id: "1587",
    desc: "Мероприятий, план, итого",
    aggregationFunction: "sum",
  },
  1588: {
    id: "1588",
    desc: "Мероприятий, выполнено, итого",
    aggregationFunction: "sum",
  },
  1589: {
    id: "1589",
    desc: "Мероприятий, невыполнено, итого",
    aggregationFunction: "sum",
  },
  1590: {
    id: "1590",
    desc: "Касса, план",
    aggregationFunction: "sum",
  },
  1591: {
    id: "1591",
    desc: "Касса, факт, доля",
    aggregationFunction: "sum",
  },
  1592: {
    id: "1592",
    desc: "Касса, цвет",
    stringAggregation: true,
    separator: ";",
  },
  1593: {
    id: "1593",
    desc: "Касса, план, итого",
    aggregationFunction: "sum",
  },
  1594: {
    id: "1594",
    desc: "Касса, факт, доля, итого",
    aggregationFunction: "sum",
  },
  1595: {
    id: "1595",
    desc: "Касса, цвет, итого",
    stringAggregation: true,
    separator: ";",
  },
  1596: {
    id: "1596",
    desc: "ОКС, план",
    aggregationFunction: "sum",
  },
  1597: {
    id: "1597",
    desc: "ОКС, выполнено, доля",
    aggregationFunction: "sum",
  },
  1598: {
    id: "1598",
    desc: "ОКС, план, итого",
    aggregationFunction: "sum",
  },
  1599: {
    id: "1599",
    desc: "ОКС, выполнено, доля, итого",
    aggregationFunction: "sum",
  },
  1600: {
    id: "1600",
    desc: "НПА, план",
    aggregationFunction: "sum",
  },
  1601: {
    id: "1601",
    desc: "НПА, выполнено",
    aggregationFunction: "sum",
  },
  1602: {
    id: "1602",
    desc: "НПА, невыполнено",
    aggregationFunction: "sum",
  },
  1603: {
    id: "1603",
    desc: "НПА, план, итого",
    aggregationFunction: "sum",
  },
  1604: {
    id: "1604",
    desc: "НПА, выполнено, итого",
    aggregationFunction: "sum",
  },
  1605: {
    id: "1605",
    desc: "НПА, невыполнено, итого",
    aggregationFunction: "sum",
  },
  1652: {
    id: "1652",
    desc: "ОКС, цвет, итого",
    stringAggregation: true,
    separator: ";",
  },
  1653: {
    id: "1653",
    desc: "ОКС, цвет",
    stringAggregation: true,
    separator: ";",
  },
  1678: {
    id: "1678",
    desc: "Уровень достижения ФП",
    aggregationFunction: "sum",
  },
  1679: {
    id: "1679",
    desc: "Мероприятия, факт",
    aggregationFunction: "sum",
  },
  1680: {
    id: "1680",
    desc: "Мероприятия, под риском",
    aggregationFunction: "sum",
  },
  1681: {
    id: "1681",
    desc: "Мероприятия, УД",
    aggregationFunction: "sum",
  },
  1730: {
    id: "1730",
    desc: "Уровень достижения, общий",
    aggregationFunction: "sum",
  },
  1731: {
    id: "1731",
    desc: "Уровень достижения, общий, цвет",
    stringAggregation: true,
    separator: ";",
  },
  1753: {
    id: "1753",
    desc: "Уровень достижения",
    aggregationFunction: "sum",
  },
  1754: {
    id: "1754",
    desc: "Касса, факт",
    aggregationFunction: "sum",
  },
  1755: {
    id: "1755",
    desc: "Цели, план",
    aggregationFunction: "sum",
  },
  1756: {
    id: "1756",
    desc: "Цели, выполнено",
    aggregationFunction: "sum",
  },
  1757: {
    id: "1757",
    desc: "Цели, невыполнено",
    aggregationFunction: "sum",
  },
  1769: {
    id: "1769",
    desc: "Список целей",
    stringAggregation: true,
    separator: ";",
  },
  1796: {
    id: "1796",
    desc: "Уровень достижения 2",
    aggregationFunction: "sum",
  },
  1834: {
    id: "1834",
    desc: "",
    aggregationFunction: "sum",
  },
  1837: {
    id: "1837",
    desc: "Количество ГП",
    aggregationFunction: "sum",
  },
  1838: {
    id: "1838",
    desc: "",
    aggregationFunction: "sum",
  },
  1839: {
    id: "1839",
    desc: "",
    aggregationFunction: "sum",
  },
  1843: {
    id: "1843",
    desc: "ГП под риском",
    aggregationFunction: "sum",
  },
  1844: {
    id: "1844",
    desc: "Секретные ГП",
    aggregationFunction: "sum",
  },
  5207: {
    id: "5207",
    desc: "Контрольные точки, план",
    aggregationFunction: "sum",
  },
  5204: {
    id: "5204",
    desc: "Показатели СЭ, план",
    aggregationFunction: "sum",
  },
  5208: {
    id: "5208",
    desc: "Контрольные точки, факт",
    aggregationFunction: "sum",
  },
  5209: {
    id: "5209",
    desc: "Контрольные точки, невыполнено",
    aggregationFunction: "sum",
  },
  5246: {
    id: "5246",
    desc: "Уровень достижения 2, общий",
    aggregationFunction: "sum",
  },
  5250: {
    id: "5250",
    desc: "Контрольные точки, план, итого",
    aggregationFunction: "sum",
  },
  5251: {
    id: "5251",
    desc: "Контрольные точки, факт, итого",
    aggregationFunction: "sum",
  },
  5252: {
    id: "5252",
    desc: "Контрольные точки, невыполнено, итого",
    aggregationFunction: "sum",
  },
  5265: {
    id: "5265",
    desc: "Уровень достижения особое мнение",
    aggregationFunction: "sum",
  },
  5269: {
    id: "5269",
    desc: "Уровень достижения особое мнение итого",
    aggregationFunction: "sum",
  },
  5270: {
    id: "5270",
    desc: "Уровень достижения особое мнение итого цвет",
    aggregationFunction: "sum",
  },
  5278: {
    id: "5278",
    desc: "СЭ ОМ, план",
    aggregationFunction: "sum",
  },
  5279: {
    id: "5279",
    desc: "СЭ ОМ, выполнено",
    aggregationFunction: "sum",
  },
  5280: {
    id: "5280",
    desc: "СЭ ОМ, невыполнено",
    aggregationFunction: "sum",
  },
  5281: {
    id: "5281",
    desc: "СЭ ОМ, план, итого",
    aggregationFunction: "sum",
  },
  5282: {
    id: "5282",
    desc: "СЭ ОМ, выполнено, итого",
    aggregationFunction: "sum",
  },
  5283: {
    id: "5283",
    desc: "Показателей ГП ОМ, план",
    aggregationFunction: "sum",
  },
  5284: {
    id: "5284",
    desc: "Показателей ГП ОМ, выполнено",
    aggregationFunction: "sum",
  },
  5285: {
    id: "5285",
    desc: "Показателей ГП ОМ, не выполнено",
    aggregationFunction: "sum",
  },
  5286: {
    id: "5286",
    desc: "Показателей ГП ОМ, план, итого",
    aggregationFunction: "sum",
  },
  5287: {
    id: "5287",
    desc: "Показателей ГП ОМ, выполнено, итого",
    aggregationFunction: "sum",
  },
  5288: {
    id: "5288",
    desc: "Показателей ГП ОМ, невыполнено, итого",
    aggregationFunction: "sum",
  },
  5289: {
    id: "5289",
    desc: "Мероприятий ОМ, план",
    aggregationFunction: "sum",
  },
  5290: {
    id: "5290",
    desc: "Мероприятий ОМ, выполнено",
    aggregationFunction: "sum",
  },
  5291: {
    id: "5291",
    desc: "Мероприятий ОМ, невыполнено",
    aggregationFunction: "sum",
  },
  5292: {
    id: "5292",
    desc: "Мероприятий ОМ, план, итого",
    aggregationFunction: "sum",
  },
  5293: {
    id: "5293",
    desc: "Мероприятий ОМ, выполнено, итого",
    aggregationFunction: "sum",
  },
  5294: {
    id: "5294",
    desc: "Мероприятия ОМ, невыполнено, итого",
    aggregationFunction: "sum",
  },
  5295: {
    id: "5295",
    desc: "Показатели СЭ, План Итого",
    aggregationFunction: "sum",
  },
  5296: {
    id: "5296",
    desc: "Показатели СЭ, Факт",
    aggregationFunction: "sum",
  },
  5297: {
    id: "5297",
    desc: "Показатели СЭ, Факт, Итого",
    aggregationFunction: "sum",
  },
  5298: {
    id: "5298",
    desc: "Показатели СЭ, Не выполнено",
    aggregationFunction: "sum",
  },
  5299: {
    id: "5299",
    desc: "Показатели СЭ, Не выполнено, Итого",
    aggregationFunction: "sum",
  },
  5306: {
    id: "5306",
    desc: "Мероприятия, под риском, итого",
    aggregationFunction: "sum",
  },
  5384: {
    id: "5384",
    desc: "Уровень достижения ФОИВ",
    aggregationFunction: "sum",
  },
  5385: {
    id: "5385",
    desc: "Уровень достижения ОМ",
    aggregationFunction: "sum",
  },
  5386: {
    id: "5386",
    desc: "Касса %",
    aggregationFunction: "sum",
  },
  5479: {
    id: "5479",
    desc: "СЭ, невыполнено, итого",
    aggregationFunction: "sum",
  },
  5480: {
    id: "5480",
    desc: "Контрольные точки, ОМ, Факт",
    aggregationFunction: "sum",
  },
  5481: {
    id: "5481",
    desc: "Контрольные точки, ОМ, Факт, итого",
    aggregationFunction: "sum",
  },
  5482: {
    id: "5482",
    desc: "Контрольные точки, ОМ, План",
    aggregationFunction: "sum",
  },
  5483: {
    id: "5483",
    desc: "Контрольные точки, ОМ, План, итого",
    aggregationFunction: "sum",
  },
  5484: {
    id: "5484",
    desc: "Контрольные точки, ОМ, Не выполнено",
    aggregationFunction: "sum",
  },
  5485: {
    id: "5485",
    desc: "Контрольные точки, ОМ, Не выполнено, итого",
    aggregationFunction: "sum",
  },
  5492: {
    id: "5492",
    desc: "Показатели СЭ, ОМ, План",
    aggregationFunction: "sum",
  },
  5493: {
    id: "52493",
    desc: "Показатели СЭ, ОМ, План Итого",
    aggregationFunction: "sum",
  },
  5494: {
    id: "5494",
    desc: "Показатели СЭ, ОМ, Факт",
    aggregationFunction: "sum",
  },
  5495: {
    id: "5495",
    desc: "Показатели СЭ, ОМ, Факт, Итого",
    aggregationFunction: "sum",
  },
  5496: {
    id: "5496",
    desc: "Показатели СЭ, ОМ, Не выполнено",
    aggregationFunction: "sum",
  },
  5497: {
    id: "5497",
    desc: "Показатели СЭ, ОМ, Не выполнено, Итого",
    aggregationFunction: "sum",
  },
  5523: {
    id: "5523",
    desc: "Участвует в УД ФОИВ",
    aggregationFunction: "sum",
  },
  5524: {
    id: "5524",
    desc: "Участвует в УД ОМ",
    aggregationFunction: "sum",
  },
};
