import { styled } from "@mui/material";

export const RiskWrapper = styled("div")(({ theme }) => {
  const {
    primary: { contrastText },
    complementary: { lightWhite },
  } = theme.palette;

  return {
    gap: "3rem",
    flex: "1",
    display: "flex",
    flexDirection: "column",

    "&.is-secret": {
      alignItems: "center",
      flex: "auto",
    },

    ".item-container": {
      gap: "1.5rem",
      display: "flex",
      flexDirection: "column",
    },
    ".title": {
      gap: "0.5rem",
      display: "flex",
      alignItems: "center",
      fontSize: "1.25rem",
      fontWeight: 400,
      color: contrastText,
    },
    ".unit-title": {
      fontSize: "0.875rem",
      color: lightWhite,
    },
  };
});
