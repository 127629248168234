import { Theme } from "@mui/material";

export const getOption = (
  value: number | string,
  total: number | string,
  withDarkBgr: boolean | undefined,
  chartSmall: boolean | undefined,
  theme: Theme,
  forAchievements: boolean | undefined
) => ({
  grid: {
    borderWidth: 0,
    width: "100%",
    height: "100%",
    left: "0",
    bottom: "0",
  },
  tooltip: {
    show: false,
  },
  series: [
    {
      type: "pie",
      color: "green",
      radius: withDarkBgr
        ? ["90%", "95%"]
        : chartSmall
        ? ["70%", "80%"]
        : ["92%", "98%"],
      avoidLabelOverlap: false,
      label: {
        show: false,
        position: "center",
      },
      emphasis: {
        scale: false,
        color: theme.palette.charts.white,
      },
      labelLine: {
        show: false,
      },
      emptyCircleStyle: true,
      data: [
        {
          value: Number(value),
          itemStyle: {
            color: forAchievements
              ? theme.palette.pale.green
              : theme.palette.pale.blue,
            borderRadius: theme.shape.borderRadius,
          },
        },
        {
          value: Number(total) - Number(value),
          itemStyle: {
            color: forAchievements
              ? theme.palette.pale.red
              : withDarkBgr
              ? theme.palette.background.progressDarkBlue
              : theme.palette.main.darkBlue,
          },
        },
      ],
    },
  ],
});
