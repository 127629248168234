import { WhiteArrowLeft, WhiteArrowRight } from "ASSETS/svg/arrow";
import React from "react";
import { Wrapper } from "SRC/components/CarouselNew/CarouselButton/CarouselButton.styled";

interface ICarouselButtonProps {
  variant: "prev" | "next";
  onClick: () => void;
}

export const CarouselButton = ({ variant, onClick }: ICarouselButtonProps) => {
  return (
    <Wrapper className={variant} onClick={onClick}>
      {variant === "prev" && <WhiteArrowLeft className="arrow" />}
      {variant === "next" && <WhiteArrowRight className="arrow arrow-right" />}
    </Wrapper>
  );
};
