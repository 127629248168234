import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IPeriod } from "SRC/redux/slices/global/slices/filters";

import { getPremiersMain } from "../api/getPremiersMain";

export enum EDataPremier {
  REGION = "Код региона",
  NUMBER_OF_GP = "Количество ГП",
  NON_SECRET_GP = "Количество несекретных ГП",
  EVENTS_PLAN = "Мероприятий, всего",
  EVENTS_PLAN_OM = "Мероприятий, всего, ОМ",
  EVENTS_FACT = "Мероприятий, выполнено",
  EVENTS_FACT_OM = "Мероприятий, выполнено, ОМ",
  EVENTS_RISK = "Мероприятий, под риском",
  UNDER_CONSTRUCTION = "Объектов строится",
  OBJECTS_PLAN = "Объекты. План на период",
  OBJECTS_FACT = "Объекты. Факт на период",
  PERIOD_OBJECT = "Период объектов",
  TREND = "Тренд",
  INDICATORS_GP = "Показателей ГП",
  INDICATORS_GP_FACT = "Показателей ГП, Выполнено",
  INDICATORS_GP_OM = "Показателей ГП, ОМ",
  INDICATORS_GP_FACT_OM = "Показателей ГП, ОМ, Выполено",
  PERFORMANCE_TOTAL = "Уровень достижения",
  PERFORMANCE = "Уровень достижения 2",
  PERFORMANCE_COLOR = "Уровень достижения 2, Цвет",
  PERFORMANCE_OM = "Уровень достижения ОМ",
  PERFORMANCE_OM_COLOR = "Уровень достижения ОМ, Цвет",
  PERFORMANCE_TOTAL_COLOR = "Уровень достижения, Цвет",
  TYPE_OF_GP = "Типы ГП",
}

export type TDataPremier = {
  [EDataPremier.REGION]: number;
  [EDataPremier.NUMBER_OF_GP]: number;
  [EDataPremier.NON_SECRET_GP]: number;
  [EDataPremier.EVENTS_PLAN]: number;
  [EDataPremier.EVENTS_PLAN_OM]: number;
  [EDataPremier.EVENTS_FACT]: number;
  [EDataPremier.EVENTS_RISK]: number;
  [EDataPremier.EVENTS_FACT_OM]: number;
  [EDataPremier.UNDER_CONSTRUCTION]: number;
  [EDataPremier.OBJECTS_PLAN]: number;
  [EDataPremier.OBJECTS_FACT]: number;
  [EDataPremier.PERIOD_OBJECT]: number;
  [EDataPremier.TREND]: string;
  [EDataPremier.INDICATORS_GP]: number;
  [EDataPremier.INDICATORS_GP_FACT]: number;
  [EDataPremier.INDICATORS_GP_OM]: number;
  [EDataPremier.INDICATORS_GP_FACT_OM]: number;
  [EDataPremier.PERFORMANCE_TOTAL]: number;
  [EDataPremier.PERFORMANCE]: number;
  [EDataPremier.PERFORMANCE_COLOR]: string;
  [EDataPremier.PERFORMANCE_OM]: number;
  [EDataPremier.PERFORMANCE_OM_COLOR]: string;
  [EDataPremier.PERFORMANCE_TOTAL_COLOR]: string;
};

export type TTypesOFGP = "Комплексная" | "Отраслевая" | "Секретная" | "Пилот";

export interface IMainPremier {
  GP_ARR: string[];
  NT_ARR: string[];
  VP_CODE: string;
  VP_NAME: string;
  VP_PHOTO_URL: string;
  VP_POST: string;
  VP_SHORT_NAME: string;
  data: TDataPremier;
  [EDataPremier.TYPE_OF_GP]: Record<TTypesOFGP, number>;
}
export interface IMainPremiersState {
  items: IMainPremier[];
  elems: IMainPremier[];
  fetching: boolean;
  fetched: boolean;
  error: string;
}

export const extraActions = {
  get: createAsyncThunk(
    "mainPremiers/get",
    async (period: IPeriod) => await getPremiersMain(period)
  ),
};

const initialState: IMainPremiersState = {
  items: [],
  elems: [],
  fetching: false,
  fetched: false,
  error: "",
};

export const slice = createSlice({
  name: "mainPremiers",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActions.get.pending, (state) => {
        state.items = [];
        state.fetching = true;
        state.fetched = false;
      })
      .addCase(
        extraActions.get.fulfilled,
        (state: IMainPremiersState, action) => {
          const { data } = action.payload.data;

          state.items = data as unknown as IMainPremier[];
          state.fetching = false;
          state.fetched = true;
        }
      )
      .addCase(extraActions.get.rejected, (state, action) => {
        state.fetching = false;
      });
  },
});

export const actions = {
  ...slice.actions,
  ...extraActions,
};
