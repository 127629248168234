import { Theme } from "@mui/material";

const icon = {
  svg: {
    width: "1rem",
    height: "1rem",
  },
};

export const css = {
  wrapper: (theme: Theme) => ({
    flexDirection: "row",
    width: "100%",
    paddingTop: "1rem",
    gap: "1rem",

    "> .titles-wrapper": {
      flex: "1 1 auto",
      flexDirection: "column",
      gap: "1rem",

      ".title": {
        display: "flex",
        flexDirection: "row",
        gap: "0.5rem",
        alignItems: "center",
      },
      ".risk": {
        display: "flex",
        alignItems: "center",
      },
      ".risk svg": {
        width: "2rem",
        height: "2rem",
      },
    },

    "& .actions": {
      display: "flex",
      flexWrap: "nowrap",
      flex: "0 0 auto",
      gap: "1rem",

      "& > .close": {
        maxWidth: "2.5rem !important",
        minWidth: "2.5rem !important",
        height: "2.5rem",
        ...icon,
      },

      "& > .buttonsContainer": {
        position: "relative",
        flex: "0 0 auto",
        height: "2.5rem",
        width: "fit-content",
        padding: "0.25rem",
        background: theme.palette.background.paper,
        borderRadius: "0.5rem",

        "& > button": {
          flex: "0 0 auto",
          height: "2rem",
          padding: "0.5rem 1rem 0.5rem 1rem !important",
          fontWeight: "normal",
          fontSize: "1rem",
          textTransform: "none",
          color: "text.onAccent",
          borderRadius: "0.5rem",
        },
      },
    },
  }),
};
