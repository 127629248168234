import { styled, Theme } from "@mui/material";
import smallPointsLine from "ASSETS/svg/line/smallPointsLine.svg";

interface IGoalWrapperProps {
  isLastChunkStyle: boolean | 0;
  sourcesDataPerformance: number;
  isOM: boolean;
}
export const Wrapper = styled("div")<IGoalWrapperProps>(
  ({ theme, isLastChunkStyle, sourcesDataPerformance, isOM }) => {
    const {
      complementary: {
        darkRedTransparent: darkRed,
        darkViolet,
        darkGreenTransparent: darkGreen,
        darkBlueTransparent,
        grayGreen,
        grayBlue,
        darkBlue,
        darkBlueLight,
      },
      pale: { paleGreen, paleRed, gray },
      background: { defaultTransparent },
      primary: { contrastText },
    } = theme.palette;
    const redGradient = `linear-gradient(180deg, ${darkRed} 0%, ${darkViolet} 100%)`;
    const greenGradient = `linear-gradient(180deg, ${darkGreen} 0%, ${grayGreen} 100%)`;
    const paleGreenGradient = `linear-gradient(180deg, ${paleGreen} 0%, ${grayGreen} 100%)`;
    const paleRedGradient = `linear-gradient(180deg, ${paleRed} 0%, ${darkViolet} 100%)`;
    const redColor = isOM ? paleRedGradient : redGradient;
    const greenColor = isOM ? paleGreenGradient : greenGradient;
    const backgroundColor =
      sourcesDataPerformance < 100 ? redColor : greenColor;

    return {
      position: "relative",
      flex: isLastChunkStyle ? 0 : 1,
      minWidth: "calc(50% - 1rem)",
      height: "16.85rem",

      "@media(min-width: 1400px)": {
        minWidth: isLastChunkStyle ? "calc(33.5% - 1rem)" : "calc(33% - 1rem)",
        flex: isLastChunkStyle ? 0 : 1,
      },

      [theme.breakpoints.up("lg")]: {
        minWidth: "calc(50% - 1rem)",
        flex: isLastChunkStyle ? 0 : 1,
      },

      [theme.breakpoints.up("xl")]: {
        minWidth: isLastChunkStyle ? "calc(33.5% - 1rem)" : "calc(33% - 1rem)",
        flex: isLastChunkStyle ? 0 : 1,
        maxHeight: "350px",
      },

      "& > .item-container": {
        display: "flex",
        position: "absolute",
        width: "100%",
        height: "100%",
        backfaceVisibility: "hidden",
        borderRadius: "1rem",
        background: darkBlue,
        boxShadow: `0px 40px 20px -20px ${defaultTransparent}`,
        transition: "transform 0.5s linear",

        "& > .indicator-container": {
          display: "flex",
          flexDirection: "column",
          flex: 1,
          padding: "1.25rem 1.5rem 1.5rem 1.5rem",

          [theme.breakpoints.up("xl")]: {
            padding: "1rem 1.5rem",
            overflow: "hidden",
          },

          "& > .indicator-header": {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "0.5rem",

            "& > .indicator-title": {
              display: "flex",
              alignItems: "center",
              gap: "1rem",
              marginBottom: 0,
              marginRight: "0.62rem",
              fontSize: "1rem",
              lineHeight: "1.25rem",
              color: gray,

              [theme.breakpoints.up("xl")]: {
                fontSize: "1rem",
                lineHeight: "1rem",
              },

              "& > .indicator-label": {
                padding: "0.25rem 0.6rem",
                borderRadius: "1rem",
                color: contrastText,
                background: darkBlue,
              },
            },

            "& > .dots-line": {
              height: "4px",
              width: "33%",
              background: `url(${smallPointsLine}) repeat`,
            },
          },

          "& > .carousel-container": {
            height: "100%",
          },
        },

        "& > .progress-container": {
          position: "relative",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          flex: "0 0 40%",
          padding: "1.5rem",
          background: backgroundColor,
          borderTopLeftRadius: "1rem",
          borderBottomLeftRadius: "1rem",
          boxShadow: `10px 0px 15px 0px ${darkBlueLight}`,

          "& > .progress-title": {
            zIndex: 1,

            ".small-text": {
              fontSize: "1rem",
              lineHeight: "1.25rem",
              color: gray,
            },

            ".main-text": {
              paddingTop: "0.9rem",
              fontSize: "1.25rem",
              lineHeight: "1.5rem",
              color: contrastText,
              whiteSpace: "normal",
            },
          },

          "& > .progress-bgr": {
            position: "absolute",
            width: "100%",
            top: 0,
            left: 0,
            height: `${100 - sourcesDataPerformance}%`,
          },

          "& > .progress-bgr-border": {
            position: "absolute",
            width: "99%",
            height: "100%",
            top: 0,
            right: 0,
            background: darkBlueTransparent,
            borderTopLeftRadius: "1rem",
            borderBottomLeftRadius: "1rem",
          },

          "& > .percent-container": {
            display: "flex",
            gap: "0.5rem",
            zIndex: 1,

            "& > .info-icon": {
              display: "flex",
              alignItems: "center",

              "& svg path": {
                fill: gray,
              },
            },

            "& > .percent": {
              display: "flex",
              marginTop: "0.35rem",

              "& > .percent-big-text": {
                display: "flex",
                fontWeight: 400,
                fontSize: "2.75rem",
                lineHeight: "2.63rem",
                color: contrastText,

                "&.gray-blue": {
                  color: grayBlue,
                  fontSize: "1.5rem",
                  marginTop: "0.35rem",
                },
              },
            },
          },
        },
      },
    };
  }
);
