import { Box, Grid, Typography } from "@mui/material";
import moment from "moment";
import React, { useMemo } from "react";
import EventChart from "SRC/components/Charts/EventChart/EventChart";
import { IncidentReport } from "SRC/components/IncidentReport";
import { OverlayBlock } from "SRC/components/OverlayBlock";
import UnitSelect from "SRC/components/UnitSelect/UnitSelect";
import { getMonthCashData } from "SRC/helpers/getMonthCashData";
import { roundNumbersToFixed } from "SRC/helpers/roundNumbersToFixed";
import { createCashExecutionData } from "SRC/pages/Event/util/utils";
import {
  ICashExecutions,
  useCashExecution,
} from "SRC/redux/slices/event/hooks/useCashExecution";
import { useFilters } from "SRC/redux/slices/event/hooks/useFilters";
import { useGlobalFilters } from "SRC/redux/slices/global/hooks/useFilters";

import { Wrapper } from "./CashTab.styled";

export interface ICashItem {
  month: string;
  plan: number | null;
  fact: number | null;
}

export enum ETypeCashData {
  PLAN = "plan",
  FACT = "fact",
}

const digits: Record<string, number> = {
  млрд: 1000000,
  млн: 1000,
  тыс: 1,
};

const convertByDigit = (value?: number | null, unit: string = "") => {
  if (value) {
    return roundNumbersToFixed((value * 1000000) / (digits[unit] || 1), 2);
  } else {
    return null;
  }
};

const currentMonth = moment().format("MM");
const currentYear = String(moment().year());
const previousYear = String(moment().year() - 1);
const LAST_MONTH_INDEX = "12";

export const CashTab = () => {
  const { items: cashExecution, fetching }: ICashExecutions =
    useCashExecution();

  const { selectedFinanceUnit } = useFilters();
  const { period } = useGlobalFilters();

  const cashExecutionData = useMemo(
    () => createCashExecutionData(cashExecution),
    [cashExecution]
  );

  const sortedCash = useMemo<ICashItem[]>(
    () =>
      cashExecutionData.map((item): ICashItem => {
        return {
          month: item.pointOrder,
          plan: convertByDigit(item.plan, selectedFinanceUnit),
          fact: convertByDigit(item.fact, selectedFinanceUnit),
        };
      }),
    [cashExecutionData, selectedFinanceUnit]
  );

  const cashExec: string[] = useMemo(() => {
    const cashDate = period.reportValue.slice(0, 2);
    let cashValue = cashExecutionData
      .map((item) => ({
        fact: item.plan,
        cash: item.cash,
        date: item.pointCode,
      }))
      .filter((item) => {
        if (period.reportValue === currentYear) {
          return item.date === currentMonth;
        } else if (period.reportValue === previousYear) {
          return item.date === LAST_MONTH_INDEX;
        }
        return cashDate === item.date;
      });

    return String(roundNumbersToFixed(cashValue[0]?.cash, 2) || 0).split(".");
  }, [cashExecutionData, period.reportValue]);

  const planData = useMemo<number[]>(() => {
    return getMonthCashData(sortedCash, ETypeCashData.PLAN);
  }, [sortedCash]);

  const factData = useMemo<number[]>(() => {
    return getMonthCashData(sortedCash, ETypeCashData.FACT);
  }, [sortedCash, period]);

  return (
    <Wrapper>
      <IncidentReport className="incident-container">
        <OverlayBlock
          isFetching={fetching}
          hasData={Boolean(factData && planData)}
        >
          <Box className="cash-tab-wrapper">
            <Grid
              container
              className="cash-tab-container"
              justifyContent="space-between"
            >
              <Grid item className="legend-container">
                <Typography className="main-text">
                  Кассовое исполнение {cashExec[0]}
                  {cashExec[1] && `,${cashExec[1]}`} %
                </Typography>
                <UnitSelect />
              </Grid>

              <Grid item className="legend-container">
                <Grid item className="legend-item-container center">
                  <Box className="legend-item white" />
                  <p>Кассовый план</p>
                </Grid>
                <Grid item className="legend-item-container center">
                  <Box className="legend-item blue" />
                  <p>Факт</p>
                </Grid>
                <Grid item className="legend-item-container">
                  <Box className="styled-point green" />
                  <p>Достижение плана</p>
                </Grid>
                <Grid item className="legend-item-container">
                  <Box className="styled-point red" />
                  <p>Недостижение плана</p>
                </Grid>
              </Grid>
            </Grid>
            <EventChart planData={planData} factData={factData} />
          </Box>
        </OverlayBlock>
      </IncidentReport>
    </Wrapper>
  );
};
