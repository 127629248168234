import { Stack } from "@mui/material";
import React from "react";
import { useMemo } from "react";
import { NavigateBackButton } from "SRC/components/NavigateBackButton/NavigateBackButton";
import { OverlayBlock } from "SRC/components/OverlayBlock";
import { TooltipText } from "SRC/components/TooltipText/TooltipText";
import { useIndicatorData } from "SRC/redux/slices/indicator/hooks/useIndicatorData";

import { css } from "./Header.styled";

export const Header: React.FC = () => {
  const { items: data, fetching: loading } = useIndicatorData();

  const { name, type, task } = useMemo(() => {
    return {
      name: data?.description.name || "Название индикатора",
      type: data?.task.seTaskType || "Тип",
      task: data?.task.name || "Задача",
    };
  }, [data]);

  return (
    <OverlayBlock isFetching={loading} hasData={Boolean(data)}>
      <Stack sx={css.wrapper}>
        <Stack className="titles-wrapper">
          <div className="title">
            <NavigateBackButton className="back-button" />
            <TooltipText hint={name} variant="h2">
              {name}
            </TooltipText>
          </div>
          <div className="title">
            <span className="type">{type}</span>
            <TooltipText hint={task} variant="h3">
              {task}
            </TooltipText>
          </div>
        </Stack>
      </Stack>
    </OverlayBlock>
  );
};
