import { Box, Paper, SxProps } from "@mui/material";
import { IFilters, useFilters } from "SRC/redux/slices/main/hooks/useFilters";
import {
  IPrograms,
  usePrograms,
} from "SRC/redux/slices/main/hooks/usePrograms";

import { css } from "./index.styled";

interface IProgramsCounterProps {
  tableDataLength?: number;
  totalDataLength?: number;
  isBig?: boolean;
}

const ProgramsCounter = ({
  tableDataLength,
  totalDataLength,
  isBig,
}: IProgramsCounterProps) => {
  const { program, clear, filteredPrograms }: IFilters = useFilters();
  const { items }: IPrograms = usePrograms();

  return (
    <Paper sx={css.indicatorValue(isBig) as SxProps} onClick={clear}>
      {tableDataLength || (program ? 1 : filteredPrograms.length)}
      <Box component="span" sx={css.indicatorTotal}>
        /{totalDataLength || items.length}
      </Box>
    </Paper>
  );
};

export default ProgramsCounter;
