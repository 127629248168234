import classnames from "classnames";
import React from "react";
import { StatusWrapper } from "SRC/pages/Event/common/Checkpoints/Status/Status.styled";
import { IStepsInfo } from "SRC/pages/Event/interfaces";
import { EColors, selectClassNames } from "SRC/pages/Event/util/utils";

interface IStatus {
  data?: IStepsInfo[];
  value: string;
  className?: string;
}

const statusColor = {
  [EColors.GREEN]: "Выполнено",
  [EColors.RED]: "Не выполнено",
  [EColors.GRAY]: "В реализации",
  [EColors.DARK]: "Не представлена отчетность",
};

const selectText = (value: string) => {
  return statusColor[value as keyof typeof statusColor];
};

const Status = ({ data, value, className }: IStatus) => {
  return (
    <StatusWrapper className={className}>
      <div className={classnames("circle", selectClassNames(value))} />
      <p>{selectText(value)}</p>
      {data && (
        <p className="white">
          {data.filter((el) => el.statusPoint === value).length}
        </p>
      )}
    </StatusWrapper>
  );
};

export default Status;
