export const isRowSecret = (row: any, subRows: any) => {
  let isSecret;
  if (row.isProgram) {
    isSecret = !!row.secretProgram;
  } else {
    isSecret = subRows.every(({ original }: any) => {
      const { secretProgram = false } = original || {};

      return secretProgram;
    }, false);
  }

  return isSecret;
};
