import { Box } from "@mui/material";
import React from "react";
import { TableProps, UseTableRowProps } from "react-table";
import { TableCell } from "SRC/components/TableCell";

interface CustomTableProps extends TableProps {
  row: UseTableRowProps<any>;
  rows?: UseTableRowProps<any>[];
}

export const indicatorColumns = [
  {
    Header: " ",
    width: 0,
    accessor: "expand-button",
    columns: [
      {
        Header: "",
        width: 0,
        accessor: "expand-button",
        Cell: (tableProps: CustomTableProps) => <Box></Box>,
      },
    ],
  },
  {
    Header: " ",
    width: 150,
    columns: [
      {
        Header: "Госпрограмма",
        accessor: "program",
        Cell: (tableProps: CustomTableProps) => (
          <TableCell color="gray">0</TableCell>
        ),
      },
    ],
  },
  {
    Header: " ",
    width: 100,
    accessor: "typeStructure",
    columns: [
      {
        Header: "Тип СЭ",
        width: 100,
        accessor: "typeStructure",
        Cell: (tableProps: CustomTableProps) => (
          <TableCell color="gray">0</TableCell>
        ),
      },
    ],
  },
  {
    Header: " ",
    width: "50%",
    accessor: "nameStructure",
    columns: [
      {
        Header: "Наименование СЭ",
        width: "50%",
        accessor: "nameStructure",
        Cell: (tableProps: CustomTableProps) => (
          <TableCell color="gray">0</TableCell>
        ),
      },
    ],
  },
  {
    Header: "Количество показателей",
    columns: [
      {
        Header: "Всего",
        accessor: "total",
        width: "auto",
        Cell: (tableProps: CustomTableProps) => (
          <TableCell color="gray">0</TableCell>
        ),
      },
      {
        Header: "Достигнуто",
        accessor: "achieved",
        width: "auto",
        Cell: (tableProps: CustomTableProps) => (
          <TableCell color="gray">0</TableCell>
        ),
      },
      {
        Header: "Не достигнуто",
        accessor: "notAchieved",
        width: "auto",
        Cell: (tableProps: CustomTableProps) => (
          <TableCell color="gray">0</TableCell>
        ),
      },
      {
        Header: "Будущий период",
        accessor: "futurePeriod",
        width: "auto",
        Cell: (tableProps: CustomTableProps) => (
          <TableCell color="gray">0</TableCell>
        ),
      },
    ],
  },
];
