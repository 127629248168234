import { InputLabel } from "@mui/material";
import SelectWithSearchIncidentLevel from "SRC/components/SelectWithSearch/SelectWithSearchIncidentLevel";

import { Wrapper } from "./IncidentLevel.styled";

const optionsLevel = [
  { label: "средний", value: "средний" },
  { label: "высокий", value: "высокий" },
  { label: "критичный", value: "критичный" },
  { label: "блокирующий", value: "блокирующий" },
];

interface IIncidentLevelProps {
  incidentLevel: string;
  selectStyle: any;
  onChangeSelectedIncidentLevel: (value: string) => void;
}

export const IncidentLevel = ({
  incidentLevel,
  selectStyle,
  onChangeSelectedIncidentLevel,
}: IIncidentLevelProps) => {
  return (
    <Wrapper>
      <InputLabel>Уровень инцидента</InputLabel>
      <SelectWithSearchIncidentLevel
        options={optionsLevel}
        value={incidentLevel}
        label="Выберите уровень инцидента"
        staticLabel={false}
        style={selectStyle}
        onChange={onChangeSelectedIncidentLevel}
      />
    </Wrapper>
  );
};
