import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IParsedItem, parseOlapdata, TDecoder } from "CORE/utils";
import { isContainedArray } from "SRC/helpers/dataTesters";
import { getIndicatorData } from "SRC/redux/slices/indicator/api/client";
import { IIndicatorParams } from "SRC/redux/slices/indicator/slices/filters";
import { ICodeItem } from "SRC/types";

const indicatorDecrypt: TDecoder = {
  period: {
    code: 5211,
    dimensions: {
      date: "PERIOD2",
    },
  },
  status: {
    code: 5214,
    dimensions: {
      name: "NAME",
      code: "STATUS_CODE",
      color: "STATUS_COLOR",
    },
  },
  seGp: {
    code: 5219,
    dimensions: {
      name: "NAME",
      code: "SE_CODE",
      type: "SE_TYPE",
      program: "StateProgramCode",
      responsible: "Responsible_Name",
    },
  },
  seGpType: {
    code: 5224,
    dimensions: {
      name: "NAME",
      type: "SE_TYPE",
    },
  },
  description: {
    code: 5234,
    dimensions: {
      name: "NAME",
      unit: "UNIT",
      code: "IND_CODE",
      period: "IMPLEMENTATION_PERIOD",
    },
  },
  task: {
    code: 5228,
    dimensions: {
      name: "NAME",
      seCode: "SE_CODE",
      seTaskCode: "SE_TASK_CODE",
      seTaskType: "SE_TASK_TYPE",
    },
  },
};

export interface IIndicatorDataInfo {
  code: string;
  name: string;
  order: string;
}

export interface IIndicatorData extends IParsedItem {
  period: Record<string, string | number | null>;
  status: Record<string, string | number | null>;
  seGp: Record<string, string | number | null>;
  seGpType: Record<string, string | number | null>;
  description: Record<string, string | number | null>;
  data: Record<string | number, ICodeItem>;
  task: Record<string, string | number | null>;
}

export interface IIndicatorDataState {
  items: IIndicatorData | null;
  fetching: boolean;
  fetched: boolean;
  error: string;
}

export const extraActions = {
  get: createAsyncThunk(
    "indicator-data/get",
    async (params: IIndicatorParams) => await getIndicatorData(params)
  ),
};

const initialState: IIndicatorDataState = {
  items: null,
  fetching: false,
  fetched: false,
  error: "",
};

const slice = createSlice({
  name: "indicator-data",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActions.get.pending, (state) => {
        state.items = null;
        state.fetching = true;
        state.fetched = false;
      })
      .addCase(
        extraActions.get.fulfilled,
        (state: IIndicatorDataState, action) => {
          // @ts-ignore
          const data = parseOlapdata(action.payload.data, indicatorDecrypt);

          state.items = isContainedArray(data)
            ? (data?.[0] as unknown as IIndicatorData)
            : null;
          state.fetching = false;
          state.fetched = true;
        }
      )
      .addCase(extraActions.get.rejected, (state, action) => {
        state.fetching = false;
        state.error = "Нет данных";
      });
  },
});

export const actions = {
  ...slice.actions,
  ...extraActions,
};

export default slice;
