import { SerializedError } from "@reduxjs/toolkit";
import { useCallback, useState } from "react";
import { IParams } from "SRC/redux/slices/gosprogram/slices/workbook";
import { IWorkbookData, TConfig, txtColors } from "SRC/types";

import { useAppDispatch, useAppSelector } from "../../../hooks";
import actions from "../actions";

export interface IWorkbook {
  fetching: boolean;
  fetched: boolean;
  items: IWorkbookData | null;
  load(params: IParams): void;
  config: TConfig;
  error: SerializedError | null;
  getFile(params: IParams): any;
}

const config: TConfig = {
  title: {
    bold: true,
    color: "blue",
    size: 14,
    alignment: {
      horizontal: "center",
      vertical: "center",
      wrapText: true,
    },
  },
  cell: {
    size: 11,
    alignment: {
      horizontal: "left",
      vertical: "center",
    },
    border: {
      top: { style: "thin", color: { rgb: txtColors.default } },
      bottom: { style: "thin", color: { rgb: txtColors.default } },
      left: { style: "thin", color: { rgb: txtColors.default } },
      right: { style: "thin", color: { rgb: txtColors.default } },
    },
  },
  header: {
    fill: "lightblue",
    size: 12,
    alignment: {
      horizontal: "center",
      vertical: "center",
      wrapText: true,
    },
    border: {
      top: { style: "thin", color: { rgb: txtColors.default } },
      bottom: { style: "thin", color: { rgb: txtColors.default } },
      left: { style: "thin", color: { rgb: txtColors.default } },
      right: { style: "thin", color: { rgb: txtColors.default } },
    },
  },
};

export const useWorkbook = (): IWorkbook => {
  const dispatch = useAppDispatch();
  const {
    fetching,
    items: workbook = null,
    fetched,
  } = useAppSelector((state) => state.gosprogram.workbook);
  const [error, setError] = useState<SerializedError | null>(null);

  const load = useCallback(
    (params: IParams) => {
      return dispatch(actions.workbook.get(params)).then((action) => {
        if (actions.workbook.get.rejected.match(action)) {
          setError(action.error);
        }

        return action;
      });
    },
    [dispatch]
  );

  const getFile = useCallback((params: IParams) => {
    return load({
      gpCode: params?.gpCode || "",
      tabName: "tab_impl_lvl",
      period: params?.period,
      isOm: params?.isOm,
    });
  }, []);

  return {
    fetching,
    fetched,
    items: workbook,
    load,
    config,
    error,
    getFile,
  };
};
