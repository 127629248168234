export interface IProgramData {
  id: string;
  name: string;
  vicePremier: string;
  gpLevel: number;
  kpLevel: number;
  kpPlan: number;
  kpFact: number;
  kpFail: number;
}

export const programData: IProgramData[] = [
  {
    id: "01",
    name: "Развитие здравоохранения",
    vicePremier: "Голикова Т.А.",
    gpLevel: 22.03,
    kpLevel: 89.51,
    kpPlan: 10,
    kpFact: 10,
    kpFail: 0,
  },
  {
    id: "02",
    name: "Развитие образования",
    vicePremier: "Голикова Т.А.",
    gpLevel: 32.58,
    kpLevel: 100,
    kpPlan: 8,
    kpFact: 8,
    kpFail: 0,
  },
  {
    id: "03",
    name: "Соц.поддержка",
    vicePremier: "Голикова Т.А.",
    gpLevel: 36.43,
    kpLevel: 100,
    kpPlan: 3,
    kpFact: 3,
    kpFail: 0,
  },
  {
    id: "04",
    name: "Доступная среда",
    vicePremier: "Голикова Т.А.",
    gpLevel: 70.83,
    kpLevel: 100,
    kpPlan: 5,
    kpFact: 5,
    kpFail: 0,
  },
  {
    id: "05",
    name: "Жилье и ЖКХ",
    vicePremier: "Хуснуллин М.Ш.",
    gpLevel: 28.33,
    kpLevel: 100,
    kpPlan: 4,
    kpFact: 4,
    kpFail: 0,
  },
  {
    id: "07",
    name: "Занятость населения",
    vicePremier: "Голикова Т.А.",
    gpLevel: 52.16,
    kpLevel: 84.62,
    kpPlan: 2,
    kpFact: 2,
    kpFail: 0,
  },
  {
    id: "08",
    name: "Обеспечение общественного порядка",
    vicePremier: "Мантуров Д.В.",
    gpLevel: 32.75,
    kpLevel: 79.01,
    kpPlan: 5,
    kpFact: 5,
    kpFail: 0,
  },
  {
    id: "10",
    name: "Защита от чрезвычайных ситуаций",
    vicePremier: "Мантуров Д.В.",
    gpLevel: 66.69,
    kpLevel: 77.38,
    kpPlan: 9,
    kpFact: 9,
    kpFail: 0,
  },
  {
    id: "11",
    name: "Развитие культуры",
    vicePremier: "Голикова Т.А.",
    gpLevel: 37.12,
    kpLevel: 100,
    kpPlan: 6,
    kpFact: 6,
    kpFail: 0,
  },
  {
    id: "12",
    name: "Охрана окружающей среды",
    vicePremier: "Абрамченко В.В.",
    gpLevel: 49.04,
    kpLevel: 90,
    kpPlan: 10,
    kpFact: 9,
    kpFail: 1,
  },
  {
    id: "13",
    name: "Развитие спорта",
    vicePremier: "Чернышенко Д.Н.",
    gpLevel: 24.58,
    kpLevel: 100,
    kpPlan: 4,
    kpFact: 4,
    kpFail: 0,
  },
  {
    id: "15",
    name: "Экономическое развитие",
    vicePremier: "Белоусов А.Р.",
    gpLevel: 26.31,
    kpLevel: 100,
    kpPlan: 5,
    kpFact: 5,
    kpFail: 0,
  },
  {
    id: "16",
    name: "Развитие промышленности",
    vicePremier: "Мантуров Д.В.",
    gpLevel: 22.67,
    kpLevel: 100,
    kpPlan: 8,
    kpFact: 8,
    kpFail: 0,
  },
  {
    id: "17",
    name: "Развитие авиа промышленности",
    vicePremier: "Мантуров Д.В.",
    gpLevel: 41.67,
    kpLevel: 97.99,
    kpPlan: 7,
    kpFact: 7,
    kpFail: 0,
  },
  {
    id: "18",
    name: "Развитие судостроения",
    vicePremier: "Мантуров Д.В.",
    gpLevel: 6.67,
    kpLevel: 87.5,
    kpPlan: 8,
    kpFact: 7,
    kpFail: 1,
  },
  {
    id: "20",
    name: "Развитие фарм промышленности",
    vicePremier: "Голикова Т.А.",
    gpLevel: 23.93,
    kpLevel: 94.45,
    kpPlan: 6,
    kpFact: 6,
    kpFail: 0,
  },
  {
    id: "23",
    name: "Информационное общество",
    vicePremier: "Чернышенко Д.Н.",
    gpLevel: 65.88,
    kpLevel: 100,
    kpPlan: 7,
    kpFact: 7,
    kpFail: 0,
  },
  {
    id: "24",
    name: "Развитие транспортной системы",
    vicePremier: "Белоусов А.Р.",
    gpLevel: 22.56,
    kpLevel: 85.64,
    kpPlan: 7,
    kpFact: 7,
    kpFail: 0,
  },
  {
    id: "25",
    name: "Развитие сельского хозяйства",
    vicePremier: "Абрамченко В.В.",
    gpLevel: 81.88,
    kpLevel: 100,
    kpPlan: 4,
    kpFact: 4,
    kpFail: 0,
  },
  {
    id: "26",
    name: "Развитие рыбохозяйственного комплекса",
    vicePremier: "Абрамченко В.В.",
    gpLevel: 82.35,
    kpLevel: 79.34,
    kpPlan: 5,
    kpFact: 4,
    kpFail: 1,
  },
  {
    id: "28",
    name: "Воспроизводство природных ресурсов",
    vicePremier: "Абрамченко В.В.",
    gpLevel: 56.77,
    kpLevel: 100,
    kpPlan: 7,
    kpFact: 7,
    kpFail: 0,
  },
  {
    id: "29",
    name: "Развитие лесного хозяйства",
    vicePremier: "Абрамченко В.В.",
    gpLevel: 43.36,
    kpLevel: 100,
    kpPlan: 4,
    kpFact: 4,
    kpFail: 0,
  },
  {
    id: "30",
    name: "Развитие энергетики",
    vicePremier: "Новак А.В.",
    gpLevel: 21.7,
    kpLevel: 95.16,
    kpPlan: 8,
    kpFact: 8,
    kpFail: 0,
  },
  {
    id: "34",
    name: "Развитие ДФО",
    vicePremier: "Трутнев Ю.П.",
    gpLevel: 80.63,
    kpLevel: 100,
    kpPlan: 7,
    kpFact: 7,
    kpFail: 0,
  },
  {
    id: "35",
    name: "Развитие СКФО",
    vicePremier: "Новак А.В.",
    gpLevel: 43.04,
    kpLevel: 90,
    kpPlan: 10,
    kpFact: 10,
    kpFail: 0,
  },
  {
    id: "36",
    name: "Развитие федеративных отношений",
    vicePremier: "Хуснуллин М.Ш.",
    gpLevel: 40.56,
    kpLevel: 98.83,
    kpPlan: 6,
    kpFact: 6,
    kpFail: 0,
  },
  {
    id: "37",
    name: "Развитие Калининградской области",
    vicePremier: "Голикова Т.А.",
    gpLevel: 15.79,
    kpLevel: 100,
    kpPlan: 6,
    kpFact: 6,
    kpFail: 0,
  },
  {
    id: "39",
    name: "Управление государственными финансами",
    vicePremier: "Григоренко Д.Ю.",
    gpLevel: 40.59,
    kpLevel: 94.64,
    kpPlan: 7,
    kpFact: 7,
    kpFail: 0,
  },
  {
    id: "42",
    name: "Юстиция",
    vicePremier: "Григоренко Д.Ю.",
    gpLevel: 81.14,
    kpLevel: 87.5,
    kpPlan: 8,
    kpFact: 8,
    kpFail: 0,
  },
  {
    id: "43",
    name: "Развитие Арктической зоны",
    vicePremier: "Трутнев Ю.П.",
    gpLevel: 15.36,
    kpLevel: 100,
    kpPlan: 4,
    kpFact: 4,
    kpFail: 0,
  },
  {
    id: "45",
    name: "Развитие Республики Крым и г. Севастополя",
    vicePremier: "Хуснуллин М.Ш.",
    gpLevel: 13.79,
    kpLevel: 32.21,
    kpPlan: 5,
    kpFact: 5,
    kpFail: 0,
  },
  {
    id: "46",
    name: "Национальная политика",
    vicePremier: "Чернышенко Д.Н.",
    gpLevel: 82.45,
    kpLevel: 99.92,
    kpPlan: 8,
    kpFact: 8,
    kpFail: 0,
  },
  {
    id: "47",
    name: "Научно-технологическое развитие",
    vicePremier: "Чернышенко Д.Н.",
    gpLevel: 26.2,
    kpLevel: 99.41,
    kpPlan: 6,
    kpFact: 6,
    kpFail: 0,
  },
  {
    id: "48",
    name: "Комплексное развитие сельских территорий",
    vicePremier: "Абрамченко В.В.",
    gpLevel: 76.75,
    kpLevel: 100,
    kpPlan: 5,
    kpFact: 5,
    kpFail: 0,
  },
  {
    id: "53",
    name: "Развитие мелиоративного комплекса",
    vicePremier: "Абрамченко В.В.",
    gpLevel: 40.78,
    kpLevel: 100,
    kpPlan: 2,
    kpFact: 2,
    kpFail: 0,
  },
  {
    id: "54",
    name: "Пространственные данные",
    vicePremier: "Хуснуллин М.Ш.",
    gpLevel: 31.67,
    kpLevel: 100,
    kpPlan: 7,
    kpFact: 7,
    kpFail: 0,
  },
  {
    id: "55",
    name: "Развитие туризма",
    vicePremier: "Чернышенко Д.Н.",
    gpLevel: 11.42,
    kpLevel: 99.88,
    kpPlan: 4,
    kpFact: 4,
    kpFail: 0,
  },
];
