import { Tooltip } from "@mui/material";
import { TooltipProps } from "@mui/material/Tooltip/Tooltip";
import React from "react";
import { CheckSecretData } from "SRC/components/CheckSecretData";
import { TableCell } from "SRC/components/TableCell";
import { ETitle } from "SRC/components/TableColumnTemplates/columns/indicators/indicatorsFact";
import { sortEventsFactColumn } from "SRC/helpers";
import { ICustomTableProps } from "SRC/types";

import { Value } from "./components/Value";
import { getEventsFact, getEventsFactTotal } from "./utils";

export const eventsFact = (isOM: boolean) => ({
  Header: "Факт",
  Tooltip: ({ children, ...props }: TooltipProps) => (
    <Tooltip {...props} title={ETitle.FACT_TITLE}>
      {children}
    </Tooltip>
  ),
  sortType: sortEventsFactColumn(isOM),
  width: 130,
  accessor: isOM ? "eventsOMFact" : "eventsFact",
  Cell: (tableProps: ICustomTableProps) => {
    const { eventsFact, isSecret } = getEventsFact(tableProps, isOM);

    return (
      <TableCell color={isOM ? "paleGreen" : "green"}>
        <CheckSecretData isSecret={isSecret}>
          <Value value={eventsFact} />
        </CheckSecretData>
      </TableCell>
    );
  },
  Footer: (tableProps: ICustomTableProps) => {
    const { eventsFactTotal } = getEventsFactTotal(tableProps, isOM);

    return (
      <Tooltip title="В расчете участвуют уникальные элементы">
        <TableCell color={isOM ? "paleGreen" : "green"}>
          {eventsFactTotal}
        </TableCell>
      </Tooltip>
    );
  },
});
