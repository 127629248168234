import { Box, Modal, SxProps } from "@mui/material";
import React, { Dispatch, SetStateAction } from "react";
import { IStatus } from "SRC/pages/Program/common/ProgramSummary/interfaces";
import { StructureCard } from "SRC/pages/Program/common/StructureCard/StructureCard";
import { useStructureElements } from "SRC/redux/slices/gosprogram/hooks/structureElements";

import { css } from "../Modals.styled";

interface IProps {
  open: boolean;
  setOpen?: Dispatch<SetStateAction<boolean>>;
  statuses: IStatus[];
}

export const ModalStructure = ({ open, setOpen, statuses }: IProps) => {
  const handleClose = () => setOpen?.(false);
  const { items: structures } = useStructureElements();

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={css.modalBox as SxProps}>
        <StructureCard
          structures={structures}
          setOpen={setOpen}
          statuses={statuses}
        />
      </Box>
    </Modal>
  );
};
