import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getStructureElementsGp } from "SRC/redux/slices/gosprogram/api/structure-elements";
import {
  TStateDefault,
  TStructureElement,
  TStructureElementsGpRequest,
  TStructureElementsResponse,
} from "SRC/types";

export interface IStructureElementsGPState extends TStateDefault {
  items: TStructureElement[];
}

export const extraActions = {
  get: createAsyncThunk(
    "structureElements/gp/get",
    async (params: TStructureElementsGpRequest) => {
      return await getStructureElementsGp(params);
    }
  ),
};

const initialState: IStructureElementsGPState = {
  items: [],
  fetching: false,
  fetched: false,
  error: null,
};

const slice = createSlice({
  name: "structureElements/gp",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActions.get.pending, (state) => {
        state.items = [];
        state.fetching = true;
        state.fetched = false;
      })
      .addCase(extraActions.get.fulfilled, (state, action) => {
        const { items = [] } = action.payload.data;

        state.items = (items as TStructureElementsResponse[]).map(
          (item: TStructureElementsResponse) => {
            const { id = "", code = "", attributeVals } = item;

            return {
              id,
              code,
              se_code: attributeVals?.SE_CODE || "",
              name: attributeVals?.NAME || "",
            };
          }
        );
        state.fetching = false;
        state.fetched = true;
      })
      .addCase(extraActions.get.rejected, (state, action) => {
        state.error = action.error;
        state.fetching = false;
        state.fetched = true;
      });
  },
});

export const actions = {
  ...slice.actions,
  ...extraActions,
};

export default slice;
