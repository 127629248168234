import { styled } from "@mui/material";

type WrapperProps = {
  lines: number;
};

export const WrapperExport = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "0.5rem",
  fontSize: "1.2rem",

  "& > .format": {
    cursor: "pointer",

    "&:hover": {
      color: theme.palette.text.onAccent,
    },
  },

  "& .active": {
    color: theme.palette.text.onAccent,
  },
}));

export const Wrapper = styled("div")<WrapperProps>(({ lines }) => ({
  ".lined-text": {
    display: "-webkit-box",
    WebkitLineClamp: lines,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    cursor: "default",
  },
}));
