import { styled, Theme } from "@mui/material";

interface IProps {
  isSmall?: boolean;
  length?: number | undefined;
  withBigRows?: boolean;
  hasBorder?: "left" | "right";
  multilineHeader?: boolean;
  withHeadBgr?: boolean;
  withClickableRow?: boolean;
  tableHeight?: string | number;
  statusLabel?: string;
}

export const StatusIconContainer = styled("div")<IProps>(({ theme }) => ({
  width: "0.5rem",
  height: "3.4rem",
  position: "absolute",
  left: "0.4rem",
  transform: "translateY(50%)",
  bottom: "50%",
  background: theme.palette.background.default,
  borderRadius: "0.25rem",
}));

export const iconCss = {
  statusIconImage: (theme: Theme, statusLabel: string = "") => {
    const {
      error: { main: errorColor },
      main: { gray: grayColor },
      success: { main: mainColor },
    } = theme.palette;

    let bgColor;
    if (["atRisk", "R"].includes(statusLabel)) {
      bgColor = errorColor;
    } else if (statusLabel === "Gr") {
      bgColor = grayColor;
    } else {
      bgColor = mainColor;
    }

    return {
      background: bgColor,
      height: "3.2rem",
      width: "0.25rem",
      left: "0.125rem",
      bottom: "1.5rem",
      position: "relative",
      transform: "translateY(50%)",
      borderRadius: "0.125rem",
    };
  },
};
