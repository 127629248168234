import { IProgramTableItem } from "SRC/hooks/summaryTable/interfaces";
import { generalRowsMainTableData } from "SRC/hooks/summaryTable/utils/utils";
import {
  EDataProgram,
  EIndicators,
  TDataProgram,
  TIndicatorsMap,
} from "SRC/redux/list/programs-main";
import { CustomRowProps } from "SRC/types/data/data";

const getValueOrZeroFn = (data: TIndicatorsMap | null) => (key: EIndicators) =>
  data ? Number(data[key]) : 0;

export const createMainProgramRow = (
  programs: TDataProgram
): IProgramTableItem => {
  const {
    data,
    gp_code,
    gp_icon_url,
    gp_short_name,
    vp_code,
    vp_name,
    [EDataProgram.TYPES_OF_GP]: gp_types,
  } = programs;

  const getValueOrZero = getValueOrZeroFn(data);

  return {
    id: gp_code,
    isProgram: true,
    name: gp_short_name,
    indicatorsPlan: getValueOrZero(EIndicators.INDICATORS_PLAN),
    indicatorsFact: getValueOrZero(EIndicators.INDICATORS_FACT),
    indicatorsOMPlan: getValueOrZero(EIndicators.INDICATORS_PLAN_OM),
    indicatorsOMFact: getValueOrZero(EIndicators.INDICATORS_FACT_OM),
    eventsPlan: getValueOrZero(EIndicators.EVENTS_PLAN),
    eventsFact: getValueOrZero(EIndicators.EVENTS_FACT),
    eventsRisk: getValueOrZero(EIndicators.EVENTS_RISK),
    eventsOMPlan: getValueOrZero(EIndicators.EVENTS_PLAN_OM),
    eventsOMFact: getValueOrZero(EIndicators.EVENTS_FACT_OM),
    eventsOMRisk: getValueOrZero(EIndicators.EVENTS_RISK),
    secretProgram: !!gp_types["Секретная"],
    performance: getValueOrZero(EIndicators.PERFORMANCE),
    performanceOM: getValueOrZero(EIndicators.PERFORMANCE_OM),
    performanceOMTotal: getValueOrZero(EIndicators.PERFORMANCE_OM),
    performanceTotal: getValueOrZero(EIndicators.PERFORMANCE),
    vp: vp_code,
    ministry: vp_name,
    icon: gp_icon_url,
  };
};

export const createMainProgramsTableData = (
  data: TDataProgram[]
): IProgramTableItem[] => {
  return data.map((elem: TDataProgram) => createMainProgramRow(elem));
};

export const createMainGpRowsTableData = (
  data: CustomRowProps[],
  isOM: boolean
) =>
  data.map((elem: CustomRowProps) => ({
    id: elem.original.id,
    icon: elem.original.icon,
    name: elem.original.name,
    ...generalRowsMainTableData(elem, isOM),
  }));
