import { actions as ministries } from "SRC/redux/slices/ministry/slices/ministries";

import { actions as programs } from "../../list/programs";

export interface IMinistryActions {
  programs: typeof programs;
  ministries: typeof ministries;
}

const actions: IMinistryActions = {
  programs,
  ministries,
};

export default actions;
