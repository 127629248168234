import React, { useMemo } from "react";
import { CheckSecretData } from "SRC/components/CheckSecretData";
import { PerformanceValue } from "SRC/components/PerformanceValue/PerformanceValue";
import { OverlayProgressBar } from "SRC/components/ProgressBar";
import { SourceValue } from "SRC/components/SourceValue/SourceValue";
import { ESources, sourceSettings } from "SRC/constants/globals";
import { checkSecretSphere } from "SRC/helpers/checkSecretSphere";
import { getPercentValue } from "SRC/helpers/getPercentValue";
import {
  IGlobalFilters,
  useGlobalFilters,
} from "SRC/redux/slices/global/hooks/useFilters";
import { IFilters, useFilters } from "SRC/redux/slices/main/hooks/useFilters";
import { ISphere } from "SRC/redux/slices/main/slices/spheres";

import { EffectiveItemContainer } from "./Effectiveness.styled";

interface IEffectiveItem {
  item: ISphere;
  onClick(): void;
}

export const EffectiveItem = ({ item, onClick }: IEffectiveItem) => {
  const { sphere }: IFilters = useFilters();
  const { sources, withSecret }: IGlobalFilters = useGlobalFilters();

  const values = useMemo(() => {
    return [
      sources[ESources.OM]
        ? {
            value: item.data[5265]?.sum || 0, // Уровень достижения, особое мнение
            settings: sourceSettings[ESources.OM],
          }
        : {
            value: item.data[1796]?.sum || 0, // Уровень достижения
            settings: sourceSettings[ESources.FOIV],
          },
    ];
  }, [item, sources]);

  const noSecret = useMemo(() => checkSecretSphere(item), [item]);

  const isAllSecret = useMemo(() => {
    const withSecret = item.data[1837]?.sum || 0;
    const noSecret = item.data[5491]?.sum || 0;

    return Boolean(!noSecret && withSecret);
  }, [item]);

  return (
    <EffectiveItemContainer
      active={sphere ? sphere === item.info.code : true}
      onClick={onClick}
    >
      <div className="itemTop">
        <div className="title">{item.info.code}</div>
        <div className="num">
          <CheckSecretData isSecret={!withSecret && isAllSecret}>
            {!withSecret ? item.data[5491]?.sum : item.data[1837]?.sum}
          </CheckSecretData>
        </div>
      </div>
      <div className="itemBottom">
        <div className="barContainer">
          <div className="barTitle">
            Уровень достижения,
            <span>%</span>
          </div>
          <div className="barUnit">
            <CheckSecretData isSecret={noSecret}>
              {values.map((value, index) => {
                return (
                  <SourceValue key={index} {...value} isPercent={true}>
                    <PerformanceValue
                      value={String(getPercentValue(value.value))}
                      sameSize={false}
                      className="performance-value"
                    />
                  </SourceValue>
                );
              })}
            </CheckSecretData>
          </div>
        </div>
        <OverlayProgressBar values={values} />
      </div>
    </EffectiveItemContainer>
  );
};
