import { styled } from "@mui/material";
import THEME from "SRC/theme";

interface IWrapper {
  isSmall: boolean;
}

const STYLES = {
  DEFAULT: {
    color: THEME.palette.text.onAccent,
    icon: {
      width: "2.75rem",
      maxHeight: "2.75rem",
    },
  },
  SMALL: {
    color: THEME.palette.main.gray,
    icon: {
      width: "1.8rem",
      maxHeight: "1.8rem",
      margin: "0 0.35rem",
    },
  },
};

export const Wrapper = styled("div")(({ isSmall }: IWrapper) => {
  const styles = isSmall ? STYLES.SMALL : STYLES.DEFAULT;

  return {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "1.5rem",
    color: styles.color,

    "& > img": {
      ...styles.icon,
    },
  };
});
