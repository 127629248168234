import React from "react";
import { TableProps, UseExpandedRowProps, UseTableRowProps } from "react-table";
import { CheckSecretData } from "SRC/components/CheckSecretData";
import MinistryInfo from "SRC/components/MinistryInfo/MinistryInfo";
import NavigateToProgram from "SRC/components/NavigateToProgram/NavigateToProgram";
import { ExpanderCell, nestingTest } from "SRC/components/Table";
import { TableCell } from "SRC/components/TableCell";
import { COLUMNS } from "SRC/components/TableColumnTemplates/columns";
import { IMinistriesTableItem } from "SRC/hooks/summaryTable/interfaces";

type CustomRowProps = UseTableRowProps<any> & UseExpandedRowProps<any>;

interface CustomTableProps extends TableProps {
  row: CustomRowProps;
  rows?: UseTableRowProps<IMinistriesTableItem>[];
}

const getGeneralInfoMinistriesColumns = (isOM: boolean) => {
  return [
    { ...COLUMNS.program.status() },
    {
      Header: "",
      accessor: "id",
      sticky: "left",
      width: 90,
      columns: [
        {
          Header: "Код ГП",
          width: 90,
          sticky: "left",
          accessor: "id",
          Cell: (tableProps: CustomTableProps) => {
            const { id } = tableProps.row.original;
            if (nestingTest(tableProps.row)) {
              return (
                <TableCell color="gray" align="center">
                  {String(id).padStart(2, "0")}
                </TableCell>
              );
            } else {
              return <ExpanderCell row={tableProps.row} />;
            }
          },
          Footer: "",
        },
      ],
      Footer: "",
    },
    {
      Header: "",
      accessor: "name",
      width: 280,
      sticky: "left",
      columns: [
        {
          Header: "Министерство",
          accessor: "name",
          sticky: "left",
          width: 280,
          Cell: (tableProps: CustomTableProps) => {
            if (nestingTest(tableProps.row)) {
              return (
                <NavigateToProgram program={tableProps.row.original} isSmall />
              );
            }

            return <MinistryInfo ministry={tableProps.row.original} />;
          },
          colspan: (row: CustomRowProps): number => (nestingTest(row) ? 2 : 1),
          Footer: <TableCell>Итого</TableCell>,
        },
      ],
      Footer: "",
    },
    {
      Header: "",
      accessor: "govPrograms",
      width: 180,
      sticky: "left",
      columns: [
        {
          Header: "Кол-во госпрограмм",
          accessor: "govPrograms",
          sticky: "left",
          width: 180,
          Cell: (tableProps: CustomTableProps) => {
            if (nestingTest(tableProps.row)) return null;
            const { govPrograms, govProgramsNoSecret } =
              tableProps.row.original;
            const { subRows } = tableProps.row.original;
            const numOfPrograms =
              subRows.length > 0 ? govPrograms : govProgramsNoSecret;

            return (
              <CheckSecretData isSecret={!numOfPrograms && govPrograms}>
                <TableCell>{numOfPrograms}</TableCell>
              </CheckSecretData>
            );
          },
          Footer: (tableProps: CustomTableProps) => {
            const total = React.useMemo(() => {
              const rowsArray = Array(tableProps.rows || []).flat();
              return rowsArray.reduce(
                (sum: number, row: UseTableRowProps<any>) => {
                  const govPrograms = row.original.subRows?.length ?? null;
                  return govPrograms + sum;
                },
                0
              );
            }, [tableProps.rows]);

            return <TableCell>{total}</TableCell>;
          },
        },
      ],
      Footer: "",
    },
    {
      Header: "",
      accessor: isOM ? "performanceOM" : "performance",
      columns: [
        {
          ...COLUMNS.performance.fact(isOM),
        },
      ],
      Footer: "",
    },
    {
      Header: "Кассовое исполнение",
      accessor: "cashExecution",
      columns: [{ ...COLUMNS.cash.plan() }, { ...COLUMNS.cash.fact() }],
    },
    {
      Header: "Показатели ГП",
      accessor: "indicators",
      columns: [
        { ...COLUMNS.indicators.plan(isOM) },
        { ...COLUMNS.indicators.fact(isOM) },
        { ...COLUMNS.indicators.fail(isOM) },
      ],
    },
    {
      Header: "Структурные элементы",
      accessor: "structureElements",
      columns: [
        { ...COLUMNS.structureElements.plan(isOM) },
        { ...COLUMNS.structureElements.fact(isOM) },
        { ...COLUMNS.structureElements.fail(isOM) },
      ],
      Footer: "",
    },
    {
      Header: "Показатели СЭ",
      accessor: "indicatorsSE",
      columns: [
        { ...COLUMNS.indicatorsSE.plan(isOM) },
        { ...COLUMNS.indicatorsSE.fact(isOM) },
        { ...COLUMNS.indicatorsSE.fail(isOM) },
      ],
      Footer: "",
    },
    {
      Header: "Мероприятия",
      accessor: "events",
      columns: [
        { ...COLUMNS.events.plan(isOM) },
        { ...COLUMNS.events.fact(isOM) },
        { ...COLUMNS.events.fail(isOM) },
      ],
      Footer: "",
    },
    {
      Header: "Контрольные точки",
      accessor: "checkpoints",
      columns: [
        { ...COLUMNS.checkpoints.plan(isOM) },
        { ...COLUMNS.checkpoints.fact(isOM) },
        { ...COLUMNS.checkpoints.fail(isOM) },
      ],
      Footer: "",
    },
  ];
};

export { getGeneralInfoMinistriesColumns };
