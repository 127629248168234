import { combineReducers } from "@reduxjs/toolkit";
import cashExecutionSlice, {
  ICashExecutionState,
} from "SRC/redux/slices/event/slices/cashExecution";
import controlPointsSlice, {
  IControlPointsState,
} from "SRC/redux/slices/event/slices/controlPoints";
import dataSlice, { IEventDataState } from "SRC/redux/slices/event/slices/data";
import filtersSlice, {
  IFiltersState,
} from "SRC/redux/slices/event/slices/filters";
import listOfObjectsSlice, {
  IListOfObjectsState,
} from "SRC/redux/slices/event/slices/listOfObjects";
import objectPhotosSlice, {
  IObjectPhotosState,
} from "SRC/redux/slices/event/slices/objectPhotos";
import objectVideosSlice, {
  IObjectVideosState,
} from "SRC/redux/slices/event/slices/objectVideos";
import subjectsSlice, {
  ISubjectsState,
} from "SRC/redux/slices/event/slices/subjects";

export interface IEventReducer {
  filters: IFiltersState;
  controlPoints: IControlPointsState;
  cashExecution: ICashExecutionState;
  data: IEventDataState;
  listOfObjects: IListOfObjectsState;
  subjects: ISubjectsState;
  objectPhotos: IObjectPhotosState;
  objectVideos: IObjectVideosState;
}

const reducer = combineReducers<IEventReducer>({
  filters: filtersSlice.reducer,
  controlPoints: controlPointsSlice.reducer,
  cashExecution: cashExecutionSlice.reducer,
  subjects: subjectsSlice.reducer,
  listOfObjects: listOfObjectsSlice.reducer,
  data: dataSlice.reducer,
  objectPhotos: objectPhotosSlice.reducer,
  objectVideos: objectVideosSlice.reducer,
});

export default reducer;
