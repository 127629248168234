import { useEffect, useMemo } from "react";
import { getInfoRowsSummaryVicePremiers } from "SRC/helpers/getInfoRows";
import { useTableExportParams } from "SRC/hooks";
import { getPremiersData } from "SRC/hooks/summaryTable/utils/utils";
import { useGlobalFilters } from "SRC/redux/slices/global/hooks/useFilters";
import {
  IFilters as IMainFilters,
  useFilters as useMainFilters,
} from "SRC/redux/slices/main/hooks/useFilters";
import { usePremiers as useMainPremiers } from "SRC/redux/slices/main/hooks/usePremiers";
import { usePrograms as useMainPrograms } from "SRC/redux/slices/main/hooks/usePrograms";
import {
  IFilters as ISummaryFilters,
  useFilters as useSummaryFilters,
} from "SRC/redux/slices/summary/hooks/useFilters";
import { usePremiers as useSummaryPremiers } from "SRC/redux/slices/summary/hooks/usePremiers";
import { usePrograms as useSummaryPrograms } from "SRC/redux/slices/summary/hooks/usePrograms";
import { IExportData } from "SRC/types";

import { IPremierTableItem } from "./interfaces";
import { createProgramsTableData as createProgramsMainTableData } from "./utils/mainData/mainDataGP";
import { createPremiersTableData as createPremiersMainTableData } from "./utils/mainData/mainDataVP";
import { createProgramsTableData } from "./utils/summaryData/summaryDataGP";
import { createPremiersTableData as createPremiersSummaryTableData } from "./utils/summaryData/summaryDataVP";

interface IUseTableData {
  premiersSummaryData: IPremierTableItem[];
  premiersSummaryExport: IExportData<Record<string, any>>;
  premiersMainData: IPremierTableItem[];
  premiersMainExport: IExportData<Record<string, any>>;
}

export const useTableData = (): IUseTableData => {
  const { period, withSecret } = useGlobalFilters();
  const { selectedSearchRows: exportSummaryData }: ISummaryFilters =
    useSummaryFilters();

  const { selectedSearchRows: exportMainData, filteredVPs }: IMainFilters =
    useMainFilters();

  const { items: summaryPrograms } = useSummaryPrograms();
  const { items: mainPrograms } = useMainPrograms();
  const { items: summaryPremiers } = useSummaryPremiers();
  const { load: loadMainPremiers, items: mainPremiers } = useMainPremiers();
  const createExportParams = useTableExportParams;

  useEffect(() => {
    loadMainPremiers(period);
  }, [period.value]);

  const programsSummaryTableData = useMemo(
    () => createProgramsTableData(summaryPrograms),
    [summaryPrograms]
  );

  const programsMainTableData = useMemo(
    () => createProgramsMainTableData(mainPrograms),
    [mainPrograms]
  );

  const premiersSummaryTableData = useMemo(
    () => createPremiersSummaryTableData(summaryPremiers),
    [summaryPremiers]
  );

  const premiersMainTableData = useMemo(
    () => createPremiersMainTableData(mainPremiers),
    [mainPremiers]
  );

  const getSummaryExport = (data: Record<string, any>[], tableName: string) => {
    return {
      csvData: data,
      fileName: "Сводная таблица. Вице-премьеры",
      infoRows: getInfoRowsSummaryVicePremiers(period),
      ...createExportParams(data, tableName),
    };
  };

  const premiersSummaryData = useMemo(() => {
    return getPremiersData(
      premiersSummaryTableData,
      programsSummaryTableData,
      withSecret
    );
  }, [premiersSummaryTableData, programsSummaryTableData, withSecret]);

  const premiersMainData: IPremierTableItem[] = useMemo(() => {
    return getPremiersData(
      premiersMainTableData,
      programsMainTableData,
      withSecret
    )
      .filter((program) => {
        return filteredVPs.includes(program.id);
      })
      .sort((a, b) => Number(a.id) - Number(b.id));
  }, [premiersMainTableData, programsMainTableData, withSecret, filteredVPs]);

  const filterXLSFields = useMemo(() => {
    return exportSummaryData.map((item) => {
      return {
        vicePremier: item.vicePremier,
        numOfPrograms: item.numOfPrograms,
        performance: item.performance,
        cashPlan: item.cashPlan,
        cashFact: item.cashFact,
        indicatorsPlan: item.indicatorsPlan,
        indicatorsFact: item.indicatorsFact,
        indicatorsFail: item.indicatorsFail,
        structureElementsPlan: item.structureElementsPlan,
        structureElementsFact: item.structureElementsFact,
        structureElementsFail: item.structureElementsFail,
        indicatorsSEPlan: item.indicatorsSEPlan,
        indicatorsSEFact: item.indicatorsSEFact,
        indicatorsSEFail: item.indicatorsSEFail,
        eventsPlan: item.eventsPlan,
        eventsFact: item.eventsFact,
        eventsFail: item.eventsFail,
        checkpointsPlan: item.checkpointsPlan,
        checkpointsFact: item.checkpointsFact,
        checkpointsFail: item.checkpointsFail,
      };
    });
  }, [exportSummaryData, premiersSummaryData]);

  const premiersSummaryExport = useMemo(() => {
    return getSummaryExport(filterXLSFields, "summaryVicePremiers");
  }, [getSummaryExport, filterXLSFields]);

  const premiersMainExport = useMemo(() => {
    const download = exportMainData.map(({ id, isSecret, ...item }) => item);

    return getSummaryExport(download, "vicePremiers");
  }, [exportMainData, getSummaryExport]);

  return {
    premiersSummaryData,
    premiersSummaryExport,
    premiersMainData,
    premiersMainExport,
  };
};
