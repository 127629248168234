import React, { ReactNode } from "react";

import { Wrapper } from "./Header.styled";

interface IHeaderProps {
  title: string;
  unit: string;
  statuses?: ReactNode[];
}

export const Header = ({ title, unit, statuses }: IHeaderProps) => {
  return (
    <Wrapper>
      <div className="title">
        {title}
        <span>{unit}</span>
      </div>
      {statuses && (
        <div className="statuses">{statuses.map((item) => item)}</div>
      )}
    </Wrapper>
  );
};
