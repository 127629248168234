import { SerializedError } from "@reduxjs/toolkit";
import { useCallback, useMemo, useState } from "react";
import { IProgram } from "SRC/redux/list/programs";
import { IPeriod } from "SRC/redux/slices/global/slices/filters";

import { useAppDispatch, useAppSelector } from "../../../hooks";
import actions from "../actions";

export interface IPrograms {
  fetching: boolean;
  fetched: boolean;
  items: IProgram[];
  load(period: IPeriod): void;
  getItemByCode(code: string): IProgram | undefined;
  error: SerializedError | null;
}

export const usePrograms = (): IPrograms => {
  const dispatch = useAppDispatch();
  const {
    fetching,
    items = [],
    fetched,
  } = useAppSelector((state) => state.main.programs);
  const [error, setError] = useState<SerializedError | null>(null);
  const programs: IProgram[] = useMemo(
    () =>
      [...items].sort((p1, p2) => Number(p1.info.code) - Number(p2.info.code)),
    [items]
  );

  const load = useCallback(
    (params: IPeriod) => {
      if (!fetching) {
        dispatch(actions.programs.get(params)).then((action) => {
          if (actions.programs.get.rejected.match(action)) {
            setError(action.error);
          }
          return action;
        });
      }
    },
    [dispatch, fetching]
  );

  const getItemByCode = useCallback(
    (code: string): IProgram | undefined =>
      programs.find(({ info }) => info.code === code),
    [programs]
  );

  return {
    fetching,
    fetched,
    items: programs,
    getItemByCode,
    load,
    error,
  };
};

export type { IProgram };
