import { Box, SxProps } from "@mui/material";
import React from "react";
import { TableProps, UseTableRowProps } from "react-table";
import { css } from "SRC/components/Table/Table.styled";
import { statusIcons } from "SRC/constants";
import { IEventStatuses } from "SRC/types";

interface CustomTableProps extends TableProps {
  row: UseTableRowProps<any>;
  rows?: UseTableRowProps<any>[];
}

const renderStatusIcons = (statuses: IEventStatuses) =>
  Object.entries(statuses).map(
    ([status, isSet]) => !!isSet && statusIcons[status]
  );

export const getEventsColumns = () => [
  {
    Header: "",
    width: css.statusIcon.width,
    accessor: "status",
    Cell: (tableProps: CustomTableProps) => (
      <Box sx={css.statusIcon as SxProps}>
        {renderStatusIcons(tableProps.row.original.status)}
      </Box>
    ),
  },
  {
    Header: "№",
    width: 50,
    accessor: "index",
  },
  {
    Header: "ID",
    width: 130,
    accessor: "id",
  },
  {
    Header: "Госпрограмма",
    width: "auto",
    accessor: "program",
  },
  {
    Header: "Тип CЭ",
    width: 80,
    accessor: "structureElement",
  },
  {
    Header: "СЭ",
    width: "auto",
    accessor: "structureElementName",
  },
  {
    Header: "Наименование",
    accessor: "name",
    width: "auto",
  },
  {
    Header: "Ед.изм.",
    accessor: "unit",
    width: 100,
  },
  {
    Header: "План",
    accessor: "plan",
    width: 100,
  },
  {
    Header: "Факт",
    accessor: "fact",
    width: 100,
  },
  {
    Header: "Уровень достижения, %",
    accessor: "performance",
    width: 250,
  },
  {
    Header: "Влияние",
    accessor: "influence",
    width: 100,
  },
];
