import { styled } from "@mui/material";

export const TooltipContent = styled("div")(() => {
  return {
    "& > .unit": {
      paddingTop: "1rem",
    },
  };
});

export const Wrapper = styled("div")(({ theme }) => {
  const {
    primary: { contrastText },
    main: { darkBlue: mainDarkBlue },
    complementary: { darkBlue },
    pale: { gray },
    text: { opinion },
  } = theme.palette;

  return {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "100%",
    height: "100%",

    "& > .indicator-unit": {
      paddingTop: "0.5rem",
      color: gray,
    },

    "& > .indicator-name": {
      flex: "1 0 auto",
    },

    ".indicatorName": {
      fontSize: "1.25rem",
      lineHeight: "1.5rem",
      color: contrastText,
      whiteSpace: "normal",

      "&.chartViewName": {
        margin: "0.5rem 0.5rem 0.5rem 0",
      },
    },

    ".plusContainer": {
      position: "absolute",
      right: "-1rem",
      top: "-1rem",
      height: "2rem",
      width: "2rem",
      background: mainDarkBlue,
      borderRadius: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      border: `2px solid ${darkBlue}`,
      cursor: "pointer",

      "& img": {
        width: "0.75rem",
        height: "0.75rem",
      },
    },

    ".boxWithChartContainer": {
      display: "flex",
      flexDirection: "row",
      background: darkBlue,
      borderRadius: "0.75rem",
      height: "5.25rem",
      position: "relative",
    },
    ".infoItem": {
      width: "33%",
      padding: "0.75rem 0.9rem",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",

      "&:first-of-type": {
        paddingLeft: "0",
      },
    },
    ".smallText": {
      fontSize: "1rem",
      lineHeight: "1.25rem",
      color: gray,
    },
    ".text": {
      display: "flex",
      alignItems: "flex-end",
      height: "1.5rem",
      fontWeight: 400,
      fontSize: "1.5rem",
      lineHeight: "2rem",
      color: contrastText,
      whiteSpace: "normal",
    },
    ".opinionText": {
      display: "flex",
      alignItems: "flex-end",
      height: "1.5rem",
      fontWeight: 400,
      fontSize: "1.5rem",
      lineHeight: "2rem",
      color: opinion,
      whiteSpace: "normal",
    },
  };
});
