import { Stack, styled } from "@mui/material";

export const StackWrapper = styled(Stack)(() => ({
  display: "flex",
  flexDirection: "row",
  width: "100%",
  gap: "1rem",

  ".programList": {
    width: "24%",
    minWidth: "24%",
    maxWidth: "24%",
    height: "100%",
  },

  ".goals-table": {
    flex: 1,
  },

  ".ng-table": {
    flex: 1,
    overflowY: "scroll",
    width: "100%",

    "& .td": {
      padding: "0.4rem 1rem",
    },
  },
}));

export const css = {
  programListContainer: {
    maxHeight: "16.5rem",
    overflowY: "scroll",
    margin: "0 -1rem -1rem -1rem",
    height: "100%",
  },

  loading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
    minHeight: "14rem",
  },

  achievementContainer: {
    padding: "0.22rem 1rem",
    width: "60%",
  },
};
