import classnames from "classnames";
import { CheckSecretData } from "SRC/components/CheckSecretData";
import { SlimProgressBar } from "SRC/components/ProgressBar";
import { WIPTag } from "SRC/components/WIP/WIP";
import { roundNumbersToFixed } from "SRC/helpers/roundNumbersToFixed";
import { useFilters } from "SRC/redux/slices/main/hooks/useFilters";
import theme from "SRC/theme";

import { RiskWrapper } from "./Risks.styled";

interface IDataRisk {
  plan: number;
  fact: number;
  risks: number;
  percent: number;
}

interface IRisksProps {
  dataTop: IDataRisk;
  dataBottom: IDataRisk;
  topName: string;
}

export const Risks = ({ dataTop, dataBottom, topName }: IRisksProps) => {
  const { isSphereSecret } = useFilters();

  return (
    <RiskWrapper
      className={classnames({
        "is-secret": isSphereSecret,
      })}
    >
      <CheckSecretData isSecret={isSphereSecret}>
        <div className="item-container">
          <div className="title">
            {topName}
            <div className="unit-title">ед.</div>
          </div>
          <SlimProgressBar
            value={roundNumbersToFixed(dataTop?.percent, 0) || 0}
            circleLabel={dataTop?.fact}
            bottomLeftLabel={0}
            bottomRightLabel={dataTop?.plan || " "}
            bottomLabelColorRight={theme.palette.charts.whiteTransparent}
            bottomLabelColorLeft={theme.palette.primary.contrastText}
            withLabel
            progressColor={
              dataTop?.percent < 100
                ? theme.palette.pale.red
                : theme.palette.pale.blue
            }
          />
        </div>
        <div className="item-container">
          <div className="title">
            Нормативные правовые акты
            <div className="unit-title">ед.</div>
            <WIPTag />
          </div>
          <SlimProgressBar
            value={roundNumbersToFixed(dataBottom?.percent, 0) || 0}
            circleLabel={dataBottom?.fact}
            bottomLeftLabel={0}
            bottomRightLabel={dataBottom?.plan || " "}
            bottomLabelColorRight={theme.palette.charts.whiteTransparent}
            bottomLabelColorLeft={theme.palette.primary.contrastText}
            withLabel
            progressColor={theme.palette.pale.blue}
          />
        </div>
      </CheckSecretData>
    </RiskWrapper>
  );
};
