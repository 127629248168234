import { Box, SxProps } from "@mui/material";
import { WhiteArrowRight } from "ASSETS/svg/arrow";
import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import Carousel from "SRC/components/CarouselNew/Carousel";
import { getRoundValue } from "SRC/helpers/getRoundValue";
import { Financing } from "SRC/pages/Main/common/Analitics/Financing/Financing";
import { Population } from "SRC/pages/Main/common/Analitics/Population/Population";
import { ProgramList } from "SRC/pages/Main/common/Analitics/ProgramList/ProgramList";
import { RisksTabs } from "SRC/pages/Main/common/Analitics/Risks/common/RisksTabs";
import { StructureElements } from "SRC/pages/Main/common/Analitics/StructureElements/StructureElements";
import { useFilters } from "SRC/redux/slices/main/hooks/useFilters";
import { useStats } from "SRC/redux/slices/main/hooks/useStats";
import { IFinancingData } from "SRC/types/analitics";
import { PROGRAMS } from "SRC/urls";

import { css } from "./Analitics.styled";

interface IRightTitleProps {
  isTitle?: boolean;
  isDoubleTitle?: boolean;
  isDetail?: boolean;
  link?: string;
  isActiveButton?: boolean | undefined;
}

export const RightTitle = ({
  isTitle,
  isDoubleTitle,
  isDetail,
  link,
  isActiveButton,
}: IRightTitleProps) => {
  const { items: stats } = useStats();
  const { filteredPrograms } = useFilters();
  const dataStats = useMemo(() => stats[0]?.data || {}, [stats]);
  const navigate = useNavigate();
  const handleLink = () => {
    if (link) {
      navigate(link);
    }
  };

  const financingData = useMemo<IFinancingData>(
    () => ({
      reportData: dataStats["Дата состояния финансирования"],
      extrabudgetary: {
        plan: dataStats["Финансирование внебюджет план"],
        fact: dataStats["Финансирование внебюджет факт"],
      },
      budget: {
        plan: dataStats["Финансирование бюджет план"],
        fact: dataStats["Финансирование бюджет факт"],
      },
      totalFinancing: {
        plan: dataStats["Финансирование план"],
        fact: dataStats["Финансирование факт"],
      },
    }),
    [dataStats]
  );

  const financingRightTitle = useMemo(() => {
    if (
      Number(financingData.totalFinancing.fact) &&
      Number(financingData.totalFinancing.plan)
    ) {
      return `${getRoundValue(
        financingData.totalFinancing.fact,
        1
      )} / ${getRoundValue(financingData.totalFinancing.plan, 2)}`;
    }

    return "";
  }, [financingData.totalFinancing.fact, financingData.totalFinancing.plan]);

  return (
    <Box sx={css.titleRightContainer}>
      {isDoubleTitle ? (
        <Box sx={css.titleRight}>
          <span className="date">
            {financingData.reportData ? `на ${financingData.reportData}` : ""}
          </span>
          {financingRightTitle && (
            <>
              <Box className="title">{financingRightTitle}</Box>
              <Box sx={css.unit}>млрд ₽</Box>
            </>
          )}
        </Box>
      ) : isTitle ? (
        <Box sx={css.title}>{filteredPrograms.length}</Box>
      ) : null}
      {isDetail ? (
        <Box
          sx={css.detailBtn(isActiveButton) as SxProps}
          onClick={() => handleLink()}
        >
          <WhiteArrowRight />
        </Box>
      ) : null}
    </Box>
  );
};

const analyticsCards = [
  {
    name: "Список госпрограмм",
    component: <ProgramList />,
    rightTitle: <RightTitle isDetail isTitle isActiveButton link={PROGRAMS} />,
    style: css.programListContainer,
  },
  {
    name: "Структурные элементы",
    component: <StructureElements />,
  },
  {
    name: "Финансирование",
    component: <Financing />,
    rightTitle: <RightTitle isDoubleTitle />,
  },
  {
    name: "Риски",
    component: <RisksTabs />,
  },
  {
    name: "Опросы населения",
    component: <Population />,
    rightTitle: <RightTitle isDetail />,
    inDevelopment: true,
  },
];

export const Analitics = () => {
  return (
    <Carousel visibleItems={4} isWheel={true}>
      {analyticsCards}
    </Carousel>
  );
};
