import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { EFinanceUnit } from "SRC/constants/globals";
import slice, { IFiltersState } from "SRC/redux/slices/event/slices/filters";

import { useAppSelector } from "../../../hooks";

export interface IFilters extends IFiltersState {
  clear(): void;
  setSelectedEventId(id: string | number): void;
  setSelectedObjectId(id: string): void;
  setEventModalOpen(open: boolean): void;
  setSelectedFinanceUnit(unit: EFinanceUnit | string): void;
}

export const useFilters = (): IFilters => {
  const dispatch = useDispatch();
  const filters = useAppSelector((state) => state.event.filters);

  const setSelectedEventId = (id: string) =>
    dispatch(slice.actions.selectEventId(id));

  const setSelectedObjectId = (id: string) =>
    dispatch(slice.actions.selectObjectId(id));

  const setEventModalOpen = (open: boolean) =>
    dispatch(slice.actions.setEventModalOpen(open));

  const setSelectedFinanceUnit = (unit: EFinanceUnit) =>
    dispatch(slice.actions.setSelectedFinanceUnit(unit));

  const clear = useCallback(() => dispatch(slice.actions.clear()), [dispatch]);

  return {
    ...filters,
    clear,
    setSelectedEventId,
    setSelectedObjectId,
    setEventModalOpen,
    setSelectedFinanceUnit,
  };
};
