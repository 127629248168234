import { Box, useTheme } from "@mui/material";
import ReactECharts from "echarts-for-react";
import moment from "moment";
import React, { useMemo } from "react";
import { OverlayBlock } from "SRC/components/OverlayBlock";
import {
  IGlobalFilters,
  useGlobalFilters,
} from "SRC/redux/slices/global/hooks/useFilters";
import { useIndicatorGraph } from "SRC/redux/slices/indicator/hooks/useIndicatorGraph";

import { css } from "./Foot.styled";
import getOptions from "./options";

const validData = (data: (number | null)[]): number[] =>
  data.map((it) => it || 0);

interface IChartData {
  plan: (number | null)[];
  fact: (number | null)[];
  forecast: (number | null)[];
}

const initialData = { plan: [], fact: [], forecast: [] };

export const Foot = () => {
  const theme = useTheme();
  const { items: data, fetching: loading } = useIndicatorGraph();
  const { period }: IGlobalFilters = useGlobalFilters();
  const unit = (data?.[0]?.description.unit as string) || undefined;
  const currentMonth = moment(period.value).month();

  const { plan, fact, forecast } = useMemo<IChartData>(
    () =>
      data?.reduce(
        (acc: IChartData, { data }) => ({
          plan: acc.plan.concat([data[5240].sum || 0]),
          fact: acc.fact.concat([data[5241].sum || 0]),
          forecast: acc.forecast.concat([data[5240].sum || 0]),
        }),
        initialData
      ) || initialData,
    [data]
  );

  const validPlan = useMemo<number[]>(() => validData(plan), [plan]);
  const correctFact = useMemo(
    () => fact.map((el, i) => (i <= currentMonth ? el : 0)),
    [fact]
  );
  const correctForecast = useMemo(() => {
    return forecast.map((el, i) =>
      i >= currentMonth ? (i === currentMonth ? fact[i] : el) : 0
    );
  }, [forecast]);

  const maxValue = useMemo(() => {
    const maxPlan = Math.max(...validPlan);
    const maxFact = Math.max(...validData(correctFact));
    const maxForecast = Math.max(...validData(correctForecast));
    return Math.max(maxPlan, maxFact, maxForecast);
  }, [validPlan, correctForecast, correctFact]);

  const options = useMemo(
    () =>
      getOptions(
        validPlan,
        correctFact,
        correctForecast,
        theme,
        maxValue,
        unit
      ),
    [validPlan, correctFact, correctForecast, maxValue, theme]
  );

  return (
    <OverlayBlock isFetching={loading} hasData={Boolean(data?.length)}>
      <Box sx={css.container}>
        <Box sx={css.head}>
          <Box sx={css.title}>Динамика достижения показателя</Box>
          <Box sx={css.legend}>
            <Box sx={css.legendItem}>
              <Box sx={css.styledPoint} className="white" />
              <p>План</p>
            </Box>
            <Box sx={css.legendItem}>
              <Box sx={css.styledPoint} className="green" />
              <p>Факт (выполнено)</p>
            </Box>
            <Box sx={css.legendItem}>
              <Box sx={css.styledPoint} className="red" />
              <p>Факт (не выполнено)</p>
            </Box>
            <Box sx={css.legendItem}>
              <Box sx={css.styledPoint} className="blue" />
              <p>Прогноз</p>
            </Box>
          </Box>
        </Box>
        <ReactECharts style={css.chart} option={options} />
      </Box>
    </OverlayBlock>
  );
};
