import { useCallback, useMemo } from "react";
import { programsApi } from "SRC/redux/list/programs";
import { IProgram } from "SRC/redux/list/programs";

import { selectors, useAppSelector } from "../../../hooks";

interface IStats {
  total: number;
  risk: number;
  secret: number;
}

export interface IPrograms {
  fetching: boolean;
  data: IProgram[];
  getItemByCode(code: string): IProgram | undefined;
  stats: IStats;
  error: boolean;
}

export const usePrograms = (): IPrograms => {
  const { period } = useAppSelector(selectors.globalFilters);
  const {
    data = [],
    isFetching,
    isError,
  } = programsApi.useGetProgramsQuery(period.apiV2);

  const programs: IProgram[] = useMemo(
    () =>
      [...data].sort((p1, p2) => Number(p1.info.code) - Number(p2.info.code)),
    [data]
  );

  const getItemByCode = useCallback(
    (code: string): IProgram | undefined =>
      programs.find(({ info }) => info.code === code),
    [programs]
  );

  const stats: IStats = useMemo(
    () =>
      programs.reduce(
        (acc, pr) => ({
          ...acc,
          secret: acc.secret + +pr.info.secret,
        }),
        {
          total: programs.length,
          risk: 0,
          secret: 0,
        }
      ),
    [programs]
  );

  return {
    fetching: isFetching,
    data: programs,
    getItemByCode,
    stats,
    error: isError,
  };
};

export type { IProgram };
