import { getIsSecret } from "SRC/pages/Summary/common/SummaryComplexGrid/utils";
import { ICustomTableProps } from "SRC/types";

export const getCheckpointsFact = (
  tableProps: ICustomTableProps,
  isOM: boolean
) => {
  const { checkpointsFact, checkpointsOMFact } = tableProps.row.original;

  return {
    isSecret: getIsSecret(tableProps),
    checkpointsFact: isOM ? checkpointsOMFact : checkpointsFact,
  };
};
