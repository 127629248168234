import React from "react";
import Breadcrumbs from "SRC/components/Breadcrumbs/Breadcrumbs";
import { Layout } from "SRC/layouts";
import { CenterLayout } from "SRC/pages/ProgramsBoard/layouts";
import Controller from "SRC/redux/stores/ProgramsBoard";

export const ProgramsBoard = () => {
  const links = [
    {
      title: "Госпрограммы",
    },
  ];

  return (
    <Controller>
      <Breadcrumbs links={links} />
      <Layout center={<CenterLayout />} />
    </Controller>
  );
};
