import { Tooltip } from "@mui/material";
import React from "react";
import { TooltipText } from "SRC/components/TooltipText/TooltipText";
import { simpleNumberByUnit } from "SRC/helpers/simpleNumberByUnit";
import { thousandSeparator } from "SRC/helpers/thousandSeparator";

import { TooltipContent, Wrapper } from "./IndicatorInfo.styled";

interface IProps {
  plan: number;
  fact: number;
  unit: string;
  indicatorName: string;
  chartPlanData: (number | null)[];
  chartFactData: (number | null)[];
  hasColorText?: boolean;
}

const IndicatorInfo = (props: IProps) => {
  const { plan, unit, fact, indicatorName, hasColorText } = props;

  return (
    <Wrapper>
      <div className="indicator-name">
        <TooltipText
          hint={
            <TooltipContent>
              <div>{indicatorName}</div>
              <div className="unit">{unit}</div>
            </TooltipContent>
          }
          variant="h3"
          lines={3}
        >
          <div className="indicatorName">{indicatorName}</div>
        </TooltipText>
      </div>
      <div className="indicator-unit">{unit}</div>
      <div className="boxWithChartContainer">
        <div className="infoItem">
          <div className="smallText">План</div>
          <Tooltip title={thousandSeparator(String(plan))}>
            <div className={hasColorText ? "opinionText" : "text"}>
              {simpleNumberByUnit(plan)}
            </div>
          </Tooltip>
        </div>
        <div className="infoItem">
          <div className="smallText">Факт</div>
          <Tooltip title={thousandSeparator(String(fact))}>
            <div className={hasColorText ? "opinionText" : "text"}>
              {simpleNumberByUnit(fact)}
            </div>
          </Tooltip>
        </div>
      </div>
    </Wrapper>
  );
};

export default IndicatorInfo;
