import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { isContainedArray } from "SRC/helpers/dataTesters";
import { getMethods } from "SRC/redux/slices/global/api/client";

export interface IMethod {
  max_year?: string;
  meth_info?: Record<string, string>[];
}

export interface IMethodsState {
  items: IMethod;
  fetching: boolean;
  fetched: boolean;
  error: string;
}

export const extraActions = {
  get: createAsyncThunk("methods/get", async () => await getMethods()),
};

const initialState: IMethodsState = {
  items: {},
  fetching: false,
  fetched: false,
  error: "",
};

export const slice = createSlice({
  name: "methods",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActions.get.pending, (state) => {
        state.items = {};
        state.fetching = true;
        state.fetched = false;
      })
      .addCase(extraActions.get.fulfilled, (state: IMethodsState, action) => {
        const { data } = action.payload;
        // @ts-ignore
        state.items = isContainedArray(data?.data) ? data?.data?.[0]?.data : {};
        state.fetching = false;
        state.fetched = true;
      })
      .addCase(extraActions.get.rejected, (state, action) => {
        state.fetching = false;
        // state.error = action.error;
      });
  },
});

export const actions = {
  ...slice.actions,
  ...extraActions,
};
