import {
  IMinistriesExportData,
  IMinistriesTableItem,
  IPremiersExportData,
  IPremierTableItem,
  IProgramTableItem,
} from "SRC/hooks/summaryTable/interfaces";
import { CustomRowProps } from "SRC/types/data/data";

export const getRowValue = (
  isSecret: boolean,
  isOM: boolean,
  value: string | number | boolean,
  valueOM?: string | number | boolean
) => {
  const singleValue = value || 0;
  const doubleValue = (isOM ? valueOM : value) || 0;
  const changeValue = valueOM ? doubleValue : singleValue;

  return isSecret ? "" : changeValue;
};

export const checkRow = (elem: CustomRowProps) => {
  const { subRows, govPrograms } = elem.original;

  const numOfPrograms = subRows ? subRows.length : null;
  const hasPublicPrograms = subRows
    ? Boolean(
        !subRows.length ||
          subRows.filter((row: any) => !row.secretProgram).length
      )
    : null;

  return {
    isSecret: !hasPublicPrograms || (!numOfPrograms && govPrograms),
    numOfPrograms,
  };
};

export const getMinistriesData = (
  ministriesTableData: IMinistriesTableItem[],
  programsTableData: IProgramTableItem[],
  withSecret: boolean
) => {
  return ministriesTableData
    .map((item): IMinistriesExportData => {
      const programs = programsTableData.filter(
        ({ ministry, secretProgram }) => {
          return ministry === item.name && !!secretProgram <= withSecret;
        }
      );
      return {
        ...item,
        subRows: programs,
      };
    })
    .filter(({ subRows }) => !!subRows.length)
    .sort((a, b) => Number(a.id) - Number(b.id));
};

export const getPremiersData = (
  premiersTableData: IPremierTableItem[],
  programsTableData: IProgramTableItem[],
  withSecret: boolean
) => {
  return premiersTableData
    .map((item): IPremiersExportData => {
      const programs = programsTableData.filter(({ vp, secretProgram }) => {
        return vp === item.code && !!secretProgram <= withSecret;
      });

      return {
        ...item,
        subRows: programs,
      };
    })
    .filter(({ subRows }) => !!subRows.length)
    .sort((a, b) => Number(a.id) - Number(b.id));
};

export const generalRowsMainTableData = (
  row: CustomRowProps,
  isOM: boolean
) => {
  const { original } = row;
  const isSecret = Boolean(
    original.secretProgram ||
      ((original.isPremier || original.isMinistry) && checkRow(row).isSecret)
  );

  return {
    indicatorsPlan: getRowValue(
      isSecret,
      isOM,
      original.indicatorsPlan,
      original.indicatorsOMPlan
    ),
    indicatorsFact: getRowValue(
      isSecret,
      isOM,
      original.indicatorsFact,
      original.indicatorsOMFact
    ),
    eventsPlan: getRowValue(
      isSecret,
      isOM,
      original.eventsPlan,
      original.eventsOMPlan
    ),
    eventsFact: getRowValue(
      isSecret,
      isOM,
      original.eventsFact,
      original.eventsOMFact
    ),
    eventsRisk: getRowValue(
      isSecret,
      isOM,
      original.eventsRisk,
      original.eventsOMRisk
    ),
    performance: getRowValue(
      isSecret,
      isOM,
      original.performance,
      original.performanceOM
    ),
    isSecret: isSecret,
  };
};

export const generalRowsSummaryTableData = (
  row: CustomRowProps,
  isOM: boolean
) => {
  const { original } = row;
  const { isSecret: isSecretRow, numOfPrograms } = checkRow(row);

  const isSecret = Boolean(
    original.secretProgram ||
      ((original.isPremier || original.isMinistry) && isSecretRow)
  );

  return {
    numOfPrograms,
    performance: getRowValue(
      isSecret,
      isOM,
      original.performance,
      original.performanceOM
    ),
    cashPlan: getRowValue(isSecret, isOM, original.cashPlan),
    cashFact: getRowValue(isSecret, isOM, original.cashFact),
    indicatorsPlan: getRowValue(
      isSecret,
      isOM,
      original.indicatorsPlan,
      original.indicatorsOMPlan
    ),
    indicatorsFact: getRowValue(
      isSecret,
      isOM,
      original.indicatorsFact,
      original.indicatorsOMFact
    ),
    indicatorsFail: getRowValue(
      isSecret,
      isOM,
      original.indicatorsFail,
      original.indicatorsOMFail
    ),
    structureElementsPlan: getRowValue(
      isSecret,
      isOM,
      original.structureElementsPlan,
      original.structureElementsOMPlan
    ),
    structureElementsFact: getRowValue(
      isSecret,
      isOM,
      original.structureElementsFact,
      original.structureElementsOMFact
    ),
    structureElementsFail: getRowValue(
      isSecret,
      isOM,
      original.structureElementsFail,
      original.structureElementsOMFail
    ),
    indicatorsSEPlan: getRowValue(
      isSecret,
      isOM,
      original.indicatorsSEPlan,
      original.indicatorsSEOMPlan
    ),
    indicatorsSEFact: getRowValue(
      isSecret,
      isOM,
      original.indicatorsSEFact,
      original.indicatorsSEOMFact
    ),
    indicatorsSEFail: getRowValue(
      isSecret,
      isOM,
      original.indicatorsSEFail,
      original.indicatorsSEOMFail
    ),
    eventsPlan: getRowValue(
      isSecret,
      isOM,
      original.eventsPlan,
      original.eventsOMPlan
    ),
    eventsFact: getRowValue(
      isSecret,
      isOM,
      original.eventsFact,
      original.eventsOMFact
    ),
    eventsFail: getRowValue(
      isSecret,
      isOM,
      original.eventsFail,
      original.eventsOMFail
    ),
    checkpointsPlan: getRowValue(
      isSecret,
      isOM,
      original.checkpointsPlan,
      original.checkpointsOMPlan
    ),
    checkpointsFact: getRowValue(
      isSecret,
      isOM,
      original.checkpointsFact,
      original.checkpointsOMFact
    ),
    checkpointsFail: getRowValue(
      isSecret,
      isOM,
      original.checkpointsFail,
      original.checkpointsOMFail
    ),
    isSecret: isSecret,
  };
};
