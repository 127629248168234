import React, { useCallback, useMemo } from "react";
import { IDPValue, ProgressDoughnutPale } from "SRC/components/Charts";
import { IncidentReport } from "SRC/components/IncidentReport";
import { OMSwitcher } from "SRC/components/OMSwitcher";
import { OverlayBlock } from "SRC/components/OverlayBlock";
import { ESources, sourceSettings } from "SRC/constants/globals";
import { checkSecretSphere } from "SRC/helpers/checkSecretSphere";
import {
  IGlobalFilters,
  useGlobalFilters,
} from "SRC/redux/slices/global/hooks/useFilters";
import { IFilters, useFilters } from "SRC/redux/slices/main/hooks/useFilters";
import { useSpheres } from "SRC/redux/slices/main/hooks/useSpheres";
import { useStats } from "SRC/redux/slices/main/hooks/useStats";
import { ISphere } from "SRC/redux/slices/main/slices/spheres";

import { EffectiveItem } from "./EffectiveItem";
import { EffetivenessContainer } from "./Effectiveness.styled";

export const Effectiveness = () => {
  const {
    setSelectedProgram,
    setSelectedSphere,
    sphere,
    setSecretSphere,
  }: IFilters = useFilters();
  const { sources }: IGlobalFilters = useGlobalFilters();
  const { items: spheres, fetching } = useSpheres();
  const { items: stats, fetched } = useStats();
  const handleSelectSphere = useCallback(
    (item: ISphere) => () => {
      const {
        info: { code },
      } = item;
      setSelectedSphere(sphere === code ? "" : code);
      setSelectedProgram("");
      setSecretSphere(checkSecretSphere(item));
    },
    [setSelectedSphere, sphere, setSelectedProgram]
  );

  const chartData: IDPValue[] = useMemo(() => {
    const { data } = stats[0] ?? {};

    return [
      sources[ESources.OM]
        ? {
            value: data?.["Уровень достижения 2 ОМ по всем ГП"] || 0,
            settings: sourceSettings[ESources.OM],
          }
        : {
            value: data?.["Уровень достижения 2 по всем ГП"] || 0,
            settings: sourceSettings[ESources.FOIV],
          },
    ];
  }, [sources, stats]);

  const effectItems = useMemo(
    (): JSX.Element[] =>
      spheres?.map((item: ISphere) => (
        <IncidentReport className="effective-item">
          <EffectiveItem
            key={item.info.code}
            item={item}
            onClick={handleSelectSphere(item)}
          />
        </IncidentReport>
      )),
    [spheres, handleSelectSphere]
  );

  return (
    <EffetivenessContainer>
      <IncidentReport className="effective-item">
        <div className="generalEffect">
          <div className="title">
            <span>Уровень достижения по открытым госпрограммам</span>
          </div>
          <OMSwitcher />
          <OverlayBlock isFetching={!fetched} hasData={Boolean(stats)}>
            <div className="chart">
              <ProgressDoughnutPale
                total={100}
                values={chartData}
                title="Уровень достижения"
              />
            </div>
          </OverlayBlock>
        </div>
      </IncidentReport>
      <div className="effectsContainer">
        <OverlayBlock isFetching={fetching} hasData={Boolean(spheres)}>
          <>{effectItems}</>
        </OverlayBlock>
      </div>
    </EffetivenessContainer>
  );
};
