import { styled } from "@mui/material";

export const Wrapper = styled("div")(() => ({
  width: "100%",
  marginBottom: "1rem",
  margin: "0 1.3rem",

  button: {
    textTransform: "none",
    fontSize: "1rem",
  },

  "& .files-list-name": {
    width: "max-content",
    display: "flex",
    alignItems: "center",
    marginBottom: "1rem",
    borderBottom: "1px dashed #ffffff",
    cursor: "pointer",
  },

  "& .files-list-input": {
    display: "none",
  },

  "& .files-list": {
    height: "3rem",
    padding: 0,
    overflowY: "auto",

    li: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      border: "1px solid #2E364A",
      borderRadius: "1rem",
      padding: "0.5rem",
      marginBottom: "0.5rem",

      "& .file-close-icon": {
        marginRight: "0.5rem",
        cursor: "pointer",

        svg: {
          width: 10,
        },
      },

      div: {
        display: "flex",
        alignItems: "center",
        gap: "0.5rem",
      },
    },
  },
}));
