import React from "react";
import { CheckSecretData } from "SRC/components/CheckSecretData";
import { TableCell } from "SRC/components/TableCell";
import { sortCashFactColumn } from "SRC/helpers";
import { ICustomTableProps } from "SRC/types";

import { getCashFact, getCashFactTotal } from "./utils";

export const cashFact = () => ({
  Header: "Факт, %",
  sortType: sortCashFactColumn(),
  width: 130,
  accessor: "cashFact",
  Cell: (tableProps: ICustomTableProps) => {
    const { cashFact, isSecret } = getCashFact(tableProps);

    return (
      <TableCell>
        <CheckSecretData isSecret={isSecret}>{cashFact}</CheckSecretData>
      </TableCell>
    );
  },
  Footer: (tableProps: ICustomTableProps) => {
    const { total } = getCashFactTotal(tableProps);

    return <TableCell>{total}</TableCell>;
  },
});
