import { useCallback } from "react";

import { selectors, useAppSelector } from "../../../hooks";
import { ISEParams, ITask, seApi } from "../api";

export interface ITasks {
  fetching: boolean;
  data: ITask[];
  getItemByCode(code: string): ITask | undefined;
  error: boolean;
}

export const useTasks = (id: string | number): ITasks => {
  const { period } = useAppSelector(selectors.globalFilters);
  const params: ISEParams = { id, period: period.apiV2 };
  const { data = [], isFetching, isError } = seApi.useGetSETasksQuery(params);

  const getItemByCode = useCallback(
    (code: string): ITask | undefined =>
      data.find(({ info }) => info.code === code),
    [data]
  );

  return {
    fetching: isFetching,
    data,
    getItemByCode,
    error: isError,
  };
};
