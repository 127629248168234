import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithInterceptors } from "CORE/api/testCore";

export const oldApi = createApi({
  reducerPath: "oldApi",
  baseQuery: baseQueryWithInterceptors("api/data_np_api/v1.0/data"),
  endpoints: () => ({}),
});

export const baseApi = createApi({
  reducerPath: "baseApi",
  baseQuery: baseQueryWithInterceptors("api/data_api/v1.0/olapdata"),
  endpoints: () => ({}),
});
