import { useCallback, useMemo } from "react";
import {
  EReportType,
  getInfoRowsEvents,
  staticInfoRows,
} from "SRC/helpers/getInfoRows";
import { useTableExportParams } from "SRC/hooks";
import { useProgramIndicators } from "SRC/pages/Program/common/IndicatorsTab/useIndicatorsData";
import { createIndicatorsGPTableData } from "SRC/pages/Program/utils";
import {
  IGlobalFilters,
  useGlobalFilters,
} from "SRC/redux/slices/global/hooks/useFilters";
import { useStructureElements } from "SRC/redux/slices/gosprogram/hooks/structureElements";
import {
  IFilters,
  useFilters,
} from "SRC/redux/slices/gosprogram/hooks/useFilters";
import {
  IProgram,
  useProgram,
} from "SRC/redux/slices/gosprogram/hooks/useProgram";
import { IExportData } from "SRC/types";

interface IUseTableData {
  eventsExports: IExportData<Record<string, any>>;
  indicatorsExports: IExportData<Record<string, any>>;
  tasksExports: IExportData<Record<string, any>>;
  goalsExports: IExportData<Record<string, any>>;
  goalsAnalyticsExports: IExportData<Record<string, any>>;
  structureGPExports: IExportData<Record<string, any>>;
}

export const useExportTableData = (): IUseTableData => {
  const { sources, period }: IGlobalFilters = useGlobalFilters();
  const {
    selectedSearchRows: exportData,
    selectedStructureElementType,
    selectedStructureElementName,
  }: IFilters = useFilters();
  const { indicators: programIndicators, analyticIndicators } =
    useProgramIndicators();
  const { items: program } = useProgram();
  const { getItemByCode } = useStructureElements();
  const createExportParams = useTableExportParams;

  const types: (keyof IProgram)[] = useMemo(
    () => ["Секретная", "Отраслевая", "Комплексная"],
    []
  );

  const getInfoRows = useCallback(
    (location: EReportType) =>
      getInfoRowsEvents(
        period,
        program?.gp_name || "",
        types.find((name) => Number(program?.[name])) || " ",
        location,
        selectedStructureElementName || selectedStructureElementType
      ),
    [
      getItemByCode,
      period,
      program,
      selectedStructureElementType,
      selectedStructureElementName,
      types,
    ]
  );

  const eventsExports = useMemo(() => {
    const download = exportData;
    return {
      csvData: download,
      fileName: "Мероприятия",
      infoRows: [...staticInfoRows, ...getInfoRows(EReportType.EVENTS)],
      ...createExportParams(download, "events"),
    };
  }, [exportData, createExportParams]);

  const indicatorsExports: IExportData<Record<string, any>> = useMemo(() => {
    const download = exportData;
    return {
      csvData: exportData,
      fileName: "Показатели СЭ",
      infoRows: [...staticInfoRows, ...getInfoRows(EReportType.INDICATORS_SE)],
      ...createExportParams(download, "indicatorsSE"),
    };
  }, [exportData, createExportParams]);

  const tasksExports: IExportData<Record<string, any>> = useMemo(() => {
    return {
      csvData: exportData,
      fileName: "Задачи СЭ",
      infoRows: [...staticInfoRows, ...getInfoRows(EReportType.TASKS_SE)],
      ...createExportParams(exportData, "tasksSE"),
    };
  }, [exportData, createExportParams]);

  const structureGPExports: IExportData<Record<string, any>> = useMemo(() => {
    return {
      csvData: exportData,
      fileName: "Список СЭ",
      infoRows: [...staticInfoRows, ...getInfoRows(EReportType.LIST_SE)],
      ...createExportParams(exportData, "structureGP"),
    };
  }, [exportData, createExportParams]);

  const goalsExports: IExportData<Record<string, any>> = useMemo(() => {
    const download = createIndicatorsGPTableData(
      programIndicators,
      sources.OM
    ).map(({ unit, ...item }) => item);

    return {
      csvData: download,
      fileName: "Показатели ГП",
      infoRows: [...staticInfoRows, ...getInfoRows(EReportType.INDICATORS_GP)],
      ...createExportParams(download, "indicatorsGP"),
    };
  }, [programIndicators, sources.OM, createExportParams]);

  const goalsAnalyticsExports: IExportData<Record<string, any>> =
    useMemo(() => {
      const download = createIndicatorsGPTableData(
        analyticIndicators,
        sources.OM
      ).map(({ goal, performance, ...item }) => item);

      return {
        csvData: download,
        fileName: "Аналитические показатели ГП",
        infoRows: [
          ...staticInfoRows,
          ...getInfoRows(EReportType.INDICATORS_ANALYTICS_GP),
        ],
        ...createExportParams(download, "indicatorsAnalyticsGP"),
      };
    }, [analyticIndicators, sources.OM, createExportParams]);

  return {
    eventsExports,
    tasksExports,
    indicatorsExports,
    goalsExports,
    goalsAnalyticsExports,
    structureGPExports,
  };
};
