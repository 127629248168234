import { getIsSecret } from "SRC/pages/Summary/common/SummaryComplexGrid/utils";
import { ICustomTableProps } from "SRC/types";

export const getEventsPlan = (tableProps: ICustomTableProps, isOM: boolean) => {
  const { eventsPlan, eventsOMPlan } = tableProps.row.original;

  return {
    eventsPlan: isOM ? eventsOMPlan : eventsPlan,
    isSecret: getIsSecret(tableProps),
  };
};
