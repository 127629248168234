import { getRoundValue } from "SRC/helpers/getRoundValue";

export const getPercentValue = (
  value: number | string,
  numOfDigits: number = 2
): string => {
  const MAX_VALUE = 100;
  const normalizeValue = String(value).indexOf(",")
    ? String(value).replace(",", ".")
    : value;
  const valueAsNumber = Number(normalizeValue) || 0;

  if (valueAsNumber >= MAX_VALUE) {
    return String(MAX_VALUE);
  }

  return getRoundValue(valueAsNumber, numOfDigits);
};
