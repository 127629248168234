import { Theme } from "@mui/material";

export const css = {
  text: (color: string) => ({
    display: "inline-block",
    fontSize: "inherit",
    lineHeight: "1em",
    color: (theme: Theme) => color || theme.palette.primary.contrastText,
  }),
  tiny: {
    display: "inline",
    fontSize: "0.5em",
    lineHeight: "1em",
  },
  unit: {
    display: "inline",
    color: (theme: Theme) => theme.palette.complementary.unitsGray,
    fontSize: "0.875rem",
    ml: "0.3rem",
  },
};
