import { useMediaQuery, useTheme } from "@mui/material";
import React, { useMemo } from "react";
import { TableModal } from "SRC/components/FullPageTableModal/FullPageTableModal";
import { SelectWithSearchStructure } from "SRC/components/SelectWithSearch/SelectWithSearchStructure";
import Table from "SRC/components/Table";
import { useFilters } from "SRC/redux/slices/structureElement/hooks";
import { useGetIndicators } from "SRC/redux/stores/StructureElement";

import { css, Wrapper } from "./IndicatorsTab.styled";
import { tableColumns } from "./tableColumns";

interface IStructureTabProps {
  isModalOpen?: boolean;
  setModalOpen?: (value: boolean) => void;
}

const sortedColumns: string[] = [
  "Ед. измерения",
  "Уровень показателя",
  "УД показателя, %",
  "План",
  "Факт",
];

const RenderTable: React.FC<IStructureTabProps> = ({
  isModalOpen,
}: IStructureTabProps) => {
  const theme = useTheme();
  const { rows, fetching } = useGetIndicators();

  const { selectedStructureElementType, setSelectedStructureElementType } =
    useFilters();

  const selectStyle = useMemo(() => css.selector(theme), [theme]);

  return (
    <Wrapper isModalOpen={isModalOpen}>
      <div className="filters">
        <div className="filters-row">
          <SelectWithSearchStructure
            label="Все ОЗР и задачи"
            staticLabel={false}
            style={selectStyle}
            onChange={setSelectedStructureElementType}
            value={selectedStructureElementType}
          />
        </div>
      </div>
      <Table
        data={rows}
        columns={tableColumns}
        loading={fetching}
        sortedColumns={sortedColumns}
        tableHeight={!isModalOpen ? "47.5rem" : undefined}
        // TODO [переделать]: зацикливает приложение
        // initialState={initialState}
        // getLocalState={setTableState}
      />
    </Wrapper>
  );
};

export const IndicatorsTab: React.FC<IStructureTabProps> = (props) => {
  const { isModalOpen, setModalOpen } = props;
  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.up("lg"));

  if (!match && isModalOpen) {
    return (
      <TableModal
        open={isModalOpen}
        setOpen={setModalOpen}
        tableName="Показатели"
      >
        <RenderTable {...props} />
      </TableModal>
    );
  }

  return <RenderTable {...props} />;
};
