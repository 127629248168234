import { createSlice } from "@reduxjs/toolkit";
import { EFinanceUnit } from "SRC/constants/globals";
import { EFootView } from "SRC/pages/Event/interfaces";

export interface IFiltersState {
  selectedEventId: string | number;
  selectedObjectId: string;
  eventModalOpen: boolean;
  selectedFinanceUnit: EFinanceUnit;
  footerView: EFootView;
}

const initialState: IFiltersState = {
  selectedEventId: "",
  selectedObjectId: "",
  eventModalOpen: false,
  selectedFinanceUnit: EFinanceUnit.BILLION,
  footerView: EFootView.POINTS,
};

const slice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    clear: () => initialState,
    selectEventId: (state, action: { payload: string | number }) => {
      state.selectedEventId = action.payload;
    },
    selectObjectId: (state, action: { payload: string }) => {
      state.selectedObjectId = action.payload;
    },
    setEventModalOpen: (state, action: { payload: boolean }) => {
      state.eventModalOpen = action.payload;
    },
    setSelectedFinanceUnit: (state, action: { payload: EFinanceUnit }) => {
      state.selectedFinanceUnit = action.payload;
    },
    setFooterView: (state, action: { payload: EFootView }) => {
      state.footerView = action.payload;
    },
  },
});

export default slice;
