import { styled } from "@mui/material";

export const WrapperPerformance = styled("div")(({ theme }) => {
  const {
    pale: { green, paleGreen },
    primary: { contrastText },
    text: { opinion },
  } = theme.palette;

  return {
    color: contrastText,
    display: "inline-block",

    "& .fraction-part": {
      display: "inline-block",
      fontSize: "0.5em",
    },

    "&.pale-green": {
      color: paleGreen,
    },

    "&.green": {
      color: green,
    },

    "&.opinion": {
      color: opinion,
    },
  };
});
