import { ICustomTableProps } from "SRC/types";

export const getStructureElementsPlanTotal = (
  tableProps: ICustomTableProps,
  isOM: boolean
) => {
  let { structureElementsPlanTotal, structureElementsOMPlanTotal } =
    tableProps?.rows?.[0]?.original || {};

  return {
    structureElementsPlanTotal: isOM
      ? structureElementsOMPlanTotal
      : structureElementsPlanTotal,
  };
};
