import React from "react";
import { CheckSecretData } from "SRC/components/CheckSecretData";
import { TableCell } from "SRC/components/TableCell";
import { sortCashPlanColumn } from "SRC/helpers";
import { ICustomTableProps } from "SRC/types";

import { getCashPlan, getCashPlanTotal } from "./utils";

export const cashPlan = () => ({
  Header: "План, млрд ₽",
  sortType: sortCashPlanColumn(),
  width: 130,
  accessor: "cashPlan",
  Cell: (tableProps: ICustomTableProps) => {
    const { cashPlan, isSecret } = getCashPlan(tableProps);

    return (
      <TableCell>
        <CheckSecretData isSecret={isSecret}>{cashPlan}</CheckSecretData>
      </TableCell>
    );
  },
  Footer: (tableProps: ICustomTableProps) => {
    const { total } = getCashPlanTotal(tableProps);

    return <TableCell>{total}</TableCell>;
  },
});
