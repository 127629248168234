import { Stack, styled } from "@mui/material";

export const Wrapper = styled(Stack)(({ theme }) => {
  const {
    background: { paper },
    charts: { darkBlueBackground },
    main: { gray, darkBlue },
    complementary: { lightWhite },
  } = theme.palette;

  return {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "1.5rem",
    maxHeight: "24rem",

    "& > .premiers-achievement": {
      display: "flex",
      width: "50%",
      maxWidth: "27.125rem",
      minHeight: "22.75rem",
      flexDirection: "column",
      background: paper,
      padding: "1rem 1.5rem",
      borderRadius: "1rem",
      gap: "0.25rem",
      flex: "1 0 15%",
      alignItems: "center",

      "& > .title": {
        fontWeight: 400,
        fontSize: "1.25rem",
        color: gray,
        paddingBottom: "0.8rem",
        borderBottom: `3px dotted ${darkBlue}`,
        margin: "0.5rem 0 2rem 0",
      },
      "& > .statuses": {
        display: "flex",
        marginTop: "1.5rem",
        gap: "1rem",

        "& > .status-label": {
          color: lightWhite,
        },
      },
      "& .chart": {
        background: darkBlueBackground,
        borderRadius: "50%",
        display: "flex",
        height: "11.25rem",
        width: "11.25rem",
        padding: "0.3rem",
      },
    },
  };
});
