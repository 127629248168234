import { getRoundValue } from "SRC/helpers/getRoundValue";
import { ICustomTableProps } from "SRC/types";

import { getIsSecret } from "../../../../../pages/Summary/common/SummaryComplexGrid/utils";

export const getCashPlan = (tableProps: ICustomTableProps) => {
  const { cashPlan = 0 } = tableProps.row.original;

  return {
    cashPlan: getRoundValue(cashPlan, 2),
    isSecret: getIsSecret(tableProps),
  };
};
