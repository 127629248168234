import { Box, useMediaQuery, useTheme } from "@mui/material";
import React, { Dispatch, SetStateAction, useCallback, useMemo } from "react";
import { Column, TableInstance } from "react-table";
import { TableModal } from "SRC/components/FullPageTableModal/FullPageTableModal";
import { OMSwitcher } from "SRC/components/OMSwitcher";
import { SecretSwitcher } from "SRC/components/SecretSwitcher";
import Table from "SRC/components/Table";
import { TableActionsButtons } from "SRC/components/TableActionsButtons/TableActionsButtons";
import { useMainTableData } from "SRC/hooks/summaryTable/useSummaryMainPremiers";
import { createMainPremierRowsTableData } from "SRC/hooks/summaryTable/utils/mainData/mainDataVPNew";
import { css } from "SRC/pages/Main/common/ComplexGrid/ComplexGrid.styled";
import { VicePremiersFilters } from "SRC/pages/Main/common/ComplexGrid/filterComponents/VicePremiersFilters";
import { getVicePremiersColumns } from "SRC/pages/Main/common/ComplexGrid/VicePremiersTab/tableData";
import {
  IGlobalFilters,
  useGlobalFilters,
} from "SRC/redux/slices/global/hooks/useFilters";
import { IFilters, useFilters } from "SRC/redux/slices/main/hooks/useFilters";
import { usePremiers } from "SRC/redux/slices/main/hooks/usePremiers";
import { CustomRowProps } from "SRC/types/data/data";

interface IVicePremiersTabProps {
  isModalOpen?: boolean;
  setModalOpen?: (value: boolean) => void;
  instance: TableInstance | undefined;
  setInstance: Dispatch<SetStateAction<TableInstance<{}> | undefined>>;
}

const sortedColumns = [
  "Госпрограммы",
  "План",
  "Факт",
  "Выполнено",
  "Под риском",
  "Уровень достижения",
  "Количество госпрограмм",
];

export const RenderTable = ({
  isModalOpen,
  setModalOpen,
  instance,
  setInstance,
}: IVicePremiersTabProps) => {
  const {
    setSelectedProgram,
    setSelectedVP,
    vp,
    setSearchRows,
    setSelectedMinistry,
  }: IFilters = useFilters();
  const { fetching } = usePremiers();
  const { premiersMainData: premiersData, premiersMainExport: premiersExport } =
    useMainTableData();
  const { sources, withSecret }: IGlobalFilters = useGlobalFilters();

  const columns: Column[] = useMemo(
    () => getVicePremiersColumns(sources.OM, withSecret),
    [sources.OM, withSecret]
  );

  const onChangeFilterRows = useCallback(
    (rows: CustomRowProps[]) => {
      setSearchRows(createMainPremierRowsTableData(rows, sources.OM));
    },
    [sources.OM]
  );

  const onSelect = useCallback(
    (id: string | number, nested: boolean) => {
      if (nested) {
        setSelectedProgram(String(id));
        setSelectedMinistry("");
      } else {
        setSelectedVP(String(id));
      }
    },
    [setSelectedMinistry, setSelectedProgram, setSelectedVP]
  );

  return (
    <Box sx={css.tabContainer}>
      {isModalOpen && (
        <Box sx={css.actionsContainer}>
          <VicePremiersFilters instance={instance} />
          <Box sx={css.leftBlock}>
            <OMSwitcher />
            <SecretSwitcher />
            <TableActionsButtons
              open={isModalOpen}
              setOpen={setModalOpen}
              {...premiersExport}
            />
          </Box>
        </Box>
      )}
      <Table
        data={premiersData}
        columns={columns}
        loading={fetching}
        sortedColumns={sortedColumns}
        selectedRow={vp}
        setSelectedRow={onSelect}
        noDataCondition={premiersData?.length === 0}
        getInstance={setInstance}
        expandable
        onFilterRows={onChangeFilterRows}
        classes={{
          thead: "table-header",
          tbody: "table-body",
          tfoot: "table-footer",
        }}
      />
    </Box>
  );
};

export const VicePremiersTab = (props: IVicePremiersTabProps) => {
  const { isModalOpen, setModalOpen } = props;
  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <>
      <RenderTable {...props} key={Number(isModalOpen)} />
      {!match && (
        <TableModal
          open={isModalOpen}
          setOpen={setModalOpen}
          tableName="Вице-премьеры"
        >
          <RenderTable {...props} />
        </TableModal>
      )}
    </>
  );
};
