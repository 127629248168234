import { createSlice } from "@reduxjs/toolkit";

export enum EGridTabs {
  PRIORITIES = "PRIORITIES",
  PROGRAMS = "PROGRAMS",
  PREMIERS = "PREMIERS",
}

export interface IFiltersState {
  complexGridTab: EGridTabs;
  selectedSearchRows: Record<string, any>[];
  program: string;
  vp: string;
  ministry: string;
}

const initialState: IFiltersState = {
  complexGridTab: EGridTabs.PRIORITIES,
  selectedSearchRows: [],
  program: "",
  vp: "",
  ministry: "",
};

const slice = createSlice({
  name: "filtersPriorities",
  initialState,
  reducers: {
    clear: () => initialState,
    setComplexGridTab: (state, action: { payload: EGridTabs }) => {
      state.complexGridTab = action.payload;
    },
    selectSearchRows: (
      state,
      action: {
        payload: Record<string, any>[];
      }
    ) => {
      state.selectedSearchRows = action.payload;
    },
    selectProgram: (state, action: { payload: string }) => {
      state.program = action.payload;
    },
    selectVP: (state, action: { payload: string }) => {
      state.vp = action.payload;
    },
    selectMinistry: (state, action: { payload: string }) => {
      state.ministry = action.payload;
    },
  },
});

export default slice;
