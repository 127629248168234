import { styled } from "@mui/material";

export const Wrapper = styled("div")(({ theme }) => {
  return {
    width: "100%",
    ".incident-container": {
      background: theme.palette.background.default,
      padding: "0.5rem 2.5rem",
      fontSize: "1rem",
      flex: 1,
    },
  };
});
