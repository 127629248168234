import { Tooltip } from "@mui/material";
import { TooltipProps } from "@mui/material/Tooltip/Tooltip";
import React from "react";
import { CheckSecretData } from "SRC/components/CheckSecretData";
import { NavigateCell } from "SRC/components/Table";
import { TableCell } from "SRC/components/TableCell";
import { ETitle } from "SRC/components/TableColumnTemplates/columns/indicators/indicatorsFact";
import { sortIndicatorsSEFailColumn } from "SRC/helpers";
import { ICustomTableProps } from "SRC/types";

import { getIndicatorsSEFail, getIndicatorsSEFailTotal } from "./utils";

export const indicatorsSEFail = (isOM: boolean) => ({
  Header: "Не выполнено",
  Tooltip: ({ children, ...props }: TooltipProps) => (
    <Tooltip {...props} title={ETitle.FAIL_TITLE}>
      {children}
    </Tooltip>
  ),
  sortType: sortIndicatorsSEFailColumn(isOM),
  width: 130,
  accessor: isOM ? "indicatorsSEOMFail" : "indicatorsSEFail",
  Cell: (tableProps: ICustomTableProps) => {
    const { url, indicatorsSEFail, isSecret } = getIndicatorsSEFail(
      tableProps,
      isOM
    );

    return (
      <NavigateCell url={url}>
        <TableCell color={isOM ? "paleRed" : "red"}>
          <CheckSecretData isSecret={isSecret}>
            {indicatorsSEFail}
          </CheckSecretData>
        </TableCell>
      </NavigateCell>
    );
  },
  Footer: (tableProps: ICustomTableProps) => {
    const { indicatorsSEFailTotal } = getIndicatorsSEFailTotal(
      tableProps,
      isOM
    );

    return (
      <Tooltip title="В расчете участвуют уникальные элементы">
        <TableCell color={isOM ? "paleRed" : "red"}>
          {indicatorsSEFailTotal}
        </TableCell>
      </Tooltip>
    );
  },
});
