// import CloseIcon from "@mui/icons-material/Close";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Alert, CircularProgress, SnackbarContent } from "@mui/material";
// import IconButton from "@mui/material/IconButton";
import MUISnackbar from "@mui/material/Snackbar";
import React from "react";
import ReactDOM from "react-dom";
import { useDispatch } from "react-redux";
import { css } from "SRC/components/Snackbar/Snackbar.styled";
import { useAppSelector } from "SRC/redux/hooks";
import slice from "SRC/redux/slices/global/slices/notification";

const ROOT = document.getElementById("root") as HTMLElement;

const Snackbar = () => {
  const dispatch = useDispatch();

  const { open, message, error } = useAppSelector(
    (state) => state.global.notification
  );

  const handleClick = () => {
    dispatch(slice.actions.open(false));
    // dispatch(slice.actions.isCanceled(true));
  };

  return ReactDOM.createPortal(
    <MUISnackbar
      sx={css.snackbar}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      open={open}
      onClose={handleClick}
      autoHideDuration={error ? 2500 : undefined}
    >
      {!error ? (
        <SnackbarContent
          message={message}
          sx={css.snackbarContent}
          action={
            <>
              <CircularProgress size="2rem" sx={css.progress} />
              {/*реализация прекращения загрузки*/}

              {/*<IconButton*/}
              {/*  sx={{ p: 0.5 }}*/}
              {/*  aria-label="close"*/}
              {/*  color="inherit"*/}
              {/*  onClick={handleClick}*/}
              {/*>*/}
              {/*  <CloseIcon fontSize="small" />*/}
              {/*</IconButton>*/}
            </>
          }
        />
      ) : (
        <Alert
          sx={css.errorAlert}
          icon={<ErrorOutlineIcon fontSize="large" />}
          variant="filled"
          severity="error"
        >
          {error}
        </Alert>
      )}
    </MUISnackbar>,
    ROOT
  );
};

export default Snackbar;
