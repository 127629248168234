import React from "react";
import { CustomCheckbox } from "SRC/components/CustomCheckbox/CustomCheckbox";
import { useGlobalFilters } from "SRC/redux/slices/global/hooks/useFilters";

interface IUnderGpCheckbox {
  checked: boolean;
}

const LABEL = "Спущен с уровня ГП";

export const UnderGpCheckbox = ({ checked }: IUnderGpCheckbox) => {
  const { toggleIsUnder } = useGlobalFilters();

  return (
    <CustomCheckbox onChange={toggleIsUnder} checked={checked} label={LABEL} />
  );
};
