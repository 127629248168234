import { styled, Theme } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";

export const FormControlLabelStyled = styled(FormControlLabel)(({ theme }) => {
  return {
    height: "2.5rem",
    userSelect: "none",
    "& .MuiCheckbox-root": {
      color: theme.palette.primary.contrastText,
    },
    "& .Mui-checked": {
      color: theme.palette.pale.paleRed,
    },
  };
});
