import { getDataSumByCodeVP } from "SRC/constants";
import { IPremierTableItem } from "SRC/hooks/summaryTable/interfaces";
import { getGovProgramsValue } from "SRC/hooks/summaryTable/utils/summaryData/summaryDataGP";
import { generalRowsSummaryTableData } from "SRC/hooks/summaryTable/utils/utils";
import { TPremier } from "SRC/redux/slices/summary/hooks/usePremiers";
import { CustomRowProps } from "SRC/types/data/data";

export const createPremierRow = (data: TPremier, index: number) => {
  const { info } = data;
  const elem = { ...data, data: Object.values(data.data) };

  return {
    id: index,
    isPremier: true,
    code: info.VP_CODE,
    name: info.VP_NAME,
    vicePremier: info.VP_SHORT_NAME,
    vicePremierPhoto: info.VP_PHOTO_URL,
    structureElementsPlan: getDataSumByCodeVP(elem, 1693) || null,
    structureElementsPlanTotal: getDataSumByCodeVP(elem, 1696) || null,
    structureElementsOMPlan: getDataSumByCodeVP(elem, 5448) || null,
    structureElementsOMPlanTotal: getDataSumByCodeVP(elem, 5458) || null,
    structureElementsFact: getDataSumByCodeVP(elem, 5374) || null,
    structureElementsFactTotal: getDataSumByCodeVP(elem, 5375) || null,
    structureElementsOMFact: getDataSumByCodeVP(elem, 5449) || null,
    structureElementsOMFactTotal: getDataSumByCodeVP(elem, 5459) || null,
    structureElementsFail: getDataSumByCodeVP(elem, 1695) || null,
    structureElementsFailTotal: getDataSumByCodeVP(elem, 1698) || null,
    structureElementsOMFail: getDataSumByCodeVP(elem, 5450) || null,
    structureElementsOMFailTotal: getDataSumByCodeVP(elem, 5460) || null,
    govPrograms: getDataSumByCodeVP(elem, 1742) || null,
    performance: getDataSumByCodeVP(elem, 1797) || null,
    performanceTotal: getDataSumByCodeVP(elem, 5248) || null,
    populationSatisfaction: getDataSumByCodeVP(elem, 1764) || null,
    cashPlan: getDataSumByCodeVP(elem, 1711) || null,
    cashPlanTotal: getDataSumByCodeVP(elem, 1714) || null,
    cashFact: getDataSumByCodeVP(elem, 1712) || null,
    cashFactTotal: getDataSumByCodeVP(elem, 1715) || null,
    goalsPlan: getDataSumByCodeVP(elem, 1761) || null,
    goalsDone: getDataSumByCodeVP(elem, 1762) || null,
    goalsFail: getDataSumByCodeVP(elem, 1763) || null,
    indicatorsPlan: getDataSumByCodeVP(elem, 1699) || null,
    indicatorsPlanTotal: getDataSumByCodeVP(elem, 1702) || null,
    indicatorsOMPlan: getDataSumByCodeVP(elem, 5392) || null,
    indicatorsOMPlanTotal: getDataSumByCodeVP(elem, 5455) || null,
    indicatorsFact: getDataSumByCodeVP(elem, 1700) || null,
    indicatorsFactTotal: getDataSumByCodeVP(elem, 1703) || null,
    indicatorsOMFact: getDataSumByCodeVP(elem, 5391) || null,
    indicatorsOMFactTotal: getDataSumByCodeVP(elem, 5456) || null,
    indicatorsFail: getDataSumByCodeVP(elem, 1701) || null,
    indicatorsFailTotal: getDataSumByCodeVP(elem, 1704) || null,
    indicatorsOMFail: getDataSumByCodeVP(elem, 5447) || null,
    indicatorsOMFailTotal: getDataSumByCodeVP(elem, 5457) || null,
    // Показатели СЭ Факт
    indicatorsSEFact: getDataSumByCodeVP(elem, 5301) || null,
    indicatorsSEFactTotal: getDataSumByCodeVP(elem, 5304) || null,
    indicatorsSEOMFact: getDataSumByCodeVP(elem, 5500) || null,
    indicatorsSEOMFactTotal: getDataSumByCodeVP(elem, 5501) || null,
    // Показатели СЭ план
    indicatorsSEPlan: getDataSumByCodeVP(elem, 5300) || null,
    indicatorsSEPlanTotal: getDataSumByCodeVP(elem, 5503) || null,
    indicatorsSEOMPlan: getDataSumByCodeVP(elem, 5498) || null,
    indicatorsSEOMPlanTotal: getDataSumByCodeVP(elem, 5499) || null,
    // Показатели СЭ не выполнено
    indicatorsSEFail: getDataSumByCodeVP(elem, 5302) || null,
    indicatorsSEFailTotal: getDataSumByCodeVP(elem, 5305) || null,
    indicatorsSEOMFail: getDataSumByCodeVP(elem, 5502) || null,
    indicatorsSEOMFailTotal: getDataSumByCodeVP(elem, 5503) || null,
    eventsPlan: getDataSumByCodeVP(elem, 1705) || null,
    eventsPlanTotal: getDataSumByCodeVP(elem, 1708) || null,
    eventsFact: getDataSumByCodeVP(elem, 1706) || null,
    eventsFactTotal: getDataSumByCodeVP(elem, 1709) || null,
    eventsOMFact: getDataSumByCodeVP(elem, 5393) || null,
    eventsOMFactTotal: getDataSumByCodeVP(elem, 5462) || null,
    eventsFail: getDataSumByCodeVP(elem, 1707) || null,
    eventsFailTotal: getDataSumByCodeVP(elem, 1710) || null,
    eventsOMFail: getDataSumByCodeVP(elem, 5451) || null,
    eventsOMFailTotal: getDataSumByCodeVP(elem, 5463) || null,
    eventsOMPlan: getDataSumByCodeVP(elem, 5394) || null,
    eventsOMPlanTotal: getDataSumByCodeVP(elem, 1708) || null,
    checkpointsPlan: getDataSumByCodeVP(elem, 5253) || null,
    checkpointsFact: getDataSumByCodeVP(elem, 5254) || null,
    checkpointsFail: getDataSumByCodeVP(elem, 5255) || null,
    checkpointsPlanTotal: getDataSumByCodeVP(elem, 5256) || null,
    checkpointsFactTotal: getDataSumByCodeVP(elem, 5257) || null,
    checkpointsFailTotal: getDataSumByCodeVP(elem, 5258) || null,
    //Контрольные точки ОМ
    checkpointsOMFact: getDataSumByCodeVP(elem, 5453) || null,
    checkpointsOMFactTotal: getDataSumByCodeVP(elem, 5465) || null,
    checkpointsOMPlan: getDataSumByCodeVP(elem, 5452) || null,
    checkpointsOMPlanTotal: getDataSumByCodeVP(elem, 5464) || null,
    checkpointsOMFail: getDataSumByCodeVP(elem, 5454) || null,
    checkpointsOMFailTotal: getDataSumByCodeVP(elem, 5466) || null,
    // Уровень достижения особое мнение
    performanceOM: getDataSumByCodeVP(elem, 5266) || null,
    // Уровень достижения особое мнение итого
    performanceOMTotal: getDataSumByCodeVP(elem, 5272) || null,
  };
};

export const createPremiersTableData = (
  data: TPremier[]
): IPremierTableItem[] =>
  data.map((elem: TPremier, index: number) => {
    return createPremierRow(elem, index + 1);
  });

export const createPremierRowsTableData = (
  data: CustomRowProps[],
  isOM: boolean,
  withSecret?: boolean
) =>
  data.map((elem: CustomRowProps, index) => {
    const { vicePremier, govProgram, govPrograms } = elem.original;

    return {
      id: index + 1,
      code: elem.original.code,
      vicePremier: elem.depth ? govProgram : vicePremier,
      govPrograms: withSecret ? govPrograms : getGovProgramsValue(elem),
      ...generalRowsSummaryTableData(elem, isOM),
    };
  });
