import { getIsSecret } from "SRC/pages/Summary/common/SummaryComplexGrid/utils";
import { ICustomTableProps } from "SRC/types";

export const getStructureElementsPlan = (
  tableProps: ICustomTableProps,
  isOM: boolean
) => {
  const { structureElementsPlan, structureElementsOMPlan } =
    tableProps.row.original;

  return {
    isSecret: getIsSecret(tableProps),
    structureElementsPlan: isOM
      ? structureElementsOMPlan
      : structureElementsPlan,
  };
};
