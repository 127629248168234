import { actions as events } from "./slices/events";
import { actions as goalConnect } from "./slices/goalConnect";
import { actions as gpStructure } from "./slices/gpStructure";
import { actions as indicators } from "./slices/indicators";
import { actions as program } from "./slices/program";
import { actions as programGoals } from "./slices/programGoals";
import { actions as reports } from "./slices/reports";
import { actions as resultCharacteristic } from "./slices/resultCharacteristic";
import { actions as statistics } from "./slices/statistics";
import {
  structureElementsActions,
  structureElementsGPActions,
  structureElementsIndicatorsActions,
  structureElementsTasksActions,
} from "./slices/structureElements";
import { actions as tableType } from "./slices/tableType";
import { actions as workbook } from "./slices/workbook";

export interface IGosprogramActions {
  programGoals: typeof programGoals;
  events: typeof events;
  indicators: typeof indicators;
  reports: typeof reports;
  statistics: typeof statistics;
  tableType: typeof tableType;
  resultCharacteristic: typeof resultCharacteristic;
  goalConnect: typeof goalConnect;
  program: typeof program;
  gpStructure: typeof gpStructure;
  structureElements: typeof structureElementsActions;
  structureElementsGP: typeof structureElementsGPActions;
  structureElementsIndicators: typeof structureElementsIndicatorsActions;
  structureElementsTasks: typeof structureElementsTasksActions;
  workbook: typeof workbook;
}

const actions: IGosprogramActions = {
  programGoals,
  events,
  indicators,
  reports,
  statistics,
  tableType,
  resultCharacteristic,
  goalConnect,
  gpStructure,
  program,
  structureElements: structureElementsActions,
  structureElementsGP: structureElementsGPActions,
  structureElementsIndicators: structureElementsIndicatorsActions,
  structureElementsTasks: structureElementsTasksActions,
  workbook,
};

export default actions;
