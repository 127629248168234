import { IAchievementData } from "SRC/components/AchievementsTile/interfaces";

export const topData: IAchievementData[] = [
  {
    name: "Уровень достижения ГП",
    sum: 86,
    url: "https://nc.planetanalytics.ru/icons/46.png",
  },
  {
    name: "Уровень достижения ГП",
    sum: 75,
    url: "https://nc.planetanalytics.ru/icons/01.png",
  },
  {
    name: "Уровень достижения ГП",
    sum: 24,
    url: "https://nc.planetanalytics.ru/icons/02.png",
  },
  {
    name: "Уровень достижения ГП",
    sum: 15,
    url: "https://nc.planetanalytics.ru/icons/03.png",
  },
  {
    name: "Уровень достижения ГП",
    sum: 11,
    url: "https://nc.planetanalytics.ru/icons/04.png",
  },
];

export const topDataVP: IAchievementData[] = [
  {
    name: "Абрамченко В.В.",
    sum: 86,
    url: "https://gasu.gov.ru/pl/icons/ng/person/Abramchenko.png",
  },
  {
    name: "Белоусов А.Р.",
    sum: 75,
    url: "https://gasu.gov.ru/pl/icons/ng/person/Belousov.png",
  },
  {
    name: "Борисов Ю.И.",
    sum: 24,
    url: "https://gasu.gov.ru/pl/icons/ng/person/Borisov.png",
  },
  {
    name: "Голикова Т.А.",
    sum: 15,
    url: "https://gasu.gov.ru/pl/icons/ng/person/Golikova.png",
  },
  {
    name: "Григоренко Д.Ю.",
    sum: 11,
    url: "https://gasu.gov.ru/pl/icons/ng/person/Grigorenko.png",
  },
];
