import { WarningTriangle } from "ASSETS/svg";
import classnames from "classnames";
import React, { useMemo } from "react";
import { IncidentReport } from "SRC/components/IncidentReport";
import { OverlayBlock } from "SRC/components/OverlayBlock";
import { PerformanceValue } from "SRC/components/PerformanceValue/PerformanceValue";
import UnitSelect from "SRC/components/UnitSelect/UnitSelect";
import { WIPTag } from "SRC/components/WIP";
import { convertSourceValue } from "SRC/constants/globals";
import { getPercentValue } from "SRC/helpers/getPercentValue";
import { IDataSideBar } from "SRC/pages/Event/interfaces";
import { useEventData } from "SRC/redux/slices/event/hooks/useEventData";
import { useFilters } from "SRC/redux/slices/event/hooks/useFilters";
import {
  IGlobalFilters,
  useGlobalFilters,
} from "SRC/redux/slices/global/hooks/useFilters";
import { useStats } from "SRC/redux/slices/main/hooks/useStats";

import { convertByDigit, prepareData } from "../../util/utils";
import { Wrapper } from "./Sidebar.styled";

export const Sidebar = () => {
  const { items: event, fetching } = useEventData();
  const { selectedFinanceUnit } = useFilters();
  const { sources }: IGlobalFilters = useGlobalFilters();
  const { items: stats } = useStats();
  const statsData = useMemo(() => stats[0]?.data || {}, [stats]);

  const financingData = statsData["Дата состояния финансирования"];

  const data = useMemo<IDataSideBar>(
    () => prepareData(event),
    [selectedFinanceUnit, event]
  );
  const value = useMemo(() => {
    const sourceValue = convertSourceValue(data.performance, sources)[0];

    return String(getPercentValue(sourceValue?.value || 0));
  }, [data, sources]);

  const getSidebarValue = (value: number | undefined) => {
    return typeof value === "number"
      ? convertByDigit(value, selectedFinanceUnit)
      : "-";
  };

  return (
    <OverlayBlock isFetching={fetching} hasData={Boolean(event)}>
      <Wrapper>
        <IncidentReport className="incident-container">
          <div className="title">
            Уровень достижения
            <div className="wrapper-value">
              <PerformanceValue
                value={value}
                sameSize={true}
                className="performance-value"
              />
              <span>%</span>
            </div>
          </div>
          <div className="title">
            Количество корректировок <span>значение / срок</span>
          </div>
          <div className="values-container">
            <div className="value-row">
              <div className="name">ухудшилось</div>
              <div className="value done">
                <span>{data.corrections.value.worse ?? "-"}</span>
                <span>{data.corrections.time.worse ?? "-"}</span>
              </div>
            </div>
            <div className="value-row">
              <div className="name">улучшилось</div>
              <div className="value fail">
                <span>{data.corrections.value.better ?? "-"}</span>
                <span>{data.corrections.time.better ?? "-"}</span>
              </div>
            </div>
          </div>
          <WIPTag
            sx={{
              marginTop: "0.5rem",
              display: "inline-block",
              flexWrap: "nowrap",
            }}
          />
          <hr />

          <div className="title">
            Значение мероприятия <span>ед.</span>
            {data.event.atRisk !== 0 && (
              <div className="risk">
                <WarningTriangle />
              </div>
            )}
          </div>
          <div className="values-container">
            <div className="value-row">
              <div className="name">план</div>
              <div
                className={classnames("value", {
                  "is-om": sources.OM,
                })}
              >
                {data.event.plan ?? "-"}
              </div>
            </div>
            <div className="value-row">
              <div className="name">факт</div>
              <div
                className={classnames("value", {
                  "is-om": sources.OM,
                })}
              >
                {convertSourceValue(data.event.fact, sources)[0]?.value || "-"}
              </div>
            </div>
            <div className="value-row">
              <div className="name">прогноз</div>
              <div className="value">{data.event.forecast ?? "-"}</div>
            </div>
          </div>
          <hr />

          <div className="title">
            Срок реализации
            {/*TODO нет данных для иконки ПодРиском*/}
            {false ?? (
              <div className="risk">
                <WarningTriangle />
              </div>
            )}
          </div>
          <div className="values-container">
            <div className="value-row">
              <div className="name">план</div>
              <div className="value">{data.realization.plan ?? "-"}</div>
            </div>
            <div className="value-row">
              <div className="name">факт</div>
              <div className="value">{data.realization.fact ?? "-"}</div>
            </div>
          </div>
          <hr />

          <div className="title">
            <div className="title-row">
              <div>
                {/*TODO нет данных на дату*/}
                Финансирование <span>на {financingData}</span>
              </div>
              <UnitSelect />
            </div>
            {/*TODO нет данных для иконки ПодРиском*/}
            {false ?? (
              <div className="risk">
                <WarningTriangle />
              </div>
            )}
          </div>
          <div className="values-container">
            <div className="value-row">
              <div className="name">роспись (план)</div>
              <div className="value">
                {getSidebarValue(data.financing.plan)}
              </div>
            </div>
            <div className="value-row">
              <div className="name">факт</div>
              <div className="value fail">
                {getSidebarValue(data.financing.fact)}
              </div>
            </div>
          </div>
        </IncidentReport>
      </Wrapper>
    </OverlayBlock>
  );
};
