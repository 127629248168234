import { useMemo } from "react";
import { useGlobalFilters } from "SRC/redux/slices/global/hooks/useFilters";
import { useProgramGoals } from "SRC/redux/slices/gosprogram/hooks";
import { IProgramGoal } from "SRC/types";

interface IIndicatorsData {
  indicators: IProgramGoal[];
  analyticIndicators: IProgramGoal[];
}

interface IUseIndicatorsData extends IIndicatorsData {
  fetching: boolean;
}

export const useProgramIndicators = (): IUseIndicatorsData => {
  const { items: indicatorsData, fetching } = useProgramGoals();
  const { inPerformance, sources } = useGlobalFilters();

  const filterIndicatorsData = useMemo(() => {
    return indicatorsData.filter((item) => {
      const inPerformanceFilterFOIV =
        !inPerformance || Boolean(item["Участвует в расчёте УД"]);
      const inPerformanceFilterOM =
        (!inPerformance && sources.OM) ||
        Boolean(item["Участвует в расчёте УД ОМ"]);
      return inPerformanceFilterFOIV || inPerformanceFilterOM;
    });
  }, [inPerformance, indicatorsData, sources]);

  const { indicators, analyticIndicators } = useMemo(() => {
    return filterIndicatorsData.reduce<IIndicatorsData>(
      (acc, indicator: IProgramGoal) => {
        const { indicators, analyticIndicators } = acc;
        const isAnalytical = indicator["Аналитический"];

        if (isAnalytical) {
          analyticIndicators.push(indicator);
        } else {
          indicators.push(indicator);
        }

        return {
          indicators,
          analyticIndicators,
        };
      },
      { indicators: [], analyticIndicators: [] }
    );
  }, [filterIndicatorsData]);

  return {
    indicators,
    analyticIndicators,
    fetching,
  };
};
