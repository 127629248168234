import {
  Box,
  Button,
  Grid,
  SxProps,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { ReactComponent as CloseButton } from "ASSETS/svg/close/close.svg";
import React, { Dispatch, SetStateAction, useMemo, useState } from "react";
import TableStatusCard from "SRC/components/TableStatusCard/TableStatusCard";
import { IStatus } from "SRC/pages/Program/common/ProgramSummary/interfaces";
import { css } from "SRC/pages/Program/common/StructureCard/StructureCard.styled";
import { StructureTabs } from "SRC/pages/Program/common/StructureCard/StructureTabs/StructureTabs";
import { useFilters } from "SRC/redux/slices/gosprogram/hooks/useFilters";
import { IStructureElement } from "SRC/types";

interface IProps {
  structures: IStructureElement[];
  setOpen?: Dispatch<SetStateAction<boolean>>;
  setEventModalMode?: Dispatch<
    SetStateAction<"default" | "eventCard" | "eventsFilter">
  >;
  openTableModal?: boolean;
  statuses: IStatus[];
}

export const StructureCard = ({
  structures,
  setOpen,
  setEventModalMode,
  openTableModal,
  statuses,
}: IProps) => {
  const {
    setSelectedStructureId,
    setSelectedStructureRow,
    selectedStructureRow,
  } = useFilters();

  const totalEvents = structures.reduce(
    (a, b) => a + b["Мероприятий, всего"],
    0
  );

  const theme = useTheme();
  const isExtraLarge = useMediaQuery(theme.breakpoints.up("xl"));
  const [activeTab, setActiveTab] = useState(0);

  const handleReset = () => {
    setActiveTab(0);
    setSelectedStructureId("");
    setSelectedStructureRow("");
    setEventModalMode?.("default");
  };

  const statusesNode: JSX.Element[] = useMemo(() => {
    return statuses.map((item, i) => (
      <TableStatusCard
        key={i}
        status={item.status}
        amount={item.amount}
        sxContainer={css.tableStatusContainer as SxProps}
      />
    ));
  }, [statuses]);

  return (
    <Box sx={css.structureWrapper(openTableModal)}>
      <Grid item container sx={css.titleWrapper}>
        <Typography sx={css.headerTitle}>
          Выбор структурного элемента
        </Typography>
        {!openTableModal && !isExtraLarge && (
          <Box
            sx={css.closeButton}
            onClick={() => {
              setOpen?.(false);
              setEventModalMode?.("default");
            }}
          >
            <CloseButton />
          </Box>
        )}
      </Grid>
      <Grid item container>
        <Box sx={{ width: "100%" }}>
          <StructureTabs activeTab={activeTab} onChange={setActiveTab} />
        </Box>
      </Grid>
      <Grid item container sx={css.filterStatusesContainer}>
        <Box sx={css.statusesContainer}>
          <Box sx={css.totalContainer}>
            <Typography sx={css.totalTitle}>
              Всего <br />
              мероприятий
            </Typography>
            <Typography sx={css.totalCount}>{totalEvents}</Typography>
          </Box>
          {statusesNode}
        </Box>
        <Box sx={css.buttonContainer}>
          <Button
            variant="contained"
            sx={css.statusesButton}
            onClick={() => {
              setSelectedStructureId?.(String(selectedStructureRow));
              setOpen?.(false);
              setEventModalMode?.("default");
            }}
          >
            Показать мероприятия
          </Button>
          <Button
            onClick={handleReset}
            variant="outlined"
            sx={css.statusesButton}
          >
            Сбросить фильтр
          </Button>
        </Box>
      </Grid>
    </Box>
  );
};
