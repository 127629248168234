import React from "react";
import Breadcrumbs from "SRC/components/Breadcrumbs/Breadcrumbs";
import { Layout } from "SRC/layouts";
import Controller from "SRC/redux/stores/ProgramsBoard";

import { CenterLayout } from "./layouts";

export const MinistriesBoard = () => {
  const links = [
    {
      title: "Госпрограммы",
    },
  ];

  return (
    <>
      <Breadcrumbs links={links} />
      <Layout center={<CenterLayout />} />
    </>
  );
};
