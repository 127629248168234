import { olapRequestData2 } from "CORE/api/core";
import { MODEL_UUID, SECTION_NAMES } from "CORE/scheme/olap";
import { IStatParams } from "SRC/redux/slices/main/slices/stats";

import { QUERY_KEY } from "./consts";

export const getMainStats = (params: IStatParams) => {
  return olapRequestData2(
    {
      dimensions: [],
      indicators: {},
      section_name: SECTION_NAMES.STATS,
      modelUuid: MODEL_UUID,
      filters: {
        period_code: params.period.filterValue,
        gp_code: params.gp_code,
        vp_code: params.vp_code,
        sphere: params.sphere,
        foiv: params.foiv,
      },
    },
    QUERY_KEY
  );
};
