import { Box } from "@mui/material";
import React, { useMemo } from "react";
import { Column } from "react-table";
import Table from "SRC/components/Table";
import { getIndicatorsColumns } from "SRC/pages/Ministry/common/IndicatorsTab/tableColumns";
import { css } from "SRC/pages/Ministry/common/ministriesTabs.styled";
import { Statuses } from "SRC/pages/Ministry/common/Statuses/Statuses";
import {
  IGlobalFilters,
  useGlobalFilters,
} from "SRC/redux/slices/global/hooks/useFilters";

import { useGpIndicatorsTableData } from "../../tableData/useGpIndicatorsTableData";

const RenderTable = () => {
  const { sources }: IGlobalFilters = useGlobalFilters();
  const columns: Column[] = useMemo(
    () => getIndicatorsColumns(sources.OM),
    [sources.OM]
  );
  const { gpIndicatorsTableData: data, gpIndicatorsStatuses: statuses } =
    useGpIndicatorsTableData();

  return (
    <Box sx={css.modalContainer}>
      <Statuses statuses={statuses} />
      <Table
        data={data}
        columns={columns}
        sortedColumns={[
          "Период достижения",
          "Факт",
          "План",
          "План на год",
          "Уровень достижения, %",
        ]}
      />
    </Box>
  );
};

export const IndicatorsTab = () => {
  return <RenderTable />;
};
