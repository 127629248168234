import classnames from "classnames";
import moment from "moment";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { OverlayBlock } from "SRC/components/OverlayBlock";
import { ProgramTile } from "SRC/components/ProgramTile/ProgramTile";
import { ESources } from "SRC/constants";
import { useGlobalFilters } from "SRC/redux/slices/global/hooks/useFilters";
import { IFilters, useFilters } from "SRC/redux/slices/main/hooks/useFilters";
import { IProgram, usePrograms } from "SRC/redux/slices/main/hooks/usePrograms";

import { StackWrapper } from "./ProgramList.styled";

export const ProgramList = () => {
  const selectedRef = useRef<HTMLSpanElement | null>(null);
  const { sources, period, calendarDate } = useGlobalFilters();
  const { items, fetching, load } = usePrograms();
  const {
    program,
    filteredPrograms,
    setSelectedProgram,
    setSelectedGoal,
  }: IFilters = useFilters();
  const [hasChanges, setHasChanges] = useState<boolean | undefined>();
  useEffect(() => {
    setHasChanges(!moment(period.value).isSame(calendarDate));
  }, [period, calendarDate]);

  useEffect(() => {
    if (hasChanges) {
      load(period);
    }
  }, [period, calendarDate]);

  const handleOnClick = useCallback(
    ({ info: { code } }: IProgram) => {
      if (!filteredPrograms.includes(code)) {
        setSelectedGoal("");
      }
      setSelectedProgram(program === code ? "" : code);
    },
    [program, setSelectedProgram, setSelectedGoal, filteredPrograms]
  );

  const programs = useMemo(
    (): IProgram[] =>
      items
        .filter(({ info }: IProgram) => filteredPrograms.includes(info.code))
        .sort(({ data: d1 }: IProgram, { data: d2 }: IProgram) => {
          if (sources[ESources.OM]) {
            return Number(d2[5265]?.sum) - Number(d1[5265]?.sum);
          } else {
            return Number(d2[1796]?.sum) - Number(d1[1796]?.sum);
          }
        })
        .sort((d1: IProgram, d2: IProgram) => {
          return Number(d1.info.secret) - Number(d2.info.secret);
        }),
    [items, filteredPrograms, sources]
  );

  useEffect(() => {
    const spanElement = selectedRef.current;
    if (spanElement) {
      spanElement.scrollIntoView();
    }
  }, []);

  return (
    <OverlayBlock isFetching={fetching} hasData={Boolean(items)}>
      <StackWrapper
        className={classnames({
          "has-selected": program,
        })}
        data-scroll={true}
      >
        {programs.map((item: IProgram) => {
          const isSelected = program === item.info.code;

          return (
            <>
              {isSelected && <span ref={selectedRef} />}
              <ProgramTile
                key={item.info.code}
                className={classnames({ "is-selected": isSelected })}
                program={item}
                onClick={handleOnClick}
              />
            </>
          );
        })}
      </StackWrapper>
    </OverlayBlock>
  );
};
