import { Box, Grid } from "@mui/material";
import React from "react";

import { css } from "./Layout.styles";

interface ILayoutProps {
  left?: React.ReactNode;
  center?: React.ReactNode;
  right?: React.ReactNode;
  isProgramLayout?: boolean;
}
export const Layout = ({
  left,
  center,
  right,
  isProgramLayout,
}: ILayoutProps) => (
  <Grid container sx={css.container}>
    {center && (
      <Grid item xs={12} lg={4} xl={6} gap={5} sx={css.center}>
        {center}
      </Grid>
    )}
    {right && (
      <Grid item xs={12} lg={4} xl={3} sx={css.right(isProgramLayout)}>
        <Box sx={css.gridWrapper}>{right}</Box>
      </Grid>
    )}
    {left && (
      <Grid item xs={12} lg={4} xl={3} sx={css.left}>
        <Box sx={css.gridWrapper}>{left}</Box>
      </Grid>
    )}
  </Grid>
);
