import React from "react";
import { ProgressDoughnutPale } from "SRC/components/Charts";
import ProgressDoughnutFinancing from "SRC/components/Charts/ProgressDoughnutFinancing/ProgressDoughnutFinancing";
import { OverlayBlock } from "SRC/components/OverlayBlock";
import { StatusLabel } from "SRC/components/StatusLabel/StatusLabel";
import { data } from "SRC/pages/VicePremier/vicePremierData";
import { useStats } from "SRC/redux/slices/main/hooks/useStats";

import { Wrapper } from "./Achievements.styled";

export const Achievements = () => {
  const { items: stats, fetching: fetchingStats } = useStats();

  const title = (
    <>
      По ключевым <br /> приоритетам
    </>
  );

  return (
    <Wrapper>
      <div className="premiers-achievement">
        <div className="title">Уровень достижения ГП Вице-премьера</div>
        <OverlayBlock isFetching={fetchingStats} hasData={Boolean(stats)}>
          <div className="chart">
            <ProgressDoughnutPale
              total={data.chartPaleTotal}
              values={data.chartPaleValues}
              title="По открытым госпрограммам"
            />
          </div>
        </OverlayBlock>
      </div>
      <div className="premiers-achievement">
        <div className="title">Уровень достижения KП Вице-премьера</div>
        <OverlayBlock isFetching={fetchingStats} hasData={Boolean(stats)}>
          <div className="chart">
            <ProgressDoughnutFinancing
              value={data.chartValue}
              total={data.chartTotal}
              forAchievements={true}
              title={title}
            />
          </div>
        </OverlayBlock>
        <div className="statuses">
          <StatusLabel
            className="status-label"
            label="done"
            name="Достигнуто"
          />
          <span>{data.chartUnits} ед.</span>
          <StatusLabel
            className="status-label"
            label="fail"
            name="Не достигнуто"
          />
          <span>{data.chartUnits} ед.</span>
        </div>
      </div>
    </Wrapper>
  );
};
