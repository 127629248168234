import { selectors, useAppSelector } from "../../../hooks";
import { ISEData, ISEParams, seApi } from "../api";

export interface ISEDatas {
  fetching: boolean;
  error: boolean;
  data?: ISEData | null;
}

export const useSEData = (id: string | number, pId: string): ISEDatas => {
  const { period } = useAppSelector(selectors.globalFilters);
  const params: ISEParams = { id, period: period.apiV2, programId: pId };
  const { data, isFetching, isError } = seApi.useGetSEDataQuery(params);

  return {
    fetching: isFetching,
    data,
    error: isError,
  };
};
