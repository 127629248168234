import { olapDimensionRequestData } from "CORE/api/core";

const GET_STRUCTURE_ELEMENTS_TASKS = "get-structure-elements-tasks";

export const getStructureElementsTasks = (gp_code: string | number) => {
  return olapDimensionRequestData(
    [
      {
        requestId: "6bcaf7fe-0772-4d3b-a95d-e3153251e4f6",
        type: "data2",
        params: {
          modelUuid: "40d45cfc-6b16-407b-a1c4-f90532b17eaa",
          dashboardUuid: "",
          includeGaps: false,
          dimensions: [
            {
              id: "5395",
              includeItems: true,
              includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
            },
            {
              id: "5396",
              includeItems: true,
              includeAttributesByCodes: [
                "NAME",
                "SHORT_NAME",
                "SORT_ORDER",
                "StateProgramVersionMetaId",
                "StateProgramCode",
                "SE_TYPE",
                "SE_CODE",
                "VersionMetaId",
              ],
            },
            {
              id: "5397",
              includeItems: true,
              includeAttributesByCodes: [
                "NAME",
                "SHORT_NAME",
                "SORT_ORDER",
                "SE_CODE",
                "SE_TASK_CODE",
                "SE_TASK_TYPE",
              ],
            },
          ],
          indicators: {
            id: "5398",
            items: [
              {
                id: "5399",
                aggregationFunction: "sum",
              },
              {
                id: "5400",
                aggregationFunction: "sum",
              },
            ],
          },
          dataRequestCaching: true,
          dataFilter: {
            type: "AND",
            version: 1,
            operands: [
              {
                type: "AND",
                version: 1,
                operands: [
                  {
                    type: "STARTER",
                    version: 1,
                    dim_id: "5396",
                  },
                  {
                    type: "STARTER",
                    version: 1,
                    dim_id: "5397",
                  },
                ],
              },
              {
                type: "EQ",
                version: 1,
                operands: [
                  {
                    type: "DIM",
                    version: 2,
                    id: "5395",
                    attributeCode: "gp_code",
                  },
                  {
                    type: "TEXT",
                    version: 2,
                    values: [gp_code],
                  },
                ],
              },
            ],
          },
        },
      },
    ],
    GET_STRUCTURE_ELEMENTS_TASKS
  );
};
