import React, { useMemo } from "react";
import { AchievementsTile } from "SRC/components/AchievementsTile/AchievementsTile";
import { IAchievementData } from "SRC/components/AchievementsTile/interfaces";
import Breadcrumbs from "SRC/components/Breadcrumbs/Breadcrumbs";
import { isSecret } from "SRC/helpers/dataTesters";
import { Header } from "SRC/pages/Ministry/common/Header/Header";
import { MinistriesTable } from "SRC/pages/Ministry/common/MinistriesTable/MinistriesTable";
import { createMinistriesData } from "SRC/pages/Ministry/common/utils/utils";
import { StructureElements } from "SRC/pages/Ministry/StrucutreElements/StructureElements";
import {
  IMinistries,
  useMinistries,
} from "SRC/redux/slices/main/hooks/useMinistries";
import { IProgram } from "SRC/redux/slices/main/hooks/usePrograms";
import {
  IFilters,
  useFilters,
} from "SRC/redux/slices/ministry/hooks/useFilters";
import { useMinistry } from "SRC/redux/slices/ministry/hooks/useMinistry";
import { usePrograms } from "SRC/redux/slices/ministry/hooks/usePrograms";

import { StackWrapper } from "./Ministry.styled";

export const Ministry = () => {
  const { ministry }: IFilters = useFilters();
  useMinistry();
  const { filteredPrograms } = useFilters();
  const { items } = usePrograms();
  const { items: minItems }: IMinistries = useMinistries();

  const programs = useMemo<IAchievementData[]>(
    () =>
      items
        .map(({ info, data }: IProgram) => ({
          url: info.icon,
          name: info.name,
          sum: data[1796]?.sum || 0,
          secret: isSecret(info),
          code: info.code,
        }))
        .filter((item) => filteredPrograms.includes(item.code)),
    [items, filteredPrograms]
  );

  const links = [
    {
      title: "Министерства",
    },
    {
      title: "ministry",
    },
  ];

  const ministriesTableData = useMemo(
    () => createMinistriesData(minItems),
    [minItems]
  );

  const ministryData = useMemo(
    () => ministriesTableData.filter((elem) => ministry === elem.name),
    [ministriesTableData, ministry]
  );

  return (
    <StackWrapper>
      <Breadcrumbs links={links} />
      <Header />
      <div className="body">
        <div className="programs">
          <div className="programs-title">
            Госпрограммы
            <span className="counter">{programs.length}</span>{" "}
          </div>
          <div className="programs-container">
            {programs.map((item: IAchievementData) => (
              <AchievementsTile data={item} fetching={false} />
            ))}
          </div>
        </div>
        <div className="divider" />
        <StructureElements ministryData={ministryData} />
      </div>
      <MinistriesTable />
    </StackWrapper>
  );
};
