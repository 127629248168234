import { useMemo } from "react";
import { IEventItem } from "SRC/pages/Program/common/EventsSection/util/interfaces";
import { IndicatorItem } from "SRC/pages/Program/common/IndicatorsTab/interface";
import { IStatus } from "SRC/pages/Program/common/ProgramSummary/interfaces";
import {
  IGlobalFilters,
  useGlobalFilters,
} from "SRC/redux/slices/global/hooks/useFilters";
import { useStructureElements } from "SRC/redux/slices/gosprogram/hooks/structureElements";
import { useEvents } from "SRC/redux/slices/gosprogram/hooks/useEvents";
import {
  IFilters,
  useFilters,
} from "SRC/redux/slices/gosprogram/hooks/useFilters";
import { useIndicators } from "SRC/redux/slices/gosprogram/hooks/useIndicators";
import {
  EVENT_STATUS,
  IIndicatorStatuses,
  TEventStatus,
  TIndicatorStatus,
} from "SRC/types";

import {
  createEventsTableData,
  createIndicatorsTableData,
  isStatusMatch,
  structureElementTypes,
  structureElementTypesMap,
} from "../utils";

interface IEventTypeAll {
  short: string;
  full: string;
}

interface IUseTableData {
  eventsData: IEventItem[];
  eventsStatuses: IStatus[];
  eventsTableData: IEventItem[];
  containedEventFeatures: string[];
  eventTypeAllList: IEventTypeAll[];
  indicatorsData: IndicatorItem[];
  indicatorsStatuses: IStatus[];
  uniqueTypeEventsProjects: string[];
  uniqueEventsTypes: string[];
  uniqueStructureNames: string[];
}

export const useEventsTableData = (): IUseTableData => {
  const { sources, inPerformance }: IGlobalFilters = useGlobalFilters();
  const { items: events = [] } = useEvents();
  const { items: indicators = [] } = useIndicators();
  const { items: structures } = useStructureElements();
  const {
    selectedStatus,
    selectedEventFeature,
    selectedStructureRow,
    selectedStructureElementType,
    selectedStructureElementName,
    selectedStructureElementTask,
    selectedEventTypeName,
    tableState,
  }: IFilters = useFilters();

  const structureCodes = useMemo<string[] | undefined>(
    () =>
      structures.find((item) => item.code === selectedStructureRow)?.eventsCode,
    [selectedStructureRow, structures]
  );

  const eventsTableData = useMemo(() => {
    return createEventsTableData(events);
  }, [events]);

  const instance = useMemo(() => {
    return tableState?.globalFilter;
  }, [tableState?.globalFilter]);

  const eventsData = useMemo(() => {
    return eventsTableData.filter((item) => {
      const structureCodeFilter =
        !structureCodes || structureCodes.includes(String(item?.id));
      const statusFilter =
        !selectedStatus.length || isStatusMatch(selectedStatus, item);
      const eventFeatureFilter =
        !selectedEventFeature || item.features?.includes(selectedEventFeature);
      const structureElementTypeFilter =
        selectedStructureElementType === "Федеральные проекты"
          ? item.structureElement === "ФП"
          : selectedStructureElementType === "Ведомственные проекты"
          ? item.structureElement === "ВП"
          : selectedStructureElementType === "Комплексы процессных мероприятий"
          ? item.structureElement === "КПМ"
          : true;
      const atRiskFilter =
        selectedStatus.includes(EVENT_STATUS.AT_RISK) && !selectedEventFeature
          ? item.atRisk === 1
          : true;
      const structureElementNameFilter =
        !selectedStructureElementName ||
        item.structureElementName === selectedStructureElementName;
      const structureElementTaskFilter =
        !selectedStructureElementTask ||
        item.structureTaskName === selectedStructureElementTask;
      // Если не выбрана галочка "Участвует в УД"
      // или
      // У item есть флаг "Участвуют в расчёте УД" в зависимости от ОМ
      const inPerformanceFilter =
        !inPerformance ||
        (sources.OM ? item.inPerformanceOM : item.inPerformance);

      const eventTypeNameFilter =
        !selectedEventTypeName ||
        item.eventTypeNameShort === selectedEventTypeName ||
        item.eventTypeNameFull === selectedEventTypeName;

      return (
        structureCodeFilter &&
        statusFilter &&
        eventFeatureFilter &&
        structureElementTypeFilter &&
        atRiskFilter &&
        inPerformanceFilter &&
        structureElementNameFilter &&
        eventTypeNameFilter &&
        structureElementTaskFilter
      );
    });
  }, [
    instance,
    eventsTableData,
    structureCodes,
    selectedStatus,
    selectedEventFeature,
    selectedStructureElementType,
    selectedStructureElementName,
    selectedEventTypeName,
    inPerformance,
    sources.OM,
  ]);

  const containedEventFeatures: string[] = useMemo(
    () =>
      Array.from(
        new Set(
          eventsData
            .map((e) => e.features)
            .flat()
            .filter(Boolean) as string[]
        ).values()
      ),
    [eventsData]
  );

  const eventTypeAllList: IEventTypeAll[] = useMemo(() => {
    return Array.from(
      new Set(
        eventsTableData
          .map((e) => ({
            short: e.eventTypeNameShort || "",
            full: e.eventTypeNameFull || "",
          }))
          .flat()
          .filter(
            (value, index, arr) =>
              arr.findIndex((item) => item.short === value.short) === index
          )
          .reverse()
      ).values()
    );
  }, [eventsTableData]);

  const eventsStatuses: IStatus[] = useMemo(() => {
    const eventTotalByStatus = eventsData.reduce(
      (acc: Partial<Record<TEventStatus, IStatus>>, { status }) => ({
        ...acc,
        ...Object.entries(status).reduce(
          (itemAcc: Partial<Record<TEventStatus, IStatus>>, [key, value]) => ({
            ...itemAcc,
            [key as TEventStatus]: {
              status: key,
              amount: Number(value) + (acc[key as TEventStatus]?.amount || 0),
            },
          }),
          {}
        ),
      }),
      {}
    );

    return Object.values(eventTotalByStatus);
  }, [eventsData]);

  // уникальный список Типов структурных элементов (ФП ВП КПМ)
  const uniqueTypeEventsProjects = useMemo(() => {
    return Array.from(
      new Set(
        eventsTableData
          .filter((item) => {
            const structureElementNameFilter =
              !selectedStructureElementName ||
              selectedStructureElementName === item.structureElementName;

            const structureElementTypeFilter =
              !selectedStructureElementType ||
              item.structureElement ===
                structureElementTypes[selectedStructureElementType];

            const structureEventTypeNameFilter =
              !selectedEventTypeName ||
              item.eventTypeNameShort === selectedEventTypeName;

            const currentFeature =
              item.features?.includes(selectedEventFeature);

            const eventFeaturesFilter = !selectedEventFeature || currentFeature;

            return (
              structureElementNameFilter &&
              structureElementTypeFilter &&
              structureEventTypeNameFilter &&
              eventFeaturesFilter
            );
          })
          .map((item) => {
            return structureElementTypesMap[item.structureElement as string];
          })
      )
    );
  }, [
    eventsTableData,
    selectedEventFeature,
    selectedEventTypeName,
    selectedStructureElementName,
    selectedStructureElementType,
  ]);

  // уникальный список типов мероприятий
  const uniqueEventsTypes = useMemo(() => {
    return Array.from(
      new Set(
        eventsTableData
          .filter((item) => {
            const structureElementTypeFilter =
              !selectedStructureElementType ||
              item.structureElement ===
                structureElementTypes[selectedStructureElementType];
            const structureNameFilter =
              !selectedStructureElementName ||
              item.structureElementName === selectedStructureElementName;
            const structureEventTypeNameFilter =
              !selectedEventTypeName ||
              item.eventTypeNameShort === selectedEventTypeName;
            const currentFeature =
              item.features?.includes(selectedEventFeature);

            const eventFeaturesFilter = !selectedEventFeature || currentFeature;

            return (
              structureElementTypeFilter &&
              structureNameFilter &&
              structureEventTypeNameFilter &&
              eventFeaturesFilter
            );
          })
          .map((item) => {
            return item.eventTypeNameShort;
          })
          .filter(Boolean) as string[]
      )
    );
  }, [
    eventsTableData,
    selectedStructureElementType,
    selectedStructureElementName,
    selectedEventTypeName,
    selectedEventFeature,
  ]);

  // уникальный список наименований структурного элемента
  const uniqueStructureNames = useMemo(() => {
    return Array.from(
      new Set(
        eventsTableData
          .filter((item) => {
            const structureElementTypeFilter =
              !selectedStructureElementType ||
              item.structureElement ===
                structureElementTypes[selectedStructureElementType];
            const structureNameFilter =
              !selectedEventTypeName ||
              item.eventTypeNameShort === selectedEventTypeName;

            const currentFeature =
              item.features?.includes(selectedEventFeature);

            const eventFeaturesFilter = !selectedEventFeature || currentFeature;

            return (
              structureElementTypeFilter &&
              structureNameFilter &&
              eventFeaturesFilter
            );
          })
          .map((item) => {
            return item.structureElementName;
          })
          .filter(Boolean) as string[]
      )
    );
  }, [
    eventsTableData,
    selectedEventFeature,
    selectedEventTypeName,
    selectedStructureElementType,
  ]);

  const indicatorsTableData = useMemo(() => {
    return createIndicatorsTableData(indicators);
  }, [indicators]);

  const indicatorsData = useMemo(() => {
    return indicatorsTableData
      .filter(
        (item) => !structureCodes || structureCodes.includes(String(item?.id))
      )
      .filter(
        (item) => !selectedStatus.length || selectedStatus.includes(item.status)
      )
      .filter(
        (item) =>
          !selectedStructureElementName ||
          item.structureElementName === selectedStructureElementName
      )
      .filter((item) =>
        selectedStructureElementType === "Федеральные проекты"
          ? item.structureElement === "ФП"
          : selectedStructureElementType === "Ведомственные проекты"
          ? item.structureElement === "ВП"
          : selectedStructureElementType === "Комплексы процессных мероприятий"
          ? item.structureElement === "КПМ"
          : true
      )
      .sort((a, b) => Number(a.id) - Number(b.id));
  }, [
    indicatorsTableData,
    structureCodes,
    selectedStatus,
    selectedStructureElementType,
    selectedStructureElementName,
  ]);

  const indicatorsStatuses: IStatus[] = useMemo(() => {
    const sorted: IIndicatorStatuses = indicatorsData.reduce(
      (acc: IIndicatorStatuses, item) => ({
        ...acc,
        [item.status]:
          ((acc[item.status as TIndicatorStatus] as number) || 0) + 1,
      }),
      {
        [EVENT_STATUS.DONE]: 0,
        [EVENT_STATUS.NOT_DONE]: 0,
        [EVENT_STATUS.NOT_STARTED]: 0,
      }
    );

    return Object.entries(sorted)
      .filter(([key]) => Boolean(key))
      .map(([key, value]) => ({
        status: key,
        amount: value,
      }));
  }, [indicatorsData]);

  return {
    eventsData,
    eventsStatuses,
    eventsTableData,
    containedEventFeatures,
    eventTypeAllList,
    indicatorsData,
    indicatorsStatuses,
    uniqueTypeEventsProjects,
    uniqueEventsTypes,
    uniqueStructureNames,
  };
};
