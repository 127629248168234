import { Tooltip, Typography } from "@mui/material";
import { Variant } from "@mui/material/styles/createTypography";
import React from "react";

import { Wrapper } from "./TooltipText.styled";

type TooltipTextProps = {
  children: React.ReactNode;
  hint: string | number | React.ReactNode;
  lines?: number;
  variant: Variant;
  placement?: "bottom" | "left" | "top";
  arrow?: boolean;
};

export const TooltipText = ({
  children,
  hint,
  lines = 1,
  variant,
  placement = "bottom",
}: TooltipTextProps) => {
  return (
    <Wrapper lines={lines}>
      <Tooltip
        title={hint || ""}
        placement={placement}
        PopperProps={{
          disablePortal: true,
        }}
        componentsProps={{ tooltip: { sx: { width: "max-content" } } }}
      >
        <Typography className="lined-text" variant={variant}>
          {children}
        </Typography>
      </Tooltip>
    </Wrapper>
  );
};
