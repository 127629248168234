import { useMemo } from "react";
import { isStatusMatch } from "SRC/pages/Ministry/common/utils/statuses";
import { eventsData } from "SRC/pages/Ministry/common/utils/tableData";
import { IStatus } from "SRC/pages/Program/common/ProgramSummary/interfaces";
import {
  IGlobalFilters,
  useGlobalFilters,
} from "SRC/redux/slices/global/hooks/useFilters";
import {
  IFilters,
  useFilters,
} from "SRC/redux/slices/ministry/hooks/useFilters";
import { TEventStatus } from "SRC/types";

interface IUseTableData {
  eventsTableData: any;
  eventsStatuses: IStatus[];
  containedEventFeatures: string[];
}

export const useEventsTableData = (): IUseTableData => {
  const { sources, inPerformance }: IGlobalFilters = useGlobalFilters();
  const {
    selectedStatus,
    tableState,
    selectedEventFeature,
    selectedStructureElementType,
    selectedStructureElementName,
    selectedStructureElementTask,
  }: IFilters = useFilters();

  const instance = useMemo(() => {
    return tableState?.globalFilter;
  }, [tableState?.globalFilter]);

  const eventsTableData = useMemo(() => {
    return eventsData.filter((item) => {
      const statusFilter =
        !selectedStatus.length || isStatusMatch(selectedStatus, item);

      const structureElementTypeFilter =
        selectedStructureElementType === "Федеральные проекты"
          ? item.structureElement === "ФП"
          : selectedStructureElementType === "Ведомственные проекты"
          ? item.structureElement === "ВП"
          : selectedStructureElementType === "Комплексы процессных мероприятий"
          ? item.structureElement === "КПМ"
          : true;

      const structureElementNameFilter =
        !selectedStructureElementName ||
        item.structureElementName === selectedStructureElementName;

      const structureElementTaskFilter =
        !selectedStructureElementTask ||
        item.structureElementTask === selectedStructureElementTask;

      const eventFeatureFilter =
        !selectedEventFeature || item.features?.includes(selectedEventFeature);

      return (
        statusFilter &&
        structureElementTypeFilter &&
        structureElementNameFilter &&
        structureElementTaskFilter &&
        eventFeatureFilter
      );
    });
  }, [
    instance,
    selectedStatus,
    selectedStructureElementName,
    selectedStructureElementTask,
    selectedStructureElementType,
    selectedEventFeature,
    inPerformance,
    sources.OM,
  ]);

  const eventsStatuses: IStatus[] = useMemo(() => {
    const eventTotalByStatus = eventsTableData.reduce(
      (acc: Partial<Record<TEventStatus, IStatus>>, { status }) => ({
        ...acc,
        ...Object.entries(status).reduce(
          (itemAcc: Partial<Record<TEventStatus, IStatus>>, [key, value]) => ({
            ...itemAcc,
            [key as TEventStatus]: {
              status: key,
              amount: Number(value) + (acc[key as TEventStatus]?.amount || 0),
            },
          }),
          {}
        ),
      }),
      {}
    );

    return Object.values(eventTotalByStatus);
  }, [eventsTableData]);

  const containedEventFeatures: string[] = useMemo(
    () =>
      Array.from(
        new Set(
          eventsData
            .map((e) => e.features)
            .flat()
            .filter(Boolean) as string[]
        ).values()
      ),
    [eventsData]
  );

  return {
    eventsTableData,
    eventsStatuses,
    containedEventFeatures,
  };
};
