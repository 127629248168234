import React, { useState } from "react";
import { ExtendedTabs, ITab } from "SRC/components/ExtendedTabs/ExtendedTabs";
import { Correction } from "SRC/pages/Event/common/EventCorrection/Correction/Correction";

const valueData = [
  {
    period: "Март 2021",
    previousValue: 70,
    presentValue: 75,
    difference: {
      value: "+5",
      color: "green",
    },
  },
  {
    period: "Октябрь 2021",
    previousValue: 75,
    presentValue: 74,
    difference: {
      value: "-1",
      color: "red",
    },
  },
  {
    period: "Декабрь 2021",
    previousValue: 74,
    presentValue: 73,
    difference: {
      value: "-1",
      color: "red",
    },
  },
  {
    period: "Январь 2021",
    previousValue: 73,
    presentValue: 80,
    difference: {
      value: "+7",
      color: "green",
    },
  },
];

const timeData = [
  {
    period: "Март 2021",
    previousValue: "31.12.2021",
    presentValue: "29.12.2021",
    difference: {
      value: "-2",
      color: "green",
    },
  },
  {
    period: "Октябрь 2021",
    previousValue: "29.12.2021",
    presentValue: "30.12.2021",
    difference: {
      value: "+1",
      color: "red",
    },
  },
  {
    period: "Декабрь 2021",
    previousValue: "30.12.2021",
    presentValue: "30.03.2022",
    difference: {
      value: "+89",
      color: "red",
    },
  },
];

export const EventCorrection = () => {
  const [activeTab, setActiveTab] = useState<number>(0);
  const onChangeTab = (value: number): void => setActiveTab(value);

  const tabs: ITab<number>[] = [
    {
      value: 0,
      label: "Значение",
      component: <Correction data={valueData} />,
    },
    {
      value: 1,
      label: "Сроки",
      component: <Correction data={timeData} isTime />,
    },
  ];

  return (
    <ExtendedTabs
      value={activeTab}
      onChange={onChangeTab}
      tabs={tabs}
      borderStyle="solid"
    />
  );
};
