const LIVE_VIDEO_ID_REGEXP = /live\/([^?/]+)/;

export const getYoutubeVideoId = (url: string) => {
  const urlObj = new URL(url);

  let videoId = urlObj.searchParams.get("v");
  if (!videoId) {
    [, videoId] = url.match(LIVE_VIDEO_ID_REGEXP) || [];
  }

  return videoId;
};
