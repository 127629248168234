import { styled } from "@mui/material";

export const PopulationWrapper = styled("div")(({ theme }) => {
  const {
    primary: { contrastText },
    charts: { darkBlueBackground },
    pale: { green, red },
    complementary: { lightWhite },
  } = theme.palette;

  return {
    gap: "3rem",
    display: "flex",
    flexDirection: "column",

    "&.is-secret": {
      alignItems: "center",
      justifyContent: "center",
    },

    ".title": {
      fontSize: "1.25rem",
      fontWeight: 400,
      color: contrastText,
    },

    ".chart-container": {
      display: "flex",
    },

    ".doughnut": {
      borderRadius: "50%",
      display: "flex",
      background: darkBlueBackground,

      [theme.breakpoints.up("md")]: {
        minHeight: "11.25rem",
        minWidth: "11.25rem",
      },

      [theme.breakpoints.down("md")]: {
        minHeight: "11rem",
        minWidth: "11rem",
      },
    },

    ".chart": {
      flex: "0 0 50%",
      display: "flex",
      justifyContent: "center",
    },

    ".info-container": {
      gap: "1rem",
      flex: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },

    ".info": {
      gap: "1rem",
      display: "flex",
    },

    ".info-item": {
      gap: "0.375rem",
      display: "flex",
      alignItems: "baseline",

      "& > .circle": {
        width: "0.75rem",
        height: "0.75rem",
        borderRadius: "50%",

        "&.circle-green": {
          backgroundColor: green,
        },

        "&.circle-red": {
          backgroundColor: red,
        },
      },
    },

    ".number": {
      display: "flex",
      alignItems: "center",
      fontSize: "1.25rem",
      fontWeight: 400,
      color: contrastText,

      "& span": {
        ml: "0.25rem",
        display: "flex",
        fontSize: "0.875rem",
        color: lightWhite,
      },
    },

    ".status-name": {
      fontSize: "1rem",
      fontWeight: 400,
      color: contrastText,
    },
  };
});
