import { SxProps } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import SelectWithSearch from "SRC/components/SelectWithSearch/SelectWithSearch";
import { useFilters } from "SRC/redux/slices/ministry/hooks/useFilters";

import { useEventsTableData } from "../../tableData/useEventsTableData";

type TOptionValue = string | number;

interface IOption {
  value: TOptionValue;
  label: string;
  disabled?: boolean;
}

interface IStyle {
  labelStyle?: SxProps;
  selectStyle?: SxProps;
  optionStyle?: SxProps;
}

interface IFilterFeatureProps {
  onChange(value: TOptionValue): void;
  label?: string;
  staticLabel?: boolean;
  colorizeActiveOption?: boolean;
  style: IStyle;
}

const selectOptionsEvents = [
  { value: "Признак_основные", label: "Признак основные" },
  { value: "Признак_не основные", label: "Признак не основные" },
  { value: "Признак_ОКС онлайн", label: "Признак ОКС онлайн" },
  {
    value: "Признак_Реализация в субъектах",
    label: "Признак Реализация в субъектах",
  },
  { value: "Признак_СЗП", label: "Признак СЗП" },
  { value: "Признак_ФХД", label: "Признак ФХД" },
  { value: "Признак_КТ", label: "Признак КТ" },
  { value: "Признак_ОКС", label: "Признак ОКС" },
  { value: "Признак_СМНП", label: "Признак СМНП" },
];

export const FilterFeatures = ({
  onChange,
  staticLabel = true,
  label,
  colorizeActiveOption = true,
  style,
}: IFilterFeatureProps) => {
  const { selectedEventFeature } = useFilters();
  const { containedEventFeatures } = useEventsTableData();

  const [feature, setFeature] = useState<string>("");

  useEffect(() => {
    setFeature(selectedEventFeature);
  }, [selectedEventFeature]);

  const customOptions = useMemo(
    () => [
      {
        value: "",
        label: label || "Все",
      },
      ...selectOptionsEvents.filter((o) =>
        containedEventFeatures.includes(o.value)
      ),
    ],
    [label]
  );

  return (
    <SelectWithSearch
      onChange={onChange}
      options={customOptions}
      getOptionLabel={(option: IOption) => option.label}
      staticLabel={staticLabel}
      label={label}
      colorizeActiveOption={colorizeActiveOption}
      style={style}
      hasCloseIcon={Boolean(feature)}
      value={feature}
    />
  );
};
