import {
  CriticalIcon,
  DoneIcon,
  FailIcon,
  InProgressIcon,
  NotStartedIcon,
  OutOfDateIcon,
  WarningTriangle,
} from "ASSETS/svg";
import React from "react";
import { TSourcesState } from "SRC/redux/slices/global/slices/filters";
import theme from "SRC/theme";
import { EVENT_STATUS } from "SRC/types";

export enum EDateType {
  YEAR = "year",
  MONTH = "month",
  QUARTER = "quarter",
}

export enum ESources {
  FOIV = "FOIV",
  OM = "OM",
}

export interface ISourceSettings {
  successColor: string;
  failColor: string;
  valueColor: string;
  title: string;
}

export const sourceSettings: Record<ESources, ISourceSettings> = {
  [ESources.FOIV]: {
    successColor: theme.palette.pale.green,
    failColor: theme.palette.pale.red,
    valueColor: theme.palette.primary.contrastText,
    title: "ФОИВ",
  },
  [ESources.OM]: {
    successColor: theme.palette.pale.paleGreen,
    failColor: theme.palette.pale.paleRed,
    valueColor: theme.palette.text.opinion,
    title: "МЭР",
  },
};

export interface ISourceValue {
  value: number | null | undefined;
  settings: ISourceSettings;
}

export const convertSourceValue = (
  value: Record<ESources, number | undefined>,
  sources: TSourcesState
): ISourceValue[] =>
  Object.values(ESources)
    .map((key: ESources): ISourceValue | null => {
      if (!sources[key]) return null;
      return {
        value: value[key],
        settings: sourceSettings[key],
      };
    })
    .filter(Boolean) as ISourceValue[];

export const statusIcons: Record<string, JSX.Element> = {
  [EVENT_STATUS.DONE]: <DoneIcon />,
  [EVENT_STATUS.IN_PROGRESS]: <InProgressIcon />,
  [EVENT_STATUS.NOT_STARTED]: <NotStartedIcon />,
  [EVENT_STATUS.OUT_DATE]: <OutOfDateIcon />,
  [EVENT_STATUS.NOT_ACHIEVED]: <FailIcon />,
  [EVENT_STATUS.NOT_DONE]: <FailIcon />,
  [EVENT_STATUS.AT_RISK]: <WarningTriangle />,
  [EVENT_STATUS.CRITICAL]: <CriticalIcon />,
};

export enum EFinanceUnit {
  BILLION = "млрд",
  MILLION = "млн",
  THOUSAND = "тыс",
}

export const months: string[] = [
  "январь",
  "февраль",
  "март",
  "апрель",
  "май",
  "июнь",
  "июль",
  "август",
  "сентябрь",
  "октябрь",
  "ноябрь",
  "декабрь",
];

export enum EExtensions {
  ODS = 0,
  XLSX = 1,
}

export enum ETypeCalcPerformance {
  FOR_PLAN = "Расчет уровня достижения за выбранный отчетный период выполнен по плановому и фактическому значениям",
  FOR_POINTS = "Расчет уровня достижения за выбранный отчетный период выполнен по контрольным точкам",
  NOT_PARTICIPATE = "Не участвует в расчете уровня достижения",
}
