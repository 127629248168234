import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { isContainedArray } from "SRC/helpers/dataTesters";
import { ITopVP } from "SRC/pages/Summary/interfaces";
import { IPeriod } from "SRC/redux/slices/global/slices/filters";

import { getTopBestVPOpinion } from "../api/getTopBestVPOpinion";

export interface ITopBestVpOpinion {
  items: ITopVP[];
  fetching: boolean;
  fetched: boolean;
  error: string;
}

export const extraActions = {
  get: createAsyncThunk(
    "topBestVpOpinion/get",
    async (period: IPeriod) => await getTopBestVPOpinion(period)
  ),
};

const initialState: ITopBestVpOpinion = {
  items: [],
  fetching: false,
  fetched: false,
  error: "",
};

const slice = createSlice({
  name: "topBestVpOpinion",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActions.get.pending, (state) => {
        state.items = [];
        state.fetching = true;
        state.fetched = false;
      })
      .addCase(
        extraActions.get.fulfilled,
        (state: ITopBestVpOpinion, action) => {
          const data = action.payload.data;
          state.items = isContainedArray(action.payload.data[0]?.data[0]?.data)
            ? data[0]?.data[0]?.data
            : [];
          state.fetching = false;
          state.fetched = true;
        }
      )
      .addCase(extraActions.get.rejected, (state, action) => {
        state.fetching = false;
        state.fetched = false;
      });
  },
});

export const actions = {
  ...slice.actions,
  ...extraActions,
};

export default slice;
