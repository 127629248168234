import { combineReducers } from "@reduxjs/toolkit";

import filtersSlice, { IFiltersState } from "./slices/filters";
import ministriesSlice, { IMinistriesState } from "./slices/ministries";
import { IPremiersState, slice as premiersSlice } from "./slices/premiers";
import { IProgramsState, slice as programsSlice } from "./slices/programs";

export interface IPrioritiesReducer {
  filters: IFiltersState;
  programs: IProgramsState;
  premiers: IPremiersState;
  ministries: IMinistriesState;
}

const reducer = combineReducers<IPrioritiesReducer>({
  filters: filtersSlice.reducer,
  programs: programsSlice.reducer,
  premiers: premiersSlice.reducer,
  ministries: ministriesSlice.reducer,
});

export default reducer;
