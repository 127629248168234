import { getIsSecret } from "SRC/pages/Summary/common/SummaryComplexGrid/utils";
import { ICustomTableProps } from "SRC/types";

export const getIndicatorsSEPlan = (
  tableProps: ICustomTableProps,
  isOM: boolean
) => {
  const { indicatorsSEPlan, indicatorsSEOMPlan } = tableProps.row.original;

  return {
    isSecret: getIsSecret(tableProps),
    indicatorsSEPlan: isOM ? indicatorsSEOMPlan : indicatorsSEPlan,
  };
};
