import React, { useEffect, useMemo } from "react";
import { useParams } from "react-router";
import Breadcrumbs from "SRC/components/Breadcrumbs/Breadcrumbs";
import { Card } from "SRC/layouts";
import { useFilters } from "SRC/redux/slices/indicator/hooks/useFilters";
import { useIndicator } from "SRC/redux/slices/indicator/hooks/useIndicator";
import { useIndicatorData } from "SRC/redux/slices/indicator/hooks/useIndicatorData";
import { MINISTRIES, PROGRAMS, setParamsToUrl } from "SRC/urls";

import { Content } from "./common/Content/Content";
import { Description } from "./common/Description/Description";
import { Foot } from "./common/Foot/Foot";
import { Header } from "./common/Header/Header";
import { Sidebar } from "./common/Sidebar/Sidebar";

interface IProps {
  modalId?: string | number | null;
  close?(): void;
}

export const MinistryIndicator: React.FC<IProps> = ({ modalId }) => {
  useIndicator();
  const { id, ministryId = "" } = useParams();
  const { items: data } = useIndicatorData();

  const { setSelectedIndicatorId, clear } = useFilters();

  useEffect(() => () => clear(), []);

  useEffect(() => {
    if (modalId) {
      setSelectedIndicatorId(modalId);
    } else if (id) {
      setSelectedIndicatorId(id);
    } else {
      clear();
    }
  }, [id, modalId]);

  const links = useMemo(
    () => [
      {
        title: "Госпрограммы",
        link: PROGRAMS,
      },
      {
        title: "МИНИСТЕРСТВО" || "",
        link: setParamsToUrl(MINISTRIES, { ministryId }),
      },
      {
        title: "ОПИСАНИЕ" || "",
      },
    ],
    [data]
  );

  return (
    <>
      {!Boolean(modalId) && <Breadcrumbs links={links} />}
      <Card
        head={<Header />}
        description={<Description />}
        summary={<Sidebar />}
        content={<Content />}
        foot={<Foot />}
      />
    </>
  );
};
