import { PerformanceValue } from "SRC/components/PerformanceValue/PerformanceValue";
import { getRoundValue } from "SRC/helpers/getRoundValue";
import { IWidgetPrioritiesData } from "SRC/pages/Priorities/utils/widgetPrioritiesData";

import { Wrapper } from "./PrioritySummaryWidget.styled";

interface IPrioritySummaryWidget {
  data: IWidgetPrioritiesData;
}

export const PrioritySummaryWidget = ({ data }: IPrioritySummaryWidget) => {
  return (
    <Wrapper>
      <div className="section section-left">
        <div className="rows-container">
          <div className="row">
            <div className="title">Уровень достижения ГП</div>
            <div className="value">
              <PerformanceValue
                value={getRoundValue(data.performanceGP)}
                sameSize={true}
              />
              <span>%</span>
            </div>
          </div>
          <div className="row">
            <div className="title">Количество ГП</div>
            <div className="value">{data.countGP}</div>
          </div>
        </div>
      </div>
      <div className="section section-right">
        <div className="rows-container">
          <div className="row">
            <div className="title">Уровень достижения КП</div>
            <div className="value">
              {getRoundValue(data.performanceKP)}
              <span>%</span>
            </div>
          </div>
          <div className="row">
            <div className="title">Количество КП</div>
            <div className="value">{data.countKP}</div>
          </div>
        </div>
        <div className="info-container">
          <div className="row">
            <div className="title">Процент выполнения</div>
            <div className="value">
              {getRoundValue(data.performancePercentKP)}
              <span>%</span>
            </div>
          </div>
          <div className="row">
            <div className="title">Достигнуто КП</div>
            <div className="value">
              {data.doneCountKP}
              <span>ед.</span>
            </div>
          </div>
          <div className="row">
            <div className="title">Не достигнуто КП</div>
            <div className="value">
              {data.failCountKP}
              <span>ед.</span>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
