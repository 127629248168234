import { SerializedError } from "@reduxjs/toolkit";
import { useCallback, useState } from "react";
import { IIndicatorParams } from "SRC/redux/slices/indicator/slices/filters";
import { IIndicatorGraph } from "SRC/redux/slices/indicator/slices/graph";

import { useAppDispatch, useAppSelector } from "../../../hooks";
import actions from "../../indicator/actions";

export interface IIndicatorGraphHook {
  fetching: boolean;
  fetched: boolean;
  items: IIndicatorGraph[] | null;
  load(params: IIndicatorParams): void;
  error: SerializedError | null;
}

export const useIndicatorGraph = (): IIndicatorGraphHook => {
  const dispatch = useAppDispatch();
  const {
    fetching,
    items: data,
    fetched,
  } = useAppSelector((state) => state.indicator.graph);
  const [error, setError] = useState<SerializedError | null>(null);

  const load = useCallback(
    (params: IIndicatorParams) => {
      if (!fetching) {
        dispatch(actions.graph.get(params)).then((action) => {
          if (actions.graph.get.rejected.match(action)) {
            setError(action.error);
          }
          return action;
        });
      }
    },
    [dispatch, fetching]
  );

  return {
    fetching,
    fetched,
    items: data,
    load,
    error,
  };
};
