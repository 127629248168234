import { Box } from "@mui/material";
import React from "react";
import { TableProps, UseTableRowProps } from "react-table";
import {
  iconCss,
  StatusIconContainer,
} from "SRC/components/Table/assets/icons";
import { css } from "SRC/components/Table/Table.styled";
import { TableCell } from "SRC/components/TableCell";
import theme from "SRC/theme";

interface CustomTableProps extends TableProps {
  row: UseTableRowProps<any>;
  rows?: UseTableRowProps<any>;
}

const subjectColumns = [
  {
    Header: " ",
    Footer: "",
    width: "30%",
    accessor: "nameSubject",
    columns: [
      {
        Header: "Название субъекта",
        accessor: "nameSubject",
        width: "30%",
        Cell: (tableProps: CustomTableProps) => {
          const { statusLabel, nameSubject } = tableProps.row.original;
          return (
            <div style={{ position: "relative", padding: "0 20px" }}>
              <StatusIconContainer>
                <Box sx={iconCss.statusIconImage(theme, statusLabel)} />
              </StatusIconContainer>
              <Box sx={css.tableCellText()} className="withMarginLeft">
                <TableCell>{nameSubject}</TableCell>
              </Box>
            </div>
          );
        },
      },
    ],
  },
  {
    Header: "Сроки",
    width: "30%",
    columns: [
      {
        Header: "План",
        accessor: "timePlan",
        width: "15%",
        Cell: (tableProps: CustomTableProps) => {
          const { timePlan } = tableProps.row.original;
          return <TableCell>{timePlan}</TableCell>;
        },
      },
      {
        Header: "Факт",
        width: "15%",
        accessor: "timeFact",
        Cell: (tableProps: CustomTableProps) => {
          const { timeFact, timeFactColor } = tableProps.row.original;
          return <TableCell color={timeFactColor}>{timeFact}</TableCell>;
        },
      },
    ],
  },
  {
    Header: "Значение",
    width: "30%",
    columns: [
      {
        Header: "План",
        width: "15%",
        accessor: "countObjectPlan",
        Cell: (tableProps: CustomTableProps) => {
          const { countObjectPlan } = tableProps.row.original;
          return <TableCell>{countObjectPlan}</TableCell>;
        },
      },
      {
        Header: "Факт",
        width: "15%",
        accessor: "countObjectFact",
        Cell: (tableProps: CustomTableProps) => {
          const { objectFactColor, countObjectFact } = tableProps.row.original;

          return (
            <TableCell color={objectFactColor}>{countObjectFact}</TableCell>
          );
        },
      },
    ],
  },
];

export { subjectColumns };
