import { Theme } from "@mui/material";

export const css = {
  counterBox: (position: string) => (theme: Theme) => ({
    display: "flex",
    width: "5ch",
    aspectRatio: "1",
    alignItems: "center",
    justifyContent: "center",
    background: theme.palette.complementary.darkBlue,
    position: "absolute",
    right: 0,
    top: position === "top" ? "-1.5rem" : "",
    bottom: position === "bottom" ? "-1.5rem" : "",
    zIndex: position === "top" ? 1 : position === "bottom" ? 2 : "",
    borderRadius: "50%",
    fontSize: "1rem",
  }),
};
