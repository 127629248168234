import { styled } from "@mui/material";

export const Wrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "1.75rem",
  "> .structure-item": {
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
    fontSize: "0.8rem",
    color: theme.palette.main.gray,
    " > div": {
      fontSize: "1rem",
      color: theme.palette.text.onAccent,
    },
  },
  "> .structure-item-main": {
    fontSize: "1rem",
    paddingBottom: "1rem",
    borderBottom: `1px solid ${theme.palette.text.secondary}`,
    div: {
      fontSize: "1.2rem",
    },
  },
}));
