import { Button, styled } from "@mui/material";
import { selectorMixin } from "SRC/components/FilterForms/MainHeaderFilter/MainHeaderFilter.styled";

interface ITasksTabWrapper {
  isTableModalOpen?: boolean;
}
export const Wrapper = styled("div")<ITasksTabWrapper>(
  ({ isTableModalOpen }) => {
    const marginModal = isTableModalOpen ? "1rem" : "0rem";
    return {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      overflowY: "hidden",
      overflowX: "visible",
      padding: "0 1rem",
      gap: "0.5rem",

      ".searchSelectContainer": {
        display: "flex",
        gap: "1rem",
      },

      ".search": {
        flex: "1",
        minWidth: "20rem",
      },

      ".filtersContainer": {
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        marginTop: marginModal,
      },

      ".structure-tab-incident": {
        button: {
          right: "1rem",
        },
      },
    };
  }
);

export const ResetFilterButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  background: theme.palette.complementary.darkGrayBlue,
  borderRadius: "0.5rem",
  fontSize: "1rem !important",
  fontWeight: "400",
  textTransform: "none",
  height: "2.5rem",
  padding: "0.6rem 1.125rem",
}));

export const css = {
  selector: selectorMixin,
  filtersContainer: (isTableModalOpen?: boolean) => ({
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    marginTop: isTableModalOpen ? "1rem" : "0rem",
  }),
  statusCardContainer: {
    display: "flex",
    height: "100%",
    gap: "1rem",
    flex: 1,
  },
};
