import { useMemo } from "react";
import {
  eventsData,
  indicatorsSEData,
} from "SRC/pages/Ministry/common/utils/tableData";
import { getIndicatorStatuses } from "SRC/pages/Program/utils";
import { useFilters } from "SRC/redux/slices/ministry/hooks/useFilters";

type TSEIndicatorsTableData = {
  indicatorsStatuses: any;
  indicatorsTableData: any;
  uniqueStructureNames: string[];
  uniqueStructureNamesTasks: string[];
};

export const useSEIndicatorsTableData = (): TSEIndicatorsTableData => {
  let data = indicatorsSEData;

  const {
    selectedStatus,
    selectedStructureElementType,
    selectedStructureElementName,
    selectedStructureElementTask,
  } = useFilters();

  const { indicatorsTableData, indicatorsStatuses } = useMemo(() => {
    const filteredData = data.filter((item: any) => {
      const statusFilter =
        !selectedStatus.length || selectedStatus.includes(item.status);

      const structureElementTypeFilter =
        selectedStructureElementType === "Федеральные проекты"
          ? item.structureElementType === "ФП"
          : selectedStructureElementType === "Ведомственные проекты"
          ? item.structureElementType === "ВП"
          : selectedStructureElementType === "Комплексы процессных мероприятий"
          ? item.structureElementType === "КПМ"
          : true;

      const structureElementNameFilter =
        !selectedStructureElementName ||
        item.structureElementName === selectedStructureElementName;

      const structureElementTaskFilter =
        !selectedStructureElementTask ||
        item.structureElementTask === selectedStructureElementTask;

      return (
        statusFilter &&
        structureElementNameFilter &&
        structureElementTaskFilter &&
        structureElementTypeFilter
      );
    });

    return {
      indicatorsTableData: filteredData,
      indicatorsStatuses: getIndicatorStatuses(filteredData),
    };
  }, [
    data,
    selectedStatus,
    selectedStructureElementType,
    selectedStructureElementName,
    selectedStructureElementTask,
  ]);

  const uniqueStructureNames = useMemo(() => {
    return Array.from(
      new Set(
        data.map((item) => {
          return item.structureElementName;
        })
      )
    );
  }, [data]);

  const uniqueStructureNamesTasks = useMemo(() => {
    return Array.from(
      new Set(
        data.map((item) => {
          return item.structureElementsTasks;
        })
      )
    );
  }, [data]);

  return {
    indicatorsTableData,
    indicatorsStatuses,
    uniqueStructureNames,
    uniqueStructureNamesTasks,
  };
};
