import { combineReducers } from "@reduxjs/toolkit";
import programFiltersSlice, {
  IProgramFiltersState,
} from "SRC/redux/slices/gosprogram/slices/filters";
import goalConnect, {
  IGoalConnectState,
} from "SRC/redux/slices/gosprogram/slices/goalConnect";
import gpStructureSlice, {
  IGpStructureState,
} from "SRC/redux/slices/gosprogram/slices/gpStructure";
import {
  IProgram,
  IProgramState,
  slice as programSlice,
} from "SRC/redux/slices/gosprogram/slices/program";
import resultCharacteristicSlice, {
  IResultCharacteristicState,
} from "SRC/redux/slices/gosprogram/slices/resultCharacteristic";
import statisticsSlice, {
  IStatisticsState,
} from "SRC/redux/slices/gosprogram/slices/statistics";
import {
  IStructureElementsGPState,
  IStructureElementsIndicatorsState,
  IStructureElementsState,
  IStructureElementsTasksState,
  structureElementsGPSlice,
  structureElementsIndicatorsSlice,
  structureElementsSlice,
  structureElementsTasksSlice,
} from "SRC/redux/slices/gosprogram/slices/structureElements";

import eventsSlice, { IEventsState } from "./slices/events";
import indicatorsSlice, { IIndicatorsState } from "./slices/indicators";
import programGoalsSlice, { IProgramGoalsState } from "./slices/programGoals";
import reportsSlice, { IReportState } from "./slices/reports";
import tableTypeSlice, { ITableTypeState } from "./slices/tableType";
import workbookSlice, { IWorkbookState } from "./slices/workbook";

export interface IGosprogramReducer {
  programGoals: IProgramGoalsState;
  events: IEventsState;
  indicators: IIndicatorsState;
  statistics: IStatisticsState;
  tableType: ITableTypeState;
  filters: IProgramFiltersState;
  resultCharacteristic: IResultCharacteristicState;
  goalConnects: IGoalConnectState;
  gpStructure: IGpStructureState;
  program: IProgramState;
  structureElements: IStructureElementsState;
  structureElementsGP: IStructureElementsGPState;
  structureElementsIndicators: IStructureElementsIndicatorsState;
  structureElementsTasks: IStructureElementsTasksState;
  workbook: IWorkbookState;
  reports: IReportState;
}

const reducer = combineReducers<IGosprogramReducer>({
  programGoals: programGoalsSlice.reducer,
  events: eventsSlice.reducer,
  indicators: indicatorsSlice.reducer,
  statistics: statisticsSlice.reducer,
  tableType: tableTypeSlice.reducer,
  gpStructure: gpStructureSlice.reducer,
  filters: programFiltersSlice.reducer,
  resultCharacteristic: resultCharacteristicSlice.reducer,
  goalConnects: goalConnect.reducer,
  program: programSlice.reducer,
  structureElements: structureElementsSlice.reducer,
  structureElementsGP: structureElementsGPSlice.reducer,
  structureElementsIndicators: structureElementsIndicatorsSlice.reducer,
  structureElementsTasks: structureElementsTasksSlice.reducer,
  workbook: workbookSlice.reducer,
  reports: reportsSlice.reducer,
});

export default reducer;
