import { TIndicatorMap } from "../types";

export const PREMIER_INDICATORS: TIndicatorMap = {
  1683: {
    id: "1683",
    desc: "Период",
  },
  1686: {
    id: "1686",
    desc: "Вице-премьер",
  },
  1692: {
    id: "1692",
    desc: "Показатели",
  },
  1693: {
    id: "1693",
    desc: "СЭ, план",
    aggregationFunction: "sum",
  },
  1694: {
    id: "1694",
    desc: "СЭ, выполнено",
    aggregationFunction: "sum",
  },
  1695: {
    id: "1695",
    desc: "СЭ, невыполнено",
    aggregationFunction: "sum",
  },
  1696: {
    id: "1696",
    desc: "СЭ, план, итого",
    aggregationFunction: "sum",
  },
  1697: {
    id: "1697",
    desc: "СЭ, выполнено, итого",
    aggregationFunction: "sum",
  },
  1698: {
    id: "1698",
    desc: "СЭ, невыполнено, итого",
    aggregationFunction: "sum",
  },
  1699: {
    id: "1699",
    desc: "Показателей, план",
    aggregationFunction: "sum",
  },
  1700: {
    id: "1700",
    desc: "Показателей, выполнено",
    aggregationFunction: "sum",
  },
  1701: {
    id: "1701",
    desc: "Показателей, невыполнено",
    aggregationFunction: "sum",
  },
  1702: {
    id: "1702",
    desc: "Показателей, план, итого",
    aggregationFunction: "sum",
  },
  1703: {
    id: "1703",
    desc: "Показателей, выполнено, итого",
    aggregationFunction: "sum",
  },
  1704: {
    id: "1704",
    desc: "Показателей, невыполнено, итого",
    aggregationFunction: "sum",
  },
  1705: {
    id: "1705",
    desc: "Мероприятий, план",
    aggregationFunction: "sum",
  },
  1706: {
    id: "1706",
    desc: "Мероприятий, выполнено",
    aggregationFunction: "sum",
  },
  1707: {
    id: "1707",
    desc: "Мероприятий, невыполнено",
    aggregationFunction: "sum",
  },
  1708: {
    id: "1708",
    desc: "Мероприятий, план, итого",
    aggregationFunction: "sum",
  },
  1709: {
    id: "1709",
    desc: "Мероприятий, выполнено, итого",
    aggregationFunction: "sum",
  },
  1710: {
    id: "1710",
    desc: "Мероприятий, невыполнено, итого",
    aggregationFunction: "sum",
  },
  1711: {
    id: "1711",
    desc: "Касса, план",
    aggregationFunction: "sum",
  },
  1712: {
    id: "1712",
    desc: "Касса, факт, доля",
    aggregationFunction: "sum",
  },
  1713: {
    id: "1713",
    desc: "Касса, цвет",
    stringAggregation: true,
    separator: ";",
  },
  1714: {
    id: "1714",
    desc: "Касса, план, итого",
    aggregationFunction: "sum",
  },
  1715: {
    id: "1715",
    desc: "Касса, факт, доля, итого",
    aggregationFunction: "sum",
  },
  1716: {
    id: "1716",
    desc: "Касса, цвет, итого",
    stringAggregation: true,
    separator: ";",
  },
  1717: {
    id: "1717",
    desc: "ОКС, план",
    aggregationFunction: "sum",
  },
  1718: {
    id: "1718",
    desc: "ОКС, выполнено, доля",
    aggregationFunction: "sum",
  },
  1719: {
    id: "1719",
    desc: "ОКС, план, итого",
    aggregationFunction: "sum",
  },
  1720: {
    id: "1720",
    desc: "ОКС, выполнено, доля, итого",
    aggregationFunction: "sum",
  },
  1721: {
    id: "1721",
    desc: "НПА, план",
    aggregationFunction: "sum",
  },
  1722: {
    id: "1722",
    desc: "НПА, выполнено",
    aggregationFunction: "sum",
  },
  1723: {
    id: "1723",
    desc: "НПА, невыполнено",
    aggregationFunction: "sum",
  },
  1724: {
    id: "1724",
    desc: "НПА, план, итого",
    aggregationFunction: "sum",
  },
  1725: {
    id: "1725",
    desc: "НПА, выполнено, итого",
    aggregationFunction: "sum",
  },
  1726: {
    id: "1726",
    desc: "НПА, невыполнено, итого",
    aggregationFunction: "sum",
  },
  1727: {
    id: "1727",
    desc: "ОКС, цвет, итого",
    stringAggregation: true,
    separator: ";",
  },
  1728: {
    id: "1728",
    desc: "ОКС, цвет",
    stringAggregation: true,
    separator: ";",
  },
  1742: {
    id: "1742",
    desc: "Количество ГП",
    aggregationFunction: "sum",
  },
  1743: {
    id: "1743",
    desc: "УД",
    aggregationFunction: "sum",
  },
  1744: {
    id: "1744",
    desc: "УД, цвет",
    stringAggregation: true,
    separator: ";",
  },
  1745: {
    id: "1745",
    desc: "УД, общий",
    aggregationFunction: "sum",
  },
  1746: {
    id: "1746",
    desc: "УД, общий, цвет",
    stringAggregation: true,
    separator: ";",
  },
  1747: {
    id: "1747",
    desc: "Мероприятия, план",
    aggregationFunction: "sum",
  },
  1748: {
    id: "1748",
    desc: "Мероприятия, факт",
    aggregationFunction: "sum",
  },
  1749: {
    id: "1749",
    desc: "Мероприятия, риски",
    aggregationFunction: "sum",
  },
  1758: {
    id: "1758",
    desc: "Количество ГП",
    aggregationFunction: "sum",
  },
  1759: {
    id: "1759",
    desc: "Уровень достижения",
    aggregationFunction: "sum",
  },
  1760: {
    id: "1760",
    desc: "Касса, факт",
    aggregationFunction: "sum",
  },
  1761: {
    id: "1761",
    desc: "Цели, план",
    aggregationFunction: "sum",
  },
  1762: {
    id: "1762",
    desc: "Цели, выполнено",
    aggregationFunction: "sum",
  },
  1763: {
    id: "1763",
    desc: "Цели, невыполнено",
    aggregationFunction: "sum",
  },
  1764: {
    id: "1764",
    desc: "Удовлетворённость населения",
    aggregationFunction: "sum",
  },
  1765: {
    id: "1765",
    desc: "Список госпрограмм",
    stringAggregation: true,
    separator: ";",
  },
  1766: {
    id: "1766",
    desc: "Список целей",
    stringAggregation: true,
    separator: ";",
  },
  1767: {
    id: "1767",
    desc: "Список госпрограмм",
    stringAggregation: true,
    separator: ";",
  },
  1768: {
    id: "1768",
    desc: "Список целей",
    stringAggregation: true,
    separator: ";",
  },
  1797: {
    id: "1797",
    desc: "Уровень достижения 2",
    aggregationFunction: "sum",
  },
  5207: {
    id: "5207",
    desc: "Контрольные точки, план",
    aggregationFunction: "sum",
  },
  5208: {
    id: "5209",
    desc: "Контрольные точки, факт",
    aggregationFunction: "sum",
  },
  5209: {
    id: "5209",
    desc: "Контрольные точки, невыполнено",
    aggregationFunction: "sum",
  },
  5248: {
    id: "5248",
    desc: "Уровень достижения 2, общий",
    aggregationFunction: "sum",
  },
  5253: {
    id: "5253",
    desc: "Контрольные точки, план",
    aggregationFunction: "sum",
  },
  5254: {
    id: "5254",
    desc: "Контрольные точки, факт",
    aggregationFunction: "sum",
  },
  5255: {
    id: "5255",
    desc: "Контрольные точки, невыполнено",
    aggregationFunction: "sum",
  },
  5256: {
    id: "5256",
    desc: "Контрольные точки, план, итого",
    aggregationFunction: "sum",
  },
  5257: {
    id: "5257",
    desc: "Контрольные точки, факт, итого",
    aggregationFunction: "sum",
  },
  5258: {
    id: "5258",
    desc: "Контрольные точки, невыполнено, итого",
    aggregationFunction: "sum",
  },
  5266: {
    id: "5266",
    desc: "Уровень достижения особое мнение",
    aggregationFunction: "sum",
  },
  5271: {
    id: "5271",
    desc: "Уровень достижения особое мнение итого цвет",
    aggregationFunction: "sum",
  },
  5272: {
    id: "5272",
    desc: "Уровень достижения особое мнение итого",
    aggregationFunction: "sum",
  },
  5300: {
    id: "5300",
    desc: "Показатели СЭ, План",
    aggregationFunction: "sum",
  },
  5301: {
    id: "5301",
    desc: "Показатели СЭ, Факт",
    aggregationFunction: "sum",
  },
  5302: {
    id: "5302",
    desc: "Показатели СЭ, Не выполнено",
    aggregationFunction: "sum",
  },
  5303: {
    id: "5303",
    desc: "Показатели СЭ, План Итого",
    aggregationFunction: "sum",
  },
  5304: {
    id: "5304",
    desc: "Показатели СЭ, Факт Итого",
    aggregationFunction: "sum",
  },
  5305: {
    id: "5305",
    desc: "Показатели СЭ, Не выполнено Итого",
    aggregationFunction: "sum",
  },
  5374: {
    id: "5374",
    desc: "СЭ, Факт",
    aggregationFunction: "sum",
  },
  5375: {
    id: "5375",
    desc: "СЭ, Факт Итого",
    aggregationFunction: "sum",
  },
  5391: {
    id: "5391",
    desc: "Показатели ОМ Факт",
    aggregationFunction: "sum",
  },
  5392: {
    id: "5392",
    desc: "Показатели ОМ План",
    aggregationFunction: "sum",
  },
  5393: {
    id: "5393",
    desc: "Мероприятия ОМ Факт",
    aggregationFunction: "sum",
  },
  5394: {
    id: "5394",
    desc: "Мероприятия ОМ План",
    aggregationFunction: "sum",
  },
  5447: {
    id: "5447",
    desc: "Показатели, ОМ, Не выполнено",
    aggregationFunction: "sum",
  },
  5448: {
    id: "5448",
    desc: "Структурные элементы, ОМ, План",
    aggregationFunction: "sum",
  },
  5449: {
    id: "5449",
    desc: "Структурные элементы, ОМ, Факт",
    aggregationFunction: "sum",
  },
  5450: {
    id: "5450",
    desc: "Структурные элементы, ОМ, Не выполнено",
    aggregationFunction: "sum",
  },
  5451: {
    id: "5451",
    desc: "Мероприятия ОМ, Не выполнено",
    aggregationFunction: "sum",
  },
  5455: {
    id: "5455",
    desc: "Показатели, ОМ, План, Итого",
    aggregationFunction: "sum",
  },
  5456: {
    id: "5456",
    desc: "Показатели, ОМ, Факт, Итого",
    aggregationFunction: "sum",
  },
  5457: {
    id: "5457",
    desc: "Показатели, ОМ, Не выполнено, Итого",
    aggregationFunction: "sum",
  },
  5458: {
    id: "5458",
    desc: "Структурные элементы, ОМ, План, Итого",
    aggregationFunction: "sum",
  },
  5459: {
    id: "5459",
    desc: "Структурные элементы, ОМ, Факт, Итого",
    aggregationFunction: "sum",
  },
  5460: {
    id: "5460",
    desc: "Структурные элементы, ОМ, Не выполнено, Итого",
    aggregationFunction: "sum",
  },
  5462: {
    id: "5462",
    desc: "Мероприятия ОМ, Факт, Итого",
    aggregationFunction: "sum",
  },
  5463: {
    id: "5463",
    desc: "Мероприятия ОМ, Не выполнено, Итого",
    aggregationFunction: "sum",
  },
  5452: {
    id: "5452",
    desc: "Контрольные точки, ОМ, План",
    aggregationFunction: "sum",
  },
  5453: {
    id: "5453",
    desc: "Контрольные точки, ОМ, Факт",
    aggregationFunction: "sum",
  },
  5454: {
    id: "5454",
    desc: "Контрольные точки, ОМ, Не выполнено",
    aggregationFunction: "sum",
  },
  5464: {
    id: "5464",
    desc: "Контрольные точки, ОМ, План, Итого",
    aggregationFunction: "sum",
  },
  5465: {
    id: "5465",
    desc: "Контрольные точки, ОМ, Факт, Итого",
    aggregationFunction: "sum",
  },
  5466: {
    id: "5466",
    desc: "Контрольные точки, ОМ, Не выполнено, Итого",
    aggregationFunction: "sum",
  },
  5475: {
    id: "5475",
    desc: "Количество ГП Итого",
    aggregationFunction: "sum",
  },
  5461: {
    id: "5461",
    desc: "Мероприятия ФОИВ, риск, Итого",
    aggregationFunction: "sum",
  },
  5488: {
    id: "5488",
    desc: "Мероприятия ОМ, План, Итого",
    aggregationFunction: "sum",
  },
  5498: {
    id: "5498",
    desc: "Показатели СЭ, OM, План",
    aggregationFunction: "sum",
  },
  5499: {
    id: "5499",
    desc: "Показатели СЭ, OM, Факт",
    aggregationFunction: "sum",
  },
  5500: {
    id: "5500",
    desc: "Показатели СЭ, OM, Не выполнено",
    aggregationFunction: "sum",
  },
  5501: {
    id: "5501",
    desc: "Показатели СЭ, OM, План Итого",
    aggregationFunction: "sum",
  },
  5502: {
    id: "5502",
    desc: "Показатели СЭ, OM, Факт Итого",
    aggregationFunction: "sum",
  },
  5503: {
    id: "5503",
    desc: "Показатели СЭ, OM, Не выполнено Итого",
    aggregationFunction: "sum",
  },
};
