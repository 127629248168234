import { useCallback } from "react";

import { selectors, useAppSelector } from "../../../hooks";
import { IEvent, ISEParams, seApi } from "../api";

export interface IEvents {
  fetching: boolean;
  data: IEvent[];
  getItemByCode(code: string): IEvent | undefined;
  error: boolean;
}

export const useEvents = (id: string | number): IEvents => {
  const { period } = useAppSelector(selectors.globalFilters);
  const params: ISEParams = { id, period: period.apiV2 };
  const { data = [], isFetching, isError } = seApi.useGetSEEventsQuery(params);

  const getItemByCode = useCallback(
    (code: string): IEvent | undefined =>
      data.find(({ info }) => info.code === code),
    [data]
  );

  return {
    fetching: isFetching,
    data,
    getItemByCode,
    error: isError,
  };
};
