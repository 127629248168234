import * as React from "react";
import { NavLink } from "react-router-dom";
import { Wrapper } from "SRC/components/Breadcrumbs/BreadcrumbItem/BreadcrumbItem.styled";
import { TooltipText } from "SRC/components/TooltipText/TooltipText";
import { ILink } from "SRC/types/breadcrumbs";

export const BreadcrumbItem = ({ link, title = "" }: ILink) => {
  return (
    <Wrapper>
      {link && (
        <NavLink key={link} to={link}>
          <TooltipText hint={title} variant="h3">
            {title}
          </TooltipText>
        </NavLink>
      )}
      {!link && (
        <TooltipText hint={title} variant="h3">
          {title}
        </TooltipText>
      )}
    </Wrapper>
  );
};
