import { Box, Button, SxProps, useMediaQuery, useTheme } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Column, TableInstance, UseTableRowProps } from "react-table";
import { TableModal } from "SRC/components/FullPageTableModal/FullPageTableModal";
import { IncidentReport } from "SRC/components/IncidentReport";
import { OMSwitcher } from "SRC/components/OMSwitcher";
import { SelectWithSearchStructureEvent } from "SRC/components/SelectWithSearch/SelectWithSearchStructureEvent";
import Table from "SRC/components/Table";
import { TableActionsButtons } from "SRC/components/TableActionsButtons/TableActionsButtons";
import TableStatusCardNew from "SRC/components/TableStatusCardNew/TableStatusCardNew";
import { useTableExportParams } from "SRC/hooks";
import { getEventColumns } from "SRC/pages/Program/common/EventsSection/util/columns";
import { TableSearch } from "SRC/pages/Program/common/EventsTab/TableSearch/TableSearch";
import { FilterEventNameStructure } from "SRC/pages/Program/common/filters/FilterEventNameStructure";
import { FilterEventTypeName } from "SRC/pages/Program/common/filters/FilterEventTypeName";
import { FilterFeatures } from "SRC/pages/Program/common/filters/FilterFeatures";
import { FilterTaskStructures } from "SRC/pages/Program/common/filters/FilterTasksStructure";
import { IStatus } from "SRC/pages/Program/common/ProgramSummary/interfaces";
import { useEventsTableData } from "SRC/pages/Program/tableData/useEventsTableData";
import { createEventRowsTableData } from "SRC/pages/Program/utils";
import {
  IGlobalFilters,
  useGlobalFilters,
} from "SRC/redux/slices/global/hooks/useFilters";
import { useProgram } from "SRC/redux/slices/gosprogram/hooks";
import { useEvents } from "SRC/redux/slices/gosprogram/hooks/useEvents";
import { useFilters } from "SRC/redux/slices/gosprogram/hooks/useFilters";
import { PROGRAM_EVENT_VIEW, setParamsToUrl } from "SRC/urls";

import { css, Wrapper } from "./EventsTab.styled";

interface IEventsTabProps {
  isTableModalOpen?: boolean;
  setTableModalOpen?: (value: boolean) => void;
  infoRows?: Record<string, string>[][];
  getData?: (data: any) => void;
}

const Statuses = () => {
  const { eventsStatuses: statuses } = useEventsTableData();
  const { selectedStatus, setSelectedStatus } = useFilters();

  const handleClick = useCallback(
    ({ amount, status }: IStatus) =>
      () => {
        if (amount) {
          setSelectedStatus(status);
        }
      },
    [setSelectedStatus]
  );

  const statusesNodes: JSX.Element[] = useMemo(
    () =>
      statuses.map((item: IStatus) => (
        <TableStatusCardNew
          key={item.status}
          status={item.status}
          amount={item.amount}
          onClick={handleClick(item)}
          disabled={item.status === "В реализации"}
          selected={selectedStatus.includes(item.status)}
        />
      )),
    [statuses, handleClick, selectedStatus]
  );

  return <Box sx={css.statusCardContainer}>{statusesNodes}</Box>;
};

const sortedColumns = [
  "План",
  "Факт",
  "Уровень достижения, %",
  "Не доведено средств, млрд ₽",
  "Не принято средств, млрд ₽",
];

const RenderTable: React.FC<IEventsTabProps> = ({
  isTableModalOpen,
  getData,
}: IEventsTabProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { items: program } = useProgram();
  const [searchParams] = useSearchParams();
  const filterStatus = searchParams.get("filter");
  const [instance, setInstance] = useState<TableInstance | undefined>(
    undefined
  );
  const selectStyle = useMemo(() => css.selector(theme), [theme]);
  const [searchValue, setSearchValue] = useState<string>("");
  const { fetching, fetched: eventsDataFetched } = useEvents();
  const { eventsData: data } = useEventsTableData();
  const { sources }: IGlobalFilters = useGlobalFilters();
  const columns: Column[] = useMemo(
    () => getEventColumns(sources.OM, theme),
    [sources.OM]
  );

  const setTableFilter = useCallback(
    (value: string) => {
      instance?.setGlobalFilter(value);
      setSearchValue(value);
    },
    [instance]
  );

  const {
    selectedStructureElementType,
    setSelectedEventFeature,
    setSelectedEventTypeName,
    setSelectedStructureElementType,
    setSelectedStructureElementTask,
    setSelectedStructureElementName,
    setSelectedStatus,
    clearSelectedStatus,
    tableState: initialState,
    setSearchRows,
    setTableState,
  } = useFilters();

  const handleClearFilters = () => {
    setSelectedStructureElementType("");
    setSelectedStructureElementName("");
    setSelectedStructureElementTask("");
    setSelectedEventTypeName("");
    setTableFilter("");
    clearSelectedStatus();
  };

  useEffect(() => {
    getData && getData(data);
  }, [data]);

  useEffect(() => {
    if (filterStatus && eventsDataFetched) {
      setSelectedStatus(filterStatus);
    }
  }, [filterStatus, eventsDataFetched]);

  const onChangeFilterRows = useCallback(
    (rows: UseTableRowProps<any>[]) => {
      setSearchRows(createEventRowsTableData(rows, sources.OM));
    },
    [sources.OM]
  );

  const handleOnClick = useCallback(
    (eventId: number | string) =>
      program &&
      navigate(
        setParamsToUrl(PROGRAM_EVENT_VIEW, {
          eventId,
          programId: program.gp_code,
        })
      ),
    [program, navigate]
  );

  return (
    <Wrapper>
      <Box sx={css.searchSelectContainer}>
        <Box sx={{ flex: "1", minWidth: "20rem" }}>
          <TableSearch value={searchValue} onChange={setTableFilter} />
        </Box>
        <FilterFeatures
          label="Признак мероприятия"
          staticLabel={false}
          style={selectStyle}
          onChange={setSelectedEventFeature}
        />
        <SelectWithSearchStructureEvent
          label="Тип структурного элемента"
          staticLabel={false}
          style={selectStyle}
          onChange={setSelectedStructureElementType}
          value={selectedStructureElementType}
        />
        <FilterEventNameStructure
          label="Наименование структурного элемента"
          staticLabel={false}
          style={selectStyle}
          onChange={setSelectedStructureElementName}
        />
        <FilterEventTypeName
          label="Тип мероприятия"
          staticLabel={false}
          style={selectStyle}
          onChange={setSelectedEventTypeName}
        />
        <FilterTaskStructures
          onChange={setSelectedStructureElementTask}
          style={selectStyle}
          label="Наименование задачи"
          staticLabel={false}
        />
        {isTableModalOpen && <OMSwitcher />}
      </Box>
      <div className="controls-panel">
        <Statuses />
        <div className="filters">
          <Button sx={css.filterButton as SxProps} onClick={handleClearFilters}>
            Сбросить фильтры
          </Button>
        </div>
      </div>
      <IncidentReport className="events-tab-incident">
        <Table
          data={data}
          columns={columns}
          loading={fetching}
          sortedByPerformance={sources.OM ? "performanceOM" : "performance"}
          sortedColumns={sortedColumns}
          tableHeight={!isTableModalOpen ? "70vh" : undefined}
          noDataCondition={data?.length === 0}
          initialState={initialState}
          getLocalState={setTableState}
          getInstance={setInstance}
          onClick={handleOnClick}
          onFilterRows={onChangeFilterRows}
          inPerformance
        />
      </IncidentReport>
    </Wrapper>
  );
};

export const EventsTab: React.FC<IEventsTabProps> = (props) => {
  const { isTableModalOpen, setTableModalOpen, infoRows } = props;
  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.up("lg"));
  const { selectedSearchRows: exportData } = useFilters();
  const exports = useTableExportParams(exportData || [], "events");

  if (!match && isTableModalOpen) {
    return (
      <TableModal
        open={isTableModalOpen}
        setOpen={setTableModalOpen}
        tableName="Мероприятия"
      >
        {exportData && (
          <TableActionsButtons
            {...exports}
            open={isTableModalOpen}
            setOpen={setTableModalOpen}
            csvData={exportData}
            fileName="Мероприятия"
            infoRows={infoRows}
          />
        )}
        <RenderTable {...props} />
      </TableModal>
    );
  }

  return <RenderTable {...props} />;
};
