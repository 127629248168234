import { Box, SxProps } from "@mui/material";
import React from "react";
import { TableProps, UseTableRowProps } from "react-table";
import { css } from "SRC/components/Table/Table.styled";
import { TableCell } from "SRC/components/TableCell";
import { statusIcons } from "SRC/constants";
import { indicatorsData as data } from "SRC/pages/Ministry/common/utils/tableData";

interface CustomTableProps extends TableProps {
  row: UseTableRowProps<any>;
  rows?: UseTableRowProps<any>[];
}

const renderStatusIcons = (status: string) => statusIcons[status];

export const getIndicatorsColumns = (isOM: boolean) => [
  {
    Header: "",
    accessor: "status",
    width: css.statusIcon.width,
    columns: [
      {
        Header: "",
        width: css.statusIcon.width,
        accessor: "status",
        Cell: (tableProps: CustomTableProps) => (
          <Box sx={css.statusIcon as SxProps}>
            {renderStatusIcons(tableProps.row.original.status)}
          </Box>
        ),
      },
    ],
  },
  {
    Header: "",
    accessor: "gpCode",
    width: 90,
    columns: [
      {
        Header: "Код ГП",
        width: 90,
        accessor: "gpCode",
      },
    ],
  },
  {
    Header: "",
    accessor: "program",
    width: "auto",
    sorted: true,
    columns: [
      {
        Header: "Госпрограмма",
        width: "auto",
        accessor: "program",
      },
    ],
  },
  {
    Header: "",
    accessor: "name",
    width: "auto",
    sorted: true,
    columns: [
      {
        Header: "Наименование",
        accessor: "name",
        width: "auto",
      },
    ],
  },
  {
    Header: "",
    accessor: "unit",
    width: 100,
    sorted: true,
    columns: [
      {
        Header: "Ед.изм.",
        accessor: "unit",
        width: 100,
      },
    ],
  },
  {
    Header: "",
    accessor: "implementationPeriod",
    width: 220,
    columns: [
      {
        Header: "Период достижения",
        width: 220,
        accessor: "implementationPeriod",
      },
    ],
  },
  {
    Header: "",
    accessor: "plan",
    width: 100,
    sorted: true,
    columns: [
      {
        Header: "План",
        accessor: "plan",
        width: 100,
      },
    ],
  },
  {
    Header: "",
    accessor: "planYear",
    width: 170,
    sorted: true,
    columns: [
      {
        Header: "План на год",
        accessor: "planYear",
        width: 170,
      },
    ],
  },
  {
    Header: "Факт",
    accessor: "fact",
    width: isOM ? 200 : 100,
    columns: isOM
      ? [
          {
            Header: "ФОИВ",
            accessor: "factFoiv",
            width: 100,
            Cell: () => <TableCell color="white">{data[0].factFoiv}</TableCell>,
          },
          {
            Header: "МЭР",
            accessor: "factMayor",
            width: 100,
            Cell: () => <TableCell color="blue">{data[0].factMayor}</TableCell>,
          },
        ]
      : [
          {
            Header: "ФОИВ",
            accessor: "factFoiv",
            width: 100,
            Cell: () => <TableCell color="white">{data[0].factFoiv}</TableCell>,
          },
        ],
  },
  {
    Header: "Уровень достижения, %",
    accessor: "achievement",
    width: isOM ? 240 : 120,
    columns: isOM
      ? [
          {
            Header: "ФОИВ",
            accessor: "performance",
            width: 120,
            Cell: () => (
              <TableCell color="white">{data[0].performance}</TableCell>
            ),
          },
          {
            Header: "МЭР",
            accessor: "performanceOM",
            width: 120,
            Cell: () => (
              <TableCell color="blue">{data[0].performanceOM}</TableCell>
            ),
          },
        ]
      : [
          {
            Header: "ФОИВ",
            accessor: "performance",
            width: 120,
            Cell: () => (
              <TableCell color="white">{data[0].performance}</TableCell>
            ),
          },
        ],
  },
];
