import { styled } from "@mui/material";

export const StatusWrapper = styled("div")(({ theme }) => {
  const {
    main: { gray },
    success: { main: green },
    error: { main: red },
    background: { default: dark },
    text: { onAccent },
  } = theme.palette;

  return {
    display: "flex",
    alignItems: "center",
    gap: "0.5rem",

    "& .circle": {
      width: "0.7rem",
      height: "0.7rem",
      borderRadius: "45%",

      "&.is-red": {
        backgroundColor: red,
      },
      "&.is-green": {
        backgroundColor: green,
      },
      "&.is-gray": {
        backgroundColor: gray,
      },
      "&.is-dark": {
        backgroundColor: dark,
      },
    },
    ".white": {
      color: onAccent,
    },
  };
});
