import { IOlapResponseData } from "CORE/api/core";
import {
  createPeriodDims,
  IOlapdataResponse,
  IParsedItem,
  parseOlapdata,
  TDecoder,
} from "CORE/utils";
import { isContainedArray } from "SRC/helpers/dataTesters";
import { baseApi } from "SRC/redux/api";
import { gpInfoDims, IProgramInfo } from "SRC/redux/list/programs";
import {
  eventInfoDims,
  IEventDataInfo,
} from "SRC/redux/slices/event/slices/data";
import { IPeriod } from "SRC/redux/slices/global/slices/filters";
import {
  getDataSE,
  getEventsSE,
  getIndicatorsSE,
  getTasksSE,
} from "SRC/redux/slices/structureElement/api/client";

export interface ISEParams {
  id: number | string;
  programId?: string;
  period: IPeriod["apiV2"];
}

const GET_SE_DATA = "get-se-data";
const GET_SE_TASKS = "get-se-tasks";
const GET_SE_EVENTS = "get-se-events";
const GET_SE_INDICATORS = "get-se-indicators";

const dataDecrypt: TDecoder = {
  period: createPeriodDims(5379),
  gp: {
    code: 5380,
    dimensions: gpInfoDims,
  },
  info: {
    code: 5381,
    dimensions: {
      name: "NAME",
      code: "SE_CODE",
      type: "SE_TYPE",
    },
  },
};

export interface ISEDataInfo {
  name: string;
  code: string;
  type: string;
}

export interface ISEData extends IParsedItem {
  info: ISEDataInfo;
  gp: IProgramInfo;
}

const taskDecrypt: TDecoder = {
  gp: {
    code: 5395,
    dimensions: gpInfoDims,
  },
  se: {
    code: 5396,
    dimensions: {
      name: "NAME",
      code: "SE_CODE",
      type: "SE_TYPE",
      spc: "StateProgramCode",
    },
  },
  info: {
    code: 5397,
    dimensions: {
      name: "NAME",
      type: "SE_TASK_TYPE",
      code: "SE_TASK_CODE",
    },
  },
};

export interface ITaskInfo {
  name: string;
  type: string;
  code: string;
}

export interface ITask extends IParsedItem {
  info: ITaskInfo;
  gp: IProgramInfo;
  se: Record<string, string | number | null>;
}

const eventDecrypt: TDecoder = {
  period: createPeriodDims(1344),
  se: {
    code: 5467,
    dimensions: {
      name: "NAME",
      type: "SE_TYPE",
      code: "SE_CODE",
    },
  },
  info: {
    code: 1348,
    dimensions: eventInfoDims,
  },
};

export interface IEvent extends IParsedItem {
  info: IEventDataInfo;
  se: Record<string, string | number | null>;
}

const indicatorDecrypt: TDecoder = {
  period: createPeriodDims(5211),
  info: {
    code: 5219,
    dimensions: {
      name: "NAME",
      type: "SE_TYPE",
      code: "SE_CODE",
    },
  },
};

export interface IIndicator extends IParsedItem {
  info: IEventDataInfo;
  se: Record<string, string | number | null>;
}

export const seApi = baseApi.injectEndpoints({
  // overrideExisting: false,
  endpoints: (builder) => ({
    getSEData: builder.query({
      query: (params: ISEParams) => ({
        url: "?",
        params: { name: GET_SE_DATA },
        method: "POST",
        body: {
          requests: getDataSE(params),
        },
      }),
      transformResponse: (response: IOlapResponseData<any>): ISEData | null => {
        const parsed = parseOlapdata(
          response?.responses?.[0]?.data as IOlapdataResponse,
          dataDecrypt
        ) as unknown as ISEData[];

        return isContainedArray(parsed) ? parsed[0] : null;
      },
    }),
    getSETasks: builder.query({
      query: (params: ISEParams) => ({
        url: "?",
        params: { name: GET_SE_TASKS },
        method: "POST",
        body: {
          requests: getTasksSE(params),
        },
      }),
      transformResponse: (response: IOlapResponseData<any>) => {
        const parsed = parseOlapdata(
          response?.responses?.[0]?.data as IOlapdataResponse,
          taskDecrypt
        ) as unknown as ITask[];

        return isContainedArray(parsed) ? parsed : ([] as ITask[]);
      },
    }),
    getSEEvents: builder.query({
      query: (params: ISEParams) => ({
        url: "?",
        params: { name: GET_SE_EVENTS },
        method: "POST",
        body: {
          requests: getEventsSE(params),
        },
      }),
      transformResponse: (response: IOlapResponseData<any>) => {
        const parsed = parseOlapdata(
          response?.responses?.[0]?.data as IOlapdataResponse,
          eventDecrypt
        ) as unknown as IEvent[];

        return isContainedArray(parsed) ? parsed : ([] as IEvent[]);
      },
    }),
    getSEIndicators: builder.query({
      query: (params: ISEParams) => ({
        url: "?",
        params: { name: GET_SE_INDICATORS },
        method: "POST",
        body: {
          requests: getIndicatorsSE(params),
        },
      }),
      transformResponse: (response: IOlapResponseData<any>) => {
        const parsed = parseOlapdata(
          response?.responses?.[0]?.data as IOlapdataResponse,
          indicatorDecrypt
        ) as unknown as IIndicator[];

        return isContainedArray(parsed) ? parsed : ([] as IIndicator[]);
      },
    }),
  }),
});
