import React from "react";
import { TableProps, UseExpandedRowProps, UseTableRowProps } from "react-table";
import MinistryInfo from "SRC/components/MinistryInfo/MinistryInfo";
import NavigateToProgram from "SRC/components/NavigateToProgram/NavigateToProgram";
import { StatusTableProgram } from "SRC/components/StatusTableProgram/StatusTableProgram";
import { ExpanderCell, nestingTest } from "SRC/components/Table";
import { TableCell } from "SRC/components/TableCell";
import { COLUMNS } from "SRC/components/TableColumnTemplates/columns";

type CustomRowProps = UseTableRowProps<any> & UseExpandedRowProps<any>;

interface CustomTableProps extends TableProps {
  row: CustomRowProps;
  rows?: UseTableRowProps<any>[];
}

const getMinistriesColumns = (isOM: boolean, withSecret: boolean) => [
  {
    Header: " ",
    accessor: "status-icon",
    width: 10,
    columns: [
      {
        Header: " ",
        width: 10,
        sticky: "left",
        accessor: "status-icon",
        Cell: (tableProps: CustomTableProps) => {
          const { secretProgram, atRisk } = tableProps.row.original;
          return (
            <TableCell className="status-icon">
              <StatusTableProgram isSecret={secretProgram} atRisk={atRisk} />
            </TableCell>
          );
        },
        Footer: "",
      },
    ],
    Footer: "",
  },
  {
    Header: "Код ГП",
    accessor: "expander",
    width: 90,
    columns: [
      {
        Header: "",
        width: 90,
        accessor: "expander",
        Cell: (tableProps: CustomTableProps) => {
          const { id } = tableProps.row.original;
          if (nestingTest(tableProps.row)) {
            return (
              <TableCell color="gray" align="center">
                {String(id).padStart(2, "0")}
              </TableCell>
            );
          } else {
            return <ExpanderCell row={tableProps.row} />;
          }
        },
        Footer: "",
      },
    ],
    Footer: "",
  },
  {
    Header: "",
    accessor: "name",
    columns: [
      {
        Header: "Министерство",
        accessor: "name",
        width: "auto",
        Cell: (tableProps: CustomTableProps) => {
          if (nestingTest(tableProps.row)) {
            return (
              <NavigateToProgram program={tableProps.row.original} isSmall />
            );
          }

          return <MinistryInfo ministry={tableProps.row.original} />;
        },
        Footer: <TableCell>Итого по всем министерствам</TableCell>,
      },
    ],
    Footer: "",
  },
  {
    Header: "",
    accessor: "numOfPrograms",
    columns: [
      {
        Header: "Госпрограммы",
        accessor: "numOfPrograms",
        width: 150,
        Cell: (tableProps: CustomTableProps) => {
          const numOfPrograms = tableProps.row.original.subRows?.length ?? "";
          return <TableCell>{numOfPrograms}</TableCell>;
        },
        Footer: () => {
          const total = withSecret ? 50 : 38;

          return <TableCell>{total}</TableCell>;
        },
      },
    ],
  },
  {
    Header: "Показатели",
    accessor: "indicators",
    columns: [
      { ...COLUMNS.indicators.plan(isOM) },
      { ...COLUMNS.indicators.fact(isOM) },
    ],
  },
  {
    Header: "Мероприятия",
    accessor: "events",
    columns: [
      { ...COLUMNS.events.plan(isOM) },
      { ...COLUMNS.events.fact(isOM) },
      { ...COLUMNS.events.risk(isOM) },
    ],
  },
  {
    Header: "",
    accessor: isOM ? "performanceOM" : "performance",
    columns: [{ ...COLUMNS.performance.factMain(isOM) }],
  },
];

export { getMinistriesColumns };
