import { Button, styled, Theme } from "@mui/material";

export const css = {
  buttonsContainer: {
    display: "flex",
    gap: "0.5rem",
    justifyContent: "flex-end",
    alignItems: "center",
    height: "fit-content",
  },
  button: (active?: boolean) => ({
    backgroundColor: (theme: Theme) =>
      active
        ? theme.palette.main.blue
        : theme.palette.complementary.darkGrayBlue,
    width: "2.5rem",
    height: "2.5rem",
    minWidth: "auto",
    borderRadius: "8px",
    padding: 0,

    svg: {
      width: "1rem",
    },

    "& > span": {
      display: "flex",
      justifyContent: "center",
    },
  }),
  iconButton: (color?: string) => ({
    backgroundColor: "transparent !important",
    minWidth: 0,
    height: "fit-content",
    color,
  }),
  outlinedButton: {
    color: (theme: Theme) => theme.palette.primary.contrastText,
    background: (theme: Theme) => theme.palette.main.blue,
    borderRadius: "0.5rem",
    fontSize: "1rem !important",
    lineHeight: "1rem !important",
    textTransform: "capitalize",
    fontWeight: "0rem !important",
    padding: "0.8rem 1.2rem",
  },
};

export const ProgramExportButtonStyled = styled("div")(() => {
  return {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "1.875rem",
    height: "1.875rem",
  };
});

interface IDownloadButtonStyled {
  btncolor?: string;
}

export const DownloadButtonStyled = styled(Button)<IDownloadButtonStyled>(
  ({ btncolor }) => {
    return {
      backgroundColor: "transparent !important",
      minWidth: 0,
      height: "fit-content",
      color: btncolor,
    };
  }
);

export const TableExportButtonStyled = styled("div")(({ theme }) => {
  return {
    overflow: "hidden",

    "& .export-button": {
      backgroundColor: theme.palette.complementary.darkGrayBlue,
      width: "2.5rem",
      height: "2.5rem",
      minWidth: "auto",
      borderRadius: "8px",
      padding: 0,

      svg: {
        width: "1.2rem",
        color: "#FFFFFF",
      },

      "& > span": {
        display: "flex",
        justifyContent: "center",
      },
    },
  };
});
