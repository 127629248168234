import { styled } from "@mui/material";

export const Wrapper = styled("div")(() => ({
  display: "flex",
  justifyContent: "space-between",
  "> .title": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "baseline",
    fontSize: "1.2rem",
    gap: "0.5rem",
    span: {
      fontSize: "0.8rem",
      color: "rgba(255, 255, 255, 0.5)",
    },
  },
  "> .statuses": {
    display: "flex",
    gap: "1rem",
  },
}));
