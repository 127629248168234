import React from "react";
import { Wrapper } from "SRC/components/NavigateToProgram/NavigateToProgram.styled";
import { NavigateCell } from "SRC/components/Table";
import { ITableDataItem } from "SRC/hooks/summaryTable/interfaces";
import { PROGRAM_TABS } from "SRC/types";
import { createProgramCardUrl, PROGRAM_VIEW_TAB } from "SRC/urls";

interface INavigateToProgramProps {
  program: ITableDataItem;
  isSmall?: boolean;
}
const NavigateToProgram = ({
  program,
  isSmall = false,
}: INavigateToProgramProps) => {
  return (
    <Wrapper isSmall={isSmall}>
      <img src={program.icon} alt={program.name} />
      <NavigateCell
        url={createProgramCardUrl(
          PROGRAM_VIEW_TAB,
          program.id,
          PROGRAM_TABS.INDICATORS,
          ""
        )}
      >
        {program.name}
      </NavigateCell>
    </Wrapper>
  );
};

export default NavigateToProgram;
