import { getRoundValue } from "SRC/helpers/getRoundValue";
import { getIsSecret } from "SRC/pages/Summary/common/SummaryComplexGrid/utils";
import { ICustomTableProps } from "SRC/types";

export const getPerformance = (
  tableProps: ICustomTableProps,
  isOM: boolean
) => {
  const { performance, performanceOM } = tableProps.row.original;

  const value = isOM ? performanceOM : performance;

  return {
    isSecret: getIsSecret(tableProps),
    performance: value,
    formattedPerformance: getRoundValue(value, 2),
  };
};
