import { useCallback } from "react";

import { selectors, useAppSelector } from "../../../hooks";
import { IIndicator, ISEParams, seApi } from "../api";

export interface IIndicators {
  fetching: boolean;
  data: IIndicator[];
  getItemByCode(code: string): IIndicator | undefined;
  error: boolean;
}

export const useIndicators = (id: string | number): IIndicators => {
  const { period } = useAppSelector(selectors.globalFilters);
  const params: ISEParams = { id, period: period.apiV2 };
  const {
    data = [],
    isFetching,
    isError,
  } = seApi.useGetSEIndicatorsQuery(params);

  const getItemByCode = useCallback(
    (code: string): IIndicator | undefined =>
      data.find(({ info }) => info.code === code),
    [data]
  );

  return {
    fetching: isFetching,
    data,
    getItemByCode,
    error: isError,
  };
};
