import { useCallback } from "react";
import { useDispatch } from "react-redux";
import slice, {
  IFiltersState,
} from "SRC/redux/slices/indicator/slices/filters";

import { useAppSelector } from "../../../hooks";

export interface IFilters extends IFiltersState {
  clear(): void;
  setSelectedIndicatorId(id: string | number): void;
  setIndicatorModalOpen(open: boolean): void;
}

export const useFilters = (): IFilters => {
  const dispatch = useDispatch();
  const filters = useAppSelector((state) => state.indicator.filters);

  const setSelectedIndicatorId = (id: string) =>
    dispatch(slice.actions.selectIndicatorId(id));

  const setIndicatorModalOpen = (open: boolean) =>
    dispatch(slice.actions.setIndicatorModalOpen(open));

  const clear = useCallback(() => dispatch(slice.actions.clear()), [dispatch]);

  return {
    ...filters,
    clear,
    setSelectedIndicatorId,
    setIndicatorModalOpen,
  };
};
