import { Box, SxProps } from "@mui/material";
import React, { useMemo } from "react";
import { OverlayBlock } from "SRC/components/OverlayBlock";
import { EffectiveInfo } from "SRC/pages/Program/common/GovProgramInfoWidget/common/EffectiveInfo/EffectiveInfo";
import { FinancingInfo } from "SRC/pages/Program/common/GovProgramInfoWidget/common/FinancingInfo/FinancingInfo";
import { HeaderInfoWidget } from "SRC/pages/Program/common/GovProgramInfoWidget/common/HeaderInfoWidget/HeaderInfoWidget";
import {
  getHeaderData,
  getProgramIconUrl,
  getProgramType,
} from "SRC/pages/Program/utils";
import { useProgram } from "SRC/redux/slices/gosprogram/hooks/useProgram";

import { EventsInfo } from "./common/EventsInfo/EventsInfo";
import { css } from "./GovProgramInfoWidget.styled";

export const GovProgramInfoWidget = () => {
  const { items: program, fetching } = useProgram();

  const { programIconUrl, programType } = useMemo(
    () => ({
      programIconUrl: getProgramIconUrl(program),
      programType: getProgramType(program),
    }),
    [program]
  );

  const headerData = useMemo(() => {
    return getHeaderData(program, programType, programIconUrl);
  }, [program, programType]);

  return (
    <Box sx={css.infoWidgetContainer as SxProps}>
      <OverlayBlock isFetching={fetching} hasData={Boolean(headerData)}>
        <>
          <Box sx={css.programIcon as SxProps}>
            <img src={programIconUrl} alt="icon" />
          </Box>
          <HeaderInfoWidget headerData={headerData} />
        </>
      </OverlayBlock>
      <Box sx={css.bodyInfoWidget}>
        <EffectiveInfo />
        <EventsInfo />
        <FinancingInfo />
      </Box>
    </Box>
  );
};
