import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import { baseApi, oldApi } from "SRC/redux/api";
import { spheresApi } from "SRC/store/spheres";

import reducer from "./reducer";

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      oldApi.middleware,
      baseApi.middleware,
      spheresApi.middleware
    ),
});

export type IRootState = ReturnType<typeof store.getState>;
export type IAppDispatch = typeof store.dispatch;
export type IAppThunk = ThunkAction<void, unknown, unknown, Action<string>>;

export const dispatch = store.dispatch;

export default store;
