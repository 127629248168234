import { SxProps } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import SelectWithSearch from "SRC/components/SelectWithSearch/SelectWithSearch";
import { useEventsTableData } from "SRC/pages/Program/tableData/useEventsTableData";
import { useFilters } from "SRC/redux/slices/gosprogram/hooks/useFilters";

type TOptionValue = string | number;

interface IOption {
  value: TOptionValue;
  label: string;
  disabled?: boolean;
}

interface IStyle {
  labelStyle?: SxProps;
  selectStyle?: SxProps;
  optionStyle?: SxProps;
}

interface IFilterFeatureProps {
  onChange(value: TOptionValue): void;
  label?: string;
  staticLabel?: boolean;
  colorizeActiveOption?: boolean;
  style: IStyle;
}
export const FilterFeatures = ({
  onChange,
  staticLabel = true,
  label,
  colorizeActiveOption = true,
  style,
}: IFilterFeatureProps) => {
  const { selectedEventFeature } = useFilters();
  const { containedEventFeatures } = useEventsTableData();
  const [feature, setFeature] = useState<string>("");

  useEffect(() => {
    setFeature(selectedEventFeature);
  }, [selectedEventFeature]);

  const selectOptionsEventFeatures = useMemo(
    () =>
      containedEventFeatures.map((item) => ({
        value: item,
        label: item,
      })),
    [containedEventFeatures]
  );

  const customOptions = useMemo(
    () => [
      {
        value: "",
        label: label || "Все",
      },
      ...selectOptionsEventFeatures,
    ],
    [label, selectOptionsEventFeatures]
  );

  return (
    <SelectWithSearch
      onChange={onChange}
      options={customOptions}
      getOptionLabel={(option: IOption) => option.label}
      staticLabel={staticLabel}
      label={label}
      colorizeActiveOption={colorizeActiveOption}
      style={style}
      hasCloseIcon={Boolean(feature)}
      value={feature}
    />
  );
};
