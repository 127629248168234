import { olapRequestData3 } from "CORE/api/core";
import { PREMIERS_MODEL_UUID } from "CORE/scheme/olap";
import { getVpIndicators } from "SRC/constants";
import { IPeriod } from "SRC/redux/slices/global/slices/filters";

const GET_TOP_BEST_VP = "get-top-best-vp";

export const getTopBestVP = (period: IPeriod) => {
  return olapRequestData3(
    [
      {
        requestId: "88d37d5e-416e-4210-b0ce-483704c9492c",
        type: "data2",
        params: {
          modelUuid: PREMIERS_MODEL_UUID,
          dashboardUuid: "",
          includeGaps: false,
          dimensions: [
            {
              id: "1683",
              includeItems: true,
              includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
            },
            {
              id: "1686",
              includeItems: true,
              includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
            },
          ],
          indicators: {
            id: "1692",
            items: getVpIndicators(1759),
            limit: {
              id: "1759",
              order: "desc",
              type: "TOP",
              value: 5,
            },
          },
          dataRequestCaching: true,
          dataFilter: {
            type: "AND",
            version: 1,
            operands: [
              {
                type: "STARTER",
                version: 1,
                dim_id: "1686",
              },
              {
                type: "EQ",
                version: 1,
                operands: [
                  {
                    type: "DIM",
                    version: 1,
                    id: "1683",
                  },
                  {
                    type: "CONST",
                    version: 1,
                    values: [period.apiV2],
                  },
                ],
              },
            ],
          },
        },
      },
    ],
    GET_TOP_BEST_VP
  );
};
