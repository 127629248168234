import { ICustomTableProps } from "SRC/types";

export const getCheckpointsFactTotal = (
  tableProps: ICustomTableProps,
  isOM: boolean
) => {
  let { checkpointsFactTotal, checkpointsOMFactTotal } =
    tableProps?.rows?.[0]?.original || {};

  return {
    checkpointsFactTotal: isOM ? checkpointsOMFactTotal : checkpointsFactTotal,
  };
};
