export enum PROGRAM_TABS {
  INDICATORS = "indicators",
  EVENTS = "events",
  STRUCTURE = "structure",
  TASKS = "tasks",
  PROGRAM_STRUCTURE = "programStructure",
}

export enum INDICATORS_TYPE {
  SIMPLE = "simple",
  ANALYTICS = "analytics",
}
