import { ICustomTableProps } from "SRC/types";

export const getIndicatorsSEPlanTotal = (
  tableProps: ICustomTableProps,
  isOM: boolean
) => {
  // @ts-ignore
  let { indicatorsSEPlanTotal, indicatorsSEOMPlanTotal } =
    tableProps?.rows?.[0]?.original || {};

  return {
    indicatorsSEPlanTotal: isOM
      ? indicatorsSEOMPlanTotal
      : indicatorsSEPlanTotal,
  };
};
