import { SerializedError } from "@reduxjs/toolkit";
import { useCallback, useState } from "react";
import {
  IProgram,
  IProgramParams,
} from "SRC/redux/slices/gosprogram/slices/program";

import { useAppDispatch, useAppSelector } from "../../../hooks";
import actions from "../actions";
import { TProgramCachePath } from "../types";
import { periodYearMonth } from "../utils";

export interface IPrograms {
  fetching: boolean;
  fetched: boolean;
  items: IProgram | null;
  load(params: IProgramParams): void;
  getCached(params: TProgramCachePath): void;
  error: SerializedError | null;
}

export const useProgram = (): IPrograms => {
  const dispatch = useAppDispatch();
  const {
    fetched,
    fetching,
    items: programs = null,
    map,
  } = useAppSelector((state) => state.gosprogram.program);
  const [error, setError] = useState<SerializedError | null>(null);

  const getCached = useCallback(
    (params: TProgramCachePath) => {
      dispatch(actions.program.getCachedProgram(params));
    },
    [dispatch]
  );

  const load = useCallback(
    (params: any) => {
      if (!fetching) {
        const { programId, period_code } = params;
        const { year, month } = periodYearMonth(period_code);

        if (map[year]?.[month]?.[programId]) {
          getCached({ year, month, programId });
        } else {
          dispatch(actions.program.get(params)).then((action) => {
            if (actions.program.get.rejected.match(action)) {
              setError(action.error);
            }
            return action;
          });
        }
      }
    },
    [dispatch, fetching]
  );

  return {
    fetching,
    fetched,
    items: programs,
    load,
    getCached,
    error,
  };
};

export type { IProgram };
