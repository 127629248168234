import { Theme } from "@mui/material";

export const css = {
  listShadow: (position: string) => ({
    position: "absolute",
    transform: position !== "top" ? "matrix(1, 0, 0, -1, 0, 0)" : "",
    width: "100%",
    padding: "2rem",
    top: position !== "top" ? "" : 0,
    zIndex: "1",
    bottom: position !== "top" ? 0 : "",
    // background: "linear-gradient(180deg, #212735 0%, rgba(33, 39, 53, 0) 100%)",
    "& button": {
      margin: "0 auto",
      width: "3.75rem",
      height: "2.375rem",
      background: "#4A79D8",
      borderRadius: "0.5rem",
      display: "flex",
      position: "relative",
      top: "-3rem",
      "& svg": {
        width: "3.75rem",
        height: "0.6rem",
      },
      "&:disabled": {
        background: (theme: Theme) => theme.palette.complementary.grayBlue,
      },
    },
  }),
};
