import { Stack } from "@mui/material";
import { WarningTriangle } from "ASSETS/svg";
import React from "react";
import { useMemo } from "react";
import { NavigateBackButton } from "SRC/components/NavigateBackButton/NavigateBackButton";
import { OverlayBlock } from "SRC/components/OverlayBlock";
import { TooltipText } from "SRC/components/TooltipText/TooltipText";
import { IDataSideBar } from "SRC/pages/Event/interfaces";
import { prepareData } from "SRC/pages/Event/util/utils";
import { useEventData } from "SRC/redux/slices/event/hooks/useEventData";

import { css } from "./Header.styled";

export const Header: React.FC = () => {
  const { items: event, fetching: loading } = useEventData();

  const { name } = useMemo(() => {
    return {
      name: event?.info.name || "Название мероприятия",
      characteristic: event?.info.characteristic || "Характеристика",
    };
  }, [event]);

  const data = useMemo<IDataSideBar>(() => prepareData(event), [event]);

  return (
    <OverlayBlock isFetching={loading} hasData={Boolean(event)}>
      <Stack sx={css.wrapper}>
        <Stack className="titles-wrapper">
          <div className="title">
            <NavigateBackButton className="back-button" />
            {data?.event?.atRisk !== 0 && (
              <div className="risk">
                <WarningTriangle />
              </div>
            )}
            <TooltipText hint={name} variant="h2">
              {name}
            </TooltipText>
          </div>
        </Stack>
      </Stack>
    </OverlayBlock>
  );
};
