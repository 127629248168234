import { TIndicatorMap } from "../types";

export const MINISTRY_INDICATORS: TIndicatorMap = {
  1770: {
    id: "1770",
    desc: "Министерства",
  },
  1774: {
    id: "1774",
    desc: "Период",
  },
  1782: {
    id: "1782",
    desc: "Госпрограммы",
    aggregationFunction: "sum",
  },
  1783: {
    id: "1783",
    desc: "Показатели. План",
    aggregationFunction: "sum",
  },
  1784: {
    id: "1784",
    desc: "Показатели. Выполнено",
    aggregationFunction: "sum",
  },
  1785: {
    id: "1785",
    desc: "Мероприятия. План",
    aggregationFunction: "sum",
  },
  1786: {
    id: "1786",
    desc: "Мероприятия. Факт",
    aggregationFunction: "sum",
  },
  1787: {
    id: "1787",
    desc: "Мероприятия. Под риском",
    aggregationFunction: "sum",
  },
  1788: {
    id: "1788",
    desc: "Уровень достижения",
    aggregationFunction: "sum",
  },
  1845: {
    id: "1845",
    desc: "Уровень достижения",
    aggregationFunction: "sum",
  },
  1848: {
    id: "1848",
    desc: "Уровень достижения структурных элементов",
    aggregationFunction: "sum",
  },
  1849: {
    id: "1849",
    desc: "Уровень достижения структурных элементов",
    aggregationFunction: "sum",
  },
  5268: {
    id: "5268",
    desc: "Уровень достижения особое мнение",
    aggregationFunction: "sum",
  },
  5273: {
    id: "5273",
    desc: "Уровень достижения особое мнение итого",
    aggregationFunction: "sum",
  },
  5274: {
    id: "5274",
    desc: "Уровень достижения особое мнение итого цвет",
    aggregationFunction: "sum",
  },
  5275: {
    id: "5275",
    desc: "Уровень достижения итого",
    aggregationFunction: "sum",
  },
  5276: {
    id: "5276",
    desc: "Уровень достижения итого цвет",
    aggregationFunction: "sum",
  },
  5387: {
    id: "5387",
    desc: "Показатели ОМ Факт",
    aggregationFunction: "sum",
  },
  5388: {
    id: "5388",
    desc: "Показатели ОМ План",
    aggregationFunction: "sum",
  },
  5389: {
    id: "5389",
    desc: "Мероприятия ОМ Факт",
    aggregationFunction: "sum",
  },
  5390: {
    id: "5390",
    desc: "Мероприятия ОМ План",
    aggregationFunction: "sum",
  },
  5401: {
    id: "5401",
    desc: "Кол-во ГП без секретных",
    aggregationFunction: "sum",
  },
  5402: {
    id: "5402",
    desc: "Кассовое исполнение, План",
    aggregationFunction: "sum",
  },
  5403: {
    id: "5403",
    desc: "Кассовое исполнение, Факт %",
    aggregationFunction: "sum",
  },
  5404: {
    id: "5404",
    desc: "Показатели, ФОИВ, Не выполнено",
    aggregationFunction: "sum",
  },
  5405: {
    id: "5405",
    desc: "Показатели, ОМ, Не выполнено",
    aggregationFunction: "sum",
  },
  5406: {
    id: "5406",
    desc: "Структурные элементы, ФОИВ, План",
    aggregationFunction: "sum",
  },
  5407: {
    id: "5407",
    desc: "Структурные элементы, ФОИВ, Факт",
    aggregationFunction: "sum",
  },
  5408: {
    id: "5408",
    desc: "Структурные элементы, ФОИВ, Не выполнено",
    aggregationFunction: "sum",
  },
  5409: {
    id: "5409",
    desc: "Структурные элементы, ОМ, План",
    aggregationFunction: "sum",
  },
  5410: {
    id: "5410",
    desc: "Структурные элементы, ОМ, Факт",
    aggregationFunction: "sum",
  },
  5411: {
    id: "5411",
    desc: "Структурные элементы, ОМ, Не выполнено",
    aggregationFunction: "sum",
  },
  5412: {
    id: "5412",
    desc: "Мероприятия ФОИВ, Не выполнено",
    aggregationFunction: "sum",
  },
  5413: {
    id: "5413",
    desc: "Мероприятия ОМ, Не выполнено",
    aggregationFunction: "sum",
  },
  5421: {
    id: "5421",
    desc: "Кассовое исполнение, План, Итого",
    aggregationFunction: "sum",
  },
  5422: {
    id: "5422",
    desc: "Кассовое исполнение, Факт, %, Итого",
    aggregationFunction: "sum",
  },
  5423: {
    id: "5423",
    desc: "Показатели, ФОИВ, План, Итого",
    aggregationFunction: "sum",
  },
  5424: {
    id: "5424",
    desc: "Показатели, ФОИВ, Не выполнено, Итого",
    aggregationFunction: "sum",
  },
  5425: {
    id: "5425",
    desc: "Показатели, ОМ, План, Итого",
    aggregationFunction: "sum",
  },
  5426: {
    id: "5426",
    desc: "Показатели, ОМ, Факт, Итого",
    aggregationFunction: "sum",
  },
  5427: {
    id: "5427",
    desc: "Показатели, ОМ, Не выполнено, Итого",
    aggregationFunction: "sum",
  },
  5428: {
    id: "5428",
    desc: "Структурные элементы, ФОИВ, План, Итого",
    aggregationFunction: "sum",
  },
  5429: {
    id: "5429",
    desc: "Структурные элементы, ФОИВ, Факт, Итого",
    aggregationFunction: "sum",
  },
  5430: {
    id: "5430",
    desc: "Структурные элементы, ФОИВ, Не выполнено, Итого",
    aggregationFunction: "sum",
  },
  5431: {
    id: "5431",
    desc: "Структурные элементы, ОМ, План, Итого",
    aggregationFunction: "sum",
  },
  5432: {
    id: "5432",
    desc: "Структурные элементы, ОМ, Факт, Итого",
    aggregationFunction: "sum",
  },
  5433: {
    id: "5433",
    desc: "Структурные элементы, ОМ, Не выполнено, Итого",
    aggregationFunction: "sum",
  },
  5434: {
    id: "5434",
    desc: "Мероприятия ФОИВ/ОМ, План, Итого",
    aggregationFunction: "sum",
  },
  5435: {
    id: "5435",
    desc: "Мероприятия ФОИВ, Факт, Итого",
    aggregationFunction: "sum",
  },
  5436: {
    id: "5436",
    desc: "Мероприятия ФОИВ, Не выполнено, Итого",
    aggregationFunction: "sum",
  },
  5437: {
    id: "5437",
    desc: "Мероприятия ФОИВ, риск, Итого",
    aggregationFunction: "sum",
  },
  5438: {
    id: "5438",
    desc: "Мероприятия ОМ, Факт, Итого",
    aggregationFunction: "sum",
  },
  5439: {
    id: "5439",
    desc: "Мероприятия ОМ, Не выполнено, Итого",
    aggregationFunction: "sum",
  },
  5414: {
    id: "5414",
    desc: "Контрольные точки, ФОИВ, План",
    aggregationFunction: "sum",
  },
  5415: {
    id: "5415",
    desc: "Контрольные точки, ФОИВ, Факт",
    aggregationFunction: "sum",
  },
  5416: {
    id: "5416",
    desc: "Контрольные точки, ФОИВ, Не выполнено",
    aggregationFunction: "sum",
  },
  5440: {
    id: "5440",
    desc: "Контрольные точки, ФОИВ, План, Итого",
    aggregationFunction: "sum",
  },
  5441: {
    id: "5441",
    desc: "Контрольные точки, ФОИВ, Факт, Итого",
    aggregationFunction: "sum",
  },
  5442: {
    id: "5442",
    aggregationFunction: "sum",
    desc: "Контрольные точки, ФОИВ, Не выполнено, Итого",
  },
  5417: {
    id: "5417",
    desc: "Контрольные точки, ОМ, План",
    aggregationFunction: "sum",
  },
  5418: {
    id: "5418",
    desc: "Контрольные точки, ОМ, Факт",
    aggregationFunction: "sum",
  },
  5419: {
    id: "5419",
    desc: "Контрольные точки, ОМ, Не выполнено",
    aggregationFunction: "sum",
  },
  5443: {
    id: "5443",
    desc: "Контрольные точки, ОМ, План, Итого",
    aggregationFunction: "sum",
  },
  5444: {
    id: "5444",
    desc: "Контрольные точки, ОМ, Факт, Итого",
    aggregationFunction: "sum",
  },
  5445: {
    id: "5445",
    desc: "Контрольные точки, Не выполнено, Итого",
    aggregationFunction: "sum",
  },
  5504: {
    id: "5504",
    desc: "Показатели СЭ, ОМ, План",
    aggregationFunction: "sum",
  },
  5505: {
    id: "5505",
    desc: "Показатели СЭ, ОМ, План, Итого",
    aggregationFunction: "sum",
  },
  5506: {
    id: "5506",
    desc: "Показатели СЭ, ОМ, Выполнено",
    aggregationFunction: "sum",
  },
  5507: {
    id: "5507",
    desc: "Показатели СЭ, ОМ, Выполнено, Итого",
    aggregationFunction: "sum",
  },
  5508: {
    id: "5508",
    desc: "Показатели СЭ, ОМ, Невыполнено",
    aggregationFunction: "sum",
  },
  5509: {
    id: "5509",
    desc: "Показатели СЭ, ОМ, Невыполнено, Итого",
    aggregationFunction: "sum",
  },
  5510: {
    id: "5510",
    desc: "Показатели СЭ, ФОИВ, План",
    aggregationFunction: "sum",
  },
  5511: {
    id: "5511",
    desc: "Показатели СЭ, ФОИВ, План, Итого",
    aggregationFunction: "sum",
  },
  5512: {
    id: "5512",
    desc: "Показатели СЭ, ФОИВ, Выполнено",
    aggregationFunction: "sum",
  },
  5513: {
    id: "5513",
    desc: "Показатели СЭ, ФОИВ, Выполнено, Итого",
    aggregationFunction: "sum",
  },
  5514: {
    id: "5514",
    desc: "Показатели СЭ, ФОИВ, Невыполнено",
    aggregationFunction: "sum",
  },
  5515: {
    id: "5515",
    desc: "Показатели СЭ, ФОИВ, Невыполнено, Итого",
    aggregationFunction: "sum",
  },
  5525: {
    id: "5525",
    desc: "Количество ФП",
    aggregationFunction: "sum",
  },
  5526: {
    id: "5526",
    desc: "Количество ВП",
    aggregationFunction: "sum",
  },
  5527: {
    id: "5527",
    desc: "Количество КПМ",
    aggregationFunction: "sum",
  },
  5598: {
    id: "5598",
    desc: "Показатели, ФОИВ, Факт, Итого",
    aggregationFunction: "sum",
  },
};
