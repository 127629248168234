import React, { useMemo, useState } from "react";
import { ExtendedTabs, ITab } from "SRC/components/ExtendedTabs/ExtendedTabs";
import { Risks } from "SRC/pages/Main/common/Analitics/Risks/Risks";
import { useStats } from "SRC/redux/slices/main/hooks/useStats";
import { IIndicatorRisksData } from "SRC/types/analitics";

export const RisksTabs = () => {
  const [activeTab, setActiveTab] = useState(0);
  const { items: stats } = useStats();
  const data = useMemo(() => stats[0]?.data || {}, [stats]);

  const { objects, events, indicatiorRegulations, indicatorRegulationsAtRisk } =
    useMemo<IIndicatorRisksData>(
      () => ({
        objects: {
          plan: data["Создано объектов, план"] || 0,
          fact: data["Создано объектов, факт"] || 0,
          risks: data["Объектов под риском"] || 0,
          percent: data["Создано объектов, %"] || 0,
        },
        events: {
          plan: data["Мероприятий всего"] || 0,
          fact: data["Мероприятий под риском"] || 0,
          risks: data["Мероприятий под риском"] || 0,
          percent: data["Мероприятия под риском, %"] || 0,
        },
        indicatiorRegulations: {
          plan: data["Нормативно-правовые акты, план"] || 0,
          fact: data["Нормативно-правовые акты, факт"] || 0,
          percent: data["Нормативно правовые акты, %"] || 0,
          risks: data["Нормативно правовые акты, %"] || 0,
        },
        indicatorRegulationsAtRisk: {
          plan: data["Нормативно-правовые акты, план"] || 0,
          fact: data["Нормативно правовые акты, под риском"] || 0,
          percent: data["Нормативно правовые акты, %"] || 0,
          risks: data["Нормативно правовые акты, под риском"] || 0,
        },
      }),
      [data]
    );

  const tabs: ITab<number>[] = [
    {
      value: 0,
      label: "OKC",
      component: (
        <Risks
          topName={"Создано объектов"}
          dataTop={objects}
          dataBottom={indicatiorRegulations}
        />
      ),
    },
    {
      value: 1,
      label: "Риски",
      component: (
        <Risks
          topName={"Мероприятия"}
          dataTop={events}
          dataBottom={indicatorRegulationsAtRisk}
        />
      ),
    },
  ];

  const onChangeTab = (value: number): void => setActiveTab(value);

  return (
    <ExtendedTabs
      value={activeTab}
      onChange={onChangeTab}
      tabs={tabs}
      borderStyle="solid"
      style={{ fontSize: "1.25rem" }}
    />
  );
};
