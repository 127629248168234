export const INDICATORS = [
  {
    id: "1796",
    desc: "Уровень достижения 2",
    aggregationFunction: "sum",
  },
  {
    id: "1837",
    desc: "Количество ГП",
    aggregationFunction: "sum",
  },
  {
    id: "5265",
    desc: "Уровень достижения ОМ",
    aggregationFunction: "sum",
  },
  {
    id: "5491",
    desc: "Количество несекретных ГП",
    aggregationFunction: "sum",
  },
];
