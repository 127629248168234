import { Grid, Stack, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { OverlayBlock } from "SRC/components/OverlayBlock";
import { TooltipText } from "SRC/components/TooltipText/TooltipText";
import { LEVEL_EVENT_LIST, TLevelEventList } from "SRC/pages/Event/util/utils";
import { useEventData } from "SRC/redux/slices/event/hooks/useEventData";

import { css } from "./Description.styled";

interface ICol {
  label: string;
  value?: number | string;
  flex?: boolean;
}

export const Description = () => {
  const { items: data, fetching: loading } = useEventData();
  const cols: JSX.Element[] = useMemo(
    () =>
      [
        {
          label: "Ответственный",
          value: data?.info.curator || "–",
          flex: true,
        },
        {
          label: "Структурный элемент",
          value: data?.info.project || "–",
          flex: true,
        },
        {
          label: "Уровень мероприятия",
          value: LEVEL_EVENT_LIST[data?.info.type as TLevelEventList] || "-",
        },
        {
          label: "Единица измерения",
          value: data?.info.resultUnit || "–",
        },
      ].map((item: ICol) => (
        <Stack
          sx={css.headerEvent}
          key={item.label}
          className={item.flex ? "fullSize" : ""}
        >
          <Typography sx={css.text} color="main.gray">
            {item.label}
          </Typography>
          <TooltipText hint={String(item.value)} variant="h3">
            {item.value}
          </TooltipText>
        </Stack>
      )),
    [data]
  );

  return (
    <OverlayBlock isFetching={loading} hasData={Boolean(cols.length)}>
      <Grid container sx={css.container}>
        {cols}
      </Grid>
    </OverlayBlock>
  );
};
