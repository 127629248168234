export interface IPremiersData {
  number: number;
  name: string;
  countGP: number;
  performanceGP: number;
  performanceKP: number;
  prioritiesTotal: number;
  prioritiesDone: number;
  prioritiesFail: number;
}

export const premiersData: IPremiersData[] = [
  {
    number: 1,
    name: "Абрамченко В.В.",
    countGP: 3,
    performanceGP: 12,
    performanceKP: 35,
    prioritiesDone: 23,
    prioritiesFail: 45,
    prioritiesTotal: 11,
  },
  {
    number: 2,
    name: "Белоусов А.Р.",
    countGP: 3,
    performanceGP: 12,
    performanceKP: 35,
    prioritiesDone: 23,
    prioritiesFail: 45,
    prioritiesTotal: 11,
  },
  {
    number: 3,
    name: "Борисов Ю.И.",
    countGP: 3,
    performanceGP: 12,
    performanceKP: 35,
    prioritiesDone: 23,
    prioritiesFail: 45,
    prioritiesTotal: 11,
  },
];
