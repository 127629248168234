type TRow = {
  id: string;
  hasFeature: boolean;
  value: string | number;
};

export const sortColumn = (rowA: TRow, rowB: TRow, isDesc: boolean) => {
  const { id: idA, hasFeature: isFeatureA, value: valueA } = rowA;
  const { id: idB, hasFeature: isFeatureB, value: valueB } = rowB;

  // Сперва сортировка по секретности: секретные ГП всегда внизу списка
  if (isFeatureA !== isFeatureB) {
    if (isDesc) {
      return isFeatureA < isFeatureB ? 1 : -1;
    } else {
      return isFeatureA < isFeatureB ? -1 : 1;
    }
  } else {
    // Если обе ГП секретные, то сортировка по ID
    if (isFeatureA) {
      return idA < idB ? 1 : -1;
    } else {
      // Если значения совпадают,
      if (valueA === valueB) {
        if (isDesc) {
          return idA < idB ? 1 : -1;
        } else {
          return idA < idB ? -1 : 1;
        }
      }
    }
  }

  return valueA > valueB ? 1 : -1;
};
