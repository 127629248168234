import InfoIcon from "@mui/icons-material/Info";
import { useMediaQuery, useTheme } from "@mui/material";
import React, { useMemo } from "react";
import { TooltipText } from "SRC/components/TooltipText/TooltipText";
import { getPercentValue } from "SRC/helpers/getPercentValue";
import ChartView from "SRC/pages/Program/common/GoalsTab/GoalItem/ChartView/ChartView";
import IndicatorInfo from "SRC/pages/Program/common/GoalsTab/GoalItem/IndicatorInfo/IndicatorInfo";
import { useGlobalFilters } from "SRC/redux/slices/global/hooks/useFilters";
import { IProgramGoal } from "SRC/types";
import { INDICATORS_TYPE } from "SRC/types/program/program-tab";

import { Wrapper } from "./GoalItem.styled";

interface IProps {
  item: IProgramGoal;
  chunkLength: number | boolean;
  type: INDICATORS_TYPE;
}

const GoalItem = (props: IProps) => {
  const { item, chunkLength } = props;
  const { sources } = useGlobalFilters();
  const theme = useTheme();
  const isExtraLarge = useMediaQuery(theme.breakpoints.up("xl"));
  const validChartPlanData = item?.["График УД показателя, План"];
  const validChartFactData = item?.["График УД показателя, Факт"];
  const yearly = item?.["Годовой"];
  const hasIndicatorTypeSimple = props.type === INDICATORS_TYPE.SIMPLE;
  const isLastChunkStyle = chunkLength && chunkLength < (isExtraLarge ? 9 : 6);

  const getSources = useMemo(() => {
    return sources.FOIV ? "По мнению ФОИВ" : "По мнению МЭР";
  }, [sources]);

  const sourcesData = useMemo(() => {
    if (sources.OM) {
      return {
        plan: item?.["УД показателя ОМ МЭР, План"],
        fact: item?.["УД показателя ОМ МЭР, Факт"],
        performance: item?.["УД по данным ОМ МЭР"],
      };
    }
    return {
      plan: item?.["УД показателя, План"],
      fact: item?.["УД показателя, Факт"],
      performance: item?.["Уровень достижения"],
    };
  }, [sources]);

  return (
    <Wrapper
      isLastChunkStyle={isLastChunkStyle}
      sourcesDataPerformance={sourcesData.performance}
      isOM={sources.OM}
    >
      <div className="item-container">
        {hasIndicatorTypeSimple ? (
          <>
            <div className="progress-container">
              <div className="progress-bgr" />
              <div className="progress-bgr-border" />
              <div className="progress-title">
                <div className="small-text">Цель ГП</div>
                <TooltipText hint={item?.["Имя Цели"]} variant="h3" lines={3}>
                  <div className="main-text">{item?.["Имя Цели"]}</div>
                </TooltipText>
              </div>
              <div className="percent-container">
                <div className="info-icon">
                  <TooltipText hint={getSources} variant={"h3"}>
                    <InfoIcon />
                  </TooltipText>
                </div>

                <div className="percent">
                  <div className="percent-big-text">
                    {getPercentValue(sourcesData.performance)}
                  </div>
                  <div className="percent-big-text gray-blue">&nbsp;%</div>
                </div>
              </div>
            </div>
            <div className="indicator-container">
              <div className="indicator-header">
                <div className="indicator-title">
                  Показатель{" "}
                  {yearly ? <div className="indicator-label">годовой</div> : ""}
                </div>
                <div className="dots-line" />
              </div>
              <div className="carousel-container">
                <IndicatorInfo
                  hasColorText={sources.OM}
                  plan={sourcesData.plan}
                  fact={sourcesData.fact}
                  unit={item?.["Ед измерения показателя"]}
                  indicatorName={item?.["Имя показателя"]}
                  chartPlanData={validChartPlanData}
                  chartFactData={validChartFactData}
                />
              </div>
            </div>
          </>
        ) : (
          <ChartView
            plan={sourcesData.plan}
            fact={sourcesData.fact}
            unit={item?.["Ед измерения показателя"]}
            indicatorName={item?.["Имя показателя"]}
            chartPlanData={validChartPlanData}
            chartFactData={validChartFactData}
            yearly={yearly}
          />
        )}
      </div>
    </Wrapper>
  );
};

export default GoalItem;
