import { olapRequestData3 } from "CORE/api/core";
import { IPeriod } from "SRC/redux/slices/global/slices/filters";

const GET_TOP_BEST_GP_OM = "get-top-best-gp-om";

export const getTopBestGpOM = (period: IPeriod) => {
  return olapRequestData3(
    [
      {
        requestId: "26ea1ea5-b61a-487b-b9c2-a91553fb3dd6",
        type: "data2",
        params: {
          modelUuid: "c116bb29-cc0d-4f3a-a050-392dd7f41db8",
          dashboardUuid: "",
          includeGaps: false,
          dimensions: [
            {
              id: "1562",
              includeItems: true,
              includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
            },
            {
              id: "1565",
              includeItems: true,
              includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
            },
          ],
          indicators: {
            id: "1571",
            items: [
              {
                id: "5265",
                aggregationFunction: "sum",
              },
              {
                id: "5267",
                aggregationFunction: "sum",
              },
            ],
            limit: {
              id: "5267",
              order: "asc",
              type: "TOP",
              value: 5,
            },
          },
          dataRequestCaching: true,
          dataFilter: {
            type: "AND",
            version: 1,
            operands: [
              {
                type: "EQ",
                version: 1,
                operands: [
                  {
                    type: "DIM",
                    version: 1,
                    id: "1562",
                  },
                  {
                    type: "CONST",
                    version: 1,
                    values: [period.apiV2],
                  },
                ],
              },
              {
                type: "EQ",
                version: 1,
                operands: [
                  {
                    type: "DIM",
                    version: 2,
                    id: "1565",
                    attributeCode: "Секретная",
                  },
                  {
                    type: "TEXT",
                    version: 2,
                    values: ["0"],
                  },
                ],
              },
            ],
          },
        },
      },
    ],
    GET_TOP_BEST_GP_OM
  );
};
