import { SecretProgram } from "ASSETS/svg";
import React from "react";

interface IProps {
  isSecret?: boolean;
  children: React.ReactNode;
}

export const CheckSecretData = ({ isSecret, children }: IProps) => {
  return <>{isSecret ? <SecretProgram width={12} /> : children}</>;
};
