import { Button, styled } from "@mui/material";
import { selectorMixin } from "SRC/components/FilterForms/MainHeaderFilter/MainHeaderFilter.styled";

interface ITasksTabWrapper {
  isModalOpen?: boolean;
}
export const Wrapper = styled("div")<ITasksTabWrapper>(({ isModalOpen }) => {
  const marginModal = isModalOpen ? "1rem" : "0rem";
  return {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    overflowY: "hidden",
    overflowX: "visible",
    padding: "0 1rem",
    gap: "0.5rem",

    ".filters": {
      display: "flex",
      flexDirection: "column",
      gap: "1rem",
      marginTop: marginModal,

      "&-row": {
        display: "flex",
        gap: "1rem",
        justifyContent: "space-between",
      },
    },
  };
});

export const ResetFilterButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  background: theme.palette.complementary.darkGrayBlue,
  borderRadius: "0.5rem",
  fontSize: "1rem !important",
  fontWeight: "400",
  textTransform: "none",
  height: "2.5rem",
  padding: "0.6rem 1.125rem",
}));

export const css = {
  selector: selectorMixin,
  filtersContainer: (isModalOpen?: boolean) => ({
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    marginTop: isModalOpen ? "1rem" : "0rem",
  }),
  statusCardContainer: {
    display: "flex",
    height: "100%",
    gap: "1rem",
    flex: 1,
  },
};
