import moment from "moment";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { EDateType, ESources } from "SRC/constants/globals";
import slice, {
  IFiltersState,
  TSourcesState,
} from "SRC/redux/slices/global/slices/filters";

import { useAppSelector } from "../../../hooks";

export interface IGlobalFilters extends IFiltersState {
  clear(): void;
  setSelectedPeriod(period: null | Date, view?: EDateType): void;
  setSelectedDate(value: null | Date): void;
  toggleSource(source: ESources): void;
  toggleSecret(secret: boolean): void;
  toggleInAchievement(): void;
  toggleIsUnder(): void;
  resetInAchievement(): void;
  isSamePeriod(value: null | Date): boolean;
  calendarDate: Date | null;
}

export const useGlobalFilters = (): IGlobalFilters => {
  const dispatch = useDispatch();
  const filters = useAppSelector((state) => state.global.filters);
  const { sources, period } = filters;

  const isSamePeriod = useCallback(
    (value: null | Date = new Date()) => {
      return (
        moment(value).format("YYYY-MM") ===
        moment(period.value).format("YYYY-MM")
      );
    },
    [period.value]
  );

  const setSelectedPeriod = (
    value: null | Date = new Date(),
    view: EDateType = EDateType.YEAR
  ) => {
    const maxDate = moment(new Date()).endOf(EDateType.YEAR).toDate();
    let valueDate = moment(value).endOf(view).startOf("day").toDate();

    if (valueDate > maxDate) {
      valueDate = maxDate;
    }

    const details = {
      [EDateType.YEAR]: `${moment(valueDate).year()}`,
      [EDateType.QUARTER]: moment(valueDate).format("Q"),
      [EDateType.MONTH]: moment(valueDate).format("MM"),
    };

    const filterValue = `${details[EDateType.YEAR]}${
      view === EDateType.YEAR ? "00" : details[view]
    }`;

    const apiV2 = `${details[EDateType.YEAR]}${
      view === EDateType.YEAR ? "" : details[view]
    }`;

    let reportValue = details[EDateType.YEAR];
    if (view !== EDateType.YEAR) {
      reportValue = `${details[EDateType.MONTH]}-${reportValue}`;
    }
    const periodRes = {
      value: valueDate,
      filterValue,
      apiV2,
      year: details[EDateType.YEAR],
      reportValue,
      maxDate,
      calendarDate: period.value,
    };

    dispatch(slice.actions.selectPeriod(periodRes));
  };

  const setSelectedDate = (value: null | Date = new Date()) => {
    const currentDate = moment(value).startOf("day").toDate();
    const date = { value: currentDate };

    dispatch(slice.actions.selectDate(date));
  };

  const clear = useCallback(() => dispatch(slice.actions.clear()), [dispatch]);

  const toggleSource = useCallback(
    (value: ESources) => {
      dispatch(
        slice.actions.setSources(
          Object.keys(sources).reduce(
            (acc, key) => ({ ...acc, [key]: key === value }),
            {}
          ) as TSourcesState
        )
      );
    },
    [dispatch, sources]
  );

  const toggleInAchievement = useCallback(
    () => dispatch(slice.actions.setInAchievement(!filters.inPerformance)),
    [dispatch, filters.inPerformance]
  );

  const toggleIsUnder = useCallback(
    () => dispatch(slice.actions.setIsUnder(!filters.isUnder)),
    [dispatch, filters.isUnder]
  );

  const resetInAchievement = useCallback(
    () => dispatch(slice.actions.setInAchievement(false)),
    [dispatch, filters.inPerformance]
  );

  const toggleSecret = (activeSecret: boolean) =>
    dispatch(slice.actions.toggleSecret(activeSecret));

  return {
    ...filters,
    setSelectedPeriod,
    setSelectedDate,
    clear,
    toggleSource,
    toggleSecret,
    toggleIsUnder,
    toggleInAchievement,
    resetInAchievement,
    isSamePeriod,
    calendarDate: period.calendarDate,
  };
};
