import { SerializedError } from "@reduxjs/toolkit";
import { useCallback, useEffect } from "react";
import { useParams } from "react-router";
import { useCashExecution } from "SRC/redux/slices/event/hooks/useCashExecution";
import { useControlPoints } from "SRC/redux/slices/event/hooks/useControlPoints";
import { useEventData } from "SRC/redux/slices/event/hooks/useEventData";
import { useFilters } from "SRC/redux/slices/event/hooks/useFilters";
import { useListOfObjects } from "SRC/redux/slices/event/hooks/useListOfObjects";
import { useSubjects } from "SRC/redux/slices/event/hooks/useSubjects";
import {
  IGlobalFilters,
  useGlobalFilters,
} from "SRC/redux/slices/global/hooks/useFilters";

export interface IEvent {
  loading: boolean;
  loaded: boolean;
  init(): void;
  hasError: SerializedError | null;
}

export const useEvent = (): IEvent => {
  const { period }: IGlobalFilters = useGlobalFilters();
  const { selectedEventId } = useFilters();
  const { programId } = useParams();
  const {
    fetching: loadingCashExecution,
    load: loadCashExecution,
    fetched: loadedCashExecution,
    error: errorCashExecution,
  } = useCashExecution();
  const {
    fetching: loadingControlPoints,
    load: loadControlPoints,
    fetched: loadedControlPoints,
    error: errorControlPoints,
  } = useControlPoints();
  const {
    fetching: loadingSubjects,
    load: loadSubjects,
    fetched: loadedSubjects,
    error: errorSubjects,
  } = useSubjects();
  const {
    fetching: loadingEventData,
    load: loadEventData,
    fetched: loadedEventData,
    error: errorEventData,
  } = useEventData();
  const {
    fetching: loadingListOfObjects,
    load: loadListOfObjects,
    fetched: loadedListOfObjects,
    error: errorListOfObjects,
  } = useListOfObjects();

  const loading =
    loadingCashExecution ||
    loadingControlPoints ||
    loadingSubjects ||
    loadingEventData ||
    loadingListOfObjects;
  const loaded =
    loadedCashExecution &&
    loadedControlPoints &&
    loadedSubjects &&
    loadedEventData &&
    loadedListOfObjects;
  const hasError =
    errorCashExecution ||
    errorControlPoints ||
    errorSubjects ||
    errorEventData ||
    errorListOfObjects;

  const init = useCallback(() => {
    const params = {
      id: selectedEventId,
      period,
      gpCode: programId,
    };

    loadCashExecution(params);
    loadControlPoints(params);
    loadSubjects(params);
    loadEventData(params);
    loadListOfObjects(params);
  }, [
    loadCashExecution,
    loadControlPoints,
    loadSubjects,
    loadEventData,
    loadListOfObjects,
    period,
    selectedEventId,
  ]);

  useEffect(() => {
    if (selectedEventId) init();
  }, [selectedEventId, period]);

  return {
    loading,
    loaded,
    init,
    hasError,
  };
};
