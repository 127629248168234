import { styled } from "@mui/material";

export const Wrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginTop: "1.75rem",
  "& > .title": {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "2rem",
  },
  "& > .info": {
    display: "flex",
    flexDirection: "column",
    "& > .title-text span": {
      fontSize: "1.25rem",
      color: theme.palette.divider,
      verticalAlign: "top",
    },
    "& > .head": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      fontSize: "1.5rem",
      "& > .title-text span": {
        fontSize: "1.25rem",
        color: theme.palette.divider,
        verticalAlign: "top",
      },
      "& > .title-achievement": {
        display: "flex",
        flexDirection: "column",
        "& > .title-percent": {
          display: "flex",
          flexDirection: "row",
          "div:first-of-type": {
            marginRight: "2rem",
            paddingTop: "0.2rem",
            fontSize: "1.25rem",
          },
        },
      },
    },
    "& > .buttons-container": {
      flex: "0 0 auto",
      height: "2.5rem",
      width: "fit-content",
      padding: "0.25rem",
      margin: "0.5rem 0 1rem 0",
      background: theme.palette.complementary.darkBlue,
      borderRadius: "0.5rem",

      "& > button": {
        flex: "0 0 auto",
        height: "2rem",
        padding: "0.5rem 1rem 0.5rem 1rem !important",
        fontWeight: "normal",
        fontSize: "1rem",
        textTransform: "none",
        color: theme.palette.text.onAccent,
        borderRadius: "0.5rem",
      },
    },
  },
}));
