import { EExtensions } from "SRC/constants";
import { IExportData, IWorkbookData } from "SRC/types";
import * as XLSX from "xlsx";
import { BookType } from "xlsx";

interface IData extends IExportData<Record<string, unknown>> {}

const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

interface IPayload {
  ext: EExtensions;
  data: IData;
}

export const isFullReport = (
  data: IWorkbookData | IExportData<object>
): data is IWorkbookData => {
  return "file" in data;
};

export const getTableFile = ({ ext, data }: IPayload) => {
  const {
    csvData,
    headings,
    mergeCells,
    wscols,
    infoRows,
    fileName: name,
  } = data;

  const ws = XLSX.utils.json_to_sheet(headings, {
    skipHeader: true,
  });

  ws["!cols"] = wscols;
  ws["!merges"] = mergeCells;

  if (infoRows) {
    for (let i = 0; i < infoRows[0].length; i++) {
      ws[`B${i + 1}`] = infoRows[0][i];
      ws[`C${i + 1}`] = infoRows[1][i];
    }
  }

  XLSX.utils.sheet_add_json(ws, csvData, {
    skipHeader: true,
    origin: "A11",
  });
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const normalizeExtension = ext === EExtensions.ODS ? "ods" : "xlsx";
  const excelBuffer = XLSX.write(wb, {
    bookType: normalizeExtension as BookType,
    type: "array",
  });

  const blob = new Blob([excelBuffer], { type: fileType });

  return { blob, name, extension: ext };
};
