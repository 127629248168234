import { styled } from "@mui/material";

export const Wrapper = styled("div")(() => {
  return {
    cursor: "pointer",
    userSelect: "none",

    ":hover": {
      textDecoration: "underline",
    },
  };
});
