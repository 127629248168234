import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "SRC/redux/hooks";
import actions from "SRC/redux/slices/gosprogram/actions";
import { TStateDefault } from "SRC/types";

export interface IUseStructureElementsTasks extends TStateDefault {
  items: any;
  load(gp_code: string | number): void;
}

export const useStructureElementsTasks = (): IUseStructureElementsTasks => {
  const dispatch = useAppDispatch();
  const { items, fetching, fetched, error } = useAppSelector((state) => {
    return state.gosprogram.structureElementsTasks;
  });
  const load = useCallback(
    (gp_code: string | number) => {
      if (!fetching) {
        dispatch(actions.structureElementsTasks.get(gp_code));
      }
    },
    [dispatch, fetching]
  );

  return {
    items,
    fetching,
    fetched,
    error,
    load,
  };
};
