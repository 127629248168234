import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { isContainedArray } from "SRC/helpers/dataTesters";
import { getProgramEvents } from "SRC/redux/slices/gosprogram/api/client";
import { ICodeItem } from "SRC/types";

export interface IProgramEvent {
  NAME: string;
  R_ID: string;
  code: string;
  data: ICodeItem[];
  "Наименование проекта": string;
  "Наименование задачи": string;
  eventsCode: string[];
  Документ: string;
  Нацпроект: string;
  "Тип мероприятия": string;
  "Тип мероприятия, короткое название от АЦ": string;
  "Тип мероприятия, полное наименование": string;
  "Документ/НацПроект": string;
  "Ед. измерения бюджета": string;
  "Срок начала реализации": string;
  "Ед. измерения результата": string;
  "Срок окончания реализации": string;
  "Ответственный исполнитель": string;
  "Характеристика результата": string;
  "Участвует в расчёте УД, ФОИВ": number;
  "Участвует в расчёте УД, ОМ": number;
  "Тип расчета УД": string;
}

export interface IEventsState {
  items: IProgramEvent[];
  total: number;
  fetching: boolean;
  fetched: boolean;
  error: string;
}

export interface IParams {
  id: string | number;
  fp_code: string | null;
  dp_code: string | null;
  cpm_code: string | null;
  gp_code?: string;
  period_code?: string;
}

export const extraActions = {
  get: createAsyncThunk(
    "events/get",
    async (params: IParams) => await getProgramEvents(params)
  ),
};

const initialState: IEventsState = {
  items: [],
  total: 0,
  fetching: false,
  fetched: false,
  error: "",
};

const slice = createSlice({
  name: "events",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActions.get.pending, (state) => {
        state.items = [];
        state.fetching = true;
        state.fetched = false;
      })
      .addCase(extraActions.get.fulfilled, (state: IEventsState, action) => {
        state.total = action.payload.dataTotal || 0;
        state.items = (
          isContainedArray(action.payload.data) ? action.payload.data : []
        ) as IProgramEvent[];
        state.fetching = false;
        state.fetched = true;
      })
      .addCase(extraActions.get.rejected, (state, action) => {
        state.fetching = false;
      });
  },
});

export const actions = {
  ...slice.actions,
  ...extraActions,
};

export default slice;
