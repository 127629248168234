import { Box, SxProps, Tooltip, Typography } from "@mui/material";
import React, { useMemo } from "react";
import {
  ESelectedType,
  IDataGoal,
  TCheckForActiveFn,
  TOnSelectFn,
} from "SRC/pages/Program/common/GoalsTab/common/NationalGoalsConnect/NationalGoalsConnect";

import { css } from "./GoalItem.styled";

interface IGoalItemProps {
  item: IDataGoal;
  checkActive: TCheckForActiveFn;
  onClick: TOnSelectFn;
}

export const GoalItem = ({ item, checkActive, onClick }: IGoalItemProps) => {
  const programCount = useMemo(
    () =>
      item.factors.reduce(
        (acc, { programId }) => acc + (programId.length || 0),
        0
      ),
    [item]
  );

  const activeGoal: boolean = !!checkActive(ESelectedType.GOAL, item.id);

  const onFactorClick = (callback: () => void) => (): void => {
    if (!activeGoal) return;
    callback();
  };

  return (
    <Box sx={css.item} id={`goalItem-${item.id}`}>
      <Box sx={css.programCounter(activeGoal)}>
        <Box sx={css.counter(activeGoal)}>{programCount}</Box>
      </Box>
      <Box sx={css.factorContainer(activeGoal)} className={"factorContainer"}>
        <Box sx={css.borderFactor(activeGoal)}>
          <Box sx={css.borderRound} />
        </Box>
        <Box sx={css.factorsList}>
          {item.factors.map((factor, i) => (
            <Tooltip
              key={i}
              componentsProps={{
                tooltip: {
                  sx: css.hintContainer as SxProps,
                },
              }}
              title={
                <Typography sx={css.hintText as SxProps}>
                  {Object.values(factor.name)}
                </Typography>
              }
            >
              <Box
                key={factor.id}
                sx={css.factor(!!checkActive(ESelectedType.FACTOR, factor.id))}
                onClick={onFactorClick(
                  onClick(ESelectedType.FACTOR, factor.id)
                )}
              >
                <Box sx={css.factorPoint}>{factor.point}</Box>
                <Box sx={css.factorName}>{factor.name}</Box>
              </Box>
            </Tooltip>
          ))}
        </Box>
      </Box>
      <Box
        sx={css.itemContainer}
        onClick={onClick(ESelectedType.GOAL, item.id)}
      >
        <Box sx={css.rightImg}>
          <img src={item.icon} alt="icon" />
        </Box>
        <Box sx={css.rightName(activeGoal)}>{item.goalTitle}</Box>
      </Box>
    </Box>
  );
};
