import { SerializedError } from "@reduxjs/toolkit";
import { useCallback, useState } from "react";
import { IWorstProgram } from "SRC/pages/Summary/interfaces";
import { IPeriod } from "SRC/redux/slices/global/slices/filters";

import { useAppDispatch, useAppSelector } from "../../../hooks";
import actions from "../actions";

export interface IProgramsTopWorst {
  fetching: boolean;
  fetched: boolean;
  items: IWorstProgram[];
  load(period: IPeriod): void;
  error: SerializedError | null;
}

export const useTopWorstGP = (): IProgramsTopWorst => {
  const dispatch = useAppDispatch();
  const {
    fetching,
    items: programsTopWorst = [],
    fetched,
  } = useAppSelector((state) => state.summary.topWorstGP);
  const [error, setError] = useState<SerializedError | null>(null);

  const load = useCallback(
    (period: IPeriod) => {
      if (!fetching) {
        dispatch(actions.topWorstGp.get(period)).then((action) => {
          if (actions.topWorstGp.get.rejected.match(action)) {
            setError(action.error);
          }
          return action;
        });
      }
    },
    [dispatch, fetching]
  );

  return {
    fetching,
    fetched,
    items: [...programsTopWorst].sort((a, b) => Number(b.sum) - Number(a.sum)),
    load,
    error,
  };
};
