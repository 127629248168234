import React from "react";
import { TableProps, UseExpandedRowProps, UseTableRowProps } from "react-table";
import NavigateToPriorities from "SRC/components/NavigateToPriorities/NavigateToPriorities";
import NavigateToProgram from "SRC/components/NavigateToProgram/NavigateToProgram";
import { TableCell } from "SRC/components/TableCell";
import { getPercentValue } from "SRC/helpers/getPercentValue";
import { IPremierTableItem } from "SRC/hooks/summaryTable/interfaces";

type CustomRowProps = UseTableRowProps<any> & UseExpandedRowProps<any>;

interface CustomTableProps extends TableProps {
  row: CustomRowProps;
  rows?: UseTableRowProps<IPremierTableItem>[];
}

const getGovProgramKPColumns = (isOM: boolean) => [
  {
    Header: "",
    accessor: "id",
    columns: [
      {
        Header: "Код ГП",
        width: 90,
        accessor: "id",
        Cell: (tableProps: CustomTableProps) => (
          <TableCell color="gray">{tableProps.row.original.id}</TableCell>
        ),
      },
    ],
    Footer: "",
  },
  {
    Header: "",
    accessor: "govProgram",
    columns: [
      {
        Header: "Государственная программа",
        width: "auto",
        accessor: "govProgram",
        Cell: (tableProps: CustomTableProps) => (
          <TableCell color="gray">
            <NavigateToProgram program={tableProps.row.original} />
          </TableCell>
        ),
      },
    ],
    Footer: "",
  },
  {
    Header: "",
    accessor: "vicePremier",
    columns: [
      {
        Header: "Вице-премьер",
        width: 230,
        accessor: "vicePremier",
        Cell: (tableProps: CustomTableProps) => (
          <TableCell color="gray">
            {tableProps.row.original.vicePremier}
          </TableCell>
        ),
        Footer: "",
      },
    ],
    Footer: "",
  },
  {
    Header: "",
    accessor: "curator",
    columns: [
      {
        Header: "Ответственный исполнитель",
        width: 230,
        accessor: "curator",
        Cell: (tableProps: CustomTableProps) => (
          <TableCell color="gray">
            {tableProps.row.original.vicePremier}
          </TableCell>
        ),
        Footer: "",
      },
    ],
    Footer: "",
  },
  {
    Header: "",
    accessor: "gpLevel",
    columns: [
      {
        Header: "Уровень достижения ГП, %",
        width: 180,
        accessor: "gpLevel",
        Cell: (tableProps: CustomTableProps) => {
          const gpLevel = Number(tableProps.row.original.gpLevel) || 0;
          const color = gpLevel === 100 ? "green" : "white";

          return (
            <TableCell color={color}>{getPercentValue(gpLevel)}</TableCell>
          );
        },
        Footer: "",
      },
    ],
    Footer: "",
  },
  {
    Header: "",
    accessor: "kpLevel",
    columns: [
      {
        Header: "Уровень достижения ГП, %",
        width: 180,
        accessor: "kpLevel",
        Cell: (tableProps: CustomTableProps) => {
          const kpLevel = Number(tableProps.row.original.kpLevel) || 0;
          const color = kpLevel === 100 ? "green" : "white";

          return (
            <TableCell color={color}>{getPercentValue(kpLevel)}</TableCell>
          );
        },
      },
    ],
    Footer: "",
  },
  {
    Header: "Ключевые приоритеты",
    accessor: "keyPriorities",
    columns: [
      {
        Header: "Всего",
        width: 100,
        accessor: "kpPlan",
        Cell: (tableProps: CustomTableProps) => (
          <TableCell>
            <NavigateToPriorities>
              {tableProps.row.original.kpPlan}
            </NavigateToPriorities>
          </TableCell>
        ),
      },
      {
        Header: "Достигнуто",
        width: 100,
        accessor: "kpFact",
        Cell: (tableProps: CustomTableProps) => (
          <TableCell color="green">{tableProps.row.original.kpFact}</TableCell>
        ),
      },
      {
        Header: "Не достигнуто",
        width: 100,
        accessor: "kpFail",
        Cell: (tableProps: CustomTableProps) => (
          <TableCell color="red">{tableProps.row.original.kpFail}</TableCell>
        ),
      },
    ],
    Footer: "",
  },
];

export { getGovProgramKPColumns };
