import { Theme } from "@mui/material";

const contained = {
  maxHeight: "100%",
  minHeight: 0,
  flex: 1,
};

export const css = {
  container: (atPage?: boolean) => (theme: Theme) => ({
    padding: "0 0.5rem",
    ...(atPage ? contained : {}),

    [theme.breakpoints.up("lg")]: {
      padding: "0",
    },
    [theme.breakpoints.up("xl")]: {
      maxHeight: "100%",
      height: "100%",
      overflow: "hidden",
    },
  }),
  center: (theme: Theme) => ({
    flex: 1,
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("lg")]: {
      paddingTop: "2rem",
    },
    [theme.breakpoints.up("xl")]: {
      padding: "0rem",
      paddingTop: "6rem",
      height: "100%",
    },
  }),
  centerContentWrapper: (theme: Theme) => ({
    [theme.breakpoints.up("lg")]: {
      display: "flex",
      flexDirection: "column",
    },
    [theme.breakpoints.up("xl")]: {
      flexDirection: "row",
      "& .program-card": {
        flex: "0 0 auto",
      },
      "& .program-state": {
        flex: "0 1 100%",
        paddingLeft: "4rem",
        width: "100%",
      },
    },
  }),
  tabs: {
    maxWidth: (theme: Theme) => theme.breakpoints.values.md,
    margin: "auto",
    width: "100%",
  },
  tabSX: (theme: Theme) => ({
    flex: "0 0 auto",
    maxWidth: "fit-content",
    color: theme.palette.text.secondary,
    fontSize: theme.typography.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    "&.Mui-selected": {
      color: theme.palette.text.onAccent,
    },
  }),
  right: (isProgramLayout?: boolean) => (theme: Theme) => ({
    padding: "0 2.5rem",
    flex: 1,
    [theme.breakpoints.up("xl")]: {
      padding: isProgramLayout ? "0 4rem 0 6rem" : "2rem 4rem 0 6rem",
    },
  }),
  left: (theme: Theme) => ({
    padding: "0 2.5rem",
    flex: 1,
    [theme.breakpoints.up("lg")]: {
      order: -1,
      height: "100%",
    },
    [theme.breakpoints.up("xl")]: { padding: "2rem 6rem 0 4rem" },
  }),
  gridWrapper: (theme: Theme) => ({
    maxWidth: theme.breakpoints.values.md,
    margin: "auto",
    [theme.breakpoints.up("lg")]: {
      height: "100%",
    },
  }),
};
