import { useMemo } from "react";
import { getInfoRowsSEEvents } from "SRC/helpers/getInfoRows";
import { useTableExportParams } from "SRC/hooks";
import { selectors, useAppSelector } from "SRC/redux/hooks";
import { IEvent } from "SRC/redux/slices/structureElement/api";
import { useEvents } from "SRC/redux/slices/structureElement/hooks";
import { IEvents } from "SRC/redux/slices/structureElement/hooks/useEvents";
import { IExportData } from "SRC/types";

export interface ITableDataItem {
  name?: string;
  id: number | string;
}

export interface IEventTableItem extends ITableDataItem {
  unit?: string;
  performance?: number;
  cpPlan?: number;
  cpFact?: number;
  valuePlan?: number;
  valueFact?: number;
  datePlan?: string;
  dateFact?: string;
}

export type TEventExportItem = Omit<IEventTableItem, "id">;

export const createEventRow = ({ info, data }: IEvent): IEventTableItem => ({
  id: info.code,
  name: info.name,

  unit: info.type,
  performance: data[1359]?.sum || 0,
  cpPlan: data[5468]?.sum || 0,
  cpFact: data[5469]?.sum || 0,
  valuePlan: data[1352]?.sum || 0,
  valueFact: data[1358]?.sum || 0,
  datePlan: (data[1366]?.sum || "") as string,
  dateFact: (data[1367]?.sum || "") as string,
});

export const createEventsTableData = (data: IEvent[]): IEventTableItem[] =>
  data.map((elem: IEvent) => createEventRow(elem));

export interface IUseExtendedEvents extends IEvents {
  filtered: string[];
  rows: IEventTableItem[];
  exports: IExportData<TEventExportItem>;
}

export const useExtendedEvents = (id: string | number): IUseExtendedEvents => {
  const createExportParams = useTableExportParams;
  const events = useEvents(id);
  const filters = useAppSelector(selectors.seeFilters);

  const tableData = useMemo(() => createEventsTableData(events.data), [events]);

  const filtered = useMemo(
    () =>
      events.data
        .filter((item) => {
          if (
            filters.selectedStructureElementType &&
            filters.selectedStructureElementType !== item.info.type
          )
            return false;
          return true;
        })
        .map((item) => item.info.code),
    [events, filters]
  );

  const rows = useMemo(
    () =>
      tableData
        .filter(({ id }) => filtered.includes(String(id)))
        .sort((a, b) => Number(a.id) - Number(b.id)),
    [tableData, filtered]
  );

  const exports = useMemo(() => {
    const download: TEventExportItem[] = rows.map(({ id, ...item }) => item);

    return {
      csvData: download,
      fileName: "Структурный элемент. Мероприятия",
      infoRows: getInfoRowsSEEvents(),
      ...createExportParams(download, "seEvents"),
    };
  }, [rows]);

  return {
    ...events,
    filtered,
    rows,
    exports,
  };
};
