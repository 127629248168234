import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { getAuthorized, IUser } from "../api/client";
import { getUserGroups } from "../api/get-user-groups";

export interface IUserState {
  user?: IUser;
  groups: Set<string>;
  fetching: boolean;
  fetched: boolean;
  groupsFetching: boolean;
  groupsFetched: boolean;
  error: string | undefined;
}

export const initialState: IUserState = {
  user: undefined,
  groups: new Set(),
  fetching: false,
  fetched: false,
  groupsFetching: false,
  groupsFetched: false,
  error: undefined,
};

export const extraActions = {
  getAuthorized: createAsyncThunk(
    "auth/user/authorized",
    async () => await getAuthorized()
  ),
  getUserGroups: createAsyncThunk(
    "auth/user/groups",
    async () => await getUserGroups()
  ),
};

const slice = createSlice({
  name: "auth/user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActions.getAuthorized.pending, (state) => {
        state.fetching = true;
        state.fetched = false;
        state.error = undefined;
      })
      .addCase(extraActions.getAuthorized.fulfilled, (state, action) => {
        state.user = action.payload.data;
        state.fetching = false;
        state.fetched = true;
      })
      .addCase(extraActions.getAuthorized.rejected, (state, action) => {
        state.fetching = false;
        state.fetched = true;
        state.error = action.error.message;

        localStorage.removeItem("tokenInfo");
      })
      .addCase(extraActions.getUserGroups.pending, (state) => {
        state.groupsFetching = true;
        state.groupsFetched = false;
        state.error = undefined;
      })
      .addCase(extraActions.getUserGroups.fulfilled, (state, action) => {
        state.groupsFetching = false;
        state.groupsFetched = true;
        state.groups = action.payload;
      })
      .addCase(extraActions.getUserGroups.rejected, (state, action) => {
        state.groupsFetching = false;
        state.groupsFetched = true;
        state.error = action.error.message;
      });
  },
});

export const actions = {
  ...slice.actions,
  ...extraActions,
};
export const reducer = slice.reducer;

export default slice;
