import { styled } from "@mui/material";

export const Wrapper = styled("div")(() => ({
  width: "100%",
  margin: "0 1.3rem",
  marginBottom: "1rem",

  label: {
    color: "#ffffff",
  },

  "& .MuiInputBase-adornedEnd": {
    padding: "1rem",
  },
}));
