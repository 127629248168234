import { styled, Theme } from "@mui/material";

export const Wrapper = styled("div")(() => {
  return {
    width: "100%",
  };
});

export const css = {
  container: {
    gap: "1rem",
    flex: 1,
    padding: "0 1rem 1rem",
  },

  progress: {
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
    flex: 1,
    fontSize: "1rem",
  },
  head: {
    display: "flex",
    justifyContent: "space-between",
  },
  title: {
    fontSize: "1.25em",
  },
  legend: {
    display: "flex",
    gap: "1.5rem",
    color: "main.gray",
    fontSize: "1rem",
  },
  legendLi: {
    display: "flex",
    alignItems: "center",
    gap: "0.5rem",
  },
  dot: (color?: string) => ({
    display: color ? "flex" : "none",
    alignItems: "center",
    justifyContent: "center",
    width: "1rem",
    height: "1rem",
    border: `1px solid ${color}`,
    borderRadius: "50%",
    color,
    fontSize: "0.7rem",
    lineHeight: "1em",
  }),
  values: {
    display: "flex",
    justifyContent: "space-between",
  },
  value: {
    fontSize: "1.5em",
  },
  progressBox: (color?: string) => ({
    height: "1.25rem",
    flex: "0 1 auto",
    borderRadius: "4px",
    overflow: "hidden",
    background: (theme: Theme) =>
      `repeating-linear-gradient(-60deg, ${
        color || theme.palette.main.gray
      } 0, ${
        color || theme.palette.main.gray
      } 1px, transparent 1px, transparent 3px)`,
  }),
  progressLine: (color?: string) => ({
    flex: 1,
    height: "100%",
    backgroundColor: color || "main.gray",
  }),
  descriptions: {
    display: "flex",
    justifyContent: "space-between",
    color: "main.gray",
  },
  name: {
    fontSize: "1rem",
  },
};
