import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IPeriod } from "SRC/redux/slices/global/slices/filters";
import { getTabImplLvl } from "SRC/redux/slices/gosprogram/api/client";
import { IWorkbookData } from "SRC/types";

export interface IParams {
  period: IPeriod;
  tabName: string;
  gpCode: string;
  isOm: boolean;
}

export interface IWorkbookState {
  items: IWorkbookData | null;
  fetching: boolean;
  fetched: boolean;
  error: string;
}

export const extraActions = {
  get: createAsyncThunk(
    "workbook/get",
    async ({ period, gpCode, tabName, isOm }: IParams) =>
      await getTabImplLvl(gpCode, tabName, period.filterValue, isOm)
  ),
};

const initialState: IWorkbookState = {
  items: null,
  fetching: false,
  fetched: false,
  error: "",
};

export const slice = createSlice({
  name: "workbook",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActions.get.pending, (state) => {
        state.items = null;
        state.fetching = true;
        state.fetched = false;
      })
      .addCase(extraActions.get.fulfilled, (state: IWorkbookState, action) => {
        // @ts-ignore
        state.items = action.payload.data.data?.[0]
          ?.data as IWorkbookData | null;
        state.fetching = false;
        state.fetched = true;
      })
      .addCase(extraActions.get.rejected, (state, action) => {
        state.fetching = false;
        // state.error = action.error;
      });
  },
});

export const actions = {
  ...slice.actions,
  ...extraActions,
};

export default slice;
