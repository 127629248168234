import { olapDimensionRequestData, olapRequestData2 } from "CORE/api/core";
import {
  MINISTRY_MODEL_UUID,
  MODEL_UUID,
  PREMIERS_MODEL_UUID,
  PROGRAMS_MODEL_UUID,
  SECTION_NAMES,
} from "CORE/scheme/olap";
import { getGpIndicators, getVpIndicators } from "SRC/constants/dataCodes";
import { createFiltersForText } from "SRC/helpers/apiHelpers";
import { IPeriod } from "SRC/redux/slices/global/slices/filters";
import { IStatParams } from "SRC/redux/slices/main/slices/stats";

const GET_STATS = "get-stats";
const GET_GOALS = "get-goals";
const GET_PREMIERS = "get-premiers";
const GET_MINISTRIES = "get-ministries-2";
const GET_SPHERES = "get-spheres";
const GET_SUM_SPHERES = "get-sum-spheres";

export const getStats = (params: IStatParams) => {
  return olapRequestData2(
    {
      dimensions: [],
      indicators: {},
      section_name: SECTION_NAMES.STATS,
      modelUuid: MODEL_UUID,
      filters: {
        period_code: params.period.filterValue,
        gp_code: params.gp_code,
        vp_code: params.vp_code,
        sphere: params.sphere,
        foiv: params.foiv,
      },
    },
    GET_STATS
  );
};

export const getGoals = (period: IPeriod) => {
  return olapRequestData2(
    {
      dimensions: [],
      indicators: {},
      section_name: SECTION_NAMES.GOALS,
      modelUuid: MODEL_UUID,
      filters: {
        period_code: period.filterValue,
      },
    },
    GET_GOALS
  );
};

export const getPremiers = (period: IPeriod) => {
  return olapDimensionRequestData(
    [
      {
        requestId: "46c4caf5-da1a-48ba-898b-79a6d6196bd6",
        type: "data2",
        params: {
          modelUuid: PREMIERS_MODEL_UUID,
          dashboardUuid: "",
          includeGaps: false,
          dimensions: [
            {
              id: "1683",
              includeItems: true,
              includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
            },
            {
              id: "1686",
              includeItems: true,
              includeAttributesByCodes: [
                "NAME",
                "SHORT_NAME",
                "SORT_ORDER",
                "VP_PHOTO_URL",
                "VP_NAME",
              ],
            },
          ],
          indicators: {
            id: "1692",
            items: getVpIndicators(
              1742,
              1743,
              1744,
              1747,
              1748,
              1749,
              1767,
              1768,
              1769,
              1797,
              1699,
              1700,
              5248,
              5266,
              5392,
              5391,
              5394,
              5393,
              5451,
              5475,
              5455,
              5456,
              5461,
              1708,
              1709,
              1702,
              1703,
              5272,
              5462,
              1706,
              1705,
              5488
              //5393 todo: ломает запрос
            ),
          },
          dataRequestCaching: true,
          dataSort: [
            {
              dim_id: "1683",
              sortField: "name",
              sortOrder: "asc",
            },
            {
              dim_id: "1686",
              sortField: "name",
              sortOrder: "asc",
            },
          ],
          dataFilter: {
            type: "AND",
            version: 1,
            operands: [
              {
                type: "STARTER",
                version: 1,
                dim_id: "1686",
              },
              {
                type: "EQ",
                version: 1,
                operands: [
                  {
                    type: "DIM",
                    version: 1,
                    id: "1683",
                  },
                  {
                    type: "CONST",
                    version: 1,
                    values: [period.apiV2],
                  },
                ],
              },
            ],
          },
        },
      },
    ],
    GET_PREMIERS
  );
};

// Министерства
export const getMinistries = (period: IPeriod) => {
  return olapDimensionRequestData(
    [
      {
        requestId: "44306cc1-9221-4740-a6cb-c8a92b413f45",
        type: "data2",
        params: {
          modelUuid: MINISTRY_MODEL_UUID,
          dashboardUuid: "",
          includeGaps: false,
          dimensions: [
            {
              id: "1770",
              includeItems: true,
              includeAttributesByCodes: [
                "NAME",
                "SHORT_NAME",
                "SORT_ORDER",
                "GERB_ICON",
              ],
            },
            {
              id: "1774",
              includeItems: true,
              includeAttributesByCodes: [
                "NAME",
                "SHORT_NAME",
                "SORT_ORDER",
                "DatePart",
                "PeriodStart",
                "PeriodEnd",
                "PERIOD2",
              ],
            },
          ],
          indicators: {
            id: "1781",
            items: [
              {
                id: "1782",
                aggregationFunction: "sum",
              },
              {
                id: "1783",
                aggregationFunction: "sum",
              },
              {
                id: "1784",
                aggregationFunction: "sum",
              },
              {
                id: "1785",
                aggregationFunction: "sum",
              },
              {
                id: "1786",
                aggregationFunction: "sum",
              },
              {
                id: "1787",
                aggregationFunction: "sum",
              },
              {
                id: "1788",
                aggregationFunction: "sum",
              },
              {
                id: "1845",
                aggregationFunction: "sum",
              },
              {
                id: "5268",
                aggregationFunction: "sum",
              },
              {
                id: "5387",
                aggregationFunction: "sum",
              },
              {
                id: "5388",
                aggregationFunction: "sum",
              },
              {
                id: "5389",
                aggregationFunction: "sum",
              },
              {
                id: "5390",
                aggregationFunction: "sum",
              },
              {
                id: "5404",
                aggregationFunction: "sum",
              },
              {
                id: "5405",
                aggregationFunction: "sum",
              },
              {
                id: "5413",
                aggregationFunction: "sum",
              },
              {
                id: "5435",
                aggregationFunction: "sum",
              },
              {
                id: "5423",
                aggregationFunction: "sum",
              },
              {
                id: "5424",
                aggregationFunction: "sum",
              },
              {
                id: "5425",
                aggregationFunction: "sum",
              },
              {
                id: "5426",
                aggregationFunction: "sum",
              },
              {
                id: "5427",
                aggregationFunction: "sum",
              },
              {
                id: "5437",
                aggregationFunction: "sum",
              },
              {
                id: "5438",
                aggregationFunction: "sum",
              },
              {
                id: "5275",
                aggregationFunction: "sum",
              },
              {
                id: "5273",
                aggregationFunction: "sum",
              },
              {
                id: "5434",
                aggregationFunction: "sum",
              },
              {
                id: "5489",
                aggregationFunction: "sum",
              },
              {
                id: "5598",
                aggregationFunction: "sum",
              },
            ],
          },
          dataRequestCaching: true,
          dataSort: [
            {
              dim_id: "1770",
              sortField: "name",
              sortOrder: "asc",
            },
            {
              dim_id: "1774",
              sortField: "name",
              sortOrder: "asc",
            },
          ],
          dataFilter: {
            type: "AND",
            version: 1,
            operands: [
              {
                type: "STARTER",
                version: 1,
                dim_id: "1770",
              },
              {
                type: "EQ",
                version: 1,
                operands: [
                  {
                    type: "DIM",
                    version: 1,
                    id: "1774",
                  },
                  {
                    type: "CONST",
                    version: 1,
                    values: [period.apiV2],
                  },
                ],
              },
            ],
          },
        },
      },
    ],
    GET_MINISTRIES
  );
};

export const getSpheres = (params: IStatParams) => {
  return olapDimensionRequestData(
    [
      {
        requestId: "dbd3e35e-b26a-4ed3-8082-b802f8a91450",
        type: "data2",
        params: {
          modelUuid: PROGRAMS_MODEL_UUID,
          dashboardUuid: "",
          includeGaps: false,
          dimensions: [
            {
              id: "1562",
              includeItems: true,
              includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
            },
            {
              id: "1835",
              includeItems: true,
              includeAttributesByCodes: [
                "Сфера",
                "Министерство",
                "Вице-премьер",
              ],
            },
          ],
          indicators: {
            id: "1571",
            items: [
              {
                id: "1753",
                aggregationFunction: "sum",
              },
              {
                id: "1796",
                aggregationFunction: "sum",
              },
              {
                id: "1837",
                aggregationFunction: "sum",
              },
              {
                id: "5265",
                aggregationFunction: "sum",
              },
              {
                id: "5491",
                aggregationFunction: "sum",
              },
            ],
          },
          dataRequestCaching: true,
          dataSort: [
            {
              dim_id: "1562",
              sortField: "name",
              sortOrder: "asc",
            },
            {
              dim_id: "1835",
              sortField: "name",
              sortOrder: "asc",
            },
          ],
          dataFilter: {
            type: "AND",
            version: 1,
            operands: [
              {
                type: "STARTER",
                version: 1,
                dim_id: "1835",
              },
              {
                type: "EQ",
                version: 1,
                operands: [
                  {
                    type: "DIM",
                    version: 1,
                    id: "1562",
                  },
                  {
                    type: "CONST",
                    version: 1,
                    values: [params.period.apiV2],
                  },
                ],
              },
              ...createFiltersForText([
                { id: "1565", code: "Министерство", value: params.foiv },
                {
                  id: "1565",
                  code: "Вице-премьер, код",
                  value: params.vp_code,
                },
              ]),
            ],
          },
        },
      },
    ],
    GET_SPHERES
  );
};

export const getSumSpheres = (params: IStatParams) => {
  return olapDimensionRequestData(
    [
      {
        requestId: "7d93addc-4f47-4340-8695-08c07c1f2a74",
        type: "data2",
        params: {
          modelUuid: PROGRAMS_MODEL_UUID,
          dashboardUuid: "",
          includeGaps: false,
          dimensions: [
            {
              id: "1562",
              includeItems: true,
              includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
            },
            {
              id: "1835",
              includeItems: true,
              includeAttributesByCodes: [
                "Сфера",
                "Министерство",
                "Вице-премьер",
              ],
            },
          ],
          indicators: {
            id: "1571",
            items: getGpIndicators(1753, 1796, 1837, 1843, 1844),
          },
          dataRequestCaching: true,
          dataFilter: {
            type: "AND",
            version: 1,
            operands: [
              {
                type: "EQ",
                version: 1,
                operands: [
                  {
                    type: "DIM",
                    version: 1,
                    id: "1562",
                  },
                  {
                    type: "CONST",
                    version: 1,
                    values: [params.period.apiV2],
                  },
                ],
              },
              ...createFiltersForText([
                { id: "1565", code: "Секретная", value: "0" },
                { id: "1565", code: "Министерство", value: params.foiv },
                {
                  id: "1565",
                  code: "Вице-премьер, код",
                  value: params.vp_code,
                },
              ]),
            ],
          },
        },
      },
    ],
    GET_SUM_SPHERES
  );
};
