import { styled } from "@mui/material";

export const FinancingWrapper = styled("div")(({ theme }) => {
  const {
    complementary: { lightWhite },
    primary: { contrastText },
  } = theme.palette;

  return {
    gap: "3rem",
    display: "flex",
    flexDirection: "column",

    "&.is-secret": {
      alignItems: "center",
      justifyContent: "center",
    },

    "& .item": {
      width: "100%",
      gap: "1.5rem",
      display: "flex",
      flexDirection: "column",

      "& .title": {
        gap: "0.5rem",
        display: "flex",
        alignItems: "center",
        fontSize: "1.25rem",
        fontWeight: 400,
        color: contrastText,

        "& .unit-title": {
          fontSize: "0.875rem",
          color: lightWhite,
        },
      },
    },
  };
});
