import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { TableState } from "react-table";
import slice, {
  IProgramFiltersState,
} from "SRC/redux/slices/gosprogram/slices/filters";

import { useAppSelector } from "../../../hooks";

export interface IFilters extends IProgramFiltersState {
  clear(): void;
  setFiltersForGP(id: number | string): void;
  setSelectedStatus(status: string): void;
  setSelectedStructureId(id: string | number): void;
  setSelectedStructureRow(id: string | number): void;
  setSelectedStructureCodes(codes: string[]): void;
  setSelectedEventFeature(feature: string): void;
  setSelectedEventTypeName(eventTypeName: string): void;
  setSelectedStructureElementType(type: string): void;
  setSelectedStructureElementName(name: string): void;
  setSelectedStructureElementTask(task: string): void;
  setTableState(state: Partial<TableState> | undefined): void;
  setSearchRows(rows: Record<string, any>[]): void;
  clearSelectedStatus(): void;
}

export const useFilters = (): IFilters => {
  const dispatch = useDispatch();
  const filters = useAppSelector((state) => state.gosprogram.filters);

  const clear = useCallback(() => dispatch(slice.actions.clear()), [dispatch]);

  const setFiltersForGP = useCallback(
    (id: number | string) => {
      if (filters.filtersForGP !== id) clear();
      return dispatch(slice.actions.setFiltersForGP(id));
    },
    [dispatch, clear, filters]
  );

  const setSelectedStatus = useCallback(
    (status: string) => {
      return dispatch(slice.actions.selectStatus(status));
    },
    [dispatch]
  );

  const clearSelectedStatus = useCallback(() => {
    return dispatch(slice.actions.clearStatus());
  }, [dispatch]);

  const setSelectedStructureRow = (id: string) =>
    dispatch(slice.actions.selectStructureRow(id));

  const setSelectedStructureId = (id: string) =>
    dispatch(slice.actions.selectStructureId(id));

  const setSelectedStructureCodes = (codes: string[]) =>
    dispatch(slice.actions.selectStructureCodes(codes));

  const setSelectedEventFeature = (feature: string) =>
    dispatch(slice.actions.selectEventFeature(feature));

  const setSelectedEventTypeName = (eventTypeName: string) =>
    dispatch(slice.actions.selectEventTypeName(eventTypeName));

  const setSelectedStructureElementType = (type: string) =>
    dispatch(slice.actions.selectStructureElementType(type));

  const setSelectedStructureElementName = (name: string) =>
    dispatch(slice.actions.selectStructureElementName(name));

  const setSelectedStructureElementTask = (task: string) =>
    dispatch(slice.actions.selectStructureElementTask(task));

  const setTableState = (state: Partial<TableState> | undefined) =>
    dispatch(slice.actions.setTableInstance(state));

  const setSearchRows = (rows: Record<string, any>[]) => {
    dispatch(slice.actions.selectSearchRows(rows));
  };

  return {
    ...filters,
    setFiltersForGP,
    setSelectedStatus,
    setSelectedStructureRow,
    setSelectedStructureId,
    setSelectedStructureCodes,
    setSelectedEventFeature,
    setSelectedEventTypeName,
    setSelectedStructureElementType,
    setSelectedStructureElementName,
    setSelectedStructureElementTask,
    setTableState,
    setSearchRows,
    clear,
    clearSelectedStatus,
  };
};
