import { ICustomTableProps } from "SRC/types";

export const getIndicatorsSEFactTotal = (
  tableProps: ICustomTableProps,
  isOM: boolean
) => {
  // @ts-ignore
  let { indicatorsSEFactTotal, indicatorsSEOMFactTotal } =
    tableProps?.rows?.[0]?.original || {};

  return {
    indicatorsSEFactTotal: isOM
      ? indicatorsSEOMFactTotal
      : indicatorsSEFactTotal,
  };
};
