import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";
import { SlimProgressBar } from "SRC/components/ProgressBar";
import { WIPTag } from "SRC/components/WIP/WIP";

import { css } from "./LineProgressEvents.styled";

interface ILineProgressProps {
  nameValue: string;
  unit: string;
  value: number;
  percentage: number;
  total: number;
}

export const LineProgressEvents = ({
  nameValue,
  unit,
  value,
  percentage,
  total,
}: ILineProgressProps) => {
  const theme = useTheme();
  return (
    <Box sx={css.container}>
      <Box sx={css.nameContainer}>
        <Box sx={css.name}>
          {nameValue}
          {nameValue === "НПА" && (
            <WIPTag
              sx={{
                marginLeft: "0.5rem",
                marginTop: "0.2rem",
              }}
            />
          )}
        </Box>
        <Box sx={css.unit}>{unit}</Box>
      </Box>
      <SlimProgressBar
        value={percentage}
        progressColor={theme.palette.pale.red}
        withLabel={true}
        bottomRightLabel={total || " "}
        circleLabel={value}
        bottomLabelColorRight={theme.palette.charts.whiteTransparent}
        bottomLabelColorLeft={theme.palette.primary.contrastText}
        style={{
          marginTop: "1.25rem",
          background: theme.palette.background.progressDarkBlue,
        }}
      />
    </Box>
  );
};
