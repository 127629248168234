import { getIsSecret } from "SRC/pages/Summary/common/SummaryComplexGrid/utils";
import { ICustomTableProps } from "SRC/types";

export const getIndicatorsPlan = (
  tableProps: ICustomTableProps,
  isOM: boolean
) => {
  const { indicatorsPlan, indicatorsOMPlan } = tableProps.row.original;

  return {
    isSecret: getIsSecret(tableProps),
    indicatorsPlan: isOM ? indicatorsOMPlan : indicatorsPlan,
  };
};
