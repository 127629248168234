import { styled, Theme } from "@mui/material";

export const Wrapper = styled("div")(({ theme }) => {
  return {
    width: "100%",

    ".incident-container": {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      gap: "0.5rem",
      flex: 1,
      flexWrap: "nowrap",
      borderRadius: "1rem",
      padding: "1.5rem 2rem",
      backgroundColor: theme.palette.complementary.darkBlueLight,
    },
  };
});

export const css = {
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
    flex: 1,
    flexWrap: "nowrap",
    borderRadius: "1rem",
    padding: "1.5rem 2rem",
    backgroundColor: (theme: Theme) =>
      theme.palette.complementary.darkBlueLight,
  },
  progress: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    paddingBottom: "1rem",
  },
  nameContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  name: {
    fontSize: "1.25rem",
    display: "flex",
    gap: "0.25rem",
    "& svg": {
      width: "1.5rem",
      height: "1.25rem",
    },
  },
  unit: {
    fontSize: "1.25rem",
    color: (theme: Theme) => theme.palette.text.secondary,
  },
};
