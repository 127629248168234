import { Box } from "@mui/material";
import React from "react";
import { TableProps, UseTableRowProps } from "react-table";
import { TableCell } from "SRC/components/TableCell";

interface CustomTableProps extends TableProps {
  row: UseTableRowProps<any>;
  rows?: UseTableRowProps<any>[];
}

export const eventColumns = [
  {
    Header: " ",
    width: 0,
    accessor: "expand-button",
    columns: [
      {
        Header: "",
        width: 0,
        accessor: "expand-button",
        Cell: (tableProps: CustomTableProps) => <Box></Box>,
      },
    ],
  },
  {
    Header: " ",
    width: 150,
    columns: [
      {
        Header: "Госпрограмма",
        accessor: "program",
        Cell: (tableProps: CustomTableProps) => (
          <TableCell color="gray">0</TableCell>
        ),
      },
    ],
  },
  {
    Header: " ",
    width: 100,
    accessor: "typeStructure",
    columns: [
      {
        Header: "Тип СЭ",
        width: 100,
        accessor: "typeStructure",
        Cell: (tableProps: CustomTableProps) => (
          <TableCell color="gray">0</TableCell>
        ),
      },
    ],
  },
  {
    Header: " ",
    width: "50%",
    accessor: "nameStructure",
    columns: [
      {
        Header: "Наименование СЭ",
        width: "50%",
        accessor: "nameStructure",
        Cell: (tableProps: CustomTableProps) => (
          <TableCell color="gray">0</TableCell>
        ),
      },
    ],
  },
  {
    Header: "Количество показателей",
    width: "60%",
    columns: [
      {
        Header: "Всего",
        accessor: "total",
        Cell: (tableProps: CustomTableProps) => (
          <TableCell color="gray">0</TableCell>
        ),
      },
      {
        Header: "Выполнено",
        accessor: "done",
        Cell: (tableProps: CustomTableProps) => (
          <TableCell color="gray">0</TableCell>
        ),
      },
      {
        Header: "В реализации",
        accessor: "inProgress",
        Cell: (tableProps: CustomTableProps) => (
          <TableCell color="gray">0</TableCell>
        ),
      },
      {
        Header: "Не начато",
        accessor: "notStart",
        Cell: (tableProps: CustomTableProps) => (
          <TableCell color="gray">0</TableCell>
        ),
      },
      {
        Header: "Под риском",
        accessor: "atRisk",
        Cell: (tableProps: CustomTableProps) => (
          <TableCell color="gray">0</TableCell>
        ),
      },
      {
        Header: "ЧС",
        accessor: "emergency",
        Cell: (tableProps: CustomTableProps) => (
          <TableCell color="gray">0</TableCell>
        ),
      },
    ],
  },
];
