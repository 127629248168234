import { Box, Theme } from "@mui/system";

export const Percent = () => (
  <Box
    component="span"
    sx={{
      color: (theme: Theme) => theme.palette.complementary.grayBlue,
      ml: "2px",
    }}
  >
    %
  </Box>
);
