import {
  Box,
  Button,
  Grid,
  Modal,
  SxProps,
  Typography,
  useTheme,
} from "@mui/material";
import { Close } from "ASSETS/svg/close";
import React, { useMemo, useState } from "react";
import { DownloadReportsButton } from "SRC/components/Buttons/DownloadReportsButton";
import { cssModal } from "SRC/components/DownloadReportsModal/DownloadReportsModal.styled";
import { SelectWithSearchReportsDataType } from "SRC/components/SelectWithSearch/SelectWithSearchReportsDataType";
import { SelectWithSearchReportsPeriod } from "SRC/components/SelectWithSearch/SelectWithSearchReportsPeriod";
import { SelectWithSearchReportsType } from "SRC/components/SelectWithSearch/SelectWithSearchReportsType";
import useFullReportCreator from "SRC/hooks/use-excel-creator/useFullReportCreator";
import { css } from "SRC/pages/Ministry/common/ministriesTabs.styled";
import { useReports } from "SRC/redux/slices/gosprogram/hooks";
import { useWorkbook } from "SRC/redux/slices/gosprogram/hooks/useWorkbook";

interface IDownloadReportsModalProps {
  isOpen: boolean;
  handleClose(): void;
}

export const DownloadReportsModal = ({
  isOpen,
  handleClose,
}: IDownloadReportsModalProps) => {
  const theme = useTheme();
  const selectStyle = useMemo(() => css.selector(theme), [theme]);

  const [selectedReportType, setSelectedReportType] = useState("");
  const onChangeReportType = (value: string): void =>
    setSelectedReportType(value);

  const [selectedPeriod, setSelectedPeriod] = useState("");
  const onChangeReportPeriod = (value: string): void =>
    setSelectedPeriod(value);

  const [selectedDataType, setSelectedDataType] = useState("");
  const onChangeDataType = (value: string): void => setSelectedDataType(value);

  const { config } = useWorkbook();
  const { items: reports } = useReports();
  const saveFile = useFullReportCreator(reports, config);

  return (
    <Box>
      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        componentsProps={{
          backdrop: {
            style: cssModal.backdrop,
          },
        }}
      >
        <Box sx={cssModal.modalBox as SxProps}>
          <Grid container sx={cssModal.modalHeader}>
            <Grid item>
              <Typography sx={cssModal.modalName}>
                Выгрузка отчётов в excel
              </Typography>
            </Grid>
            <Grid item>
              <Box sx={cssModal.closeButton} onClick={handleClose}>
                <Close />
              </Box>
            </Grid>
          </Grid>
          <Box sx={cssModal.selectorsContainer}>
            <SelectWithSearchReportsType
              label=""
              staticLabel
              style={selectStyle}
              onChange={onChangeReportType}
              value={selectedReportType}
            />
            <SelectWithSearchReportsPeriod
              label="Отчетный период"
              staticLabel
              style={selectStyle}
              onChange={onChangeReportPeriod}
              value={selectedPeriod}
            />
            <SelectWithSearchReportsDataType
              label="Вид данных"
              staticLabel
              style={selectStyle}
              onChange={onChangeDataType}
              value={selectedDataType}
            />
          </Box>
          <Grid container sx={cssModal.modalFooter}>
            <Grid item>
              <Button sx={cssModal.button as SxProps} onClick={handleClose}>
                Отмена
              </Button>
            </Grid>
            <Grid item>
              <DownloadReportsButton
                saveFile={saveFile}
                reportType={selectedReportType}
                period={selectedPeriod}
                dataType={selectedDataType}
                onClose={handleClose}
              />
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </Box>
  );
};
