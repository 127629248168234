import { Box, debounce } from "@mui/material";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { IncidentReport } from "SRC/components/IncidentReport";
import { OverlayBlock } from "SRC/components/OverlayBlock";
import { CounterBox } from "SRC/pages/Program/common/GoalsTab/common/NationalGoalsConnect/CounterBox/CounterBox";
import { GoalItem } from "SRC/pages/Program/common/GoalsTab/common/NationalGoalsConnect/GoalItem/GoalItem";
import {
  INationalGoals,
  IStateProgramIndicators,
} from "SRC/pages/Program/common/GoalsTab/common/NationalGoalsConnect/interfaces";
import { ProgramGoalItem } from "SRC/pages/Program/common/GoalsTab/common/NationalGoalsConnect/ProgramGoalItem/ProgramGoalItem";
import { useGoalConnect } from "SRC/redux/slices/gosprogram/hooks/useGoalConnect";

import { ConnectorLine } from "./ConnectorLine/ConnectorLine";
import { Wrapper } from "./NationalGoalsConnect.styled";
import { ScrollButton } from "./ScrollButton/ScrollButton";

export interface IDataGoal {
  id: number;
  icon: string;
  goalTitle: string;
  factors: IFactorInfo[];
}

export interface IFactorInfo {
  id: number;
  point: string;
  name: string;
  programId: number[];
}

export interface IDataProgramGoal {
  id: number;
  title: string;
  unit: string;
  goalsCode: number[];
  idGoalsFactor: number[];
}

export interface ICordsItemLeft {
  x: number;
  y: number;
  dataInfo: IDataProgramGoal;
  heightItem: number;
}

export interface ICordsItemRight {
  x: number;
  y: number;
  heightItem: number;
  dataInfo: IDataGoal;
}

export type TCounter = {
  top: number;
  bottom: number;
};

const convertNationalToData = (
  goal: INationalGoals,
  index: number
): IDataGoal => ({
  id: goal.NationalGoalId, //NationalGoalId
  icon: require(`ASSETS/icons/nationals/icon${index + 1}.gif`).default,
  goalTitle: goal.NationalGoalName, //NationalGoalName
  factors: goal.GoalIndicators.map((indicator) => ({
    id: indicator.GoalIndicatorId,
    point: indicator.GoalIndicatorNum,
    name: indicator.GoalIndicatorName,
    programId: indicator.StateIndicatorIds,
  })).filter((indicator) => indicator.programId !== null),
});

const convertNationalToPrData = (
  goal: IStateProgramIndicators
): IDataProgramGoal => ({
  id: goal.StateIndicatorId, //StateIndicatorId
  title: goal.StateIndicatorName, //StateIndicatorName
  unit: goal.StateIndicatorOKEIName, //StateIndicatorOKEIName
  goalsCode: goal?.Nationalgoalids,
  idGoalsFactor: goal?.IndGoalsIds,
});

const dataToCoords = <D extends object>(
  arr: Element[],
  data: D[],
  offset: number = 0
) =>
  Array.from(arr).map((item, i) => ({
    x: item.getBoundingClientRect()?.x,
    y: item.getBoundingClientRect()?.y - offset,
    heightItem: item.getBoundingClientRect().height,
    dataInfo: data[i],
  })) || [];

export enum ESelectedType {
  GOAL,
  IND,
  FACTOR,
}

export interface ISelected {
  type: ESelectedType;
  id: number;
}

const selectedFactory = (
  type: ESelectedType = ESelectedType.GOAL,
  id: number = 0
): ISelected => ({
  type,
  id,
});

type TGoalMap = Map<IDataGoal["id"], IDataGoal>;
type TIndicatorsMap = Map<IDataProgramGoal["id"], IDataProgramGoal>;

export type TOnSelectFn = (type: ESelectedType, id: number) => () => void;
export type TCheckForActiveFn = (
  type: ESelectedType,
  id: number
) => boolean | undefined;

export const NationalGoalsConnect = () => {
  const {
    items: { NationalGoals, StateProgramIndicators },
    fetching,
  } = useGoalConnect();
  const [selected, setSelected] = useState<ISelected | null>(null);

  const refLeft = useRef<HTMLElement>();
  const refRight = useRef<HTMLElement>();
  const refWrapper = useRef<HTMLElement>();
  const refCanvasContainer = useRef<HTMLElement>();
  //--------canvas-------------
  const [leftList, setLeftList] = useState<HTMLElement | undefined>();

  const [rightItems, setRightItems] = useState<HTMLCollection | undefined>();
  const [leftItems, setLeftItems] = useState<HTMLCollection | undefined>();

  const [cordsLeftItems, setCordsLeftItems] = useState<ICordsItemLeft[]>([]);
  const [cordsRightItems, setCordsRightItems] = useState<ICordsItemRight[]>([]);
  const [scrollTopList, setScrollTopList] = useState<number | undefined>(0);
  const [countHiddenItems, setCountHiddenItems] = useState<TCounter>({
    top: 0,
    bottom: 0,
  });
  const [size, setSize] = useState<DOMRect | undefined>();

  const goalsMap = useMemo<TGoalMap | undefined>(
    () =>
      NationalGoals?.reduce((acc: TGoalMap, item, index) => {
        const el = convertNationalToData(item, index);
        return el?.factors.length ? acc.set(el.id, el) : acc;
      }, new Map() as TGoalMap),
    [NationalGoals]
  );

  const dataGoals: IDataGoal[] = useMemo(
    () => (goalsMap ? Array.from(goalsMap.values()) : []),
    [goalsMap]
  );

  const indicatorsMap = useMemo<TIndicatorsMap | undefined>(
    () =>
      StateProgramIndicators?.reduce((acc: TIndicatorsMap, item) => {
        const el = convertNationalToPrData(item);
        return acc.set(el.id, el);
      }, new Map() as TIndicatorsMap),
    [StateProgramIndicators]
  );

  const dataIndicators: IDataProgramGoal[] = useMemo(
    () => (indicatorsMap ? Array.from(indicatorsMap.values()) : []),
    [indicatorsMap]
  );

  useEffect(() => {
    const leftList = refLeft.current;
    setLeftList(leftList);
    if (leftList === null) return;
    setLeftItems(leftList?.children);
    setRightItems(refRight.current?.children);
  }, []);

  const setCanvasSize = debounce((): void => {
    setSize(refCanvasContainer.current?.getBoundingClientRect());
  }, 100);

  useEffect(() => {
    setCanvasSize();
    window.addEventListener("resize", setCanvasSize);
    return () => {
      window.removeEventListener("resize", setCanvasSize);
    };
  }, [refCanvasContainer]);

  useEffect(() => {
    leftList?.addEventListener("scroll", () =>
      setScrollTopList(leftList?.scrollTop)
    );
  }, [leftList]);

  useEffect(() => {
    const wrapper = refWrapper.current?.getBoundingClientRect();
    if (leftItems && wrapper) {
      setCordsLeftItems(
        dataToCoords(Array.from(leftItems), dataIndicators, wrapper.y)
      );
    }
    if (rightItems && wrapper) {
      setCordsRightItems(
        dataToCoords(Array.from(rightItems), dataGoals, wrapper.y)
      );
    }
  }, [scrollTopList, rightItems, leftItems, dataIndicators, dataGoals, size]);

  const handleScrollUp = () => {
    if (refLeft?.current) {
      refLeft.current.scrollTop = refLeft.current.scrollTop - 100;
    }
  };

  const handleScrollDown = () => {
    if (refLeft?.current) {
      refLeft.current.scrollTop = refLeft.current.scrollTop + 100;
    }
  };

  const onSelect: TOnSelectFn = (type, id) => () => {
    setSelected((state) =>
      state?.type === type && state?.id === id
        ? null
        : selectedFactory(type, id)
    );
  };

  const checkForActive: TCheckForActiveFn = useCallback(
    (type, id): boolean | undefined => {
      if (!indicatorsMap || !goalsMap || !selected) return false;
      if (selected.type === type) return selected.id === id;

      if (selected.type === ESelectedType.IND && type === ESelectedType.GOAL) {
        return indicatorsMap.get(selected.id)?.goalsCode.includes(id);
      }

      if (
        selected.type === ESelectedType.IND &&
        type === ESelectedType.FACTOR
      ) {
        return indicatorsMap.get(selected.id)?.idGoalsFactor?.includes(id);
      }

      if (selected.type === ESelectedType.GOAL && type === ESelectedType.IND) {
        return indicatorsMap.get(id)?.goalsCode.includes(selected.id);
      }

      if (
        selected.type === ESelectedType.GOAL &&
        type === ESelectedType.FACTOR
      ) {
        return goalsMap.get(selected.id)?.factors?.some((f) => f.id === id);
      }

      if (
        selected.type === ESelectedType.FACTOR &&
        type === ESelectedType.IND
      ) {
        return indicatorsMap.get(id)?.idGoalsFactor.includes(selected.id);
      }

      if (
        selected.type === ESelectedType.FACTOR &&
        type === ESelectedType.GOAL
      ) {
        return goalsMap.get(id)?.factors?.some((f) => f.id === selected.id);
      }
    },
    [selected, goalsMap, indicatorsMap]
  );

  return (
    <Wrapper height={size?.height ?? 0}>
      <IncidentReport className="incident-container">
        <OverlayBlock
          isFetching={fetching}
          hasData={Boolean(dataIndicators?.length)}
          noData={
            <div className="no-data-text">
              Согласно паспорту ГП связи с показателями НЦ отсутствуют
            </div>
          }
        >
          <>
            <Box className="item-list-wrapper" ref={refWrapper}>
              <ScrollButton
                position="top"
                handleScroll={handleScrollUp}
                disabled={!countHiddenItems.top}
              />
              <CounterBox position="top" count={countHiddenItems.top} />
              <Box className="item-list-left" ref={refLeft}>
                {dataIndicators.map((programGoal) => (
                  <ProgramGoalItem
                    key={programGoal.id}
                    item={programGoal}
                    active={!!checkForActive(ESelectedType.IND, programGoal.id)}
                    onClick={onSelect(ESelectedType.IND, programGoal.id)}
                  />
                ))}
              </Box>
              <CounterBox position="bottom" count={countHiddenItems.bottom} />
              <ScrollButton
                position="bottom"
                handleScroll={handleScrollDown}
                disabled={!countHiddenItems.bottom}
              />
            </Box>
            <Box className="canvas-container" ref={refCanvasContainer}>
              <ConnectorLine
                cordsLeft={cordsLeftItems}
                cordsRight={cordsRightItems}
                size={size}
                selected={selected}
                setCountHiddenItems={setCountHiddenItems}
                checkActive={checkForActive}
              />
            </Box>
            <Box className="item-list-right" ref={refRight}>
              {dataGoals.map((item) => (
                <GoalItem
                  key={item.id}
                  item={item}
                  checkActive={checkForActive}
                  onClick={onSelect}
                />
              ))}
            </Box>
          </>
        </OverlayBlock>
      </IncidentReport>
    </Wrapper>
  );
};
