import { ICustomTableProps } from "SRC/types";

export const getEventsPlanTotal = (
  tableProps: ICustomTableProps,
  isOM: boolean
) => {
  let { eventsPlanTotal, eventsOMPlanTotal } =
    tableProps?.rows?.[0]?.original || {};

  return {
    eventsPlanTotal: isOM ? eventsOMPlanTotal : eventsPlanTotal,
  };
};
