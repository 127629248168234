import { TIndicatorMap } from "../types";

export const EVENT_INDICATORS: TIndicatorMap = {
  1344: {
    id: "1344",
    desc: "Период",
  },
  1348: {
    id: "1348",
    desc: "Мероприятие",
  },
  1351: {
    id: "1351",
    desc: "Показатели",
  },
  1352: {
    id: "1352",
    desc: "Значение, план",
    aggregationFunction: "sum",
  },
  1358: {
    id: "1358",
    desc: "Значение, факт",
    aggregationFunction: "sum",
  },
  1359: {
    id: "1359",
    desc: "Значение, процент достижения",
    aggregationFunction: "sum",
  },
  1360: {
    id: "1360",
    desc: "Финансирование, план",
    aggregationFunction: "sum",
  },
  1361: {
    id: "1361",
    desc: "Финансирование, факт",
    aggregationFunction: "sum",
  },
  1362: {
    id: "1362",
    desc: "Финансирование, процент достижения",
    aggregationFunction: "sum",
  },
  1363: {
    id: "1363",
    desc: "Реализация в субъектах, план",
    aggregationFunction: "sum",
  },
  1364: {
    id: "1364",
    desc: "Реализация в субъектах, факт",
    aggregationFunction: "sum",
  },
  1365: {
    id: "1365",
    desc: "Реализация в субъектах, процент достижения",
    aggregationFunction: "sum",
  },
  1366: {
    id: "1366",
    desc: "Срок реализации, плановая дата",
    stringAggregation: true,
    separator: ";",
  },
  1367: {
    id: "1367",
    desc: "Срок реализации, фактическая дата",
    stringAggregation: true,
    separator: ";",
  },
  1468: {
    id: "1468",
    desc: "В реализации",
  },
  1469: {
    id: "1469",
    desc: "Новое",
  },
  1470: {
    id: "1470",
    desc: "Выполнено",
  },
  1471: {
    id: "1471",
    desc: "Под риском",
  },
  1472: {
    id: "1472",
    desc: "Не выполнено",
  },
  1473: {
    id: "1473",
    desc: "Всего мероприятий",
    aggregationFunction: "sum",
  },
  1474: {
    id: "1474",
    desc: "Государственные программы",
    stringAggregation: true,
    separator: ";",
  },
  1475: {
    id: "1475",
    desc: "Национальные проекты",
    stringAggregation: true,
    separator: ";",
  },
  1476: {
    id: "1476",
    desc: "Иные мероприятия",
    stringAggregation: true,
    separator: ";",
  },
  1477: {
    id: "1477",
    desc: "Влияние",
  },
  1478: {
    id: "1478",
    desc: "Вектор влияния",
  },
  1479: {
    id: "1479",
    desc: "Признак_основные",
  },
  1480: {
    id: "1480",
    desc: "Признак_не основные",
  },
  1481: {
    id: "1481",
    desc: "Признак_ОКС онлайн",
  },
  1482: {
    id: "1482",
    desc: "Признак_Реализация в субъектах",
  },
  1483: {
    id: "1483",
    desc: "Признак_СЗП",
  },
  1484: {
    id: "1484",
    desc: "Признак_ФХД",
  },
  1485: {
    id: "1485",
    desc: "Признак_КТ",
  },
  1486: {
    id: "1486",
    desc: "Признак_ОКС",
  },
  1487: {
    id: "1487",
    desc: "Признак_ЛЮГ",
  },
  1488: {
    id: "1488",
    desc: "Признак_СМНП",
  },
  1489: {
    id: "1489",
    desc: "Риск_Значение Результата",
  },
  1490: {
    id: "1490",
    desc: "Риск_Срок реализации",
  },
  1491: {
    id: "1491",
    desc: "Риск_Финансирование",
  },
  1492: {
    id: "1492",
    desc: "Риск_Финансирование Ведомство",
  },
  1493: {
    id: "1493",
    desc: "Риск_Финансирование Негативный",
  },
  1494: {
    id: "1494",
    desc: "Риск_Реализация в субъектах",
  },
  1495: {
    id: "1495",
    desc: "Значение результата. Факт. Цвет",
  },
  1496: {
    id: "1496",
    desc: "Финансирование. Факт. Цвет",
  },
  1497: {
    id: "1497",
    desc: "Срок реализации. Конец. Цвет",
  },
  1498: {
    id: "1498",
    desc: "Реализация в субъектах. Факт. Цвет",
  },
  1499: {
    id: "1499",
    desc: "Риск_Финансирование Сценарий",
  },
  1500: {
    id: "1500",
    desc: "Кассовое исполнение%",
  },
  1501: {
    id: "1501",
    desc: "Кассовое исполнение_цвет",
  },
  1502: {
    id: "1502",
    desc: "Признак_ВПЦТ",
  },
  1503: {
    id: "1503",
    desc: "ВПЦТ_Ссылка",
  },
  1666: {
    id: "1666",
    desc: "Статус",
  },
  1750: {
    id: "1750",
    desc: "Значение процент достижения факт к плану",
    aggregationFunction: "sum",
  },
  1823: {
    id: "1823",
    desc: "Корректировки увеличилось значение",
    aggregationFunction: "sum",
  },
  1824: {
    id: "1824",
    desc: "Корректировки увеличилось значение, срок",
    aggregationFunction: "sum",
  },
  1825: {
    id: "1825",
    desc: "Корректировки ухудшилось значение",
    aggregationFunction: "sum",
  },
  1826: {
    id: "1826",
    desc: "Корректировки ухудшилось значение, срок",
    aggregationFunction: "sum",
  },
  1827: {
    id: "1827",
    desc: "Значение, прогноз",
    aggregationFunction: "sum",
  },
  5307: {
    id: "5307",
    desc: "Факт ОМ",
    aggregationFunction: "sum",
  },
  5308: {
    id: "5308",
    desc: "Уровень достижения ОМ",
    aggregationFunction: "sum",
  },
};
