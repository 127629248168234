import { olapRequestData3 } from "CORE/api/core";
import { IPeriod } from "SRC/redux/slices/global/slices/filters";

const GET_TOP_BEST_VP_OM = "get-top-best-vp-om";

export const getTopBestVPOpinion = (period: IPeriod) => {
  return olapRequestData3(
    [
      {
        requestId: "6e816ea8-9589-45bc-91d2-08ee7e624df7",
        type: "data2",
        params: {
          modelUuid: "f8cfb9f5-ce0b-4b19-86c5-fd5a55d97a77",
          dashboardUuid: "",
          includeGaps: false,
          dimensions: [
            {
              id: "1683",
              includeItems: true,
              includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
            },
            {
              id: "1686",
              includeItems: true,
              includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
            },
          ],
          indicators: {
            id: "1692",
            items: [
              {
                id: "5266",
                aggregationFunction: "sum",
              },
            ],
            limit: {
              id: "5266",
              order: "desc",
              type: "TOP",
              value: 5,
            },
          },
          dataRequestCaching: true,
          dataFilter: {
            type: "AND",
            version: 1,
            operands: [
              {
                type: "STARTER",
                version: 1,
                dim_id: "1686",
              },
              {
                type: "EQ",
                version: 1,
                operands: [
                  {
                    type: "DIM",
                    version: 1,
                    id: "1683",
                  },
                  {
                    type: "CONST",
                    version: 1,
                    values: [period.apiV2],
                  },
                ],
              },
            ],
          },
        },
      },
    ],
    GET_TOP_BEST_VP_OM
  );
};
