import React, { useMemo } from "react";
import { AchievementsTile } from "SRC/components/AchievementsTile/AchievementsTile";
import { IAchievementData } from "SRC/components/AchievementsTile/interfaces";
import { isSecret } from "SRC/helpers/dataTesters";
import { Achievements } from "SRC/pages/VicePremier/Achievements/Achievements";
import { Header } from "SRC/pages/VicePremier/common/Header/Header";
import { IProgram } from "SRC/redux/list/programs";
import { useFilters } from "SRC/redux/slices/ministry/hooks/useFilters";
import { usePrograms } from "SRC/redux/slices/ministry/hooks/usePrograms";

import { StackWrapper } from "./VicePremier.styled";

export const VicePremier = () => {
  const { filteredPrograms } = useFilters();
  const { items } = usePrograms();

  const programs = useMemo<IAchievementData[]>(
    () =>
      items
        .map(({ info, data }: IProgram) => ({
          url: info.icon,
          name: info.name,
          sum: data[1796]?.sum || 0,
          secret: isSecret(info),
          code: info.code,
        }))
        .filter((item) => filteredPrograms.includes(item.code)),
    [items, filteredPrograms]
  );
  return (
    <StackWrapper>
      <Header />
      <div className="body">
        <div className="premiers">
          <div className="premiers-title">Главные достижения</div>
          <Achievements />
        </div>
        <div className="divider" />
        <div className="programs">
          <div className="programs-title">
            Госпрограммы
            <span className="counter">{programs.length}</span>{" "}
          </div>
          <div>
            {programs.map((item: IAchievementData) => (
              <AchievementsTile data={item} fetching={false} />
            ))}
          </div>
        </div>
      </div>
    </StackWrapper>
  );
};
