import { Theme } from "@mui/material";
import { CSSProperties } from "@mui/styles";

const tag = {
  background: (theme: Theme) => theme.palette.pale?.yellow,
  width: "fit-content",
  color: (theme: Theme) => theme.palette.background?.default,
  textTransform: "uppercase",
  fontSize: "1rem",
  lineHeight: "1.5rem",
  height: "1.5rem",
  padding: "0 .5em",
  borderRadius: "0.25em",
  whiteSpace: "nowrap",
};

export const css = {
  tag,
  worker: (
    position: "relative" | "absolute" | "fixed" | "sticky" = "relative",
    bordered: boolean = false,
    style: CSSProperties = {}
  ) => ({
    width: 0,
    height: 0,
    display: "none",

    "+ *": {
      position,
      border: (theme: Theme) =>
        bordered && `1px solid ${theme.palette.pale?.yellow}`,

      "&:after": {
        ...tag,
        position: "absolute",
        top: 0,
        right: 0,
        transform: "translate(20%, -50%)",
        content: "'в работе'",
        ...style,
      },
    },
  }),
};
