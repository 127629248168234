import { styled } from "@mui/material";
import { DarkChartBgr, LightChartBgr } from "ASSETS/svg/backgrounds";

interface IProgressDoughnutPaleWrapper {
  withDarkBgr?: boolean | undefined;
}

export const Wrapper = styled("div")<IProgressDoughnutPaleWrapper>(
  ({ withDarkBgr, theme }) => ({
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "end",
    position: "relative",

    " > .textContainer": {
      position: "absolute",
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      gap: "0.5rem",
      background: `url(${
        withDarkBgr ? DarkChartBgr : LightChartBgr
      }) no-repeat`,
      backgroundSize: "contain",
      backgroundPosition: "center",
      fontWeight: 400,

      " > .symbol": {
        fontSize: "0.875rem",
        lineHeight: "1em",
        fontWeight: "bold",
        color: theme.palette.complementary.lightWhite,
        display: "flex",
      },

      "> .value": {
        display: "flex",
        gap: "0.5rem",
        fontSize: "2.5rem",
      },

      " > .title": {
        fontSize: "0.875rem",
        width: "60%",
        lineHeight: 1.2,
        textAlign: "center",
        color: theme.palette.complementary.lightWhite,
      },

      "& .performance-value": {
        height: "2.5rem",
      },
    },
  })
);
