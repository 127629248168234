import { Stack, styled, Theme } from "@mui/material";

export const StackWrapper = styled(Stack)(() => {
  return {
    height: "100%",
  };
});

export const css = {
  tabs: {
    width: "100%",
    flex: "0 0 auto",
  },
  tabSX: (theme: Theme) => ({
    maxWidth: "fit-content",
    color: theme.palette.text.secondary,
    fontSize: theme.typography.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    "&.Mui-selected": {
      color: theme.palette.text.onAccent,
    },
  }),
};
