import { getRoundValue } from "SRC/helpers/getRoundValue";
import { ICustomTableProps } from "SRC/types";

export const getPerformanceTotal = (
  tableProps: ICustomTableProps,
  isOM: boolean
) => {
  let { performanceTotal, performanceOMTotal } =
    tableProps?.rows?.[0]?.original || {};

  const value = (isOM ? performanceOMTotal : performanceTotal) || 0;

  return {
    performanceTotal: value,
    formattedPerformanceTotal: getRoundValue(value, 2),
  };
};
