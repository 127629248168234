import { Tooltip } from "@mui/material";
import React from "react";
import { CheckSecretData } from "SRC/components/CheckSecretData";
import { TableCell } from "SRC/components/TableCell";
import { sortCheckpointsPlanColumn } from "SRC/helpers";
import { ICustomTableProps } from "SRC/types";

import { getCheckpointsPlan, getCheckpointsPlanTotal } from "./utils";

export const checkpointsPlan = (isOM: boolean) => ({
  Header: "План",
  sortType: sortCheckpointsPlanColumn(isOM),
  width: 130,
  accessor: isOM ? "checkpointsOMPlan" : "checkpointsPlan",
  Cell: (tableProps: ICustomTableProps) => {
    const { checkpointsPlan, isSecret } = getCheckpointsPlan(tableProps, isOM);

    return (
      <TableCell color={isOM ? "blue" : "white"}>
        <CheckSecretData isSecret={isSecret}>{checkpointsPlan}</CheckSecretData>
      </TableCell>
    );
  },
  Footer: (tableProps: ICustomTableProps) => {
    const { checkpointsPlanTotal } = getCheckpointsPlanTotal(tableProps, isOM);

    return (
      <Tooltip title="В расчете участвуют уникальные элементы">
        <TableCell color={isOM ? "blue" : "white"}>
          {checkpointsPlanTotal}
        </TableCell>
      </Tooltip>
    );
  },
});
