import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createPeriodDims,
  IParsedItem,
  parseOlapdata,
  TDecoder,
} from "CORE/utils";
import { isContainedArray } from "SRC/helpers/dataTesters";
import { getObjectPhotos } from "SRC/redux/slices/event/api/client";
import { IEventParams } from "SRC/types";

const decoder: TDecoder = {
  period: createPeriodDims(1382),
  object: {
    code: 1387,
    dimensions: {
      name: "NAME",
      eventCode: "R_ID",
      order: "SORT_ORDER",
      startDate: "Дата начала",
      endDate: "Дата окончания",
      code: "Код ОКС",
      location: "Местонахождение объекта",
      capacity: "Мощность",
    },
  },
};

export interface IObjectInfo {
  name: string;
  eventCode: string;
  order: string;
  startDate: string;
  endDate: string;
  code: string;
  location: string;
  capacity: string;
}

export interface IObjectPhoto extends IParsedItem {
  item: IObjectInfo;
}

export interface IObjectPhotosState {
  items: IObjectPhoto[];
  fetching: boolean;
  fetched: boolean;
  error: string;
}

export const extraActions = {
  get: createAsyncThunk(
    "objectPhotos/get",
    async (params: IEventParams) => await getObjectPhotos(params)
  ),
};

const initialState: IObjectPhotosState = {
  items: [],
  fetching: false,
  fetched: false,
  error: "",
};

const slice = createSlice({
  name: "objectPhotos",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActions.get.pending, (state) => {
        state.items = [];
        state.fetching = true;
        state.fetched = false;
      })
      .addCase(
        extraActions.get.fulfilled,
        (state: IObjectPhotosState, action) => {
          const parsed = parseOlapdata(
            // @ts-ignore
            action.payload.data,
            decoder
          ) as unknown as IObjectPhoto[];
          state.items = isContainedArray(parsed) ? parsed : [];
          state.fetching = false;
          state.fetched = true;
        }
      )
      .addCase(extraActions.get.rejected, (state, action) => {
        state.fetching = false;
      });
  },
});

export const actions = {
  ...slice.actions,
  ...extraActions,
};

export default slice;
