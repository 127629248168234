import { useMediaQuery, useTheme } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Column, TableInstance, UseTableRowProps } from "react-table";
import { TableModal } from "SRC/components/FullPageTableModal/FullPageTableModal";
import { IncidentReport } from "SRC/components/IncidentReport";
import { OMSwitcher } from "SRC/components/OMSwitcher";
import { SelectWithSearchStructureElements } from "SRC/components/SelectWithSearch/SelectWithSearchStructureElements";
import Table from "SRC/components/Table";
import { TableSearch } from "SRC/pages/Program/common/EventsTab/TableSearch/TableSearch";
import { useGpStructuresTableData } from "SRC/pages/Program/tableData/useGpStructureTableData";
import { createStructureGPRowsTableData } from "SRC/pages/Program/utils";
import {
  IGlobalFilters,
  useGlobalFilters,
} from "SRC/redux/slices/global/hooks/useFilters";
import { useFilters } from "SRC/redux/slices/gosprogram/hooks";
import { useGpStructure } from "SRC/redux/slices/gosprogram/hooks/useGpStructure";

import { css, ResetFilterButton, Wrapper } from "./StructureTab.styled";
import { getStructureColumns } from "./tableColumns";

interface IStructureTabProps {
  isTableModalOpen?: boolean;
  setTableModalOpen?: (value: boolean) => void;
  infoRows?: Record<string, string>[][];
  getData?: (data: any) => void;
}

const RenderTable: React.FC<IStructureTabProps> = ({
  isTableModalOpen,
  getData,
}: IStructureTabProps) => {
  const theme = useTheme();
  const { sources }: IGlobalFilters = useGlobalFilters();
  const { setSearchRows } = useFilters();
  const { gpStructuresData: data } = useGpStructuresTableData();
  const [instance, setInstance] = useState<TableInstance | undefined>(
    undefined
  );
  const [searchValue, setSearchValue] = useState<string>("");
  const { fetching: gpStructureFetched } = useGpStructure();

  useEffect(() => {
    getData && getData(data);
  }, [data]);

  const {
    selectedStructureElementType,
    setSelectedStructureElementName,
    setSelectedStructureElementType,
    setSelectedEventFeature,
    tableState: initialState,
    setTableState,
  } = useFilters();

  const columns: Column[] = useMemo(
    () => getStructureColumns(sources.OM),
    [sources.OM]
  );

  const setTableFilter = useCallback(
    (value: string) => {
      instance?.setGlobalFilter(value);
      setSearchValue(value);
    },
    [instance]
  );

  const selectStyle = useMemo(() => css.selector(theme), [theme]);

  const handleClearFilters = () => {
    setSelectedStructureElementType("");
    setSelectedStructureElementName("");
    setSelectedEventFeature("");
    setTableFilter("");
  };

  const onChangeFilterRows = useCallback(
    (rows: UseTableRowProps<any>[]) => {
      setSearchRows(createStructureGPRowsTableData(rows, sources.OM));
    },
    [sources.OM]
  );

  useEffect(() => {
    handleClearFilters();
  }, []);

  return (
    <Wrapper isTableModalOpen={isTableModalOpen}>
      <div className="filtersContainer">
        <div className="searchSelectContainer">
          <div className="search">
            <TableSearch value={searchValue} onChange={setTableFilter} />
          </div>
          <SelectWithSearchStructureElements
            key={Math.random()}
            label="Тип структурного элемента"
            staticLabel={false}
            style={selectStyle}
            onChange={setSelectedStructureElementType}
            value={selectedStructureElementType}
          />
          <ResetFilterButton onClick={handleClearFilters}>
            Сбросить фильтры
          </ResetFilterButton>
          {isTableModalOpen && <OMSwitcher />}
        </div>
      </div>
      <IncidentReport className="structure-tab-incident">
        <Table
          data={data}
          columns={columns}
          loading={gpStructureFetched}
          sortedColumns={["Уровень достижения, %", "Касса, %"]}
          tableHeight={!isTableModalOpen ? "47.5rem" : undefined}
          initialState={initialState}
          getLocalState={setTableState}
          getInstance={setInstance}
          onFilterRows={onChangeFilterRows}
        />
      </IncidentReport>
    </Wrapper>
  );
};

export const StructureTab: React.FC<IStructureTabProps> = (props) => {
  const { isTableModalOpen, setTableModalOpen } = props;
  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.up("lg"));

  if (!match && isTableModalOpen) {
    return (
      <TableModal
        open={isTableModalOpen}
        setOpen={setTableModalOpen}
        tableName="Структура ГП"
      >
        <RenderTable {...props} />
      </TableModal>
    );
  }

  return <RenderTable {...props} />;
};
