import { Box, SxProps } from "@mui/material";
import { CSSProperties } from "@mui/styles";
import React from "react";
import { css } from "SRC/components/CardsAnalitic/CardsAnalitic.styled";
import { WIPTag } from "SRC/components/WIP/WIP";

export interface ICardsAnalitic {
  children?: React.ReactNode;
  headerLeft?: string;
  headerRight?: string | number | React.ReactNode;
  style?: CSSProperties | undefined;
  isSmall?: boolean;
  inDevelopment?: boolean;
}

export const CardsAnalitic = ({
  children,
  headerLeft,
  headerRight,
  style,
  isSmall,
  inDevelopment,
}: ICardsAnalitic) => {
  return (
    <Box sx={css.container(isSmall) as SxProps}>
      {(headerLeft || headerRight) && (
        <Box sx={css.header}>
          <Box sx={css.headerLeftContainer}>
            <Box sx={css.headerLeft}>{headerLeft}</Box>
            {inDevelopment && <WIPTag />}
          </Box>
          <Box className="header-right">{headerRight}</Box>
        </Box>
      )}
      <Box sx={style as SxProps}>{children}</Box>
    </Box>
  );
};
