import React from "react";

import { Wrapper } from "./StructureCount.styled";

interface IStructureCountProps {
  name: string;
  value: number;
  className?: string;
}

export const StructureCount = ({
  name,
  value,
  className,
}: IStructureCountProps) => {
  return (
    <Wrapper className={className}>
      <div className="title">{name}</div>
      <div className="value">{value}</div>
    </Wrapper>
  );
};
