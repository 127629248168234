import { olapDimensionRequestData } from "CORE/api/core";
import { IPeriod } from "SRC/redux/slices/global/slices/filters";
import { getDataFiltersOperands } from "SRC/redux/utils";
import { TStructureElementsIndicatorsRequest } from "SRC/types";

const DATA_FILTERS = {
  gp_code: (codes: string | string[]) => {
    return {
      type: "CONTAINS",
      version: 2,
      operands: [
        {
          type: "DIM",
          version: 2,
          id: "5219",
          attributeCode: "StateProgramCode",
        },
        {
          type: "TEXT",
          version: 2,
          values: codes,
        },
      ],
    };
  },
  se_code: (code: string) => {
    return {
      type: "EQ",
      version: 1,
      operands: [
        {
          type: "DIM",
          version: 2,
          id: "5219",
          attributeCode: "SE_CODE",
        },
        {
          type: "TEXT",
          version: 2,
          values: code,
        },
      ],
    };
  },
  se_task_code: (codes: string) => {
    return {
      type: "EQ",
      version: 1,
      operands: [
        {
          type: "DIM",
          version: 2,
          id: "5228",
          attributeCode: "SE_TASK_CODE",
        },
        {
          type: "TEXT",
          version: 2,
          values: codes,
        },
      ],
    };
  },
  se_type: (type: string) => {
    return {
      type: "EQ",
      version: 1,
      operands: [
        {
          type: "DIM",
          version: 2,
          id: "5219",
          attributeCode: "SE_TYPE",
        },
        {
          type: "TEXT",
          version: 2,
          values: type,
        },
      ],
    };
  },
  period: (period: IPeriod[]) => {
    return {
      type: "EQ",
      version: 1,
      operands: [
        {
          type: "DIM",
          version: 1,
          id: "5211",
        },
        {
          type: "CONST",
          version: 1,
          values: [period[0].apiV2],
        },
      ],
    };
  },
};
const GET_STRUCTURE_ELEMENTS_INDICATORS = "get-structure-elements-indicators";

// Показатели СЭ
export const getStructureElementsIndicators = (
  params: TStructureElementsIndicatorsRequest
) => {
  const operands = getDataFiltersOperands(params, DATA_FILTERS);
  const request = [
    {
      requestId: "0f99a128-c9c9-4934-a695-3fb2d85dd4fe",
      type: "data2",
      params: {
        modelUuid: "dbd43734-dc29-4fc4-a16a-144efa1a7d62",
        dashboardUuid: "e9453e1c-4e0d-40f9-9751-060c1fc6be2d",
        includeGaps: false,
        dimensions: [
          // Период
          {
            id: "5211",
            includeItems: true,
            includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
          },
          // Статус показателя
          {
            id: "5214",
            includeItems: true,
            includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
          },
          // Структурный элемент ГП
          {
            id: "5219",
            includeItems: true,
            includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
          },
          // Тип структурного элемента ГП
          {
            id: "5224",
            includeItems: true,
            includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
          },
          // Показатель структурного элемента ГП
          {
            id: "5234",
            includeItems: true,
            includeAttributesByCodes: [
              "NAME",
              "SHORT_NAME",
              "SORT_ORDER",
              "UNIT",
              "IMPLEMENTATION_PERIOD",
            ],
          },
          {
            id: "5228",
            includeItems: true,
            includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
          },
        ],
        indicators: {
          id: "5239",
          items: [
            // План
            {
              id: "5240",
              aggregationFunction: "sum",
            },
            // План ОМ
            {
              id: "5278",
              aggregationFunction: "sum",
            },
            // Выполнено, ОМ
            {
              id: "5279",
              aggregationFunction: "sum",
            },
            // Не выполнено, ОМ
            {
              id: "5280",
              aggregationFunction: "sum",
            },
            // ОМ, План, итого
            {
              id: "5281",
              aggregationFunction: "sum",
            },
            // ОМ, выполнено, итого
            {
              id: "5282",
              aggregationFunction: "sum",
            },
            // Факт
            {
              id: "5241",
              aggregationFunction: "sum",
            },
            // Факт, цвет
            {
              id: "5242",
              aggregationFunction: "sum",
            },
            // План на год
            {
              id: "5243",
              aggregationFunction: "sum",
            },
            // Уровень достижения
            {
              id: "5244",
              aggregationFunction: "sum",
            },
            // Уровень достижения, цвет
            {
              id: "5245",
              aggregationFunction: "sum",
            },
            //Факт ОМ
            {
              id: "5470",
              aggregationFunction: "sum",
            },
            //Уровень достижения ОМ
            {
              id: "5471",
              aggregationFunction: "sum",
            },
            //Участвует в УД ОМ
            {
              id: "5521",
              aggregationFunction: "sum",
            },
            //Участвует в УД ФОИВ
            {
              id: "5522",
              aggregationFunction: "sum",
            },
            //Спущено с уровня ГП
            {
              id: "5533",
              aggregationFunction: "sum",
            },
            //Спущено с уровня ГП ОМ
            {
              id: "5534",
              aggregationFunction: "sum",
            },
          ],
        },
        dataRequestCaching: true,
        dataFilter: {
          type: "AND",
          version: 1,
          operands,
        },
      },
    },
  ];

  return olapDimensionRequestData(request, GET_STRUCTURE_ELEMENTS_INDICATORS);
};
