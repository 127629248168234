import { Theme } from "@mui/material";

export const css = {
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },
  nameContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  name: {
    fontSize: "1.25rem",
    display: "flex",
    gap: "0.25rem",
    "& svg": {
      width: "1.5rem",
      height: "1.25rem",
    },
  },
  unit: {
    fontSize: "1.25rem",
    color: (theme: Theme) => theme.palette.text.secondary,
  },
};
