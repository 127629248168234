import { useMediaQuery, useTheme } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { Column, TableInstance, UseTableRowProps } from "react-table";
import { TableModal } from "SRC/components/FullPageTableModal/FullPageTableModal";
import { IncidentReport } from "SRC/components/IncidentReport";
import { OMSwitcher } from "SRC/components/OMSwitcher";
import { SelectWithSearchStructure } from "SRC/components/SelectWithSearch/SelectWithSearchStructure";
import Table from "SRC/components/Table";
import { TableSearch } from "SRC/pages/Program/common/EventsTab/TableSearch/TableSearch";
import { FilterNameStructure } from "SRC/pages/Program/common/filters/FilterNameStructure";
import { useSETasksTableData } from "SRC/pages/Program/tableData/useSETasksTableData";
import { createTasksSERowsTableData } from "SRC/pages/Program/utils";
import {
  IGlobalFilters,
  useGlobalFilters,
} from "SRC/redux/slices/global/hooks/useFilters";
import {
  useFilters,
  useStructureElementsTasks,
} from "SRC/redux/slices/gosprogram/hooks";

import { getTasksColumns } from "./tableColumns";
import { css, ResetFilterButton, Wrapper } from "./TasksTab.styled";

interface IIndicatorsTabProps {
  isTableModalOpen?: boolean;
  setTableModalOpen?: (value: boolean) => void;
  infoRows?: Record<string, string>[][];
  getData?: (data: any) => void;
}

const RenderTable: React.FC<IIndicatorsTabProps> = ({
  isTableModalOpen,
  getData,
}: IIndicatorsTabProps) => {
  const theme = useTheme();
  const [searchParams] = useSearchParams();
  const { programId } = useParams();
  const filterStatus = searchParams.get("filter");
  const [searchValue, setSearchValue] = useState<string>("");
  const [instance, setInstance] = useState<TableInstance | undefined>(
    undefined
  );
  const { setSearchRows } = useFilters();
  const { fetching } = useStructureElementsTasks();
  const { tasksTableData } = useSETasksTableData();
  const { sources }: IGlobalFilters = useGlobalFilters();

  const {
    selectedStructureElementType,
    setSelectedStructureElementName,
    setSelectedStructureElementType,
    setSelectedStructureElementTask,
    setSelectedEventFeature,
    setSelectedStatus,
    clearSelectedStatus,
  } = useFilters();

  const getTaskName = (value: string) => {
    setSelectedStructureElementTask(value);
  };

  const columns: Column[] = useMemo(() => {
    return getTasksColumns(getTaskName, programId);
  }, []);

  useEffect(() => {
    getData && getData(tasksTableData);
  }, [tasksTableData]);

  const selectStyle = useMemo(() => css.selector(theme), [theme]);

  const setTableFilter = useCallback(
    (value: string) => {
      instance?.setGlobalFilter(value);
      setSearchValue(value);
    },
    [instance]
  );

  const handleClearFilters = () => {
    setSelectedStructureElementType("");
    setSelectedStructureElementName("");
    setSelectedEventFeature("");
    setTableFilter("");
    clearSelectedStatus();
  };

  useEffect(() => {
    if (filterStatus) {
      setSelectedStatus(filterStatus);
    }
  }, [filterStatus]);

  const onChangeFilterRows = useCallback(
    (rows: UseTableRowProps<any>[]) => {
      setSearchRows(createTasksSERowsTableData(rows, sources.OM));
    },
    [sources.OM]
  );

  return (
    <Wrapper isTableModalOpen={isTableModalOpen}>
      <div className="filtersContainer">
        <div className="searchSelectContainer">
          <div className="search">
            <TableSearch value={searchValue} onChange={setTableFilter} />
          </div>
          <SelectWithSearchStructure
            key={Math.random()}
            label="Тип структурного элемента"
            staticLabel={false}
            style={selectStyle}
            onChange={setSelectedStructureElementType}
            value={selectedStructureElementType}
          />
          <FilterNameStructure
            label="Наименование структурного элемента"
            staticLabel={false}
            style={selectStyle}
            onChange={setSelectedStructureElementName}
          />
          <ResetFilterButton onClick={handleClearFilters}>
            Сбросить фильтры
          </ResetFilterButton>
          {isTableModalOpen && <OMSwitcher />}
        </div>
      </div>
      <IncidentReport className="tasks-tab-incident">
        <Table
          loading={fetching}
          columns={columns}
          data={tasksTableData}
          getInstance={setInstance}
          sortedColumns={["Количество показателей", "Количество мероприятий"]}
          tableHeight={!isTableModalOpen ? "47.5rem" : undefined}
          onFilterRows={onChangeFilterRows}
        />
      </IncidentReport>
    </Wrapper>
  );
};

export const TasksTab: React.FC<IIndicatorsTabProps> = (props) => {
  const { isTableModalOpen, setTableModalOpen } = props;
  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.up("lg"));

  if (!match && isTableModalOpen) {
    return (
      <TableModal
        open={isTableModalOpen}
        setOpen={setTableModalOpen}
        tableName="Задачи СЭ"
      >
        <RenderTable {...props} />
      </TableModal>
    );
  }

  return <RenderTable {...props} />;
};
