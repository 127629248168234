import "react-datepicker/dist/react-datepicker.css";

import { Container } from "@mui/material";
import logo from "ASSETS/logo.png";
import classNames from "classnames";
import React, { useMemo } from "react";
import { Calendar } from "SRC/components/Calendar/Calendar";
import { InAchievementCheckbox } from "SRC/components/InAchievementCheckbox/InAchievementCheckbox";
import { OMSwitcher } from "SRC/components/OMSwitcher";
import SidebarMenu from "SRC/components/SidebarMenu";
import { useAppSelector } from "SRC/redux/hooks";
import {
  IGlobalFilters,
  useGlobalFilters,
} from "SRC/redux/slices/global/hooks/useFilters";
import { MAIN, navList } from "SRC/urls";

import { NavTab, Wrapper } from "./Header.styled";

export const Header = (): JSX.Element => {
  const { user } = useAppSelector((state) => state.auth.user);
  const { sources }: IGlobalFilters = useGlobalFilters();

  const navs = useMemo(
    () =>
      navList.map((route) => (
        <NavTab to={route.path} key={route.path + route.title} end>
          {route.title}
        </NavTab>
      )),
    []
  );

  if (!user) {
    return (
      <Wrapper>
        <div className="content">
          <div className="content-wrapper">
            <Container className="container">
              <NavTab className="logo" to={MAIN}>
                <img src={logo} alt="Герб" />
                Госпрограммы
              </NavTab>
            </Container>
          </div>
        </div>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <div className="content">
        <div className="content-wrapper">
          <Container className="container">
            <div className="header-box">
              <SidebarMenu />
              <NavTab className="logo" to={MAIN}>
                <img src={logo} alt="Герб" />
                Госпрограммы <span> {process.env.REACT_APP_VERSION} </span>
              </NavTab>
            </div>
            <nav>{navs}</nav>
            <div className="filters">
              <InAchievementCheckbox />
              <div className="switcher">
                <OMSwitcher variant="white" />
                <div
                  className={classNames("legend-item", { visible: sources.OM })}
                >
                  <div className="styled-point pale-green" />
                  <div className="styled-point pale-red" />
                </div>
              </div>
              <Calendar />
            </div>
          </Container>
        </div>
      </div>
    </Wrapper>
  );
};
