import { Tooltip } from "@mui/material";
import { TooltipProps } from "@mui/material/Tooltip/Tooltip";
import React from "react";
import { CheckSecretData } from "SRC/components/CheckSecretData";
import { TableCell } from "SRC/components/TableCell";
import { sortIndicatorsFactColumn } from "SRC/helpers";
import { ICustomTableProps } from "SRC/types";

import { getIndicatorsFact, getIndicatorsFactTotal } from "./utils";

export enum ETitle {
  FACT_TITLE = "Уровень достижения 100%",
  FAIL_TITLE = "Уровень достижения ниже 100%",
}

export const indicatorsFact = (isOM: boolean) => ({
  Header: "Факт",
  Tooltip: ({ children, ...props }: TooltipProps) => (
    <Tooltip {...props} title={ETitle.FACT_TITLE}>
      {children}
    </Tooltip>
  ),
  sortType: sortIndicatorsFactColumn(isOM),
  width: 130,
  accessor: isOM ? "indicatorsOMFact" : "indicatorsFact",
  Cell: (tableProps: ICustomTableProps) => {
    const { indicatorsFact, isSecret } = getIndicatorsFact(tableProps, isOM);

    return (
      <TableCell color={isOM ? "paleGreen" : "green"}>
        <CheckSecretData isSecret={isSecret}>{indicatorsFact}</CheckSecretData>
      </TableCell>
    );
  },
  Footer: (tableProps: ICustomTableProps) => {
    const { indicatorsFactTotal } = getIndicatorsFactTotal(tableProps, isOM);

    return (
      <Tooltip title="В расчете участвуют уникальные элементы">
        <TableCell color={isOM ? "paleGreen" : "green"}>
          {indicatorsFactTotal}
        </TableCell>
      </Tooltip>
    );
  },
});
