import React from "react";
import { TableProps, UseTableRowProps } from "react-table";
import { Percent } from "SRC/components/Percent/Percent";
import { SlimProgressBar } from "SRC/components/ProgressBar";
import {
  TableCellBigText,
  TableFooterText,
} from "SRC/components/Table/BaseTable/BaseTable.styled";
import { getRoundValue } from "SRC/helpers/getRoundValue";
import { roundNumbersToFixed } from "SRC/helpers/roundNumbersToFixed";
import { css } from "SRC/pages/Summary/common/SummaryComplexGrid/SummaryComplexGrid.styled";

interface CustomTableProps extends TableProps {
  row: UseTableRowProps<any>;
  rows?: UseTableRowProps<any>;
}

const getSatisfactionColumns = () => {
  return [
    {
      Header: "Удовлетворенность населения",
      accessor: "populationSatisfaction",
      Cell: (tableProps: CustomTableProps) => (
        <div style={css.populationSatisfactionColumn.cellWrapperDashboard}>
          <TableCellBigText
            sx={css.populationSatisfactionColumn.cellText(
              tableProps.row.original.populationSatisfaction
            )}
          >
            {`${tableProps.row.original.populationSatisfaction}`}
            <Percent />
          </TableCellBigText>
          <SlimProgressBar
            value={tableProps.row.original.populationSatisfaction || 0}
          />
        </div>
      ),
      Footer: (tableProps: CustomTableProps) => {
        const average = React.useMemo(() => {
          const rowsArray = Array(tableProps.rows || []).flat();
          const total = rowsArray.reduce(
            (sum: number, row: UseTableRowProps<any>) =>
              row.original.populationSatisfaction + sum,
            0
          );

          return roundNumbersToFixed(total / rowsArray.length, 0) || 0;
        }, [tableProps.rows]);

        return (
          <div style={css.populationSatisfactionColumn.footerWrapperDashboard}>
            <TableFooterText sx={css.performanceColumn.footerText(average)}>
              {getRoundValue(average, 2)}
              <Percent />
            </TableFooterText>
            <SlimProgressBar value={average} />
          </div>
        );
      },
    },
  ];
};

export { getSatisfactionColumns };
