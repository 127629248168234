import { useTheme } from "@mui/material";
import ReactECharts from "echarts-for-react";
import React, { useMemo } from "react";
import { PerformanceValue } from "SRC/components/PerformanceValue/PerformanceValue";
import { SourceValue } from "SRC/components/SourceValue/SourceValue";
import { ISourceValue } from "SRC/constants/globals";
import { getPercentValue } from "SRC/helpers/getPercentValue";
import themeConfig from "SRC/theme";

import { getOption } from "./options";
import { Wrapper } from "./ProgressDoughnutPale.styled";

export interface IDPValue extends ISourceValue {
  value: number;
}

interface IProps {
  total: number;
  values: IDPValue[];
  title?: string;
  hasBgr?: boolean | undefined;
}

const ProgressDoughnutPale = ({ total, values, hasBgr, title }: IProps) => {
  const theme = useTheme<typeof themeConfig>();

  const option = useMemo(
    () => getOption(values, total, hasBgr, theme),
    [theme, hasBgr, values, total]
  );

  return (
    <Wrapper withDarkBgr={hasBgr}>
      <div className="textContainer">
        <div className="symbol">%</div>
        <div className="value">
          {values.map((value, index) => (
            <SourceValue key={index} {...value} isPercent={true}>
              <PerformanceValue
                value={String(getPercentValue(value.value))}
                sameSize={false}
                className="performance-value"
              />
            </SourceValue>
          ))}
        </div>
        {Boolean(title) && <div className="title">{title}</div>}
      </div>
      <ReactECharts
        option={option}
        style={{ width: "100%", height: "100%" }}
        key={option.series.length}
      />
    </Wrapper>
  );
};

export default ProgressDoughnutPale;
