import { styled } from "@mui/material";

export const Wrapper = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "0.2rem",
  padding: "0.4rem 0.5rem",
  width: "4rem",
  borderRadius: "3rem",
  background: theme.palette.pale.gray,
}));
