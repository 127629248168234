import { Theme } from "@mui/material";
import { EChartsOption } from "echarts";
import { months } from "SRC/constants/globals";

const getOptions = (
  plan: number[],
  fact: (number | null)[],
  forecast: (number | null)[],
  theme: Theme,
  maxValue?: number | null,
  unit?: string
): EChartsOption => ({
  grid: {
    width: "auto",
    height: "auto",
    top: 60,
    left: 50,
    right: 30,
    bottom: 20,
  },
  xAxis: {
    type: "category",
    show: true,
    boundaryGap: false,
    offset: 0,
    axisLine: {
      show: false,
    },
    splitLine: {
      show: false,
      lineStyle: {
        color: theme.palette.charts.splitLine,
      },
      interval: 0,
    },
    axisTick: {
      show: true,
      lineStyle: {
        color: theme.palette.charts.splitLine,
      },
    },
    axisLabel: {
      formatter: (value: string) => value.slice(0, 3),
      show: true,
      fontSize: 12,
      color: theme.palette.main.gray,
    },
    data: months,
  },
  yAxis: {
    type: "value",
    show: true,
    offset: 20,
    // max: Math.round((maxValue || 0) * 1.3),
    splitLine: {
      lineStyle: {
        color: theme.palette.charts.splitLine,
        type: "dashed",
      },
    },
  },
  tooltip: {
    trigger: "axis",
    backgroundColor: theme.palette.complementary.darkGrayBlue,
    borderColor: theme.palette.complementary.grayBorder,
    textStyle: {
      fontSize: "12px",
      fontWeight: "lighter",
      color: theme.palette.primary.contrastText,
    },
  },
  series: [
    {
      name: "План",
      data: plan,
      type: "line",
      smooth: true,
      symbol: "circle",
      symbolSize: 5,
      tooltip: {
        valueFormatter: (value) => `${value || 0} ${unit || ""}`,
      },
      lineStyle: {
        width: 3,
      },
      itemStyle: {
        color: theme.palette.primary.contrastText,
      },
      areaStyle: {
        color: theme.palette.charts.grayBlue,
      },
      label: {
        lineHeight: 12,
        fontSize: 12,
        position: "top",
        show: true,
        color: theme.palette.primary.contrastText,
        distance: 16,
        align: "center",

        formatter: ({ name, value }) => {
          const index = months.indexOf(name);
          const fv = fact[index];
          const real = Number(
            Number.isFinite(fv) ? fv : forecast[index]
          ).toFixed(2);
          const color = Number.isFinite(fv)
            ? Number(fv) >= value
              ? "green"
              : "red"
            : "blue";
          return [Number(value).toFixed(2), "{hr|}", `{${color}|${real}}`].join(
            "\n"
          );
        },

        rich: {
          plan: {},
          hr: {
            borderColor: theme.palette.charts.grayBlue,
            width: "100%",
            align: "right",
            borderWidth: 1,
            height: 0,
          },
          green: {
            fontSize: 12,
            color: theme.palette.charts.green,
          },
          red: {
            fontSize: 12,
            color: theme.palette.charts.red,
          },
          blue: {
            fontSize: 12,
            color: theme.palette.main.blue,
          },
        },
      },
    },
    {
      name: "Прогноз",
      data: forecast as number[],
      type: "line",
      smooth: true,
      tooltip: {
        valueFormatter: (value) =>
          Number.isFinite(value) ? `${value} ${unit || ""}` : "",
      },
      symbol: "circle",
      symbolSize: 7,
      lineStyle: {
        width: 3,
        type: "dashed",
        color: theme.palette.main.blue,
      },
      itemStyle: {
        color: theme.palette.main.blue,
      },
    },
    {
      name: "Факт",
      data: fact as number[],
      type: "line",
      smooth: true,
      tooltip: {
        valueFormatter: (value) =>
          Number.isFinite(value) ? `${value} ${unit || ""}` : "",
      },
      symbol: "circle",
      symbolSize: 7,
      lineStyle: {
        width: 3,
        color: theme.palette.charts.green,
      },
      itemStyle: {
        color: ({ name, value }) =>
          plan[months.indexOf(name)] <= value
            ? theme.palette.charts.green
            : theme.palette.charts.red,
      },
    },
  ],
});

export default getOptions;
