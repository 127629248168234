import { Tooltip } from "@mui/material";
import { TooltipProps } from "@mui/material/Tooltip/Tooltip";
import React from "react";
import { CheckSecretData } from "SRC/components/CheckSecretData";
import { TableCell } from "SRC/components/TableCell";
import { ETitle } from "SRC/components/TableColumnTemplates/columns/indicators/indicatorsFact";
import { sortIndicatorsSEFactColumn } from "SRC/helpers";
import { ICustomTableProps } from "SRC/types";

import { getIndicatorsSEFact, getIndicatorsSEFactTotal } from "./utils";

export const indicatorsSEFact = (isOM: boolean) => ({
  Header: "Факт",
  Tooltip: ({ children, ...props }: TooltipProps) => (
    <Tooltip {...props} title={ETitle.FACT_TITLE}>
      {children}
    </Tooltip>
  ),
  sortType: sortIndicatorsSEFactColumn(isOM),
  width: 130,
  accessor: isOM ? "indicatorsSEOMFact" : "indicatorsSEFact",
  Cell: (tableProps: ICustomTableProps) => {
    const { indicatorsSEFact, isSecret } = getIndicatorsSEFact(
      tableProps,
      isOM
    );

    return (
      <TableCell color={isOM ? "paleGreen" : "green"}>
        <CheckSecretData isSecret={isSecret}>
          {indicatorsSEFact}
        </CheckSecretData>
      </TableCell>
    );
  },
  Footer: (tableProps: ICustomTableProps) => {
    const { indicatorsSEFactTotal } = getIndicatorsSEFactTotal(
      tableProps,
      isOM
    );

    return (
      <Tooltip title="В расчете участвуют уникальные элементы">
        <TableCell color={isOM ? "paleGreen" : "green"}>
          {indicatorsSEFactTotal}
        </TableCell>
      </Tooltip>
    );
  },
});
