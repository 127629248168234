import { Stack, styled } from "@mui/material";

export const StackWrapper = styled(Stack)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  ".body": {
    display: "flex",
    gap: "1rem",
  },
  ".programs": {
    display: "flex",
    flexDirection: "column",
    flex: "0 0 42%",
    gap: "1rem",
  },
  ".programs-title": {
    display: "flex",
    alignItems: "center",
    gap: "0.8rem",
    fontSize: "1.25rem",
    "& > .counter": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "0.25rem",
      width: "1.2rem",
      height: "1.2rem",
      borderRadius: "50%",
      fontSize: "0.8rem",
      backgroundColor: theme.palette.complementary.darkBlue,
    },
  },
  ".programs-container": {
    display: "flex",
    flexWrap: "wrap",
    gap: "1rem",
    maxHeight: "24rem",
    overflowY: "scroll",
    " > div": {
      flex: 1,
      gap: "1rem",
      maxWidth: "49%",
      width: "50%",
    },
  },
  ".programs-container::-webkit-scrollbar": {
    display: "none",
  },
  ".divider": {
    width: `1px`,
    backgroundColor: theme.palette.complementary.lightWhite,
  },
  ".structures": {
    display: "flex",
    flex: 1,
  },
  ".structures-info": {
    display: "flex",
    gap: "1rem",
    " > div": {
      width: "100%",
      padding: "0.75rem 1.5rem 1.5rem 1.5rem",
      gap: "0.75rem",
      backgroundColor: "#2E364A",
      borderRadius: "0.5rem",
    },
  },
  ".structures-count": {
    display: "flex",
    flexDirection: "column",
    flex: "0 0 25%",
  },
  ".structures-events": {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    flex: 1,
  },
  ".structures-header": {
    display: "flex",
    justifyContent: "space-between",
    "> div": {
      display: "flex",
      gap: "0.5rem",
    },
  },
}));
