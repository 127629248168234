import { styled } from "@mui/material";

interface IOverlayStackedProgressProps {
  isFit: boolean;
}
export const Wrapper = styled("div")<IOverlayStackedProgressProps>(
  ({ theme, isFit }) => {
    const {
      background: { default: bgColor },
      charts: { whiteTransparent },
    } = theme.palette;

    const justify = isFit ? "space-between" : "left";
    const rightLabelStyle = isFit ? {} : { maxWidth: "none" };

    return {
      "& > .title": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "0.25rem",

        "& > .title-value": {
          marginLeft: "auto",
        },
      },

      "& > .progress-lines-container": {
        display: "flex",
        width: "100%",
        maxWidth: "100%",
        flex: "0 1 auto",
        borderRadius: 10,
        overflow: "hidden",
        justifyContent: justify,
        backgroundColor: bgColor,
      },

      "& > .labels-container": {
        display: "flex",
        width: "100%",
        maxWidth: "100%",
        marginTop: "0.5rem",
        overflow: "hidden",
        justifyContent: "space-between",

        "& > .left-label": {
          flex: "1 1 auto",
          paddingRight: "2ch",
        },

        "& > .right-label-wrapper": {
          ...rightLabelStyle,

          display: "flex",
          flex: "1 1 min-content",
          gap: "2ch",
          justifyContent: "space-between",

          "& > .label": {
            width: "max-content",

            "& > svg": {
              display: "inline-block",
              height: "1em",
              marginLeft: "0.5ch",
              verticalAlign: "baseline",
            },
          },

          "& > .total": {
            flex: "0 0 auto",
            color: whiteTransparent,
          },
        },
      },
    };
  }
);

interface IProgressLineProps {
  percent: number;
}
export const ProgressLine = styled("div")<IProgressLineProps>(({ percent }) => {
  const isPercent = Number.isFinite(percent);
  const flex = isPercent ? "unset" : 1;
  const width = isPercent ? `${percent}%` : "auto";

  return {
    flex,
    height: 8,
    width,
    borderRadius: 4,
  };
});

interface IProgressLabelProps {
  percent?: number;
}
export const ProgressLabel = styled("span")<IProgressLabelProps>(
  ({ percent }) => {
    return {
      width: percent ? `${percent}%` : "max-content",
      maxWidth: percent ? `${percent}%` : "max-content",
      minWidth: "min-content",
    };
  }
);
