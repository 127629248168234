import { styled, Theme } from "@mui/material";

export const RatingWrapper = styled("div")(({ theme }) => ({
  width: "100%",

  ".incident-container": {
    position: "relative",
    display: "flex",
    width: "100%",
    height: "6.125rem",
    gap: "0.5rem",
    padding: "0.75rem",
    background: theme.palette.background.paper,
    borderRadius: "1rem",

    ".icon-container": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: "0.5rem",
    },

    ".icon": {
      borderRadius: "1.5rem",

      "& img": {
        height: "2.5rem",
        width: "auto",
        borderRadius: "50%",
      },
    },

    ".secret-program": {
      justifyItems: "end",

      "& svg": {
        width: "1rem",
        height: "1rem",
      },
    },

    ".info-container": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      gap: "0.5rem",

      "& span": {
        color: theme.palette.complementary.lightWhite,
      },
    },

    ".title": {
      height: "2.5rem",
      display: "flex",
      columnGap: "0.5rem",
      alignItems: "center",

      "& > span": {
        fontSize: "1rem",
      },
    },

    ".percent-wrapper": {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      gap: "0.5rem",
      height: "1.5rem",
    },

    ".percents": {
      display: "flex",
      alignItems: "baseline",
      gap: "0.25rem",

      "& span": {
        fontSize: "0.875rem",
      },
    },

    ".opinion > div": {
      color: theme.palette.text.opinion,
    },

    ".separator": {
      margin: "0.5rem 0",
      width: "0.25rem",
      height: "0.25rem",
      backgroundColor: theme.palette.primary.contrastText,
      borderRadius: "50%",
    },

    "& .performance-value": {
      fontSize: "1.25rem",
      fontWeight: 400,
    },
  },
}));

export const css = {
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    gap: "0.5rem",
  },
  title: (theme: Theme) => ({
    fontSize: "1.25rem",
    fontWeight: "bold",
    color: theme.palette.text.primary,
  }),
  rating: {
    display: "flex",
    justifyContent: "space-between",
    gap: "1rem",
  },
};
