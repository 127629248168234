import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { isContainedArray } from "SRC/helpers/dataTesters";
import { getResultCharacteristic } from "SRC/redux/slices/gosprogram/api/client";
import { IEventParams } from "SRC/types";

export interface IResultCharacteristic {
  data: Record<string, any>;
  requestId: string;
  type: string;
}

export interface IResultCharacteristicState {
  items: IResultCharacteristic[];
  fetching: boolean;
  fetched: boolean;
  error: string;
}

export const extraActions = {
  get: createAsyncThunk(
    "resultCharacteristic/get",
    async (params: IEventParams) => await getResultCharacteristic(params)
  ),
};

const initialState: IResultCharacteristicState = {
  items: [],
  fetching: false,
  fetched: false,
  error: "",
};

const slice = createSlice({
  name: "resultCharacteristic",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActions.get.pending, (state) => {
        state.items = [];
        state.fetching = true;
        state.fetched = false;
      })
      .addCase(
        extraActions.get.fulfilled,
        (state: IResultCharacteristicState, action) => {
          state.items = (
            isContainedArray(action.payload.data) ? action.payload.data : []
          ) as IResultCharacteristic[];
          state.fetching = false;
          state.fetched = true;
        }
      )
      .addCase(extraActions.get.rejected, (state, action) => {
        state.fetching = false;
      });
  },
});

export const actions = {
  ...slice.actions,
  ...extraActions,
};

export default slice;
