import { ENPV_DOMAIN } from "../is-enpv-video";

const ENPV_USER = "ventuz";
const ENPV_PASS = "FMG5mboLj2sg";
const REQUEST_HEADER = `Basic ${btoa(`${ENPV_USER}:${ENPV_PASS}`)}`;

export const getEnpvAuthToken = () => {
  let result = null;

  try {
    const request = new XMLHttpRequest();
    request.open("POST", `${ENPV_DOMAIN}/key`, false);
    request.setRequestHeader("Authorization", REQUEST_HEADER);
    request.send();

    ({
      data: { key: result },
    } = JSON.parse(request.response));
  } catch (e) {
    console.error(e);
  }

  return result;
};
