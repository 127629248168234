import { Grid } from "@mui/material";
import React, { useMemo } from "react";
import { DescriptionItem } from "SRC/components/DescriptionItem/DescriptionItem";
import { OverlayBlock } from "SRC/components/OverlayBlock";
import { useIndicatorData } from "SRC/redux/slices/indicator/hooks/useIndicatorData";

import { css } from "./Description.styled";

interface ICol {
  label: string | number;
  value?: number | string;
  flex?: boolean;
}

export const Description = () => {
  const { items: data, fetching: loading } = useIndicatorData();

  const responsible = {
    label: "Ответственный",
    value: data?.seGp.responsible || "-",
  };

  const cols: JSX.Element[] = useMemo(() => {
    const typeIndicator = Boolean(data?.data[5277]?.text)
      ? "Возрастающий"
      : "Убывающий";

    const indicatorLevel = Boolean(data?.data[5551]?.sum)
      ? "Структурный элемент"
      : "Национальный проект";

    return [
      {
        label: data?.seGpType.type || "-",
        value: data?.seGp.name || "–",
        flex: true,
      },
      {
        label: "Уровень показателя",
        value: indicatorLevel,
        flex: true,
      },
      {
        label: "Тип показателя",
        value: typeIndicator || "–",
      },
      {
        label: "Единица измерения",
        value: data?.description.unit || "–",
      },
    ].map(({ flex, label, value }: ICol) => (
      <DescriptionItem key={label} label={label} value={value} flex={flex} />
    ));
  }, [data]);
  return (
    <OverlayBlock isFetching={loading} hasData={Boolean(cols.length)}>
      <Grid container sx={css.container}>
        <DescriptionItem
          className="responsible-column"
          key={responsible.label}
          label={responsible.label}
          value={responsible.value}
        />
        {cols}
      </Grid>
    </OverlayBlock>
  );
};
