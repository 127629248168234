import { Box, SxProps, Theme } from "@mui/material";
import React from "react";
import { TableProps, UseTableRowProps } from "react-table";
import { PerformanceValue } from "SRC/components/PerformanceValue/PerformanceValue";
import { css } from "SRC/components/Table/Table.styled";
import { TableCell } from "SRC/components/TableCell";
import { TooltipText } from "SRC/components/TooltipText";
import { ETypeCalcPerformance, statusIcons } from "SRC/constants/globals";
import { sortInPerformanceColumn } from "SRC/helpers/sort-column/sort-in-performance-column";
import { IEventStatuses } from "SRC/types";

interface CustomTableProps extends TableProps {
  row: UseTableRowProps<any>;
  rows?: UseTableRowProps<any>[];
}

const renderStatusIcons = (statuses: IEventStatuses) =>
  Object.entries(statuses).map(
    ([status, isSet]) => !!isSet && statusIcons[status]
  );

export const getEventColumns = (isOM: boolean, theme: Theme) => [
  {
    Header: " ",
    width: 0,
    accessor: "status",
    Cell: (tableProps: CustomTableProps) => (
      <Box sx={css.statusIcon as SxProps}>
        {renderStatusIcons(tableProps.row.original.status)}
      </Box>
    ),
  },
  {
    Header: "№",
    width: 50,
    accessor: "index",
    Cell: (tableProps: CustomTableProps) => {
      return <TableCell color="gray">{tableProps.row.index}</TableCell>;
    },
  },
  {
    Header: "ID мер-ия",
    width: 130,
    accessor: "id",
    Cell: (tableProps: CustomTableProps) => (
      <TableCell color="gray">{tableProps.row.original.id}</TableCell>
    ),
  },
  {
    Header: "Тип CЭ",
    width: 80,
    accessor: "structureElement",
    Cell: (tableProps: CustomTableProps) => (
      <TableCell color="gray">
        {tableProps.row.original.structureElement}
      </TableCell>
    ),
  },
  {
    Header: "Наименование CЭ",
    width: 200,
    accessor: "structureElementName",
    Cell: (tableProps: CustomTableProps) => (
      <TableCell>{tableProps.row.original.structureElementName}</TableCell>
    ),
  },
  {
    Header: "Тип мероприятия",
    width: 165,
    accessor: "eventTypeNameShort",
    Cell: (tableProps: CustomTableProps) => (
      <TableCell>
        <TooltipText
          hint={tableProps.row.original.eventTypeNameFull}
          variant="h3"
        >
          {tableProps.row.original.eventTypeNameShort}
        </TooltipText>
      </TableCell>
    ),
  },
  {
    Header: "Наименование мероприятия",
    width: 210,
    accessor: "name",
    Cell: (tableProps: CustomTableProps) => (
      <TableCell>{tableProps.row.original.name}</TableCell>
    ),
  },
  {
    Header: "Ед.изм.",
    width: 120,
    accessor: "units",
    Cell: (tableProps: CustomTableProps) => (
      <TableCell>{tableProps.row.original.units}</TableCell>
    ),
  },
  {
    Header: "План",
    width: 100,
    accessor: "plan",
    Cell: (tableProps: CustomTableProps) => (
      <TableCell color={isOM ? "blue" : "white"}>
        {tableProps.row.original.plan}
      </TableCell>
    ),
  },
  {
    Header: "Факт",
    width: 100,
    accessor: isOM ? "factOM" : "fact",
    Cell: (tableProps: CustomTableProps) => {
      const { fact, factOM } = tableProps.row.original;
      return (
        <TableCell color={isOM ? "blue" : "white"}>
          {isOM ? factOM : fact}
        </TableCell>
      );
    },
  },
  {
    Header: "Уровень достижения, %",
    sortType: sortInPerformanceColumn(isOM),
    width: 140,
    accessor: isOM ? "performanceOM" : "performance",
    Cell: (tableProps: CustomTableProps) => {
      const { done, factOMPercent, inPerformance, typeCalcPerformance } =
        tableProps.row.original;

      const achievement = isOM ? factOMPercent : done;

      const typeCalc =
        typeCalcPerformance === 0
          ? ETypeCalcPerformance.FOR_PLAN
          : ETypeCalcPerformance.FOR_POINTS;

      const hint = inPerformance
        ? typeCalc
        : ETypeCalcPerformance.NOT_PARTICIPATE;

      return (
        <TableCell>
          <TooltipText hint={hint} variant="h3">
            <PerformanceValue value={achievement} sameSize={true} />
          </TooltipText>
        </TableCell>
      );
    },
  },
  {
    Header: "Не доведено средств, млрд ₽",
    width: 180,
    accessor: "notDeliveredFunds",
    Cell: (tableProps: CustomTableProps) => {
      const value = Number(tableProps.row.original.notDeliveredFunds ?? 0);

      return <TableCell>{value}</TableCell>;
    },
    /*
    columns: [
      {
        Header: (
          <SelectWithSearchCurrency
            value={""}
            label="млрд ₽"
            staticLabel={false}
            style={selectorStyle.selector(theme)}
            onChange={setCurrencyType}
            options={selectOptions}
          />
        ),
        accessor: "notDeliveredFunds",
        width: 130,
        Cell: (tableProps: CustomTableProps) => {
          return (
            <TableCell>{tableProps.row.original.notDeliveredFunds}</TableCell>
          );
        },
      },
      {
        Header: "%",
        accessor: "notConveyedPercent",
        width: 50,
        Cell: (tableProps: CustomTableProps) => {
          return <TableCell>{resources.notConveyed.percent}</TableCell>;
        },
      },
    ],
     */
  },
  {
    Header: "Не принято средств, млрд ₽",
    width: 180,
    accessor: "notAcceptedFunds",
    Cell: (tableProps: CustomTableProps) => {
      const value = Number(tableProps.row.original.notAcceptedFunds ?? 0);

      return <TableCell>{value}</TableCell>;
    },
    /*
    columns: [
      {
        Header: (
          <SelectWithSearchCurrency
            value={""}
            label="млрд ₽"
            staticLabel={false}
            style={selectorStyle.selector(theme)}
            onChange={setCurrencyType}
            options={selectOptions}
          />
        ),
        accessor: "notAcceptedFunds",
        width: 130,
        Cell: (tableProps: CustomTableProps) => {
          return (
            <TableCell>{tableProps.row.original.notAcceptedFunds}</TableCell>
          );
        },
      },
      /*
      {
        Header: "%",
        accessor: "notAcceptedPercent",
        width: 50,
        Cell: (tableProps: CustomTableProps) => {
          return <TableCell>{resources.notAccepted.percent}</TableCell>;
        },
      },
    ],
     */
  },
  /*
  {
    Header: (
      <div>
        <div>
          <WIPTag />
        </div>
        Влияние
      </div>
    ),
    accessor: "effect",
    columns: [
      {
        Header: "",
        accessor: "effect",
        width: 160,
        Cell: (tableProps: CustomTableProps) => {
          return <TableCell>{tableProps.row.original.effect}</TableCell>;
        },
      },
    ],
  },
   */
];
