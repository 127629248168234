import { useCallback, useEffect, useMemo } from "react";
import { getInfoRowsSummaryPrograms } from "SRC/helpers/getInfoRows";
import { useTableExportParams } from "SRC/hooks";
import { IProgramTableItem } from "SRC/hooks/summaryTable/interfaces";
import { createMainProgramsTableData } from "SRC/hooks/summaryTable/utils/mainData/mainDataGPNew";
import {
  IGlobalFilters,
  useGlobalFilters,
} from "SRC/redux/slices/global/hooks/useFilters";
import { useFilters } from "SRC/redux/slices/main/hooks/useFilters";
import { useMainPrograms } from "SRC/redux/slices/main/hooks/useMainPrograms";
import { IExportData } from "SRC/types";

const TOTAL_ID = "Итого";
const ALLOWED_KEYS = ["name", "id"];
const OM_KEY = "OM";

interface IUseTableData {
  programsMainTableData: IProgramTableItem[];
  programsMainExport: IExportData<Record<string, any>>;
  programsMainData: IProgramTableItem[];
}

export const useMainTableData = (): IUseTableData => {
  const { period } = useGlobalFilters();
  const { filteredMainPrograms } = useFilters();
  const { load: loadMainPrograms, items: mainPrograms } = useMainPrograms();
  const createExportParams = useTableExportParams;
  const { sources }: IGlobalFilters = useGlobalFilters();

  useEffect(() => {
    loadMainPrograms(period);
  }, [period.filterValue]);

  const programsMainTableData = useMemo(() => {
    const programs = createMainProgramsTableData(mainPrograms);

    const programsTotalIndex = programs.findIndex(({ id }) => id === TOTAL_ID);
    const [programsTotal] = programs.splice(programsTotalIndex, 1);

    return programs.map((program) => {
      return {
        ...program,
        indicatorsPlanTotal: programsTotal?.indicatorsPlan,
        indicatorsOMPlanTotal: programsTotal?.indicatorsOMPlan,
        indicatorsFactTotal: programsTotal?.indicatorsFact,
        indicatorsOMFactTotal: programsTotal?.indicatorsOMFact,
        eventsPlanTotal: programsTotal?.eventsPlan,
        eventsOMPlanTotal: programsTotal?.eventsOMPlan,
        eventsFactTotal: programsTotal?.eventsFact,
        eventsOMFactTotal: programsTotal?.eventsOMFact,
        eventsRiskTotal: programsTotal?.eventsRisk,
        eventsOMRiskTotal: programsTotal?.eventsOMRisk,
        performanceTotal: programsTotal?.performance,
        performanceOMTotal: programsTotal?.performanceOM,
      };
    });
  }, [mainPrograms]);

  const programsMainData = useMemo(() => {
    return programsMainTableData
      .filter((program) => filteredMainPrograms.includes(program.id))
      .sort((a, b) => Number(a.id) - Number(b.id));
  }, [programsMainTableData, filteredMainPrograms]);

  const predicate = useCallback(
    (key: string) => {
      return ALLOWED_KEYS.includes(key) || sources.OM === key.includes(OM_KEY);
    },
    [sources.OM]
  );

  const programsMainExport = useMemo(() => {
    const download = programsMainTableData.map(
      ({
        icon,
        secretProgram,
        performanceTotal,
        performanceOMTotal,
        indicatorsPlanTotal,
        vp,
        ministry,
        isProgram,
        indicatorsOMPlanTotal,
        indicatorsOMFactTotal,
        indicatorsFactTotal,
        eventsFactTotal,
        eventsOMFactTotal,
        eventsOMPlanTotal,
        eventsOMRiskTotal,
        eventsPlanTotal,
        eventsRiskTotal,
        ...item
      }) => {
        return Object.keys(item).reduce(
          (acc, key) => ({
            ...acc,
            ...(predicate(key) && { [key]: item[key as keyof typeof item] }),
          }),
          {}
        );
      }
    );

    return {
      csvData: download,
      fileName: "Сводная таблица. Госпрограммы",
      infoRows: getInfoRowsSummaryPrograms(period),
      ...createExportParams(download, "govPrograms"),
    };
  }, [programsMainTableData, period, createExportParams, predicate]);

  return {
    programsMainTableData,
    programsMainExport,
    programsMainData,
  };
};
