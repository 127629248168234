import { Theme } from "@mui/material";

export const css = {
  container: (isSmall?: boolean) => ({
    display: "flex",
    flexDirection: "column",
    width: "100%",
    background: (theme: Theme) => theme.palette.background.paper,
    borderRadius: "1rem",
    padding: "0.75rem 1.5rem 1rem 1.5rem",
    gap: "1rem",
    minHeight: isSmall ? "21.5rem" : "22rem",
    flex: 1,
  }),
  header: {
    display: "flex",
    alignItems: "baseline",
    paddingBottom: "0.5rem",
    gap: "1rem",
    borderBottom: (theme: Theme) =>
      `1px solid ${theme.palette.background.opacityWhite}`,

    ".header-right": {
      display: "flex",
      flex: "1 1 auto",
      justifyContent: "flex-end",
    },
  },
  headerLeftContainer: {
    display: "flex",
    flex: "1",
    gap: "1rem",
    alignItems: "baseline",
  },
  headerLeft: {
    whiteSpace: "nowrap",
    fontSize: "1.25rem",
    fontWeight: 400,
  },
};
