import { Stack, styled } from "@mui/material";

export const StackWrapper = styled(Stack)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "0.5rem",
  fontSize: "1rem",
  flex: "0 0 auto",
  maxWidth: "max-content",
  "&.fullSize": {
    minWidth: 0,
    flex: 1,
  },
  "& .text": {
    fontSize: "1rem",
    lineHeight: "1.2rem",
    whiteSpace: "nowrap",
    color: theme.palette.main.gray,
    "&.value": {
      width: "100%",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
}));

export const css = {
  container: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "nowrap",
    flex: 1,
    width: "100%",
    maxWidth: "100%",
    gap: "2rem",
    padding: "1rem",
  },
};
