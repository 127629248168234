import React from "react";
import { TableProps, UseTableRowProps } from "react-table";
import { NavigateCell } from "SRC/components/Table";
import { TableCell } from "SRC/components/TableCell";
import { PROGRAM_TABS } from "SRC/types/program/program-tab";
import { createProgramCardUrl, PROGRAM_VIEW_TAB } from "SRC/urls";

interface CustomTableProps extends TableProps {
  row: UseTableRowProps<any>;
  rows?: UseTableRowProps<any>[];
}

type TColumnValue = (value: string) => void;

export const getTasksColumns = (getColumnValue: TColumnValue, id = "") => {
  return [
    {
      Header: "№",
      accessor: " ",
      width: 50,
      Cell: (tableProps: CustomTableProps) => (
        <TableCell color="gray">{tableProps.row.index}</TableCell>
      ),
    },
    {
      Header: "Наименование",
      accessor: "name",
      width: "auto",
      Cell: (tableProps: CustomTableProps) => (
        <TableCell>{tableProps.row.original.name}</TableCell>
      ),
    },
    {
      Header: "Количество показателей",
      accessor: "indicatorsCount",
      width: 200,
      Cell: (tableProps: CustomTableProps) => {
        const { name, indicatorsCount } = tableProps.row.original;

        return (
          <NavigateCell
            url={createProgramCardUrl(
              PROGRAM_VIEW_TAB,
              id,
              PROGRAM_TABS.STRUCTURE
            )}
            title="Перейти к показателям"
          >
            <TableCell onClick={() => getColumnValue(name)}>
              {indicatorsCount}
            </TableCell>
          </NavigateCell>
        );
      },
    },
    {
      Header: "Количество мероприятий",
      accessor: "eventsCount",
      width: 200,
      Cell: (tableProps: CustomTableProps) => {
        const { name, eventsCount } = tableProps.row.original;

        return (
          <NavigateCell
            url={createProgramCardUrl(
              PROGRAM_VIEW_TAB,
              id,
              PROGRAM_TABS.EVENTS
            )}
            title="Перейти к мероприятиям"
          >
            <TableCell onClick={() => getColumnValue(name)}>
              {eventsCount}
            </TableCell>
          </NavigateCell>
        );
      },
    },
  ];
};
