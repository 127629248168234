import React from "react";

import { AccessDeniedPageStyled } from "./access-denied-page.styled";
//@ts-ignore

const DEFAULT_MESSAGE = "Нет доступа к АРМ";

export interface IAccessDeniedPage {
  message?: React.ReactNode;
}
export const AccessDeniedPage = ({
  message = DEFAULT_MESSAGE,
}: IAccessDeniedPage) => (
  <AccessDeniedPageStyled>
    <div>{message}</div>
  </AccessDeniedPageStyled>
);
