import { Theme } from "@mui/material";

export const govProgramsTabStyled = {
  atRisk: (theme: Theme) => {
    const {
      main: { gray, darkBlue },
      error: { main: red },
    } = theme.palette;

    return {
      color: gray,
      "&.at-risk": {
        color: red,
      },
      background: `radial-gradient(
        circle at 0.3rem,
        ${darkBlue} 0,
        ${darkBlue} 0.15rem,
        ${darkBlue} 0.15rem,
        transparent 0.2rem,
        transparent 0.2rem
      )`,
    };
  },
  searchInputWrapper: {
    width: "100%",
    maxWidth: "100%",
  },
  searchInput: {
    fontSize: "1rem",
  },
  search: (theme: Theme) => ({
    color: theme.palette.main.gray,
    width: "1.25rem",
    height: "1.25rem",
    ml: "10px",
  }),
  gpWrapper: {
    "& .MuiFormControl-root": {
      flexDirection: "row",
    },
    "& .MuiFormControl-root > .MuiBox-root": {
      color: "#58637B",
      fontSize: "1rem",
      marginRight: "0.3rem",
      whiteSpace: "nowrap",
      width: "30%",
    },
    "& .MuiFormControl-root > .MuiAutocomplete-root": {
      width: "100%",
    },
  },
};
