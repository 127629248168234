import { styled } from "@mui/material";

export const Wrapper = styled("footer")(({ theme }) => {
  const {
    pale: { gray },
  } = theme.palette;

  return {
    padding: "1rem 2rem",

    "> a": {
      fontSize: "1rem",
      textDecoration: "none",
      color: gray,

      "&:hover": {
        textDecoration: "underline",
      },
    },
  };
});
