const REPORT_TYPES = {
  CP: "xls_control_points",
  SE: "xls_structure_elements",
  EVENTS: "xls_events",
};

// type TReports = REPORT_TYPES.CP | REPORT_TYPES.SE | REPORT_TYPES.EVENTS;

export const REPORT_TYPES_LABELS = [
  { value: REPORT_TYPES.CP, label: "Сводные данные по КТ" },
  { value: REPORT_TYPES.SE, label: "Сводные данные по СЭ" },
  { value: REPORT_TYPES.EVENTS, label: "Сводные данные по мероприятиям" },
];
