import { Box, Grid } from "@mui/material";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { Column } from "react-table";
import { IncidentReport } from "SRC/components/IncidentReport";
import Table from "SRC/components/Table";
import { useSubjectTableData } from "SRC/pages/Program/tableData/useSubjectTableData";
import { ISubjectsTableData } from "SRC/pages/Program/utils";
import { useSubjects } from "SRC/redux/slices/event/hooks/useSubjects";
import { SUBJECT_STATUS } from "SRC/types";

import { Wrapper } from "./SubjectTab.styled";

interface IProps {
  columns: Column[];
}

export const SubjectTab = ({ columns }: IProps) => {
  const { fetching } = useSubjects();
  const { subjectsTableData, subjectsStatuses } = useSubjectTableData();
  const [filteredData, setFilteredData] = useState(subjectsTableData);

  const [filterValue, setFilterValue] = useState<string | null>(null);
  const onSetFilter = (value: SUBJECT_STATUS | null) => () => {
    setFilterValue(value);
  };

  const { doneItems, noDataItems, notDoneItems, totalItems } = subjectsStatuses;

  useEffect(() => {
    if (filterValue) {
      setFilteredData(
        subjectsTableData.filter(
          (subject) => subject[filterValue as keyof ISubjectsTableData]
        )
      );
    } else {
      setFilteredData(subjectsTableData);
    }
  }, [filterValue, subjectsTableData]);

  return (
    <Wrapper>
      <IncidentReport className="incident-container">
        <Grid container className="grid-container">
          <Grid item>
            <Box
              onClick={onSetFilter(null)}
              className={classNames("flex-container", {
                "is-active": !filterValue,
              })}
            >
              <span className="gray-text">Всего </span>
              <span>{totalItems}</span>
            </Box>
          </Grid>
          <Grid item>
            <Box
              onClick={onSetFilter(SUBJECT_STATUS.DONE)}
              className={classNames("flex-container", {
                "is-active": filterValue === SUBJECT_STATUS.DONE,
              })}
            >
              <Box className="styled-dot green" />
              <span className="gray-text">Выполнено</span>
              <span>{doneItems}</span>
            </Box>
          </Grid>
          <Grid item>
            <Box
              onClick={onSetFilter(SUBJECT_STATUS.NOT_DONE)}
              className={classNames("flex-container", {
                "is-active": filterValue === SUBJECT_STATUS.NOT_DONE,
              })}
            >
              <Box className="styled-dot red" />
              <span className="gray-text">Не выполнено </span>
              <span>{notDoneItems}</span>
            </Box>
          </Grid>
          <Grid item>
            <Box
              onClick={onSetFilter(SUBJECT_STATUS.NO_REPORTING)}
              className={classNames("flex-container", {
                "is-active": filterValue === SUBJECT_STATUS.NO_REPORTING,
              })}
            >
              <Box className="styled-dot blue" />
              <span className="gray-text">Не представлена отчетность </span>
              <span>{noDataItems}</span>
            </Box>
          </Grid>
        </Grid>
        <Table
          columns={columns}
          data={filteredData}
          loading={fetching}
          sortedColumns={["План", "Факт", "Название субъекта"]}
          tableHeight="31rem"
          noDataCondition={!fetching && filteredData?.length === 0}
          noDataText="Не реализуется в субъектах РФ"
        />
      </IncidentReport>
    </Wrapper>
  );
};
