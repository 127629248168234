export const getMaxPointValue = (pointsA: any[], pointsB: any[]) => {
  const maxIndex = Math.max(pointsA.length, pointsB.length);
  const result = [];

  for (let i = 0; i < maxIndex; i++) {
    result.push(Math.max(pointsA[i] || 0, pointsB[i] || 0));
  }

  return result;
};
