import { Box, useTheme } from "@mui/material";
import React, { useMemo } from "react";
import { IncidentReport } from "SRC/components/IncidentReport";
import { OverlayBlock } from "SRC/components/OverlayBlock";
import { getRoundValue } from "SRC/helpers/getRoundValue";
import { ObjectTab } from "SRC/pages/Event/common/EventTabs/ObjectTab/ObjectTab";
import { ITableObject } from "SRC/pages/Event/common/EventTabs/ObjectTab/util/interfaces";
import {
  IObject,
  useListOfObjects,
} from "SRC/redux/slices/event/hooks/useListOfObjects";

import { isVideo } from "../../video";
import { Wrapper } from "./ObjectTab.styled";

export const ObjectTabWrapper = () => {
  const theme = useTheme();
  const { items, fetching } = useListOfObjects();

  const data = useMemo<ITableObject[]>(
    () =>
      items
        .map(({ item, data }: IObject) => ({
          ...item,
          id: item.code,
          ready: getRoundValue(
            Number(
              (Number(data[1404]?.sum) / Number(data[1403]?.sum)) * 100 || 0
            ),
            0
          ),
          datePlan: [item.startDate, item.endDate] as [string, string],
          hasPhoto: Boolean(data[1455]?.sum),
          hasVideo: isVideo((data[1456]?.sum || "").toString()),
        }))
        .sort(
          (a: ITableObject, b: ITableObject) =>
            Number(a.order) - Number(b.order)
        ),
    [items]
  );

  return (
    <Wrapper>
      <IncidentReport className="incident-container">
        <OverlayBlock isFetching={fetching} hasData={Boolean(data.length)}>
          <ObjectTab data={data} />
        </OverlayBlock>
      </IncidentReport>
    </Wrapper>
  );
};
