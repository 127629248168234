import { useMediaQuery, useTheme } from "@mui/material";
import React, { FC, Fragment, useEffect } from "react";
import { useParams } from "react-router";
import Breadcrumbs from "SRC/components/Breadcrumbs/Breadcrumbs";
import { LayoutNew } from "SRC/layouts";
import {
  IGlobalFilters,
  useGlobalFilters,
} from "SRC/redux/slices/global/hooks/useFilters";
import { useEvents } from "SRC/redux/slices/gosprogram/hooks/useEvents";
import { useGosprogram } from "SRC/redux/slices/gosprogram/hooks/useGosprogram";
import { useProgram } from "SRC/redux/slices/gosprogram/hooks/useProgram";
import themeConfig from "SRC/theme";
import { PROGRAMS } from "SRC/urls";

import { CenterLayout, LeftLayout, RightLayout } from "./layouts";

export const Program: FC = () => {
  const theme = useTheme<typeof themeConfig>();
  const isExtraLarge = useMediaQuery(theme.breakpoints.up("xl"));

  const { init } = useGosprogram();
  const { programId } = useParams();
  const { items: events } = useEvents();
  const { fetching, items: program } = useProgram();

  const { period }: IGlobalFilters = useGlobalFilters();

  useEffect(() => {
    if (programId && !fetching) {
      init({ programId });
    }
  }, [programId, period]);

  const links = [
    {
      title: "Госпрограммы",
      link: PROGRAMS,
    },
    {
      title: program?.gp_name || "",
    },
  ];

  return (
    <Fragment>
      <Breadcrumbs links={links} />
      <LayoutNew
        left={<LeftLayout />}
        center={<CenterLayout isExtraLarge={isExtraLarge} />}
        right={
          isExtraLarge && <RightLayout programGoals={[]} events={events} />
        }
        isProgramLayout
      />
    </Fragment>
  );
};
