import { Box, SxProps, useTheme } from "@mui/material";
import ReactECharts, { EChartsInstance } from "echarts-for-react";
import React, { useEffect, useMemo, useState } from "react";
import { ISourceValue } from "SRC/constants/globals";
import themeConfig from "SRC/theme";

import { getOption } from "./options";
import { css } from "./PieRadialChart.styled";

export interface IPRChartElement {
  name: string;
  value: ISourceValue;
  total: number;
}

interface IProps {
  values: IPRChartElement[];
}

const PieRadialChart = ({ values }: IProps) => {
  const theme = useTheme<typeof themeConfig>();
  const [instance, setInstance] = useState<EChartsInstance>(null);

  useEffect(() => {
    if (instance) window.addEventListener("resize", instance.resize);
  }, [instance]);

  const options = useMemo(
    () => getOption(values, theme, instance),
    [values, theme, instance]
  );

  return (
    <Box sx={css.container as SxProps}>
      <Box sx={css.graphContainer as SxProps}>
        <ReactECharts
          option={options}
          style={css.graph}
          key={values.length}
          onChartReady={(i) => setInstance(i)}
          opts={{ renderer: "svg" }}
        />
      </Box>
    </Box>
  );
};

export default PieRadialChart;
