import { Box, FormControlLabel, Switch, SxProps } from "@mui/material";
import React, { useCallback } from "react";
import { useGlobalFilters } from "SRC/redux/slices/global/hooks/useFilters";

import { css } from "./SecretSwitcher.styled";

export const SecretSwitcher = () => {
  const { withSecret, toggleSecret } = useGlobalFilters();

  const handleChange = useCallback(() => {
    toggleSecret(!withSecret);
  }, [toggleSecret, withSecret]);

  return (
    <Box sx={css.switch as SxProps}>
      <FormControlLabel
        classes={{ label: "controlLabel" }}
        value="start"
        control={
          <Switch
            checked={withSecret}
            onChange={handleChange}
            inputProps={{ "aria-label": "controlled" }}
            size="small"
          />
        }
        label="Скрытые"
        labelPlacement="start"
      />
    </Box>
  );
};
