import { DoubleDownArrow, DoubleUpArrow } from "ASSETS/svg/arrow";
import classnames from "classnames";
import moment from "moment";
import React, { Dispatch, SetStateAction } from "react";
import { StepWrapper } from "SRC/pages/Event/common/Checkpoints/Step/Step.styled";
import TooltipCheckpoints from "SRC/pages/Event/common/Checkpoints/TooltipCheckpoints/TooltipCheckpoints";
import { IStepsInfo } from "SRC/pages/Event/interfaces";
import { selectClassNames } from "SRC/pages/Event/util/utils";

import { StepLabelCheckpoint } from "../Checkpoint.styled";

interface IProps {
  label: IStepsInfo;
  openName: boolean;
  setOpenName: Dispatch<SetStateAction<boolean>>;
}

export const StepComponent = ({ label, openName, setOpenName }: IProps) => {
  const maxLength = 70;
  const { statusPoint, datePlan, namePoint, dateFinish, deviation } = label;
  const truncatedString = namePoint.substring(0, maxLength) + "...";
  const isLargeString = namePoint.length > maxLength;
  const displayText = openName ? namePoint : truncatedString;

  const handleExpand = () => setOpenName(!openName);

  const formatDeviation = (
    dateFinish: string,
    datePlan: string,
    deviation: string | number | undefined
  ) => {
    if (moment(dateFinish).isAfter(datePlan)) {
      return deviation;
    } else {
      return null;
    }
  };

  return (
    <StepWrapper key={namePoint}>
      <div className="step-label-date">
        <div className="date-plan">{datePlan}</div>
        {dateFinish && (
          <span
            className={classnames("date-finish", selectClassNames(statusPoint))}
          >
            {dateFinish}
          </span>
        )}
        {deviation && (
          <div
            className={classnames("deviation", selectClassNames(statusPoint))}
          >
            {formatDeviation(dateFinish, datePlan, deviation)}
          </div>
        )}
      </div>
      <TooltipCheckpoints title={label}>
        <StepLabelCheckpoint
          status={statusPoint}
          StepIconComponent={() => (
            <div
              className={classnames(
                "color-step",
                selectClassNames(statusPoint)
              )}
            />
          )}
        >
          {isLargeString ? (
            <>
              {displayText}
              {openName ? (
                <DoubleUpArrow className="expand-icon" onClick={handleExpand} />
              ) : (
                <DoubleDownArrow
                  className="expand-icon"
                  onClick={handleExpand}
                />
              )}
            </>
          ) : (
            namePoint
          )}
        </StepLabelCheckpoint>
      </TooltipCheckpoints>
      <div
        className={classnames(
          "stepper-connector",
          selectClassNames(statusPoint)
        )}
      />
    </StepWrapper>
  );
};
