import { styled } from "@mui/system";

//@ts-ignore
import icon from "./sprites.png";

export const AccessDeniedPageStyled = styled("div")(() => {
  return {
    display: "flex",
    width: "100%",
    justifyContent: "center",

    "& > div": {
      minWidth: "6rem",
      marginTop: "25vh",
      padding: "5rem 0 0 0",
      fontSize: "0.8rem",
      lineHeight: "1rem",
      textAlign: "center",
      background: `url(${icon}) 50% 0 no-repeat`,
      backgroundSize: "5rem",
    },
  };
});
