import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { isContainedArray } from "SRC/helpers/dataTesters";
import { IPeriod } from "SRC/redux/slices/global/slices/filters";
import { getProgram } from "SRC/redux/slices/gosprogram/api/client";
import { ICodeItem } from "SRC/types/data";

import { TProgramCachePath } from "../types";
import { periodYearMonth } from "../utils";

export interface IProgramParams {
  period: IPeriod;
  gp_code: string;
}

export interface IProgram {
  code: string;
  "Вице-премьер": string | number;
  "Вице-премьер, фото": string;
  Отраслевая: string;
  Сфера: string;
  Комплексная: string;
  Секретная: string;
  "Вице-премьер, код": string;
  gp_code: string;
  gp_short_name: string;
  gp_icon_url: string;
  Министерство: string;
  gp_name: string;
  data: ICodeItem[];
}

type TByCodeMap = Record<string, IProgram>;
type TByMonthMap = Record<string, TByCodeMap>;
type TByYearMap = Record<string, TByMonthMap>;

export interface IProgramState {
  map: TByYearMap;
  items: IProgram | null;
  fetching: boolean;
  fetched: boolean;
  error: string;
}

export const extraActions = {
  get: createAsyncThunk(
    "govprogram/get",
    async ({ period, gp_code }: IProgramParams) =>
      await getProgram(period, gp_code)
  ),
};

const initialState: IProgramState = {
  map: {},
  items: null,
  fetching: false,
  fetched: false,
  error: "",
};

export const slice = createSlice({
  name: "govprogram",
  initialState,
  reducers: {
    getCachedProgram: (state, action: { payload: TProgramCachePath }) => {
      const { year, month, programId } = action.payload;

      state.items = state.map[year]?.[month]?.[programId] || null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(extraActions.get.pending, (state) => {
        state.items = null;
        state.fetching = true;
        state.fetched = false;
      })
      .addCase(extraActions.get.fulfilled, (state: IProgramState, action) => {
        const { data } = action.payload;
        // @ts-ignore
        const program = (isContainedArray(data) ? data[0] : null) as IProgram;

        const periodKey = action.meta.arg.period.apiV2;

        if (program) {
          const { year, month } = periodYearMonth(periodKey);

          const yearState = state.map[year] || {};
          const monthState = yearState[month] || {};

          if (year) {
            state.map[year] = {
              ...yearState,
              [month]: {
                ...monthState,
                [program.code]: program,
              },
            };
          }
        }

        state.items = program;
        state.fetching = false;
        state.fetched = true;
      })
      .addCase(extraActions.get.rejected, (state, action) => {
        state.fetching = false;
        state.fetched = true;
        // state.error = action.error;
      });
  },
});

export const actions = {
  ...slice.actions,
  ...extraActions,
};
