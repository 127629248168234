export const data = {
  chartValue: 4,
  chartTotal: 5,
  chartUnits: 10,
  chartPaleValues: [
    {
      value: 45,
      settings: {
        successColor: "#45E595",
        failColor: "#FF6682",
        valueColor: "#FFFFFF",
        title: "ФОИВ",
      },
    },
  ],
  chartPaleTotal: 100,
  premierInfo: {
    name: "Голикова Татьяна Алексеевна",
    vicePremierPhoto: "",
  },
};
