import { getDataSumByCodeMin } from "SRC/constants";
import { IMinistriesTableItem } from "SRC/hooks/summaryTable/interfaces";
import { TMinistry } from "SRC/redux/slices/main/hooks/useMinistries";

export const createMinistriesData = (
  data: TMinistry[]
): IMinistriesTableItem[] =>
  data.map((item: TMinistry) => {
    const { info } = item;
    const elem = { ...data, data: Object.values(item.data) };
    return {
      id: info.code,
      name: info.code,
      govPrograms: getDataSumByCodeMin(elem, 1782) || 0,
      govProgramsNoSecret: getDataSumByCodeMin(elem, 5401) || 0,
      checkpointsFactTotal: getDataSumByCodeMin(elem, 5441) || 0,
      // Мероприятия план
      eventsPlan: getDataSumByCodeMin(elem, 1785) || 0,
      eventsPlanTotal: getDataSumByCodeMin(elem, 5434) || 0,
      eventsOMPlanTotal: getDataSumByCodeMin(elem, 5434) || 0,
      eventsFoivOrOMPlanTotal: getDataSumByCodeMin(elem, 5434) || 0,
      // Уровень достижения
      performance: getDataSumByCodeMin(elem, 1845) || 0,
      performanceOM: getDataSumByCodeMin(elem, 5268) || 0,
      // Структурные элементы план
      structureElementsPlan: getDataSumByCodeMin(elem, 5406) || 0,
      structureElementsPlanTotal: getDataSumByCodeMin(elem, 5428) || 0,
      structureElementsOMPlan: getDataSumByCodeMin(elem, 5409) || 0,
      structureElementsOMPlanTotal: getDataSumByCodeMin(elem, 5431) || 0,
      // Структурные элементы факт
      structureElementsFact: getDataSumByCodeMin(elem, 5407) || 0,
      structureElementsFactTotal: getDataSumByCodeMin(elem, 5429) || 0,
      structureElementsOMFact: getDataSumByCodeMin(elem, 5410) || 0,
      structureElementsOMFactTotal: getDataSumByCodeMin(elem, 5432) || 0,
      // Структурные элементы не выполнено
      structureElementsFail: getDataSumByCodeMin(elem, 5408) || 0,
      structureElementsFailTotal: getDataSumByCodeMin(elem, 5430) || 0,
      structureElementsOMFail: getDataSumByCodeMin(elem, 5411) || 0,
      structureElementsOMFailTotal: getDataSumByCodeMin(elem, 5433) || 0,
      structureElementsVP: getDataSumByCodeMin(elem, 5526) || 0,
      structureElementsFP: getDataSumByCodeMin(elem, 5525) || 0,
      structureElementsKPM: getDataSumByCodeMin(elem, 5527) || 0,
    };
  });
