import { useMediaQuery, useTheme } from "@mui/material";
import { Layout } from "SRC/layouts";
import { CenterLayout } from "SRC/pages/Structure/layouts";
import themeConfig from "SRC/theme/theme";

export const Structure = () => {
  const theme = useTheme<typeof themeConfig>();
  const isLargeSize = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <Layout
      left={<></>}
      center={<CenterLayout theme={theme} isLargeSize={isLargeSize} />}
      right={<></>}
    />
  );
};
