import { isRowSecret } from "./is-row-secret";
import { sortColumn } from "./sort-column";

export const sortPerformanceColumn = (isOM: boolean) => {
  return (
    { original: rowA, subRows: subRowsA }: any,
    { original: rowB, subRows: subRowsB }: any,
    ...rest: any[]
  ) => {
    const [, isDesc] = rest;

    return sortColumn(
      {
        id: rowA.id,
        hasFeature: isRowSecret(rowA, subRowsA),
        value: isOM ? rowA.performanceOM : rowA.performance,
      },
      {
        id: rowB.id,
        hasFeature: isRowSecret(rowB, subRowsB),
        value: isOM ? rowB.performanceOM : rowB.performance,
      },
      isDesc
    );
  };
};
