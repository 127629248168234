import { ESources } from "SRC/constants";
import { getRoundValue } from "SRC/helpers/getRoundValue";
import { IPeriodKP } from "SRC/pages/Event/common/Checkpoints/Checkpoints";
import { ICashExecutionData } from "SRC/pages/Event/common/EventTabs/CashTab/utils/interfaces";
import { IDataSideBar, IStepsInfo } from "SRC/pages/Event/interfaces";
import { ICashExecution } from "SRC/redux/slices/event/slices/cashExecution";
import { IEventData } from "SRC/redux/slices/event/slices/data";

const digits: Record<string, number> = {
  млрд: 1000000,
  млн: 1000,
  тыс: 1,
};

export const convertByDigit = (value: number = 0, unit: string = "") => {
  const convertedValue = (value * 1000000) / (digits[unit] || 1);
  return getRoundValue(convertedValue, 2);
};

export const prepareData = (event: IEventData | null): IDataSideBar => {
  return {
    performance: {
      [ESources.OM]: event?.data[5308]?.sum,
      [ESources.FOIV]: event?.data[1359]?.sum,
    },
    corrections: {
      value: {
        worse: event?.data[1825]?.sum,
        better: event?.data[1823]?.sum,
      },
      time: {
        worse: event?.data[1826]?.sum,
        better: event?.data[1824]?.sum,
      },
    },
    event: {
      plan: event?.data[1352]?.sum,
      fact: {
        [ESources.OM]: event?.data[5307]?.sum,
        [ESources.FOIV]: event?.data[1358]?.sum,
      },
      atRisk: event?.data[1471]?.sum || 0,
      forecast: event?.data[1827]?.sum,
    },
    realization: {
      plan: event?.data[1366]?.sum,
      fact: event?.data[1367]?.sum,
    },
    financing: {
      plan: event?.data[1360]?.sum,
      fact: event?.data[1361]?.sum,
    },
  };
};

export interface ILevelEventList {
  [LEVEL_EVENT_TYPES.FP]: string;
  [LEVEL_EVENT_TYPES.VP]: string;
  [LEVEL_EVENT_TYPES.KPM]: string;
}
export enum LEVEL_EVENT_TYPES {
  FP = "ФП",
  VP = "ВП",
  KPM = "КПМ",
}

export enum EColors {
  RED = "R",
  GREEN = "G",
  GRAY = "Gr",
  DARK = "default",
}

export const selectClassNames = (value: string) => {
  return {
    "is-red": value === EColors.RED,
    "is-green": value === EColors.GREEN,
    "is-gray": value === EColors.GRAY,
    "is-dark": value === EColors.DARK,
  };
};

export const LEVEL_EVENT_LIST: ILevelEventList = {
  [LEVEL_EVENT_TYPES.FP]: "Федеральные проекты",
  [LEVEL_EVENT_TYPES.VP]: "Ведомственные проекты",
  [LEVEL_EVENT_TYPES.KPM]: "Комплексы процессных мероприятий",
};

export type TLevelEventList = keyof ILevelEventList;

export const searchNearlyMonth = (arr: IPeriodKP[], currPeriod: IPeriodKP) => {
  const initialValue = {
    index: 0,
    month: 0,
    year: 0,
  };

  return arr.reduce((a, c) => {
    if (currPeriod.month !== 0) {
      return a.month <= currPeriod.month && c.month >= currPeriod.month ? a : c;
    } else {
      return initialValue;
    }
  }, initialValue);
};

export const getDateByCheckpoints = (data: IStepsInfo[]): IPeriodKP[] => {
  return data.map((item, index) => {
    const date = item.datePlan.split(".");
    return {
      year: Number(date[2]),
      month: Number(date[1]),
      index: index,
    };
  });
};

export const splitPeriodArray = (str: string): IPeriodKP => {
  return {
    year: Number(str.slice(0, 4)),
    month: Number(str.slice(4)),
  };
};

export const createCashExecutionData = (
  data: ICashExecution[]
): ICashExecutionData[] =>
  data.map((item: ICashExecution) => {
    return {
      plan: item.data[1442]?.sum ?? null,
      fact: item.data[1443]?.sum ?? null,
      cash: item.data[1677]?.sum ?? null,
      infoCode: item.info.code,
      infoName: item.info.name,
      infoOrder: item.info.order,
      periodCode: item.period.code,
      date: item.period.date,
      period: item.period.period,
      periodEnd: item.period.periodEnd,
      periodStart: item.period.periodStart,
      type: item.period.year,
      pointCode: item.point.code,
      pointName: item.point.order,
      pointOrder: item.point.order,
    };
  });
