import React, { useCallback, useMemo, useState } from "react";
import { EExtensions } from "SRC/constants";
import { TExcelCreator } from "SRC/hooks/use-excel-creator/useFullReportCreator";
import { useReports } from "SRC/redux/slices/gosprogram/hooks/useReports";

import { DownloadButton } from "./DownloadButton";

interface IProgramExportButtonProps {
  saveFile: TExcelCreator;
  reportType: string;
  period: string;
  dataType: string;
  onClose(): void;
}

export const DownloadReportsButton = ({
  saveFile,
  reportType,
  period,
  dataType,
  onClose,
}: IProgramExportButtonProps) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const { getReportFile } = useReports();
  const isOm = dataType === "МЭР";

  const finishDownloading = () => {
    setIsDownloading(false);
  };

  const handleProgram = useCallback(
    (ext: EExtensions, fileName: string) => {
      saveFile({ ext, callback: finishDownloading, fileName });
    },
    [saveFile]
  );

  const reportParams = useMemo(
    () => ({
      tabName: reportType || "",
      periodCode: `${period}00` || "",
      isOm: Boolean(isOm) || false,
    }),
    [isOm, period, reportType]
  );

  const handleExportXLSX = useCallback(async () => {
    onClose();
    await setIsDownloading(true);
    getReportFile(reportParams);
    handleProgram(EExtensions.XLSX, reportParams.tabName);
  }, [getReportFile, handleProgram, reportParams]);

  let isButtonDisabled = Boolean(reportType && period && dataType);

  return (
    <>
      <DownloadButton
        onClick={handleExportXLSX}
        hasReports
        isButtonDisabled={isButtonDisabled}
      />
    </>
  );
};
