import { useMemo } from "react";
import { getInfoRowsSETasks } from "SRC/helpers/getInfoRows";
import { useTableExportParams } from "SRC/hooks";
import { selectors, useAppSelector } from "SRC/redux/hooks";
import { ITask } from "SRC/redux/slices/structureElement/api";
import {
  ITasks,
  useTasks,
} from "SRC/redux/slices/structureElement/hooks/useTasks";
import { IExportData } from "SRC/types";

export interface ITableDataItem {
  name?: string;
  id: number | string;
}

export interface ITaskTableItem extends ITableDataItem {
  type?: string;
  indicators?: number;
  events?: number;
}

export type TTaskExportItem = Omit<ITaskTableItem, "id">;

export const createTaskRow = ({ info, data }: ITask): ITaskTableItem => ({
  id: info.code,
  name: info.name,
  type: info.type,
  indicators: data[5399]?.sum || 0,
  events: data[5400]?.sum || 0,
});

export const createTasksTableData = (data: ITask[]): ITaskTableItem[] =>
  data.map((elem: ITask) => createTaskRow(elem));

export interface IUseExtendedTasks extends ITasks {
  filtered: string[];
  rows: ITaskTableItem[];
  exports: IExportData<TTaskExportItem>;
}

export const useExtendedTasks = (id: string | number): IUseExtendedTasks => {
  const tasks = useTasks(id);
  const createExportParams = useTableExportParams;
  const tableData = useMemo(() => createTasksTableData(tasks.data), [tasks]);
  const filters = useAppSelector(selectors.seeFilters);

  const filtered = useMemo(
    () =>
      tasks.data
        .filter((item) => {
          if (
            filters.selectedStructureElementType &&
            filters.selectedStructureElementType !== item.info.type
          )
            return false;
          return true;
        })
        .map((item) => item.info.code),
    [tasks, filters]
  );

  const rows = useMemo(
    () =>
      tableData
        .filter(({ id }) => filtered.includes(String(id)))
        .sort((a, b) => Number(a.id) - Number(b.id)),
    [tableData, filtered]
  );

  const exports = useMemo(() => {
    const download: TTaskExportItem[] = rows.map(({ id, ...item }) => item);

    return {
      csvData: download,
      fileName: "Структурный элемент. Список ОЗР и задач",
      infoRows: getInfoRowsSETasks(),
      ...createExportParams(download, "seTasks"),
    };
  }, [rows]);

  return {
    ...tasks,
    filtered,
    rows,
    exports,
  };
};
