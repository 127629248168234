export const eventsData = [
  {
    status: {
      Выполнено: 1,
      "В реализации": 0,
      "Не выполнено": 0,
    },
    index: "1",
    id: "fp8681927864",
    program:
      "Управление государственными финансами и регулирование финансовых рынков",
    structureElement: "КПМ",
    structureElementName: "Формирование бюджетной политики",
    structureElementTask:
      "Мониторинг в сфере межнациональных и межконфессиональных отношений",
    features: "Признак_ОКС",
    name:
      "Обеспечен уровень материального обеспечения неработяющих пенсионеров d" +
      "размере не ниже прожиточногоминимума пенсионера в субъекте РФ",
    unit: "шт.",
    plan: "123",
    fact: "25",
    performance: "10",
    influence: "0",
  },
  {
    status: {
      Выполнено: 0,
      "В реализации": 0,
      "Не выполнено": 1,
    },
    index: "2",
    id: "fp8681927864",
    program:
      "Управление государственными финансами и регулирование финансовых рынков",
    structureElement: "ФП",
    structureElementName:
      "Акселерация субъектов малого и среднего предпринимательства",
    structureElementTask: "Межконфессиональных отношений",
    features: "Признак_КТ",
    name:
      "Обеспечен уровень материального обеспечения неработяющих пенсионеров d" +
      "размере не ниже прожиточногоминимума пенсионера в субъекте РФ",
    unit: "шт.",
    plan: "123",
    fact: "25",
    performance: "10",
    influence: "0",
  },
  {
    status: {
      Выполнено: 0,
      "В реализации": 1,
      "Не выполнено": 1,
    },
    index: "3",
    id: "fp8681927864",
    program:
      "Управление государственными финансами и регулирование финансовых рынков",
    structureElement: "ФП",
    structureElementName: "Формирование бюджетной политики",
    structureElementTask: "Мониторинг",
    features: "Признак_КТ",
    name:
      "Обеспечен уровень материального обеспечения неработяющих пенсионеров d" +
      "размере не ниже прожиточногоминимума пенсионера в субъекте РФ",
    unit: "шт.",
    plan: "123",
    fact: "25",
    performance: "10",
    influence: "0",
  },
];

export const indicatorsSEData = [
  {
    index: "1",
    indicatorId: "1",
    structureElementType: "ФП",
    structureElementName:
      "Акселерация субъектов малого и среднего предпринимательства",
    structureElementsTasks:
      "Мониторинг в сфере межнациональных и межконфессиональных отношений",
    name: "Ежегодный рост выручки в расчёте на 1 субъекта МСП - юрисдическое лицо",
    unit: "шт.",
    implementationPeriod: "123",
    factFoiv: "25",
    factMayor: "25",
    plan: "10",
    planYear: "10",
    performance: "0",
    performanceOM: "0",
    id: "cpm1542",
    status: "Выполнено",
  },
  {
    index: "2",
    indicatorId: "2",
    structureElementType: "ВП",
    structureElementName:
      "Акселерация субъектов малого и среднего предпринимательства",
    structureElementsTasks:
      "Мониторинг в сфере межнациональных и межконфессиональных отношений",
    name: "Ежегодный рост выручки в расчёте на 1 субъекта МСП - юрисдическое лицо",
    unit: "шт.",
    implementationPeriod: "123",
    factFoiv: "25",
    factMayor: "25",
    plan: "10",
    planYear: "10",
    performance: "0",
    performanceOM: "0",
    id: "cpm1543",
    status: "Не достигнуто",
  },
];

export const indicatorsData = [
  {
    status: "Не достигнуто",
    gpCode: "1",
    program:
      "Управление государственными финансами и регулирование финансовых рынков",
    name: "Ежегодный рост выручки в расчёте на 1 субъекта МСП - юридическое лицо",
    structureElementsTasks:
      "Мониторинг в сфере межнациональных и межконфессиональных отношений",
    unit: "шт.",
    implementationPeriod: "123",
    factFoiv: "25",
    factMayor: "30",
    plan: "10",
    planTear: "10",
    performance: "0",
    performanceOM: "10",
  },
  {
    status: "Выполнено",
    gpCode: "2",
    program:
      "Управление государственными финансами и регулирование финансовых рынков",
    name: "Ежегодный рост выручки в расчёте на 1 субъекта МСП - юридическое лицо",
    unit: "шт.",
    implementationPeriod: "123",
    factFoiv: "25",
    factMayor: "30",
    plan: "10",
    planTear: "10",
    performance: "0",
    performanceOM: "10",
  },
  {
    status: "Выполнено",
    gpCode: "3",
    program:
      "Управление государственными финансами и регулирование финансовых рынков",
    name: "Ежегодный рост выручки в расчёте на 1 субъекта МСП - юридическое лицо",
    unit: "шт.",
    implementationPeriod: "123",
    factFoiv: "25",
    factMayor: "30",
    plan: "10",
    planTear: "10",
    performance: "0",
    performanceOM: "10",
  },
  {
    status: "Не начато",
    gpCode: "4",
    program:
      "Управление государственными финансами и регулирование финансовых рынков",
    name: "Ежегодный рост выручки в расчёте на 1 субъекта МСП - юридическое лицо",
    unit: "шт.",
    implementationPeriod: "123",
    factFoiv: "25",
    factMayor: "30",
    plan: "10",
    planTear: "10",
    performance: "0",
    performanceOM: "10",
  },
];
