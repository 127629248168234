import { Button, Grid } from "@mui/material";
import { CheckpointArrow } from "ASSETS/svg/arrow";
import classnames from "classnames";
import { useEffect, useMemo, useState } from "react";
import React from "react";
import { IncidentReport } from "SRC/components/IncidentReport";
import { OverlayBlock } from "SRC/components/OverlayBlock";
import { CheckpointsButtons } from "SRC/pages/Event/common/Checkpoints/CheckpointsButtons/CheckpointsButtons";
import Status from "SRC/pages/Event/common/Checkpoints/Status/Status";
import { StepComponent } from "SRC/pages/Event/common/Checkpoints/Step/Step";
import { IStepsInfo } from "SRC/pages/Event/interfaces";
import {
  getDateByCheckpoints,
  searchNearlyMonth,
  splitPeriodArray,
} from "SRC/pages/Event/util/utils";
import { useControlPoints } from "SRC/redux/slices/event/hooks/useControlPoints";
import { useGlobalFilters } from "SRC/redux/slices/global/hooks/useFilters";

import {
  BoxConnector,
  BoxStepper,
  CheckpointWrapper,
  css,
} from "./Checkpoint.styled";

export interface IPeriodKP {
  index?: number;
  year: number;
  month: number;
}

export const Checkpoints = () => {
  const { period } = useGlobalFilters();
  const { items: controlPoints, fetching } = useControlPoints();
  const [currentStep, setCurrenStep] = useState(0);
  const [openName, setOpenName] = useState<boolean>(false);

  const currPeriod = useMemo(() => {
    return splitPeriodArray(period.filterValue);
  }, [period]);

  const checkpointsData = useMemo<IStepsInfo[]>(() => {
    return controlPoints
      .map(({ data, info }) => {
        return {
          //1380 - Плановая дата
          datePlan: String(data[1380]?.sum || "0"),
          // 1381 - Фактическая дата
          dateFinish: String(data[1381]?.text || data[1381]?.sum || "0"),
          namePoint: info.name,
          // 1504 - Цвет
          statusPoint: String(data[1504]?.sum || "default"),
          SORT_ORDER: Number(info.order),
          // 1830 - Кол-во дней опережения/запоздания
          deviation: data[1830]?.sum ? data[1830]?.sum : "0",
          // 1831 - Кол-во дней опережения/запоздания, цвет
          deviationColor: String(data[1831]?.sum || ""),
        };
      })
      .sort((a, b) => a.SORT_ORDER - b.SORT_ORDER);
  }, [controlPoints, period]);

  //массив с информацией о дате каждой КТ
  const arrayOfDateCheckpoints = useMemo<IPeriodKP[]>(() => {
    return getDateByCheckpoints(checkpointsData);
  }, [checkpointsData]);

  //обновленная позиция (индекс) КТ с учетом выбранного месяца и года
  const updateCurrKP = useMemo<IPeriodKP>(() => {
    return searchNearlyMonth(arrayOfDateCheckpoints, currPeriod);
  }, [arrayOfDateCheckpoints]);

  //обновление позиции степпера Контрольных точек при изменении периода
  useEffect(() => {
    if (currPeriod.month) {
      //позиция ближайшая к выбранному периоду
      setCurrenStep(updateCurrKP.index || 0);
    } else {
      // начальная позиция при первой загрузки страницы (выбран только год)
      setCurrenStep(controlPoints.length - 3);
    }
  }, [currPeriod, updateCurrKP]);

  const handleNext = () => {
    if (currentStep < checkpointsData.length)
      setCurrenStep((state) => state + 1);
  };

  const handleBack = () => {
    if (currentStep > 0) setCurrenStep((state) => state - 1);
  };

  const steps = useMemo(
    () =>
      checkpointsData.map((label, index) => (
        <StepComponent
          key={index}
          label={label}
          openName={openName}
          setOpenName={setOpenName}
        />
      )),
    [checkpointsData, openName]
  );
  const isPrevBtnDisabled = currentStep === 0;
  const isNextBtnDisabled = currentStep === checkpointsData.length - 3;

  return (
    <OverlayBlock isFetching={fetching} hasData>
      <CheckpointWrapper>
        <CheckpointsButtons />
        <IncidentReport className="inner-wrapper">
          <div>
            <div
              className={classnames("stepper-container", {
                "is-open": openName,
              })}
            >
              <BoxStepper
                activeStep={4}
                connector={
                  <div className="connector-wrapper">
                    <BoxConnector />
                  </div>
                }
                sx={{ right: `calc(33.33% * ${currentStep})` }}
                alternativeLabel
              >
                {steps}
              </BoxStepper>
              <Button
                className="button"
                onClick={handleBack}
                style={{ left: "1rem" }}
                disabled={isPrevBtnDisabled}
              >
                <CheckpointArrow
                  className={classnames("arrow-left", {
                    "is-disabled": isPrevBtnDisabled,
                  })}
                />
              </Button>
              <Button
                className="button"
                onClick={handleNext}
                style={{ right: "1rem" }}
                disabled={isNextBtnDisabled}
              >
                <CheckpointArrow
                  className={classnames("arrow-right", {
                    "is-disabled": isNextBtnDisabled,
                  })}
                />
              </Button>
              <div
                className={classnames("checkpoint-stepper-line", {
                  "control-points": Boolean(controlPoints.length),
                })}
              />
            </div>
            <Grid container sx={css.legendContainer}>
              <Grid item sx={{ display: "flex", gap: "1rem" }}>
                <p>Всего</p>
                <p className="white">{checkpointsData.length}</p>
              </Grid>
              <Grid item sx={css.legendItemContainer}>
                <Status data={checkpointsData} value="G" />
              </Grid>
              <Grid item sx={css.legendItemContainer}>
                <Status data={checkpointsData} value="R" />
              </Grid>
              <Grid item sx={css.legendItemContainer}>
                <Status data={checkpointsData} value="Gr" />
              </Grid>
              <Grid item sx={css.legendItemContainer}>
                <Status data={checkpointsData} value="default" />
              </Grid>
            </Grid>
          </div>
        </IncidentReport>
      </CheckpointWrapper>
    </OverlayBlock>
  );
};
