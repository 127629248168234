import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IParsedItem, parseOlapdata, TDecoder } from "CORE/utils";
import { isContainedArray } from "SRC/helpers/dataTesters";
import { getStructureElementsTasks } from "SRC/redux/slices/gosprogram/api/structure-elements";
import { TStateDefault } from "SRC/types";

const decoder: TDecoder = {
  info: {
    code: 5397,
    dimensions: {
      name: "NAME",
      code: "SE_TASK_CODE",
      seCode: "SE_CODE",
    },
  },
  se: {
    code: 5396,
    dimensions: {
      name: "NAME",
      code: "SE_CODE",
      type: "SE_TYPE",
      spc: "StateProgramCode",
    },
  },
};

export interface IStructureElementsTaskInfo {
  name: string;
  code: string;
  seCode: string;
}

export interface IStructureElementsInfo {
  name: string;
  type: string;
  code: string;
  spc: string;
}

export interface IStructureElementsTask extends IParsedItem {
  info: IStructureElementsTaskInfo;
  se: IStructureElementsInfo;
}

export interface IStructureElementsTasksState extends TStateDefault {
  items: IStructureElementsTask[];
}

export const extraActions = {
  get: createAsyncThunk(
    "structureElements/tasks/get",
    async (gp_code: string | number) => {
      return await getStructureElementsTasks(gp_code);
    }
  ),
};

const initialState: IStructureElementsTasksState = {
  items: [],
  fetching: false,
  fetched: false,
  error: null,
};

const slice = createSlice({
  name: "structureElements/tasks",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActions.get.pending, (state) => {
        state.fetching = true;
        state.fetched = false;
      })
      .addCase(extraActions.get.fulfilled, (state, action) => {
        const parsed = parseOlapdata(
          // @ts-ignore
          action.payload.data,
          decoder
        ) as unknown as IStructureElementsTask[];
        state.items = (
          isContainedArray(parsed) ? parsed : []
        ) as IStructureElementsTask[];
        state.fetching = false;
        state.fetched = true;
      })
      .addCase(extraActions.get.rejected, (state, action) => {
        state.error = action.error;
        state.fetching = false;
        state.fetched = true;
      });
  },
});

export const actions = {
  ...slice.actions,
  ...extraActions,
};

export default slice;
