import { styled } from "@mui/material";

interface ICarouselWrapperProps {
  transform: number;
  transition: number;
}

export const Wrapper = styled("div")<ICarouselWrapperProps>(
  ({ theme, transform, transition }) => {
    return {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      position: "relative",

      "> .carousel": {
        display: "flex",
        width: "100%",

        "> .carousel-container": {
          overflow: "hidden",
          height: "100%",
          width: "100%",

          "> .inner-container": {
            display: "inline-flex",
            marginLeft: "-0.5rem",
            width: "100%",
            height: "100%",
            transform: `translateX(-${transform}%)`,
            transition: `transform ${transition}s`,
            whiteSpace: "nowrap",
          },
        },
      },
    };
  }
);
