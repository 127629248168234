export const DIMENSIONS = [
  {
    id: "1562",
    includeItems: true,
    includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
  },
  {
    id: "1835",
    includeItems: true,
    includeAttributesByCodes: ["Сфера", "Министерство", "Вице-премьер"],
  },
];
