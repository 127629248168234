import { Box, SxProps, Tooltip, Typography, useTheme } from "@mui/material";
import ReactECharts from "echarts-for-react";
import React, { useMemo } from "react";
import { TooltipText } from "SRC/components/TooltipText/TooltipText";
import { simpleNumberByUnit } from "SRC/helpers/simpleNumberByUnit";
import { thousandSeparator } from "SRC/helpers/thousandSeparator";
import { IIndicatorsInfo } from "SRC/pages/Program/common/GoalsTab/interfaces";

import { css, Wrapper } from "./ChartView.styled";
import getOptions from "./util/options";

interface IChartViewProps {
  indicatorsInfo?: IIndicatorsInfo;
  plan: number;
  fact: number;
  indicatorName: string;
  unit: string;
  chartPlanData: (number | null)[];
  chartFactData: (number | null)[];
  yearly: boolean | null;
}

const ChartView = ({
  plan,
  fact,
  indicatorName,
  unit,
  chartPlanData,
  chartFactData,
}: IChartViewProps) => {
  const theme = useTheme();
  const validPlanData = chartPlanData?.map((it) => {
    if (it === null) {
      return 0;
    }
    return it;
  });
  const planData = useMemo<number[]>(
    () => validPlanData?.filter((item: number) => item),
    [chartPlanData]
  );
  const factData = useMemo<number[]>(
    () =>
      chartFactData
        .map((it) => {
          if (it === null) {
            return 0;
          }
          return it;
        })
        ?.filter((item: number) => item),
    [chartFactData]
  );

  const maxPlan = Math.max.apply(null, planData);
  const maxFact = Math.max.apply(null, factData);
  const maxValue = Math.max(maxPlan, maxFact);

  return (
    <Wrapper>
      <div className="infoContainer">
        <div className="title">
          <TooltipText hint={indicatorName} variant="h3" lines={2}>
            <div className="indicatorName">{indicatorName}</div>
          </TooltipText>
          <div className="percentSmallText">{unit}</div>
        </div>
        <div className="indicators">
          <div className="indicatorItem">
            <Box sx={css.circle(theme.palette.primary.contrastText)} />
            <div className="smallText">План</div>
            <Tooltip title={thousandSeparator(String(plan))}>
              <Typography
                sx={
                  css.text(theme, theme.palette.primary.contrastText) as SxProps
                }
              >
                {simpleNumberByUnit(plan)}
              </Typography>
            </Tooltip>
          </div>
          <div className="indicatorItem">
            <Box sx={css.circle(theme.palette.primary.main)} />
            <div className="smallText">Факт</div>
            <Tooltip title={thousandSeparator(String(fact))}>
              <Typography
                sx={css.text(theme, theme.palette.main.blue) as SxProps}
              >
                {simpleNumberByUnit(fact)}
              </Typography>
            </Tooltip>
          </div>
        </div>
      </div>
      <ReactECharts
        style={css.chart}
        option={getOptions(validPlanData, chartFactData, maxValue, unit, theme)}
      />
    </Wrapper>
  );
};

export default ChartView;
