import { Button, Stack, SxProps, useMediaQuery, useTheme } from "@mui/material";
import React, {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from "react";
import { Column, TableInstance } from "react-table";
import { AchievementsTile } from "SRC/components/AchievementsTile/AchievementsTile";
import { TableModal } from "SRC/components/FullPageTableModal/FullPageTableModal";
import { PrioritySummaryWidget } from "SRC/components/PrioritySummaryWidget/PrioritySummaryWidget";
import SelectWithSearch, {
  IOption,
} from "SRC/components/SelectWithSearch/SelectWithSearch";
import SelectWithSearchGP from "SRC/components/SelectWithSearch/SelectWithSearchGP";
import SelectWithSearchVP from "SRC/components/SelectWithSearch/SelectWithSearchVP";
import Table from "SRC/components/Table";
import { createGpRowsTableData } from "SRC/hooks/summaryTable/utils/mainData/mainDataGP";
import { getKeyPrioritiesColumns } from "SRC/pages/Priorities/common/PrioritiesComplexGrid/KeyPrioritiesTab/tableColumns";
import { usePrioritiesTableData } from "SRC/pages/Priorities/tableData";
import { topData } from "SRC/pages/Priorities/utils/topData";
import { widgetPrioritiesData } from "SRC/pages/Priorities/utils/widgetPrioritiesData";
import { TableSearch } from "SRC/pages/Program/common/EventsTab/TableSearch/TableSearch";
import {
  IGlobalFilters,
  useGlobalFilters,
} from "SRC/redux/slices/global/hooks/useFilters";
import { useFilters } from "SRC/redux/slices/priorities/hooks/useFilters";
import {
  IPremiers,
  usePremiers,
} from "SRC/redux/slices/priorities/hooks/usePremiers";
import { usePrograms } from "SRC/redux/slices/priorities/hooks/usePrograms";
import { CustomRowProps } from "SRC/types/data/data";

import { css, TabWrapper } from "../PrioritiesComplexGrid.styled";

interface IGeneralInfoTabProps {
  posTable?: string;
  forwardRef?: MutableRefObject<HTMLElement | undefined>;
  onScroll?: (scroll: any) => void;
  isModalOpen?: boolean;
  setModalOpen?: (value: boolean) => void;
  instance: TableInstance | undefined;
  setInstance: Dispatch<SetStateAction<TableInstance<{}> | undefined>>;
}

export const staticColumns = [
  "status-icon",
  "id",
  "icon",
  "govProgram",
  "vicePremier",
  "foiv",
];
const sortedColumns = ["Уровень достижения ГП", "Уровень достижения КП"];

const RenderTable: React.FC<IGeneralInfoTabProps> = ({
  forwardRef,
  onScroll,
  isModalOpen,
  setModalOpen,
  instance,
  setInstance,
}) => {
  const { sources }: IGlobalFilters = useGlobalFilters();
  const { items: vpItems }: IPremiers = usePremiers();
  const { items: programs } = usePrograms();
  const { priorityData } = usePrioritiesTableData();
  const {
    setSearchRows,
    setSelectedProgram,
    vp,
    program,
    filteredVPs,
    setSelectedVP,
  } = useFilters();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));

  const [searchValue, setSearchValue] = useState<string>("");

  const setTableFilter = useCallback(
    (value: string) => {
      instance?.setGlobalFilter(value);
      setSearchValue(value);
    },
    [instance]
  );

  const handleClearFilters = () => {
    setTableFilter("");
    setSelectedVP("");
    setSelectedProgram("");
  };

  const columns: Column[] = useMemo(() => {
    return getKeyPrioritiesColumns(sources.OM);
  }, [sources.OM]);

  const tableHeight: string | undefined = useMemo(() => {
    if (isModalOpen) return;
    return matches ? css.tableHeightLong : css.tableHeight;
  }, [isModalOpen, matches]);

  const onChangeFilterRows = useCallback(
    (rows: CustomRowProps[]) => {
      setSearchRows(createGpRowsTableData(rows, sources.OM));
    },
    [sources.OM]
  );

  const selectStyle = useMemo(() => css.selectorFilter(theme), [theme]);

  const optionsVP: IOption[] = useMemo(
    () =>
      vpItems
        .filter((item) => filteredVPs.includes(item.info.VP_CODE))
        .map((item) => ({
          value: item.info.VP_SHORT_NAME,
          label: item.info.VP_SHORT_NAME,
        })),
    [vpItems, filteredVPs]
  );

  const optionsGP: IOption[] = useMemo(
    () =>
      programs.map((program) => {
        return {
          value: program.info.name,
          label: program.info.shortName,
        };
      }),
    [programs]
  );

  return (
    <TabWrapper>
      <div className="priority-summary">
        <PrioritySummaryWidget data={widgetPrioritiesData} />
      </div>
      <div className="achievements-tile">
        {topData.map((item, key) => (
          <AchievementsTile
            data={item}
            withDarkBgr={true}
            key={key}
            fetching={false}
          />
        ))}
      </div>
      <div className="search-select-container">
        <div className="search">
          <TableSearch value={searchValue} onChange={setTableFilter} />
        </div>
        <SelectWithSearch
          options={[
            {
              value: "",
              label: "Ответственный исполнитель",
            },
          ]}
          staticLabel={false}
          label="Ответственный исполнитель"
        />
        <SelectWithSearchVP
          value={vp}
          label="Вице-премьер"
          staticLabel={false}
          style={selectStyle}
          onChange={setSelectedVP}
          options={optionsVP}
        />
        <SelectWithSearchGP
          value={program}
          label="Государственная программа"
          staticLabel={false}
          onChange={setSelectedProgram}
          options={optionsGP}
          style={selectStyle}
        />
        <Button sx={css.filterButton as SxProps} onClick={handleClearFilters}>
          Сбросить фильтры
        </Button>
      </div>
      <div className="tables-wrapper">
        <Table
          data={priorityData}
          columns={columns}
          sortedColumns={sortedColumns}
          tableHeight={tableHeight}
          forwardRef={forwardRef}
          onScroll={onScroll}
          noDataCondition={[].length === 0}
          sticky
          getInstance={setInstance}
          classes={{
            tr: "table-row",
            tbody: "table-body",
            tfoot: "table-footer",
          }}
          onFilterRows={onChangeFilterRows}
        />
      </div>
    </TabWrapper>
  );
};

export const KeyPrioritiesTab = (props: IGeneralInfoTabProps) => {
  const { isModalOpen, setModalOpen } = props;

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <Stack style={css.tabContainer}>
      <RenderTable {...props} />
      {!matches && (
        <TableModal
          open={isModalOpen}
          setOpen={setModalOpen}
          tableName="Ключевые приоритеты"
        >
          <RenderTable {...props} />
        </TableModal>
      )}
    </Stack>
  );
};
