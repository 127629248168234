import {
  Box,
  SxProps,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { MutableRefObject, useMemo } from "react";
import { Column, useGlobalFilter, useSortBy, useTable } from "react-table";
import { BaseTable } from "SRC/components/Table";
import { ITableDataItem } from "SRC/hooks/summaryTable/interfaces";
import { getSatisfactionColumns } from "SRC/pages/Summary/common/DasboardTables/SatisfactionTable/satisfactionData";
import {
  IFilters,
  useFilters,
} from "SRC/redux/slices/summary/hooks/useFilters";

import { css } from "../DashboardTables.styled";

interface IGeneralInfoTabProps {
  forwardRef: MutableRefObject<HTMLElement | undefined>;
  onScroll: (scroll: any) => void;
  tableData: ITableDataItem[];
  setSortBy: (sortBy: any) => void;
  loading?: boolean;
}

export const SatisfactionTable = ({
  forwardRef,
  onScroll,
  tableData,
  setSortBy,
  loading,
}: IGeneralInfoTabProps) => {
  const theme = useTheme();
  const { program, setSelectedProgram }: IFilters = useFilters();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));

  // @ts-ignore
  const columns: Column[] = useMemo(() => getSatisfactionColumns(), []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    setSortBy: baseSortBy,
  } = useTable(
    {
      columns,
      data: tableData,
    },
    useGlobalFilter,
    useSortBy
  );

  return (
    <Box sx={css.tablesWrapper as SxProps}>
      <Box>
        <Typography sx={css.title as SxProps}>
          Удовлетворенность населения
        </Typography>
      </Box>
      <BaseTable
        loading={loading}
        columns={columns}
        data={tableData}
        sortedColumns={["Удовлетворенность населения"]}
        getTableProps={getTableProps}
        getTableBodyProps={getTableBodyProps}
        headerGroups={headerGroups}
        footerGroups={footerGroups}
        rows={rows}
        prepareRow={prepareRow}
        setSortBy={(sortBy) => {
          setSortBy(sortBy);
          baseSortBy(sortBy);
        }}
        tableHeight={css.tableHeight}
        selectedRow={program}
        setSelectedRow={setSelectedProgram}
        wrapSort={matches}
        scrollHide={true}
        forwardRef={forwardRef}
        onScroll={onScroll}
        singleTable={true}
      />
    </Box>
  );
};
