import { Theme } from "@mui/material";
import * as CSS from "csstype";
import theme from "SRC/theme";

export const css = {
  container: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "100%",
    width: "100%",
    height: "100%",
    flex: 1,
    "& .table-body": {
      ".status-icon": {
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        maxWidth: 0,

        "> *": {
          position: "relative",
          left: "-0.5rem",
          minWidth: "1.2rem",
          minHeight: "1.2rem",
        },
      },
    },
    "& .table-footer, & .table-body": {
      ".flex-container": {
        display: "flex",
        alignItems: "center",
        gap: "0.5rem",
      },
    },
  },
  head: (
    borderStyle: CSS.Property.BorderBlockStyle = "none",
    withBgr?: boolean
  ) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    gap: "2rem",
    paddingBottom: "0px",
    background: (theme: Theme) =>
      withBgr ? theme.palette.complementary.darkBlue : "transparent",
    backgroundImage: (theme: Theme) =>
      borderStyle === "solid"
        ? `linear-gradient(to right, ${theme.palette.background.opacityWhite} 100%, rgba(255,255,255,0) 0%)`
        : borderStyle !== "none"
        ? "linear-gradient(to right, #2E364A 33%, rgba(255,255,255,0) 0%)"
        : "none",
    backgroundPosition: "bottom",
    backgroundSize: "1rem 0.1rem",
    backgroundRepeat: "repeat-x",
    overflowX: "auto",
    "::-webkit-scrollbar": {
      height: "0.5rem",
    },
    "::-webkit-scrollbar-thumb": {
      borderRadius: "0.5rem",
      border: "unset",
    },
  }),
  tabs: (contained?: boolean) => ({
    flex: `${contained ? 0 : 1} 0 auto`,
    backgroundColor: (theme: Theme) =>
      contained ? theme.palette.background.paper : "none",
    borderRadius: contained ? "1rem" : "none",
    padding: contained ? "4px" : "none",

    "& .MuiTabs-indicator": {
      height: contained ? "100%" : "4px",
      borderRadius: contained ? "0.8rem" : "3px 3px 0 0",
    },
  }),
  tab: (active?: boolean, stretch?: boolean) => ({
    flex: stretch ? 1 : "0 0 auto",
  }),
  label: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "0.6rem",
  },
  labelNumber: (borderColor: string, innerTab: boolean, isSmall: boolean) => {
    const {
      complementary: { darkBlue, darkBlueLight },
    } = theme.palette;

    return {
      border: `2px solid ${borderColor || "transparent"}`,
      padding: "0px 1rem",
      borderRadius: (theme: Theme) => theme.shape.borderRadius,
      display: "flex",
      alignItems: "baseline",
      background: isSmall ? darkBlueLight : darkBlue,
      height: "2rem",
      fontWeight: isSmall ? "normal" : "bold",
      fontSize: innerTab ? "0.9rem" : "1.25rem",
      lineHeight: "1.7rem",
    };
  },
  additional: {
    display: "flex",
    flex: 1,
    maxHeight: "100%",
  },
  actions: {
    display: "flex",
    flexWrap: "nowrap",
    gap: "0.5rem",
    flex: "0 0 auto",
    alignItems: "center",
  },
};
