import { Box } from "@mui/material";
import { DspProgram, SecretProgram, WarningTriangle } from "ASSETS/svg";
import React, { Fragment, useMemo } from "react";
import { OverlayProgressBar } from "SRC/components/ProgressBar";
import { SecretSwitcher } from "SRC/components/SecretSwitcher";
import { getDataSumByCodeGP } from "SRC/constants/dataCodes";
import { ESources, sourceSettings } from "SRC/constants/globals";
import { getPercentValue } from "SRC/helpers/getPercentValue";
import Tiles from "SRC/pages/ProgramsBoard/common/Tiles/Tiles";
import { selectors, useAppSelector } from "SRC/redux/hooks";
import { useGetPrograms, useGetSummary } from "SRC/redux/stores/ProgramsBoard";

import { css } from "./center-layout.styled";

export const CenterLayout = () => {
  return <Fragment>Список министерств</Fragment>;
};
