import { Grid } from "@mui/material";
import React from "react";

import { GridContainer } from "./Layout.styles";

interface ILayout {
  head?: React.ReactNode;
  description?: React.ReactNode;
  summary?: React.ReactNode;
  content?: React.ReactNode;
  foot?: React.ReactNode;
}

const Layout: React.FC<ILayout> = ({
  head = null,
  description = null,
  summary = null,
  content = null,
  foot = null,
}) => (
  <GridContainer container gap={1}>
    <Grid item xs={12}>
      {head}
    </Grid>
    <Grid className="row" item xs={12}>
      {description}
    </Grid>
    <Grid className="rowContainer" container item>
      <Grid className="row" item xs={3}>
        {summary}
      </Grid>
      <Grid className="row" item xs={9}>
        {content}
      </Grid>
    </Grid>
    <Grid className="row" item xs={12}>
      {foot}
    </Grid>
  </GridContainer>
);

export default Layout;
