import { Stack, styled } from "@mui/material";

export const StackWrapper = styled(Stack)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  ".body": {
    display: "flex",
    gap: "3rem",
  },
  ".premiers": {
    display: "flex",
    flexDirection: "column",
    flex: "0 0 42%",
    gap: "1rem",

    "& > .premiers-title": {
      display: "flex",
      alignItems: "center",
      gap: "0.8rem",
      fontSize: "1.25rem",
    },
  },
  ".divider": {
    width: `1px`,
    backgroundColor: theme.palette.complementary.lightWhite,
  },
}));
