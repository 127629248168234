import {
  getGovProgramsWscols,
  getMinistriesWscols,
  getProgramEventsWscols,
  getProgramIndicatorsAnalyticsGPWscols,
  getProgramIndicatorsGPWscols,
  getProgramIndicatorsSEWscols,
  getProgramTasksSEWscols,
  getSETasksWscols,
  getStructureGPWscols,
  getSummaryMinistriesWscols,
  getSummaryProgramsWscols,
  getSummaryVicePremiersWscols,
  getVicePremiersWscols,
} from "SRC/helpers/getTableWscols";
import { Range } from "xlsx";

const govProgramsHeadings = [
  {
    id: " ",
    govProgram: " ",
    indicatorsPlan: " ",
    indicatorsDone: " ",
    events: " ",
    total: " ",
    atRisk: " ",
    performance: " ",
  },
  {
    id: " ",
    govProgram: " ",
    indicatorsPlan: " ",
    indicatorsDone: " ",
    events: " ",
    total: " ",
    atRisk: " ",
    performance: " ",
  },
  {
    id: " ",
    govProgram: " ",
    indicatorsPlan: " ",
    indicatorsDone: " ",
    events: " ",
    total: " ",
    atRisk: " ",
    performance: " ",
  },
  {
    id: " ",
    govProgram: " ",
    indicatorsPlan: " ",
    indicatorsDone: " ",
    events: " ",
    total: " ",
    atRisk: " ",
    performance: " ",
  },
  {
    id: " ",
    govProgram: " ",
    indicatorsPlan: " ",
    indicatorsDone: " ",
    events: " ",
    total: " ",
    atRisk: " ",
    performance: " ",
  },
  {
    id: " ",
    govProgram: " ",
    indicatorsPlan: " ",
    indicatorsDone: " ",
    events: " ",
    total: " ",
    atRisk: " ",
    performance: " ",
  },
  {
    id: " ",
    govProgram: " ",
    indicatorsPlan: " ",
    indicatorsDone: " ",
    events: " ",
    total: " ",
    atRisk: " ",
    performance: " ",
  },
  {
    id: " ",
    govProgram: " ",
    indicatorsPlan: " ",
    indicatorsDone: " ",
    events: " ",
    total: " ",
    atRisk: " ",
    performance: " ",
  },
  {
    id: "Код ГП",
    govProgram: "Госпрограмма",
    indicatorsPlan: "Показатели",
    indicatorsDone: " ",
    events: "Мероприятия",
    total: " ",
    atRisk: "",
    performance: "Уровень достижения",
  },
  {
    id: " ",
    govProgram: " ",
    indicatorsPlan: "План",
    indicatorsDone: "Факт",
    events: "План",
    total: "Факт",
    atRisk: "Под риском",
    performance: " ",
  },
];
const govProgramsMergeCells = [
  { s: { r: 0, c: 2 }, e: { r: 0, c: 18 } },
  { s: { r: 1, c: 2 }, e: { r: 1, c: 18 } },
  { s: { r: 2, c: 2 }, e: { r: 2, c: 18 } },
  { s: { r: 3, c: 2 }, e: { r: 3, c: 18 } },
  { s: { r: 4, c: 2 }, e: { r: 4, c: 18 } },
  { s: { r: 5, c: 2 }, e: { r: 5, c: 18 } },
  { s: { r: 6, c: 2 }, e: { r: 6, c: 18 } },
  { s: { r: 8, c: 0 }, e: { r: 9, c: 0 } },
  { s: { r: 8, c: 1 }, e: { r: 9, c: 1 } },
  { s: { r: 8, c: 2 }, e: { r: 8, c: 3 } },
  { s: { r: 8, c: 4 }, e: { r: 8, c: 6 } },
  { s: { r: 8, c: 7 }, e: { r: 9, c: 7 } },
];

const vicePremiersHeadings = [
  {
    vicePremierName: " ",
    vicePremierPhoto: " ",
    numOfPrograms: " ",
    indicatorsPlan: " ",
    indicatorsDone: " ",
    events: " ",
    plan: " ",
    fact: " ",
    performance: " ",
  },
  {
    vicePremierName: " ",
    vicePremierPhoto: " ",
    numOfPrograms: " ",
    indicatorsPlan: " ",
    indicatorsDone: " ",
    events: " ",
    plan: " ",
    fact: " ",
    performance: " ",
  },
  {
    vicePremierName: " ",
    vicePremierPhoto: " ",
    numOfPrograms: " ",
    indicatorsPlan: " ",
    indicatorsDone: " ",
    events: " ",
    plan: " ",
    fact: " ",
    performance: " ",
  },
  {
    vicePremierName: " ",
    vicePremierPhoto: " ",
    numOfPrograms: " ",
    indicatorsPlan: " ",
    indicatorsDone: " ",
    events: " ",
    plan: " ",
    fact: " ",
    performance: " ",
  },
  {
    vicePremierName: " ",
    vicePremierPhoto: " ",
    numOfPrograms: " ",
    indicatorsPlan: " ",
    indicatorsDone: " ",
    events: " ",
    plan: " ",
    fact: " ",
    performance: " ",
  },
  {
    vicePremierName: " ",
    vicePremierPhoto: " ",
    numOfPrograms: " ",
    indicatorsPlan: " ",
    indicatorsDone: " ",
    events: " ",
    plan: " ",
    fact: " ",
    performance: " ",
  },
  {
    vicePremierName: " ",
    vicePremierPhoto: " ",
    numOfPrograms: " ",
    indicatorsPlan: " ",
    indicatorsDone: " ",
    events: " ",
    plan: " ",
    fact: " ",
    performance: " ",
  },
  {
    vicePremierName: " ",
    vicePremierPhoto: " ",
    numOfPrograms: " ",
    indicatorsPlan: " ",
    indicatorsDone: " ",
    events: " ",
    plan: " ",
    fact: " ",
    performance: " ",
  },
  {
    vicePremierName: "Вице-премьер",
    vicePremierPhoto: "Фото",
    numOfPrograms: "Госпрограммы",
    indicatorsPlan: "Показатели",
    indicatorsDone: " ",
    events: "Мероприятия",
    plan: " ",
    fact: " ",
    performance: "Уровень достижения",
  },
  {
    vicePremierName: " ",
    vicePremierPhoto: " ",
    numOfPrograms: " ",
    indicatorsPlan: "План",
    indicatorsDone: "Выполнено",
    events: "План",
    plan: "Факт",
    fact: "Под риском",
    performance: " ",
  },
];
const vicePremiersMergeCells = [
  { s: { r: 0, c: 2 }, e: { r: 0, c: 18 } },
  { s: { r: 1, c: 2 }, e: { r: 1, c: 18 } },
  { s: { r: 2, c: 2 }, e: { r: 2, c: 18 } },
  { s: { r: 3, c: 2 }, e: { r: 3, c: 18 } },
  { s: { r: 4, c: 2 }, e: { r: 4, c: 18 } },
  { s: { r: 5, c: 2 }, e: { r: 5, c: 18 } },
  { s: { r: 6, c: 2 }, e: { r: 6, c: 18 } },
  { s: { r: 8, c: 0 }, e: { r: 9, c: 0 } },
  { s: { r: 8, c: 1 }, e: { r: 9, c: 1 } },
  { s: { r: 8, c: 2 }, e: { r: 9, c: 2 } },
  { s: { r: 8, c: 3 }, e: { r: 8, c: 4 } },
  { s: { r: 8, c: 5 }, e: { r: 8, c: 7 } },
  { s: { r: 8, c: 8 }, e: { r: 9, c: 8 } },
];

const ministriesHeadings = [
  {
    ministryName: " ",
    numOfPrograms: " ",
    indicatorsPlan: " ",
    indicatorsFact: " ",
    plan: " ",
    fact: " ",
    atRisk: " ",
    performance: " ",
  },
  {
    ministryName: " ",
    numOfPrograms: " ",
    indicatorsPlan: " ",
    indicatorsFact: " ",
    plan: " ",
    fact: " ",
    atRisk: " ",
    performance: " ",
  },
  {
    ministryName: " ",
    numOfPrograms: " ",
    indicatorsPlan: " ",
    indicatorsFact: " ",
    plan: " ",
    fact: " ",
    atRisk: " ",
    performance: " ",
  },
  {
    ministryName: " ",
    numOfPrograms: " ",
    indicatorsPlan: " ",
    indicatorsFact: " ",
    plan: " ",
    fact: " ",
    atRisk: " ",
    performance: " ",
  },
  {
    ministryName: " ",
    numOfPrograms: " ",
    indicatorsPlan: " ",
    indicatorsFact: " ",
    plan: " ",
    fact: " ",
    atRisk: " ",
    performance: " ",
  },
  {
    ministryName: " ",
    numOfPrograms: " ",
    indicatorsPlan: " ",
    indicatorsFact: " ",
    plan: " ",
    fact: " ",
    atRisk: " ",
    performance: " ",
  },
  {
    ministryName: " ",
    numOfPrograms: " ",
    indicatorsPlan: " ",
    indicatorsFact: " ",
    plan: " ",
    fact: " ",
    atRisk: " ",
    performance: " ",
  },
  {
    ministryName: " ",
    numOfPrograms: " ",
    indicatorsPlan: " ",
    indicatorsFact: " ",
    plan: " ",
    fact: " ",
    atRisk: " ",
    performance: " ",
  },
  {
    ministryName: "Министерство",
    numOfPrograms: "Госпрограммы",
    indicatorsPlan: "Показатели",
    indicatorsFact: " ",
    plan: "Мероприятия",
    fact: " ",
    atRisk: " ",
    performance: "Уровень достижения",
  },
  {
    ministryName: " ",
    numOfPrograms: " ",
    indicatorsPlan: "План",
    indicatorsFact: "Факт",
    plan: "План",
    fact: "Факт",
    atRisk: "Под риском",
    performance: " ",
  },
];
const ministriesMergeCells = [
  { s: { r: 0, c: 2 }, e: { r: 0, c: 18 } },
  { s: { r: 1, c: 2 }, e: { r: 1, c: 18 } },
  { s: { r: 2, c: 2 }, e: { r: 2, c: 18 } },
  { s: { r: 3, c: 2 }, e: { r: 3, c: 18 } },
  { s: { r: 4, c: 2 }, e: { r: 4, c: 18 } },
  { s: { r: 5, c: 2 }, e: { r: 5, c: 18 } },
  { s: { r: 6, c: 2 }, e: { r: 6, c: 18 } },
  { s: { r: 8, c: 0 }, e: { r: 9, c: 0 } },
  { s: { r: 8, c: 1 }, e: { r: 9, c: 1 } },
  { s: { r: 8, c: 2 }, e: { r: 8, c: 3 } },
  { s: { r: 8, c: 4 }, e: { r: 8, c: 6 } },
  { s: { r: 8, c: 7 }, e: { r: 9, c: 7 } },
];

const programEventsHeadings = [
  {
    id: " ",
    structureElement: "",
    structureElementName: "",
    eventTypeNameFull: "",
    name: " ",
    units: " ",
    plan: " ",
    fact: " ",
    done: " ",
    effect: " ",
    notDeliveredFunds: " ",
    notAcceptedFunds: " ",
  },
  {
    id: " ",
    structureElement: "",
    structureElementName: "",
    eventTypeNameFull: "",
    name: " ",
    units: " ",
    plan: " ",
    fact: " ",
    done: " ",
    effect: " ",
    notDeliveredFunds: " ",
    notAcceptedFunds: " ",
  },
  {
    id: " ",
    structureElement: "",
    structureElementName: "",
    eventTypeNameFull: "",
    name: " ",
    units: " ",
    plan: " ",
    fact: " ",
    done: " ",
    effect: " ",
    notDeliveredFunds: " ",
    notAcceptedFunds: " ",
  },
  {
    id: " ",
    structureElement: "",
    structureElementName: "",
    eventTypeNameFull: "",
    name: " ",
    units: " ",
    plan: " ",
    fact: " ",
    done: " ",
    effect: " ",
    notDeliveredFunds: " ",
    notAcceptedFunds: " ",
  },
  {
    id: " ",
    structureElement: "",
    structureElementName: "",
    eventTypeNameFull: "",
    name: " ",
    units: " ",
    plan: " ",
    fact: " ",
    done: " ",
    effect: " ",
    notDeliveredFunds: " ",
    notAcceptedFunds: " ",
  },
  {
    id: " ",
    structureElement: "",
    structureElementName: "",
    eventTypeNameFull: "",
    name: " ",
    units: " ",
    plan: " ",
    fact: " ",
    done: " ",
    effect: " ",
    notDeliveredFunds: " ",
    notAcceptedFunds: " ",
  },
  {
    id: " ",
    structureElement: "",
    structureElementName: "",
    eventTypeNameFull: "",
    name: " ",
    units: " ",
    plan: " ",
    fact: " ",
    done: " ",
    effect: " ",
    notDeliveredFunds: " ",
    notAcceptedFunds: " ",
  },
  {
    id: " ",
    structureElement: "",
    structureElementName: "",
    eventTypeNameFull: "",
    name: " ",
    units: " ",
    plan: " ",
    fact: " ",
    done: " ",
    effect: " ",
    notDeliveredFunds: " ",
    notAcceptedFunds: " ",
  },
  {
    id: " ",
    structureElement: "",
    structureElementName: "",
    eventTypeNameFull: "",
    name: " ",
    units: " ",
    plan: " ",
    fact: " ",
    done: " ",
    effect: " ",
    notDeliveredFunds: " ",
    notAcceptedFunds: " ",
  },
  {
    id: "ID мероприятия",
    structureElement: "Тип CЭ",
    structureElementName: "Наименование СЭ",
    eventTypeNameFull: "Тип мероприятия",
    name: "Наименование мероприятия",
    units: "Ед. изм.",
    plan: "План",
    fact: "Факт",
    done: "Уровень достижения, %",
    effect: "Влияние",
    notDeliveredFunds: "Не доведено средств, млрд ₽",
    notAcceptedFunds: "Не принято средств, млрд ₽",
  },
];
const programEventsMergeCells = [
  { s: { r: 0, c: 2 }, e: { r: 0, c: 18 } },
  { s: { r: 1, c: 2 }, e: { r: 1, c: 18 } },
  { s: { r: 2, c: 2 }, e: { r: 2, c: 18 } },
  { s: { r: 3, c: 2 }, e: { r: 3, c: 18 } },
  { s: { r: 4, c: 2 }, e: { r: 4, c: 18 } },
  { s: { r: 5, c: 2 }, e: { r: 5, c: 18 } },
  { s: { r: 6, c: 2 }, e: { r: 6, c: 18 } },
];

const summaryProgramsHeadings = [
  {
    id: " ",
    govProgram: " ",
    vicePremier: " ",
    foiv: " ",
    performance: " ",
    cashPlan: " ",
    cashFact: " ",
    indicatorsPlan: " ",
    indicatorsFact: " ",
    indicatorsFail: " ",
    structureElementsPlan: " ",
    structureElementsFact: " ",
    structureElementsFail: " ",
    indicatorsSEPlan: " ",
    indicatorsSEFact: " ",
    indicatorsSEFail: " ",
    eventsPlan: " ",
    eventsFact: " ",
    eventsFail: " ",
    checkpointsPlan: " ",
    checkpointsFact: " ",
    checkpointsFail: " ",
  },
  {
    id: " ",
    govProgram: " ",
    vicePremier: " ",
    foiv: " ",
    performance: " ",
    cashPlan: " ",
    cashFact: " ",
    indicatorsPlan: " ",
    indicatorsFact: " ",
    indicatorsFail: " ",
    structureElementsPlan: " ",
    structureElementsFact: " ",
    structureElementsFail: " ",
    indicatorsSEPlan: " ",
    indicatorsSEFact: " ",
    indicatorsSEFail: " ",
    eventsPlan: " ",
    eventsFact: " ",
    eventsFail: " ",
    checkpointsPlan: " ",
    checkpointsFact: " ",
    checkpointsFail: " ",
  },
  {
    id: " ",
    govProgram: " ",
    vicePremier: " ",
    foiv: " ",
    performance: " ",
    cashPlan: " ",
    cashFact: " ",
    indicatorsPlan: " ",
    indicatorsFact: " ",
    indicatorsFail: " ",
    structureElementsPlan: " ",
    structureElementsFact: " ",
    structureElementsFail: " ",
    indicatorsSEPlan: " ",
    indicatorsSEFact: " ",
    indicatorsSEFail: " ",
    eventsPlan: " ",
    eventsFact: " ",
    eventsFail: " ",
    checkpointsPlan: " ",
    checkpointsFact: " ",
    checkpointsFail: " ",
  },
  {
    id: " ",
    govProgram: " ",
    vicePremier: " ",
    foiv: " ",
    performance: " ",
    cashPlan: " ",
    cashFact: " ",
    indicatorsPlan: " ",
    indicatorsFact: " ",
    indicatorsFail: " ",
    structureElementsPlan: " ",
    structureElementsFact: " ",
    structureElementsFail: " ",
    indicatorsSEPlan: " ",
    indicatorsSEFact: " ",
    indicatorsSEFail: " ",
    eventsPlan: " ",
    eventsFact: " ",
    eventsFail: " ",
    checkpointsPlan: " ",
    checkpointsFact: " ",
    checkpointsFail: " ",
  },
  {
    id: " ",
    govProgram: " ",
    vicePremier: " ",
    foiv: " ",
    performance: " ",
    cashPlan: " ",
    cashFact: " ",
    indicatorsPlan: " ",
    indicatorsFact: " ",
    indicatorsFail: " ",
    structureElementsPlan: " ",
    structureElementsFact: " ",
    structureElementsFail: " ",
    indicatorsSEPlan: " ",
    indicatorsSEFact: " ",
    indicatorsSEFail: " ",
    eventsPlan: " ",
    eventsFact: " ",
    eventsFail: " ",
    checkpointsPlan: " ",
    checkpointsFact: " ",
    checkpointsFail: " ",
  },
  {
    id: " ",
    govProgram: " ",
    vicePremier: " ",
    foiv: " ",
    performance: " ",
    cashPlan: " ",
    cashFact: " ",
    indicatorsPlan: " ",
    indicatorsFact: " ",
    indicatorsFail: " ",
    structureElementsPlan: " ",
    structureElementsFact: " ",
    structureElementsFail: " ",
    indicatorsSEPlan: " ",
    indicatorsSEFact: " ",
    indicatorsSEFail: " ",
    eventsPlan: " ",
    eventsFact: " ",
    eventsFail: " ",
    checkpointsPlan: " ",
    checkpointsFact: " ",
    checkpointsFail: " ",
  },
  {
    id: " ",
    govProgram: " ",
    vicePremier: " ",
    foiv: " ",
    performance: " ",
    cashPlan: " ",
    cashFact: " ",
    indicatorsPlan: " ",
    indicatorsFact: " ",
    indicatorsFail: " ",
    structureElementsPlan: " ",
    structureElementsFact: " ",
    structureElementsFail: " ",
    indicatorsSEPlan: " ",
    indicatorsSEFact: " ",
    indicatorsSEFail: " ",
    eventsPlan: " ",
    eventsFact: " ",
    eventsFail: " ",
    checkpointsPlan: " ",
    checkpointsFact: " ",
    checkpointsFail: " ",
  },
  {
    id: " ",
    govProgram: " ",
    vicePremier: " ",
    foiv: " ",
    performance: " ",
    cashPlan: " ",
    cashFact: " ",
    indicatorsPlan: " ",
    indicatorsFact: " ",
    indicatorsFail: " ",
    structureElementsPlan: " ",
    structureElementsFact: " ",
    structureElementsFail: " ",
    indicatorsSEPlan: " ",
    indicatorsSEFact: " ",
    indicatorsSEFail: " ",
    eventsPlan: " ",
    eventsFact: " ",
    eventsFail: " ",
    checkpointsPlan: " ",
    checkpointsFact: " ",
    checkpointsFail: " ",
  },
  {
    id: "Код ГП",
    govProgram: "Госпрограмма",
    vicePremier: "Вице-премьер",
    foiv: "Министерства",
    performance: "Уровень достижения",
    cashPlan: "Кассовое исполнение",
    cashFact: " ",
    indicatorsPlan: "Показатели",
    indicatorsFact: " ",
    indicatorsFail: " ",
    structureElementsPlan: "Структурные элементы",
    structureElementsFact: " ",
    structureElementsFail: " ",
    indicatorsSEPlan: "Показатели СЭ",
    indicatorsSEFact: " ",
    indicatorsSEFail: " ",
    eventsPlan: "Мероприятия",
    eventsFact: " ",
    eventsFail: " ",
    checkpointsPlan: "Контрольные точки",
    checkpointsFact: " ",
    checkpointsFail: " ",
  },
  {
    id: " ",
    govProgram: " ",
    vicePremier: " ",
    foiv: " ",
    performance: " ",
    cashPlan: "План",
    cashFact: "Факт",
    indicatorsPlan: "План",
    indicatorsFact: "Факт",
    indicatorsFail: "Не выполнено",
    structureElementsPlan: "План",
    structureElementsFact: "Факт",
    structureElementsFail: "Не выполнено",
    indicatorsSEPlan: "План",
    indicatorsSEFact: "Факт",
    indicatorsSEFail: "Не выполнено",
    eventsPlan: "План",
    eventsFact: "Факт",
    eventsFail: "Не выполнено",
    checkpointsPlan: "План",
    checkpointsFact: "Факт",
    checkpointsFail: "Не выполнено",
  },
];
const summaryProgramsMergeCells = [
  { s: { r: 0, c: 2 }, e: { r: 0, c: 18 } },
  { s: { r: 1, c: 2 }, e: { r: 1, c: 18 } },
  { s: { r: 2, c: 2 }, e: { r: 2, c: 18 } },
  { s: { r: 3, c: 2 }, e: { r: 3, c: 18 } },
  { s: { r: 4, c: 2 }, e: { r: 4, c: 18 } },
  { s: { r: 5, c: 2 }, e: { r: 5, c: 18 } },
  { s: { r: 6, c: 2 }, e: { r: 6, c: 18 } },
  { s: { r: 8, c: 0 }, e: { r: 9, c: 0 } },
  { s: { r: 8, c: 1 }, e: { r: 9, c: 1 } },
  { s: { r: 8, c: 2 }, e: { r: 9, c: 2 } },
  { s: { r: 8, c: 3 }, e: { r: 9, c: 3 } },
  { s: { r: 8, c: 4 }, e: { r: 9, c: 4 } },
  { s: { r: 8, c: 5 }, e: { r: 8, c: 6 } },
  { s: { r: 8, c: 7 }, e: { r: 8, c: 9 } },
  { s: { r: 8, c: 10 }, e: { r: 8, c: 12 } },
  { s: { r: 8, c: 13 }, e: { r: 8, c: 15 } },
  { s: { r: 8, c: 16 }, e: { r: 8, c: 18 } },
];

const summaryVicePremiersHeadings = [
  {
    vicePremier: " ",
    numOfPrograms: " ",
    performance: " ",
    cashPlan: " ",
    cashFact: " ",
    indicatorsPlan: " ",
    indicatorsFact: " ",
    indicatorsFail: " ",
    structureElementsPlan: " ",
    structureElementsFact: " ",
    structureElementsFail: " ",
    indicatorsSEPlan: " ",
    indicatorsSEFact: " ",
    indicatorsSEFail: " ",
    eventsPlan: " ",
    eventsFact: " ",
    eventsFail: " ",
    checkpointsPlan: " ",
    checkpointsFact: " ",
    checkpointsFail: " ",
  },
  {
    vicePremier: " ",
    numOfPrograms: " ",
    performance: " ",
    cashPlan: " ",
    cashFact: " ",
    indicatorsPlan: " ",
    indicatorsFact: " ",
    indicatorsFail: " ",
    structureElementsPlan: " ",
    structureElementsFact: " ",
    structureElementsFail: " ",
    indicatorsSEPlan: " ",
    indicatorsSEFact: " ",
    indicatorsSEFail: " ",
    eventsPlan: " ",
    eventsFact: " ",
    eventsFail: " ",
    checkpointsPlan: " ",
    checkpointsFact: " ",
    checkpointsFail: " ",
  },
  {
    vicePremier: " ",
    numOfPrograms: " ",
    performance: " ",
    cashPlan: " ",
    cashFact: " ",
    indicatorsPlan: " ",
    indicatorsFact: " ",
    indicatorsFail: " ",
    structureElementsPlan: " ",
    structureElementsFact: " ",
    structureElementsFail: " ",
    indicatorsSEPlan: " ",
    indicatorsSEFact: " ",
    indicatorsSEFail: " ",
    eventsPlan: " ",
    eventsFact: " ",
    eventsFail: " ",
    checkpointsPlan: " ",
    checkpointsFact: " ",
    checkpointsFail: " ",
  },
  {
    vicePremier: " ",
    numOfPrograms: " ",
    performance: " ",
    cashPlan: " ",
    cashFact: " ",
    indicatorsPlan: " ",
    indicatorsFact: " ",
    indicatorsFail: " ",
    structureElementsPlan: " ",
    structureElementsFact: " ",
    structureElementsFail: " ",
    indicatorsSEPlan: " ",
    indicatorsSEFact: " ",
    indicatorsSEFail: " ",
    eventsPlan: " ",
    eventsFact: " ",
    eventsFail: " ",
    checkpointsPlan: " ",
    checkpointsFact: " ",
    checkpointsFail: " ",
  },
  {
    vicePremier: " ",
    numOfPrograms: " ",
    performance: " ",
    cashPlan: " ",
    cashFact: " ",
    indicatorsPlan: " ",
    indicatorsFact: " ",
    indicatorsFail: " ",
    structureElementsPlan: " ",
    structureElementsFact: " ",
    structureElementsFail: " ",
    indicatorsSEPlan: " ",
    indicatorsSEFact: " ",
    indicatorsSEFail: " ",
    eventsPlan: " ",
    eventsFact: " ",
    eventsFail: " ",
    checkpointsPlan: " ",
    checkpointsFact: " ",
    checkpointsFail: " ",
  },
  {
    vicePremier: " ",
    numOfPrograms: " ",
    performance: " ",
    cashPlan: " ",
    cashFact: " ",
    indicatorsPlan: " ",
    indicatorsFact: " ",
    indicatorsFail: " ",
    structureElementsPlan: " ",
    structureElementsFact: " ",
    structureElementsFail: " ",
    indicatorsSEPlan: " ",
    indicatorsSEFact: " ",
    indicatorsSEFail: " ",
    eventsPlan: " ",
    eventsFact: " ",
    eventsFail: " ",
    checkpointsPlan: " ",
    checkpointsFact: " ",
    checkpointsFail: " ",
  },
  {
    vicePremier: " ",
    numOfPrograms: " ",
    performance: " ",
    cashPlan: " ",
    cashFact: " ",
    indicatorsPlan: " ",
    indicatorsFact: " ",
    indicatorsFail: " ",
    structureElementsPlan: " ",
    structureElementsFact: " ",
    structureElementsFail: " ",
    indicatorsSEPlan: " ",
    indicatorsSEFact: " ",
    indicatorsSEFail: " ",
    eventsPlan: " ",
    eventsFact: " ",
    eventsFail: " ",
    checkpointsPlan: " ",
    checkpointsFact: " ",
    checkpointsFail: " ",
  },
  {
    vicePremier: " ",
    numOfPrograms: " ",
    performance: " ",
    cashPlan: " ",
    cashFact: " ",
    indicatorsPlan: " ",
    indicatorsFact: " ",
    indicatorsFail: " ",
    structureElementsPlan: " ",
    structureElementsFact: " ",
    structureElementsFail: " ",
    indicatorsSEPlan: " ",
    indicatorsSEFact: " ",
    indicatorsSEFail: " ",
    eventsPlan: " ",
    eventsFact: " ",
    eventsFail: " ",
    checkpointsPlan: " ",
    checkpointsFact: " ",
    checkpointsFail: " ",
  },
  {
    vicePremier: "Вице-премьер",
    numOfPrograms: "Кол-во госпрограмм",
    performance: "Уровень достижения",
    cashPlan: "Кассовое исполнение",
    cashFact: " ",
    indicatorsPlan: "Показатели ГП",
    indicatorsFact: " ",
    indicatorsFail: " ",
    structureElementsPlan: "Структурные элементы",
    structureElementsFact: " ",
    structureElementsFail: " ",
    indicatorsSEPlan: "Показатели СЭ",
    indicatorsSEFact: " ",
    indicatorsSEFail: " ",
    eventsPlan: "Мероприятия",
    eventsFact: " ",
    eventsFail: " ",
    checkpointsPlan: "Контрольные точки",
    checkpointsFact: " ",
    checkpointsFail: " ",
  },

  {
    vicePremier: " ",
    numOfPrograms: " ",
    performance: " ",
    cashPlan: "План",
    cashFact: "Факт",
    indicatorsPlan: "План",
    indicatorsFact: "Факт",
    indicatorsFail: "Не выполнено",
    structureElementsPlan: "План",
    structureElementsFact: "Факт",
    structureElementsFail: "Не выполнено",
    indicatorsSEPlan: "План",
    indicatorsSEFact: "Факт",
    indicatorsSEFail: "Не выполнено",
    eventsPlan: "План",
    eventsFact: "Факт",
    eventsFail: "Не выполнено",
    checkpointsPlan: "План",
    checkpointsFact: "Факт",
    checkpointsFail: "Не выполнено",
  },
];
const summaryVicePremiersMergeCells = [
  { s: { r: 0, c: 2 }, e: { r: 0, c: 18 } },
  { s: { r: 1, c: 2 }, e: { r: 1, c: 18 } },
  { s: { r: 2, c: 2 }, e: { r: 2, c: 18 } },
  { s: { r: 3, c: 2 }, e: { r: 3, c: 18 } },
  { s: { r: 4, c: 2 }, e: { r: 4, c: 18 } },
  { s: { r: 5, c: 2 }, e: { r: 5, c: 18 } },
  { s: { r: 6, c: 2 }, e: { r: 6, c: 18 } },
  { s: { r: 8, c: 0 }, e: { r: 9, c: 0 } },
  { s: { r: 8, c: 1 }, e: { r: 9, c: 1 } },
  { s: { r: 8, c: 2 }, e: { r: 9, c: 2 } },
  { s: { r: 8, c: 3 }, e: { r: 8, c: 4 } },
  { s: { r: 8, c: 5 }, e: { r: 8, c: 7 } },
  { s: { r: 8, c: 8 }, e: { r: 8, c: 10 } },
  { s: { r: 8, c: 11 }, e: { r: 8, c: 13 } },
  { s: { r: 8, c: 14 }, e: { r: 8, c: 16 } },
  { s: { r: 8, c: 17 }, e: { r: 8, c: 19 } },
];

const summaryMinistriesHeadings = [
  {
    name: " ",
    numOfPrograms: " ",
    performance: " ",
    cashPlan: " ",
    cashFact: " ",
    indicatorsPlan: " ",
    indicatorsFact: " ",
    indicatorsFail: " ",
    structureElementsPlan: " ",
    structureElementsFact: " ",
    structureElementsFail: " ",
    indicatorsSEPlan: " ",
    indicatorsSEFact: " ",
    indicatorsSEFail: " ",
    eventsPlan: " ",
    eventsFact: " ",
    eventsFail: " ",
    checkpointsPlan: " ",
    checkpointsFact: " ",
    checkpointsFail: " ",
  },
  {
    name: " ",
    numOfPrograms: " ",
    performance: " ",
    cashPlan: " ",
    cashFact: " ",
    indicatorsPlan: " ",
    indicatorsFact: " ",
    indicatorsFail: " ",
    structureElementsPlan: " ",
    structureElementsFact: " ",
    structureElementsFail: " ",
    indicatorsSEPlan: " ",
    indicatorsSEFact: " ",
    indicatorsSEFail: " ",
    eventsPlan: " ",
    eventsFact: " ",
    eventsFail: " ",
    checkpointsPlan: " ",
    checkpointsFact: " ",
    checkpointsFail: " ",
  },
  {
    name: " ",
    numOfPrograms: " ",
    performance: " ",
    cashPlan: " ",
    cashFact: " ",
    indicatorsPlan: " ",
    indicatorsFact: " ",
    indicatorsFail: " ",
    structureElementsPlan: " ",
    structureElementsFact: " ",
    structureElementsFail: " ",
    indicatorsSEPlan: " ",
    indicatorsSEFact: " ",
    indicatorsSEFail: " ",
    eventsPlan: " ",
    eventsFact: " ",
    eventsFail: " ",
    checkpointsPlan: " ",
    checkpointsFact: " ",
    checkpointsFail: " ",
  },
  {
    name: " ",
    numOfPrograms: " ",
    performance: " ",
    cashPlan: " ",
    cashFact: " ",
    indicatorsPlan: " ",
    indicatorsFact: " ",
    indicatorsFail: " ",
    structureElementsPlan: " ",
    structureElementsFact: " ",
    structureElementsFail: " ",
    indicatorsSEPlan: " ",
    indicatorsSEFact: " ",
    indicatorsSEFail: " ",
    eventsPlan: " ",
    eventsFact: " ",
    eventsFail: " ",
    checkpointsPlan: " ",
    checkpointsFact: " ",
    checkpointsFail: " ",
  },
  {
    name: " ",
    numOfPrograms: " ",
    performance: " ",
    cashPlan: " ",
    cashFact: " ",
    indicatorsPlan: " ",
    indicatorsFact: " ",
    indicatorsFail: " ",
    structureElementsPlan: " ",
    structureElementsFact: " ",
    structureElementsFail: " ",
    indicatorsSEPlan: " ",
    indicatorsSEFact: " ",
    indicatorsSEFail: " ",
    eventsPlan: " ",
    eventsFact: " ",
    eventsFail: " ",
    checkpointsPlan: " ",
    checkpointsFact: " ",
    checkpointsFail: " ",
  },
  {
    name: " ",
    numOfPrograms: " ",
    performance: " ",
    cashPlan: " ",
    cashFact: " ",
    indicatorsPlan: " ",
    indicatorsFact: " ",
    indicatorsFail: " ",
    structureElementsPlan: " ",
    structureElementsFact: " ",
    structureElementsFail: " ",
    indicatorsSEPlan: " ",
    indicatorsSEFact: " ",
    indicatorsSEFail: " ",
    eventsPlan: " ",
    eventsFact: " ",
    eventsFail: " ",
    checkpointsPlan: " ",
    checkpointsFact: " ",
    checkpointsFail: " ",
  },
  {
    name: " ",
    numOfPrograms: " ",
    performance: " ",
    cashPlan: " ",
    cashFact: " ",
    indicatorsPlan: " ",
    indicatorsFact: " ",
    indicatorsFail: " ",
    structureElementsPlan: " ",
    structureElementsFact: " ",
    structureElementsFail: " ",
    indicatorsSEPlan: " ",
    indicatorsSEFact: " ",
    indicatorsSEFail: " ",
    eventsPlan: " ",
    eventsFact: " ",
    eventsFail: " ",
    checkpointsPlan: " ",
    checkpointsFact: " ",
    checkpointsFail: " ",
  },
  {
    name: " ",
    numOfPrograms: " ",
    performance: " ",
    cashPlan: " ",
    cashFact: " ",
    indicatorsPlan: " ",
    indicatorsFact: " ",
    indicatorsFail: " ",
    structureElementsPlan: " ",
    structureElementsFact: " ",
    structureElementsFail: " ",
    indicatorsSEPlan: " ",
    indicatorsSEFact: " ",
    indicatorsSEFail: " ",
    eventsPlan: " ",
    eventsFact: " ",
    eventsFail: " ",
    checkpointsPlan: " ",
    checkpointsFact: " ",
    checkpointsFail: " ",
  },
  {
    name: "Министерство",
    numOfPrograms: "Кол-во госпрограмм",
    performance: "Уровень достижения",
    cashPlan: "Кассовое исполнение",
    cashFact: " ",
    indicatorsPlan: "Показатели ГП",
    indicatorsFact: " ",
    indicatorsFail: " ",
    structureElementsPlan: "Структурные элементы",
    structureElementsFact: " ",
    structureElementsFail: " ",
    indicatorsSEPlan: "Показатели СЭ",
    indicatorsSEFact: " ",
    indicatorsSEFail: " ",
    eventsPlan: "Мероприятия",
    eventsFact: " ",
    eventsFail: " ",
    checkpointsPlan: "Контрольные точки",
    checkpointsFact: " ",
    checkpointsFail: " ",
  },
  {
    name: " ",
    numOfPrograms: " ",
    performance: " ",
    cashPlan: "План",
    cashFact: "Факт",
    indicatorsPlan: "План",
    indicatorsFact: "Факт",
    indicatorsFail: "Не выполнено",
    structureElementsPlan: "План",
    structureElementsFact: "Факт",
    structureElementsFail: "Не выполнено",
    indicatorsSEPlan: "План",
    indicatorsSEFact: "Факт",
    indicatorsSEFail: "Не выполнено",
    eventsPlan: "План",
    eventsFact: "Факт",
    eventsFail: "Не выполнено",
    checkpointsPlan: "План",
    checkpointsFact: "Факт",
    checkpointsFail: "Не выполнено",
  },
];
const summaryMinistriesMergeCells = [
  { s: { r: 0, c: 2 }, e: { r: 0, c: 18 } },
  { s: { r: 1, c: 2 }, e: { r: 1, c: 18 } },
  { s: { r: 2, c: 2 }, e: { r: 2, c: 18 } },
  { s: { r: 3, c: 2 }, e: { r: 3, c: 18 } },
  { s: { r: 4, c: 2 }, e: { r: 4, c: 18 } },
  { s: { r: 5, c: 2 }, e: { r: 5, c: 18 } },
  { s: { r: 6, c: 2 }, e: { r: 6, c: 18 } },
  { s: { r: 8, c: 0 }, e: { r: 9, c: 0 } },
  { s: { r: 8, c: 1 }, e: { r: 9, c: 1 } },
  { s: { r: 8, c: 2 }, e: { r: 9, c: 2 } },
  { s: { r: 8, c: 3 }, e: { r: 8, c: 4 } },
  { s: { r: 8, c: 5 }, e: { r: 8, c: 7 } },
  { s: { r: 8, c: 8 }, e: { r: 8, c: 10 } },
  { s: { r: 8, c: 11 }, e: { r: 8, c: 13 } },
  { s: { r: 8, c: 14 }, e: { r: 8, c: 16 } },
  { s: { r: 8, c: 17 }, e: { r: 8, c: 19 } },
];

const programIndicatorsSEHeadings = [
  {
    id: " ",
    se_gp_type: " ",
    name: " ",
    unit: " ",
    implementation_period: " ",
    plan: " ",
    planYear: " ",
    fact: " ",
    achievement: " ",
  },
  {
    id: " ",
    se_gp_type: " ",
    name: " ",
    unit: " ",
    implementation_period: " ",
    plan: " ",
    planYear: " ",
    fact: " ",
    achievement: " ",
  },
  {
    id: " ",
    se_gp_type: " ",
    name: " ",
    unit: " ",
    implementation_period: " ",
    plan: " ",
    planYear: " ",
    fact: " ",
    achievement: " ",
  },
  {
    id: " ",
    se_gp_type: " ",
    name: " ",
    unit: " ",
    implementation_period: " ",
    plan: " ",
    planYear: " ",
    fact: " ",
    achievement: " ",
  },
  {
    id: " ",
    se_gp_type: " ",
    name: " ",
    unit: " ",
    implementation_period: " ",
    plan: " ",
    planYear: " ",
    fact: " ",
    achievement: " ",
  },
  {
    id: " ",
    se_gp_type: " ",
    name: " ",
    unit: " ",
    implementation_period: " ",
    plan: " ",
    planYear: " ",
    fact: " ",
    achievement: " ",
  },
  {
    id: " ",
    se_gp_type: " ",
    name: " ",
    unit: " ",
    implementation_period: " ",
    plan: " ",
    planYear: " ",
    fact: " ",
    achievement: " ",
  },
  {
    id: " ",
    se_gp_type: " ",
    name: " ",
    unit: " ",
    implementation_period: " ",
    plan: " ",
    planYear: " ",
    fact: " ",
    achievement: " ",
  },
  {
    id: " ",
    se_gp_type: " ",
    name: " ",
    unit: " ",
    implementation_period: " ",
    plan: " ",
    planYear: " ",
    fact: " ",
    achievement: " ",
  },
  {
    id: "ID индикатора СЭ",
    se_gp_type: "Тип CЭ",
    name: "Наименование показателя",
    unit: "Ед.изм.",
    implementation_period: "Период достижения",
    plan: "План",
    planYear: "План на год",
    fact: "Факт",
    achievement: "Уровень достижения, %",
  },
];
const programIndicatorsSEMergeCells = [
  { s: { r: 0, c: 2 }, e: { r: 0, c: 18 } },
  { s: { r: 1, c: 2 }, e: { r: 1, c: 18 } },
  { s: { r: 2, c: 2 }, e: { r: 2, c: 18 } },
  { s: { r: 3, c: 2 }, e: { r: 3, c: 18 } },
  { s: { r: 4, c: 2 }, e: { r: 4, c: 18 } },
  { s: { r: 5, c: 2 }, e: { r: 5, c: 18 } },
  { s: { r: 6, c: 2 }, e: { r: 6, c: 18 } },
];

const programStructureGPHeadings = [
  {
    type: " ",
    name: " ",
    performance: " ",
    cash: " ",
  },
  {
    type: " ",
    name: " ",
    performance: " ",
    cash: " ",
  },
  {
    type: " ",
    name: " ",
    performance: " ",
    cash: " ",
  },
  {
    type: " ",
    name: " ",
    performance: " ",
    cash: " ",
  },
  {
    type: " ",
    name: " ",
    performance: " ",
    cash: " ",
  },
  {
    type: " ",
    name: " ",
    performance: " ",
    cash: " ",
  },
  {
    type: " ",
    name: " ",
    performance: " ",
    cash: " ",
  },
  {
    type: " ",
    name: " ",
    performance: " ",
    cash: " ",
  },
  {
    type: " ",
    name: " ",
    performance: " ",
    cash: " ",
  },
  {
    type: "Тип СЭ",
    name: "Наименование CЭ",
    performance: "Уровень достижения, %",
    cash: "Касса, %",
  },
];
const programStructureGPMergeCells = [
  { s: { r: 0, c: 2 }, e: { r: 0, c: 18 } },
  { s: { r: 1, c: 2 }, e: { r: 1, c: 18 } },
  { s: { r: 2, c: 2 }, e: { r: 2, c: 18 } },
  { s: { r: 3, c: 2 }, e: { r: 3, c: 18 } },
  { s: { r: 4, c: 2 }, e: { r: 4, c: 18 } },
  { s: { r: 5, c: 2 }, e: { r: 5, c: 18 } },
  { s: { r: 6, c: 2 }, e: { r: 6, c: 18 } },
];

const programTasksSEHeadings = [
  {
    name: " ",
    indicatorsCount: " ",
    eventsCount: " ",
  },
  {
    name: " ",
    indicatorsCount: " ",
    eventsCount: " ",
  },
  {
    name: " ",
    indicatorsCount: " ",
    eventsCount: " ",
  },
  {
    name: " ",
    indicatorsCount: " ",
    eventsCount: " ",
  },
  {
    name: " ",
    indicatorsCount: " ",
    eventsCount: " ",
  },
  {
    name: " ",
    indicatorsCount: " ",
    eventsCount: " ",
  },
  {
    name: " ",
    indicatorsCount: " ",
    eventsCount: " ",
  },
  {
    name: " ",
    indicatorsCount: " ",
    eventsCount: " ",
  },
  {
    name: " ",
    indicatorsCount: " ",
    eventsCount: " ",
  },
  {
    name: "Наименование",
    indicatorsCount: "Количество показателей",
    eventsCount: "Количество мероприятий",
  },
];
const programTasksSEMergeCells = [
  { s: { r: 0, c: 2 }, e: { r: 0, c: 18 } },
  { s: { r: 1, c: 2 }, e: { r: 1, c: 18 } },
  { s: { r: 2, c: 2 }, e: { r: 2, c: 18 } },
  { s: { r: 3, c: 2 }, e: { r: 3, c: 18 } },
  { s: { r: 4, c: 2 }, e: { r: 4, c: 18 } },
  { s: { r: 5, c: 2 }, e: { r: 5, c: 18 } },
  { s: { r: 6, c: 2 }, e: { r: 6, c: 18 } },
];

const programIndicatorsGPHeadings = [
  {
    goal: " ",
    indicator: " ",
    performance: " ",
    plan: " ",
    fact: " ",
  },
  {
    goal: " ",
    indicator: " ",
    performance: " ",
    plan: " ",
    fact: " ",
  },
  {
    goal: " ",
    indicator: " ",
    performance: " ",
    plan: " ",
    fact: " ",
  },
  {
    goal: " ",
    indicator: " ",
    performance: " ",
    plan: " ",
    fact: " ",
  },
  {
    goal: " ",
    indicator: " ",
    performance: " ",
    plan: " ",
    fact: " ",
  },
  {
    goal: " ",
    indicator: " ",
    performance: " ",
    plan: " ",
    fact: " ",
  },
  {
    goal: " ",
    indicator: " ",
    performance: " ",
    plan: " ",
    fact: " ",
  },
  {
    goal: " ",
    indicator: " ",
    performance: " ",
    plan: " ",
    fact: " ",
  },
  {
    goal: " ",
    indicator: " ",
    performance: " ",
    plan: " ",
    fact: " ",
  },
  {
    goal: "Цель",
    indicator: "Показатель",
    performance: "Уровень достижения",
    plan: "План",
    fact: "Факт",
  },
];
const programIndicatorsGPMergeCells = [
  { s: { r: 0, c: 2 }, e: { r: 0, c: 18 } },
  { s: { r: 1, c: 2 }, e: { r: 1, c: 18 } },
  { s: { r: 2, c: 2 }, e: { r: 2, c: 18 } },
  { s: { r: 3, c: 2 }, e: { r: 3, c: 18 } },
  { s: { r: 4, c: 2 }, e: { r: 4, c: 18 } },
  { s: { r: 5, c: 2 }, e: { r: 5, c: 18 } },
  { s: { r: 6, c: 2 }, e: { r: 6, c: 18 } },
];

const programIndicatorsAnalyticsGPHeadings = [
  {
    indicator: " ",
    plan: " ",
    fact: " ",
    unit: " ",
  },
  {
    indicator: " ",
    plan: " ",
    fact: " ",
    unit: " ",
  },
  {
    indicator: " ",
    plan: " ",
    fact: " ",
    unit: " ",
  },
  {
    indicator: " ",
    plan: " ",
    fact: " ",
    unit: " ",
  },
  {
    indicator: " ",
    plan: " ",
    fact: " ",
    unit: " ",
  },
  {
    indicator: " ",
    plan: " ",
    fact: " ",
    unit: " ",
  },
  {
    indicator: " ",
    plan: " ",
    fact: " ",
    unit: " ",
  },
  {
    indicator: " ",
    plan: " ",
    fact: " ",
    unit: " ",
  },
  {
    indicator: " ",
    plan: " ",
    fact: " ",
    unit: " ",
  },
  {
    indicator: "Показатель",
    plan: "План",
    fact: "Факт",
    unit: "Ед. измерения",
  },
];
const programIndicatorsAnalyticsGPMergeCells = [
  { s: { r: 0, c: 2 }, e: { r: 0, c: 18 } },
  { s: { r: 1, c: 2 }, e: { r: 1, c: 18 } },
  { s: { r: 2, c: 2 }, e: { r: 2, c: 18 } },
  { s: { r: 3, c: 2 }, e: { r: 3, c: 18 } },
  { s: { r: 4, c: 2 }, e: { r: 4, c: 18 } },
  { s: { r: 5, c: 2 }, e: { r: 5, c: 18 } },
  { s: { r: 6, c: 2 }, e: { r: 6, c: 18 } },
];

const seTasksHeadings = [
  {
    name: "Наименование",
    type: "Тип",
    indicators: "План",
    events: "Факт",
  },
];

type TTablesParamsOptions = {
  [key: string]: {
    headings: Record<string, string | object>[];
    mergeCells?: Range[] | undefined;
    wscols: Record<string, number>[];
  };
};

const tablesParams = (data: any): TTablesParamsOptions => ({
  govPrograms: {
    headings: govProgramsHeadings,
    mergeCells: govProgramsMergeCells,
    wscols: getGovProgramsWscols(data, govProgramsHeadings),
  },
  vicePremiers: {
    headings: vicePremiersHeadings,
    mergeCells: vicePremiersMergeCells,
    wscols: getVicePremiersWscols(data, vicePremiersHeadings),
  },
  ministries: {
    headings: ministriesHeadings,
    mergeCells: ministriesMergeCells,
    wscols: getMinistriesWscols(data, ministriesHeadings),
  },
  events: {
    headings: programEventsHeadings,
    mergeCells: programEventsMergeCells,
    wscols: getProgramEventsWscols(data, programEventsHeadings),
  },
  indicatorsSE: {
    headings: programIndicatorsSEHeadings,
    mergeCells: programIndicatorsSEMergeCells,
    wscols: getProgramIndicatorsSEWscols(data, programIndicatorsSEHeadings),
  },
  structureGP: {
    headings: programStructureGPHeadings,
    mergeCells: programStructureGPMergeCells,
    wscols: getStructureGPWscols(data, programStructureGPHeadings),
  },
  tasksSE: {
    headings: programTasksSEHeadings,
    mergeCells: programTasksSEMergeCells,
    wscols: getProgramTasksSEWscols(data, programTasksSEHeadings),
  },
  indicatorsGP: {
    headings: programIndicatorsGPHeadings,
    mergeCells: programIndicatorsGPMergeCells,
    wscols: getProgramIndicatorsGPWscols(data, programIndicatorsGPHeadings),
  },
  indicatorsAnalyticsGP: {
    headings: programIndicatorsAnalyticsGPHeadings,
    mergeCells: programIndicatorsAnalyticsGPMergeCells,
    wscols: getProgramIndicatorsAnalyticsGPWscols(
      data,
      programIndicatorsAnalyticsGPHeadings
    ),
  },
  summaryPrograms: {
    headings: summaryProgramsHeadings,
    mergeCells: summaryProgramsMergeCells,
    wscols: getSummaryProgramsWscols(data, summaryProgramsHeadings),
  },
  summaryVicePremiers: {
    headings: summaryVicePremiersHeadings,
    mergeCells: summaryVicePremiersMergeCells,
    wscols: getSummaryVicePremiersWscols(data, summaryVicePremiersHeadings),
  },
  summaryMinistries: {
    headings: summaryMinistriesHeadings,
    mergeCells: summaryMinistriesMergeCells,
    wscols: getSummaryMinistriesWscols(data, summaryMinistriesHeadings),
  },
  seTasks: {
    headings: seTasksHeadings,
    mergeCells: [],
    wscols: getSETasksWscols(data, seTasksHeadings),
  },
  seEvents: {
    headings: seTasksHeadings,
    mergeCells: [],
    wscols: getSETasksWscols(data, seTasksHeadings),
  },
  seIndicators: {
    headings: seTasksHeadings,
    mergeCells: [],
    wscols: getSETasksWscols(data, seTasksHeadings),
  },
});

export const useTableExportParams = <T extends object>(
  data: T[] | undefined,
  tableName: string
) => {
  const { headings, mergeCells, wscols } = tablesParams(data)[tableName];

  return { headings, mergeCells, wscols };
};
