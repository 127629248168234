import {
  isEnpvVideo,
  isOrionnetVideo,
  isRostelecomVideo,
  isRtspVideo,
  isSaferegionVideo,
  isTrassirVideo,
  isYoutubeVideo,
} from "../../formats";

export const isVideo = (url: string) => {
  return (
    isEnpvVideo(url) ||
    isOrionnetVideo(url) ||
    isRostelecomVideo(url) ||
    isRtspVideo(url) ||
    isSaferegionVideo(url) ||
    isTrassirVideo(url) ||
    isYoutubeVideo(url)
  );
};
