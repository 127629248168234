import { InputLabel } from "@mui/material";
import SelectWithSearchIncidentType from "SRC/components/SelectWithSearch/SelectWithSearchIncidentType";

import { Wrapper } from "./IncidentType.styled";

const optionsType = [
  { label: "ошибка", value: "ошибка" },
  { label: "доработка", value: "доработка" },
  { label: "консультация", value: "консультация" },
];

interface IIncidentTypeProps {
  incidentType: string;
  selectStyle: any;
  onChangeSelectedIncidentType: (value: string) => void;
}

export const IncidentType = ({
  incidentType,
  selectStyle,
  onChangeSelectedIncidentType,
}: IIncidentTypeProps) => {
  return (
    <Wrapper>
      <InputLabel>Тип инцидента</InputLabel>
      <SelectWithSearchIncidentType
        options={optionsType}
        value={incidentType}
        label="Выберите тип инцидента"
        staticLabel={false}
        style={selectStyle}
        onChange={onChangeSelectedIncidentType}
      />
    </Wrapper>
  );
};
