import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IOlapResponseData } from "CORE/api/core";
import {
  createPeriodDims,
  IOlapdataResponse,
  parseOlapdata,
  TDecoder,
} from "CORE/utils";
import { isContainedArray } from "SRC/helpers/dataTesters";
import { baseApi } from "SRC/redux/api";
import { IPeriod } from "SRC/redux/slices/global/slices/filters";
import { ICodeItem } from "SRC/types";

import { GET_PROGRAMS, getPrograms, getRequest } from "./api";

export interface IProgramsState {
  items: IProgram[];
  fetching: boolean;
  fetched: boolean;
  error: string;
}

export const extraActions = {
  get: createAsyncThunk(
    "programs/get",
    async (period: IPeriod) => await getPrograms(period)
  ),
};

const initialState: IProgramsState = {
  items: [],
  fetching: false,
  fetched: false,
  error: "",
};

export const slice = createSlice({
  name: "programs",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActions.get.pending, (state) => {
        state.items = [];
        state.fetching = true;
        state.fetched = false;
      })
      .addCase(extraActions.get.fulfilled, (state: IProgramsState, action) => {
        const parsed = parseOlapdata(
          // @ts-ignore
          action.payload.data,
          decoder
        ) as unknown as IProgram[];
        state.items = (isContainedArray(parsed) ? parsed : []) as IProgram[];
        state.fetching = false;
        state.fetched = true;
      })
      .addCase(extraActions.get.rejected, (state, action) => {
        state.fetching = false;
        state.fetched = true;
        // state.error = action.error;
      });
  },
});

export const actions = {
  ...slice.actions,
  ...extraActions,
};

export default slice;

export const gpInfoDims = {
  name: "gp_name",
  shortName: "gp_short_name",
  code: "gp_code",
  icon: "gp_icon_url",
  vp: "Вице-премьер",
  vpPhoto: "Вице-премьер, фото",
  vpCode: "Вице-премьер, код",
  industry: "Отраслевая",
  sphere: "Сфера",
  complex: "Комплексная",
  secret: "Секретная",
  ministry: "Министерство",
};

const decoder: TDecoder = {
  period: createPeriodDims(1562),
  info: {
    code: 1565,
    dimensions: gpInfoDims,
  },
};

export interface IProgramInfo {
  name: string;
  shortName: string;
  code: string;
  icon: string;
  vp: string;
  vpPhoto: string;
  vpCode: string;
  industry: string;
  sphere: string;
  complex: string;
  secret: string;
  ministry: string;
}

export interface IProgram {
  info: IProgramInfo;
  data: ICodeItem[];
}

export const programsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getPrograms: builder.query({
      query: (period: IPeriod["apiV2"]) => ({
        url: "?",
        params: { name: GET_PROGRAMS },
        method: "POST",
        body: {
          requests: [getRequest(period)],
        },
      }),
      transformResponse: (response: IOlapResponseData<any>) => {
        const parsed = parseOlapdata(
          response?.responses?.[0]?.data as IOlapdataResponse,
          decoder
        ) as unknown as IProgram[];

        return isContainedArray(parsed) ? parsed : ([] as IProgram[]);
      },
    }),
  }),
});
