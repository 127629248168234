import { styled } from "@mui/material";
import { NavLink } from "react-router-dom";

export const Wrapper = styled("div")(({ theme }) => {
  const {
    background: { default: bgColor, header },
    primary: { contrastText },
    pale: { paleGreen, paleRed },
  } = theme.palette;

  return {
    width: "100%",
    left: 0,
    top: 0,
    flex: "0 0 auto",
    zIndex: 5,
    "& .content ": {
      position: "relative",
      width: "100%",
      height: "4.5rem",
      minHeight: "4.5rem",
      zIndex: 1,
      overflow: "hidden",

      [theme.breakpoints.up("lg")]: {
        height: "6rem",
      },

      ":after": {
        width: "100vw",
        height: "2rem",
        position: "absolute",
        top: "0",
        left: "0",
        content: "''",
        zIndex: -1,
      },

      "& .content-wrapper": {
        position: "relative",
        margin: "auto",
        height: "100%",
        width: "100%",
        zIndex: 1,
        minHeight: "4.5rem",
        backgroundColor: bgColor,
        backgroundImage: `linear-gradient(
        90deg, 
        ${header}FF 29.86%, 
        ${header}00 119.17%
      )`,

        [theme.breakpoints.up("lg")]: {
          borderRadius: "0 0 2rem 2rem",
          maxWidth: "50%",
        },

        [theme.breakpoints.up("xl")]: { maxWidth: "calc(50% + 4rem)" },

        "& .container": {
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          zIndex: 1,
          minHeight: "4.5rem",
          padding: "0 2.5rem !important",

          [theme.breakpoints.up("lg")]: {
            padding: "0 5rem !important",
          },

          "& .header-box": {
            display: "flex",
            height: "90%",

            "& .logo": {
              display: "inline-flex",
              height: "90%",
              padding: "0rem",
              gap: "1rem",
              alignItems: "center",
              color: contrastText,
              textDecoration: "none",
              fontSize: "2rem",
              fontWeight: "bold",
              opacity: 1,

              "&[aria-current='page']": {
                border: "none",
              },

              "& img": {
                height: "100%",
              },

              "& span": {
                paddingTop: "0.9rem",
                fontSize: "1rem",
              },
            },
          },

          "& .filters": {
            display: "flex",
            alignItems: "center",
            gap: "2rem",

            "& .switcher": {
              display: "flex",
              flexDirection: "row",
              gap: "0.5rem",
              alignItems: "center",

              "& .legend-item": {
                display: "flex",
                gap: "0.5rem",
                alignItems: "baseline",
                visibility: "hidden",

                "&.visible": {
                  visibility: "visible",
                },

                "& .styled-point": {
                  width: "0.7rem",
                  height: "0.7rem",
                  borderRadius: "50%",

                  "&.pale-green": {
                    background: paleGreen,
                  },
                  "&.pale-red": {
                    background: paleRed,
                  },
                },
              },
            },
          },
        },
      },
    },
  };
});

export const NavTab = styled(NavLink)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  opacity: 0.8,
  textDecoration: "none",
  padding: "1.3rem",
  fontSize: "1.125rem",
  fontWeight: "bold",
  '&[aria-current="page"]': {
    color: theme.palette.primary.contrastText,
    opacity: 1,
    borderBottom: `4px solid ${theme.palette.primary.contrastText}`,
  },

  [theme.breakpoints.up("lg")]: {
    padding: "1.3rem 1.3rem 2.3rem 1.3rem",
  },
}));
