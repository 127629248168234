import { Box } from "@mui/material";
import { DspProgram, SecretProgram, WarningTriangle } from "ASSETS/svg";
import React, { Fragment, useMemo } from "react";
import { PerformanceValue } from "SRC/components/PerformanceValue/PerformanceValue";
import { OverlayProgressBar } from "SRC/components/ProgressBar";
import { SecretSwitcher } from "SRC/components/SecretSwitcher";
import { getDataSumByCodeGP } from "SRC/constants/dataCodes";
import { ESources, sourceSettings } from "SRC/constants/globals";
import { getPercentValue } from "SRC/helpers/getPercentValue";
import Tiles from "SRC/pages/ProgramsBoard/common/Tiles/Tiles";
import { selectors, useAppSelector } from "SRC/redux/hooks";
import { useGetPrograms, useGetSummary } from "SRC/redux/stores/ProgramsBoard";

import { css } from "./center-layout.styled";

export const CenterLayout = () => {
  const { data } = useGetSummary();
  const {
    stats: { total, secret },
  } = useGetPrograms();
  const { sources } = useAppSelector(selectors.globalFilters);

  const values = useMemo(() => {
    return [
      sources[ESources.OM]
        ? {
            // Уровень достижения, особое мнение
            value: getDataSumByCodeGP(data, 5269) || 0,
            settings: sourceSettings[ESources.OM],
          }
        : {
            // Уровень достижения
            value: getDataSumByCodeGP(data, 5246) || 0, // Уровень достижения
            settings: sourceSettings[ESources.FOIV],
          },
    ];
  }, [data, sources]);

  const risk = useMemo(() => getDataSumByCodeGP(data, 1843) || 0, [data]);

  return (
    <Fragment>
      <Box sx={css.headerContainer}>
        <Box sx={css.header}>
          <Box sx={css.title}>Карточки госпрограмм</Box>
          <Box sx={css.switcherContainer}>
            <SecretSwitcher />
          </Box>
        </Box>
        <Box sx={css.header}>
          <Box sx={css.progress}>
            <Box sx={css.progressHeader}>
              <Box sx={css.barName}>
                Уровень достижения по открытым госпрограммам
              </Box>
              <Box sx={css.barValue(values[0].settings.valueColor)}>
                <PerformanceValue
                  value={getPercentValue(values[0].value)}
                  sameSize={true}
                />
                <span>%</span>
              </Box>
            </Box>
            <OverlayProgressBar values={values} />
          </Box>
          <Box sx={css.statuses}>
            <Box sx={css.status}>
              <Box sx={css.statusName}>Всего</Box>
              <Box sx={css.statusCount}>{total}</Box>
            </Box>
            <Box sx={css.status}>
              <Box sx={css.statusIconSecret}>
                <SecretProgram />
              </Box>
              <Box sx={css.statusName}>Скрытые</Box>
              <Box sx={css.statusCount}>{secret}</Box>
            </Box>
            <Box sx={css.status}>
              <Box sx={css.statusIcon}>
                <WarningTriangle />
              </Box>
              <Box sx={css.statusName}>Под риском</Box>
              <Box sx={css.statusCount}>{risk}</Box>
            </Box>
            <Box sx={css.status} style={{ display: "none" }}>
              <Box sx={css.statusIcon}>
                <DspProgram />
              </Box>
              <Box sx={css.statusName}>ДСП</Box>
              <Box sx={css.statusCount}>0</Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Tiles />
    </Fragment>
  );
};
