import { getRoundValue } from "SRC/helpers/getRoundValue";
import { ICustomTableProps } from "SRC/types";

export const getCashFactTotal = (tableProps: ICustomTableProps) => {
  let total = tableProps?.rows?.[0]?.original.cashFactTotal;

  return {
    total: getRoundValue(total || 0, 2),
  };
};
