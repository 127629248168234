import { Theme } from "@mui/material";

const icon = {
  svg: {
    width: "1rem",
    height: "1rem",
  },
};

export const css = {
  wrapper: (theme: Theme) => ({
    flexDirection: "row",
    width: "100%",
    paddingTop: "1rem",
    gap: "1rem",

    "> .titles-wrapper": {
      flex: "1 1 auto",
      flexDirection: "column",
      gap: "1rem",

      ".type": {
        backgroundColor: theme.palette.complementary.whiteTransparent,
        padding: "2px 8px",
        borderRadius: "4px",
        flex: "0 0 auto",
        maxWidth: "3rem !important",
        minWidth: 0,
      },

      ".title": {
        display: "flex",
        flexDirection: "row",
        gap: "1rem",
        alignItems: "center",
      },
    },
  }),
};
