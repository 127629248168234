import { olapRequestData4 } from "CORE/api/core";
import { PREMIERS_MODEL_UUID } from "CORE/scheme/olap";
import { getVpIndicators } from "SRC/constants";
import { IPeriod } from "SRC/redux/slices/global/slices/filters";

const vpIndicators = getVpIndicators(
  1572,
  1573,
  1574,
  1693,
  1694,
  1695,
  1696,
  1697,
  1698,
  1699,
  1700,
  1701,
  1702,
  1703,
  1704,
  1705,
  1706,
  1707,
  1708,
  1709,
  1710,
  1711,
  1712,
  1713,
  1714,
  1715,
  1716,
  1717,
  1718,
  1719,
  1720,
  1721,
  1722,
  1723,
  1724,
  1725,
  1726,
  1727,
  1728,
  1742,
  1743,
  1744,
  1745,
  1746,
  1747,
  1748,
  1749,
  1758,
  1759,
  1760,
  1761,
  1762,
  1763,
  1764,
  1765,
  1766,
  1767,
  1768,
  1797,
  1840,
  1841,
  5207,
  5208,
  5209,
  5248,
  // Контрольные точки, план
  5253,
  // Контрольные точки, факт
  5254,
  // Контрольные точки, невыполнено
  5255,
  // Контрольные точки, план, итого
  5256,
  // Контрольные точки, факт, итого
  5257,
  // Контрольные точки, невыполнено, итого
  5258,
  // Уровень достижения особое мнение
  5266,
  // Уровень достижения особое мнение итого
  5272,
  // СЭ, Факт Итого
  5375
);

const GET_PREMIERS_TOTALS = "get-premiers-totals";

export const getPremiersTotals = (period: IPeriod) => {
  return olapRequestData4(
    [
      {
        requestId: "2747e9d9-cc01-4720-a28f-d6e7d2e8fa2d",
        type: "data2",
        params: {
          modelUuid: PREMIERS_MODEL_UUID,
          dashboardUuid: "",
          includeGaps: false,
          dimensions: [
            {
              id: "1683",
              includeItems: true,
              includeAttributesByCodes: [
                "NAME",
                "SHORT_NAME",
                "SORT_ORDER",
                "PERIOD_CODE",
              ],
            },
          ],
          indicators: {
            id: "1692",
            items: vpIndicators,
          },
          dataRequestCaching: true,
          dataFilter: {
            type: "EQ",
            version: 1,
            operands: [
              {
                type: "DIM",
                version: 1,
                id: "1683",
              },
              {
                type: "CONST",
                version: 1,
                values: [period.apiV2],
              },
            ],
          },
        },
      },
    ],
    GET_PREMIERS_TOTALS
  );
};
