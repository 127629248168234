import { Box } from "@mui/material";
import React from "react";
import { TableProps, UseTableRowProps } from "react-table";
import { TableCell } from "SRC/components/TableCell";

interface CustomTableProps extends TableProps {
  row: UseTableRowProps<any>;
  rows?: UseTableRowProps<any>[];
}

export const getStructureColumns = (isOM: boolean) => [
  {
    Header: "No",
    accessor: " ",
    width: 50,
    Cell: (tableProps: CustomTableProps) => (
      <TableCell color="gray">{tableProps.row.index}</TableCell>
    ),
  },
  {
    Header: "Тип СЭ",
    accessor: "type",
    width: 80,
    Cell: (tableProps: CustomTableProps) => (
      <TableCell>{tableProps.row.original.type}</TableCell>
    ),
  },
  {
    Header: "Наименование СЭ",
    accessor: "name",
    width: "auto",
    Cell: (tableProps: CustomTableProps) => (
      <TableCell>{tableProps.row.original.name}</TableCell>
    ),
  },
  /*
  {
    Header: "Ответственный исполнитель",
    accessor: "curator",
    width: "auto",
    Cell: (tableProps: CustomTableProps) => (
      <TableCell color="gray">{tableProps.row.original.curator}</TableCell>
    ),
  },
  {
    Header: "Количество показателей",
    accessor: "indicatorsNumber",
    width: 200,
    Cell: (tableProps: CustomTableProps) => (
      <TableCell>{tableProps.row.original.indicatorsNumber}</TableCell>
    ),
  },
  {
    Header: "Количество мероприятий",
    accessor: "eventsNumber",
    width: 200,
    Cell: (tableProps: CustomTableProps) => (
      <TableCell>{tableProps.row.original.eventsNumber}</TableCell>
    ),
  },
  */
  {
    Header: "Уровень достижения, %",
    accessor: "performance",
    width: 200,
    Cell: (tableProps: CustomTableProps) => {
      const { performance, performanceOpinion } = tableProps.row.original;
      const achievementNumber = (isOM ? performanceOpinion : performance) || 0;

      const foivColor = performance < 100 ? "white" : "green";
      const opinionColor =
        Number(performanceOpinion) === 100 ? "paleGreen" : "blue";

      return (
        <TableCell color={isOM ? opinionColor : foivColor}>
          {achievementNumber}
        </TableCell>
      );
    },
  },
  {
    Header: (
      <div style={{ display: "flex" }}>
        <Box>Касса, %</Box>
      </div>
    ),
    accessor: "payment",
    width: 200,
    Cell: (tableProps: CustomTableProps) => (
      <TableCell>{tableProps.row.original.cash}</TableCell>
    ),
  },
];
