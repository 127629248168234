import React from "react";

type TRtspVideo = {
  url: string;
};
export const RtspVideo = ({ url }: TRtspVideo) => {
  return (
    <iframe
      src={url}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowFullScreen
      title="Embedded"
    />
  );
};
