import { Box, Button, SxProps, useTheme } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Column, TableInstance } from "react-table";
import { SelectWithSearchStructure } from "SRC/components/SelectWithSearch/SelectWithSearchStructure";
import Table from "SRC/components/Table";
import { FilterTaskStructures } from "SRC/pages/Ministry/common/filters/FilterTasksStructure";
import { getIndicatorsSEColumns } from "SRC/pages/Ministry/common/IndicatorsSETab/tableColumns";
import { css } from "SRC/pages/Ministry/common/ministriesTabs.styled";
import { MinistriesTabsSearch } from "SRC/pages/Ministry/common/MinistriesTabsSearch/MinistriesTabsSearch";
import { Statuses } from "SRC/pages/Ministry/common/Statuses/Statuses";
import { useSEIndicatorsTableData } from "SRC/pages/Ministry/tableData/useSEIndicatorsTableData";
import { useGlobalFilters } from "SRC/redux/slices/global/hooks/useFilters";
import { useFilters } from "SRC/redux/slices/ministry/hooks/useFilters";
import { MINISTRY_EVENT_VIEW, setParamsToUrl } from "SRC/urls";

import { FilterNameStructure } from "../filters/FilterNameStructure";

const RenderTable = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const selectStyle = useMemo(() => css.selector(theme), [theme]);
  const {
    tableState: initialState,
    setSelectedStructureElementType,
    setSelectedStructureElementTask,
    setSelectedStructureElementName,
    selectedStructureElementType,
  } = useFilters();
  const { sources } = useGlobalFilters();
  const { indicatorsTableData: data, indicatorsStatuses: statuses } =
    useSEIndicatorsTableData();
  const columns: Column[] = useMemo(
    () => getIndicatorsSEColumns(sources.OM),
    [sources]
  );

  const [searchValue, setSearchValue] = useState<string | undefined>();
  const [instance, setInstance] = useState<TableInstance | undefined>(
    undefined
  );

  const handleClearFilters = () => {
    setSelectedStructureElementType("");
    setSelectedStructureElementName("");
    setSelectedStructureElementTask("");
    setTableFilter("");
  };

  useEffect(() => {
    handleClearFilters();
  }, []);

  const handleOnClick = useCallback(
    (id: number | string) => {
      return navigate(
        setParamsToUrl(MINISTRY_EVENT_VIEW, { id, ministryId: "01" })
      );
    },
    [navigate]
  );
  const setTableFilter = useCallback(
    (value?: string) => {
      instance?.setGlobalFilter(value);
      setSearchValue(value);
    },
    [instance]
  );
  return (
    <Box sx={css.modalContainer}>
      <Box sx={css.searchSelectContainer}>
        <Box sx={{ flex: "1", minWidth: "20rem" }}>
          <MinistriesTabsSearch value={searchValue} onChange={setTableFilter} />
        </Box>
        <SelectWithSearchStructure
          label="Тип структурного элемента"
          staticLabel={false}
          style={selectStyle}
          onChange={setSelectedStructureElementType}
          value={selectedStructureElementType}
        />
        <FilterNameStructure
          label="Наименование структурного элемента"
          staticLabel={false}
          style={selectStyle}
          onChange={setSelectedStructureElementName}
        />
        <FilterTaskStructures
          label="Наименование задачи"
          staticLabel={false}
          style={selectStyle}
          onChange={setSelectedStructureElementTask}
        />
        <Button sx={css.filterButton as SxProps} onClick={handleClearFilters}>
          Сбросить фильтры
        </Button>
      </Box>
      <Statuses statuses={statuses} />
      <Table
        data={data}
        columns={columns}
        sortedColumns={[
          "Период достижения",
          "План на год",
          "Уровень достижения, %",
        ]}
        onClick={handleOnClick}
        initialState={initialState}
        getInstance={setInstance}
      />
    </Box>
  );
};

export const IndicatorsSETab = () => {
  return <RenderTable />;
};
