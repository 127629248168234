import React from "react";
import {
  CardsAnalitic,
  ICardsAnalitic,
} from "SRC/components/CardsAnalitic/CardsAnalitic";
import { CarouselItem } from "SRC/components/CarouselNew/CarouselItem";
import { OverlayBlock } from "SRC/components/OverlayBlock";
import { useStats } from "SRC/redux/slices/main/hooks/useStats";

export const AnalyticsCardItems = ({ children }: any) => {
  const { fetched } = useStats();

  return children.map((item: any, i: number) => {
    const cardProps: ICardsAnalitic = {
      style: { height: "100%", ...(item.style || {}) },
    };

    if (item.name !== "Риски") {
      cardProps.headerLeft = item.name;
      cardProps.headerRight = item.rightTitle;
      cardProps.inDevelopment = item.inDevelopment;
    } else {
      cardProps.inDevelopment = item.inDevelopment;
      cardProps.headerRight = item.rightTitle;
    }

    return (
      <CarouselItem
        key={i}
        visibleItems={4}
        style={{
          padding: "0 0.5rem",
          pointerEvents: item.name === "Опросы населения" ? "none" : "unset",
          opacity: item.name === "Опросы населения" ? 0.4 : 1,
        }}
      >
        <CardsAnalitic {...cardProps}>
          <OverlayBlock isFetching={!fetched} hasData={Boolean(item)}>
            {item.component}
          </OverlayBlock>
        </CardsAnalitic>
      </CarouselItem>
    );
  });
};
