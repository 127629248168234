import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { isContainedArray } from "SRC/helpers/dataTesters";
import { IPeriod } from "SRC/redux/slices/global/slices/filters";
import { getGoals } from "SRC/redux/slices/main/api/client";

export interface IGoal {
  GP_LIST: string;
  NAME: string;
  code: number;
  data: Record<string, number>;
  NT_CODE: string;
  VP_LIST: string;
}

export interface IGoalsState {
  items: IGoal[];
  fetching: boolean;
  fetched: boolean;
  error: string;
}

export const extraActions = {
  get: createAsyncThunk(
    "goals/get",
    async (period: IPeriod) => await getGoals(period)
  ),
};

const initialState: IGoalsState = {
  items: [],
  fetching: false,
  fetched: false,
  error: "",
};

const slice = createSlice({
  name: "goals",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActions.get.pending, (state) => {
        state.items = [];
        state.fetching = true;
        state.fetched = false;
      })
      .addCase(extraActions.get.fulfilled, (state: IGoalsState, action) => {
        state.items = (
          isContainedArray(action.payload.data) ? action.payload.data : []
        ) as IGoal[];
        state.fetching = false;
        state.fetched = true;
      })
      .addCase(extraActions.get.rejected, (state, action) => {
        state.fetching = false;
        // state.error = action.error;
      });
  },
});

export const actions = {
  ...slice.actions,
  ...extraActions,
};

export default slice;
