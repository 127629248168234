import { Box, styled } from "@mui/material";

export const Wrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  gap: "1rem",

  "> .container": {
    position: "relative",
    flex: "1 1 21rem",
    maxHeight: "21rem",
  },
}));
