import { Theme } from "@mui/material";
import React, { useState } from "react";
import { ExtendedTabs, ITab } from "SRC/components/ExtendedTabs/ExtendedTabs";
import { StructureAnalytics } from "SRC/pages/Structure/common/StructureAnalytics/StructureAnalytics";
import { EventsTab } from "SRC/pages/Structure/common/StructureTabs/EventsTab/EventsTab";
import { GeneralTab } from "SRC/pages/Structure/common/StructureTabs/GeneralTab/GeneralTab";
import { IndicatorsTab } from "SRC/pages/Structure/common/StructureTabs/IndicatorsTab/IndicatorsTab";

import { css, StackWrapper } from "./center-layout.styled";

interface ICenterLayoutProps {
  theme: Theme;
  isLargeSize: boolean;
}
export const CenterLayout = ({ theme }: ICenterLayoutProps) => {
  const tabs: ITab<number>[] = [
    {
      value: 0,
      label: "Общее",
      component: <GeneralTab />,
    },
    {
      value: 1,
      label: "Показатели",
      component: <IndicatorsTab />,
    },
    {
      value: 2,
      label: "Мероприятия",
      component: <EventsTab />,
    },
  ];

  const [activeTab, setActiveTab] = useState<number>(0);
  const handleTabChange = (value: number) => setActiveTab(value);

  return (
    <StackWrapper>
      <div className="title">Структурные элементы</div>
      <StructureAnalytics />
      <div className="table">
        <ExtendedTabs
          value={activeTab}
          onChange={handleTabChange}
          tabs={tabs}
          style={css.tabs}
          borderStyle="solid"
        />
      </div>
    </StackWrapper>
  );
};
