import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createPeriodDims,
  IParsedItem,
  parseOlapdata,
  TDecoder,
} from "CORE/utils";
import { isContainedArray } from "SRC/helpers/dataTesters";
import { getControlPoints } from "SRC/redux/slices/event/api/client";
import { IEventParams } from "SRC/types";

const decoder: TDecoder = {
  period: createPeriodDims(1369),
  info: {
    code: 1374,
    dimensions: {
      name: "NAME",
      code: "R_ID",
      order: "SORT_ORDER",
      comment: "COMMENT",
    },
  },
};

export interface IControlPointInfo {
  name: string;
  code: string;
  order: string;
  comment: string;
}

export interface IControlPoint extends IParsedItem {
  info: IControlPointInfo;
}

export interface IControlPointsState {
  items: IControlPoint[];
  fetching: boolean;
  fetched: boolean;
  error: string;
}

export const extraActions = {
  get: createAsyncThunk(
    "controlPoints/get",
    async (params: IEventParams) => await getControlPoints(params)
  ),
};

const initialState: IControlPointsState = {
  items: [],
  fetching: false,
  fetched: false,
  error: "",
};

const slice = createSlice({
  name: "controlPoints",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActions.get.pending, (state) => {
        state.items = [];
        state.fetching = true;
        state.fetched = false;
      })
      .addCase(
        extraActions.get.fulfilled,
        (state: IControlPointsState, action) => {
          const parsed = parseOlapdata(
            // @ts-ignore
            action.payload.data,
            decoder
          ) as unknown as IControlPoint[];
          state.items = isContainedArray(parsed) ? parsed : [];
          state.fetching = false;
          state.fetched = true;
        }
      )
      .addCase(extraActions.get.rejected, (state, action) => {
        state.fetching = false;
      });
  },
});

export const actions = {
  ...slice.actions,
  ...extraActions,
};

export default slice;
