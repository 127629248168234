import { actions as methods } from "./slices/methods";

export interface IActions {
  methods: typeof methods;
}

const actions: IActions = {
  methods,
};

export default actions;
