import { SxProps } from "@mui/material";
import React, { useMemo } from "react";
import SelectWithSearch from "SRC/components/SelectWithSearch/SelectWithSearch";
import { useSEIndicatorsTableData } from "SRC/pages/Program/tableData/useSEIndicatorsTableData";

type TOptionValue = string | number;

interface IOption {
  value: TOptionValue;
  label: string;
  disabled?: boolean;
}

interface IStyle {
  labelStyle?: SxProps;
  selectStyle?: SxProps;
  optionStyle?: SxProps;
}

interface IFilterFeatureProps {
  value: string;
  onChange(value: TOptionValue): void;
  label?: string;
  staticLabel?: boolean;
  colorizeActiveOption?: boolean;
  style: IStyle;
}

export const FilterTypeStructure = ({
  onChange,
  value,
  staticLabel = true,
  label,
  colorizeActiveOption = true,
  style,
}: IFilterFeatureProps) => {
  const { uniqueTypeProjects } = useSEIndicatorsTableData();

  const selectTypes = useMemo(() => {
    return uniqueTypeProjects.map((item) => {
      return {
        value: item,
        label: item,
      };
    });
  }, [uniqueTypeProjects]);

  const customOptions = useMemo(
    () => [
      {
        value: "",
        label: label || "Все",
      },
      ...(selectTypes as IOption[]),
    ],
    [label, selectTypes]
  );
  return (
    <SelectWithSearch
      onChange={onChange}
      options={customOptions}
      getOptionLabel={(option: IOption) => option.label}
      staticLabel={staticLabel}
      label={label}
      colorizeActiveOption={colorizeActiveOption}
      style={style}
      hasCloseIcon={Boolean(value)}
      value={value}
    />
  );
};
