import { CellObject } from "sheetjs-style";
import { EExtensions } from "SRC/constants";
import { IFile } from "SRC/types/data/data";
import { Range } from "xlsx";

export const bgColors = {
  red: "FFAAAA",
  green: "AAFFAA",
  lightgreen: "C6E0B4",
  blue: "0070C0",
  lightblue: "DDEBF7",
  yellow: "FFEC82",
  default: "FFFFFF",
  reverse: "000000",
};

export const txtColors = {
  red: "AA3333",
  green: "33AA33",
  lightgreen: "C6E0B4",
  blue: "4F81BD",
  lightblue: "DDEBF7",
  yellow: "FFCC00",
  default: "000000",
  reverse: "FFFFFF",
};

interface ICellStyles {
  color?: keyof typeof txtColors;
  fill?: keyof typeof bgColors;
  size?: number;
  bold?: boolean;
  textAlign?: "left" | "center" | "right";
}

export type TCellStyles = CellObject["s"] & ICellStyles;

export interface IWorkbookColumn {
  key: string;
  title: string;
  styles?: ICellStyles;
  hstyles?: ICellStyles;
  children?: IWorkbookColumn[];
}

export enum EWorkbookRow {
  VP = "VP",
  DATA = "DATA",
  TOTAL = "TOTAL",
  COMMENT = "COMMENT",
}

export interface IWorkbookRow
  extends Partial<Record<string, null | string | number>> {
  rn: number;
  type: EWorkbookRow;
}

export interface IWorkbookList {
  columns: IWorkbookColumn[];
  caption?: string;
  name: string;
  rows: IWorkbookRow[];
}

export interface ITablesParams {
  wscols: Record<string, number>[];
  whrows: Record<string, number>[];
  headings: Record<string, string>[];
  merges: Range[];
  row: number;
  styles: { [addr: string]: Partial<CellObject> };
}

export interface IWorkbookData {
  file: string;
  lists: IWorkbookList[];
}

export type TConfig = Record<"title" | "cell" | "header", TCellStyles>;

export interface IUseExcelCreator {
  file: IFile | null;
  setJson(data: IWorkbookData | null, ext: EExtensions, config?: TConfig): void;
  setExtension: any;
  extension: EExtensions;
  loadExportFile(
    data: IWorkbookData | null,
    ext: EExtensions,
    config?: TConfig
  ): IFile | null;
  changeExtension(ext: EExtensions, callback: (file: IFile) => void): void;
}
