import { Tooltip } from "@mui/material";
import { TooltipProps } from "@mui/material/Tooltip/Tooltip";
import React from "react";
import { CheckSecretData } from "SRC/components/CheckSecretData";
import { NavigateCell } from "SRC/components/Table";
import { TableCell } from "SRC/components/TableCell";
import { ETitle } from "SRC/components/TableColumnTemplates/columns/indicators/indicatorsFact";
import { sortStructureElementsFailColumn } from "SRC/helpers";
import { ICustomTableProps } from "SRC/types";

import {
  getStructureElementsFail,
  getStructureElementsFailTotal,
} from "./utils";

export const structureElementsFail = (isOM: boolean) => ({
  Header: "Не выполнено",
  Tooltip: ({ children, ...props }: TooltipProps) => (
    <Tooltip {...props} title={ETitle.FAIL_TITLE}>
      {children}
    </Tooltip>
  ),
  sortType: sortStructureElementsFailColumn(isOM),
  width: 130,
  accessor: isOM ? "structureElementsOMFail" : "structureElementsFail",
  Cell: (tableProps: ICustomTableProps) => {
    const { url, structureElementsFail, isSecret } = getStructureElementsFail(
      tableProps,
      isOM
    );

    return (
      <NavigateCell url={url}>
        <TableCell color={isOM ? "paleRed" : "red"}>
          <CheckSecretData isSecret={isSecret}>
            {structureElementsFail}
          </CheckSecretData>
        </TableCell>
      </NavigateCell>
    );
  },
  Footer: (tableProps: ICustomTableProps) => {
    const { structureElementsFailTotal } = getStructureElementsFailTotal(
      tableProps,
      isOM
    );

    return (
      <Tooltip title="В расчете участвуют уникальные элементы">
        <TableCell color={isOM ? "paleRed" : "red"}>
          {structureElementsFailTotal}
        </TableCell>
      </Tooltip>
    );
  },
});
