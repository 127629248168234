import React from "react";

import { Wrapper } from "./Footer.styled";

export const Footer = () => {
  return (
    <Wrapper>
      <a href="mailto:777@roskazna.ru?subject=Расчёт УД Госпрограмм">
        Обратиться в службу поддержки
      </a>
    </Wrapper>
  );
};
