import { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { isSecret } from "SRC/helpers/dataTesters";
import {
  IMinistries,
  useMinistries,
} from "SRC/redux/slices/summary/hooks/useMinistries";
import {
  IPremiers,
  usePremiers,
} from "SRC/redux/slices/summary/hooks/usePremiers";
import {
  IPrograms,
  usePrograms,
} from "SRC/redux/slices/summary/hooks/usePrograms";
import slice, {
  EGridTabs,
  IFiltersState,
} from "SRC/redux/slices/summary/slices/filters";

import { useAppSelector } from "../../../hooks";

export { EGridTabs } from "SRC/redux/slices/summary/slices/filters";

export interface IFilters extends IFiltersState {
  clear(): void;
  setSelectedVP(code: string): void;
  setSelectedMinistry(code: string): void;
  setSelectedProgram(code: string): void;
  filteredVPs: (string | number)[];
  filteredMinistries: (string | number)[];
  filteredPrograms: (string | number | null)[];
  filteredType: (string | number)[];
  setSelectedComplexGridTab(tab?: EGridTabs): void;
  setSearchRows(rows: Record<string, any>[]): void;
}

export const useFilters = (): IFilters => {
  const dispatch = useDispatch();
  const { withSecret } = useAppSelector((state) => state.global.filters);
  const filters = useAppSelector((state) => state.summary.filters);
  const { vp, program, ministry } = filters;
  const { items: premiers }: IPremiers = usePremiers();
  const { items: programs }: IPrograms = usePrograms();
  const { items: ministries }: IMinistries = useMinistries();

  const filteredVPs = useMemo(() => {
    return premiers.map((premier) => {
      return premier.info.VP_CODE;
    });
  }, [premiers]);

  const filteredPrograms = useMemo(
    () =>
      programs
        .filter((program) => {
          const isVp = !vp || program.info.vpCode === vp;
          const isMinistry = !ministry || program.info.ministry === ministry;
          const isSecretProgram = withSecret || !isSecret(program);
          return isVp && isMinistry && isSecretProgram;
        })
        .map((program) => program.info.code),
    [programs, vp, ministry, withSecret]
  );

  const filteredType = useMemo(
    () =>
      programs
        .filter((program) => filteredPrograms.includes(program.info.code))
        .map((program) => program.info.code),
    [programs, filteredPrograms]
  );

  const filteredMinistries: string[] = useMemo(
    () =>
      ministries
        .filter((item) => !ministry || item.info.code === ministry)
        .map((item) => item.info.code),
    [ministries, program]
  );

  const setSelectedProgram = (id: string) =>
    dispatch(slice.actions.selectProgram(program === id ? "" : id));

  const setSelectedVP = (id: string) => {
    dispatch(slice.actions.selectVP(vp === id ? "" : id));

    if (id) {
      setSelectedProgram("");
    }
  };

  const setSelectedMinistry = (id: string) => {
    dispatch(slice.actions.selectMinistry(ministry === id ? "" : id));
    if (id) {
      setSelectedProgram("");
      // dispatch(slice.actions.selectMinistry(""));
    }
  };

  const clear = useCallback(() => dispatch(slice.actions.clear()), [dispatch]);

  const setSelectedComplexGridTab = (tab?: EGridTabs) => {
    dispatch(slice.actions.selectProgram(""));
    dispatch(slice.actions.selectMinistry(""));
    dispatch(slice.actions.selectVP(""));
    dispatch(slice.actions.setComplexGridTab(tab || EGridTabs.PROGRAMS));
  };

  const setSearchRows = (rows: Record<string, any>[]) => {
    dispatch(slice.actions.selectSearchRows(rows));
  };

  return {
    ...filters,
    setSelectedMinistry,
    setSelectedVP,
    setSelectedProgram,
    clear,
    filteredVPs,
    filteredMinistries,
    filteredPrograms,
    filteredType,
    setSelectedComplexGridTab,
    setSearchRows,
  };
};
