import { Box, Button } from "@mui/material";
import { SmallWhiteArrowUp } from "ASSETS/svg";

import { css } from "./ScrollButton.styled";

interface IScrollButtonProps {
  position: string;
  handleScroll(): void;
  disabled?: boolean;
}

export const ScrollButton = ({
  position,
  handleScroll,
  disabled,
}: IScrollButtonProps) => (
  <Box sx={css.listShadow(position)}>
    <Button onClick={handleScroll} disabled={disabled}>
      <SmallWhiteArrowUp />
    </Button>
  </Box>
);
