import { createSlice } from "@reduxjs/toolkit";

export interface IProgramsBoard {}

const initialState: IProgramsBoard = {};

const slice = createSlice({
  name: "programsBoard",
  initialState,
  reducers: {},
});

export const actions = {
  ...slice.actions,
};

export default slice;
