import { Box, Button, Typography } from "@mui/material";
import { IconPhotoTable, IconPlayTable } from "ASSETS/svg";
import { ObjectDoubleArrow } from "ASSETS/svg/arrow";
import moment from "moment";
import React from "react";
import { TableProps, UseTableRowProps } from "react-table";
import { css as tableCss } from "SRC/components/Table/Table.styled";
import { TableCell } from "SRC/components/TableCell";
import { TooltipText } from "SRC/components/TooltipText/TooltipText";
import { WIPTag } from "SRC/components/WIP";
import { ITableObject } from "SRC/pages/Event/common/EventTabs/ObjectTab/util/interfaces";
import { css } from "SRC/pages/Event/common/EventTabs/ObjectTab/util/objectTableData.styled";

interface CustomTableProps extends TableProps {
  row: UseTableRowProps<ITableObject>;
  rows?: UseTableRowProps<ITableObject>[];
}

const periodDate = (date: string) =>
  moment(date).isValid() ? moment(date).format("DD.MM.YYYY") : null;

type onClickType = (id: string) => void;

const getObjectColumns = (
  onPhotoClick: onClickType,
  onVideoClick: onClickType
) => {
  return [
    {
      Header: " ",
      accessor: "icons",
      width: 50,
      Cell: (tableProps: CustomTableProps) => (
        <TableCell>
          {tableProps.row.original.hasPhoto && (
            <Button
              sx={css.buttonStyle}
              onClick={() => onPhotoClick(tableProps.row.original.id)}
            >
              <IconPhotoTable />
            </Button>
          )}
          {tableProps.row.original.hasVideo && (
            <Button
              sx={css.buttonStyle}
              onClick={() => onVideoClick(tableProps.row.original.id)}
            >
              <IconPlayTable />
            </Button>
          )}
        </TableCell>
      ),
    },
    {
      Header: "Название объекта",
      accessor: "name",
      width: "auto",
      Cell: (tableProps: CustomTableProps) => (
        <TableCell className="with-right-border">
          {tableProps.row.original.name}
        </TableCell>
      ),
    },
    {
      Header: "Регион/город",
      accessor: "location",
      width: "15%",
      Cell: (tableProps: CustomTableProps) => (
        <Box
          sx={tableCss.tableCellText()}
          className="with-right-border columnDirection verticalPadding"
        >
          <TableCell>{tableProps.row.original.location}</TableCell>
        </Box>
      ),
    },
    {
      Header: "Мощность",
      accessor: "capacity",
      width: "15%",
      Cell: (tableProps: CustomTableProps) => (
        <TableCell>{tableProps.row.original.capacity}</TableCell>
      ),
    },
    {
      accessor: "ready",
      Header: (
        <div style={{ display: "flex" }}>
          <Box sx={css.construction}>Строительная готовность</Box>
          <WIPTag
            sx={{
              display: "inline-block",
              marginLeft: "0.5rem",
              flexWrap: "nowrap",
            }}
          />
        </div>
      ),
      width: "20%",
      Cell: (tableProps: CustomTableProps) => (
        <TableCell>{tableProps.row.original.ready + " %"}</TableCell>
      ),
    },
    {
      Header: "Период реализации",
      accessor: "datePlan",
      width: "10%",
      Cell: (tableProps: CustomTableProps) => (
        <Box
          sx={tableCss.tableCellText()}
          className="withMarginLeft verticalPadding"
        >
          <Box sx={css.datePlanContainer}>
            <Box sx={css.dates}>
              <TableCell>
                {periodDate(tableProps.row.original.datePlan[0])}
              </TableCell>
              <TableCell>
                {periodDate(tableProps.row.original.datePlan[1])}
              </TableCell>
            </Box>
          </Box>
        </Box>
      ),
    },
    {
      Header: "",
      accessor: "button",
      width: "8%",
      Cell: (tableProps: CustomTableProps) => {
        const onStand = window.top !== window.self;

        let oksHref;
        if (onStand && window.top) {
          const { origin } = window.top.parent.location;

          oksHref = `${origin}/pages/smnp-oks#/v2-objects/`;
        } else {
          oksHref = `http://smnp-oks-front.gasu-development.ibs.ru/#/v2-objects/`;
        }

        oksHref += tableProps.row.original.id;

        return (
          <TooltipText
            variant="h3"
            hint="Переход в карточку объекта (АРМ Строительство)"
            placement="left"
          >
            <a target="_blank" href={oksHref} rel="noreferrer">
              <ObjectDoubleArrow />
            </a>
          </TooltipText>
        );
      },
    },
  ];
};

export { getObjectColumns };
