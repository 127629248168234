import { Box, Stack, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { IncidentReport } from "SRC/components/IncidentReport";
import { OverlayBlock } from "SRC/components/OverlayBlock";
import { PerformanceValue } from "SRC/components/PerformanceValue/PerformanceValue";
import { OverlayProgressBar } from "SRC/components/ProgressBar";
import { SourceValue } from "SRC/components/SourceValue/SourceValue";
import { WIPTag } from "SRC/components/WIP";
import { convertSourceValue, ESources } from "SRC/constants/globals";
import { useGlobalFilters } from "SRC/redux/slices/global/hooks/useFilters";
import { useIndicatorData } from "SRC/redux/slices/indicator/hooks/useIndicatorData";

import { css, Wrapper } from "./Sidebar.styled";

export const Sidebar = () => {
  const { items: indicator, fetching } = useIndicatorData();
  const { sources } = useGlobalFilters();

  const data = useMemo(() => {
    return {
      performance: {
        percent: {
          [ESources.FOIV]: indicator?.data?.[5244]?.sum || 0,
          [ESources.OM]: indicator?.data?.[5471]?.sum || 0,
        },
        units: "%",
      },
    };
  }, [indicator]);

  const [performance] = useMemo(() => {
    return convertSourceValue(data.performance.percent, sources);
  }, [sources, data.performance.percent]);

  return (
    <OverlayBlock isFetching={fetching} hasData={Boolean(indicator)}>
      <Wrapper>
        <Stack sx={css.content}>
          <IncidentReport className="incident-container">
            <Box sx={css.achievement}>
              <Typography sx={css.title}>Уровень достижения</Typography>
            </Box>
            <Box sx={css.achievement}>
              <SourceValue {...performance} unit>
                <PerformanceValue
                  value={String(performance.value)}
                  sameSize={true}
                />
              </SourceValue>
            </Box>
            <OverlayProgressBar values={[performance]} />
          </IncidentReport>
          <Box sx={css.valuesBox}>
            <Box sx={css.achievement}>
              <Typography sx={css.title}>Количество корректировок</Typography>
              <WIPTag />
            </Box>
            <Box sx={css.row}>
              <Typography sx={css.label}>ухудшилось</Typography>
              <Box sx={css.valueRow}>
                <Typography sx={css.value} className="red">
                  {0}
                </Typography>
              </Box>
            </Box>
            <Box sx={css.row}>
              <Typography sx={css.label}>улучшилось</Typography>
              <Box sx={css.valueRow}>
                <Typography sx={css.value} className="green">
                  {0}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Stack>
      </Wrapper>
    </OverlayBlock>
  );
};
