import { getRoundValue } from "SRC/helpers/getRoundValue";
import { ICustomTableProps } from "SRC/types";

export const getCashPlanTotal = (tableProps: ICustomTableProps) => {
  let total = tableProps?.rows?.[0]?.original.cashPlanTotal || 0;

  return {
    total: getRoundValue(total, 2),
  };
};
