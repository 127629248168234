import { Box, useTheme } from "@mui/material";
import React, { useMemo } from "react";
import ProgramsCounter from "SRC/components/ProgramsCounter/ProgramsCounter";
import Select, { IOption } from "SRC/components/Select/Select";
import { IFilters, useFilters } from "SRC/redux/slices/main/hooks/useFilters";
import {
  IPremiers,
  usePremiers,
} from "SRC/redux/slices/main/hooks/usePremiers";
import themeConfig from "SRC/theme";

import { css } from "./Filters.styled";

const foivOptions = [
  {
    value: "foiv0",
    label: "Минпромторг",
  },
  {
    value: "foiv1",
    label: "Минтруд",
  },
  {
    value: "foiv2",
    label: "Росрезерв",
  },
];

interface IProps {
  withCounter?: boolean;
}

export const Filters = ({ withCounter = true }: IProps) => {
  const theme = useTheme<typeof themeConfig>();
  const { vp, foiv, setSelectedVP, setSelectedFoiv, filteredVPs }: IFilters =
    useFilters();

  const { items: vpItems }: IPremiers = usePremiers();

  const premiers: IOption<string>[] = useMemo(
    () =>
      vpItems
        .filter((item) => filteredVPs.includes(item.info.VP_CODE))
        .map((item) => ({
          value: item.info.VP_CODE,
          label: item.info.VP_SHORT_NAME,
        })),
    [vpItems, filteredVPs]
  );

  const selectStyle = useMemo(() => css.selector(theme), [theme]);

  return (
    <Box sx={css.container}>
      <Box sx={css.selectorsWrapper}>
        <Select
          value={vp}
          onChange={setSelectedVP}
          options={premiers}
          label="Вице-премьеры"
          style={selectStyle}
        />
        <Select
          value={foiv}
          onChange={setSelectedFoiv}
          options={foivOptions}
          label="Министерства"
          style={selectStyle}
        />
      </Box>
      {withCounter && (
        <Box sx={css.indicator}>
          <Box sx={css.label}>Кол-во госпрограмм</Box>
          <ProgramsCounter />
        </Box>
      )}
    </Box>
  );
};
