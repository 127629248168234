import { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { TableState } from "react-table";
import { useAppSelector } from "SRC/redux/hooks";
import {
  IMinistries,
  useMinistries,
} from "SRC/redux/slices/ministry/hooks/useMinistries";
import {
  IPrograms,
  usePrograms,
} from "SRC/redux/slices/ministry/hooks/usePrograms";
import slice, { IFiltersState } from "SRC/redux/slices/ministry/slices/filters";

export interface IFilters extends IFiltersState {
  filteredPrograms: (string | number)[];
  filteredMinistries: (string | number)[];
  setSelectedMinistry(code: string): void;
  setSelectedProgram(code: string): void;
  setSelectedStatus(status: string): void;
  setSelectedStructureElementType(type: string): void;
  setSelectedStructureElementName(name: string): void;
  setSelectedStructureElementTask(task: string): void;
  setSelectedEventFeature(feature: string): void;
  setTableState(state: Partial<TableState> | undefined): void;
  clearSelectedStatus(): void;
}

export const useFilters = (): IFilters => {
  const dispatch = useDispatch();
  const filters = useAppSelector((state) => state.ministry.filters);
  const { ministry } = filters;
  const { items: programs }: IPrograms = usePrograms();
  const { items: ministries }: IMinistries = useMinistries();

  const filteredPrograms = useMemo(
    () =>
      programs
        .filter(({ info }) => info.ministry === ministry)
        .map(({ info: { code } }) => code),
    [programs, ministry]
  );

  const filteredMinistries: string[] = useMemo(
    () =>
      ministries
        .filter((item) => !ministry || item.code === ministry)
        .map((item) => item.code),
    [ministries, ministry]
  );

  const setSelectedProgram = (id: string) =>
    dispatch(slice.actions.selectProgram(id));

  const setSelectedMinistry = (id: string) => {
    dispatch(slice.actions.selectMinistry(id));
    if (id) {
      setSelectedProgram("");
      // dispatch(slice.actions.selectMinistry(""));
    }
  };

  const setSelectedStructureElementType = (type: string) =>
    dispatch(slice.actions.selectStructureElementType(type));

  const setSelectedStructureElementName = (name: string) =>
    dispatch(slice.actions.selectStructureElementName(name));

  const setSelectedStructureElementTask = (task: string) =>
    dispatch(slice.actions.selectStructureElementTask(task));

  const setSelectedEventFeature = (feature: string) =>
    dispatch(slice.actions.selectEventFeature(feature));

  const setSelectedStatus = useCallback(
    (status: string) => {
      return dispatch(slice.actions.selectStatus(status));
    },
    [dispatch]
  );

  const setTableState = (state: Partial<TableState> | undefined) =>
    dispatch(slice.actions.setTableInstance(state));

  const clearSelectedStatus = useCallback(() => {
    return dispatch(slice.actions.clearStatus());
  }, [dispatch]);

  return {
    ...filters,
    filteredPrograms,
    filteredMinistries,
    setSelectedMinistry,
    setSelectedStructureElementType,
    setSelectedStructureElementName,
    setSelectedStructureElementTask,
    setSelectedEventFeature,
    setSelectedProgram,
    setSelectedStatus,
    setTableState,
    clearSelectedStatus,
  };
};
