import { Box, Paper, useMediaQuery, useTheme } from "@mui/material";
import React, { MutableRefObject, useMemo, useState } from "react";
import { TableInstance } from "react-table";
import { OpenerButton, TableExportButton } from "SRC/components/Buttons";
import { ExtendedTabs, ITab } from "SRC/components/ExtendedTabs/ExtendedTabs";
import ProgramsCounter from "SRC/components/ProgramsCounter/ProgramsCounter";
import Select, { IOption } from "SRC/components/Select/Select";
import { ColumnsController } from "SRC/components/Table";
import {
  IGeneralInfoDataItemWithoutIcon,
  IGeneralInfoPremiersDataItemWithoutId,
  IMinistriesTableItemWithoutId,
} from "SRC/hooks/summaryTable/interfaces";
import { useTableData as useTableDataMinistries } from "SRC/hooks/summaryTable/useSummaryMinistries";
import { useTableData as useTableDataPremiers } from "SRC/hooks/summaryTable/useSummaryPremiers";
import { useTableData as useTableDataPrograms } from "SRC/hooks/summaryTable/useSummaryPrograms";
import { GeneralInfoTab } from "SRC/pages/Summary/common/SummaryComplexGrid/GeneralInfoTab/GeneralInfoTab";
import {
  EGridTabs,
  IFilters,
  useFilters,
} from "SRC/redux/slices/summary/hooks/useFilters";
import themeConfig from "SRC/theme";
import { IExportData } from "SRC/types";

import { Filters } from "../Filters/Filters";
import {
  GeneralFullInfoMinistriesTab,
  staticColumns as mStatic,
} from "./GeneralFullInfoMinistriesTab/GeneralFullInfoMinistriesTab";
import {
  GeneralFullInfoPremiersTab,
  staticColumns as vpStatic,
} from "./GeneralFullInfoPremiersTab/GeneralFullInfoPremiersTab";
import {
  GeneralFullInfoTab,
  staticColumns as gpStatic,
} from "./GeneralFullInfoTab/GeneralFullInfoTab";
import { GeneralInfoPremiersTab } from "./GeneralInfoPremiersTab/GeneralInfoPremiersTab";
import { css } from "./SummaryComplexGrid.styled";

interface ISummaryComplexGrid {
  forwardRef?: MutableRefObject<HTMLElement | undefined>;
  onScroll?: (scroll: any) => void;
  scrollX?: boolean;
  setSortBy: (sortBy: any) => void;
}
type TDData =
  | IGeneralInfoDataItemWithoutIcon
  | IGeneralInfoPremiersDataItemWithoutId
  | IMinistriesTableItemWithoutId;

type TExportData<T> = {
  [key: string]: IExportData<T>;
};

const statics: Record<EGridTabs, string[]> = {
  [EGridTabs.PROGRAMS]: gpStatic,
  [EGridTabs.PREMIERS]: vpStatic,
  [EGridTabs.MINISTRIES]: mStatic,
};

const SubComponents: React.FC<ISummaryComplexGrid> = (props) => {
  const { complexGridTab }: IFilters = useFilters();
  const { programsSummaryData: programsData } = useTableDataPrograms();

  return (
    <>
      {complexGridTab === EGridTabs.PREMIERS
        ? [<GeneralInfoPremiersTab {...props} tableData={programsData} />]
        : [<GeneralInfoTab {...props} tableData={programsData} />]}
    </>
  );
};

export const SummaryComplexGrid: React.FC<ISummaryComplexGrid> = (props) => {
  const { forwardRef, onScroll } = props;
  const theme = useTheme<typeof themeConfig>();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));
  const { clear, complexGridTab, setSelectedComplexGridTab }: IFilters =
    useFilters();
  // const { programsExport, premiersExport, ministriesExport } = useTableData();
  const { programsSummaryExport: programsExport } = useTableDataPrograms();
  const { premiersSummaryExport: premiersExport } = useTableDataPremiers();
  const { ministriesSummaryExport: ministriesExport } =
    useTableDataMinistries();
  const [modalOpen, setModalOpen] = useState(false);
  const [tableInstance, setTableInstance] = useState<TableInstance | undefined>(
    undefined
  );
  const onOpenModal = (): void => setModalOpen(true);

  const tabsHandler = (value: EGridTabs) => {
    setModalOpen(false);
    setTableInstance(undefined);
    clear();
    setSelectedComplexGridTab(value);
  };

  const staticColumns: string[] = useMemo(
    () => statics[complexGridTab] || [],
    [complexGridTab]
  );

  const tabExportData: IExportData<TDData> = useMemo(() => {
    const exportData: TExportData<TDData> = {
      [EGridTabs.PROGRAMS]: programsExport,
      [EGridTabs.PREMIERS]: premiersExport,
      [EGridTabs.MINISTRIES]: ministriesExport,
    };
    return exportData[complexGridTab];
  }, [complexGridTab, programsExport, premiersExport, ministriesExport]);

  const tabs: ITab<EGridTabs>[] = [
    {
      label: "Госпрограммы",
      value: EGridTabs.PROGRAMS,
      component: (
        <GeneralFullInfoTab
          onScroll={onScroll}
          forwardRef={forwardRef}
          isModalOpen={modalOpen}
          setModalOpen={setModalOpen}
          instance={tableInstance}
          setInstance={setTableInstance}
        />
      ),
    },
    {
      label: "Вице-премьеры",
      value: EGridTabs.PREMIERS,
      component: (
        <GeneralFullInfoPremiersTab
          onScroll={onScroll}
          forwardRef={forwardRef}
          isModalOpen={modalOpen}
          setModalOpen={setModalOpen}
          instance={tableInstance}
          setInstance={setTableInstance}
        />
      ),
    },
    {
      label: "Министерства",
      value: EGridTabs.MINISTRIES,
      component: (
        <GeneralFullInfoMinistriesTab
          onScroll={onScroll}
          forwardRef={forwardRef}
          isModalOpen={modalOpen}
          setModalOpen={setModalOpen}
          instance={tableInstance}
          setInstance={setTableInstance}
        />
      ),
    },
  ];

  const selectStyle = useMemo(() => css.selector(theme), [theme]);

  return (
    <Box>
      {matches ? (
        <>
          <Box sx={css.tableHeaderWrapper}>
            <Paper
              sx={css.tableTitle}
              component="h1"
              onClick={clear}
              elevation={0}
            >
              Сводная таблица
            </Paper>
            <ProgramsCounter />
          </Box>
          <Box sx={css.divider} />
          <Box sx={css.selectorsWrapper}>
            <Select
              value={complexGridTab}
              onChange={setSelectedComplexGridTab}
              options={tabs as IOption<EGridTabs>[]}
              label="Вид"
              hasAllOption={false}
              colorizeActiveOption={false}
              style={selectStyle}
            />
            <Box sx={css.filters}>
              <Filters withCounter={false} />
            </Box>
          </Box>
          <SubComponents {...props} />
        </>
      ) : (
        <ExtendedTabs
          value={complexGridTab}
          onChange={tabsHandler}
          tabs={tabs}
          style={{ fontSize: "1.5rem", fontWeight: "bold" }}
          actions={[
            tableInstance && (
              <ColumnsController
                {...tableInstance}
                statics={staticColumns}
                key="1"
              />
            ),
            <TableExportButton {...tabExportData} key="2" />,
            <OpenerButton open={modalOpen} setOpen={onOpenModal} key="3" />,
          ]}
        />
      )}
    </Box>
  );
};
