import { useMediaQuery, useTheme } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { LayoutMain } from "SRC/layouts";
import { ComplexGrid } from "SRC/pages/Main/common/ComplexGrid/ComplexGrid";
import { NationalGoalsTable } from "SRC/pages/Main/common/NationalGoalsTable/NationalGoalsTable";
import { useFetchStats } from "SRC/queries/main-stats";
import {
  IGlobalFilters,
  useGlobalFilters,
} from "SRC/redux/slices/global/hooks/useFilters";
import { IFilters, useFilters } from "SRC/redux/slices/main/hooks/useFilters";
import { useMain } from "SRC/redux/slices/main/hooks/useMain";
import {
  IPremiers,
  usePremiers,
} from "SRC/redux/slices/main/hooks/usePremiers";
import {
  useSpheres,
  useSumSpheres,
} from "SRC/redux/slices/main/hooks/useSpheres";
import themeConfig from "SRC/theme";

import { CenterLayout } from "./layouts";

export const Main = () => {
  const theme = useTheme<typeof themeConfig>();
  const isLargeSize = useMediaQuery(theme.breakpoints.up("lg"));
  const { init } = useMain();
  const { load: updateSpheres } = useSpheres();
  const { load: updateSumSpheres } = useSumSpheres();
  const { items: premiers }: IPremiers = usePremiers();
  const { program, vp, sphere, ministry }: IFilters = useFilters();
  const { period }: IGlobalFilters = useGlobalFilters();
  const params = {
    gp_code: program || null,
    vp_code: vp || null,
    period,
    sphere: sphere || null,
    foiv: ministry || null,
  };
  const { stats } = useFetchStats({ params });

  const [calendarDate, setCalendarDate] = useState<Date | null>(null);
  const [hasChanges, setHasChanges] = useState<boolean | undefined>();

  useEffect(() => {
    setHasChanges(!moment(period.value).isSame(calendarDate));
  }, [period, calendarDate]);

  useEffect(() => {
    setHasChanges(Boolean(sphere));
  }, [sphere]);

  useEffect(() => {
    setHasChanges(Boolean(ministry));
  }, [ministry]);

  useEffect(() => {
    setHasChanges(Boolean(program));
  }, [program]);

  useEffect(() => {
    setHasChanges(Boolean(premiers));
  }, [premiers]);

  useEffect(() => {
    setHasChanges(Boolean(vp));
  }, [vp]);

  useEffect((): void => {
    if (hasChanges || (!hasChanges && hasChanges !== false)) {
      if (!calendarDate) {
        init({
          period,
          gp_code: program || null,
          vp_code: vp || null,
          sphere: sphere || null,
          foiv: ministry || null,
        });
      } else {
        if (hasChanges) {
          updateSpheres(params);
          updateSumSpheres(params);
        }
      }

      setCalendarDate(period.value);
    }
  }, [hasChanges, program, vp, sphere, ministry, period]);

  return (
    <LayoutMain
      left={isLargeSize && <ComplexGrid />}
      center={<CenterLayout isLargeSize={isLargeSize} />}
      right={isLargeSize && <NationalGoalsTable />}
    />
  );
};
