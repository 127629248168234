import React from "react";
import { CheckSecretData } from "SRC/components/CheckSecretData";
import { PerformanceValue } from "SRC/components/PerformanceValue/PerformanceValue";
import { StatusPoint } from "SRC/components/StatusPoint/StatusPoint";
import { TableCell } from "SRC/components/TableCell";
import { sortPerformanceColumn } from "SRC/helpers";
import { ICustomTableProps } from "SRC/types";

import { getPerformance, getPerformanceTotal } from "./utils";

export const performance = (isOM: boolean) => ({
  Header: "Уровень достижения, %",
  sortType: sortPerformanceColumn(isOM),
  accessor: (row: any) => {
    const value = isOM ? row.performanceOM : row.performance;

    return Number(value);
  },
  width: 180,
  Cell: (tableProps: ICustomTableProps) => {
    const { performance, formattedPerformance, isSecret } = getPerformance(
      tableProps,
      isOM
    );

    return (
      <TableCell color={isOM ? "blue" : "white"} className="flex-container">
        <CheckSecretData isSecret={isSecret}>
          <StatusPoint value={performance} isPale={isOM} />
          <PerformanceValue value={formattedPerformance} sameSize={true} />
        </CheckSecretData>
      </TableCell>
    );
  },
  Footer: (tableProps: ICustomTableProps) => {
    const { performanceTotal, formattedPerformanceTotal } = getPerformanceTotal(
      tableProps,
      isOM
    );

    return (
      <TableCell color={isOM ? "blue" : "white"} className="flex-container">
        <StatusPoint value={performanceTotal} isPale={isOM} />
        <PerformanceValue value={formattedPerformanceTotal} sameSize={true} />
      </TableCell>
    );
  },
});
