import { styled } from "@mui/material";

interface IWrapperProps {
  height: number;
}
export const Wrapper = styled("div")<IWrapperProps>(({ height }) => {
  return {
    width: "100%",

    "& > .incident-container": {
      position: "relative",
      paddingTop: "3.125rem",
      display: "flex",
      width: "100%",
      maxHeight: "80vh",

      "& > .no-data-text": {
        width: "100%",
        paddingBottom: "3.125rem",
        textAlign: "center",
      },

      "& > .item-list-wrapper": {
        display: "flex",
        flexDirection: "column",
        maxWidth: "31rem",
        position: "relative",
        maxHeight: "90vh",
        flex: "0 0 40%",

        "& > .item-list-left": {
          overflowY: "scroll",
          direction: "rtl",
          display: "flex",
          flexDirection: "column",
          gap: "0.5rem",
          padding: "3.5rem 0 3.5rem 1.375rem",
          scrollBehavior: "smooth",
        },
      },

      "& > .item-list-right": {
        display: "flex",
        flex: "0 0 auto",
        flexDirection: "column",
        gap: "0.5rem",
        justifyContent: "center",
        alignItems: "end",
      },

      "& > .canvas-container": {
        display: "flex",
        flex: 1,

        canvas: {
          width: "100%",
          height: `${height}px`,
        },
      },
    },
  };
});
