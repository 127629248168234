import { ICustomTableProps } from "SRC/types";

export const getStructureElementsFailTotal = (
  tableProps: ICustomTableProps,
  isOM: boolean
) => {
  let { structureElementsFailTotal, structureElementsOMFailTotal } =
    tableProps?.rows?.[0]?.original || {};

  return {
    structureElementsFailTotal: isOM
      ? structureElementsOMFailTotal
      : structureElementsFailTotal,
  };
};
