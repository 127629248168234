import { EVENT_INDICATORS } from "./eventIndicators";
import { MINISTRY_INDICATORS } from "./ministryIndicators";
import { PREMIER_INDICATORS } from "./premierIndicators";
import { PROGRAM_INDICATORS } from "./programIndicators";

export type { TIndicatorMap } from "./types";
export {
  getEventIndicator,
  getIndicator,
  getMinistryIndicator,
  getPremierIndicator,
  getProgramIndicator,
} from "./utils";

export const INDICATORS = {
  EVENT: EVENT_INDICATORS,
  MINISTRY: MINISTRY_INDICATORS,
  PREMIER: PREMIER_INDICATORS,
  PROGRAM: PROGRAM_INDICATORS,
};
