import { TOlapdataParsed, TOlapdataResponse } from "../types";

export const parseOlapdata = (
  response: TOlapdataResponse
): TOlapdataParsed[] => {
  const olapdataResponse = response?.responses[0]?.data;

  if (!olapdataResponse) {
    return [];
  }

  const { data = [], dimensionItems = {} } = olapdataResponse;

  return data.map((item): TOlapdataParsed => {
    const { dims, indicatorVals } = item;

    return {
      indicatorVals,
      dimensionItems: Object.fromEntries(
        Object.entries(dims).map(([dimKey, itemKey]) => {
          return [dimKey, dimensionItems[dimKey]?.[itemKey]];
        })
      ),
    };
  });
};
