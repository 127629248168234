import { Grid, styled } from "@mui/material";

export const GridContainer = styled(Grid)(({ theme }) => ({
  ".rowContainer": {
    flexWrap: "nowrap",
    gap: "0.5rem",
  },
  ".row": {
    minHeight: "4rem",
    borderRadius: "1rem",
    backgroundColor: theme.palette.background.paper,
  },
}));
