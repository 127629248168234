import { styled } from "@mui/material";

export const Wrapper = styled("div")(({ theme }) => {
  const {
    background: { opacityWhite },
    main: { darkBlue },
    text: { onAccent, opinion, primary },
    success: { light },
  } = theme.palette;

  return {
    hr: {
      width: "100%",
      border: "none",
      borderBottom: `1px solid ${opacityWhite}`,
    },
    ".content": {
      display: "flex",
      flexDirection: "column",
    },
    ".incident-container": {
      padding: "0.8rem 1.5rem",
      display: "flex",
      flexDirection: "column",
    },
    ".title": {
      position: "relative",
      fontSize: "1.2rem",
    },
    "& .wrapper-value": {
      float: "right",
      display: "inline-flex",
      alignItems: "baseline",
      lineHeight: "2rem",

      "& > span": {
        fontSize: "0.8rem",
        opacity: "0.5",
      },
    },
    "& .performance-value": {
      display: "inline-block",
      marginLeft: "auto",
      fontSize: "2rem",
      opacity: 1,
    },
    ".title-row": {
      display: "flex",
      gap: "0.25rem",
      justifyContent: "space-between",
    },
    ".title > span": {
      fontSize: "0.8rem",
      opacity: "0.5",
    },
    ".risk": {
      position: "absolute",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      top: "0.5rem",
      left: "-2.4rem",
      width: "1.5rem",
      height: "1.5rem",
      borderRadius: "50%",
      backgroundColor: darkBlue,
    },
    ".risk svg": {
      width: "1.2rem",
      height: "1.2rem",
    },
    ".value-row": {
      display: "flex",
      justifyContent: "space-between",
    },
    ".name": {
      color: primary,
      fontSize: "1rem",
    },
    ".value > span:first-of-type:after": {
      content: "' / '",
      color: onAccent,
    },
    ".value.is-om": {
      color: opinion,
    },
    ".value-row > .done": {
      color: light,
    },
  };
});
