import { Typography } from "@mui/material";
import React, { useMemo } from "react";
import { OverlayBlock } from "SRC/components/OverlayBlock";
import { SlimProgressBar } from "SRC/components/ProgressBar";
import { SourceValue } from "SRC/components/SourceValue/SourceValue";
import { WIPTag } from "SRC/components/WIP";
import { ESources, sourceSettings } from "SRC/constants";
import { getRoundValue } from "SRC/helpers/getRoundValue";
import { roundNumbersToFixed } from "SRC/helpers/roundNumbersToFixed";
import { Header } from "SRC/pages/Structure/common/StructureAnalytics/Header/Header";
import {
  IGlobalFilters,
  useGlobalFilters,
} from "SRC/redux/slices/global/hooks/useFilters";
import { useGetData } from "SRC/redux/stores/StructureElement";
import theme from "SRC/theme";
import { IFinancingData } from "SRC/types/analitics";

import { Wrapper } from "./Description.styled";

const Description = () => {
  const { fetching, data } = useGetData();
  const { sources }: IGlobalFilters = useGlobalFilters();

  const performance = useMemo(
    () =>
      sources[ESources.OM]
        ? {
            value: data?.data[5385]?.sum || 0, // Уровень достижения, особое мнение
            settings: sourceSettings[ESources.OM],
          }
        : {
            value: data?.data[5384]?.sum || 0, // Уровень достижения
            settings: sourceSettings[ESources.FOIV],
          },
    [data, sources]
  );

  const financingData = useMemo<IFinancingData>(
    () => ({
      reportData: data?.period?.date || "",
      budget: {
        performance: data?.data[5386].sum || 0,
        plan: data?.data[5486].sum || 0,
        fact: data?.data[5487].sum || 0,
      },
      extrabudgetary: {
        performance: 0,
        plan: 0,
        fact: 0,
      },
      totalFinancing: {
        plan: 0,
        fact: 0,
      },
    }),
    [data]
  );

  const objectsData = useMemo(
    () => ({
      plan: 0,
      fact: 0,
      percent: 0,
    }),
    []
  );

  return (
    <Wrapper>
      <div className="widget">
        <Header title="Уровень достижения" unit="" />
        <OverlayBlock isFetching={fetching} hasData={!!data}>
          <div className="valuesBox">
            <div className="achievement">
              <Typography className="achievement-value big">
                <SourceValue {...performance} isPercent={true} />
              </Typography>
              <Typography className="achievement-value percent">%</Typography>
            </div>
            <SlimProgressBar
              value={Number(performance.value) || 0}
              bottomLeftLabel={<>&nbsp;</>}
            />
          </div>
        </OverlayBlock>
      </div>
      <div className="widget static">
        <OverlayBlock isFetching={fetching} hasData={!!data}>
          <div className="curator">
            <div className="curator-item">
              <div className="curator-photo">
                <img alt="photo" src={data?.gp.vpPhoto} />
              </div>
              <div className="curator-title">
                <div className="curator-text">Куратор</div>
                <div className="curator-name">{data?.gp.vp}</div>
              </div>
            </div>
            <div className="curator-item">
              <div className="curator-photo">
                <img alt="photo" src={data?.gp.icon} />
              </div>
              <div className="curator-title">
                <div className="curator-text">Ответственный исполнитель</div>
                <div className="curator-name">{data?.gp.ministry}</div>
              </div>
            </div>
          </div>
        </OverlayBlock>
      </div>
      <div className="widget large">
        <Header title="Финансирование" unit="ед." />
        <OverlayBlock isFetching={fetching} hasData={!!data}>
          <div className="value-container">
            <div className="value-item">
              <div className="value-title">
                Бюджет
                <div className="value-unitTitle">млрд ₽</div>
              </div>
              <SlimProgressBar
                value={
                  financingData?.budget.performance ||
                  roundNumbersToFixed(
                    (financingData?.budget.fact * 100) /
                      financingData?.budget.plan,
                    1
                  ) ||
                  0
                }
                circleLabel={getRoundValue(financingData?.budget.fact, 2) || 0}
                bottomLeftLabel={0}
                bottomRightLabel={
                  getRoundValue(financingData?.budget.plan, 2) || 0
                }
                withLabel
                progressColor={theme.palette.pale.blue}
              />
            </div>
            <div className="value-item">
              <div className="value-title">
                Внебюджет
                <div className="value-unitTitle">млрд ₽</div>
                <WIPTag />
              </div>
              <SlimProgressBar
                value={
                  roundNumbersToFixed(
                    (financingData?.extrabudgetary.fact * 100) /
                      financingData?.extrabudgetary.plan,
                    1
                  ) || 0
                }
                circleLabel={financingData?.extrabudgetary.fact}
                bottomLeftLabel={0}
                bottomRightLabel={financingData?.extrabudgetary.plan}
                withLabel
                progressColor={theme.palette.pale.blue}
              />
            </div>
          </div>
        </OverlayBlock>
      </div>
      <div className="widget">
        <Header title="Создано объектов" unit="ед." />
        <OverlayBlock isFetching={fetching} hasData={!!data}>
          <div className="value-item">
            <div className="value-title">&nbsp;</div>
            <SlimProgressBar
              value={roundNumbersToFixed(objectsData?.percent, 0) || 0}
              circleLabel={objectsData?.fact}
              bottomLeftLabel={0}
              bottomRightLabel={objectsData?.plan}
              withLabel
              progressColor={
                theme.palette.pale[objectsData?.percent < 100 ? "red" : "blue"]
              }
            />
          </div>
        </OverlayBlock>
      </div>
    </Wrapper>
  );
};

export default Description;
