export const css = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  tabs: {
    fontSize: "1.2rem",
    fontWeight: "normal",
  },
};
