import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { OpenerButton, TableExportButton } from "SRC/components/Buttons";
import { ExtendedTabs, ITab } from "SRC/components/ExtendedTabs/ExtendedTabs";
import { EventsTab } from "SRC/pages/Program/common/EventsTab/EventsTab";
import GoalsTab from "SRC/pages/Program/common/GoalsTab/GoalsTab";
import { IndicatorsTab } from "SRC/pages/Program/common/IndicatorsTab/IndicatorsTab";
import { useProgramIndicators } from "SRC/pages/Program/common/IndicatorsTab/useIndicatorsData";
import { StructureTab } from "SRC/pages/Program/common/StructureTab/StructureTab";
import {
  useEventsTableData,
  useExportTableData,
  useSEIndicatorsTableData,
} from "SRC/pages/Program/tableData";
import { useGpStructuresTableData } from "SRC/pages/Program/tableData/useGpStructureTableData";
import { useSETasksTableData } from "SRC/pages/Program/tableData/useSETasksTableData";
import { useFilters } from "SRC/redux/slices/gosprogram/hooks/useFilters";
import { IExportData } from "SRC/types";
import { EVENT_STATUS, PROGRAM_TABS } from "SRC/types";
import { PROGRAM_VIEW_TAB, setParamsToUrl } from "SRC/urls";

import { TasksTab } from "../TasksTab/TasksTab";

type TExportData<T> = {
  [key: string]: IExportData<T>;
};

export const ProgramSummary = () => {
  const navigate = useNavigate();
  const { tab, programId } = useParams();
  const { indicators } = useProgramIndicators();
  const { eventsData } = useEventsTableData();
  const {
    eventsExports,
    indicatorsExports,
    tasksExports,
    goalsExports,
    goalsAnalyticsExports,
    structureGPExports,
  } = useExportTableData();

  const {
    setSelectedEventFeature,
    setSelectedStructureElementType,
    setSelectedStructureElementTask,
    setSelectedStructureElementName,
    selectedStructureElementTask,
  } = useFilters();

  const { indicatorsTableData } = useSEIndicatorsTableData();
  const { tasksTableData } = useSETasksTableData();
  const { gpStructuresData } = useGpStructuresTableData();
  const [openTableModal, setOpenTableModal] = useState(false);
  const [currTab, setCurrTab] = useState(tab || PROGRAM_TABS.INDICATORS);
  const [goalsCurrTab, setGoalsCurrTab] = useState(0);
  const [hasData, setHasData] = useState<boolean>(false);

  const getData = useCallback((data: any) => {
    setHasData(Boolean(data.length));
  }, []);

  const tabs: ITab<PROGRAM_TABS>[] = useMemo(() => {
    const eventsTotal = eventsData.filter(
      (item) =>
        item.structureTaskName === selectedStructureElementTask ||
        !selectedStructureElementTask
    ).length;

    return [
      {
        value: PROGRAM_TABS.INDICATORS,
        label: "Показатели ГП",
        numberOfItems: indicators.length,
        component: (
          <GoalsTab
            setTableModalOpen={setOpenTableModal}
            isTableModalOpen={openTableModal}
            setGoalsCurrTab={setGoalsCurrTab}
            getData={getData}
          />
        ),
      },
      {
        value: PROGRAM_TABS.PROGRAM_STRUCTURE,
        label: "Список СЭ",
        numberOfItems: gpStructuresData.length,
        component: (
          <StructureTab
            setTableModalOpen={setOpenTableModal}
            isTableModalOpen={openTableModal}
            getData={getData}
          />
        ),
      },
      {
        value: PROGRAM_TABS.TASKS,
        label: "Задачи СЭ",
        numberOfItems: tasksTableData.length,
        component: (
          <TasksTab
            setTableModalOpen={setOpenTableModal}
            isTableModalOpen={openTableModal}
            getData={getData}
          />
        ),
      },
      {
        value: PROGRAM_TABS.STRUCTURE,
        label: "Показатели СЭ",
        numberOfItems: indicatorsTableData.length,
        component: (
          <IndicatorsTab
            setTableModalOpen={setOpenTableModal}
            isTableModalOpen={openTableModal}
            getData={getData}
          />
        ),
      },
      {
        value: PROGRAM_TABS.EVENTS,
        label: "Мероприятия",
        numberOfItems: eventsTotal,
        component: (
          <EventsTab
            setTableModalOpen={setOpenTableModal}
            isTableModalOpen={openTableModal}
            getData={getData}
          />
        ),
      },
    ];
  }, [
    eventsData,
    indicators.length,
    openTableModal,
    getData,
    gpStructuresData.length,
    tasksTableData.length,
    indicatorsTableData.length,
  ]);

  const onChangeTab = useCallback(
    (tab: PROGRAM_TABS) => {
      if (programId) {
        setSelectedStructureElementType("");
        setSelectedStructureElementName("");
        setSelectedStructureElementTask("");
        setSelectedEventFeature("");
        setCurrTab(tab);

        return navigate(setParamsToUrl(PROGRAM_VIEW_TAB, { programId, tab }));
      }
    },
    [programId]
  );

  useEffect(() => {
    if (!tab) {
      setCurrTab(PROGRAM_TABS.INDICATORS);
    } else {
      setCurrTab(tab);
    }
  }, [tab]);

  const indicatorsExportData = useMemo(() => {
    return goalsCurrTab === 0 ? goalsExports : goalsAnalyticsExports;
  }, [goalsCurrTab, goalsExports, goalsAnalyticsExports]);

  const tabExportData: IExportData<Record<string, any>> = useMemo(() => {
    const exportData: TExportData<Record<string, any>> = {
      [PROGRAM_TABS.INDICATORS]: indicatorsExportData,
      [PROGRAM_TABS.PROGRAM_STRUCTURE]: structureGPExports,
      [PROGRAM_TABS.EVENTS]: eventsExports,
      [PROGRAM_TABS.STRUCTURE]: indicatorsExports,
      [PROGRAM_TABS.TASKS]: tasksExports,
    };
    return exportData[currTab];
  }, [
    currTab,
    eventsExports,
    indicatorsExports,
    tasksExports,
    goalsExports,
    indicatorsExportData,
    structureGPExports,
  ]);

  const actions = useMemo(() => {
    return tab === PROGRAM_TABS.INDICATORS && goalsCurrTab === 1
      ? [
          <OpenerButton
            key={Math.random()}
            open={openTableModal}
            setOpen={() => setOpenTableModal(true)}
          />,
        ]
      : [
          hasData && (
            <TableExportButton key={Math.random()} {...tabExportData} />
          ),
          <OpenerButton
            key={Math.random()}
            open={openTableModal}
            setOpen={() => setOpenTableModal(true)}
          />,
        ];
  }, [goalsCurrTab, tab, tabExportData, hasData]);

  if (!currTab) {
    return null;
  }

  return (
    <ExtendedTabs
      value={currTab}
      onChange={onChangeTab}
      tabs={tabs}
      actions={actions}
      style={{ fontSize: "2rem", fontWeight: 700 }}
    />
  );
};
