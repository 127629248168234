import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { isContainedArray } from "SRC/helpers/dataTesters";
import { IPeriod } from "SRC/redux/slices/global/slices/filters";
import { getGpStructure } from "SRC/redux/slices/gosprogram/api/client";
import { ICodeItem } from "SRC/types";

export interface IGpStructureParams {
  period: IPeriod;
  gp_code: string;
}
export interface IGpStructure {
  code: string;
  SE_TYPE: string;
  VersionMetaId: string;
  StateProgramCode: string;
  SE_CODE: string;
  StateProgramVersionMetaId: string;
  NAME: string;
  data: ICodeItem[];
}

export interface IGpStructureState {
  items: IGpStructure[];
  fetching: boolean;
  fetched: boolean;
  error: string;
}

export const extraActions = {
  get: createAsyncThunk(
    "gpStructure/get",
    async ({ period, gp_code }: IGpStructureParams) =>
      await getGpStructure({ period, gp_code })
  ),
};

const initialState: IGpStructureState = {
  items: [],
  fetching: false,
  fetched: false,
  error: "",
};

const slice = createSlice({
  name: "gpStructure",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActions.get.pending, (state) => {
        state.items = [];
        state.fetching = true;
        state.fetched = false;
      })
      .addCase(
        extraActions.get.fulfilled,
        (state: IGpStructureState, action) => {
          state.items = (
            isContainedArray(action.payload.data)
              ? action.payload.data[0]?.data[0]?.data[0]?.data
              : []
          ) as IGpStructure[];
          state.fetching = false;
          state.fetched = true;
        }
      )
      .addCase(extraActions.get.rejected, (state, action) => {
        state.fetching = false;
        state.fetched = false;
      });
  },
});

export const actions = {
  ...slice.actions,
  ...extraActions,
};

export default slice;
