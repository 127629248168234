import DownloadIcon from "@mui/icons-material/Download";
import { Button, SxProps } from "@mui/material";
import React, { useCallback, useMemo } from "react";
import { ESources, sourceSettings } from "SRC/constants";
import {
  IGlobalFilters,
  useGlobalFilters,
} from "SRC/redux/slices/global/hooks/useFilters";

import { css, DownloadButtonStyled } from "./Buttons.styles";

interface IDownloadButtonProps {
  onClick: () => void;
  hasReports?: boolean;
  isButtonDisabled?: boolean;
}

export const DownloadButton = ({
  onClick,
  hasReports,
  isButtonDisabled,
}: IDownloadButtonProps) => {
  const { sources }: IGlobalFilters = useGlobalFilters();
  const settings = useMemo(
    () => sourceSettings[sources.OM ? ESources.OM : ESources.FOIV],
    [sources]
  );

  const handleOnClick = useCallback(() => {
    if (onClick) {
      onClick();
    }
  }, [onClick]);
  return (
    <>
      {hasReports ? (
        <Button
          sx={css.outlinedButton as SxProps}
          disabled={!isButtonDisabled}
          onClick={handleOnClick}
        >
          Выгрузить
        </Button>
      ) : (
        <DownloadButtonStyled
          variant="text"
          btncolor={settings?.valueColor}
          onClick={handleOnClick}
        >
          <DownloadIcon />
        </DownloadButtonStyled>
      )}
    </>
  );
};
