import { Button, Stack, SxProps, useMediaQuery, useTheme } from "@mui/material";
import React, {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from "react";
import { Column, TableInstance } from "react-table";
import { AchievementsTile } from "SRC/components/AchievementsTile/AchievementsTile";
import { TableModal } from "SRC/components/FullPageTableModal/FullPageTableModal";
import { PrioritySummaryWidget } from "SRC/components/PrioritySummaryWidget/PrioritySummaryWidget";
import SelectWithSearch, {
  IOption,
} from "SRC/components/SelectWithSearch/SelectWithSearch";
import SelectWithSearchVP from "SRC/components/SelectWithSearch/SelectWithSearchVP";
import Table from "SRC/components/Table";
import { createPremierRowsTableData } from "SRC/hooks/summaryTable/utils/summaryData/summaryDataVP";
import { usePrioritiesTableData } from "SRC/pages/Priorities/tableData";
import { topDataVP } from "SRC/pages/Priorities/utils/topData";
import { widgetPrioritiesData } from "SRC/pages/Priorities/utils/widgetPrioritiesData";
import { TableSearch } from "SRC/pages/Program/common/EventsTab/TableSearch/TableSearch";
import {
  IGlobalFilters,
  useGlobalFilters,
} from "SRC/redux/slices/global/hooks/useFilters";
import { useFilters } from "SRC/redux/slices/priorities/hooks/useFilters";
import { IPremiers } from "SRC/redux/slices/priorities/hooks/usePremiers";
import { usePremiers } from "SRC/redux/slices/summary/hooks/usePremiers";
import { CustomRowProps } from "SRC/types/data/data";

import { css, TabWrapper } from "../PrioritiesComplexGrid.styled";
import { getVicePremierKPColumns } from "./tableColumns";

interface IGeneralInfoPremiersTab {
  posTable?: string;
  forwardRef?: MutableRefObject<HTMLElement | undefined>;
  onScroll?: (scroll: any) => void;
  isModalOpen?: boolean;
  setModalOpen?: (value: boolean) => void;
  instance: TableInstance | undefined;
  setInstance: Dispatch<SetStateAction<TableInstance<{}> | undefined>>;
}

const sortedColumns = [
  "№",
  "Кол-во госпрограмм",
  "Вице - премьер",
  "План, млрд ₽",
  "Факт, %",
  "План",
  "Факт",
  "Не выполнено",
  "Уровень достижения, %",
];
export const staticColumns = [
  "icon",
  "expander",
  "id",
  "vicePremierPhoto",
  "vicePremier",
  "govPrograms",
];

export const RenderTable = ({
  posTable,
  forwardRef,
  onScroll,
  isModalOpen,
  setModalOpen,
  instance,
  setInstance,
}: IGeneralInfoPremiersTab) => {
  const theme = useTheme();
  const { sources }: IGlobalFilters = useGlobalFilters();
  const { items: vpItems }: IPremiers = usePremiers();
  const { premiersData } = usePrioritiesTableData();
  const { setSearchRows, vp, filteredVPs, setSelectedVP } = useFilters();
  const { fetching } = usePremiers();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));
  const [searchValue, setSearchValue] = useState<string>("");

  const setTableFilter = useCallback(
    (value: string) => {
      instance?.setGlobalFilter(value);
      setSearchValue(value);
    },
    [instance]
  );

  const handleClearFilters = () => {
    setTableFilter("");
    setSelectedVP("");
  };

  const columns: Column[] = useMemo(() => {
    return getVicePremierKPColumns(sources.OM);
  }, [sources.OM]);

  const tableHeight: string | undefined = useMemo(() => {
    if (isModalOpen) return;
    return matches ? css.tableHeightLong : css.tableHeight;
  }, [isModalOpen, matches]);

  const onChangeFilterRows = useCallback(
    (rows: CustomRowProps[]) => {
      setSearchRows(createPremierRowsTableData(rows, sources.OM));
    },
    [sources.OM]
  );

  const selectStyle = useMemo(() => css.selectorFilter(theme), [theme]);

  const optionsVP: IOption[] = useMemo(
    () =>
      vpItems
        .filter((item) => filteredVPs.includes(item.info.VP_CODE))
        .map((item) => ({
          value: item.info.VP_SHORT_NAME,
          label: item.info.VP_SHORT_NAME,
        })),
    [vpItems, filteredVPs]
  );

  return (
    <TabWrapper>
      <div className="priority-summary">
        <PrioritySummaryWidget data={widgetPrioritiesData} />
      </div>
      <div className="achievements-tile">
        {topDataVP.map((item, key) => (
          <AchievementsTile
            data={item}
            withDarkBgr={true}
            key={key}
            fetching={fetching}
          />
        ))}
      </div>
      <div className="search-select-container">
        <div className="search">
          <TableSearch value={searchValue} onChange={setTableFilter} />
        </div>
        <SelectWithSearch
          options={[
            {
              value: "",
              label: "Ответственный исполнитель",
            },
          ]}
          label="Ответственный исполнитель"
          staticLabel={false}
        />
        <SelectWithSearchVP
          value={vp}
          label="Вице-премьер"
          staticLabel={false}
          style={selectStyle}
          onChange={setSelectedVP}
          options={optionsVP}
        />
        <Button sx={css.filterButton as SxProps} onClick={handleClearFilters}>
          Сбросить фильтры
        </Button>
      </div>
      <div className="tables-wrapper">
        <Table
          data={premiersData}
          columns={columns}
          loading={fetching}
          sortedColumns={sortedColumns}
          positionTable={posTable}
          tableHeight={tableHeight}
          forwardRef={forwardRef}
          onScroll={onScroll}
          noDataCondition={[].length === 0}
          getInstance={setInstance}
          classes={{
            tr: "table-row",
            tfoot: "table-footer",
            tbody: "table-body",
          }}
          sticky
          expandable
          onFilterRows={onChangeFilterRows}
        />
      </div>
    </TabWrapper>
  );
};

export const VicepremiersTab = (props: IGeneralInfoPremiersTab) => {
  const { isModalOpen, setModalOpen } = props;

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <Stack style={css.tabContainer}>
      <RenderTable {...props} />
      {!matches && (
        <TableModal
          open={isModalOpen}
          setOpen={setModalOpen}
          tableName="Сводная таблица. Вице-премьеры"
        >
          <RenderTable {...props} />
        </TableModal>
      )}
    </Stack>
  );
};
