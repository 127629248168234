import { styled, Theme } from "@mui/material";
import { selectorMixin } from "SRC/components/FilterForms/MainHeaderFilter/MainHeaderFilter.styled";

export const GovProgramsWrapper = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  minHeight: 0,
  flex: 1,

  ".table-header": {
    padding: "0",

    ".th": {
      "&:first-of-type": {
        borderTopLeftRadius: "1rem",
      },

      "&:last-of-type": {
        borderTopRightRadius: "1rem",
      },
    },
  },

  ".table-body": {
    ".status-icon": {
      position: "relative",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      maxWidth: 0,

      "> *": {
        position: "relative",
        left: "-0.5rem",
        minWidth: "1.2rem",
        minHeight: "1.2rem",
      },
    },
  },

  ".table-footer": {
    padding: "0",

    ".tf": {
      height: "auto",

      "&:first-of-type": {
        borderBottomLeftRadius: "1rem",
      },

      "&:last-of-type": {
        borderBottomRightRadius: "1rem",
      },
    },
  },

  ".incident-container": {
    height: "100%",
  },

  ".incident-btn": {
    right: "1rem",
  },
}));

export const css = {
  selector: selectorMixin,
  informer: {
    height: 40,
    display: "flex",
    alignItems: " center",
    px: 6,
    py: 2,
    color: (theme: Theme) => theme.palette.text.secondary,
  },
  numberCurrent: {
    color: (theme: Theme) => theme.palette.text.primary,
  },
  numberLine: {
    px: 1,
  },

  search: {
    maxWidth: (theme: Theme) => theme.spacing(150),
    "& .MuiFilledInput-root": {
      background: (theme: Theme) => theme.palette.background.paper,
      borderRadius: (theme: Theme) => theme.shape.borderRadius,
      overflow: "hidden",
      px: 4,
      py: 0,
      height: 45,
      "&:hover": {
        background: (theme: Theme) => theme.palette.background.paper,
      },
    },
    "& .MuiFilledInput-input": {
      p: 0,
      height: 45,
    },
    "& .MuiInputAdornment-root": {
      m: "0 !important",
    },
  },

  headSubTitle: {
    "& .MuiTypography-root": {
      color: (theme: Theme) => theme.palette.text.secondary,
    },
  },

  uiSearch: {
    maxWidth: (theme: Theme) => theme.spacing(150),
    "& .MuiFilledInput-root": {
      height: "2.5rem",
      padding: "0 1rem",
      overflow: "hidden",
      borderRadius: "0.5rem",
      background: (theme: Theme) => theme.palette.background.paper,

      "&:hover": {
        borderBottom: "none",
        background: (theme: Theme) => theme.palette.background.paper,

        "&::before": {
          borderBottom: "none !important",
        },
      },

      "&::before": {
        borderBottom: "none",
      },

      "&::after": {
        borderBottom: "none",
      },
    },

    "& .MuiFilledInput-input": {
      p: 0,
    },

    "& .MuiInputAdornment-root": {
      margin: "0 0.5rem 0 0 !important",
    },
  },
  tabContainer: {
    display: "flex",
    flexDirection: "column",
    minHeight: 0,
    width: "100%",
    marginRight: "1rem",

    ".table-header": {
      padding: "0",

      ".th": {
        "&:first-of-type": {
          borderTopLeftRadius: "1rem",
        },
        "&:last-of-type": {
          borderTopRightRadius: "1rem",
        },
      },
    },

    ".table-body": {
      ".status-icon": {
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        maxWidth: 0,

        "> *": {
          position: "relative",
          left: "-0.5rem",
          minWidth: "1.2rem",
          minHeight: "1.2rem",
        },
      },
    },

    ".table-footer": {
      padding: "0",

      ".tf": {
        height: "auto",

        "&:first-of-type": {
          borderBottomLeftRadius: "1rem",
        },
        "&:last-of-type": {
          borderBottomRightRadius: "1rem",
        },
      },
    },
  },
  actionsContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "1rem",
    gap: 2,
  },
  leftBlock: {
    display: "flex",
    alignItems: "center",
    gap: 2,
  },
};
