import { useEffect, useMemo } from "react";
import { getInfoRowsSummaryPrograms } from "SRC/helpers/getInfoRows";
import { useTableExportParams } from "SRC/hooks";
import { useGlobalFilters } from "SRC/redux/slices/global/hooks/useFilters";
import {
  IFilters as IMainFilters,
  useFilters as useMainFilters,
} from "SRC/redux/slices/main/hooks/useFilters";
import { usePrograms as useMainPrograms } from "SRC/redux/slices/main/hooks/usePrograms";
import {
  IFilters as ISummaryFilters,
  useFilters as useSummaryFilters,
} from "SRC/redux/slices/summary/hooks/useFilters";
import { usePrograms as useSummaryPrograms } from "SRC/redux/slices/summary/hooks/usePrograms";
import { IExportData } from "SRC/types";

import { IProgramTableItem } from "./interfaces";
import { createProgramsTableData as createProgramsMainTableData } from "./utils/mainData/mainDataGP";
import { createProgramsTableData } from "./utils/summaryData/summaryDataGP";

interface IUseTableData {
  programsSummaryData: IProgramTableItem[];
  programsMainData: IProgramTableItem[];
  programsSummaryExport: IExportData<Record<string, any>>;
  programsMainExport: IExportData<Record<string, any>>;
}

export const useTableData = (): IUseTableData => {
  const { period } = useGlobalFilters();

  const {
    filteredPrograms: filteredSummaryPrograms,
    selectedSearchRows: exportSummaryData,
  }: ISummaryFilters = useSummaryFilters();

  const {
    filteredPrograms: filteredMainPrograms,
    selectedSearchRows: exportMainData,
  }: IMainFilters = useMainFilters();

  const { items: summaryPrograms } = useSummaryPrograms();
  const { load: loadMainPrograms, items: mainPrograms } = useMainPrograms();
  const createExportParams = useTableExportParams;

  useEffect(() => {
    loadMainPrograms(period);
  }, [period.filterValue]);

  const programsMainTableData = useMemo(
    () => createProgramsMainTableData(mainPrograms),
    [mainPrograms]
  );

  const programsSummaryTableData = useMemo(
    () => createProgramsTableData(summaryPrograms),
    [summaryPrograms]
  );

  const programsMainData = useMemo(() => {
    return programsMainTableData
      .filter((program) => filteredMainPrograms.includes(program.id))
      .sort((a, b) => Number(a.id) - Number(b.id));
  }, [programsMainTableData, filteredMainPrograms]);

  const programsSummaryData = useMemo(() => {
    return programsSummaryTableData
      .filter((program) => filteredSummaryPrograms.includes(program.id))
      .sort((a, b) => Number(a.id) - Number(b.id));
  }, [programsSummaryTableData, filteredSummaryPrograms]);

  const programsSummaryExport = useMemo(() => {
    const download = exportSummaryData.map((item) => {
      return {
        id: item.id,
        govProgram: item.govProgram,
        premier: item.premier,
        ministry: item.ministry,
        performance: item.performance,
        cashPlan: item.cashPlan,
        cashFact: item.cashFact,
        indicatorsPlan: item.indicatorsPlan,
        indicatorsFact: item.indicatorsFact,
        indicatorsFail: item.indicatorsFail,
        structureElementsPlan: item.structureElementsPlan,
        structureElementsFact: item.structureElementsFact,
        structureElementsFail: item.structureElementsFail,
        indicatorsSEPlan: item.indicatorsSEPlan,
        indicatorsSEFact: item.indicatorsSEFact,
        indicatorsSEFail: item.indicatorsSEFail,
        eventsPlan: item.eventsPlan,
        eventsFact: item.eventsFact,
        eventsFail: item.eventsFail,
        checkpointsPlan: item.checkpointsPlan,
        checkpointsFact: item.checkpointsFact,
        checkpointsFail: item.checkpointsFail,
      };
    });

    return {
      csvData: download,
      fileName: "Сводная таблица. Госпрограммы",
      infoRows: getInfoRowsSummaryPrograms(period),
      ...createExportParams(download, "summaryPrograms"),
    };
  }, [exportSummaryData, period, createExportParams]);

  const programsMainExport = useMemo(() => {
    const download = exportMainData.map(
      ({
        icon,
        secretProgram,
        performanceTotal,
        performanceOMTotal,
        indicatorsPlanTotal,
        performanceOM,
        indicatorsDoneTotal,
        factTotal,
        atRiskTotal,
        planTotal,
        vp,
        ministry,
        indicators,
        isSecret,
        ...item
      }) => item
    );

    return {
      csvData: download,
      fileName: "Сводная таблица. Госпрограммы",
      infoRows: getInfoRowsSummaryPrograms(period),
      ...createExportParams(download, "govPrograms"),
    };
  }, [exportMainData, period, createExportParams]);

  return {
    programsMainData,
    programsSummaryData,
    programsMainExport,
    programsSummaryExport,
  };
};
