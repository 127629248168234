import { actions as ministries } from "./slices/ministries";
import { actions as premiers } from "./slices/premiers";
import { actions as programs } from "./slices/programs";
import { actions as topBestGp } from "./slices/topBestGp";
import { actions as topBestGp2 } from "./slices/topBestGp2";
import { actions as topBestGpOM } from "./slices/topBestGpSpecialOp";
import { actions as topBestMinistries } from "./slices/topBestMinistries";
import { actions as topBestMinistries2 } from "./slices/topBestMinistries2";
import { actions as topBestMinistriesOM } from "./slices/topBestMinistriesOM";
import { actions as topBestVp } from "./slices/topBestVp";
import { actions as topBestVp2 } from "./slices/topBestVp2";
import { actions as topBestVpOpinion } from "./slices/topBestVpOpinion";
import { actions as topWorstGp } from "./slices/topWorstGp";
import { actions as topWorstGp2 } from "./slices/topWorstGp2";
import { actions as topWorstGpOM } from "./slices/topWorstGpSpecialOp";
import { actions as topWorstMinistries } from "./slices/topWorstMinistries";
import { actions as topWorstMinistries2 } from "./slices/topWorstMinistries2";
import { actions as topWorstMinistriesOM } from "./slices/topWorstMinistriesOM";
import { actions as topWorstVp } from "./slices/topWorstVp";
import { actions as topWorstVp2 } from "./slices/topWorstVp2";
import { actions as topWorstVpOpinion } from "./slices/topWorstVpOpinion";

export interface ISummaryActions {
  ministries: typeof ministries;
  programs: typeof programs;
  premiers: typeof premiers;
  topBestGp: typeof topBestGp;
  topWorstGp: typeof topWorstGp;
  topBestVp: typeof topBestVp;
  topWorstVp: typeof topWorstVp;
  topBestVp2: typeof topBestVp2;
  topWorstVp2: typeof topWorstVp2;
  topWorstGp2: typeof topWorstGp2;
  topBestGp2: typeof topBestGp2;
  topBestVpOpinion: typeof topBestVpOpinion;
  topWorstVpOpinion: typeof topWorstVpOpinion;
  topWorstMinistries: typeof topWorstMinistries;
  topBestMinistries: typeof topBestMinistries;
  topBestMinistriesOM: typeof topBestMinistriesOM;
  topBestMinistries2: typeof topBestMinistries2;
  topWorstMinistries2: typeof topBestMinistries2;
  topWorstMinistriesOM: typeof topBestMinistriesOM;
  topBestGpOM: typeof topBestGpOM;
  topWorstGpOM: typeof topWorstGpOM;
}

const actions: ISummaryActions = {
  ministries,
  programs,
  premiers,
  topBestGp,
  topWorstGp,
  topBestVp,
  topWorstVp,
  topBestVp2,
  topWorstVp2,
  topWorstGp2,
  topBestGp2,
  topBestVpOpinion,
  topWorstVpOpinion,
  topWorstMinistries,
  topBestMinistries,
  topBestMinistriesOM,
  topBestMinistries2,
  topWorstMinistries2,
  topWorstMinistriesOM,
  topBestGpOM,
  topWorstGpOM,
};

export default actions;
