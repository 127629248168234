import { performanceMain } from "SRC/components/TableColumnTemplates/columns/performanceMain";

import { cashFact, cashPlan } from "./cash";
import {
  checkpointsFact,
  checkpointsFail,
  checkpointsPlan,
} from "./checkpoints";
import { eventsFact, eventsFail, eventsPlan } from "./events";
import { eventsRisk } from "./events/eventsRisk";
import { indicatorsFact, indicatorsFail, indicatorsPlan } from "./indicators";
import {
  indicatorsSEFact,
  indicatorsSEFail,
  indicatorsSEPlan,
} from "./indicatorsSE";
import { performance } from "./performance";
import { programStatus } from "./program";
import {
  structureElementsFact,
  structureElementsFail,
  structureElementsPlan,
} from "./structureElements";

export const COLUMNS = {
  program: {
    status: programStatus,
  },
  performance: {
    fact: performance,
    factMain: performanceMain,
  },
  cash: {
    fact: cashFact,
    plan: cashPlan,
  },
  checkpoints: {
    fact: checkpointsFact,
    fail: checkpointsFail,
    plan: checkpointsPlan,
  },
  events: {
    fact: eventsFact,
    fail: eventsFail,
    plan: eventsPlan,
    risk: eventsRisk,
  },
  indicators: {
    fact: indicatorsFact,
    fail: indicatorsFail,
    plan: indicatorsPlan,
  },
  structureElements: {
    fact: structureElementsFact,
    fail: structureElementsFail,
    plan: structureElementsPlan,
  },
  indicatorsSE: {
    fact: indicatorsSEFact,
    fail: indicatorsSEFail,
    plan: indicatorsSEPlan,
  },
};
