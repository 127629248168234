import { SxProps, Theme } from "@mui/material";

export const css = {
  element: (value: unknown, colorize: boolean, style: SxProps = {}) => {
    return {
      flex: 1,
      width: "100%",
      maxWidth: "27rem",
      "& .textField > div": {
        padding: "0.5rem",
      },
      "&.selectWidth": {
        width: "120px",
      },
      "& .MuiAutocomplete-inputRoot": {
        ...style,
        backgroundColor: (theme: Theme) =>
          value && colorize
            ? theme.palette.background.header
            : theme.palette.background.paper,
        color: (theme: Theme) =>
          value && colorize && theme.palette.text.onAccent,
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
      "& .MuiOutlinedInput-root": {
        height: "2.5rem",
        backgroundColor: (theme: Theme) =>
          value && colorize
            ? theme.palette.background.header
            : theme.palette.background.paper,
        borderRadius: "0.5rem 0.7rem 0.7rem 0.5rem",
      },
      "& .MuiAutocomplete-input": {
        padding: "0 0.5rem !important",
        fontSize: "1rem",
      },
      "& .MuiAutocomplete-clearIndicator": {
        marginTop: "0.35rem",
        "&:hover": {
          backgroundColor: "none",
        },
      },
      "& .MuiAutocomplete-endAdornment": {
        height: "100%",
        top: 0,
        display: "flex",
        alignItems: "center",
      },
      "& .MuiFormLabel-root": {
        display: "none",
      },
    };
  },
  label: {
    fontSize: "0.8rem",
    lineHeight: "2em",
    color: "text.onAccent",
  },
  allItemContainer: {
    borderBottom: (theme: Theme) => `1px solid ${theme.palette.divider}`,
    marginBottom: "1rem",
  },
  allItem: (isSelected: boolean) => ({
    position: "relative",
    backgroundColor: (theme: Theme) =>
      isSelected ? theme.palette.primary.main : theme.palette.background.paper,
    marginBottom: "0.5rem",
  }),
  item: (isSelected: boolean) => ({
    whiteSpace: "normal",
    backgroundColor: (theme: Theme) =>
      isSelected ? theme.palette.primary.main : theme.palette.background.paper,
    "& > div": {
      display: "-webkit-box",
      WebkitLineClamp: 3,
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
    },
  }),
  closeButton: {
    width: "0.7rem",
    opacity: "0.5",
    cursor: "pointer",
  },
  arrowButton: {
    width: "1rem",
    cursor: "pointer",
  },
  iconComponent: {
    position: "absolute",
    right: "1rem",
    display: "flex",
    alignItems: "center",
    gap: "0.5rem",
    "& :first-of-type path": {
      fill: "#fff",
    },
  },
  paperRoot: {
    padding: "0.5rem",
    "& .MuiAutocomplete-listbox": {
      padding: "0 !important",
      color: (theme: Theme) => theme.palette.primary.contrastText,
    },
  },
  noOptionsText: {
    fontSize: "1rem",
  },
};
