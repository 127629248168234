import { MutableRefObject, useEffect, useState } from "react";

export const useOnScreen = <T extends Element>(
  ref: MutableRefObject<T | undefined>,
  rootMargin: string = "0px"
): boolean => {
  const [onScreen, setOnScreen] = useState<boolean>(false);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setOnScreen(entry.isIntersecting);
      },
      {
        rootMargin,
      }
    );
    const currentElement = ref?.current;
    if (currentElement) {
      observer.observe(currentElement);
    }
    return () => {
      if (currentElement) {
        observer.unobserve(currentElement);
      }
    };
  }, []);
  return onScreen;
};
