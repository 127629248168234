import { SerializedError } from "@reduxjs/toolkit";
import { useCallback, useEffect } from "react";
import { useGlobalFilters } from "SRC/redux/slices/global/hooks/useFilters";
import { useMinistries } from "SRC/redux/slices/summary/hooks/useMinistries";
import { usePremiers } from "SRC/redux/slices/summary/hooks/usePremiers";
import { usePrograms } from "SRC/redux/slices/summary/hooks/usePrograms";
import { useTopBestGP } from "SRC/redux/slices/summary/hooks/useTopBestGP";
import { useTopBestGP2 } from "SRC/redux/slices/summary/hooks/useTopBestGP2";
import { useTopBestGpOM } from "SRC/redux/slices/summary/hooks/useTopBestGpOM";
import { useTopBestMinistries } from "SRC/redux/slices/summary/hooks/useTopBestMinistries";
import { useTopBestMinistries2 } from "SRC/redux/slices/summary/hooks/useTopBestMinistries2";
import { useTopBestMinistriesOM } from "SRC/redux/slices/summary/hooks/useTopBestMinistriesOM";
import { useTopBestVP } from "SRC/redux/slices/summary/hooks/useTopBestVP";
import { useTopBestVP2 } from "SRC/redux/slices/summary/hooks/useTopBestVP2";
import { useTopBestVpOpinion } from "SRC/redux/slices/summary/hooks/useTopBestVpOpinion";
import { useTopWorstGP } from "SRC/redux/slices/summary/hooks/useTopWorstGP";
import { useTopWorstGP2 } from "SRC/redux/slices/summary/hooks/useTopWorstGP2";
import { useTopWorstGpOM } from "SRC/redux/slices/summary/hooks/useTopWorstGpOM";
import { useTopWorstMinistries } from "SRC/redux/slices/summary/hooks/useTopWorstMinistries";
import { useTopWorstMinistries2 } from "SRC/redux/slices/summary/hooks/useTopWorstMinistries2";
import { useTopWorstMinistriesOM } from "SRC/redux/slices/summary/hooks/useTopWorstMinistriesOM";
import { useTopWorstVP } from "SRC/redux/slices/summary/hooks/useTopWorstVP";
import { useTopWorstVP2 } from "SRC/redux/slices/summary/hooks/useTopWorstVP2";
import { useTopWorstVpOpinion } from "SRC/redux/slices/summary/hooks/useTopWorstVpOpinion";

export interface ISummaryState {
  loading: boolean;
  loaded: boolean;
  init(): void;
  hasError: SerializedError | null;
}

export const useSummary = (): ISummaryState => {
  const { period } = useGlobalFilters();
  const {
    fetching: loadingTopBestGP,
    load: loadTopBestGP,
    fetched: loadedTopBestGP,
    error: errorTopBestGP,
  } = useTopBestGP();
  const {
    fetching: loadingTopBestGP2,
    load: loadTopBestGP2,
    fetched: loadedTopBestGP2,
    error: errorTopBestGP2,
  } = useTopBestGP2();
  const {
    fetching: loadingTopBestVP,
    load: loadTopBestVP,
    fetched: loadedTopBestVP,
    error: errorTopBestVP,
  } = useTopBestVP();
  const {
    fetching: loadingTopBestVP2,
    load: loadTopBestVP2,
    fetched: loadedTopBestVP2,
    error: errorTopBestVP2,
  } = useTopBestVP2();
  const {
    fetching: loadingTopWorstGP,
    load: loadTopWorstGP,
    fetched: loadedTopWorstGP,
    error: errorTopWorstGP,
  } = useTopWorstGP();
  const {
    fetching: loadingTopWorstGP2,
    load: loadTopWorstGP2,
    fetched: loadedTopWorstGP2,
    error: errorTopWorstGP2,
  } = useTopWorstGP2();
  const {
    fetching: loadingTopWorstVP,
    load: loadTopWorstVP,
    fetched: loadedTopWorstVP,
    error: errorTopWorstVP,
  } = useTopWorstVP();
  const {
    fetching: loadingTopWorstVP2,
    load: loadTopWorstVP2,
    fetched: loadedTopWorstVP2,
    error: errorTopWorstVP2,
  } = useTopWorstVP2();
  const {
    fetching: loadingTopBestMinistries,
    load: loadTopBestMinistries,
    fetched: loadedTopBestMinistries,
    error: errorTopBestMinistries,
  } = useTopBestMinistries();
  const {
    fetching: loadingTopBestMinistries2,
    load: loadTopBestMinistries2,
    fetched: loadedTopBestMinistries2,
    error: errorTopBestMinistries2,
  } = useTopBestMinistries2();
  const {
    fetching: loadingTopWorstMinistries,
    load: loadTopWorstMinistries,
    fetched: loadedTopWorstMinistries,
    error: errorTopWorstMinistries,
  } = useTopWorstMinistries();
  const {
    fetching: loadingTopWorstMinistries2,
    load: loadTopWorstMinistries2,
    fetched: loadedTopWorstMinistries2,
    error: errorTopWorstMinistries2,
  } = useTopWorstMinistries2();
  const {
    fetching: loadingTopBestMinistriesOM,
    load: loadTopBestMinistriesOM,
    fetched: loadedTopBestMinistriesOM,
    error: errorTopBestMinistriesOM,
  } = useTopBestMinistriesOM();
  const {
    fetching: loadingTopWorstMinistriesOM,
    load: loadTopWorstMinistriesOM,
    fetched: loadedTopWorstMinistriesOM,
    error: errorTopWorstMinistriesOM,
  } = useTopWorstMinistriesOM();
  const {
    fetching: loadingPrograms,
    load: loadPrograms,
    fetched: loadedPrograms,
    error: errorPrograms,
  } = usePrograms();
  const {
    fetching: loadingPremiers,
    load: loadPremiers,
    fetched: loadedPremiers,
    error: errorPremiers,
  } = usePremiers();
  const {
    load: loadMinistries,
    fetched: loadedMinistries,
    error: errorMinistries,
  } = useMinistries();
  const {
    fetching: loadingTopBestGpOM,
    load: loadTopBestGpOM,
    fetched: loadedTopBestGpOM,
    error: errorTopBestGpOM,
  } = useTopBestGpOM();
  const {
    fetching: loadingTopWorstGpOM,
    load: loadTopWorstGpOM,
    fetched: loadedTopWorstGpOM,
    error: errorTopWorstGpOM,
  } = useTopWorstGpOM();
  const {
    fetching: loadingTopWorstVpOpinion,
    load: loadTopWorstVpOpinion,
    fetched: loadedTopWorstVpOpinion,
    error: errorTopWorstVpOpinion,
  } = useTopWorstVpOpinion();
  const {
    fetching: loadingTopBestVpOpinion,
    load: loadTopBestVpOpinion,
    fetched: loadedTopBestVpOpinion,
    error: errorTopBestVpOpinion,
  } = useTopBestVpOpinion();

  const loading =
    loadingTopBestGP ||
    loadingTopBestGP2 ||
    loadingTopWorstGP ||
    loadingTopWorstGP2 ||
    loadingTopBestVP ||
    loadingTopBestVP2 ||
    loadingTopWorstVP ||
    loadingTopWorstVP2 ||
    loadingTopBestMinistries ||
    loadingTopBestMinistriesOM ||
    loadingTopBestMinistries2 ||
    loadingTopWorstMinistries ||
    loadingTopWorstMinistriesOM ||
    loadingTopWorstMinistries2 ||
    loadingPrograms ||
    loadingPremiers ||
    loadingTopBestGpOM ||
    loadingTopWorstGpOM ||
    loadingTopBestVpOpinion ||
    loadingTopWorstVpOpinion;
  const loaded =
    loadedTopBestGP ||
    loadedTopBestGP2 ||
    loadedTopWorstGP ||
    loadedTopWorstGP2 ||
    loadedTopBestVP ||
    loadedTopBestVP2 ||
    loadedTopWorstVP ||
    loadedTopWorstVP2 ||
    loadedTopBestMinistries ||
    loadedTopBestMinistriesOM ||
    loadedTopBestMinistries2 ||
    loadedTopWorstMinistries ||
    loadedTopWorstMinistriesOM ||
    loadedTopWorstMinistries2 ||
    loadedPrograms ||
    loadedPremiers ||
    loadedMinistries ||
    loadedTopBestGpOM ||
    loadedTopWorstGpOM ||
    loadedTopBestVpOpinion ||
    loadedTopWorstVpOpinion;
  const hasError =
    errorTopBestGP ||
    errorTopBestGP2 ||
    errorTopWorstGP ||
    errorTopWorstGP2 ||
    errorTopBestMinistries ||
    errorTopBestMinistriesOM ||
    errorTopBestMinistries2 ||
    errorTopWorstMinistries ||
    errorTopWorstMinistriesOM ||
    errorTopWorstMinistries2 ||
    errorPrograms ||
    errorPremiers ||
    errorMinistries ||
    errorTopBestVP ||
    errorTopBestVP2 ||
    errorTopWorstVP ||
    errorTopWorstVP2 ||
    errorTopBestGpOM ||
    errorTopWorstGpOM ||
    errorTopBestVpOpinion ||
    errorTopWorstVpOpinion;

  const init = useCallback(() => {
    if (!loading) {
      loadTopBestGP(period);
      loadTopBestGP2(period);
      loadTopWorstGP(period);
      loadTopWorstGP2(period);
      loadTopBestVP(period);
      loadTopBestVP2(period);
      loadTopWorstVP(period);
      loadTopWorstVP2(period);
      loadTopBestMinistries(period);
      loadTopBestMinistriesOM(period);
      loadTopBestMinistries2(period);
      loadTopWorstMinistries(period);
      loadTopWorstMinistriesOM(period);
      loadTopWorstMinistries2(period);
      loadPrograms(period);
      loadPremiers(period);
      loadMinistries(period);
      loadTopBestGpOM(period);
      loadTopWorstGpOM(period);
      loadTopBestVpOpinion(period);
      loadTopWorstVpOpinion(period);
    }
  }, [
    loading,
    period,
    loadTopBestGP,
    loadTopBestGP2,
    loadTopWorstGP,
    loadTopWorstGP2,
    loadTopBestVP,
    loadTopWorstVP,
    loadTopBestVP2,
    loadTopWorstVP2,
    loadTopBestMinistries,
    loadTopBestMinistriesOM,
    loadTopWorstMinistries,
    loadTopWorstMinistriesOM,
    loadTopBestMinistries2,
    loadTopWorstMinistries2,
    loadPrograms,
    loadPremiers,
    loadMinistries,
    loadTopBestGpOM,
    loadTopWorstGpOM,
    loadTopBestVpOpinion,
    loadTopWorstVpOpinion,
  ]);

  useEffect(init, [period]);

  return {
    loading,
    loaded,
    init,
    hasError,
  };
};
