import React, { JSX, useState } from "react";
import { IncidentReport } from "SRC/components/IncidentReport";
import Status from "SRC/pages/Event/common/Checkpoints/Status/Status";
import {
  ItemList,
  TooltipWrapper,
} from "SRC/pages/Event/common/Checkpoints/TooltipCheckpoints/TooltipCheckpoints.styled";
import { IStepsInfo } from "SRC/pages/Event/interfaces";

interface ITooltipCheckpoints {
  title: IStepsInfo;
  children: JSX.Element;
}

const TooltipCheckpoints = ({ title, children }: ITooltipCheckpoints) => {
  const { namePoint, datePlan, dateFinish, statusPoint, deviation } = title;
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    if (open) {
      setOpen(false);
    }
  };
  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <TooltipWrapper
      arrow
      open={open}
      placement="top"
      onOpen={handleOpen}
      onClose={handleClose}
      title={
        <IncidentReport
          onClose={handleClose}
          open={open}
          handleOpen={handleOpen}
        >
          <ItemList>
            <div className="item">
              <div className="label">Наименование КТ</div>
              <div className="value">{namePoint}</div>
            </div>
            <div className="item">
              <div className="label">Статус</div>
              <Status className="value" value={statusPoint} />
            </div>
            <div className="item">
              <div className="label">Дата плана</div>
              <div className="value">{datePlan}</div>
            </div>
            <div className="item">
              <div className="label">Дата факта</div>
              <div className="value">{dateFinish}</div>
            </div>
            <div className="item">
              <div className="label">Нарушение сроков</div>
              <div className="value">{deviation}</div>
            </div>
          </ItemList>
        </IncidentReport>
      }
    >
      {children}
    </TooltipWrapper>
  );
};

export default TooltipCheckpoints;
