import { roundNumbersToFixed } from "SRC/helpers/roundNumbersToFixed";

export const simpleNumberByUnit = (num: number, skipFlag: boolean = true) => {
  // временно надо откатить нижеследующий код
  // после убрать skipFlag
  if (skipFlag) {
    return roundNumbersToFixed(num, 2);
  }

  if (!num) return 0;
  if (num >= 1000000000)
    return roundNumbersToFixed(num / 1000000000, 1) + "млрд";
  if (num >= 1000000) return roundNumbersToFixed(num / 1000000, 1) + "мл";
  return num;
};
