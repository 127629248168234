import { SecretProgram, WarningTriangle } from "ASSETS/svg";

interface IStatusTableProgramProps {
  atRisk: boolean | number;
  isSecret: boolean;
}
export const StatusTableProgram = ({
  atRisk,
  isSecret,
}: IStatusTableProgramProps) => {
  return (
    <>
      {Boolean(isSecret) && <SecretProgram style={{ minWidth: "0.8rem" }} />}
      {Boolean(atRisk && !isSecret) && <WarningTriangle />}
    </>
  );
};
