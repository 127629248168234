import { AxiosResponse } from "axios";

const HTML_RESPONSE = "text/html";

/**
 * Анализ статуса ответа
 *
 * @param {AxiosResponse} response Ответ запроса
 */
export const checkResponseStatus = (response: AxiosResponse) => {
  const responseType = response.headers["content-type"];

  /*
  На стендах при одновременном открытии под одним пользователем происходит сброс авторизации
  и все запросы к данным пробрасываются через https://gasu-office-test.roskazna.ru/auth/selection-form
  со статусом 200

  Различие между запросами с авторизацией и без - в типе ответа. Ответ с данными - json, с ошибкой - html

  Для предотвращение показа пустых данных обновляется родительская страница с iframe ресурса
  */
  if (responseType.startsWith(HTML_RESPONSE)) {
    window.parent?.location.reload();
  }

  return response;
};
