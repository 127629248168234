import React from "react";
import { Column } from "react-table";
import { TableCell } from "SRC/components/TableCell";
import { IIndicatorTableItem } from "SRC/redux/stores/StructureElement/useExtendedIndicators";

const columns: Column<IIndicatorTableItem>[] = [
  {
    Header: "ID",
    accessor: "id",
    width: 200,
    Cell: ({ value }) => <TableCell color="gray">{value}</TableCell>,
  },
  {
    Header: "Наименование",
    accessor: "name",
    width: "auto",
    Cell: ({ value }) => <TableCell>{value}</TableCell>,
  },
  {
    Header: "Ед. измерения",
    accessor: "unit",
    width: 170,
    // sorted: true,
    Cell: ({ value }) => <TableCell>{value}</TableCell>,
  },
  {
    Header: "Уровень показателя",
    accessor: "level",
    width: 200,
    Cell: ({ value }) => <TableCell>{value}</TableCell>,
  },
  {
    Header: "УД показателя, %",
    accessor: "performance",
    width: 200,
    Cell: ({ value }) => <TableCell>{value}</TableCell>,
  },
  {
    Header: "План",
    accessor: "plan",
    width: 150,
    Cell: ({ value }) => <TableCell>{value}</TableCell>,
  },
  {
    Header: "Факт",
    accessor: "fact",
    width: 150,
    Cell: ({ value }) => <TableCell>{value}</TableCell>,
  },
];

export const tableColumns = columns as Column<object>[];
