import { Box, useMediaQuery, useTheme } from "@mui/material";
import { Dispatch, SetStateAction, useCallback, useMemo } from "react";
import React from "react";
import { Column, TableInstance } from "react-table";
import { TableModal } from "SRC/components/FullPageTableModal/FullPageTableModal";
import { IncidentReport } from "SRC/components/IncidentReport";
import { OMSwitcher } from "SRC/components/OMSwitcher";
import { SecretSwitcher } from "SRC/components/SecretSwitcher";
import Table from "SRC/components/Table";
import { TableActionsButtons } from "SRC/components/TableActionsButtons/TableActionsButtons";
import { useMainTableData } from "SRC/hooks/summaryTable/useMainPrograms";
import { createMainGpRowsTableData } from "SRC/hooks/summaryTable/utils/mainData/mainDataGPNew";
import {
  css,
  GovProgramsWrapper,
} from "SRC/pages/Main/common/ComplexGrid/ComplexGrid.styled";
import { GovProgramsFilters } from "SRC/pages/Main/common/ComplexGrid/filterComponents/GovProgramsFilters";
import { getGovProgramsColumns } from "SRC/pages/Main/common/ComplexGrid/GovProgramsTab/tableData";
import {
  IGlobalFilters,
  useGlobalFilters,
} from "SRC/redux/slices/global/hooks/useFilters";
import { IFilters, useFilters } from "SRC/redux/slices/main/hooks/useFilters";
import {
  IMainPrograms,
  useMainPrograms,
} from "SRC/redux/slices/main/hooks/useMainPrograms";
import { CustomRowProps } from "SRC/types/data/data";

interface IGovProgramsTabProps {
  isModalOpen?: boolean;
  setModalOpen?: (value: boolean) => void;
  instance: TableInstance | undefined;
  setInstance: Dispatch<SetStateAction<TableInstance<{}> | undefined>>;
}

const sortedColumns = [
  "Уровень достижения",
  "План",
  "Факт",
  "Под риском",
  "Выполнено",
  "Код ГП",
];

export const RenderTable = ({
  isModalOpen,
  setModalOpen,
  instance,
  setInstance,
}: IGovProgramsTabProps) => {
  const { sources }: IGlobalFilters = useGlobalFilters();
  const { program, setSelectedProgram, setSearchRows }: IFilters = useFilters();
  const { programsMainData, programsMainExport: programsExport } =
    useMainTableData();
  const { fetching }: IMainPrograms = useMainPrograms();

  const columns: Column[] = useMemo(
    () => getGovProgramsColumns(sources.OM),
    [sources.OM]
  );

  const onChangeFilterRows = useCallback(
    (rows: CustomRowProps[]) => {
      setSearchRows(createMainGpRowsTableData(rows, sources.OM));
    },
    [sources.OM]
  );

  return (
    <GovProgramsWrapper>
      {isModalOpen && (
        <Box sx={css.actionsContainer}>
          <GovProgramsFilters instance={instance} />
          <OMSwitcher />
          <SecretSwitcher />
          <TableActionsButtons
            open={isModalOpen}
            setOpen={setModalOpen}
            {...programsExport}
          />
        </Box>
      )}
      <IncidentReport className="incident-container">
        <Table
          data={programsMainData}
          columns={columns}
          loading={fetching}
          sortedColumns={sortedColumns}
          selectedRow={program}
          setSelectedRow={setSelectedProgram}
          noDataCondition={programsMainData?.length === 0}
          getInstance={setInstance}
          classes={{
            thead: "table-header",
            tbody: "table-body",
            tfoot: "table-footer",
          }}
          onFilterRows={onChangeFilterRows}
        />
      </IncidentReport>
    </GovProgramsWrapper>
  );
};

export const GovProgramsTab = (props: IGovProgramsTabProps) => {
  const { isModalOpen, setModalOpen } = props;
  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <>
      <RenderTable {...props} key={Number(isModalOpen)} />
      {!match && (
        <TableModal
          open={isModalOpen}
          setOpen={setModalOpen}
          tableName="Государственные программы"
        >
          <RenderTable {...props} />
        </TableModal>
      )}
    </>
  );
};
