import { ICustomTableProps } from "SRC/types";

export const getCheckpointsFailTotal = (
  tableProps: ICustomTableProps,
  isOM: boolean
) => {
  let { checkpointsFailTotal, checkpointsOMFailTotal } =
    tableProps?.rows?.[0]?.original || {};

  return {
    checkpointsFactTotal: isOM ? checkpointsOMFailTotal : checkpointsFailTotal,
  };
};
