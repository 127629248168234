import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createPeriodDims, parseOlapdata, TDecoder } from "CORE/utils";
import { isContainedArray } from "SRC/helpers/dataTesters";
import { IPeriod } from "SRC/redux/slices/global/slices/filters";
import { getMinistries } from "SRC/redux/slices/summary/api";
import { ICodeItem } from "SRC/types";

export interface IMinistryData {
  DatePart: string;
  NAME: string;
  PERIOD2: string;
  PERIOD_CODE: string;
  PeriodEnd: string;
  PeriodStart: string;
  code: string;
  data: IDataItem[];
}
export interface IDataItem {
  [x: string]: any;
  [id: number]: ICodeItem;
}
export interface IMinistry {
  code: string;
  icon_url: string;
  data: IMinistryData[];
}

export interface IMinistriesState {
  items: IMinistry[];
  fetching: boolean;
  fetched: boolean;
  error: string;
}

export const extraActions = {
  get: createAsyncThunk(
    "ministries/get",
    async (period: IPeriod) => await getMinistries(period)
  ),
};

const initialState: IMinistriesState = {
  items: [],
  fetching: false,
  fetched: false,
  error: "",
};

const slice = createSlice({
  name: "ministries",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActions.get.pending, (state) => {
        state.items = [];
        state.fetching = true;
        state.fetched = false;
      })
      .addCase(
        extraActions.get.fulfilled,
        (state: IMinistriesState, action) => {
          const parsed = parseOlapdata(
            // @ts-ignore
            action.payload.data,
            decoder
          ) as unknown as IMinistry[];
          state.items = (isContainedArray(parsed) ? parsed : []) as IMinistry[];
          state.fetching = false;
          state.fetched = true;
        }
      )
      .addCase(extraActions.get.rejected, (state, action) => {
        state.fetching = false;
        // state.error = action.error;
      });
  },
});

export const actions = {
  ...slice.actions,
  ...extraActions,
};

export default slice;

const decoder: TDecoder = {
  period: createPeriodDims(1774),
  info: {
    code: 1770,
    dimensions: {
      icon_url: "icon_url",
    },
  },
};
