import { CSSProperties } from "@mui/styles";
import { Box, SxProps } from "@mui/system";
import React from "react";

import { css } from "./WIP.styled";

interface IWIPTagProps {
  sx?: SxProps;
}
export const WIPTag = ({ sx = {} }: IWIPTagProps) => {
  return (
    <Box sx={{ ...css.tag, ...sx } as SxProps} component="span">
      в работе
    </Box>
  );
};

interface IWIPProps {
  children?: React.ReactNode;
  display?: boolean;
  position?: "relative" | "absolute" | "fixed" | "sticky";
  bordered?: boolean;
  style?: CSSProperties;
}
export const WIP = ({
  children,
  display = true,
  position = "relative",
  bordered = false,
  style = {},
}: IWIPProps) => (
  <>
    {display && (
      <Box sx={css.worker(position, bordered, style)} component="span" />
    )}
    {children}
  </>
);
