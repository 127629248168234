import { combineReducers } from "@reduxjs/toolkit";
import programsSlice, {
  IMainProgramsState,
} from "SRC/redux/list/programs-main";

import filtersSlice, { IFiltersState } from "./slices/filters";
import ministriesSlice, { IMinistriesState } from "./slices/ministries";

export interface IMinistryReducer {
  programs: IMainProgramsState;
  ministries: IMinistriesState;
  filters: IFiltersState;
}

const reducer = combineReducers<IMinistryReducer>({
  programs: programsSlice.reducer,
  ministries: ministriesSlice.reducer,
  filters: filtersSlice.reducer,
});
export default reducer;
