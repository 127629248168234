import classnames from "classnames";
import React, { useMemo } from "react";
import { DoughnutPale } from "SRC/components/Charts";
import { CheckSecretData } from "SRC/components/CheckSecretData";
import { PopulationWrapper } from "SRC/pages/Main/common/Analitics/Population/Population.styled";
import { useFilters } from "SRC/redux/slices/main/hooks/useFilters";
import { useStats } from "SRC/redux/slices/main/hooks/useStats";
import { IPopulationData } from "SRC/types/analitics";

export const Population = () => {
  const { items: stats } = useStats();
  const data = useMemo(() => stats[0]?.data || {}, [stats]);
  const { isSphereSecret } = useFilters();

  const populationData = useMemo<IPopulationData>(
    () => ({
      positive: data["Опросы населения, да"],
      negative: data["Опросы населения, нет"],
      percent:
        (data["Опросы населения, да"] / data["Опросы населения, нет"]) * 100 ||
        0,
    }),
    [data]
  );
  return (
    <PopulationWrapper
      className={classnames({
        "is-secret": isSphereSecret,
      })}
    >
      <CheckSecretData isSecret={isSphereSecret}>
        <div className="title">Удовлетворенность результатами ГП</div>
        <div className="chart-container">
          <div className="doughnut">
            <DoughnutPale success={populationData?.positive || 0} />
          </div>
          <div className="info-container">
            <div className="info">
              <div className="info-item">
                <div className="circle circle-green" />
                <div className="number">
                  {populationData?.positive}
                  <span>%</span>
                </div>
              </div>
              <div className="info-item">
                <div className="circle circle-red" />
                <div className="number">
                  {populationData?.negative}
                  <span>%</span>
                </div>
              </div>
            </div>
            <div className="info">
              <div className="info-item">
                <div className="circle circle-green" />
                <div className="status-name">Да</div>
              </div>
              <div className="info-item">
                <div className="circle circle-red" />
                <div className="status-name">Нет</div>
              </div>
            </div>
          </div>
        </div>
      </CheckSecretData>
    </PopulationWrapper>
  );
};
