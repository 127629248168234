import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import { useMemo } from "react";
import { css } from "SRC/components/FilterForms/MainHeaderFilter/MainHeaderFilter.styled";
import Counter from "SRC/components/ProgramsCounter/ProgramsCounter";
import { IOption } from "SRC/components/SelectWithSearch/SelectWithSearch";
import SelectWithSearchMinistry from "SRC/components/SelectWithSearch/SelectWithSearchMinistry";
import SelectWithSearchVP from "SRC/components/SelectWithSearch/SelectWithSearchVP";
import {
  EGridTabs,
  useFilters,
} from "SRC/redux/slices/summary/hooks/useFilters";
import {
  IMinistries,
  useMinistries,
} from "SRC/redux/slices/summary/hooks/useMinistries";
import {
  IPremiers,
  usePremiers,
} from "SRC/redux/slices/summary/hooks/usePremiers";
import {
  IPrograms,
  usePrograms,
} from "SRC/redux/slices/summary/hooks/usePrograms";

export const GeneralInfoTabFilters = () => {
  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.up("lg"));
  const {
    ministry,
    vp,
    setSelectedMinistry,
    setSelectedVP,
    filteredPrograms,
    filteredMinistries,
    filteredVPs,
    complexGridTab,
  } = useFilters();

  const { items: vpItems }: IPremiers = usePremiers();
  const { items: programs }: IPrograms = usePrograms();
  const { items: minItems }: IMinistries = useMinistries();

  const premiers: IOption[] = useMemo(
    () =>
      vpItems
        .filter((item) => filteredVPs.includes(item.info.VP_CODE))
        .map((item) => ({
          value: item.info.VP_CODE,
          label: item.info.VP_SHORT_NAME,
        })),
    [vpItems, filteredVPs]
  );

  const ministries: IOption[] = useMemo(() => {
    return minItems
      .filter((ministry) => filteredMinistries.includes(ministry.info.code))
      .map((item) => ({
        value: item.info.code,
        label: item.info.code,
      }));
  }, [minItems]);

  const selectStyle = useMemo(() => css.selector(theme), [theme]);

  return (
    <Grid
      container
      justifyContent={match ? "space-between" : "flex-start"}
      sx={{
        marginBottom: match ? "1rem" : "0rem",
        maxWidth: match ? "100%" : "50%",
      }}
    >
      <Box sx={css.selectors}>
        <SelectWithSearchMinistry
          value={ministry}
          label="Министерство"
          staticLabel={false}
          style={selectStyle}
          onChange={setSelectedMinistry}
          options={ministries}
        />
        <SelectWithSearchVP
          value={vp}
          label="Вице-премьер"
          staticLabel={false}
          style={selectStyle}
          onChange={setSelectedVP}
          options={premiers}
        />
      </Box>
      {complexGridTab === EGridTabs.PROGRAMS && (
        <Grid
          item
          sm={2}
          sx={{
            display: "flex",
            height: "2.5rem",
            alignSelf: "flex-end",
            marginLeft: "1rem",
          }}
        >
          <Counter
            tableDataLength={filteredPrograms.length}
            totalDataLength={programs.length}
          />
        </Grid>
      )}
    </Grid>
  );
};
