import { Stack, useTheme } from "@mui/material";
import moment from "moment";
import React from "react";
import { IncidentReport } from "SRC/components/IncidentReport";
import { OverlayBlock } from "SRC/components/OverlayBlock";
import { IValue, WideProgress } from "SRC/components/ProgressBar";
import { ESources } from "SRC/constants/globals";
import { useGlobalFilters } from "SRC/redux/slices/global/hooks/useFilters";
import { useIndicatorData } from "SRC/redux/slices/indicator/hooks/useIndicatorData";

import { css, Wrapper } from "./ValueTab.styled";

export const ValueTab: React.FC = () => {
  const theme = useTheme();
  const { items: data, fetching: loading } = useIndicatorData();
  const { period, sources } = useGlobalFilters();
  const periodMonth = moment(period.value).format("MMMM");
  const periodYear = moment(period.value).format("YYYY");

  const yearPlan: IValue = {
    value: data?.data?.[5243]?.sum || 0,
    name: `План на ${periodYear}`,
  };

  const plan: IValue = {
    value: data?.data?.[5240]?.sum || 0,
    name: `План на ${periodMonth} ${periodYear}`,
  };

  const fact: IValue = {
    value:
      (sources[ESources.OM]
        ? data?.data?.[5470]?.sum
        : data?.data?.[5241]?.sum) || 0,
    name: "Факт",
    color: theme.palette.charts.red,
    legend: "Не совпадает с планом",
  };

  const yearFact: IValue = {
    value: data?.data?.[5240]?.sum || 0,
    name: "Прогноз",
    color: theme.palette.charts.green,
    legend: "Совпадает с планом",
  };

  return (
    <Wrapper>
      <IncidentReport>
        <OverlayBlock isFetching={loading} hasData={Boolean(data)}>
          <Stack sx={css.container}>
            <WideProgress title="Плановое значение" values={[plan, yearPlan]} />
            <WideProgress
              title="Факт и прогноз"
              values={[fact, yearFact]}
              color={theme.palette.charts.blueArea}
              hasLegend
            />
          </Stack>
        </OverlayBlock>
      </IncidentReport>
    </Wrapper>
  );
};
