import React, { useEffect, useMemo } from "react";
import { useParams } from "react-router";
import Breadcrumbs from "SRC/components/Breadcrumbs/Breadcrumbs";
import { Card } from "SRC/layouts";
import { useGosprogram, useProgram } from "SRC/redux/slices/gosprogram/hooks";
import { useFilters } from "SRC/redux/slices/indicator/hooks/useFilters";
import { useIndicator } from "SRC/redux/slices/indicator/hooks/useIndicator";
import { useIndicatorData } from "SRC/redux/slices/indicator/hooks/useIndicatorData";
import { PROGRAM_VIEW, PROGRAMS, setParamsToUrl } from "SRC/urls";

import { Content } from "./common/Content/Content";
import { Description } from "./common/Description/Description";
import { Foot } from "./common/Foot/Foot";
import { Header } from "./common/Header/Header";
import { Sidebar } from "./common/Sidebar/Sidebar";

interface IProps {
  modalId?: string | number | null;
  close?(): void;
}

export const Indicator: React.FC<IProps> = ({ modalId }) => {
  useIndicator();
  const { indicatorId, programId = "" } = useParams();
  const { init } = useGosprogram();
  const { items: data } = useIndicatorData();
  const { fetching, items: program } = useProgram();

  const { setSelectedIndicatorId, clear } = useFilters();

  useEffect(() => () => clear(), []);

  useEffect(() => {
    if (modalId) {
      setSelectedIndicatorId(modalId);
    } else if (indicatorId) {
      setSelectedIndicatorId(indicatorId);
    } else {
      clear();
    }
  }, [indicatorId, modalId]);

  useEffect(() => {
    if (programId && !fetching) {
      init({ programId });
    }
  }, [programId]);

  const links = useMemo(
    () => [
      {
        title: "Госпрограммы",
        link: PROGRAMS,
      },
      {
        title: program?.gp_name || "",
        link: setParamsToUrl(PROGRAM_VIEW, { programId }),
      },
      {
        title: (data?.description.name as string) || "",
      },
    ],
    [program, data]
  );

  return (
    <>
      {!Boolean(modalId) && <Breadcrumbs links={links} />}
      <Card
        head={<Header />}
        description={<Description />}
        summary={<Sidebar />}
        content={<Content />}
        foot={<Foot />}
      />
    </>
  );
};
