import { styled } from "@mui/material";

export const VideosModalStyles = styled("div")({
  "& > .modal-header": {
    "& > .title": {
      marginBottom: "1rem",
      fontSize: "1.5rem",
      lineHeight: "1.2",
      fontWeight: "500",
    },
  },
  "& > .video-content": {
    "& iframe": {
      display: "block",
      width: "100%",
      minWidth: "640px",
      minHeight: "360px",
      aspectRatio: "16/9",
    },
  },
});
