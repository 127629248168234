import { Theme } from "@mui/material";
import { IDPValue } from "SRC/components/Charts";

export const getOption = (
  values: IDPValue[],
  total: number,
  withDarkBgr: boolean | undefined,
  theme: Theme
) => ({
  grid: {
    borderWidth: 0,
    width: "100%",
    height: "100%",
    left: "0",
    bottom: "0",
  },
  tooltip: {
    show: false,
  },
  series: values.map(({ value, settings }, index) => ({
    type: "pie",
    color: settings.successColor || "green",
    radius: [`${90 - index * 10}%`, `${95 - index * 10}%`],
    avoidLabelOverlap: false,
    label: {
      show: false,
      position: "center",
    },
    emphasis: {
      scale: false,
      color: "#f7f7f7",
    },
    labelLine: {
      show: false,
    },
    emptyCircleStyle: true,
    data: [
      {
        value: value,
        itemStyle: {
          color: value < 100 ? settings.valueColor : settings.successColor,
          borderRadius: theme.shape.borderRadius,
        },
      },
      {
        value: total - Number(value),
        itemStyle: {
          color: withDarkBgr
            ? theme.palette.complementary.darkBlue
            : theme.palette.main.darkBlue,
        },
      },
    ],
  })),
});
