import { Grid, IconButton, Modal } from "@mui/material";
import { Close } from "ASSETS/svg/close";
import React, { Dispatch, ReactNode, SetStateAction } from "react";
import { useFilters } from "SRC/redux/slices/summary/hooks/useFilters";

import { ModalContent } from "./FullPageTableModal.styled";

interface ITableModalProps {
  open?: boolean;
  setOpen?: (value: boolean) => void;
  setEventModalMode?: Dispatch<
    SetStateAction<"default" | "eventCard" | "eventsFilter">
  >;
  tableName: string;
  children: ReactNode;
  backButton?: ReactNode;
}

export const TableModal = ({
  open = false,
  setOpen,
  setEventModalMode,
  tableName,
  children,
  backButton,
}: ITableModalProps) => {
  const { setSelectedVP, setSelectedMinistry } = useFilters();
  const handleClose = () => {
    setOpen?.(false);
    setEventModalMode?.("default");
    setSelectedVP("");
    setSelectedMinistry("");
  };
  return (
    <Modal open={open} onClose={handleClose}>
      <ModalContent>
        <Grid container className="header">
          {backButton}
          <Grid item>
            <div className="title">{tableName}</div>
          </Grid>
          <Grid item>
            <IconButton className="close-button" onClick={handleClose}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
        {children}
      </ModalContent>
    </Modal>
  );
};
