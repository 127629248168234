import { styled } from "@mui/material";

export const ModalWrapper = styled("div")(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  display: "flex",
  flexDirection: "column",
  borderRadius: "1rem",
  background: theme.palette.background.default,
  userSelect: "none",

  "& .modal-header": {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    padding: "1rem",
    borderRadius: "1rem",
    background: "#2E364A",
  },
  "& .modal-header > .header-icons": {
    width: "10%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 0,
  },

  "& .icon-close": {
    width: "10px",
    cursor: "pointer",
  },

  "& .icon-crop": {
    marginRight: "0.5rem",
    cursor: "pointer",
  },

  "& .title": {
    width: "90%",
    margin: "auto 0",
    fontSize: "1.3rem",
    fontWeight: "bold",
    color: theme.palette.primary.contrastText,
  },

  "& .container": {
    display: "flex",
    flexWrap: "wrap",
    borderRadius: "1rem",
    background: "#212735",
  },

  "& .columns": {
    width: "100%",
    display: "flex",
    flexWrap: "nowrap",
    gap: "1rem",

    paddingTop: "1.3rem",
  },

  "& .column": {
    width: "50%",
    display: "flex",
    flexDirection: "column",
  },

  "& .MuiTextField-root": {
    width: "100%",
  },

  "& .MuiInputBase-root": {
    background: "#2E364A",
  },

  "& .MuiBox-root": {
    width: "100%",
  },

  "& .MuiFormControl-root": {
    boxSizing: "border-box",
    maxWidth: "100%",
  },

  "& .MuiInputAdornment-root": {
    marginTop: "auto",
    color: "#A0B0D0",
  },

  "& .modal-footer": {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "1rem",
    borderRadius: "1rem",
    background: "#2E364A",
    color: "#ffffff",
  },

  "& .modal-footer > .footer-buttons > button": {
    marginLeft: "1rem",
    fontSize: "1rem",
    textTransform: "none",
  },
}));
