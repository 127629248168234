import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { isContainedArray } from "SRC/helpers/dataTesters";
import { ITopVP } from "SRC/pages/Summary/interfaces";
import { IPeriod } from "SRC/redux/slices/global/slices/filters";
import { getTopBestVP } from "SRC/redux/slices/summary/api";

export interface IVpTopBestState {
  items: ITopVP[];
  fetching: boolean;
  fetched: boolean;
  error: string;
}

export const extraActions = {
  get: createAsyncThunk(
    "topBestVP/get",
    async (period: IPeriod) => await getTopBestVP(period)
  ),
};

const initialState: IVpTopBestState = {
  items: [],
  fetching: false,
  fetched: false,
  error: "",
};

const slice = createSlice({
  name: "topBestVP",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActions.get.pending, (state) => {
        state.items = [];
        state.fetching = true;
        state.fetched = false;
      })
      .addCase(extraActions.get.fulfilled, (state: IVpTopBestState, action) => {
        const data = action.payload.data?.[0]?.data?.[0]?.data;
        state.items = isContainedArray(action.payload.data) ? data : [];
        state.fetching = false;
        state.fetched = true;
      })
      .addCase(extraActions.get.rejected, (state, action) => {
        state.fetching = false;
      });
  },
});

export const actions = {
  ...slice.actions,
  ...extraActions,
};

export default slice;
