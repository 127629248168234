import { SxProps } from "@mui/material";
import React, { useMemo } from "react";
import SelectWithSearch from "SRC/components/SelectWithSearch/SelectWithSearch";
import { useSETasksTableData } from "SRC/pages/Program/tableData/useSETasksTableData";
import { useFilters } from "SRC/redux/slices/gosprogram/hooks/useFilters";

type TOptionValue = string | number;

interface IOption {
  value: TOptionValue;
  label: string;
  disabled?: boolean;
}

interface IStyle {
  labelStyle?: SxProps;
  selectStyle?: SxProps;
  optionStyle?: SxProps;
}

interface IFilterFeatureProps {
  onChange(value: TOptionValue): void;
  label?: string;
  staticLabel?: boolean;
  colorizeActiveOption?: boolean;
  style: IStyle;
}

export const FilterNameStructure = ({
  onChange,
  staticLabel = true,
  label,
  colorizeActiveOption = true,
  style,
}: IFilterFeatureProps) => {
  const { selectedStructureElementName } = useFilters();
  const { uniqueStructureNames } = useSETasksTableData();

  const selectOptionsStructures = useMemo(
    () =>
      uniqueStructureNames.map((item) => ({
        value: item,
        label: item,
      })),
    [uniqueStructureNames]
  );

  const customOptions = useMemo(
    () => [
      {
        value: "",
        label: label || "Все",
      },
      ...selectOptionsStructures,
    ],
    [label, selectOptionsStructures]
  );

  return (
    <SelectWithSearch
      onChange={onChange}
      options={customOptions}
      getOptionLabel={(option: IOption) => option.label}
      staticLabel={staticLabel}
      label={label}
      colorizeActiveOption={colorizeActiveOption}
      style={style}
      hasCloseIcon={Boolean(selectedStructureElementName)}
      value={selectedStructureElementName}
    />
  );
};
