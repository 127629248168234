import { Typography } from "@mui/material";
import { IncidentReport } from "SRC/components/IncidentReport";
import { useEventData } from "SRC/redux/slices/event/hooks/useEventData";

export const CharacterTab = () => {
  const { items: data } = useEventData();

  const text = data?.info.characteristic || "";

  return (
    <IncidentReport>
      <Typography
        component={"p"}
        sx={{
          fontSize: "1.2rem",
          lineHeight: "1.675rem",
          padding: "1rem 2rem",
          minHeight: "27rem",
          flex: 1,
        }}
      >
        {text}
      </Typography>
    </IncidentReport>
  );
};
