import { sortColumn } from "SRC/helpers/sort-column/sort-column";

export const sortInPerformanceColumn = (isOM: boolean) => {
  return (
    { original: rowA, subRows: subRowsA }: any,
    { original: rowB, subRows: subRowsB }: any,
    ...rest: any[]
  ) => {
    const [, isDesc] = rest;
    return sortColumn(
      {
        id: rowA.id,
        hasFeature: isOM ? rowA.inPerformanceOM : rowA.inPerformance,
        value: isOM ? rowA.inPerformanceOM : rowA.inPerformance,
      },
      {
        id: rowB.id,
        hasFeature: isOM ? rowB.inPerformanceOM : rowB.inPerformance,
        value: isOM ? rowB.inPerformanceOM : rowB.inPerformance,
      },
      !isDesc
    );
  };
};
