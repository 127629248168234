import React from "react";
import { TableProps, UseExpandedRowProps, UseTableRowProps } from "react-table";
import { CheckSecretData } from "SRC/components/CheckSecretData";
import NavigateToProgram from "SRC/components/NavigateToProgram/NavigateToProgram";
import { PremierInfo } from "SRC/components/PremierInfo";
import { ExpanderCell, nestingTest } from "SRC/components/Table";
import { TableCell } from "SRC/components/TableCell";
import { COLUMNS } from "SRC/components/TableColumnTemplates/columns";
import { IPremierTableItem } from "SRC/hooks/summaryTable/interfaces";

type CustomRowProps = UseTableRowProps<any> & UseExpandedRowProps<any>;

interface CustomTableProps extends TableProps {
  row: CustomRowProps;
  rows?: UseTableRowProps<IPremierTableItem>[];
}

const getGeneralInfoPremiersColumns = (isOM: boolean) => [
  { ...COLUMNS.program.status() },
  {
    Header: "",
    sticky: "left",
    accessor: "expander",
    width: 90,
    columns: [
      {
        Header: "Код ГП",
        width: 90,
        sticky: "left",
        accessor: "expander",
        Cell: (tableProps: CustomTableProps) => {
          const { id } = tableProps.row.original;
          if (nestingTest(tableProps.row)) {
            return (
              <TableCell color="gray" align="center">
                {String(id).padStart(2, "0")}
              </TableCell>
            );
          } else {
            return <ExpanderCell row={tableProps.row} />;
          }
        },
        Footer: "",
      },
    ],
    Footer: "",
  },
  {
    Header: "",
    accessor: "name",
    sticky: "left",
    columns: [
      {
        Header: "Вице - премьер",
        sticky: "left",
        accessor: "name",
        width: 232,
        colspan: (row: CustomRowProps): number => (nestingTest(row) ? 2 : 1),
        Cell: (tableProps: CustomTableProps) => {
          if (nestingTest(tableProps.row)) {
            return (
              <NavigateToProgram program={tableProps.row.original} isSmall />
            );
          }

          return <PremierInfo premierCode={tableProps.row.original.code} />;
        },
        Footer: "",
      },
    ],
    Footer: "",
  },
  {
    Header: "",
    accessor: "govPrograms",
    sticky: "left",
    width: 180,
    columns: [
      {
        Header: "Кол-во госпрограмм",
        sticky: "left",
        width: 180,
        accessor: "govPrograms",
        Cell: (tableProps: CustomTableProps) => {
          const { subRows, govPrograms } = tableProps.row.original;
          const numOfPrograms = subRows ? subRows.length : null;
          if (!nestingTest(tableProps.row)) {
            return (
              <TableCell>
                <CheckSecretData isSecret={!numOfPrograms && govPrograms}>
                  {numOfPrograms}
                </CheckSecretData>
              </TableCell>
            );
          } else {
            return <TableCell />;
          }
        },
        Footer: (tableProps: CustomTableProps) => {
          const total = React.useMemo(() => {
            return Array(tableProps.rows || [])
              .flat()
              .reduce((sum: number, row: UseTableRowProps<any>) => {
                const govPrograms = row.original.subRows?.length ?? null;
                return govPrograms + sum;
              }, 0);
          }, [tableProps.rows]);

          return <TableCell>{total}</TableCell>;
        },
      },
    ],
    Footer: "",
  },
  {
    Header: "",
    accessor: isOM ? "performanceOM" : "performance",
    columns: [
      {
        ...COLUMNS.performance.fact(isOM),
      },
    ],
    Footer: "",
  },
  {
    Header: "Кассовое исполнение",
    accessor: "cash",
    columns: [{ ...COLUMNS.cash.plan() }, { ...COLUMNS.cash.fact() }],
    Footer: "",
  },
  {
    Header: "Показатели ГП",
    accessor: "indicators",
    columns: [
      { ...COLUMNS.indicators.plan(isOM) },
      { ...COLUMNS.indicators.fact(isOM) },
      { ...COLUMNS.indicators.fail(isOM) },
    ],
    Footer: "",
  },
  {
    Header: "Структурные элементы",
    accessor: "structure",
    columns: [
      { ...COLUMNS.structureElements.plan(isOM) },
      { ...COLUMNS.structureElements.fact(isOM) },
      { ...COLUMNS.structureElements.fail(isOM) },
    ],
    Footer: "",
  },
  {
    Header: "Показатели СЭ",
    accessor: "indicatorsSE",
    columns: [
      { ...COLUMNS.indicatorsSE.plan(isOM) },
      { ...COLUMNS.indicatorsSE.fact(isOM) },
      { ...COLUMNS.indicatorsSE.fail(isOM) },
    ],
    Footer: "",
  },
  {
    Header: "Мероприятия",
    accessor: "events",
    columns: [
      { ...COLUMNS.events.plan(isOM) },
      { ...COLUMNS.events.fact(isOM) },
      { ...COLUMNS.events.fail(isOM) },
    ],
    Footer: "",
  },
  {
    Header: "Контрольные точки",
    accessor: "checkpoints",
    columns: [
      { ...COLUMNS.checkpoints.plan(isOM) },
      { ...COLUMNS.checkpoints.fact(isOM) },
      { ...COLUMNS.checkpoints.fail(isOM) },
    ],
    Footer: "",
  },
];

export { getGeneralInfoPremiersColumns };
