import { parseOlapdata } from "SRC/store/api/planeta";
import { TOlapdataResponse } from "SRC/store/api/planeta/types";

import { ISphere } from "../../../types";

export const getSpheresResponse = (response: TOlapdataResponse): ISphere[] => {
  const parsedOlapdata = parseOlapdata(response);

  const spheres = parsedOlapdata.map(({ indicatorVals, dimensionItems }) => {
    return {
      id: dimensionItems[1835].code,
      name: dimensionItems[1835].attributeVals.NAME,
      sortIndex: dimensionItems[1835].attributeVals.sort_order,
      performance: indicatorVals[1796].sum,
      performanceOM: indicatorVals[5265].sum,
      programsCount: indicatorVals[1837].sum,
      publicProgramsCount: indicatorVals[5491].sum,
    };
  });

  spheres.sort(({ sortIndex: sortIndexA }, { sortIndex: sortIndexB }) => {
    return sortIndexA > sortIndexB ? 1 : -1;
  });

  return spheres;
};
