import { styled, Theme } from "@mui/material";

export const Wrapper = styled("div")(({ theme }) => ({
  ".tooltip-content": {
    cursor: "pointer",
    color: theme.palette.primary.main,
  },
}));

export const WrapperDetailed = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
}));

export const css = {
  hintContainer: {
    minWidth: "16rem",
    "& .MuiTooltip-tooltip": {
      backgroundColor: (theme: Theme) => theme.palette.background.paper,
      padding: "1rem 1.5rem 1.5rem 1rem",
      color: (theme: Theme) => theme.palette.primary.contrastText,
    },
  },
};
