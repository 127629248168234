import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { PLANETA_QUERY_CONFIG } from "SRC/store/api/planeta";

import { getSpheresRequest, getSpheresResponse } from "../../api/planeta";
import { ISphere, TGetSpheresParams } from "../../types";

export const spheresApi = createApi({
  reducerPath: "spheres",
  baseQuery: fetchBaseQuery(PLANETA_QUERY_CONFIG),
  endpoints: (builder) => ({
    getSpheres: builder.query<ISphere[], TGetSpheresParams>({
      query: getSpheresRequest,
      transformResponse: getSpheresResponse,
    }),
  }),
});

export const { useGetSpheresQuery } = spheresApi;
