import { Theme } from "@mui/material";
import { styled } from "@mui/material";
import { ESources } from "SRC/constants";

export const Wrapper = styled("div")(({ theme }) => {
  const {
    background: { paper },
    complementary: { darkBlueLight, linearDarkBlue2 },
    primary: { contrastText },
    main: { darkBlue, gray },
    text: { opinion },
  } = theme.palette;

  return {
    ".incident-container": {
      position: "relative",
      cursor: "pointer",
      display: "inline-block",
      width: "calc(20% - 0.8rem)",
      margin: "0 1rem 1rem 0",
      verticalAlign: "middle",

      "&:nth-of-type(5n)": {
        marginRight: "0",
      },
      "> .program-icon": {
        position: "absolute",
        zIndex: "2",
        top: "50%",
        transform: "translate(0, -50%)",
        width: "2rem",
        paddingTop: "0.25rem",
        display: "flex",
        flexDirection: "column",
        borderRadius: "1.5rem",
        border: `2px solid ${paper}`,
        fontSize: "0.875rem",
        lineHeight: "1.25rem",
        fontWeight: "600",
        textAlign: "center",
        backgroundColor: darkBlue,
        color: gray,

        img: {
          width: "100%",
        },
      },

      "> .info": {
        display: "flex",
        flexDirection: "row",
        height: "3.75rem",
        marginLeft: "0.75rem",
        alignItems: "center",
        borderRadius: "0.5rem",
        border: `2px solid ${paper}`,
        backgroundColor: darkBlueLight,
        overflow: "hidden",

        "> .title": {
          flex: "1",
          padding: "0.75rem 1rem 0.75rem 1.5rem",
          fontSize: "1rem",
          lineHeight: "1.125rem",
          overflow: "hidden",

          "> .name": {
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          },

          svg: {
            width: "1rem",
            height: "1rem",
          },
        },

        "> .secret-program-icon": {
          flex: "0 0 calc(4ch + 2rem)",
          display: "flex",
          height: "100%",
          alignItems: "center",
          borderLeft: `2px solid ${linearDarkBlue2}`,

          "> span": {
            flex: "1",
            textAlign: "center",
          },

          svg: {
            width: "1.4rem",
            height: "1.4rem",
          },
        },

        "> button": {
          width: "2rem",
          height: "100%",
          minWidth: "2rem",
          borderRadius: "0",
          backgroundColor: `${paper}C0`,
          color: contrastText,
        },

        [`> .progress-${ESources.OM}`]: {
          color: opinion,
        },
      },
    },
  };
});

export const css = {
  container: {
    height: "100%",
    width: "100%",
  },
  elementSX: (theme: Theme) => ({
    flex: 1,
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    minWidth: `calc(${100 / 5}% - 1.5rem)`,
    maxWidth: `${100 / 5}%`,

    [theme.breakpoints.up("md")]: {
      minWidth: `calc(${100 / 7}% - 1.5rem)`,
      maxWidth: `${100 / 7}%`,
    },
    [theme.breakpoints.up("xl")]: {
      minWidth: `calc(${100 / 10}% - 1.5rem)`,
      maxWidth: `${100 / 10}%`,
    },
  }),
  item: {
    minHeight: "4.3rem",
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    opacity: 1,
    width: "100%",
    borderRadius: "0.5rem",
    cursor: "pointer",
  },
  icon: (theme: Theme) => ({
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    left: "-1rem",
    display: "flex",
    flexDirection: "column",
    flex: "0 0 auto",
    backgroundColor: theme.palette.main.darkBlue,
    border: `2px solid ${theme.palette.background.paper}`,
    borderRadius: "1rem",
    alignItems: "center",

    "& img": {
      height: "1.5rem",
      width: "auto",
    },
  }),
  title: (value: number) => (theme: Theme) => ({
    justifyContent: "space-between",
    flex: 1,
    height: "100%",
    overflow: "hidden",
    fontSize: "1rem",
    lineHeight: "1em",
    maxHeight: "100%",
    display: "flex",
    alignItems: "center",
    padding: "0.3rem 0.8rem 0.3rem 1.3rem",
    borderRadius: "0.5rem 0 0 0.5rem",
    background: `linear-gradient(
      0deg,
      ${theme.palette.complementary.darkBlue} -20%,
      ${
        value < 100
          ? theme.palette.complementary.whiteTransparent
          : theme.palette.complementary.darkGreenTransparent
      } ${value}%,
      ${theme.palette.complementary.darkBlueLight} ${value}%,
      ${theme.palette.complementary.darkBlueLight} 100%
    )`,
  }),

  indicator: (value: number) => (theme: Theme) => ({
    flex: "0 0 auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "3rem",
    borderLeft: `2px solid ${theme.palette.background.default}`,
    background: `linear-gradient(
      0deg,
      ${theme.palette.complementary.darkBlue} -20%,
      ${
        value < 100
          ? theme.palette.complementary.whiteTransparent
          : theme.palette.complementary.darkGreenTransparent
      } ${value}%,
      ${theme.palette.complementary.darkBlueLight} ${value}%,
      ${theme.palette.complementary.darkBlueLight} 100%
    )`,

    "& > span": {
      color: theme.palette.text.primary,
      fontSize: "0.9rem",
    },

    "& > p": {
      fontSize: "1.6rem",
      fontWeight: "700",
    },
  }),
  shortName: (theme: Theme) => ({
    textShadow: `1px 1px 2px ${theme.palette.complementary.darkGrayBlue}`,
    flex: "0 0 85%",
  }),
  secretContainer: {
    flex: "0 0 15%",
    textAlign: "center",
    position: "absolute",
    top: "0.5rem",
    right: "5.5rem",
    "& svg": {
      width: "1rem",
      minWidth: "1rem",
    },
  },
  rightArrowContainer: {
    flex: "0 0 auto",
    display: "flex",
    height: "100%",
    width: "2rem",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "0 0.5rem 0.5rem 0",
    borderLeft: (theme: Theme) =>
      `2px solid ${theme.palette.background.default}`,
    background: (theme: Theme) => theme.palette.complementary.darkGrayBlue,
  },
};
