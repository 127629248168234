import { ICustomTableProps } from "SRC/types";

export const getIndicatorsSEFailTotal = (
  tableProps: ICustomTableProps,
  isOM: boolean
) => {
  // @ts-ignore
  let { indicatorsSEFailTotal, indicatorsSEOMFailTotal } =
    tableProps?.rows?.[0]?.original || {};

  return {
    indicatorsSEFailTotal: isOM
      ? indicatorsSEOMFailTotal
      : indicatorsSEFailTotal,
  };
};
