import { combineReducers } from "@reduxjs/toolkit";
import programsSlice, { IProgramsState } from "SRC/redux/list/programs";
import mainProgramsSlices, {
  IMainProgramsState,
} from "SRC/redux/list/programs-main";
import {
  IMainPremiersState,
  slice as mainPremiersSlice,
} from "SRC/redux/slices/main/slices/mainPremiers";

import filtersSlice, { IFiltersState } from "./slices/filters";
import goalsSlice, { IGoalsState } from "./slices/goals";
import ministriesSlice, { IMinistriesState } from "./slices/ministries";
import { IPremiersState, slice as premiersSlice } from "./slices/premiers";
import {
  ISpheresState,
  slice as shperesSlice,
  slice2 as sumSpheresSlice,
} from "./slices/spheres";
import statsSlice, { IStatsState } from "./slices/stats";

export interface IMainReducer {
  mainPrograms: IMainProgramsState;
  ministries: IMinistriesState;
  programs: IProgramsState;
  goals: IGoalsState;
  premiers: IPremiersState;
  mainPremiers: IMainPremiersState;
  stats: IStatsState;
  filters: IFiltersState;
  spheres: ISpheresState;
  sumSpheres: ISpheresState;
}

const reducer = combineReducers<IMainReducer>({
  mainPrograms: mainProgramsSlices.reducer,
  ministries: ministriesSlice.reducer,
  programs: programsSlice.reducer,
  goals: goalsSlice.reducer,
  premiers: premiersSlice.reducer,
  mainPremiers: mainPremiersSlice.reducer,
  stats: statsSlice.reducer,
  filters: filtersSlice.reducer,
  spheres: shperesSlice.reducer,
  sumSpheres: sumSpheresSlice.reducer,
});

export default reducer;
