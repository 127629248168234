import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { TableState } from "react-table";
import slice, {
  IStructureElement,
} from "SRC/redux/slices/structureElement/reducer";

import { useAppSelector } from "../../../hooks";

export interface IFilters extends IStructureElement {
  clear(): void;
  setSelectedStatus(status: string): void;
  setSelectedStructureElementType(type: string): void;
  setTableState(state: Partial<TableState> | undefined): void;
  clearSelectedStatus(): void;
}

export const useFilters = (): IFilters => {
  const dispatch = useDispatch();
  const filters = useAppSelector((state) => state.structureElement);

  const clear = useCallback(() => dispatch(slice.actions.clear()), [dispatch]);

  const setSelectedStatus = useCallback(
    (status: string) => {
      return dispatch(slice.actions.selectStatus(status));
    },
    [dispatch]
  );

  const clearSelectedStatus = useCallback(() => {
    return dispatch(slice.actions.clearStatus());
  }, [dispatch]);

  const setSelectedStructureElementType = (type: string) =>
    dispatch(slice.actions.selectStructureElementType(type));

  const setTableState = (state: Partial<TableState> | undefined) =>
    dispatch(slice.actions.setTableInstance(state));

  return {
    ...filters,
    setSelectedStatus,
    setSelectedStructureElementType,
    setTableState,
    clear,
    clearSelectedStatus,
  };
};
