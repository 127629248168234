import { styled } from "@mui/material";

export const StructureElemetsWrapper = styled("div")(({ theme }) => {
  const {
    text: { primary, onAccent },
    success: { light },
    error: { light: errLight },
  } = theme.palette;

  return {
    gap: "0.5rem",
    display: "flex",
    flexDirection: "column",

    "&.is-secret": {
      alignItems: "center",
      justifyContent: "center",
    },

    "& .text-container": {
      fontSize: "1rem",

      "& p.gray-blue-text": {
        color: primary,
      },

      "& p.white-text": {
        color: onAccent,
      },

      "& p.big-text": {
        fontSize: "1.5rem",
      },
    },

    "& .progressbar-head": {
      marginBottom: "1rem",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },

    "& .legend": {
      gap: "0.4rem",
      display: "flex",
      alignItems: "baseline",

      "& p.legend-text": {
        fontSize: "0.8rem",
      },
    },

    dot: {
      width: "0.5rem",
      height: "0.5rem",
      borderRadius: "50%",

      "&.green": {
        background: light,
      },

      "&.red": {
        background: errLight,
      },
    },
  };
});
