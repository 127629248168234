type TRequestParam = {
  id: string;
  code: string;
  value?: any;
};

export const getFilterParams = (params: TRequestParam[]) => {
  return params
    .filter(({ value }) => value)
    .map(({ id, code, value }) => ({
      type: "EQ",
      version: 1,
      operands: [
        {
          type: "DIM",
          version: 2,
          id,
          attributeCode: code,
        },
        {
          type: "TEXT",
          version: 2,
          values: [value],
        },
      ],
    }));
};
