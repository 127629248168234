import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router";
import { AccessDeniedPage } from "SRC/components/AccessDeniedPage";
import { Footer } from "SRC/components/Footer";
import { Header } from "SRC/components/Header/Header";
import { PageContent } from "SRC/components/PageContent/PageContent";
import Snackbar from "SRC/components/Snackbar/Snackbar";
import { useAppInit } from "SRC/hooks/useAppInit";
import { Event } from "SRC/pages/Event/Event";
import { Indicator } from "SRC/pages/Indicator/Indicator";
import { Main } from "SRC/pages/Main/Main";
import { MinistriesBoard } from "SRC/pages/MinistriesBoard/MinistriesBoard";
import { Ministry } from "SRC/pages/Ministry/Ministry";
import { MinistryIndicator } from "SRC/pages/MinistryIndicator/MinistryIndicator";
import { Priorities } from "SRC/pages/Priorities/Priorities";
import { Program } from "SRC/pages/Program/Program";
import { ProgramsBoard } from "SRC/pages/ProgramsBoard/ProgramsBoard";
import { Structure } from "SRC/pages/Structure/Structure";
import StructureElement from "SRC/pages/StructureElement/StructureElement";
import { VicePremier } from "SRC/pages/VicePremier/VicePremier";
import {
  ACCESS_DENIED,
  MAIN,
  MINISTRIES,
  MINISTRY_EVENT_VIEW,
  MINISTRY_INDICATOR_VIEW,
  MINISTRY_VIEW,
  PRIORITIES,
  PROGRAM_EVENT_VIEW,
  PROGRAM_INDICATOR_VIEW,
  PROGRAM_STRUCTURE_ELEMENT_VIEW,
  PROGRAM_VIEW,
  PROGRAM_VIEW_TAB,
  PROGRAMS,
  STRUCTURE,
  SUMMARY,
  VICE_PREMIER,
} from "SRC/urls";

import { Content, RootContainer } from "./App.styled";
import { Summary } from "./Summary/Summary";

const App = () => {
  const { user, isLoading, isAccessGranted } = useAppInit();
  const [isReady, setIsReady] = useState(false);

  const isContentAvailable = isReady && isAccessGranted;

  useEffect(() => {
    if (!isLoading) {
      // На проде используется iframe, в самом SPA - hash route
      const urlRoute = window.top === window.self ? "/#" : "";

      if (!isAccessGranted) {
        window.parent.location = `${window.location.origin}${urlRoute}${ACCESS_DENIED}`;
      }

      // Нужно что бы не мигал интерфейс, !!urlRoute - для dev
      setIsReady(isAccessGranted || !!urlRoute);
    }
  }, [isLoading, isAccessGranted]);

  const selfHash = window.location.hash;
  const parentHash = window.parent.location.hash;
  let routeProps;
  if (parentHash && !selfHash) {
    routeProps = {
      location: parentHash.replace("#", ""),
    };
  } else {
    routeProps = {};
  }

  return (
    <RootContainer>
      <>
        {isContentAvailable && <Header />}
        <div className="content-scroll">
          <PageContent isLoading={!isReady} user={user}>
            <Content>
              <Routes {...routeProps}>
                <Route path={ACCESS_DENIED} element={<AccessDeniedPage />} />

                <Route path={MAIN} element={<Main />} />

                <Route path={PROGRAMS}>
                  <Route index element={<ProgramsBoard />} />
                  <Route path={PROGRAM_VIEW_TAB} element={<Program />} />
                  <Route path={PROGRAM_VIEW} element={<Program />} />
                  <Route path={PROGRAM_EVENT_VIEW} element={<Event />} />
                  <Route
                    path={PROGRAM_INDICATOR_VIEW}
                    element={<Indicator />}
                  />
                  <Route
                    path={PROGRAM_STRUCTURE_ELEMENT_VIEW}
                    element={<StructureElement />}
                  />
                </Route>

                <Route path={MINISTRIES}>
                  <Route index element={<MinistriesBoard />} />
                  <Route path={MINISTRY_VIEW} element={<Ministry />} />
                  <Route path={MINISTRY_EVENT_VIEW} element={<Event />} />
                  <Route
                    path={MINISTRY_INDICATOR_VIEW}
                    element={<MinistryIndicator />}
                  />
                </Route>

                <Route path={SUMMARY} element={<Summary />} />
                <Route path={PRIORITIES} element={<Priorities />} />
                <Route path={STRUCTURE} element={<Structure />} />

                <Route path={VICE_PREMIER} element={<VicePremier />} />
                <Route path="*" element={<Navigate to={MAIN} />} />
              </Routes>
            </Content>
          </PageContent>
          {isContentAvailable && <Footer />}
        </div>
        <Snackbar />
      </>
    </RootContainer>
  );
};

export default App;
