import { Box, Stack, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { OverlayBlock } from "SRC/components/OverlayBlock";
import { SlimProgressBar } from "SRC/components/ProgressBar";
import { WIPTag } from "SRC/components/WIP";
import { getRoundValue } from "SRC/helpers/getRoundValue";
import { IDataSideBar } from "SRC/pages/Indicator/interfaces";
import { useIndicatorData } from "SRC/redux/slices/indicator/hooks/useIndicatorData";
import { IIndicatorData } from "SRC/redux/slices/indicator/slices/data";

import { css } from "./Sidebar.styled";

const prepareData = (indicator: IIndicatorData | null): IDataSideBar => ({
  performance: getRoundValue(indicator?.data[5244]?.sum || 0, 2) || 0,
  corrections: {
    worse: 0,
    better: 0,
  },
});

export const Sidebar = () => {
  const { items: indicator, fetching } = useIndicatorData();

  const data = useMemo<IDataSideBar>(() => prepareData(indicator), [indicator]);

  return (
    <OverlayBlock isFetching={fetching} hasData={Boolean(data)}>
      <Box sx={css.container}>
        <Stack sx={css.content}>
          <Box sx={css.valuesBox}>
            <Box sx={css.achievement}>
              <Typography sx={css.title}>Уровень достижения,</Typography>
              <Typography sx={css.performanceText} className="percent">
                %
              </Typography>
            </Box>
            <Box sx={css.achievement}>
              <Typography sx={css.performanceText} className="big">
                {String(data.performance).split(".")[0]}
              </Typography>
              <Typography sx={css.performanceText} className="small">
                {String(data.performance).split(".")[1] &&
                  `,${String(data.performance).split(".")[1]}`}
              </Typography>
            </Box>
            <SlimProgressBar value={Number(data.performance) || 0} />
          </Box>
          <Box sx={css.valuesBox}>
            <Box sx={css.achievement}>
              <Typography sx={css.title}>Количество корректировок</Typography>
              <WIPTag />
            </Box>
            <Box sx={css.row}>
              <Typography sx={css.label}>ухудшилось</Typography>
              <Box sx={css.valueRow}>
                <Typography sx={css.value} className="red">
                  {data.corrections.worse}
                </Typography>
              </Box>
            </Box>
            <Box sx={css.row}>
              <Typography sx={css.label}>улучшилось</Typography>
              <Box sx={css.valueRow}>
                <Typography sx={css.value} className="green">
                  {data.corrections.better}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Stack>
      </Box>
    </OverlayBlock>
  );
};
