import { getIsSecret } from "SRC/pages/Summary/common/SummaryComplexGrid/utils";
import { ICustomTableProps } from "SRC/types";

export const getIndicatorsFact = (
  tableProps: ICustomTableProps,
  isOM: boolean
) => {
  const { indicatorsFact, indicatorsOMFact } = tableProps.row.original;

  return {
    isSecret: getIsSecret(tableProps),
    indicatorsFact: isOM ? indicatorsOMFact : indicatorsFact,
  };
};
