import { Button, useTheme } from "@mui/material";
import React, { useMemo } from "react";
import { SlimProgressBar } from "SRC/components/ProgressBar";
import { IOption } from "SRC/components/SelectWithSearch/SelectWithSearch";
import SelectWithSearchMinistry from "SRC/components/SelectWithSearch/SelectWithSearchMinistry";
import { SourceValue } from "SRC/components/SourceValue/SourceValue";
import { ESources, sourceSettings } from "SRC/constants";
import { Wrapper } from "SRC/pages/Ministry/common/Header/Header.styled";
import { createMinistriesData } from "SRC/pages/Ministry/common/utils/utils";
import { css } from "SRC/pages/Program/common/IndicatorsTab/IndicatorsTab.styled";
import {
  IGlobalFilters,
  useGlobalFilters,
} from "SRC/redux/slices/global/hooks/useFilters";
import {
  IMinistries,
  useMinistries,
} from "SRC/redux/slices/main/hooks/useMinistries";
import {
  IFilters,
  useFilters,
} from "SRC/redux/slices/ministry/hooks/useFilters";
import { useMinistry } from "SRC/redux/slices/ministry/hooks/useMinistry";

export const Header = () => {
  const theme = useTheme();

  const { ministry, filteredMinistries, setSelectedMinistry }: IFilters =
    useFilters();
  const { sources }: IGlobalFilters = useGlobalFilters();
  useMinistry();
  const { items: minItems }: IMinistries = useMinistries();

  const selectStyle = useMemo(() => css.selector(theme), [theme]);

  const ministries: IOption[] = useMemo(() => {
    return minItems
      .filter((ministry) => filteredMinistries.includes(ministry.info.code))
      .map((item) => ({
        value: item.info.code,
        label: item.info.code,
      }));
  }, [minItems, filteredMinistries]);

  const ministriesTableData = useMemo(
    () => createMinistriesData(minItems),
    [minItems]
  );

  const ministryData = useMemo(
    () => ministriesTableData.filter((elem) => ministry === elem.name),
    [ministriesTableData, ministry]
  );

  const values = useMemo(() => {
    return [
      sources[ESources.OM]
        ? {
            value: ministryData[0]?.performanceOM || 0, // Уровень достижения, особое мнение
            settings: sourceSettings[ESources.OM],
          }
        : {
            value: ministryData[0]?.performance || 0, // Уровень достижения
            settings: sourceSettings[ESources.FOIV],
          },
    ];
  }, [ministryData, sources]);
  return (
    <Wrapper>
      <div className="title">
        Министерства
        <SelectWithSearchMinistry
          value={ministry}
          label="Министерство"
          staticLabel={false}
          style={selectStyle}
          onChange={setSelectedMinistry}
          options={ministries}
        />
      </div>
      <div className="info">
        <div className="head">
          {/*icon*/}
          <div className="title-text">
            <div>{ministry}</div>
            <span>Министерство</span>
          </div>
          <div className="title-achievement">
            <div className="title-percent">
              <div> Уровень достижения, %</div>
              {values.map((value, index) => (
                <SourceValue
                  key={index}
                  {...value}
                  isPercent={true}
                  fontSize="2rem"
                />
              ))}
            </div>
            <SlimProgressBar value={50} />
          </div>
        </div>
        <div className="buttons-container">
          <Button>Ответственный за ГП</Button>
          <Button variant="contained">Участвует в реализации ГП</Button>
        </div>
      </div>
    </Wrapper>
  );
};
