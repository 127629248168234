import { AxiosResponse } from "axios";
import { v4 } from "uuid";

import {
  IOlapRequest,
  IOlapRequestData2,
  IOlapResponse,
  IOlapResponseData2,
  IOlapResponseDataData2,
  REQUEST_TYPE,
} from "../scheme/olap";
import client from "./client";

interface IOlapRequestData3 {
  requestId: string;
  type: string;
  params: IOlapRequestData2["params"];
}

// TODO исправить any

interface IOlapResponseDataData3 {
  data: Record<string, any>;
  requestId?: string;
  type?: string;
}

interface IOlapResponseDataData4 {
  data: {
    [key: string]: IOlapResponseDataData3;
  };
}

interface IOlapResponseDataData5 {
  data: {
    [key: string]: IOlapResponseDataData4;
  };
}

export interface IOlapResponseData<T> {
  responses: {
    [index: string]: {
      requestId: string;
      type: REQUEST_TYPE.DATA2;
      data: {
        [key: string]: T;
      };
    };
  };
}

export const prepareResponseData2 = (
  response: AxiosResponse<IOlapResponseData2>
): IOlapResponseDataData2 => ({
  data: response.data.responses[0].data.data,
  dataTotal: response.data.responses[0].data.dataTotal,
});

export const prepareResponseData3 = (
  response: AxiosResponse<IOlapResponseData2>
): IOlapResponseDataData3 => ({
  data: response.data.responses,
});

export const prepareResponseData4 = (
  response: AxiosResponse<IOlapResponseData<IOlapResponseDataData3>>
): IOlapResponseDataData4 => ({
  data: response.data.responses[0].data[0].data,
});

export const prepareResponseData5 = (
  response: AxiosResponse<IOlapResponseData<IOlapResponseDataData5>>
): IOlapResponseDataData5 => ({
  data: response.data.responses[0].data,
});

export const olapRequest = (
  { type, requestId, params }: IOlapRequest,
  query: string = ""
) => {
  const request = {
    requestId: requestId || v4(),
    type,
    params,
  };

  return client.post<IOlapResponse>(`api/data_api/v1.0/olapdata?${query}`, {
    requests: [request],
  });
};

export const olapRequest2 = (
  requests: IOlapRequestData3[],
  query: string = ""
) => {
  return client.post<IOlapResponse>(`api/data_np_api/v1.0/data?${query}`, {
    requests: requests,
  });
};

export const olapRequest3 = (
  requests: IOlapRequestData3[],
  query: string = ""
) => {
  return client.post<IOlapResponseData<IOlapResponseDataData3>>(
    `api/data_np_api/v1.0/data?${query}`,
    {
      requests,
    }
  );
};

export const olapRequestData2 = (
  params: IOlapRequestData2["params"],
  query?: string
) =>
  olapRequest({ type: REQUEST_TYPE.DATA2, params }, query).then((response) =>
    prepareResponseData2(response as AxiosResponse<IOlapResponseData2>)
  );

export const olapRequestData3 = (
  requests: IOlapRequestData3[],
  query?: string
) =>
  olapRequest2(requests, query).then((response) =>
    prepareResponseData3(response as AxiosResponse<IOlapResponseData2>)
  );

export const olapRequestData4 = (
  requests: IOlapRequestData3[],
  query?: string
) =>
  olapRequest3(requests, query).then((response) =>
    prepareResponseData4(
      response as AxiosResponse<IOlapResponseData<IOlapResponseDataData3>>
    )
  );

export const olapRequestData5 = (
  requests: IOlapRequestData3[],
  query?: string
) =>
  olapRequest3(requests, query).then((response) =>
    prepareResponseData5(
      response as AxiosResponse<IOlapResponseData<IOlapResponseDataData5>>
    )
  );

export const olapDimensionRequest = (
  requests: IOlapRequestData3[],
  query: string = ""
) => {
  return client.post<IOlapResponseData<IOlapResponseDataData3>>(
    `api/data_api/v1.0/olapdata?${query}`,
    {
      requests,
    }
  );
};

export const olapDimensionRequestData = (
  requests: IOlapRequestData3[],
  query?: string
) =>
  olapDimensionRequest(requests, query).then((response) => {
    return prepareResponseData5(
      response as AxiosResponse<IOlapResponseData<IOlapResponseDataData3>>
    );
  });
