import classNames from "classnames";
import React from "react";
import { TooltipText } from "SRC/components/TooltipText";
import { StackWrapper } from "SRC/pages/Indicator/common/Description/Description.styled";

interface IDescriptionItem {
  label: string | number;
  value?: number | string;
  className?: string;
  flex?: boolean;
}

export const DescriptionItem = ({
  label,
  value,
  flex,
  className = "",
}: IDescriptionItem) => {
  return (
    <StackWrapper
      key={label}
      className={classNames({ fullSize: flex, [className]: className })}
    >
      <div className="text">{label}</div>
      <TooltipText hint={String(value)} variant="h3">
        {value}
      </TooltipText>
    </StackWrapper>
  );
};
