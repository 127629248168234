import { Theme } from "@mui/material";

export const css = {
  container: {
    height: "100%",
    width: "100%",
    flex: 1,
    display: "flex",
    justifyContent: "center",
    position: "relative",
  },
  labelContainer: (bottomOffset: string, leftOffset: string) => ({
    position: "absolute",
    left: leftOffset,
    bottom: bottomOffset,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    zIndex: 1,
  }),
  planText: {
    fontSize: "1rem",
    lineHeight: "1rem",
    fontWeight: "bold",
  },
  divider: {
    width: "100%",
    height: "1px",
    background: (theme: Theme) => theme.palette.primary.contrastText,
    margin: "0.5rem 0",
  },
  factText: (factData: number, planData: number) => ({
    fontSize: "1.2rem",
    lineHeight: "1rem",
    color: (theme: Theme) =>
      factData === null
        ? theme.palette.text.secondary
        : factData > 0 && factData >= planData
        ? theme.palette.success.main
        : theme.palette.error.main,
    fontWeight: "bold",
  }),
};
