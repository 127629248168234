const PERIOD_REGEXP = /^(\d{4})(?:(\d{2})|(\d))?$/;
const MONTHS_PER_QUARTER = 3;
const MAX_MONTH = 12;

export const periodYearMonth = (period: string, len = 2, pad = "0") => {
  let [, year, month, quarter] = period.match(PERIOD_REGEXP) || [];

  if (year) {
    month = quarter
      ? `${Number(quarter) * MONTHS_PER_QUARTER}`
      : `${Number(month) || MAX_MONTH}`;

    month = month.padStart(len, pad);
  }

  return { year, month };
};
