import AddIcon from "@mui/icons-material/Add";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import { Close } from "ASSETS/svg/close";

import { Wrapper } from "./IncidentFileList.styled";

interface IIncidentFileListProps {
  handleDeleteFile: (id: number) => void;
  fileNames: any;
  onChangeFiles: (e: any) => void;
  uploadInputRef: React.MutableRefObject<HTMLInputElement | null>;
}

export const IncidentFileList = ({
  handleDeleteFile,
  fileNames,
  onChangeFiles,
  uploadInputRef,
}: IIncidentFileListProps) => {
  const handleOnClick = () => {
    uploadInputRef.current && uploadInputRef.current.click();
  };

  return (
    <Wrapper>
      <ul className="files-list">
        {fileNames.map(({ label, id }: any) => (
          <li key={id}>
            <div>
              <TextSnippetIcon /> {label}
            </div>
            <div className="file-close-icon">
              <Close onClick={() => handleDeleteFile(id)} />
            </div>
          </li>
        ))}
      </ul>
      <input
        ref={uploadInputRef}
        type="file"
        accept="image/*"
        className="files-list-input"
        onChange={onChangeFiles}
      />

      <span onClick={handleOnClick} className="files-list-name">
        <AddIcon /> Добавить файл
      </span>
    </Wrapper>
  );
};
