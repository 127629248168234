import { SerializedError } from "@reduxjs/toolkit";
import { useCallback, useState } from "react";
import { IEventParams } from "SRC/types";

import { useAppDispatch, useAppSelector } from "../../../hooks";
import actions from "../actions";
import { IGoalConnect } from "../slices/goalConnect";

export interface IGoalConnects {
  fetching: boolean;
  fetched: boolean;
  items: IGoalConnect;
  load(params: IEventParams): void;
  error: SerializedError | null;
}

export const useGoalConnect = (): IGoalConnects => {
  const dispatch = useAppDispatch();
  const {
    fetching,
    items: goalConnects,
    fetched,
  } = useAppSelector((state) => state.gosprogram.goalConnects);
  const [error, setError] = useState<SerializedError | null>(null);

  const load = useCallback(
    (params: IEventParams) => {
      if (!fetching) {
        dispatch(actions.goalConnect.get(params.id)).then((action) => {
          if (actions.goalConnect.get.rejected.match(action)) {
            setError(action.error);
          }
          return action;
        });
      }
    },
    [dispatch, fetching]
  );

  return {
    fetching,
    fetched,
    items: goalConnects,
    load,
    error,
  };
};
