// Министерства
import { olapRequestData3 } from "CORE/api/core";
import { MINISTRY_MODEL_UUID } from "CORE/scheme/olap";
import { getMinistriesIndicators } from "SRC/constants";
import { IPeriod } from "SRC/redux/slices/global/slices/filters";

const GET_MINISTRIES = "get-ministries-3";

export const getMinistries = (period: IPeriod) => {
  return olapRequestData3(
    [
      {
        requestId: "ff3b74d2-b429-4b81-8580-6d43df336b38",
        type: "data2",
        params: {
          modelUuid: MINISTRY_MODEL_UUID,
          dashboardUuid: "",
          includeGaps: false,
          dimensions: [
            {
              id: "1770",
              includeItems: true,
              includeAttributesByCodes: [
                "NAME",
                "SHORT_NAME",
                "SORT_ORDER",
                "GERB_ICON",
              ],
            },
            {
              id: "1774",
              includeItems: true,
              includeAttributesByCodes: [
                "NAME",
                "SHORT_NAME",
                "SORT_ORDER",
                "DatePart",
                "PeriodStart",
                "PeriodEnd",
                "PERIOD2",
              ],
            },
          ],
          indicators: {
            id: "1781",
            items: getMinistriesIndicators(
              1782,
              1785,
              1787,
              1788,
              5268,
              5401,
              5406,
              5407,
              5408,
              5409,
              5410,
              5411,
              5428,
              5429,
              5430,
              5431,
              5432,
              5433,
              5434,
              5504,
              5505,
              5506,
              5507,
              5508,
              5509,
              5510,
              5511,
              5512,
              5513,
              5514,
              5515,
              5525,
              5526,
              5527
            ),
          },
          dataRequestCaching: true,
          dataSort: [
            {
              dim_id: "1770",
              sortField: "name",
              sortOrder: "asc",
            },
            {
              dim_id: "1774",
              sortField: "name",
              sortOrder: "asc",
            },
          ],
          dataFilter: {
            type: "AND",
            version: 1,
            operands: [
              {
                type: "STARTER",
                version: 1,
                dim_id: "1770",
              },
              {
                type: "EQ",
                version: 1,
                operands: [
                  {
                    type: "DIM",
                    version: 1,
                    id: "1774",
                  },
                  {
                    type: "CONST",
                    version: 1,
                    values: [period.apiV2],
                  },
                ],
              },
            ],
          },
        },
      },
    ],
    GET_MINISTRIES
  );
};
