import { ArrowBackOutlined } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useCallback } from "react";
import { useNavigate } from "react-router";

import { Wrapper } from "./NavigateBackButton.styled";

type INavigateBackButtonProps = {
  className?: string;
  url?: string;
};

export const NavigateBackButton = ({ url }: INavigateBackButtonProps) => {
  const navigate = useNavigate();

  const handleOnClick = useCallback(() => {
    url ? navigate(url) : navigate(-1);
  }, []);

  return (
    <Wrapper>
      <IconButton onClick={handleOnClick} className="button">
        <ArrowBackOutlined />
      </IconButton>
    </Wrapper>
  );
};
