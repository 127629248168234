import Box from "@mui/material/Box";
import React, { useMemo } from "react";
import { IncidentReport } from "SRC/components/IncidentReport";
import { OverlayBlock } from "SRC/components/OverlayBlock";
import { OverlayStackedProgress } from "SRC/components/ProgressBar";
import { convertSourceValue, ESources } from "SRC/constants";
import { roundNumbersToFixed } from "SRC/helpers/roundNumbersToFixed";
import {
  IGlobalFilters,
  useGlobalFilters,
} from "SRC/redux/slices/global/hooks/useFilters";
import { useStatistics } from "SRC/redux/slices/gosprogram/hooks/useStatistics";

import { LineProgressEvents } from "../LineProgressEvents/LineProgressEvents";
import { css, Wrapper } from "./EventsInfo.styled";

type TPercentValue = Record<ESources, number>;

interface IEventsData {
  events: {
    name: string;
    total: number;
    done: TPercentValue;
    notDone: TPercentValue;
    units: string;
    percentage: TPercentValue;
  };
  objectsCreated: {
    name: string;
    total: number;
    done: number;
    atRisk?: number;
    units: string;
    percentage: number;
  };
  npa: {
    name: string;
    total: number;
    done: number;
    atRisk: number;
    units: string;
    percentage: number;
  };
}

export const EventsInfo = () => {
  const { sources }: IGlobalFilters = useGlobalFilters();
  const { items: statistics, fetching } = useStatistics();
  const { events, objectsCreated, npa }: IEventsData = useMemo(
    () => ({
      events: {
        name: "Мероприятия",
        total: statistics["Мероприятий всего"] || 0,
        done: {
          [ESources.FOIV]: statistics["Мероприятий выполнено"] || 0,
          [ESources.OM]:
            Number(statistics.results_overall_om_complete_cnt) || 0,
        },
        notDone: {
          [ESources.FOIV]: statistics["Мероприятий под риском"] || 0,
          [ESources.OM]: statistics["Мероприятий под риском"] || 0,
        },
        units: "ед.",
        percentage: {
          [ESources.FOIV]: statistics["Мероприятий выполнено, %"],
          [ESources.OM]: statistics.res_complete_om_perc,
        },
      },
      objectsCreated: {
        name: "Создано объектов",
        total: statistics["Создано объектов, план"] || 0,
        done: statistics["Создано объектов, факт"] || 0,
        units: "ед.",
        percentage:
          roundNumbersToFixed(statistics["Создано объектов, %"], 0) || 0,
      },
      npa: {
        name: "НПА",
        total: statistics["Нормативно-правовые акты, план"] || 0,
        done: statistics["Нормативно-правовые акты, факт"] || 0,
        atRisk: statistics["Нормативно правовые акты, под риском"] || 0,
        units: "ед.",
        percentage:
          roundNumbersToFixed(statistics["Нормативно правовые акты, %"], 0) ||
          0,
      },
    }),
    [statistics]
  );

  const { success, fail } = useMemo(() => {
    return {
      success: convertSourceValue(events.done, sources),
      fail: convertSourceValue(events.notDone, sources),
    };
  }, [events, sources]);

  return (
    <Wrapper>
      <IncidentReport className="incident-container">
        <OverlayBlock isFetching={fetching} hasData={Boolean(statistics)}>
          <>
            <Box sx={css.progress}>
              <Box sx={css.nameContainer}>
                <Box sx={css.name}>{events.name}</Box>
                <Box sx={css.unit}>{events.units}</Box>
              </Box>
              <OverlayStackedProgress
                successValues={success}
                failValues={fail}
                failIcon={true}
                total={events.total}
                isFit={true}
                showFailPercents={true}
              />
            </Box>
            <LineProgressEvents
              nameValue={objectsCreated.name}
              unit={objectsCreated.units}
              percentage={objectsCreated.percentage}
              value={objectsCreated.done}
              total={objectsCreated.total}
            />
            <LineProgressEvents
              nameValue={npa.name}
              unit={npa.units}
              percentage={npa.percentage}
              value={npa.done}
              total={npa.total}
            />
          </>
        </OverlayBlock>
      </IncidentReport>
    </Wrapper>
  );
};
