import { getDataSumByCodeMinSummary } from "SRC/constants";
import { IMinistriesTableItem } from "SRC/hooks/summaryTable/interfaces";
import { getGovProgramsValue } from "SRC/hooks/summaryTable/utils/summaryData/summaryDataGP";
import { generalRowsSummaryTableData } from "SRC/hooks/summaryTable/utils/utils";
import { TMinistry } from "SRC/redux/slices/summary/hooks/useMinistries";
import { CustomRowProps } from "SRC/types/data/data";

export const createMinistriesTableData = (
  data: TMinistry[]
): IMinistriesTableItem[] =>
  data.map((item: TMinistry, index: number) => {
    const { info } = item;
    const elem = { ...data, data: Object.values(item.data) };
    return {
      id: index + 1,
      isMinistry: true,
      name: info?.code,
      iconUrl: info?.icon_url,
      govPrograms: getDataSumByCodeMinSummary(elem, 1782) || 0,
      govProgramsNoSecret: getDataSumByCodeMinSummary(elem, 5401) || 0,
      cashPlan: getDataSumByCodeMinSummary(elem, 5402) || 0,
      cashFact: getDataSumByCodeMinSummary(elem, 5403) || 0,
      cashPlanTotal: getDataSumByCodeMinSummary(elem, 5421) || 0,
      cashFactTotal: getDataSumByCodeMinSummary(elem, 5422) || 0,
      checkpointsPlan: getDataSumByCodeMinSummary(elem, 5414) || 0,
      checkpointsPlanTotal: getDataSumByCodeMinSummary(elem, 5440) || 0,
      checkpointsOMPlan: getDataSumByCodeMinSummary(elem, 5417) || 0,
      checkpointsOMPlanTotal: getDataSumByCodeMinSummary(elem, 5443) || 0,
      checkpointsFact: getDataSumByCodeMinSummary(elem, 5415) || 0,
      checkpointsFactTotal: getDataSumByCodeMinSummary(elem, 5441) || 0,
      checkpointsOMFact: getDataSumByCodeMinSummary(elem, 5418) || 0,
      checkpointsOMFactTotal: getDataSumByCodeMinSummary(elem, 5444) || 0,
      checkpointsFail: getDataSumByCodeMinSummary(elem, 5416) || 0,
      checkpointsFailTotal: getDataSumByCodeMinSummary(elem, 5442) || 0,
      checkpointsOMFail: getDataSumByCodeMinSummary(elem, 5419) || 0,
      checkpointsOMFailTotal: getDataSumByCodeMinSummary(elem, 5445) || 0,
      // Мероприятия факт
      eventsFact: getDataSumByCodeMinSummary(elem, 1786) || 0,
      eventsOMFact: getDataSumByCodeMinSummary(elem, 5389) || 0,
      eventsFactTotal: getDataSumByCodeMinSummary(elem, 5435) || 0,
      eventsOMFactTotal: getDataSumByCodeMinSummary(elem, 5438) || 0,
      // Мероприятия план
      eventsPlan: getDataSumByCodeMinSummary(elem, 1785) || 0,
      eventsPlanTotal: getDataSumByCodeMinSummary(elem, 5434) || 0,
      eventsOMPlan: getDataSumByCodeMinSummary(elem, 5390) || 0,
      eventsOMPlanTotal: getDataSumByCodeMinSummary(elem, 5434) || 0,
      eventsFoivOrOMPlanTotal: getDataSumByCodeMinSummary(elem, 5434) || 0,
      // Мероприятия не выполнено
      eventsFail: getDataSumByCodeMinSummary(elem, 5412) || 0,
      eventsFailTotal: getDataSumByCodeMinSummary(elem, 5436) || 0,
      eventsOMFail: getDataSumByCodeMinSummary(elem, 5413) || 0,
      eventsOMFailTotal: getDataSumByCodeMinSummary(elem, 5439) || 0,
      eventsAtRiskTotal: getDataSumByCodeMinSummary(elem, 5437) || 0,
      // Показатели выполнено/факт
      indicatorsFact: getDataSumByCodeMinSummary(elem, 1784) || 0,
      indicatorsFactTotal: getDataSumByCodeMinSummary(elem, 5598) || 0,
      indicatorsOMFact: getDataSumByCodeMinSummary(elem, 5387) || 0,
      indicatorsOMFactTotal: getDataSumByCodeMinSummary(elem, 5426) || 0,
      // Показатели план
      indicatorsPlan: getDataSumByCodeMinSummary(elem, 1783) || 0,
      indicatorsPlanTotal: getDataSumByCodeMinSummary(elem, 5423) || 0,
      indicatorsOMPlan: getDataSumByCodeMinSummary(elem, 5388) || 0,
      indicatorsOMPlanTotal: getDataSumByCodeMinSummary(elem, 5425) || 0,
      // Показатели не выполнено
      indicatorsFail: getDataSumByCodeMinSummary(elem, 5404) || 0,
      indicatorsFailTotal: getDataSumByCodeMinSummary(elem, 5424) || 0,
      indicatorsOMFail: getDataSumByCodeMinSummary(elem, 5405) || 0,
      indicatorsOMFailTotal: getDataSumByCodeMinSummary(elem, 5427) || 0,
      // Показатели СЭ Факт
      indicatorsSEFact: getDataSumByCodeMinSummary(elem, 5512) || 0,
      indicatorsSEFactTotal: getDataSumByCodeMinSummary(elem, 5513) || 0,
      indicatorsSEOMFact: getDataSumByCodeMinSummary(elem, 5506) || 0,
      indicatorsSEOMFactTotal: getDataSumByCodeMinSummary(elem, 5507) || 0,
      // Показатели СЭ план
      indicatorsSEPlan: getDataSumByCodeMinSummary(elem, 5510) || 0,
      indicatorsSEPlanTotal: getDataSumByCodeMinSummary(elem, 5511) || 0,
      indicatorsSEOMPlan: getDataSumByCodeMinSummary(elem, 5504) || 0,
      indicatorsSEOMPlanTotal: getDataSumByCodeMinSummary(elem, 5505) || 0,
      // Показатели СЭ не выполнено
      indicatorsSEFail: getDataSumByCodeMinSummary(elem, 5514) || 0,
      indicatorsSEFailTotal: getDataSumByCodeMinSummary(elem, 5515) || 0,
      indicatorsSEOMFail: getDataSumByCodeMinSummary(elem, 5508) || 0,
      indicatorsSEOMFailTotal: getDataSumByCodeMinSummary(elem, 5509) || 0,
      // Уровень достижения
      performance: getDataSumByCodeMinSummary(elem, 1845) || 0,
      performanceTotal: getDataSumByCodeMinSummary(elem, 5275) || 0,
      // Уровень достижения особое мнение
      performanceOM: getDataSumByCodeMinSummary(elem, 5268) || 0,
      // Уровень достижения особое мнение итого
      performanceOMTotal: getDataSumByCodeMinSummary(elem, 5273) || 0,
      // Структурные элементы план
      structureElementsPlan: getDataSumByCodeMinSummary(elem, 5406) || 0,
      structureElementsPlanTotal: getDataSumByCodeMinSummary(elem, 5428) || 0,
      structureElementsOMPlan: getDataSumByCodeMinSummary(elem, 5409) || 0,
      structureElementsOMPlanTotal: getDataSumByCodeMinSummary(elem, 5431) || 0,
      // Структурные элементы факт
      structureElementsFact: getDataSumByCodeMinSummary(elem, 5407) || 0,
      structureElementsFactTotal: getDataSumByCodeMinSummary(elem, 5429) || 0,
      structureElementsOMFact: getDataSumByCodeMinSummary(elem, 5410) || 0,
      structureElementsOMFactTotal: getDataSumByCodeMinSummary(elem, 5432) || 0,
      // Структурные элементы не выполнено
      structureElementsFail: getDataSumByCodeMinSummary(elem, 5408) || 0,
      structureElementsFailTotal: getDataSumByCodeMinSummary(elem, 5430) || 0,
      structureElementsOMFail: getDataSumByCodeMinSummary(elem, 5411) || 0,
      structureElementsOMFailTotal: getDataSumByCodeMinSummary(elem, 5433) || 0,
    };
  });

export const createMinistryRowsTableData = (
  data: CustomRowProps[],
  isOM: boolean,
  withSecret?: boolean
) =>
  data.map((elem: CustomRowProps, index) => ({
    id: elem.original,
    name: elem.depth === 0 ? elem.original.name : elem.original.govProgram,
    govPrograms: withSecret
      ? elem.original.govPrograms
      : getGovProgramsValue(elem),
    ...generalRowsSummaryTableData(elem, isOM),
  }));
