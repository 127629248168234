import React, { useMemo, useState } from "react";
import { useParams } from "react-router";
import Breadcrumbs from "SRC/components/Breadcrumbs/Breadcrumbs";
import { OpenerButton, TableExportButton } from "SRC/components/Buttons";
import { ExtendedTabs, ITab } from "SRC/components/ExtendedTabs/ExtendedTabs";
import Controller, {
  TExportsData,
  useGetData,
  useGetTasks,
} from "SRC/redux/stores/StructureElement";
import { PROGRAM_VIEW } from "SRC/urls";

import Description from "./common/Description";
import { EventsTab } from "./common/EventsTab/EventsTab";
import { IndicatorsTab } from "./common/IndicatorsTab/IndicatorsTab";
import { TasksTab } from "./common/TasksTab/TasksTab";
import { css, StackWrapper } from "./StructureElement.styled";

const fakeExportData = {
  csvData: [],
  fileName: "string;",
  wscols: [],
  headings: [],
};

const initBreadcrumbs = [
  {
    title: "Госпрограмма", // program?.gp_name || "",
    link: PROGRAM_VIEW.replace(":id", "01"), // String(programId)),
  },
  {
    title: "Структурный элемент", // data?.info.name || "Структурный элемент",
  },
];

enum EGridTabs {
  TASKS,
  INDICATORS,
  EVENTS,
}

const StructureElement = () => {
  const { fetching, data } = useGetData();
  const { exports } = useGetTasks();

  const [activeTab, setActiveTab] = useState<number>(0);
  const handleTabChange = (value: number) => setActiveTab(value);
  const [modalOpen, setModalOpen] = useState(false);
  const onOpenModal = (): void => setModalOpen(true);

  const tabs: ITab<number>[] = [
    {
      value: 0,
      label: "ОЗР и задачи",
      component: (
        <TasksTab isModalOpen={modalOpen} setModalOpen={setModalOpen} />
      ),
    },
    {
      value: 1,
      label: "Показатели",
      component: (
        <IndicatorsTab isModalOpen={modalOpen} setModalOpen={setModalOpen} />
      ),
    },
    {
      value: 2,
      label: "Мероприятия",
      component: (
        <EventsTab isModalOpen={modalOpen} setModalOpen={setModalOpen} />
      ),
    },
  ];

  const links = useMemo(
    () =>
      fetching
        ? initBreadcrumbs
        : [
            {
              title: "Госпрограмма", // data?.info.gpName || "Госпрограмма",
              link: PROGRAM_VIEW.replace(":id", "01"), // String(data?.info.gpId)),
            },
            {
              title: "Структурный элемент", // data?.info.name || "Структурный элемент",
            },
          ],
    [fetching, data] // [programId, data?.info.name]
  );

  const tabExportData: TExportsData = useMemo(() => {
    const exportData: Record<EGridTabs, TExportsData | typeof fakeExportData> =
      {
        [EGridTabs.TASKS]: exports,
        [EGridTabs.INDICATORS]: fakeExportData,
        [EGridTabs.EVENTS]: fakeExportData,
      };
    return exports; // exportData[activeTab];
  }, [activeTab, exports]);

  return (
    <StackWrapper>
      <Breadcrumbs links={links} />
      <div className="title">
        {`${data?.info.type || "Тип"}.${
          data?.info.name || "Название структурного элемента"
        }`}
      </div>
      <Description />
      <div className="tables">
        <ExtendedTabs
          value={activeTab}
          onChange={handleTabChange}
          tabs={tabs}
          style={css.tabs}
          borderStyle="solid"
          actions={[
            <TableExportButton {...tabExportData} key="1" />,
            <OpenerButton open={modalOpen} setOpen={onOpenModal} key="2" />,
          ]}
        />
      </div>
    </StackWrapper>
  );
};

export default function () {
  const { id, programId } = useParams();

  if (!id || !programId) return null;

  return (
    <Controller id={id} pId={programId}>
      <StructureElement />
    </Controller>
  );
}
