import {
  Box,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Equalizer, Programms, Tiles } from "ASSETS/svg";
import React from "react";
import { MainHeaderFilter } from "SRC/components/FilterForms";
import { useFilters } from "SRC/redux/slices/main/hooks/useFilters";
import themeConfig from "SRC/theme";

import { css } from "./GraphSwitcher.styled";

interface IGraphSwitcherProps {
  withRightFilters?: boolean;
}

const GraphSwitcher = ({ withRightFilters }: IGraphSwitcherProps) => {
  const theme = useTheme<typeof themeConfig>();
  const match = useMediaQuery(theme.breakpoints.up("lg"));
  const { toggleChart, viewChart } = useFilters();

  return (
    <Box sx={css.container}>
      <Box sx={css.leftContainer}>
        {!match && (
          <Typography variant={"h1"} component={"h1"}>
            Все госпрограммы
          </Typography>
        )}

        <Box sx={css.switch}>
          <IconButton
            sx={css.button(viewChart === 1)}
            onClick={() => toggleChart(1)}
          >
            <Tiles />
          </IconButton>
          <IconButton
            sx={css.button(viewChart === 2)}
            onClick={() => toggleChart(2)}
          >
            <Equalizer />
          </IconButton>
          {!match && (
            <IconButton
              sx={css.button(viewChart === 3)}
              onClick={() => toggleChart(3)}
            >
              <Programms />
            </IconButton>
          )}
        </Box>
      </Box>
      <MainHeaderFilter />
    </Box>
  );
};

export default GraphSwitcher;
