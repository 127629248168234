import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "SRC/redux/hooks";

import slice, { IFiltersState } from "../slices/filters";

export interface IFilters extends IFiltersState {
  clear(): void;
  setSelectedStructureElement(element: string): void;
  setSelectedGosprogram(gp: string): void;
}

export const useFilters = (): IFilters => {
  const dispatch = useDispatch();
  const filters = useAppSelector((state) => state.structure.filters);

  const setSelectedGosprogram = (gp: string) => {
    dispatch(slice.actions.setGosprogram(gp));
  };

  const setSelectedStructureElement = (element: string) => {
    dispatch(slice.actions.setStructureElement(element));
  };

  const clear = useCallback(() => dispatch(slice.actions.clear()), [dispatch]);

  return {
    ...filters,
    setSelectedStructureElement,
    setSelectedGosprogram,
    clear,
  };
};
