import { ICustomTableProps } from "SRC/types";

export const getStructureElementsFactTotal = (
  tableProps: ICustomTableProps,
  isOM: boolean
) => {
  let { structureElementsFactTotal, structureElementsOMFactTotal } =
    tableProps?.rows?.[0]?.original || {};

  return {
    structureElementsFactTotal: isOM
      ? structureElementsOMFactTotal
      : structureElementsFactTotal,
  };
};
