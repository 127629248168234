import { combineReducers } from "@reduxjs/toolkit";
import ministriesSlice, {
  IMinistriesState,
} from "SRC/redux/slices/summary/slices/ministries";
import {
  IPremiersState,
  slice as premiersSlice,
} from "SRC/redux/slices/summary/slices/premiers";
import {
  IProgramsState,
  slice as programsSlice,
} from "SRC/redux/slices/summary/slices/programs";
import topBestGpSlice, {
  IProgramsTopBestState,
} from "SRC/redux/slices/summary/slices/topBestGp";
import topBestGp2Slice, {
  IProgramsTopBest2State,
} from "SRC/redux/slices/summary/slices/topBestGp2";
import topBestGpOMSlice, {
  IProgramsTopBestOMState,
} from "SRC/redux/slices/summary/slices/topBestGpSpecialOp";
import topBestMinistriesSlice, {
  IMinistriesTopBestState,
} from "SRC/redux/slices/summary/slices/topBestMinistries";
import topBestMinistriesOMSlice, {
  IMinistriesTopBestOMState,
} from "SRC/redux/slices/summary/slices/topBestMinistriesOM";
import topBestVpSlice, {
  IVpTopBestState,
} from "SRC/redux/slices/summary/slices/topBestVp";
import topBestVp2Slice, {
  IVpTopBest2State,
} from "SRC/redux/slices/summary/slices/topBestVp2";
import topBestVpOpinionSlice, {
  ITopBestVpOpinion,
} from "SRC/redux/slices/summary/slices/topBestVpOpinion";
import topWorstGpSlice, {
  IProgramsTopWorstState,
} from "SRC/redux/slices/summary/slices/topWorstGp";
import topWorstGp2Slice, {
  IProgramsTopWorst2State,
} from "SRC/redux/slices/summary/slices/topWorstGp2";
import topWorstGpOMSlice, {
  IProgramsTopWorstOMState,
} from "SRC/redux/slices/summary/slices/topWorstGpSpecialOp";
import topWorstMinistriesSlice, {
  IMinistriesTopWorstState,
} from "SRC/redux/slices/summary/slices/topWorstMinistries";
import topWorstMinistries2Slice, {
  IMinistriesTopWorst2State,
} from "SRC/redux/slices/summary/slices/topWorstMinistries2";
import topWorstMinistriesOMSlice, {
  IMinistriesTopWorstOMState,
} from "SRC/redux/slices/summary/slices/topWorstMinistriesOM";
import topWorstVpSlice, {
  IVpTopWorstState,
} from "SRC/redux/slices/summary/slices/topWorstVp";
import topWorstVp2Slice, {
  IVpTopWorst2State,
} from "SRC/redux/slices/summary/slices/topWorstVp2";
import topWorstVpOpinionSlice, {
  ITopWorstVpOpinion,
} from "SRC/redux/slices/summary/slices/topWorstVpOpinion";

import filtersSlice, { IFiltersState } from "./slices/filters";
import topBestMinistries2Slice, {
  IMinistriesTopBest2State,
} from "./slices/topBestMinistries2";

export interface ISummaryReducer {
  filters: IFiltersState;
  programs: IProgramsState;
  premiers: IPremiersState;
  ministries: IMinistriesState;
  topBestGP: IProgramsTopBestState;
  topWorstGP: IProgramsTopWorstState;
  topBestVP: IVpTopBestState;
  topBestVpOpinion: ITopBestVpOpinion;
  topWorstVpOpinion: ITopWorstVpOpinion;
  topWorstVP: IVpTopWorstState;
  topBestVp2: IVpTopBest2State;
  topWorstVP2: IVpTopWorst2State;
  topWorstGp2: IProgramsTopWorst2State;
  topBestGp2: IProgramsTopBest2State;
  topBestMinistries: IMinistriesTopBestState;
  topBestMinistriesOM: IMinistriesTopBestOMState;
  topWorstMinistries: IMinistriesTopWorstState;
  topWorstMinistriesOM: IMinistriesTopWorstOMState;
  topBestMinistries2: IMinistriesTopBest2State;
  topWorstMinistries2: IMinistriesTopWorst2State;
  topBestGpOM: IProgramsTopBestOMState;
  topWorstGpOM: IProgramsTopWorstOMState;
}

const reducer = combineReducers<ISummaryReducer>({
  filters: filtersSlice.reducer,
  programs: programsSlice.reducer,
  premiers: premiersSlice.reducer,
  ministries: ministriesSlice.reducer,
  topBestGP: topBestGpSlice.reducer,
  topWorstGP: topWorstGpSlice.reducer,
  topBestVpOpinion: topBestVpOpinionSlice.reducer,
  topWorstVpOpinion: topWorstVpOpinionSlice.reducer,
  topBestVP: topBestVpSlice.reducer,
  topWorstVP: topWorstVpSlice.reducer,
  topBestVp2: topBestVp2Slice.reducer,
  topWorstVP2: topWorstVp2Slice.reducer,
  topWorstGp2: topWorstGp2Slice.reducer,
  topBestGp2: topBestGp2Slice.reducer,
  topBestMinistries: topBestMinistriesSlice.reducer,
  topBestMinistriesOM: topBestMinistriesOMSlice.reducer,
  topWorstMinistries: topWorstMinistriesSlice.reducer,
  topWorstMinistriesOM: topWorstMinistriesOMSlice.reducer,
  topBestMinistries2: topBestMinistries2Slice.reducer,
  topWorstMinistries2: topWorstMinistries2Slice.reducer,
  topBestGpOM: topBestGpOMSlice.reducer,
  topWorstGpOM: topWorstGpOMSlice.reducer,
});

export default reducer;
