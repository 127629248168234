import { Tooltip } from "@mui/material";
import { TooltipProps } from "@mui/material/Tooltip/Tooltip";
import React from "react";
import { CheckSecretData } from "SRC/components/CheckSecretData";
import { NavigateCell } from "SRC/components/Table";
import { TableCell } from "SRC/components/TableCell";
import { ETitle } from "SRC/components/TableColumnTemplates/columns/indicators/indicatorsFact";
import { sortEventsFailColumn } from "SRC/helpers";
import { ICustomTableProps } from "SRC/types";

import { getEventsFail, getEventsFailTotal } from "./utils";

export const eventsFail = (isOM: boolean) => ({
  Header: "Не выполнено",
  Tooltip: ({ children, ...props }: TooltipProps) => (
    <Tooltip {...props} title={ETitle.FAIL_TITLE}>
      {children}
    </Tooltip>
  ),
  sortType: sortEventsFailColumn(isOM),
  width: 130,
  accessor: isOM ? "eventsOMFail" : "eventsFail",
  Cell: (tableProps: ICustomTableProps) => {
    const { url, eventsFail, isSecret } = getEventsFail(tableProps, isOM);

    return (
      <NavigateCell url={url}>
        <TableCell color={isOM ? "paleRed" : "red"}>
          <CheckSecretData isSecret={isSecret}>{eventsFail}</CheckSecretData>
        </TableCell>
      </NavigateCell>
    );
  },
  Footer: (tableProps: ICustomTableProps) => {
    const { eventsFailTotal } = getEventsFailTotal(tableProps, isOM);

    return (
      <Tooltip title="В расчете участвуют уникальные элементы">
        <TableCell color={isOM ? "paleRed" : "red"}>
          {eventsFailTotal}
        </TableCell>
      </Tooltip>
    );
  },
});
