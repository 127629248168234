import { SerializedError } from "@reduxjs/toolkit";
import { useCallback, useState } from "react";
import { IPeriod } from "SRC/redux/slices/global/slices/filters";
import { IMinistry } from "SRC/redux/slices/summary/slices/ministries";

import { useAppDispatch, useAppSelector } from "../../../hooks";
import actions from "../actions";

export type TMinistry = IMinistry;

export interface IMinistries {
  fetching: boolean;
  fetched: boolean;
  items: TMinistry[];
  load(period: IPeriod): void;
  getItemByCode(code: string): TMinistry | undefined;
  error: SerializedError | null;
}

export const useMinistries = (): IMinistries => {
  const dispatch = useAppDispatch();
  const {
    fetching,
    items: ministries = [],
    fetched,
  } = useAppSelector((state) => state.summary.ministries);
  const [error, setError] = useState<SerializedError | null>(null);

  const load = useCallback(
    (params: IPeriod) => {
      if (!fetching) {
        dispatch(actions.ministries.get(params)).then((action) => {
          if (actions.ministries.get.rejected.match(action)) {
            setError(action.error);
          }
          return action;
        });
      }
    },
    [dispatch, fetching]
  );

  const getItemByCode = (code: string): TMinistry | undefined =>
    ministries.find((ministry: TMinistry) => ministry.info.code === code);

  return {
    fetching,
    fetched,
    items: ministries,
    getItemByCode,
    load,
    error,
  };
};
