import { ArrowForwardIosOutlined } from "@mui/icons-material";
import { Button, Tooltip } from "@mui/material";
import { SecretProgram, WarningTriangle } from "ASSETS/svg";
import React, { BaseSyntheticEvent, useCallback, useMemo, useRef } from "react";
import { useNavigate } from "react-router";
import { ESources, sourceSettings } from "SRC/constants";
import { isSecret } from "SRC/helpers/dataTesters";
import { useOnScreen } from "SRC/hooks";
import { selectors, useAppSelector } from "SRC/redux/hooks";
import { IProgram } from "SRC/redux/slices/programsBoard/hooks/usePrograms";
import { PROGRAM_VIEW, setParamsToUrl } from "SRC/urls";

import { ProgramProgress } from "./ProgramProgress";
import { Wrapper } from "./ProgramTile.styled";

interface ITileProps {
  onClick: (program: IProgram) => void;
  program: IProgram;
  className?: string;
}

export const ProgramTile = ({
  onClick,
  className = "",
  program,
}: ITileProps) => {
  const {
    info: { code, icon, shortName },
    data,
  } = program;
  const navigate = useNavigate();
  const { sources } = useAppSelector(selectors.globalFilters);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const tooltipRef = useRef<HTMLDivElement>();
  const elementOnScreen: boolean = useOnScreen<HTMLDivElement>(tooltipRef);

  const handleNavigate = useCallback(
    (e: BaseSyntheticEvent): void => {
      e.stopPropagation();
      navigate(setParamsToUrl(PROGRAM_VIEW, { programId: program.info.code }));
    },
    [navigate, program]
  );

  const values = useMemo(() => {
    const result: Partial<Record<ESources, number>> = {};

    if (sources[ESources.OM]) {
      result[ESources.OM] = data[5265]?.sum || 0;
    } else {
      result[ESources.FOIV] = data[1796]?.sum || 0;
    }

    return result;
  }, [data, sources]);

  const secretProgram = useMemo(() => {
    return isSecret(program.info);
  }, [program]);

  const atRisk = useMemo(() => {
    return Boolean(data[1680]?.sum || 0);
  }, [data]);

  const handleProgramClick = useCallback(() => {
    onClick(program);
  }, [onClick, program]);

  const programs = Object.entries(values).map(([key, value]) => {
    const hint = sourceSettings[key as ESources]?.title;
    const opinion = sources.OM;

    return (
      <ProgramProgress
        key={key}
        className={`progress-${key}`}
        value={value}
        opinion={opinion}
        hint={hint ? `По данным ${hint}` : ""}
      />
    );
  });

  return (
    <Wrapper
      ref={containerRef}
      className={className}
      onClick={handleProgramClick}
    >
      <div className="program-icon">
        <span>{code}</span>
        <img src={icon} aria-hidden alt="program-icon" />
      </div>

      <div className="info">
        <div className="title">
          <div className="icons">{atRisk && <WarningTriangle />}</div>
          <Tooltip
            ref={tooltipRef}
            title={shortName}
            className="tooltip"
            PopperProps={{
              sx: { display: elementOnScreen ? "block" : "none", opacity: 1 },
              container: containerRef.current,
            }}
          >
            <div className="name">{shortName}</div>
          </Tooltip>
        </div>
        {secretProgram ? (
          <div className="secret-program-icon">
            <span>
              <SecretProgram />
            </span>
          </div>
        ) : (
          programs
        )}
        <Button onClick={handleNavigate}>
          <ArrowForwardIosOutlined />
        </Button>
      </div>
    </Wrapper>
  );
};
