import { styled } from "@mui/material";
import { DarkChartBgr, LightChartBgr } from "ASSETS/svg/backgrounds";

interface IProgressDoughnutFinancingWrapper {
  withDarkBgr?: boolean | undefined;
}

export const Wrapper = styled("div")<IProgressDoughnutFinancingWrapper>(
  ({ withDarkBgr, theme }) => ({
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "end",
    position: "relative",
    " > .textContainer": {
      width: "100%",
      height: "100%",
      position: "absolute",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      top: "1rem",
      gap: "0.5rem",
      background: withDarkBgr
        ? `url(${DarkChartBgr}) no-repeat`
        : `url(${LightChartBgr}) no-repeat`,
      backgroundSize: "contain",
      backgroundPosition: "center",
      " > .symbol": {
        position: "relative",
        bottom: "0.8rem",
        fontSize: "0.875rem",
        lineHeight: "1rem",
        color: theme.palette.complementary.lightWhite,
        display: "flex",
        textAlign: "center",
      },
      " > .text": {
        position: "relative",
        bottom: "0.8rem",
        fontSize: "2.5rem",
        lineHeight: "2.5rem",
        fontWeight: "normal",
        color: theme.palette.primary.contrastText,
      },
    },
  })
);
