import { getIsSecret } from "SRC/pages/Summary/common/SummaryComplexGrid/utils";
import { ICustomTableProps } from "SRC/types";

export const getCheckpointsPlan = (
  tableProps: ICustomTableProps,
  isOM: boolean
) => {
  const { checkpointsPlan, checkpointsOMPlan } = tableProps.row.original;

  return {
    isSecret: getIsSecret(tableProps),
    checkpointsPlan: isOM ? checkpointsOMPlan : checkpointsPlan,
  };
};
