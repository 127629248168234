import { combineReducers } from "@reduxjs/toolkit";
import dataSlice, {
  IIndicatorDataState,
} from "SRC/redux/slices/indicator/slices/data";
import filtersSlice, {
  IFiltersState,
} from "SRC/redux/slices/indicator/slices/filters";
import graphSlice, {
  IIndicatorGraphState,
} from "SRC/redux/slices/indicator/slices/graph";

export interface IIndicatorReducer {
  filters: IFiltersState;
  data: IIndicatorDataState;
  graph: IIndicatorGraphState;
}

const reducer = combineReducers<IIndicatorReducer>({
  filters: filtersSlice.reducer,
  data: dataSlice.reducer,
  graph: graphSlice.reducer,
});

export default reducer;
