import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createPeriodDims,
  IParsedItem,
  parseOlapdata,
  TDecoder,
} from "CORE/utils";
import { isContainedArray } from "SRC/helpers/dataTesters";
import { getCashExecution } from "SRC/redux/slices/event/api/client";
import { IEventParams } from "SRC/types";

const decoder: TDecoder = {
  period: createPeriodDims(1427),
  info: {
    code: 1432,
    dimensions: {
      name: "NAME",
      code: "R_ID",
      order: "SortOrder",
    },
  },
  point: {
    code: 1436,
    dimensions: {
      name: "NAME",
      code: "R_ID",
      order: "SORT_ORDER",
    },
  },
};

export interface IInfo {
  name: string;
  code: string;
  order: string;
}

export interface ICashExecution extends IParsedItem {
  info: IInfo;
  point: IInfo;
}

export interface ICashExecutionState {
  items: ICashExecution[];
  fetching: boolean;
  fetched: boolean;
  error: string;
}

export const extraActions = {
  get: createAsyncThunk(
    "cashExecution/get",
    async (params: IEventParams) => await getCashExecution(params)
  ),
};

const initialState: ICashExecutionState = {
  items: [],
  fetching: false,
  fetched: false,
  error: "",
};

const slice = createSlice({
  name: "cashExecution",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActions.get.pending, (state) => {
        state.items = [];
        state.fetching = true;
        state.fetched = false;
      })
      .addCase(
        extraActions.get.fulfilled,
        (state: ICashExecutionState, action) => {
          const parsed = parseOlapdata(
            // @ts-ignore
            action.payload.data,
            decoder
          ) as unknown as ICashExecution[];
          state.items = isContainedArray(parsed) ? parsed : [];
          state.fetching = false;
          state.fetched = true;
        }
      )
      .addCase(extraActions.get.rejected, (state) => {
        state.fetching = false;
      });
  },
});

export const actions = {
  ...slice.actions,
  ...extraActions,
};

export default slice;
