import { Search } from "@mui/icons-material";
import { FilledInput, FormControl, InputAdornment, Theme } from "@mui/material";
import React, { useCallback } from "react";
import { css as cssComplexGrid } from "SRC/pages/Main/common/ComplexGrid/ComplexGrid.styled";

import { css } from "./TableSearch.styled";

interface IProps {
  value: string;
  onChange: (value: string) => void;
}

export const TableSearch = ({ value = "", onChange }: IProps) => {
  const handleOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange(event.target.value);
    },
    [onChange]
  );

  return (
    <FormControl
      sx={cssComplexGrid.uiSearch}
      variant="filled"
      style={{ width: "100%", maxWidth: "100%" }}
    >
      <FilledInput
        key={(value || "").length}
        startAdornment={
          <InputAdornment position="start">
            <Search
              sx={{
                color: (theme: Theme) => theme.palette.main.gray,
                width: "1.25rem",
                height: "1.25rem",
              }}
            />
          </InputAdornment>
        }
        className="filledInput"
        sx={css.filledInput}
        placeholder="Поиск"
        value={value}
        autoFocus={Boolean(value)}
        onChange={handleOnChange}
      />
    </FormControl>
  );
};
