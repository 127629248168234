import React from "react";

import { getYoutubeVideoId } from "./utils";

type TYoutubeVideo = {
  url: string;
};
export const YoutubeVideo = ({ url }: TYoutubeVideo) => {
  const videoId = getYoutubeVideoId(url);

  return (
    <iframe
      src={`https://www.youtube.com/embed/${videoId}?autoplay=1`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowFullScreen
      title="Embedded"
    />
  );
};
