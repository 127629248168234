import { IPremierTableItem } from "SRC/hooks/summaryTable/interfaces";
import { generalRowsMainTableData } from "SRC/hooks/summaryTable/utils/utils";
import { TPremier } from "SRC/redux/slices/main/hooks/useMainPremiers";
import { EDataPremier } from "SRC/redux/slices/main/slices/mainPremiers";
import { CustomRowProps } from "SRC/types/data/data";

export const createPremierRow = (premiers: TPremier): IPremierTableItem => {
  const { VP_CODE, data, VP_NAME, VP_PHOTO_URL, GP_ARR } = premiers;

  return {
    id: VP_CODE,
    isPremier: true,
    code: VP_CODE,
    name: VP_NAME,
    vicePremier: VP_NAME,
    vicePremierPhoto: VP_PHOTO_URL,
    govPrograms: GP_ARR?.length,

    //Показатели
    indicatorsPlan: data[EDataPremier.INDICATORS_GP],
    indicatorsPlanTotal: data[EDataPremier.INDICATORS_GP] || 0,
    indicatorsOMPlan: data[EDataPremier.INDICATORS_GP_OM],
    indicatorsOMPlanTotal: data[EDataPremier.INDICATORS_GP_OM],

    indicatorsFact: data[EDataPremier.INDICATORS_GP_FACT],
    indicatorsFactTotal: data[EDataPremier.INDICATORS_GP_FACT],
    indicatorsOMFact: data[EDataPremier.INDICATORS_GP_FACT_OM],
    indicatorsOMFactTotal: data[EDataPremier.INDICATORS_GP_FACT_OM],

    //Мероприятия
    eventsPlan: data[EDataPremier.EVENTS_PLAN],
    eventsPlanTotal: data[EDataPremier.EVENTS_PLAN],
    eventsOMPlanTotal: data[EDataPremier.EVENTS_PLAN_OM],
    eventsOMPlan: data[EDataPremier.EVENTS_PLAN_OM],

    eventsFact: data[EDataPremier.EVENTS_FACT],
    eventsFactTotal: data[EDataPremier.EVENTS_FACT],
    eventsOMFactTotal: data[EDataPremier.EVENTS_FACT_OM],
    eventsOMFact: data[EDataPremier.EVENTS_FACT_OM],

    eventsRisk: data[EDataPremier.EVENTS_RISK],
    eventsRiskTotal: data[EDataPremier.EVENTS_RISK],
    // TODO: Дублирование данных
    eventsOMRiskTotal: data[EDataPremier.EVENTS_RISK],
    // TODO: Дублирование данных
    eventsOMRisk: data[EDataPremier.EVENTS_RISK],

    //УД
    performance: data[EDataPremier.PERFORMANCE],
    performanceOM: data[EDataPremier.PERFORMANCE_OM],
    performanceTotal: data[EDataPremier.PERFORMANCE],
    performanceOMTotal: data[EDataPremier.PERFORMANCE_OM],
  };
};

export const createPremiersMainTableData = (
  data: TPremier[]
): IPremierTableItem[] => {
  return data.map((elem: TPremier) => createPremierRow(elem));
};

export const createMainPremierRowsTableData = (
  data: CustomRowProps[],
  isOM: boolean
) =>
  data.map((row: CustomRowProps) => {
    return {
      id: row.original.id,
      name: row.original.name,
      vicePremierPhoto: row.original.vicePremierPhoto,
      numOfPrograms: row.original.subRows?.length,
      ...generalRowsMainTableData(row, isOM),
    };
  });
