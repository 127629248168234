import DownloadIcon from "@mui/icons-material/Download";
import { Button, CircularProgress } from "@mui/material";
import React, { useCallback, useState } from "react";
import { EExtensions } from "SRC/constants";
import useFullReportCreator from "SRC/hooks/use-excel-creator/useFullReportCreator";
import { IExportData } from "SRC/types";

import { TooltipText } from "../TooltipText/TooltipText";
import { WrapperExport } from "../TooltipText/TooltipText.styled";
import { TableExportButtonStyled } from "./Buttons.styles";

export const TableExportButton = <T extends object>({
  csvData,
  fileName,
  wscols,
  headings,
  mergeCells,
  infoRows,
}: IExportData<T>) => {
  const saveFile = useFullReportCreator({
    csvData,
    fileName,
    headings,
    mergeCells,
    wscols,
    infoRows,
  });

  const [isDownloading, setIsDownloading] = useState(false);

  const finishDownloading = () => {
    setIsDownloading(false);
  };

  const handleExtension = useCallback(
    (ext: EExtensions, fileName: string) => {
      saveFile({ ext, callback: finishDownloading, fileName });
    },
    [saveFile]
  );

  const handleExportODS = useCallback(async () => {
    setIsDownloading(true);
    handleExtension(EExtensions.ODS, fileName);
  }, [handleExtension]);

  const handleExportXLSX = useCallback(() => {
    setIsDownloading(true);
    handleExtension(EExtensions.XLSX, fileName);
  }, [handleExtension]);

  const hintElement = (
    <WrapperExport>
      <div className="format" onClick={handleExportODS}>
        .ods
      </div>
      <div className="format" onClick={handleExportXLSX}>
        .xlsx
      </div>
    </WrapperExport>
  );

  return (
    <TableExportButtonStyled>
      {isDownloading ? (
        <Button className="export-button" disabled>
          <CircularProgress />
        </Button>
      ) : (
        <TooltipText hint={hintElement} variant="h3">
          <Button className="export-button" onClick={handleExportXLSX}>
            <DownloadIcon />
          </Button>
        </TooltipText>
      )}
    </TableExportButtonStyled>
  );
};

export default TableExportButton;
