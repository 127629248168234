import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { isContainedArray } from "SRC/helpers/dataTesters";
import { ITopVP } from "SRC/pages/Summary/interfaces";
import { IPeriod } from "SRC/redux/slices/global/slices/filters";
import { getTopWorstVP } from "SRC/redux/slices/summary/api";

export interface IVpTopWorstState {
  items: ITopVP[];
  fetching: boolean;
  fetched: boolean;
  error: string;
}

export const extraActions = {
  get: createAsyncThunk(
    "topWorstVP/get",
    async (period: IPeriod) => await getTopWorstVP(period)
  ),
};

const initialState: IVpTopWorstState = {
  items: [],
  fetching: false,
  fetched: false,
  error: "",
};

const slice = createSlice({
  name: "topWorstVP",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActions.get.pending, (state) => {
        state.items = [];
        state.fetching = true;
        state.fetched = false;
      })
      .addCase(
        extraActions.get.fulfilled,
        (state: IVpTopWorstState, action) => {
          state.items = isContainedArray(action.payload.data)
            ? action.payload.data?.[0]?.data?.[0]?.data || []
            : [];
          state.fetching = false;
          state.fetched = true;
        }
      )
      .addCase(extraActions.get.rejected, (state, action) => {
        state.fetching = false;
      });
  },
});

export const actions = {
  ...slice.actions,
  ...extraActions,
};

export default slice;
