import { styled } from "@mui/material";

export const Wrapper = styled("div")(({ theme }) => {
  return {
    width: "100%",

    ".incident-container": {
      background: theme.palette.background.default,
      padding: "0.5rem 2.5rem",
      fontSize: "1rem",
      flex: 1,

      "& > .grid-container": {
        gap: "1rem",
        marginBottom: "0.5rem",

        ".flex-container": {
          display: "flex",
          alignItems: "center",
          padding: "0.5rem 1rem",
          background: theme.palette.background.paper,
          fontSize: "1rem",
          gap: "0.5rem",
          borderRadius: "0.5rem",
          border: "none",
          cursor: "pointer",

          "& span.gray-text": {
            color: theme.palette.main.gray,
          },

          "&.is-active": {
            background: theme.palette.background.paperBorderGray,
            border: `1px solid ${theme.palette.primary.contrastText}`,
          },
        },

        ".styled-dot": {
          width: "0.8rem",
          height: "0.8rem",
          borderRadius: "50%",

          "&.green": {
            background: theme.palette.success.main,
          },
          "&.red": {
            background: theme.palette.error.main,
          },
          "&.blue": {
            background: theme.palette.background.default,
          },
          "&.yellow": {
            background: theme.palette.main.yellow,
          },
          "&.gray": {
            background: theme.palette.main.gray,
          },
        },
      },
    },
  };
});
