import { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { isSecret } from "SRC/helpers/dataTesters";
import {
  IPremiers,
  usePremiers,
} from "SRC/redux/slices/priorities/hooks/usePremiers";
import {
  IPrograms,
  usePrograms,
} from "SRC/redux/slices/priorities/hooks/usePrograms";
import slice, {
  EGridTabs,
  IFiltersState,
} from "SRC/redux/slices/priorities/slices/filters";

import { useAppSelector } from "../../../hooks";

export interface IFilters extends IFiltersState {
  clear(): void;
  setSelectedComplexGridTab(tab?: EGridTabs): void;
  setSearchRows(rows: Record<string, any>[]): void;
  filteredVPs: (string | number)[];
  filteredPrograms: (string | number)[];
  setSelectedVP(code: string): void;
  setSelectedProgram(code: string): void;
}

export const useFilters = (): IFilters => {
  const dispatch = useDispatch();
  const filters = useAppSelector((state) => state.priorities.filters);
  const { withSecret } = useAppSelector((state) => state.global.filters);

  const { vp, program, ministry } = filters;

  const { items: premiers }: IPremiers = usePremiers();
  const { items: programs }: IPrograms = usePrograms();

  const clear = useCallback(() => dispatch(slice.actions.clear()), [dispatch]);

  const filteredVPs = useMemo(
    () => premiers.map((premier) => premier.info.VP_CODE),
    [premiers]
  );

  const filteredPrograms = useMemo(
    () =>
      programs
        .filter((program) => {
          const isVp = !vp || program.info.vpCode === vp;
          const isSecretProgram = withSecret || !isSecret(program);
          return isVp && isSecretProgram;
        })
        .map((program) => program.info.code),
    [programs, vp, ministry, withSecret]
  );

  const setSelectedComplexGridTab = (tab?: EGridTabs) => {
    dispatch(slice.actions.setComplexGridTab(tab || EGridTabs.PROGRAMS));
  };

  const setSearchRows = (rows: Record<string, any>[]) => {
    dispatch(slice.actions.selectSearchRows(rows));
  };

  const setSelectedProgram = (id: string) =>
    dispatch(slice.actions.selectProgram(program === id ? "" : id));

  const setSelectedVP = (id: string) => {
    dispatch(slice.actions.selectVP(vp === id ? "" : id));

    if (id) {
      setSelectedProgram("");
    }
  };

  return {
    ...filters,
    clear,
    setSelectedComplexGridTab,
    setSearchRows,
    filteredVPs,
    filteredPrograms,
    setSelectedVP,
    setSelectedProgram,
  };
};
