import React from "react";
import { StatusTableProgram } from "SRC/components/StatusTableProgram/StatusTableProgram";
import { TableCell } from "SRC/components/TableCell";
import { ICustomTableProps } from "SRC/types";

import { getProgramStatus } from "./utils";

export const programStatus = () => ({
  Header: " ",
  sticky: "left",
  accessor: "icon",
  columns: [
    {
      Header: " ",
      width: 10,
      sticky: "left",
      accessor: "icon",
      Cell: (tableProps: ICustomTableProps) => {
        const { isSecret, atRisk } = getProgramStatus(tableProps);

        return (
          <TableCell className="status-icon">
            <StatusTableProgram isSecret={isSecret} atRisk={atRisk} />
          </TableCell>
        );
      },
      Footer: "",
    },
  ],
  Footer: "",
});
