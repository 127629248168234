import { Theme, useMediaQuery, useTheme } from "@mui/material";
import React, { useState } from "react";
import { TSortBy } from "SRC/hooks/summaryTable/interfaces";
import { Layout } from "SRC/layouts";
import { usePriorities } from "SRC/redux/slices/priorities/hooks/usePriorities";

import { LeftLayout } from "./layouts";

export const Priorities = () => {
  const theme = useTheme<Theme>();
  const isLargeSize = useMediaQuery(theme.breakpoints.up("lg"));
  usePriorities();

  const [sortBy, setSortBy] = useState<TSortBy>(null);

  return (
    <Layout
      left={<LeftLayout isLargeSize={isLargeSize} setSortBy={setSortBy} />}
    />
  );
};
