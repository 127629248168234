import { Box } from "@mui/material";
import React, { useEffect, useMemo } from "react";
import TableStatusCardNew from "SRC/components/TableStatusCardNew/TableStatusCardNew";
import { css } from "SRC/pages/Ministry/common/Statuses/Statuses.styled";
import { IStatus } from "SRC/pages/Program/common/ProgramSummary/interfaces";
import { useFilters } from "SRC/redux/slices/ministry/hooks/useFilters";

interface IStatuses {
  statuses: IStatus[];
}

export const Statuses = ({ statuses }: IStatuses) => {
  const { selectedStatus, setSelectedStatus, clearSelectedStatus } =
    useFilters();

  useEffect(() => () => clearSelectedStatus(), [clearSelectedStatus]);

  const handleClick =
    ({ status }: IStatus) =>
    () => {
      setSelectedStatus(status);
    };

  const statusesNodes: JSX.Element[] = useMemo(
    () =>
      statuses.map((item: IStatus) => (
        <TableStatusCardNew
          key={item.status}
          status={item.status}
          amount={item.amount}
          onClick={handleClick(item)}
          selected={selectedStatus.includes(item.status)}
        />
      )),
    [statuses, selectedStatus]
  );

  return <Box sx={css.statusCardContainer}>{statusesNodes}</Box>;
};
