import { olapDimensionRequestData } from "CORE/api/core";
import { MINISTRY_MODEL_UUID } from "CORE/scheme/olap";
import { getMinistriesIndicators } from "SRC/constants";
import { IPeriod } from "SRC/redux/slices/global/slices/filters";

const GET_MINISTRIES = "get-ministries-1";

export const getMinistries = (period: IPeriod) => {
  return olapDimensionRequestData(
    [
      {
        requestId: "46398f51-9135-4cb8-9688-51238fb3cc31",
        type: "data2",
        params: {
          modelUuid: MINISTRY_MODEL_UUID,
          dashboardUuid: "",
          includeGaps: false,
          dimensions: [
            {
              id: "1770",
              includeItems: true,
              includeAttributesByCodes: [
                "NAME",
                "SHORT_NAME",
                "SORT_ORDER",
                "icon_url",
              ],
            },
            {
              id: "1774",
              includeItems: true,
              includeAttributesByCodes: [
                "NAME",
                "SHORT_NAME",
                "SORT_ORDER",
                "DatePart",
                "PeriodStart",
                "PeriodEnd",
                "PERIOD_CODE",
                "PERIOD2",
              ],
            },
          ],
          indicators: {
            id: "1781",
            items: getMinistriesIndicators(
              1782,
              1783,
              1784,
              1785,
              1786,
              1788,
              1845,
              1848,
              1849,
              5268,
              5273,
              5275,
              5387,
              5388,
              5389,
              5390,
              5401,
              5402,
              5403,
              5404,
              5405,
              5406,
              5407,
              5408,
              5409,
              5410,
              5411,
              5413,
              5412,
              5414,
              5415,
              5416,
              5417,
              5418,
              5419,
              5421,
              5422,
              5423,
              5424,
              5425,
              5426,
              5427,
              5428,
              5429,
              5430,
              5431,
              5432,
              5433,
              5434,
              5435,
              5436,
              5437,
              5438,
              5439,
              5440,
              5441,
              5442,
              5443,
              5444,
              5445,
              5505,
              5506,
              5507,
              5508,
              5509,
              5510,
              5511,
              5512,
              5513,
              5514,
              5515,
              5598
            ),
          },
          dataRequestCaching: true,
          dataFilter: {
            type: "AND",
            version: 1,
            operands: [
              {
                type: "STARTER",
                version: 1,
                dim_id: "1770",
              },
              {
                type: "EQ",
                version: 1,
                operands: [
                  {
                    type: "DIM",
                    version: 1,
                    id: "1774",
                  },
                  {
                    type: "CONST",
                    version: 1,
                    values: [period.apiV2],
                  },
                ],
              },
            ],
          },
        },
      },
    ],
    GET_MINISTRIES
  );
};
