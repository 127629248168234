import { Box, Grid, Typography } from "@mui/material";
import { AchievementsTile } from "SRC/components/AchievementsTile/AchievementsTile";
import { IAchievementData } from "SRC/components/AchievementsTile/interfaces";
import { IncidentReport } from "SRC/components/IncidentReport";

import { css, RatingWrapper } from "./RatingTab.styled";

interface IRatingTabProps {
  withDarkBgr?: boolean | undefined;
  typeTop: "best" | "worst";
  data: IAchievementData[];
  fetching: boolean;
}

const awaitArray = new Array(5).fill({});

export const RatingTab = ({
  withDarkBgr,
  typeTop,
  data,
  fetching,
}: IRatingTabProps) => {
  const topData = fetching ? awaitArray : data;
  return (
    <RatingWrapper>
      <Grid sx={css.container}>
        {withDarkBgr && (
          <Box>
            <Typography component="h3" sx={css.title}>
              {typeTop === "best" ? "Лидирующие" : "Отстающие"}
            </Typography>
          </Box>
        )}
        <Box sx={css.rating}>
          {topData.map((item, key) => (
            <IncidentReport className="incident-container">
              <AchievementsTile
                data={item}
                withDarkBgr={withDarkBgr}
                key={key}
                fetching={fetching}
              />
            </IncidentReport>
          ))}
        </Box>
      </Grid>
    </RatingWrapper>
  );
};
