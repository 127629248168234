import { Box, Stack, useMediaQuery, useTheme } from "@mui/material";
import React, {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useCallback,
  useMemo,
} from "react";
import { Column, TableInstance } from "react-table";
import { TableModal } from "SRC/components/FullPageTableModal/FullPageTableModal";
import { IncidentReport } from "SRC/components/IncidentReport";
import { SecretSwitcher } from "SRC/components/SecretSwitcher";
import Table, { ColumnsController } from "SRC/components/Table";
import { TableActionsButtons } from "SRC/components/TableActionsButtons/TableActionsButtons";
import { useTableData } from "SRC/hooks/summaryTable/useSummaryMinistries";
import { createMinistryRowsTableData } from "SRC/hooks/summaryTable/utils/summaryData/summaryDataMinistries";
import { RatingTabs } from "SRC/pages/Summary/common/Rating/RatingTabs/RatingTabs";
import { css } from "SRC/pages/Summary/common/SummaryComplexGrid/SummaryComplexGrid.styled";
import { useRatingData } from "SRC/pages/Summary/useRatingData";
import {
  IGlobalFilters,
  useGlobalFilters,
} from "SRC/redux/slices/global/hooks/useFilters";
import { useFilters } from "SRC/redux/slices/summary/hooks/useFilters";
import { useMinistries } from "SRC/redux/slices/summary/hooks/useMinistries";
import { CustomRowProps } from "SRC/types/data/data";

import { getGeneralInfoMinistriesColumns } from "./tableColumns";

interface IGeneralInfoMinistriesTab {
  posTable?: string;
  forwardRef?: MutableRefObject<HTMLElement | undefined>;
  onScroll?: (scroll: any) => void;
  isModalOpen?: boolean;
  setModalOpen?: (value: boolean) => void;
  instance: TableInstance | undefined;
  setInstance: Dispatch<SetStateAction<TableInstance<{}> | undefined>>;
}

export const staticColumns = [
  "icon",
  "id",
  "ministriesIcon",
  "ministry",
  "numOfPrograms",
];
const sortedColumns = [
  "№",
  "Министерство",
  "Кол-во госпрограмм",
  "План, млрд ₽",
  "Факт, %",
  "План",
  "Факт",
  "Не выполнено",
  "Уровень достижения, %",
];

const RenderTable: React.FC<IGeneralInfoMinistriesTab> = ({
  forwardRef,
  onScroll,
  isModalOpen,
  setModalOpen,
  instance,
  setInstance,
}) => {
  const theme = useTheme();
  const { sources, withSecret }: IGlobalFilters = useGlobalFilters();
  const { setSearchRows } = useFilters();
  const { fetching } = useMinistries();
  const { ministriesSummaryData, ministriesSummaryExport } = useTableData();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));
  const columns: Column[] = useMemo(
    () => getGeneralInfoMinistriesColumns(sources.OM),
    [sources]
  );
  const { allColumns } = instance || {};

  const tableHeight: string | undefined = useMemo(() => {
    if (isModalOpen) return;
    return matches ? css.tableHeightLong : css.tableHeight;
  }, [isModalOpen, matches]);

  const onChangeFilterRows = useCallback(
    (rows: CustomRowProps[]) => {
      setSearchRows(createMinistryRowsTableData(rows, sources.OM, withSecret));
    },
    [sources.OM, withSecret]
  );

  return (
    <>
      {isModalOpen && (
        <Box sx={css.modalActionButtonsContainer}>
          <SecretSwitcher />
          <TableActionsButtons
            open={isModalOpen}
            setOpen={setModalOpen}
            {...ministriesSummaryExport}
          />
          {allColumns && (
            <ColumnsController
              allColumns={allColumns}
              statics={staticColumns}
            />
          )}
        </Box>
      )}
      <Box sx={css.tablesWrapper}>
        <IncidentReport>
          <Table
            loading={fetching}
            columns={columns}
            data={ministriesSummaryData}
            sortedColumns={sortedColumns}
            tableHeight={tableHeight}
            forwardRef={forwardRef}
            onScroll={onScroll}
            noDataCondition={ministriesSummaryData?.length === 0}
            getInstance={setInstance}
            sticky
            expandable
            classes={{ tbody: "table-body", tfoot: "table-footer" }}
            onFilterRows={onChangeFilterRows}
          />
        </IncidentReport>
      </Box>
    </>
  );
};

export const GeneralFullInfoMinistriesTab = (
  props: IGeneralInfoMinistriesTab
) => {
  const { isModalOpen, setModalOpen } = props;
  const { sources } = useGlobalFilters();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));

  const {
    bestMinistriesData,
    worstMinistriesData,
    bestMinistriesFetching,
    worstMinistriesFetching,
    bestMinistriesOMFetching,
    worstMinistriesOMFetching,
    bestMinistriesOMData,
    worstMinistriesOMData,
  } = useRatingData();

  const {
    dataBestMinistries,
    dataWorstMinistries,
    bestFetching,
    worstFetching,
  } = useMemo(() => {
    if (sources.OM) {
      return {
        dataBestMinistries: bestMinistriesOMData,
        dataWorstMinistries: worstMinistriesOMData,
        bestFetching: bestMinistriesOMFetching,
        worstFetching: worstMinistriesOMFetching,
      };
    }

    return {
      dataBestMinistries: bestMinistriesData,
      dataWorstMinistries: worstMinistriesData,
      bestFetching: bestMinistriesFetching,
      worstFetching: worstMinistriesFetching,
    };
  }, [
    sources.OM,
    bestMinistriesOMData,
    worstMinistriesOMData,
    bestMinistriesData,
    worstMinistriesData,
    bestMinistriesFetching,
    worstMinistriesFetching,
    bestMinistriesOMFetching,
    worstMinistriesOMFetching,
  ]);

  return (
    <Stack style={css.tabContainer}>
      <Box sx={{ pb: "1rem" }}>
        <RatingTabs
          dataBest={dataBestMinistries}
          dataWorst={dataWorstMinistries}
          bestFetching={bestFetching}
          worstFetching={worstFetching}
        />
      </Box>
      <RenderTable {...props} />
      {!matches && (
        <TableModal
          open={isModalOpen}
          setOpen={setModalOpen}
          tableName="Сводная таблица. Министерства"
        >
          <RenderTable {...props} />
        </TableModal>
      )}
    </Stack>
  );
};
