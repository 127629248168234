import { ICustomTableProps } from "SRC/types";

export const getIsSecret = (tableProps: ICustomTableProps) => {
  const { secretProgram, govPrograms, subRows = [] } = tableProps.row.original;

  const numOfPrograms = subRows ? subRows.length : null;

  const hasPublicPrograms = Boolean(
    !subRows.length ||
      subRows.filter(({ secretProgram }: any) => !secretProgram).length
  );

  return secretProgram || !hasPublicPrograms || (!numOfPrograms && govPrograms);
};
