import { createSlice } from "@reduxjs/toolkit";
import { TableState } from "react-table";

export interface IStructureElement {
  selectedStatus: string[];
  selectedStructureElementType: string;
  tableState: Partial<TableState> | undefined;
}

const initialState: IStructureElement = {
  selectedStatus: [],
  selectedStructureElementType: "",
  tableState: undefined,
};

const slice = createSlice({
  name: "structureElement",
  initialState,
  reducers: {
    clear: () => initialState,
    selectStatus: (state, action: { payload: string }) => {
      const actionStatus = action.payload;
      const selectedStatuses = [...state.selectedStatus];

      if (selectedStatuses.includes(actionStatus)) {
        state.selectedStatus = selectedStatuses.filter(
          (status) => status !== actionStatus
        );
      } else {
        state.selectedStatus = selectedStatuses.concat(actionStatus);
      }
    },
    clearStatus: (state) => {
      state.selectedStatus = [];
    },
    selectStructureElementType: (state, action: { payload: string }) => {
      state.selectedStructureElementType = action.payload;
    },
    setTableInstance: (
      state,
      action: { payload: Partial<TableState> | undefined }
    ) => {
      state.tableState = action.payload;
    },
  },
});

export const actions = {
  ...slice.actions,
};

export default slice;
