import { styled } from "@mui/material";

type StatusPointProps = {
  value: number;
  isPale?: boolean;
};
export const Wrapper = styled("div")<StatusPointProps>(
  ({ theme, value, isPale = false }) => {
    const {
      pale: { red, paleRed, green, paleGreen },
    } = theme.palette;

    const redColor = isPale ? paleRed : red;
    const greenColor = isPale ? paleGreen : green;
    const backgroundColor = value < 100 ? redColor : greenColor;

    return {
      width: "0.75rem",
      height: "0.75rem",
      borderRadius: "50%",
      backgroundColor,
    };
  }
);
