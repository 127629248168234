import { Box } from "@mui/material";
import React, { useState } from "react";
import { CreateIncidentBtn } from "SRC/components/CreateIncidentBtn";
import { PerformanceValue } from "SRC/components/PerformanceValue/PerformanceValue";
import { OverlayProgressBar } from "SRC/components/ProgressBar";
import { SourceValue } from "SRC/components/SourceValue/SourceValue";
import { ISourceValue } from "SRC/constants/globals";
import { getPercentValue } from "SRC/helpers/getPercentValue";

import { css } from "../EffectiveInfo.styled";

interface IProgressComponentProps {
  values: ISourceValue[];
}

export const ProgressComponent = ({ values }: IProgressComponentProps) => {
  return (
    <Box>
      <Box sx={css.value}>
        {values.map((value, index) => (
          <SourceValue key={index} {...value} unit>
            <PerformanceValue
              value={getPercentValue(value.value as number)}
              sameSize={false}
            />
          </SourceValue>
        ))}
      </Box>
      <OverlayProgressBar values={values} />
    </Box>
  );
};
