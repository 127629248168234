import React from "react";
import { Column } from "react-table";
import { TableCell } from "SRC/components/TableCell";
import { ITaskTableItem } from "SRC/redux/stores/StructureElement/useExtendedTasks";

const columns: Column<ITaskTableItem>[] = [
  {
    Header: "ID",
    accessor: "id",
    width: 200,
    Cell: ({ value }) => <TableCell color="gray">{value}</TableCell>,
  },
  {
    Header: "Наименование",
    accessor: "name",
    width: "auto",
    Cell: ({ value }) => <TableCell>{value}</TableCell>,
  },
  {
    Header: "Тип",
    accessor: "type",
    width: 100,
    Cell: ({ value }) => <TableCell>{value}</TableCell>,
  },
  {
    Header: "Количество показателей",
    accessor: "indicators",
    width: 200,
    Cell: ({ value }) => <TableCell>{value}</TableCell>,
  },
  {
    Header: "Количество мероприятий",
    accessor: "events",
    width: 200,
    Cell: ({ value }) => <TableCell>{value}</TableCell>,
  },
];

export const tableColumns = columns as Column<object>[];
