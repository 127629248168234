import { Box, SxProps, Typography, useTheme } from "@mui/material";
import ReactECharts from "echarts-for-react";
import React, { useMemo } from "react";
import { useFilters } from "SRC/redux/slices/event/hooks/useFilters";
import themeConfig from "SRC/theme";

import {
  getBottomOffset,
  getChartValue,
  getLeftOffset,
  getMaxPointValue,
} from "../utils";
import { css } from "./EventChart.styled";
import { getOption } from "./options";

interface IProps {
  planData: number[];
  factData: number[];
}

const EventChart = ({ planData, factData }: IProps) => {
  const theme = useTheme<typeof themeConfig>();

  const { selectedFinanceUnit } = useFilters();

  const options = useMemo(
    () => getOption(planData, factData, selectedFinanceUnit, theme),
    [planData, factData, selectedFinanceUnit, theme]
  );

  const maxPlan = Math.max.apply(null, planData);
  const maxFact = Math.max.apply(null, factData);
  const maxValue = Math.max(maxPlan, maxFact);

  const maxPointValue = getMaxPointValue(factData, planData);

  return (
    <Box sx={css.container}>
      {planData.map((item, i) => {
        const bottomOffset = getBottomOffset(maxPointValue, i, maxValue);
        const leftOffset = getLeftOffset(maxPointValue, i);

        return (
          <Box
            sx={css.labelContainer(bottomOffset, leftOffset)}
            key={selectedFinanceUnit + i}
          >
            <Typography sx={css.planText}>
              {getChartValue(planData[i])}
            </Typography>
            <Box sx={css.divider} />
            <Typography sx={css.factText(factData[i], planData[i]) as SxProps}>
              {getChartValue(factData[i])}
            </Typography>
          </Box>
        );
      })}
      <ReactECharts
        option={options}
        style={{
          width: "100%",
          minHeight: "100%",
        }}
      />
    </Box>
  );
};

export default EventChart;
