import { Theme } from "@mui/material";

const $activeGradient =
  "linear-gradient(90deg, rgba(46, 54, 74, 0) 0%, rgba(160, 176, 208, 0.3) 100%)";
const $lightRow = (theme: Theme) => theme.palette.complementary.darkGrayBlue;
const $darkRow = (theme: Theme) => theme.palette.background.paper;
const $bgc = (theme: Theme) => theme.palette.main.darkBlue;

const mixins = {
  headFoot: {
    position: "sticky",
    zIndex: 9,
    width: "fit-content",
    minWidth: "100%",
    borderRadius: "1rem",
    flex: "0 0 auto",
    padding: "0.5rem 0",
  },
  row: {
    position: "relative",
    display: "flex",
    width: "fit-content",
    minWidth: "100% !important",
    borderRadius: "1rem",
  },
  td: {
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    position: "relative",
    minWidth: "1rem",
    padding: "0.5rem 1rem",
  },
  cell: {
    display: "flex",
    alignItems: "center",
    flex: 1,
    fontSize: "1em",
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

const inlineFooter = {
  position: "static",
  padding: "0",
};

export const cssSortingArrows = (isSortedDesc: boolean) => ({
  ...mixins.flexCenter,
  flex: "0 0 auto",
  flexDirection: "column",
  gap: "0.5rem",
  transform: !isSortedDesc ? "scaleY(-1)" : null,

  svg: {
    width: "1.5rem",
    height: "1.5rem",
  },
});

export const css = {
  wrapper: (tableHeight?: string | number) => {
    return {
      position: "relative",
      flex: "1",
      display: "flex",
      flexDirection: "column",
      minHeight: 0,
      height: tableHeight || "auto",
      maxHeight: tableHeight || "100%",
      maxWidth: "100%",
    };
  },

  table: (scrollHide: boolean | undefined, clipPath: string) => ({
    minWidth: "fit-content",
    boxSizing: "content-box",
    width: "calc(100% + 1rem)",
    height: "100%",
    maxHeight: "100%",
    marginRight: "-1rem",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    clipPath: `path("${clipPath}")`,

    "::-webkit-scrollbar": {
      display: scrollHide ? "none" : "",
    },

    "&.sticky": {
      "[data-sticky-td]": {
        position: "sticky",

        "&:not([data-sticky-last-left-td])": {
          zIndex: "4 !important",
        },
      },

      "[data-sticky-last-left-td]": {
        boxShadow: `rgb(40 40 40 / 17%) 1px 0px, rgb(30 30 30 / 10%) 3px 0px, rgb(30 30 30 / 3%) 5px 0px`,
      },

      "[data-sticky-first-right-td]": {
        boxShadow: `rgb(40 40 40 / 17%) -1px 0px, rgb(30 30 30 / 10%) -3px 0px, rgb(30 30 30 / 3%) -5px 0px`,
      },
    },
  }),

  tr: {
    ...mixins.row,
    minHeight: "2.6rem",
  },

  thead: {
    ...mixins.headFoot,
    top: 0,
    backgroundColor: $lightRow,
  },
  th: (
    sorted: boolean,
    align: "center" | "left" | "right",
    width: number | string = "auto"
  ) => ({
    ...mixins.td,
    textAlign: align || "left",
    fontSize: "1.2rem",
    lineHeight: "1.2em",
    fontWeight: 400,
    cursor: sorted && "pointer",
    color: (theme: Theme) => theme.palette.main.gray,
    backgroundColor: $lightRow,
    flex:
      width === "auto"
        ? 1
        : `0 0 ${typeof width === "number" ? `${width}px` : width} !important`,
  }),
  thContent: (upper: boolean) => ({
    display: "flex",
    alignItems: "inherit",
    columnGap: "0.5rem",
    position: "relative",
    bottom: upper ? "100%" : "unset",
  }),

  tbody: {
    width: "fit-content",
    minWidth: "100%",
    position: "relative",
    zIndex: 4,
    flex: 1,
  },
  tableRow:
    (
      withClickableRow?: undefined | boolean,
      isSelected?: boolean,
      defaultSelection?: boolean,
      inPerformanceData?: boolean,
      inPerformance?: boolean
    ) =>
    (theme: Theme) => {
      const inPerformanceOpacity =
        inPerformance && !inPerformanceData ? 0.5 : 1;
      const selectedOpacity = defaultSelection || isSelected ? 1 : 0.5;
      return {
        ...mixins.row,
        borderRadius: "1rem",
        position: "relative",
        margin: "4px 0",
        minHeight: "3rem",
        background: isSelected
          ? `${$activeGradient}, rgba(46, 54, 74, 0.4) !important`
          : `linear-gradient(270deg, ${theme.palette.background.paper} 0%, ${theme.palette.background.paperGray} 100%)`,
        cursor: !withClickableRow ? "default" : "pointer",
        opacity: inPerformance ? inPerformanceOpacity : selectedOpacity,

        "&:hover": {
          background: theme.palette.background.rowHoverWhite,

          "[data-sticky-last-left-td]": {
            "&:after": {
              background: theme.palette.background.rowHoverWhite,
            },
          },
        },

        // "@media (minWidth: 3800px)": {
        //   height: withBigRows ? "8rem" : "6rem",
        // },
      };
    },
  td: (width: number | string, stickyWidth: number) => ({
    ...mixins.td,
    flex:
      width === "auto"
        ? 1
        : `0 0 ${typeof width === "number" ? `${width}px` : width} !important`,

    ".cellWrapper": {
      marginTop: "0rem",
      display: "flex",
      alignItems: "center",
      height: "100%",
      textAlign: "left",
    },

    "&[data-sticky-last-left-td]": {
      zIndex: "3 !important",
      boxShadow: (theme: Theme) =>
        `5px 2px 10px -4px ${theme.palette.main.darkBlue}`,

      "&:after": {
        content: '""',
        zIndex: -1,
        position: "absolute",
        width: stickyWidth + "px",
        height: "100%",
        top: 0,
        right: 0,
        borderRadius: "1rem 0 0 1rem",
        background: (theme: Theme) =>
          `linear-gradient(270deg, ${theme.palette.background.paper} 0%, ${theme.palette.background.paperGray} 100%)`,
      },
    },
  }),

  cell: (css: Record<string, string | number>) => () => {
    return {
      ...mixins.cell,
      ...css,
      left: 0,
      zIndex: 2,
      flex: 1,
    };
  },

  tfoot: (inline: boolean) => (theme: Theme) => ({
    ...mixins.headFoot,
    ...(inline ? inlineFooter : {}),
    bottom: "-1px",
    boxShadow: `0px -3px 3px ${$bgc}`,
    background: $darkRow,

    ":after": {
      width: "calc(100% + 2rem)",
      height: "100%",
      bottom: 0,
      position: "absolute",
      background: $bgc,
      zIndex: 5,
      boxShadow: `0px -10px 5px 5px ${theme.palette.main.darkBlue}`,

      [theme.breakpoints.up("xl")]: {
        height: "5rem",
      },
    },
  }),
  tf: (width: number | string = "auto") => ({
    ...mixins.td,
    height: "4rem",
    padding: "0.5rem 1rem",
    background: $darkRow,
    flex:
      width === "auto"
        ? 1
        : `0 0 ${typeof width === "number" ? `${width}px` : width} !important`,
  }),

  // TODO [Добить стили]: что мог забыть? ===========================================
  headerBackHideRiskIcon: {
    width: "calc(100% - 2rem)",
    height: "5.5rem",
    top: "-0.5rem",
    left: "-0.5rem",
    position: "absolute",
    background: "transparent",
    zIndex: 1,

    "&.hideHeaderIcon": {
      background: (theme: Theme) => theme.palette.main.darkBlue,
      width: "2rem",
      height: "6rem",
    },
    "&.auxBlock": {
      background: "transparent",
      width: "100%",
    },
  },
  footerBackHideRiskIcon: {
    width: "calc(100% - 2rem)",
    height: "5.5rem",
    top: "2rem",
    left: "-1rem",
    position: "absolute",
    background: "transparent",
    zIndex: 1,

    "&.hideFooterIcon": {
      background: (theme: Theme) => theme.palette.main.darkBlue,
      width: "2rem",
      height: "5rem",
      top: "-2rem",
    },
  },
};
