import { Tooltip } from "@mui/material";
import React from "react";
import { PerformanceValue } from "SRC/components/PerformanceValue/PerformanceValue";
import { getPercentValue } from "SRC/helpers/getPercentValue";

import { Wrapper } from "./ProgramProgress.styled";

interface IProgramProgressProps {
  value?: number;
  className?: string;
  hint?: string;
  opinion?: boolean;
}

export const ProgramProgress = ({
  value = 0,
  className = "",
  hint = "",
  opinion = false,
}: IProgramProgressProps) => {
  return (
    <Wrapper value={value} className={className} opinion={opinion}>
      <Tooltip
        title={hint}
        PopperProps={{
          disablePortal: true,
        }}
      >
        <div className="tooltip-box">
          <PerformanceValue
            className="program-progress"
            value={getPercentValue(value)}
            sameSize={true}
          />
        </div>
      </Tooltip>
    </Wrapper>
  );
};
