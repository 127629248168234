import { styled } from "@mui/material";
import background from "ASSETS/bg.png";

export const RootContainer = styled("div")(({ theme }) => {
  const {
    background: { default: backgroundColor },
    primary: { contrastText },
  } = theme.palette;

  return {
    display: "flex",
    flexDirection: "column",
    width: "100vw",
    height: "100vh",
    overflow: "hidden",
    color: contrastText,
    background: `${backgroundColor} url(${background}) 50% 50% no-repeat`,
    backgroundSize: "cover",

    "> .content-scroll": {
      flex: "1 1 auto",
      overflowY: "auto",
      zIndex: 1,
    },
  };
});

export const Content = styled("main")(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "column",
    minHeight: "100%",
    padding: "1rem 2rem",
    gap: "0.5rem",

    [theme.breakpoints.up("xl")]: {
      overflow: "hidden",
      padding: "0 2rem 0",
    },
  };
});
