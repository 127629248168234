import { Box, SxProps } from "@mui/material";
import { Cell } from "react-table";

import { css } from "../Base/Base.styled";
import { ETableEl, TClasses, TDefaultData } from "./Base";

interface IBaseDefaultProps {
  classes?: TClasses;
  cell: Cell<TDefaultData<any>, any>;
  index: number;
}

export const BaseDefault = ({ classes, cell, index }: IBaseDefaultProps) => {
  const { column, value } = cell;

  const calcColSpan = (
    cell: Cell<any>,
    index: number
  ): Record<string, string> => {
    const {
      // @ts-ignore
      column: { width = 0, colspan },
      row: { allCells },
      row,
    } = cell;

    const actualColspan = colspan ? colspan(row) : 1;

    if (actualColspan > 1) {
      const spanCells = allCells.slice(index, index + actualColspan);

      const spanWidth = spanCells.reduce(
        (acc, { column }) => acc + column.totalWidth,
        0
      );

      // TODO: перенести в стили, возвращать из функции только return width;
      return {
        position: "absolute",
        width: `calc(${spanWidth}px - 1rem)`,
        padding: "0 1rem",
      };
    }

    return { width: `${width}px` };
  };

  return (
    <Box
      className={`${ETableEl.cell} ${classes?.cell || ""}`}
      sx={css.cell(calcColSpan(cell, index)) as SxProps}
    >
      {cell.render("Cell")}
    </Box>
  );
};
