import { styled, Theme } from "@mui/material";

export const Wrapper = styled("div")(({ theme }) => ({
  width: "100%",
  ".infoContainer": {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    padding: "1.5rem 1.5rem 0 1.5rem",
    height: "50%",
  },
  ".title": {
    display: "flex",
    flexDirection: "column",
    maxWidth: "80%",
  },
  ".indicatorName": {
    margin: "0.5rem 0.5rem 0.5rem 0",
    fontSize: "1.13rem",
    lineHeight: "1.3rem",
    color: theme.palette.primary.contrastText,
    whiteSpace: "normal",
  },
  ".percentSmallText": {
    marginBottom: 0,
    fontSize: "1.25rem",
    lineHeight: "1.25rem",
    color: theme.palette.complementary.grayBlue,
  },
  ".indicators": {
    display: "flex",
    gap: "2rem",
  },
  ".indicatorItem": {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    maxHeight: "6.75rem",
  },
  ".smallText": {
    fontSize: "1.25rem",
    lineHeight: "1.25rem",
    color: theme.palette.main.gray,
  },
}));
export const css = {
  circle: (color: string) => ({
    width: "1.5rem",
    height: "1.5rem",
    borderRadius: "50%",
    background: (theme: Theme) => color,
    border: (theme: Theme) => `5px solid ${theme.palette.main.darkBlue}`,
  }),
  chart: {
    height: "45%",
  },
  text: (theme: Theme, color: string) => ({
    display: "flex",
    alignItems: "flex-end",
    fontWeight: 400,
    fontSize: "1.5rem",
    lineHeight: "2rem",
    height: "1.5rem",
    color: color,
    whiteSpace: "normal",

    [theme.breakpoints.up("xl")]: {
      fontSize: "1.2rem",
    },
  }),
};
