import { useTheme } from "@mui/material";
import React, { useCallback, useMemo } from "react";
import { Column } from "react-table";
import { FilterButtonReset } from "SRC/components/FilterButtonReset/FilterButtonReset";
import { IOption } from "SRC/components/SelectWithSearch/SelectWithSearch";
import SelectWithSearchGP from "SRC/components/SelectWithSearch/SelectWithSearchGP";
import { SelectWithSearchStructure } from "SRC/components/SelectWithSearch/SelectWithSearchStructure";
import Table from "SRC/components/Table";
import { getGeneralColumns } from "SRC/pages/Structure/common/StructureTabs/GeneralTab/columns";
import {
  IFilters,
  useFilters,
} from "SRC/redux/slices/structure/hooks/useFilters";

import { css, Wrapper } from "./GeneralTab.styled";

export const GeneralTab = () => {
  const theme = useTheme();
  const selectStyle = useMemo(() => css.selector(theme), [theme]);
  const {
    selectStructureElement,
    setSelectedGosprogram,
    setSelectedStructureElement,
    clear,
  }: IFilters = useFilters();
  const columns: Column[] = useMemo(() => getGeneralColumns(), []);

  const handleClearFilters = useCallback(() => {
    clear();
  }, []);

  const options: IOption[] = useMemo(
    () => [
      {
        value: "1",
        label: "Программа 1",
      },
      {
        value: "2",
        label: "Программа 2",
      },
      {
        value: "3",
        label: "Программа 3",
      },
    ],
    []
  );
  return (
    <Wrapper>
      <div className="filter-container">
        <SelectWithSearchGP
          value="programs"
          label="Государственная программа"
          staticLabel={false}
          style={selectStyle}
          onChange={setSelectedGosprogram}
          options={options}
        />
        <SelectWithSearchStructure
          label="Структурные элементы"
          staticLabel={false}
          style={selectStyle}
          onChange={setSelectedStructureElement}
          value={selectStructureElement}
        />
        <FilterButtonReset handleReset={handleClearFilters} />
      </div>
      <Table
        data={[]}
        columns={columns}
        sortedColumns={["Количество показателей", "Количество мероприятий"]}
      />
    </Wrapper>
  );
};
