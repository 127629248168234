import { ICodeItem } from "SRC/types";

import {
  getEventIndicator,
  getMinistryIndicator,
  getPremierIndicator,
  getProgramIndicator,
} from "./indicators";

export const getDataSumByCodeGP = (
  item: { data: ICodeItem[] } = { data: [] },
  code: number
) => {
  return item?.data?.find((el) => {
    return el.code === getProgramIndicator(code)?.id;
  })?.sum;
};

export const getGpIndicators = (...args: (number | string)[]) => {
  return args.map((code) => getProgramIndicator(code)).filter(Boolean);
};

export const getDataSumByCodeVP = (
  item: { data: ICodeItem[] } = { data: [] },
  code: number
) => item?.data?.find((el) => el.code === getPremierIndicator(code)?.id)?.sum;

export const getVpIndicators = (...args: (number | string)[]) => {
  return args.map((code) => getPremierIndicator(code)).filter(Boolean);
};

export const getEventIndicators = (...args: (number | string)[]) => {
  return args.map((code) => getEventIndicator(code)).filter(Boolean);
};

export const getMinistriesIndicators = (...args: (number | string)[]) =>
  args.map((code) => getMinistryIndicator(code)).filter(Boolean);

export const getDataSumByCodeMin = (
  item: { data: ICodeItem[] } = { data: [] },
  code: number
) => {
  return item?.data?.find(
    (el: ICodeItem) => el.code === getMinistryIndicator(code)?.id
  )?.sum;
};

export const getDataSumByCodeMinSummary = (
  item: { data: ICodeItem[] } = { data: [] },
  code: number
) => {
  return item?.data?.find(
    (el: ICodeItem) => el.code === getMinistryIndicator(code)?.id
  )?.sum;
};
