import { getRoundValue } from "SRC/helpers/getRoundValue";
import { getIsSecret } from "SRC/pages/Summary/common/SummaryComplexGrid/utils";
import { ICustomTableProps } from "SRC/types";

export const getCashFact = (tableProps: ICustomTableProps) => {
  const { cashFact = 0 } = tableProps.row.original;

  return {
    cashFact: getRoundValue(cashFact, 2),
    isSecret: getIsSecret(tableProps),
  };
};
