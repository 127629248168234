import { styled } from "@mui/material";

export const Wrapper = styled("div")(({ theme }) => {
  const backgroundColor = theme.palette.complementary.grayBlue;

  return {
    position: "absolute",
    top: "50%",
    left: "-1.5rem",
    padding: "1.2rem 0.7rem",
    transform: "translateY(-50%)",
    borderRadius: "8px",
    background: backgroundColor,
    zIndex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    opacity: 1,
    transition: "0.3s ease-in",
    "> svg": {
      height: "0.75rem",
      width: "0.375rem",
    },

    "&:hover": {
      background: backgroundColor,
    },

    "&.next": {
      right: "-0.5rem",
      left: "auto",
    },
  };
});
