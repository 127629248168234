import { Box, Button, Grid, SxProps, useTheme } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { Column, TableInstance } from "react-table";
import { SelectWithSearchStructure } from "SRC/components/SelectWithSearch/SelectWithSearchStructure";
import Table from "SRC/components/Table";
import { getEventsColumns } from "SRC/pages/Ministry/common/EventsTab/tableColumns";
import { FilterTaskStructures } from "SRC/pages/Ministry/common/filters/FilterTasksStructure";
import { css } from "SRC/pages/Ministry/common/ministriesTabs.styled";
import { MinistriesTabsSearch } from "SRC/pages/Ministry/common/MinistriesTabsSearch/MinistriesTabsSearch";
import { Statuses } from "SRC/pages/Ministry/common/Statuses/Statuses";
import { useEventsTableData } from "SRC/pages/Ministry/tableData/useEventsTableData";
import { useFilters } from "SRC/redux/slices/ministry/hooks/useFilters";
import { MINISTRY_EVENT_VIEW, setParamsToUrl } from "SRC/urls";

import { FilterFeatures } from "../filters/FilterFeatures";
import { FilterNameStructure } from "../filters/FilterNameStructure";

const RenderTable = () => {
  const theme = useTheme();
  const columns: Column[] = useMemo(() => getEventsColumns(), []);
  const selectStyle = css.selector(theme);
  const { eventsTableData, eventsStatuses } = useEventsTableData();
  const navigate = useNavigate();
  const {
    tableState: initialState,
    setTableState,
    setSelectedEventFeature,
    setSelectedStructureElementTask,
    setSelectedStructureElementName,
    setSelectedStructureElementType,
    selectedStructureElementType,
  } = useFilters();
  const [instance, setInstance] = useState<TableInstance | undefined>(
    undefined
  );
  const [searchValue, setSearchValue] = useState<string | undefined>();

  const setTableFilter = useCallback(
    (value?: string) => {
      instance?.setGlobalFilter(value);
      setSearchValue(value);
    },
    [instance]
  );

  const handleClearFilters = () => {
    setSelectedStructureElementType("");
    setSelectedStructureElementName("");
    setSelectedStructureElementTask("");
    setSelectedEventFeature("");
    setTableFilter("");
  };

  useEffect(() => {
    handleClearFilters();
  }, []);

  const handleOnClick = useCallback((id: number | string) => {
    return navigate(
      setParamsToUrl(MINISTRY_EVENT_VIEW, { id, ministryId: "01" })
    );
  }, []);

  return (
    <Box sx={css.modalContainer}>
      <Box sx={css.searchSelectContainer}>
        <Grid item xs={3} lg={2} xl={3} style={{ flex: 1 }}>
          <MinistriesTabsSearch value={searchValue} onChange={setTableFilter} />
        </Grid>
        <FilterFeatures
          label="Признак мероприятия"
          staticLabel={false}
          style={selectStyle}
          onChange={setSelectedEventFeature}
        />
        <SelectWithSearchStructure
          label="Тип структурного элемента"
          staticLabel={false}
          style={selectStyle}
          onChange={setSelectedStructureElementType}
          value={selectedStructureElementType}
        />
        <FilterNameStructure
          label="Наименование структурного элемента"
          staticLabel={false}
          style={selectStyle}
          onChange={setSelectedStructureElementName}
        />
        <FilterTaskStructures
          label="Наименование задачи"
          staticLabel={false}
          style={selectStyle}
          onChange={setSelectedStructureElementTask}
        />
        <Button sx={css.filterButton as SxProps} onClick={handleClearFilters}>
          Сбросить фильтры
        </Button>
      </Box>
      <Statuses statuses={eventsStatuses} />
      <Table
        data={eventsTableData}
        columns={columns}
        sortedColumns={[
          "Период достижения",
          "Факт",
          "План",
          "План на год",
          "Уровень достижения, %",
        ]}
        onClick={handleOnClick}
        initialState={initialState}
        getInstance={setInstance}
        getLocalState={setTableState}
      />
    </Box>
  );
};

export const EventsTab = () => {
  return <RenderTable />;
};
