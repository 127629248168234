import { styled } from "@mui/material";
import { CSSProperties } from "react";

interface ICarouselItemWrapperProps {
  itemWidth: number;
  style?: CSSProperties;
}

export const Wrapper = styled("div")<ICarouselItemWrapperProps>(
  ({ theme, itemWidth, style }) => {
    return {
      display: "inline-flex",
      height: "100%",
      width: `${itemWidth}%`,
      minWidth: `${itemWidth}%`,
      maxWidth: `${itemWidth}%`,
      ...style,

      ".incident-container": {
        width: "100%",
      },
    };
  }
);
