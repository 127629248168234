import React from "react";
import { TableProps, UseTableRowProps } from "react-table";
import NavigateToProgram from "SRC/components/NavigateToProgram/NavigateToProgram";
import { StatusTableProgram } from "SRC/components/StatusTableProgram/StatusTableProgram";
import { TableCell } from "SRC/components/TableCell";
import { COLUMNS } from "SRC/components/TableColumnTemplates/columns";

interface CustomTableProps extends TableProps {
  row: UseTableRowProps<any>;
  rows?: UseTableRowProps<any>[];
}

const getGovProgramsColumns = (isOM: boolean) => [
  {
    Header: "",
    accessor: "status-icon",
    width: 10,
    columns: [
      {
        Header: "",
        width: 10,
        accessor: "status-icon",
        Cell: (tableProps: CustomTableProps) => {
          const { secretProgram, atRisk } = tableProps.row.original;
          return (
            <TableCell className="status-icon">
              <StatusTableProgram isSecret={secretProgram} atRisk={atRisk} />
            </TableCell>
          );
        },
        Footer: "",
      },
    ],
    Footer: "",
  },
  {
    Header: "",
    accessor: "id",
    width: 120,
    sticky: "left",
    columns: [
      {
        Header: "Код ГП",
        width: 120,
        accessor: "id",
        Cell: (tableProps: CustomTableProps) => (
          <TableCell color="gray" align="center">
            {tableProps.row.original.id}
          </TableCell>
        ),
        Footer: "",
      },
    ],
    Footer: "",
  },
  {
    Header: "",
    width: "auto",
    accessor: "name",
    columns: [
      {
        Header: "Госпрограмма",
        width: "auto",
        accessor: "name",
        Cell: (tableProps: CustomTableProps) => (
          <NavigateToProgram program={tableProps.row.original} />
        ),
        Footer: <TableCell>Итого по всем госпрограммам</TableCell>,
      },
    ],
    Footer: "",
  },
  {
    Header: "Показатели",
    accessor: "indicators",
    columns: [
      { ...COLUMNS.indicators.plan(isOM) },
      { ...COLUMNS.indicators.fact(isOM) },
    ],
    Footer: "",
  },
  {
    Header: "Мероприятия",
    accessor: "events",
    columns: [
      { ...COLUMNS.events.plan(isOM) },
      { ...COLUMNS.events.fact(isOM) },
      { ...COLUMNS.events.risk(isOM) },
    ],
    Footer: "",
  },
  {
    Header: "",
    accessor: isOM ? "performanceOM" : "performance",
    width: 240,
    columns: [{ ...COLUMNS.performance.factMain(isOM) }],
  },
];

export { getGovProgramsColumns };
