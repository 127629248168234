import { Box, Grid } from "@mui/material";
import React from "react";

import { css } from "./LayoutNew.styles";

interface ILayoutNewProps {
  left: React.ReactNode;
  center: React.ReactNode;
  right: React.ReactNode;
  isProgramLayout?: boolean;
  atPage?: boolean;
}
export const LayoutNew = ({
  left,
  center,
  right,
  isProgramLayout,
  atPage,
}: ILayoutNewProps) => {
  return (
    <Grid container sx={css.container(atPage)}>
      <Grid item xs={12} lg={4} xl={6} sx={css.center}>
        {center}
      </Grid>
      <Grid item xs={12} lg={4} xl={3} sx={css.right(isProgramLayout)}>
        <Box sx={css.gridWrapper}>{right}</Box>
      </Grid>
      <Grid item xs={12} lg={4} xl={3} sx={css.left}>
        <Box sx={css.gridWrapper}>{left}</Box>
      </Grid>
    </Grid>
  );
};
