import { Theme } from "@mui/material";

export const css = {
  item: (theme: Theme) => ({
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "left",
    gap: "0.5rem",
    padding: "1rem 2rem",
    minHeight: "9rem",
    maxHeight: "9rem",
    marginRight: "0.5rem",
    fontSize: "1.375rem",
    fontWeight: 700,
    border: `0.25rem solid ${theme.palette.complementary.darkBlue}`,
    borderRadius: "1.5rem",
    background: theme.palette.complementary.darkBlueLight,
    lineHeight: "130%",
    cursor: "pointer",
    direction: "ltr",
  }),
  name: (active: boolean) => ({
    color: (theme: Theme) =>
      active
        ? theme.palette.primary.contrastText
        : theme.palette.complementary.grayBlue,
    fontWeight: 700,
    lineHeight: "1.2em",
    transition: "0.2s ease-in",
  }),
  unit: {
    fontSize: "1rem",
    color: (theme: Theme) => theme.palette.complementary.grayBlue,
  },
  point: (active: boolean) => ({
    backgroundColor: (theme: Theme) =>
      active
        ? theme.palette.primary.contrastText
        : theme.palette.complementary.grayBlue,
    display: "block",
    width: "1rem",
    height: "1rem",
    border: (theme: Theme) =>
      `0.25rem solid ${theme.palette.complementary.darkBlue}`,
    borderRadius: "50%",
    position: "absolute",
    right: "-0.6rem",
    top: "50%",
    transform: "translateY(-50%)",
    transition: "0.2s ease-in",
  }),
};
