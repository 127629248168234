import icon from "ASSETS/svg/iconPhotoTable.svg";
import React from "react";
import { TableCell } from "SRC/components/TableCell";
import { usePremiers } from "SRC/redux/slices/summary/hooks/usePremiers";

import { Wrapper } from "./PremierInfo.styled";

interface IPremierInfoProps {
  premierCode: string;
  short?: boolean;
}
export const PremierInfo = ({
  premierCode,
  short = false,
}: IPremierInfoProps) => {
  const { getItemByCode } = usePremiers();

  const { info: { VP_PHOTO_URL: photo = "", VP_NAME: name = "" } = {} } =
    getItemByCode(premierCode) || {};

  return (
    <Wrapper>
      {!short && (
        <>
          <div
            className="icon"
            style={{
              backgroundImage: `url("${photo}"), url("${icon}")`,
            }}
          />
          <TableCell>{name}</TableCell>
        </>
      )}
      {short && <TableCell color="gray">{name}</TableCell>}
    </Wrapper>
  );
};
