import { useTheme } from "@mui/material";
import React, { useMemo, useState } from "react";
import { Column } from "react-table";
import Table from "SRC/components/Table";
import { PhotosModalWrapper } from "SRC/pages/Event/common/EventTabs/ObjectTab/PhotosModal/PhotosModalWrapper";
import { ITableObject } from "SRC/pages/Event/common/EventTabs/ObjectTab/util/interfaces";
import { getObjectColumns } from "SRC/pages/Event/common/EventTabs/ObjectTab/util/objectTableData";
import { VideosModalWrapper } from "SRC/pages/Event/common/EventTabs/ObjectTab/VideosModal/VideosModalWrapper";
import { useFilters } from "SRC/redux/slices/event/hooks/useFilters";

interface IObjectTabProps {
  data: ITableObject[];
}

export const ObjectTab = ({ data }: IObjectTabProps) => {
  const theme = useTheme();

  const [isPhotoModalOpen, setPhotoModalOpen] = useState(false);
  const [isVideoModalOpen, setVideoModalOpen] = useState(false);

  const { selectedObjectId, setSelectedObjectId } = useFilters();

  const onPhotoClick = (id: string) => {
    setSelectedObjectId(id);
    setPhotoModalOpen(true);
  };

  const onVideoClick = (id: string) => {
    setSelectedObjectId(id);
    setVideoModalOpen(true);
  };

  // @ts-ignore
  const columns: Column[] = useMemo(
    () => getObjectColumns(onPhotoClick, onVideoClick),
    [theme]
  );

  const handlePhotoModalClose = () => {
    setPhotoModalOpen(false);
    setSelectedObjectId("");
  };
  const handleVideoModalClose = () => {
    setSelectedObjectId("");
    setVideoModalOpen(false);
  };

  return (
    <>
      <Table
        columns={columns}
        data={data}
        sortedColumns={[
          "Регион/город",
          "Мощность",
          "Строительная готовность",
          "Период реализации",
        ]}
        tableHeight={"31rem"}
        noDataCondition={!data.length}
        classes={{ tbody: "table-body", tfoot: "table-footer" }}
      />
      {selectedObjectId && (
        <>
          <PhotosModalWrapper
            isPhotoModalOpen={isPhotoModalOpen}
            handlePhotoModalClose={handlePhotoModalClose}
          />
          <VideosModalWrapper
            isVideoModalOpen={isVideoModalOpen}
            handleVideoModalClose={handleVideoModalClose}
          />
        </>
      )}
    </>
  );
};
