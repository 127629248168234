import icon from "ASSETS/svg/iconPhotoTable.svg";
import React from "react";
import { IMinistriesTableItem } from "SRC/hooks/summaryTable/interfaces";

import { TableCell } from "../TableCell";
import { Wrapper } from "./MinistryInfo.styled";

interface INavigateToMinistryProps {
  ministry: IMinistriesTableItem;
}
const MinistryInfo = ({ ministry }: INavigateToMinistryProps) => {
  return (
    <Wrapper>
      <div
        className="icon"
        style={{
          backgroundImage: `url("${ministry.iconUrl}"), url("${icon}")`,
        }}
      />
      <TableCell>{ministry.name}</TableCell>
    </Wrapper>
  );
};

export default MinistryInfo;
