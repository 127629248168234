import React, { useContext, useMemo } from "react";
import { isSecret } from "SRC/helpers/dataTesters";
import { selectors, useAppSelector } from "SRC/redux/hooks";
import {
  IProgram,
  IPrograms,
  usePrograms,
} from "SRC/redux/slices/programsBoard/hooks/usePrograms";
import {
  ISummaryProps,
  useSummary,
} from "SRC/redux/slices/programsBoard/hooks/useSummary";

interface IStatus {
  hasError: boolean;
  loading: boolean;
}

export interface IController {
  programs: IPrograms;
  summary: ISummaryProps;
  filteredPrograms: (string | number)[];
  status: IStatus;
}

const ControllerApi = React.createContext<IController>({
  // @ts-ignore
  programs: {},
  // @ts-ignore
  summary: {},
  filteredPrograms: [],
  status: {
    loading: false,
    hasError: false,
  },
});

export const useGetPrograms = (): IPrograms =>
  useContext(ControllerApi).programs;
export const useGetSummary = (): ISummaryProps =>
  useContext(ControllerApi).summary;
export const useFilteredPrograms = (): IController["filteredPrograms"] =>
  useContext(ControllerApi).filteredPrograms;

interface IControllerProps {
  children?: React.ReactNode;
}

const Controller = ({ children }: IControllerProps) => {
  const { withSecret } = useAppSelector(selectors.globalFilters);
  const summary = useSummary();
  const { fetching: loadingSummary, error: errorHeaderProgram } = summary;
  const programs = usePrograms();
  const { fetching: loadingPrograms, error: errorPrograms } = programs;

  const status = useMemo(
    () => ({
      loading: loadingPrograms || loadingSummary,
      hasError: errorPrograms || errorHeaderProgram,
    }),
    [loadingPrograms, loadingSummary, errorPrograms, errorHeaderProgram]
  );

  const filteredPrograms = useMemo(
    () =>
      programs.data
        .filter(({ info, data }) => withSecret || !isSecret(info))
        .map(({ info: { code } }) => code),
    [programs, withSecret]
  );

  const context = useMemo(
    () => ({
      programs,
      summary,
      filteredPrograms,
      status,
    }),
    [programs, summary, filteredPrograms, status]
  );

  return (
    <ControllerApi.Provider value={context}>{children}</ControllerApi.Provider>
  );
};

export default Controller;

export type { IProgram, IPrograms, ISummaryProps };
