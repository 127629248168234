import { Theme } from "@mui/material";

export const css = {
  statusIcon: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "0.3rem",
    width: 0,
    maxWidth: 0,
    height: "100%",
    padding: "0.3rem 0",

    "> *": {
      position: "relative",
      left: "-0.5rem",
      width: "1.2rem",
      minWidth: "1.2rem",
      height: "1.2rem",
      minHeight: "1.2rem",
    },
  },

  centered: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "0.5rem",
  },

  centeredRow: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    gap: "0.5em",
  },

  contained: {
    width: "100%",
    maxWidth: "100%",
    height: "100%",
    maxHeight: "100%",
    verticalAlign: "middle",
  },

  navGPButton: {
    padding: "0.2rem",
    fontSize: "0.8rem !important",
    color: (theme: Theme) => theme.palette.primary.contrastText,
    background: (theme: Theme) => theme.palette.main.blue,
    borderRadius: "0.5rem",
    lineHeight: "1.5rem !important",
    textTransform: "capitalize",
  },

  tableCellText:
    (statusLabel?: string, statusColored?: boolean) => (theme: Theme) =>
      ({
        flex: 1,
        fontSize: "1rem",
        lineHeight: "1.25rem",
        fontWeight: theme.typography.fontWeightRegular,
        padding: "0.5rem 0",
        userSelect: "none",

        "&.with-right-border": {
          "&:after": {
            height: "3rem",
            content: "''",
            borderLeft: `1px solid ${theme.palette.complementary.lightViolet}`,
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
            left: "100%",
          },
        },
        "&.with-dotted-border": {
          "&:after": {
            height: "0.3rem",
            content: "''",
            borderLeft: `4px solid ${theme.palette.main.darkBlue}`,
            position: "absolute",
            top: "50%",
            borderRadius: "50%",
            transform: "translateY(-50%)",
            left: "100%",
          },
        },
        "&.with-left-border": {
          "&:after": {
            height: "3rem",
            content: "''",
            borderLeft: `1px solid ${theme.palette.complementary.lightViolet}`,
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
            left: "0%",
          },
        },
        "&.colorWhite": {
          color: theme.palette.text.onAccent,
        },
        "&.colorRed": {
          color: theme.palette.error.main,
        },
        "&.colorGreen": {
          color: theme.palette.success.main,
        },
        "&.colorGray": {
          color: theme.palette.main.gray,
        },
        "&.colorBlue": {
          color: theme.palette.text.opinion,
        },
        "&.bigText": {
          fontSize: "1.1rem",
          lineHeight: "1.2rem",
          color:
            statusLabel === "completed"
              ? theme.palette.success.main
              : statusColored
              ? theme.palette.error.main
              : statusLabel === "Gr"
              ? theme.palette.main.gray
              : "",
        },
      } as const),
};
