import { useMediaQuery, useTheme } from "@mui/material";
import { Equalizer } from "SRC/components/Charts";
import GraphSwitcher from "SRC/components/GraphSwitcher/GraphSwitcher";
import { ComplexGrid } from "SRC/pages/Main/common/ComplexGrid/ComplexGrid";
import { Effectiveness } from "SRC/pages/Main/common/GovPrograms/Effectiveness/Effectiveness";
import { useFilters } from "SRC/redux/slices/main/hooks/useFilters";
import themeConfig from "SRC/theme";

import { Wrapper } from "./GovPrograms.styled";

const GovPrograms = () => {
  const theme = useTheme<typeof themeConfig>();
  const match = useMediaQuery(theme.breakpoints.up("lg"));
  const { viewChart } = useFilters();

  return (
    <Wrapper>
      {!match && <GraphSwitcher withRightFilters />}

      <div className="container">
        {viewChart === 1 && !match && <Effectiveness />}
        {viewChart === 2 && <Equalizer />}
        {viewChart === 3 && <ComplexGrid />}
      </div>
    </Wrapper>
  );
};

export default GovPrograms;
