import { styled } from "@mui/material";

export const Wrapper = styled("div")(({ theme }) => {
  const {
    primary: { contrastText },
    complementary: { darkBlue, lightWhite },
    text: { onAccent, primary },
    main: { gray },
  } = theme.palette;

  return {
    display: "flex",
    flex: "0 1 auto",
    justifyItems: "stretch",
    gap: "1.5rem",

    ".widget": {
      display: "flex",
      flex: 1,
      minWidth: "fit-content",
      minHeight: "fit-content",
      flexDirection: "column",
      justifyContent: "space-between",
      gap: "1.5rem",
      padding: "1.5rem",
      borderRadius: "0.8rem",
      background: darkBlue,

      "&.static": {
        flex: "0 0 auto",
      },

      "&.large": {
        flex: 1.5,
      },
    },
    ".valuesBox": {
      display: "flex",
      flexDirection: "column",
      gap: "0.5rem",
    },
    ".achievement": {
      display: "flex",
      alignItems: "baseline",
      gap: "0.5rem",

      "&-value": {
        color: onAccent,
        lineHeight: "0.9em",

        "&.big": {
          fontSize: "2.5rem",
          fontWeight: "bold",
        },
        "&.small": {
          fontSize: "1.5rem",
        },
        "&.percent": {
          color: primary,
          fontSize: "1rem",
        },
      },
    },
    ".curator": {
      display: "flex",
      flexDirection: "column",
      gap: "2rem",

      "&-item": {
        display: "flex",
        alignItems: "center",
        gap: "0.5rem",
      },

      "&-photo": {
        display: "flex",
        "& img": {
          width: "2rem",
          height: "2rem",
          borderRadius: "50%",
          border: `2px solid ${gray}`,
        },
      },

      "&-title": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      },

      "&-text": {
        fontSize: "0.875rem",
        lineHeight: "1.125rem",
        color: gray,
      },

      "&-name": {
        fontWeight: "bold",
        lineHeight: "1rem",
        fontSize: "1rem",
        color: contrastText,
      },
    },

    ".value": {
      "&-container": {
        display: "flex",
        gap: "2rem",
      },

      "&-item": {
        display: "flex",
        flexDirection: "column",
        flex: 1,
        gap: "1.5rem",
      },

      "&-title": {
        fontSize: "1.25rem",
        fontWeight: 400,
        color: contrastText,
        display: "flex",
        alignItems: "center",
        gap: "0.5rem",
      },

      "&-unitTitle": {
        fontSize: "0.875rem",
        color: lightWhite,
      },
    },
  };
});
