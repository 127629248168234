import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { ESources } from "SRC/constants/globals";

export interface IPeriod {
  value: null | Date;
  filterValue: string;
  apiV2: string;
  year: string;
  reportValue: string;
  maxDate: Date;
  calendarDate: Date | null;
}

export interface IDate {
  value: null | Date;
}

export type TSourcesState = Record<ESources, boolean>;

export interface IFiltersState {
  period: IPeriod;
  date: IDate;
  sources: TSourcesState;
  withSecret: boolean;
  inPerformance: boolean;
  isUnder: boolean;
}

const initialState: IFiltersState = {
  period: {
    value: new Date(),
    filterValue: `${moment().year()}00`,
    apiV2: `${moment().year()}`,
    year: `${moment().year()}`,
    reportValue: `${moment().year()}`,
    maxDate: moment(new Date()).endOf("year").toDate(),
    calendarDate: new Date(),
  },
  date: {
    value: new Date("2022-12-31"),
  },
  sources: {
    [ESources.FOIV]: true,
    [ESources.OM]: false,
  },
  withSecret: false,
  inPerformance: false,
  isUnder: false,
};

const slice = createSlice({
  name: "filtersGlobal",
  initialState,
  reducers: {
    clear: () => initialState,
    selectPeriod: (state, action: { payload: IPeriod }) => {
      state.period = action.payload;
    },
    selectDate: (state, action: { payload: IDate }) => {
      state.date = action.payload;
    },
    setSources: (state, action: { payload: TSourcesState }) => {
      state.sources = action.payload;
    },
    toggleSecret: (state, action: { payload: boolean }) => {
      state.withSecret = action.payload;
    },
    setInAchievement: (state, action: { payload: boolean }) => {
      state.inPerformance = action.payload;
    },
    setIsUnder: (state, action: { payload: boolean }) => {
      state.isUnder = action.payload;
    },
  },
});

export default slice;
