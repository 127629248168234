import { Box } from "@mui/material";
import React from "react";
import { ISourceValue } from "SRC/constants/globals";
import { LineProgress } from "SRC/pages/Program/common/GovProgramInfoWidget/common/LineProgress/LineProgress";

import { IStructureElement, TPercentValue } from "../EffectiveInfo";
import { css } from "../EffectiveInfo.styled";

interface ILineProgressComponentProps {
  structureProgresses: JSX.Element[];
  structureValues: ISourceValue[];
  structureElements: {
    elements: IStructureElement[];
    total: number;
    done: number;
    units: string;
    percentage: TPercentValue;
    percentageRadial: TPercentValue;
    name: string;
  };
}

export const LineProgressComponent = ({
  structureElements,
  structureValues,
  structureProgresses,
}: ILineProgressComponentProps) => {
  return (
    <Box>
      <Box sx={css.titleAchievement}>Уровень достижения СЭ</Box>
      <Box sx={css.lines}>
        <LineProgress
          name={structureElements.name}
          value={structureElements.total}
          percents={structureValues}
          isMain
        />
        {structureProgresses}
      </Box>
    </Box>
  );
};
