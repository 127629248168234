import { Box, styled, Theme } from "@mui/material";

export const SearchContainer = styled(Box)(({ theme }) => ({
  flex: 1,
  borderRadius: "1rem",

  [theme.breakpoints.up("lg")]: {
    padding: "1rem",
  },
}));

export const inputStyled = {
  filledInput: {
    fontSize: "1rem",
    "::before, ::after": {
      borderBottom: "none",
    },
  },
};

export const css = {
  inputSearch: {
    color: (theme: Theme) => theme.palette.main.gray,
    width: "1.25rem",
    height: "1.25rem",
    marginLeft: "10px",
  },
  uiSearch: {
    width: "100%",
    maxWidth: (theme: Theme) => theme.spacing(150),
    "& .MuiFilledInput-root": {
      height: "2.5rem",
      padding: "0 1rem",
      overflow: "hidden",
      borderRadius: "5rem",
      background: (theme: Theme) => theme.palette.background.paper,

      "&:hover": {
        borderBottom: "none",
        background: (theme: Theme) => theme.palette.background.paper,

        "&::before": {
          borderBottom: "none !important",
        },
      },

      "&::before": {
        borderBottom: "none",
      },

      "&::after": {
        borderBottom: "none",
      },
    },

    "& .MuiFilledInput-input": {
      p: 0,
    },

    "& .MuiInputAdornment-root": {
      margin: "0 0.5rem 0 0 !important",
    },
  },
};
