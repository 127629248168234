import React, { CSSProperties, useMemo } from "react";
import { Wrapper } from "SRC/components/CarouselNew/CarouselItem/CarouselItem.styled";
import { IncidentReport } from "SRC/components/IncidentReport";
import { useSingleAndDoubleClick } from "SRC/hooks";

interface ICarouselItemProps {
  children: React.ReactNode;
  visibleItems?: number;
  style?: CSSProperties;
  onClick?: () => void;
  onDoubleClick?: () => void;
}

export const CarouselItem = ({
  children,
  visibleItems,
  style,
  onClick,
  onDoubleClick,
}: ICarouselItemProps) => {
  const itemWidth = useMemo(() => {
    return visibleItems ? 100 / visibleItems : 100;
  }, [visibleItems]);

  const click = useSingleAndDoubleClick(onClick, onDoubleClick);

  return (
    <Wrapper itemWidth={itemWidth} style={style} onClick={click}>
      <IncidentReport className="incident-container">{children}</IncidentReport>
    </Wrapper>
  );
};
