import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { isContainedArray } from "SRC/helpers/dataTesters";
import { IPeriod } from "SRC/redux/slices/global/slices/filters";
import {
  getAllPremiersInfo,
  getPremiersTotals,
} from "SRC/redux/slices/summary/api";
import { ICodeItem } from "SRC/types";

export interface IPremier {
  VP_NAME: string;
  VP_CODE: string;
  VP_PHOTO_URL: string;
  VP_SHORT_NAME: string;
  VP_FOIV: string;
  code: string;
  data: ICodeItem[];
}

export interface IPremiersState {
  items: IPremier[];
  totals: ICodeItem[];
  fetching: boolean;
  fetched: boolean;
  error: string;
}

export const extraActions = {
  get: createAsyncThunk(
    "premiers-full/get",
    async (period: IPeriod) => await getAllPremiersInfo(period)
  ),
  getTotals: createAsyncThunk(
    "premiers-totals/get",
    async (period: IPeriod) => await getPremiersTotals(period)
  ),
};

const initialState: IPremiersState = {
  items: [],
  totals: [],
  fetching: false,
  fetched: false,
  error: "",
};

export const slice = createSlice({
  name: "premiersFull",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActions.get.pending, (state) => {
        state.items = [];
        state.fetching = true;
        state.fetched = false;
      })
      .addCase(extraActions.get.fulfilled, (state: IPremiersState, action) => {
        const data = action.payload.data as unknown as IPremier[];
        state.items = isContainedArray(data) ? data : [];
        state.fetching = false;
        state.fetched = true;
      })
      .addCase(extraActions.get.rejected, (state, action) => {
        state.fetching = false;
        // state.error = action.error;
      })
      .addCase(extraActions.getTotals.pending, (state) => {
        state.totals = [];
        state.fetching = true;
        state.fetched = false;
      })
      .addCase(
        extraActions.getTotals.fulfilled,
        (state: IPremiersState, action) => {
          const data = action.payload.data as unknown as ICodeItem[];
          state.totals = isContainedArray(data) ? data : [];
          state.fetching = false;
          state.fetched = true;
        }
      )
      .addCase(extraActions.getTotals.rejected, (state, action) => {
        state.fetching = false;
        // state.error = action.error;
      });
  },
});

export const actions = {
  ...slice.actions,
  ...extraActions,
};
