import { Box, Tooltip } from "@mui/material";
import React, { ReactNode } from "react";
import { ISourceSettings } from "SRC/constants/globals";

import { css } from "./SourceValue.styled";

export interface ISourceValue {
  value?: number | null;
  settings: ISourceSettings;
  fontSize?: string | number;
  rounded?: boolean;
  unit?: boolean | string;
  isPercent?: boolean;
  children?: React.ReactNode;
}

export const SourceValue: React.FC<ISourceValue> = ({
  value,
  settings,
  fontSize,
  rounded,
  unit,
  isPercent = false,
  children,
}) => {
  if (!Number.isFinite(value)) {
    return null;
  }

  return (
    <Tooltip title={`По данным ${settings.title}`}>
      <Box sx={css.text(settings.valueColor)} style={{ fontSize }}>
        {children ? children : value}
        {unit && <Box sx={css.unit}>{unit === true ? "%" : unit}</Box>}
      </Box>
    </Tooltip>
  );
};
