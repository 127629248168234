import { SerializedError } from "@reduxjs/toolkit";
import { useCallback, useMemo, useState } from "react";
import { ITopMinistries } from "SRC/pages/Summary/interfaces";
import { IPeriod } from "SRC/redux/slices/global/slices/filters";

import { useAppDispatch, useAppSelector } from "../../../hooks";
import actions from "../actions";

export interface IMinistriesTopWorst {
  fetching: boolean;
  fetched: boolean;
  items: ITopMinistries[];
  load(period: IPeriod): void;
  error: SerializedError | null;
}

export const useTopWorstMinistries = (): IMinistriesTopWorst => {
  const dispatch = useAppDispatch();
  const {
    fetching,
    items: ministriesTopWorst = [],
    fetched,
  } = useAppSelector((state) => state.summary.topWorstMinistries);
  const [error, setError] = useState<SerializedError | null>(null);

  const sortedItems = useMemo(
    () =>
      [...ministriesTopWorst].sort(
        (a, b) => Number(b.data[0].sum) - Number(a.data[0].sum)
      ),
    [ministriesTopWorst]
  );

  const load = useCallback(
    (period: IPeriod) => {
      if (!fetching) {
        dispatch(actions.topWorstMinistries.get(period)).then((action) => {
          if (actions.topWorstMinistries.get.rejected.match(action)) {
            setError(action.error);
          }
          return action;
        });
      }
    },
    [dispatch, fetching]
  );

  return {
    fetching,
    fetched,
    items: sortedItems,
    load,
    error,
  };
};
