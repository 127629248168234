import React from "react";
import { Column } from "react-table";
import { IncidentReport } from "SRC/components/IncidentReport";
import Table from "SRC/components/Table";
import { IRelatedIndicatorsDataItem } from "SRC/pages/Event/interfaces";

import { Wrapper } from "./RelatedIndicators.styled";

interface IProps {
  columns: Column[];
}

export const RelatedIndicators: React.FC<IProps> = ({ columns }) => {
  // const data = useMemo<IRelatedIndicatorsDataItem[]>(
  //   () =>
  //     relatedIndicatorsData?.map((item: IRelatedIndicatorsDataItem) => ({
  //       id: item.id,
  //       indicatorName: item.indicatorName,
  //       performance: item.performance,
  //       effect: item.effect,
  //     })),
  //   [relatedIndicatorsData]
  // );

  const data: IRelatedIndicatorsDataItem[] = [];

  return (
    <Wrapper>
      <IncidentReport className="incident-container">
        <Table
          columns={columns}
          data={data}
          sortedColumns={["План", "Факт"]}
          tableHeight={"31rem"}
          noDataCondition={data?.length === 0}
        />
      </IncidentReport>
    </Wrapper>
  );
};
