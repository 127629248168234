import classNames from "classnames";
import React, { useEffect, useState } from "react";

import { ValueWrapper } from "./Value.styled";

interface IValueProps {
  value: string | number | null;
}

export const Value = ({ value }: IValueProps) => {
  return (
    <ValueWrapper className={classNames({ "is-null": value === null })}>
      {value === null ? 0 : value}
    </ValueWrapper>
  );
};
