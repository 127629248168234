import { styled } from "@mui/material";

type TEffectiveItemContainer = {
  active: boolean;
};

export const EffetivenessContainer = styled("div")(({ theme }) => {
  return {
    gap: "1rem",
    display: "flex",

    ".generalEffect": {
      position: "relative",
      maxWidth: "16rem",
      minHeight: "21rem",
      padding: "1rem 1.5rem",
      borderRadius: "1rem",
      flex: "1 0 15%",
      gap: "0.25rem",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      background: theme.palette.background.paper,
    },

    ".chart": {
      height: "11.25rem",
      width: "11.25rem",
      padding: "0.3rem",
      borderRadius: "50%",
      display: "flex",
      background: theme.palette.charts.darkBlueBackground,
    },

    ".title": {
      fontSize: "1.25rem",
      fontWeight: 400,
    },

    ".effectsContainer": {
      flex: 1,
      gap: "1rem",
      display: "flex",
      flexWrap: "wrap",

      "& > .effective-item": {
        maxHeight: "50%",
        width: "calc(25% - 1rem)",
        maxWidth: "calc(25% - 1rem)",
        padding: "1rem 1.5rem",
        borderRadius: "1rem",
        flexGrow: "1",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        background: theme.palette.background.paper,
        transition: "0.1s ease-in",
        cursor: "pointer",
      },
    },
  };
});

export const EffectiveItemContainer = styled("div")<TEffectiveItemContainer>(
  ({ theme, active }) => {
    return {
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",

      ".title": {
        fontSize: "1.25rem",
        fontWeight: 400,
      },

      ".num": {
        width: "3ch",
        fontSize: "1.125rem",
        fontWeight: 500,
      },

      ".itemTop": {
        gap: "0.25rem",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      },

      ".itemBottom": {
        gap: "0.5rem",
        display: "flex",
        flexDirection: "column",
      },

      ".barContainer": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-end",
      },

      ".barTitle": {
        fontSize: "1rem",
        fontWeight: 300,
      },

      ".barUnit": {
        gap: "0.3rem",
        display: "flex",
        alignItems: "baseline",
        fontSize: "2rem",
        fontWeight: 400,

        "& > span": {
          fontSize: "1rem",
        },
      },

      "& .performance-value": {
        height: "1rem",
      },
    };
  }
);
