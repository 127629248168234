import { ICustomTableProps } from "SRC/types";

export const getEventsFailTotal = (
  tableProps: ICustomTableProps,
  isOM: boolean
) => {
  let { eventsFailTotal, eventsOMFailTotal } =
    tableProps?.rows?.[0]?.original || {};

  return {
    eventsFailTotal: !isOM ? eventsFailTotal : eventsOMFailTotal,
  };
};
