import { SerializedError } from "@reduxjs/toolkit";
import { useCallback, useEffect } from "react";
import { useGlobalFilters } from "SRC/redux/slices/global/hooks/useFilters";
import { useFilters } from "SRC/redux/slices/indicator/hooks/useFilters";
import { useIndicatorData } from "SRC/redux/slices/indicator/hooks/useIndicatorData";
import { useIndicatorGraph } from "SRC/redux/slices/indicator/hooks/useIndicatorGraph";

export type TIndicator = {
  loading: boolean;
  loaded: boolean;
  init(): void;
  hasError: SerializedError | null;
};

export const useIndicator = (): TIndicator => {
  const { period } = useGlobalFilters();
  const { selectedIndicatorId } = useFilters();
  const {
    load: loadData,
    fetched: loadedData,
    fetching: loadingData,
  } = useIndicatorData();
  const {
    load: loadGraph,
    fetched: loadedGraph,
    fetching: loadingGraph,
  } = useIndicatorGraph();

  const loading = loadingData || loadingGraph;
  const loaded = loadedData && loadedGraph;
  const hasError = null;
  const init = useCallback(() => {
    if (!loading) {
      loadData({ id: selectedIndicatorId, period });
      loadGraph({ id: selectedIndicatorId, period });
    }
  }, [selectedIndicatorId, loading, period, loadData, loadGraph]);

  useEffect(() => {
    if (selectedIndicatorId) init();
  }, [selectedIndicatorId, period]);

  return {
    loading,
    loaded,
    init,
    hasError,
  };
};
