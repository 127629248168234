import React from "react";

type TTrassirVideo = {
  url: string;
};
export const TrassirVideo = ({ url }: TTrassirVideo) => {
  return (
    <iframe
      src={url}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowFullScreen
      title="Embedded"
    />
  );
};
