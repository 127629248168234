import { styled, Theme } from "@mui/material";

export const Wrapper = styled("div")(({ theme }) => {
  const {
    text: { primary: gray, onAccent: white },
  } = theme.palette;

  return {
    display: "inline-block",
    maxWidth: "80ch",

    a: {
      color: white,
      cursor: "pointer",
      textDecoration: "none",

      ".lined-text": {
        cursor: "pointer",
      },

      ":active": {
        textDecoration: "none",
        color: gray,
      },
    },

    span: {
      fontSize: "1rem",
      color: gray,
    },
  };
});

export const css = {
  link: {
    fontSize: "1rem",
    ":hover": {
      textDecoration: "underline",
    },
    color: (theme: Theme) => theme.palette.text.onAccent,
  },
};
