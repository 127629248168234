import { getIsSecret } from "SRC/pages/Summary/common/SummaryComplexGrid/utils";
import { ICustomTableProps } from "SRC/types";

export const getEventsFact = (tableProps: ICustomTableProps, isOM: boolean) => {
  const { eventsFact, eventsOMFact } = tableProps.row.original;

  return {
    eventsFact: isOM ? eventsOMFact : eventsFact,
    isSecret: getIsSecret(tableProps),
  };
};
