import React from "react";
import { StatusLabel } from "SRC/components/StatusLabel/StatusLabel";
import { IMinistriesTableItem } from "SRC/hooks/summaryTable/interfaces";
import { StructureCount } from "SRC/pages/Ministry/StrucutreElements/StructureCount/StructureCount";
import { StructureEvents } from "SRC/pages/Ministry/StrucutreElements/StructureEvents/StructureEvents";

import { Wrapper } from "./StructureElements.styled";

interface IMinistriesTableItemProps {
  ministryData: IMinistriesTableItem[];
}

export const StructureElements = ({
  ministryData,
}: IMinistriesTableItemProps) => {
  return (
    <Wrapper className="structures">
      <div>Структурные элементы</div>
      <div className="structures-info">
        <div className="structures-count">
          Количество СЭ
          <StructureCount ministryData={ministryData} />
        </div>
        <div className="structures-events">
          <div className="structures-header">
            Мероприятия
            <div>
              <StatusLabel
                label="done"
                name="Выполнено"
                className="status-name"
              />
              <StatusLabel
                label="fail"
                name="Не выполнено"
                className="status-name"
              />
            </div>
          </div>
          <StructureEvents />
        </div>
      </div>
    </Wrapper>
  );
};
