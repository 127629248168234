import { Theme } from "@mui/material";

export const css = {
  container: {
    display: "flex",
    flex: 1,
    height: "100%",
    overflowY: "overlay",
    padding: "1rem",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flex: 1,
    width: "100%",
    maxWidth: "100%",
    fontSize: "1rem",
    lineHeight: "1.2em",

    hr: {
      width: "100%",
      border: "none",
      borderBottom: (theme: Theme) =>
        `1px solid ${theme.palette.background.opacityWhite}`,
    },
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "baseline",
    gap: "1rem",
  },
  achievement: {
    display: "flex",
    alignItems: "baseline",
    gap: "0.5rem",
  },
  performanceText: {
    color: (theme: Theme) => theme.palette.text.onAccent,
    lineHeight: "0.9em",

    "&.big": {
      fontSize: "2.5rem",
      fontWeight: "bold",
    },
    "&.small": {
      fontSize: "1.5rem",
    },
    "&.percent": {
      color: (theme: Theme) => theme.palette.text.primary,
      fontSize: "1rem",
    },
  },
  valuesBox: {
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
  },
  title: {
    fontSize: "1.2rem",
    color: (theme: Theme) => theme.palette.text.onAccent,
  },
  label: {
    color: (theme: Theme) => theme.palette.text.primary,
    fontSize: "1rem",
    lineHeight: "1.2rem",
  },
  valueRow: {
    display: "flex",
    color: (theme: Theme) => theme.palette.text.onAccent,
    fontWeight: "bold",
    alignItems: "baseline",
  },
  value: {
    fontSize: "1.2rem",
    lineHeight: "1em",
    fontWeight: "bold",

    "&.red": {
      color: (theme: Theme) => theme.palette.error.main,
    },
    "&.green": {
      color: (theme: Theme) => theme.palette.success.main,
    },
    "&.white": {
      color: (theme: Theme) => theme.palette.text.onAccent,
    },
  },
};
