import { Tooltip } from "@mui/material";
import React, { useCallback } from "react";
import { useNavigate } from "react-router";

import { Wrapper } from "./NavigateCell.styles";

interface INavigateCellProps {
  url: string;
  title?: string;
  children: React.ReactNode;
}
export const NavigateCell = ({
  url,
  title = "Перейти в карточку госпрограммы",
  children,
}: INavigateCellProps) => {
  const navigate = useNavigate();

  const handleOnClick = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      navigate(url);
    },
    [navigate, url]
  );

  return (
    <Tooltip title={title}>
      <Wrapper onClick={handleOnClick}>{children}</Wrapper>
    </Tooltip>
  );
};
