import { CircularProgress } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import { EExtensions, ESources, sourceSettings } from "SRC/constants";
import { TExcelCreator } from "SRC/hooks/use-excel-creator/useFullReportCreator";
import { useGlobalFilters } from "SRC/redux/slices/global/hooks/useFilters";
import { useProgram } from "SRC/redux/slices/gosprogram/hooks";
import { useWorkbook } from "SRC/redux/slices/gosprogram/hooks/useWorkbook";

import { TooltipText } from "../TooltipText";
import { WrapperExport } from "../TooltipText/TooltipText.styled";
import { ProgramExportButtonStyled } from "./Buttons.styles";
import { DownloadButton } from "./DownloadButton";

interface IProgramExportButtonProps {
  saveFile: TExcelCreator;
}

export const ProgramExportButton = ({
  saveFile,
}: IProgramExportButtonProps) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const { sources, period } = useGlobalFilters();
  const { items: program } = useProgram();
  const { getFile } = useWorkbook();
  const settings = useMemo(
    () => sourceSettings[sources.OM ? ESources.OM : ESources.FOIV],
    [sources]
  );

  const finishDownloading = () => {
    setIsDownloading(false);
  };

  const handleProgram = useCallback(
    (ext: EExtensions, fileName: string) => {
      saveFile({ ext, callback: finishDownloading, fileName });
    },
    [saveFile]
  );

  const handleExportODS = useCallback(async () => {
    await setIsDownloading(true);
    handleProgram(EExtensions.ODS, "tab_impl_lvl");
  }, [handleProgram]);

  const handleExportXLSX = useCallback(async () => {
    await setIsDownloading(true);
    getFile({
      period,
      tabName: "tab_impl_lvl",
      gpCode: program?.gp_code || "",
      isOm: sources.OM,
    });
    handleProgram(EExtensions.XLSX, "tab_impl_lvl");
  }, [handleProgram]);

  const hintElement = (
    <WrapperExport>
      <div>Скачать подложку по УД {settings.title}</div>
      <div className="format" onClick={handleExportODS}>
        .ods
      </div>
      <div className="format" onClick={handleExportXLSX}>
        .xlsx
      </div>
    </WrapperExport>
  );

  return (
    <ProgramExportButtonStyled>
      {isDownloading ? (
        <CircularProgress size="1rem" />
      ) : (
        <TooltipText hint={hintElement} variant="h3">
          <DownloadButton onClick={handleExportXLSX} />
        </TooltipText>
      )}
    </ProgramExportButtonStyled>
  );
};
