import { ICustomTableProps } from "SRC/types";

export const getProgramStatus = (tableProps: ICustomTableProps) => {
  const { secretProgram: isSecret, atRisk } = tableProps.row.original;

  return {
    isSecret,
    atRisk,
  };
};
