import { combineReducers, Reducer } from "@reduxjs/toolkit";
import { baseApi, oldApi } from "SRC/redux/api";
import { spheresApi } from "SRC/store/spheres";

import auth, { IAuthReducer } from "./slices/auth/reducer";
import event, { IEventReducer } from "./slices/event/reducer";
import global, { IGlobalReducer } from "./slices/global/reducer";
import gosprogram, { IGosprogramReducer } from "./slices/gosprogram/reducer";
import indicator, { IIndicatorReducer } from "./slices/indicator/reducer";
import main, { IMainReducer } from "./slices/main/reducer";
import ministry, { IMinistryReducer } from "./slices/ministry/reducer";
import priorities, { IPrioritiesReducer } from "./slices/priorities/reducer";
import programsBoard, { IProgramsBoard } from "./slices/programsBoard/reducer";
import structure, { IStructureReducer } from "./slices/structure/reducer";
import structureElement, {
  IStructureElement,
} from "./slices/structureElement/reducer";
import summary, { ISummaryReducer } from "./slices/summary/reducer";

export interface IState extends Record<string, Reducer> {
  auth: Reducer<IAuthReducer>;
  global: Reducer<IGlobalReducer>;
  main: Reducer<IMainReducer>;
  gosprogram: Reducer<IGosprogramReducer>;
  summary: Reducer<ISummaryReducer>;
  priorities: Reducer<IPrioritiesReducer>;
  programsBoard: Reducer<IProgramsBoard>;
  event: Reducer<IEventReducer>;
  structure: Reducer<IStructureReducer>;
  structureElement: Reducer<IStructureElement>;
  indicator: Reducer<IIndicatorReducer>;
  ministry: Reducer<IMinistryReducer>;
  oldApi: Reducer;
  baseApi: Reducer;
  [spheresApi.reducerPath]: Reducer;
}

const reducer = combineReducers<IState>({
  auth,
  global,
  main,
  gosprogram,
  summary,
  programsBoard: programsBoard.reducer,
  event,
  priorities,
  structure,
  structureElement: structureElement.reducer,
  indicator,
  ministry,
  [oldApi.reducerPath]: oldApi.reducer,
  [baseApi.reducerPath]: baseApi.reducer,
  [spheresApi.reducerPath]: spheresApi.reducer,
});

export default reducer;
