import { SerializedError } from "@reduxjs/toolkit";
import { useCallback, useState } from "react";
import { ISphere } from "SRC/redux/slices/main/slices/spheres";
import { IStatParams } from "SRC/redux/slices/main/slices/stats";

import { useAppDispatch, useAppSelector } from "../../../hooks";
import actions from "../actions";

export interface ISpheresProps {
  fetching: boolean;
  fetched: boolean;
  items: ISphere[];
  load(params: IStatParams): void;
  error: SerializedError | null;
}

export const useSpheres = (): ISpheresProps => {
  const dispatch = useAppDispatch();
  const {
    fetching,
    items: spheres,
    fetched,
  } = useAppSelector((state) => state.main.spheres);
  const [error, setError] = useState<SerializedError | null>(null);
  const load = useCallback(
    (params: IStatParams) => {
      if (!fetching) {
        dispatch(actions.spheres.get(params)).then((action) => {
          if (actions.spheres.get.rejected.match(action)) {
            setError(action.error);
          }
          return action;
        });
      }
    },
    [dispatch, fetching]
  );

  return {
    fetching,
    fetched,
    items: spheres || [],
    load,
    error,
  };
};

export const useSumSpheres = (): ISpheresProps => {
  const dispatch = useAppDispatch();
  const {
    fetching,
    items: sumSpheres,
    fetched,
  } = useAppSelector((state) => state.main.sumSpheres);
  const [error, setError] = useState<SerializedError | null>(null);
  const load = useCallback(
    (params: IStatParams) => {
      if (!fetching) {
        dispatch(actions.sumSpheres.get(params)).then((action) => {
          if (actions.sumSpheres.get.rejected.match(action)) {
            setError(action.error);
          }
          return action;
        });
      }
    },
    [dispatch, fetching]
  );

  return {
    fetching,
    fetched,
    items: sumSpheres || [],
    load,
    error,
  };
};
