export const isContainedArray = <K>(value: K | Array<K>): boolean =>
  Array.isArray(value) && Boolean(value.length);

export const isSecret = (data: any): boolean => {
  return (
    data?.["Секретная"] === "1" ||
    data?.info?.secret === "1" ||
    data?.secret === "1"
  );
};
