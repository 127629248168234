import React, { useMemo } from "react";
import { AtRiskCounter } from "SRC/components/AtRiskCounter/AtRiskCounter";
import { IOPValue, OverlayStackedProgress } from "SRC/components/ProgressBar";
import { ESources, sourceSettings } from "SRC/constants";

import { Wrapper } from "./StructureEvents.module";

export const StructureEvents = () => {
  const data: Record<string, IOPValue[]> = useMemo(
    () => ({
      success: [
        {
          value: 10,
          settings: sourceSettings[ESources.FOIV],
        },
      ].filter(Boolean) as IOPValue[],
      fail: [
        {
          value: 23,
          settings: sourceSettings[ESources.FOIV],
        },
      ].filter(Boolean) as IOPValue[],
    }),
    []
  );

  return (
    <Wrapper>
      <OverlayStackedProgress
        className="border progress main"
        failValues={data.success}
        successValues={data.fail}
        total={240}
        title="Всего"
        titleValue={<AtRiskCounter value={52} />}
      />
      <OverlayStackedProgress
        className="progress"
        failValues={data.success}
        successValues={data.fail}
        total={240}
        title="ФП"
        titleValue={<AtRiskCounter value={52} />}
      />
      <OverlayStackedProgress
        className="progress"
        failValues={data.success}
        successValues={data.fail}
        total={240}
        title="ВП"
        titleValue={<AtRiskCounter value={52} />}
      />
      <OverlayStackedProgress
        className="progress"
        failValues={data.success}
        successValues={data.fail}
        total={240}
        title="КПМ"
        titleValue={<AtRiskCounter value={52} />}
      />
    </Wrapper>
  );
};
