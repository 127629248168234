import { Button, useTheme } from "@mui/material";
import React, { useMemo } from "react";
import Breadcrumbs from "SRC/components/Breadcrumbs/Breadcrumbs";
import { css } from "SRC/components/FilterForms/MainHeaderFilter/MainHeaderFilter.styled";
import { IOption } from "SRC/components/SelectWithSearch/SelectWithSearch";
import SelectWithSearchVP from "SRC/components/SelectWithSearch/SelectWithSearchVP";
import { data } from "SRC/pages/VicePremier/vicePremierData";
import { IFilters, useFilters } from "SRC/redux/slices/main/hooks/useFilters";
import {
  IPremiers,
  usePremiers,
} from "SRC/redux/slices/main/hooks/usePremiers";
import themeConfig from "SRC/theme/theme";

import { Wrapper } from "./Header.styled";

export const Header = () => {
  const theme = useTheme<typeof themeConfig>();
  const { vp, setSelectedVP, filteredVPs }: IFilters = useFilters();
  const { items: vpItems }: IPremiers = usePremiers();

  const premiers: IOption[] = useMemo(
    () =>
      vpItems
        .filter((item) => filteredVPs.includes(item.info.VP_CODE))
        .map((item) => ({
          value: item.info.VP_CODE,
          label: item.info.VP_SHORT_NAME,
        })),
    [vpItems, filteredVPs]
  );
  const selectStyle = useMemo(() => css.selector(theme), [theme]);

  return (
    <Wrapper>
      <Breadcrumbs links={[]} />
      <div className="title">
        Вице-премьеры
        <SelectWithSearchVP
          value={vp}
          label="Вице-премьер"
          staticLabel={false}
          style={selectStyle}
          onChange={setSelectedVP}
          options={premiers}
        />
      </div>
      <div className="head">
        <div className="title-premiers">
          {/*icon*/}
          <div className="title-text">
            <div>{data.premierInfo.name}</div>
            <span>Куратор</span>
          </div>
        </div>
        <div className="buttons-container">
          <Button>Госпрограммы</Button>
          <Button variant="contained">Структурные элементы</Button>
        </div>
      </div>
    </Wrapper>
  );
};
