import { Tooltip } from "@mui/material";
import React from "react";
import { CheckSecretData } from "SRC/components/CheckSecretData";
import { NavigateCell } from "SRC/components/Table";
import { TableCell } from "SRC/components/TableCell";
import { sortCheckpointsFailColumn } from "SRC/helpers";
import { ICustomTableProps } from "SRC/types";

import { getCheckpointsFail, getCheckpointsFailTotal } from "./utils";

export const checkpointsFail = (isOM: boolean) => ({
  Header: "Не выполнено",
  sortType: sortCheckpointsFailColumn(isOM),
  width: 130,
  accessor: isOM ? "checkpointsOMFail" : "checkpointsFail",
  Cell: (tableProps: ICustomTableProps) => {
    const { url, checkpointsFail, isSecret } = getCheckpointsFail(
      tableProps,
      isOM
    );

    return (
      <NavigateCell url={url}>
        <TableCell color={isOM ? "paleRed" : "red"}>
          <CheckSecretData isSecret={isSecret}>
            {checkpointsFail}
          </CheckSecretData>
        </TableCell>
      </NavigateCell>
    );
  },
  Footer: (tableProps: ICustomTableProps) => {
    const { checkpointsFactTotal } = getCheckpointsFailTotal(tableProps, isOM);

    return (
      <Tooltip title="В расчете участвуют уникальные элементы">
        <TableCell color={isOM ? "paleRed" : "red"}>
          {checkpointsFactTotal}
        </TableCell>
      </Tooltip>
    );
  },
});
