import { Box, useMediaQuery, useTheme } from "@mui/material";
import { WhiteArrowLeft, WhiteArrowRight } from "ASSETS/svg/arrow";
import React, { useEffect, useMemo, useState } from "react";
import Carousel, { CarouselItem } from "SRC/components/Carousel/Carousel";
import { IncidentReport } from "SRC/components/IncidentReport";
import { OverlayBlock } from "SRC/components/OverlayBlock";
import GoalItem from "SRC/pages/Program/common/GoalsTab/GoalItem/GoalItem";
import { useProgramIndicators } from "SRC/pages/Program/common/IndicatorsTab/useIndicatorsData";
import themeConfig from "SRC/theme";
import { IProgramGoal } from "SRC/types";
import { INDICATORS_TYPE } from "SRC/types/program/program-tab";

import { css, Wrapper } from "./Indicators.styled";

interface IIndicators {
  type: INDICATORS_TYPE;
}
export const Indicators = ({ type }: IIndicators) => {
  const theme = useTheme<typeof themeConfig>();
  const { indicators, analyticIndicators, fetching } = useProgramIndicators();
  const isExtraLarge = useMediaQuery(theme.breakpoints.up("xl"));
  const [activeIndex, setActiveIndex] = useState(0);
  const [isPrevBtnDisabled, setPrevBtnDisabled] = useState(false);
  const [isNextBtnDisabled, setNextBtnDisabled] = useState(false);

  const perChunk = isExtraLarge ? 9 : 6;

  const inputArray =
    type === INDICATORS_TYPE.ANALYTICS ? analyticIndicators : indicators;

  const goalsChunksArray = inputArray.reduce(
    (resultArray: any[], item, index) => {
      const chunkIndex = Math.floor(index / perChunk);
      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = [];
      }
      resultArray[chunkIndex].push(item);
      return resultArray;
    },
    []
  );
  useEffect(() => {
    setPrevBtnDisabled(activeIndex === 0);
    setNextBtnDisabled(goalsChunksArray.length - activeIndex === 1);
  }, [activeIndex, goalsChunksArray.length]);

  const goalsChunks = useMemo(
    (): JSX.Element[] =>
      goalsChunksArray.map((array: IProgramGoal[], index: number) => (
        <CarouselItem key={index}>
          <Box sx={css.carouselItem}>
            {array.map((item: IProgramGoal) => (
              <GoalItem
                key={`${item["Код показателя"]}-${item["Дата расчёта"]}`}
                item={item}
                chunkLength={
                  index === goalsChunksArray.length - 1 && array.length
                }
                type={type}
              />
            ))}
          </Box>
        </CarouselItem>
      )),
    [goalsChunksArray]
  );

  const updateIndex = (newIndex: number) => {
    if (newIndex < 0) {
      newIndex = goalsChunksArray.length - 1;
    } else if (newIndex >= goalsChunksArray.length) {
      newIndex = 0;
    }

    setActiveIndex(newIndex);
  };

  const onPrevClick = () => {
    if (!isPrevBtnDisabled) {
      updateIndex(activeIndex - 1);
    }
  };
  const onNextClick = () => {
    if (!isNextBtnDisabled) {
      updateIndex(activeIndex + 1);
    }
  };

  return (
    <Wrapper isCarousel={goalsChunksArray.length > 1}>
      <IncidentReport className="incident-container">
        <OverlayBlock
          isFetching={fetching}
          hasData={Boolean(goalsChunksArray.length)}
          noData={
            <div className="no-data-text">
              Согласно паспорту ГП показатели отсутствуют
            </div>
          }
        >
          <div className="carouselContainer">
            {goalsChunksArray.length > 1 && (
              <Box onClick={onPrevClick} sx={css.button(isPrevBtnDisabled)}>
                <WhiteArrowLeft style={css.arrow} />
              </Box>
            )}
            <div className="carousel">
              <Carousel
                visibleItems={1}
                activeIndex={activeIndex}
                withoutMargin
              >
                {goalsChunks}
              </Carousel>
            </div>
            {goalsChunksArray.length > 1 && (
              <Box
                onClick={onNextClick}
                sx={css.button(isNextBtnDisabled)}
                className="nextBtn"
              >
                <WhiteArrowRight style={css.arrow} />
              </Box>
            )}
          </div>
        </OverlayBlock>
      </IncidentReport>
    </Wrapper>
  );
};
