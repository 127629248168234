import { PROGRAM_TABS } from "SRC/types";

export const MAIN = "/";

export const ACCESS_DENIED = "/error-access";

export const PROGRAMS = "/programs";
export const PROGRAM_VIEW = `${PROGRAMS}/:programId`;
export const PROGRAM_VIEW_TAB = `${PROGRAM_VIEW}/:tab`;
export const PROGRAM_EVENT_VIEW = `${PROGRAM_VIEW}/events/:eventId`;
export const PROGRAM_INDICATOR_VIEW = `${PROGRAM_VIEW}/indicators/:indicatorId`;
export const PROGRAM_STRUCTURE_ELEMENT_VIEW = `${PROGRAM_VIEW}/structure-elements/:seId`;

export const MINISTRIES = "/ministries";
export const MINISTRY_VIEW = `${MINISTRIES}/:ministryId`;
export const MINISTRY_EVENT_VIEW = `${MINISTRY_VIEW}/event/:id`;
export const MINISTRY_INDICATOR_VIEW = `${MINISTRY_VIEW}/indicator/:id`;

export const SUMMARY = "/summary";
export const PRIORITIES = "/priorities";
export const STRUCTURE = "/structure/";
export const VICE_PREMIER = "/vice-premier";

export interface IRoute {
  title: string;
  path: string;
  nav?: boolean;
  external?: boolean;
  wip?: boolean;
  download?: boolean;
}

export const routeList: IRoute[] = [
  { title: "Главная", path: MAIN, nav: true },
  { title: "Сводная таблица", path: SUMMARY, nav: true },
  { title: "Ключевые приоритеты", path: PRIORITIES, wip: true },
  { title: "Карточки госпрограмм", path: PROGRAMS },
  { title: "Карточка госпрограммы", path: PROGRAM_VIEW },
  { title: "Карточка госпрограммы", path: PROGRAM_VIEW_TAB },
  { title: "Карточка показателя", path: PROGRAM_INDICATOR_VIEW },
  { title: "Карточка СЭ", path: PROGRAM_STRUCTURE_ELEMENT_VIEW },
  { title: "Структурные элементы", path: STRUCTURE },
  { title: "Мероприятие", path: PROGRAM_EVENT_VIEW },
  {
    title: "Отчёты",
    path: `${window.location.origin}/pages/np-report`,
    nav: false,
    external: true,
  },
  { title: "Другие отчеты", path: MAIN, download: true },
];

export const navList: IRoute[] = routeList.filter((r) => r.nav);

export const setParamsToUrl = (
  url: string,
  params: Record<string, string | number | null>
) => {
  return Object.keys(params).reduce(
    (res, key) => res.replace(`:${key}`, String(params[key])),
    url
  );
};

/**
 * Функция для передачи в url параметров для открытия карточки ГП
 *
 * @param url - базовый url карточки ГП
 * @param programId - идентификатор ГП
 * @param tab - вкладка в карточке ГП
 * @param filter? - фильтр для ГП
 */
export const createProgramCardUrl = (
  url: string,
  programId: number | string | null,
  tab: PROGRAM_TABS,
  filter: string = ""
) => {
  const filterParam = filter ? `?filter=${filter}` : "";

  return setParamsToUrl(url, { programId, tab }).concat("", filterParam);
};
