import { Box } from "@mui/material";
import { Close } from "ASSETS/svg/close";
import React, { useEffect, useState } from "react";
import { useObjectVideos } from "SRC/redux/slices/event/hooks/useObjectVideos";

import {
  EnpvVideo,
  isEnpvVideo,
  isOrionnetVideo,
  isRostelecomVideo,
  isRtspVideo,
  isSaferegionVideo,
  isTrassirVideo,
  isYoutubeVideo,
  OrionnetVideo,
  RostelecomVideo,
  RtspVideo,
  SaferegionVideo,
  TrassirVideo,
  YoutubeVideo,
} from "../../../video";
import { VideosModalStyles } from "./VideosModal.styles";

interface IVideoModalProps {
  handleVideoModalClose(): void;
}

export const VideosModal = ({ handleVideoModalClose }: IVideoModalProps) => {
  const { items: objectVideos } = useObjectVideos();

  const [videoUrl, setVideoUrl] = useState("");
  const [isEnpv, setIsEnpv] = useState(false);
  const [isYoutube, setIsYoutube] = useState(false);
  const [isRostelecom, setIsRostelecom] = useState(false);
  const [isRtsp, setIsRtsp] = useState(false);
  const [isTrassir, setIsTrassir] = useState(false);
  const [isOrionnet, setIsOrionnet] = useState(false);
  const [isSaferegion, setIsSaferegion] = useState(false);

  useEffect(() => {
    const url = (objectVideos?.[0]?.data[1456]?.sum || "").toString();

    setVideoUrl(url);

    setIsEnpv(isEnpvVideo(url));
    setIsRostelecom(isRostelecomVideo(url));
    setIsRtsp(isRtspVideo(url));
    setIsTrassir(isTrassirVideo(url));
    setIsYoutube(isYoutubeVideo(url));
    setIsOrionnet(isOrionnetVideo(url));
    setIsSaferegion(isSaferegionVideo(url));
  }, [objectVideos]);

  return (
    <VideosModalStyles>
      <div className="modal-header">
        <div className="title">Видео галерея</div>
        <Box sx={{ cursor: "pointer" }} onClick={handleVideoModalClose}>
          <Close />
        </Box>
      </div>
      {videoUrl && (
        <div className="video-content">
          {isEnpv && <EnpvVideo url={videoUrl} />}
          {isOrionnet && <OrionnetVideo url={videoUrl} />}
          {isRostelecom && <RostelecomVideo url={videoUrl} />}
          {isRtsp && <RtspVideo url={videoUrl} />}
          {isTrassir && <TrassirVideo url={videoUrl} />}
          {isSaferegion && <SaferegionVideo url={videoUrl} />}
          {isYoutube && <YoutubeVideo url={videoUrl} />}
        </div>
      )}
    </VideosModalStyles>
  );
};
