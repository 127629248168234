import { Box, Typography } from "@mui/material";
import React from "react";

import { css } from "./WideProgress.styled";

export interface IValue {
  value: number;
  name?: string;
  color?: string;
  legend?: string;
}

interface IWideProgress {
  title?: string;
  color?: string;
  hasLegend?: boolean;
  values: [IValue, IValue];
  value?: number;
}

export const WideProgress: React.FC<IWideProgress> = ({
  title,
  color,
  hasLegend,
  values,
  value,
}) => {
  const v1 = values[0];
  const v2 = values[1];

  return (
    <Box sx={css.container}>
      <Box sx={css.head}>
        <Typography sx={css.title}>{title}</Typography>
        {hasLegend && (
          <Box sx={css.legend}>
            {values.map(({ legend, name, color }: IValue, index) => (
              <Box key={name} sx={css.legendLi}>
                {color && <Box sx={css.dot(color)}>{index + 1}</Box>}
                {legend}
              </Box>
            ))}
          </Box>
        )}
      </Box>
      <Box sx={css.values}>
        <Typography sx={css.value} color={v1.color}>
          {v1.value || Number(value) || 0}
        </Typography>
        <Typography sx={css.value} color={v2.color}>
          {v2.value || 0}
        </Typography>
      </Box>
      <Box sx={css.progressBox(color)}>
        <Box
          sx={css.progressLine(color)}
          width={`${Number(value || (v1.value / v2.value) * 100) || 0}%`}
        />
      </Box>
      {Boolean(v1.name || v2.name) && (
        <Box sx={css.descriptions}>
          {v1.name && <Typography sx={css.name}>{v1.name}</Typography>}
          {v2.name && <Typography sx={css.name}>{v2.name}</Typography>}
        </Box>
      )}
    </Box>
  );
};
