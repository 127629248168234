import { SerializedError } from "@reduxjs/toolkit";
import { useCallback, useState } from "react";
import { IIndicatorData } from "SRC/redux/slices/indicator/slices/data";
import { IIndicatorParams } from "SRC/redux/slices/indicator/slices/filters";

import { useAppDispatch, useAppSelector } from "../../../hooks";
import actions from "../../indicator/actions";

export interface IIndicatorDatas {
  fetching: boolean;
  fetched: boolean;
  items: IIndicatorData | null;
  load(params: IIndicatorParams): void;
  error: SerializedError | null;
}

export const useIndicatorData = (): IIndicatorDatas => {
  const dispatch = useAppDispatch();
  const {
    fetching,
    items: data,
    fetched,
  } = useAppSelector((state) => state.indicator.data);
  const [error, setError] = useState<SerializedError | null>(null);

  const load = useCallback(
    (params: IIndicatorParams) => {
      if (!fetching) {
        dispatch(actions.data.get(params)).then((action) => {
          if (actions.data.get.rejected.match(action)) {
            setError(action.error);
          }
          return action;
        });
      }
    },
    [dispatch, fetching]
  );

  return {
    fetching,
    fetched,
    items: data,
    load,
    error,
  };
};
