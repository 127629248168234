import { olapDimensionRequestData } from "SRC/core/api/core";
import { IEventParams } from "SRC/types";

const GET_INDICATOR_DATA = "get-indicator-data";
const GET_INDICATOR_GRAPH = "get-indicator-graph";

export const getIndicatorData = (params: IEventParams) => {
  const request = [
    {
      requestId: "3f109adb-b40a-41b5-ad8e-f8635aee79b4",
      type: "data2",
      params: {
        modelUuid: "dbd43734-dc29-4fc4-a16a-144efa1a7d62",
        dashboardUuid: "",
        includeGaps: false,
        dimensions: [
          {
            id: "5211",
            includeItems: true,
            includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
          },
          {
            id: "5214",
            includeItems: true,
            includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
          },
          {
            id: "5219",
            includeItems: true,
            includeAttributesByCodes: [
              "NAME",
              "SHORT_NAME",
              "SORT_ORDER",
              "SE_CODE",
              "SE_TYPE",
              "Responsible_Name",
              "StateProgramCode",
            ],
          },
          {
            id: "5224",
            includeItems: true,
            includeAttributesByCodes: [
              "NAME",
              "SHORT_NAME",
              "SORT_ORDER",
              "SE_TYPE",
            ],
          },
          {
            id: "5228",
            includeItems: true,
            includeAttributesByCodes: [
              "NAME",
              "SHORT_NAME",
              "SORT_ORDER",
              "SE_CODE",
              "SE_TASK_CODE",
              "SE_TASK_TYPE",
            ],
          },
          {
            id: "5234",
            includeItems: true,
            includeAttributesByCodes: [
              "NAME",
              "SHORT_NAME",
              "SORT_ORDER",
              "UNIT",
              "IMPLEMENTATION_PERIOD",
              "IND_CODE",
            ],
          },
        ],
        indicators: {
          id: "5239",
          items: [
            {
              id: "5240",
              aggregationFunction: "sum",
              desc: "План",
            },
            {
              id: "5241",
              aggregationFunction: "sum",
              desc: "Факт",
            },
            {
              id: "5243",
              aggregationFunction: "sum",
              desc: "План на год",
            },
            {
              id: "5244",
              aggregationFunction: "sum",
              desc: "Уровень достижения",
            },
            {
              id: "5245",
              desc: "Уровень достижения, цвет",
              stringAggregation: true,
              separator: ";",
            },
            {
              id: "5277",
              desc: "Тип показателя",
              stringAggregation: true,
              separator: ";",
            },
            {
              id: "5470",
              aggregationFunction: "sum",
              desc: "Факт ОМ",
            },
            {
              id: "5471",
              aggregationFunction: "sum",
              desc: "Уровень достижения ОМ",
            },
            {
              id: "5521",
              aggregationFunction: "sum",
              desc: "Участвует в УД ОМ",
            },
            {
              id: "5522",
              aggregationFunction: "sum",
              desc: "Участвует в УД ФОИВ",
            },
            {
              id: "5533",
              aggregationFunction: "sum",
              desc: "Спущено с уровня ГП",
            },
            {
              id: "5534",
              aggregationFunction: "sum",
              desc: "Спущено с уровня ГП ОМ",
            },
            {
              id: "5551",
              aggregationFunction: "sum",
              desc: "Уровень показателя СЭ",
            },
          ],
        },
        dataRequestCaching: true,
        dataFilter: {
          type: "AND",
          version: 1,
          operands: [
            {
              type: "AND",
              version: 1,
              operands: [
                {
                  type: "STARTER",
                  version: 1,
                  dim_id: "5214",
                },
                {
                  type: "STARTER",
                  version: 1,
                  dim_id: "5219",
                },
                {
                  type: "STARTER",
                  version: 1,
                  dim_id: "5224",
                },
              ],
            },
            {
              type: "AND",
              version: 1,
              operands: [
                {
                  type: "EQ",
                  version: 1,
                  operands: [
                    {
                      type: "DIM",
                      version: 2,
                      id: "5234",
                      attributeCode: "IND_CODE",
                    },
                    {
                      type: "TEXT",
                      version: 2,
                      values: [params.id],
                    },
                  ],
                },
                {
                  type: "EQ",
                  version: 1,
                  operands: [
                    {
                      type: "DIM",
                      version: 1,
                      id: "5211",
                    },
                    {
                      type: "CONST",
                      version: 1,
                      values: [params.period.apiV2],
                    },
                  ],
                },
              ],
            },
          ],
        },
      },
    },
  ];

  return olapDimensionRequestData(request, GET_INDICATOR_DATA);
};

export const getIndicatorGraph = (params: IEventParams) => {
  const request = [
    {
      requestId: "8b418e47-43bb-4a82-bc8b-0cba7bebea48",
      type: "data2",
      params: {
        modelUuid: "dbd43734-dc29-4fc4-a16a-144efa1a7d62",
        dashboardUuid: "",
        includeGaps: false,
        dimensions: [
          {
            id: "5211",
            includeItems: true,
            includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
          },
          {
            id: "5214",
            includeItems: true,
            includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
          },
          {
            id: "5219",
            includeItems: true,
            includeAttributesByCodes: [
              "NAME",
              "SHORT_NAME",
              "SORT_ORDER",
              "SE_CODE",
              "SE_TYPE",
              "Responsible_Name",
              "StateProgramCode",
            ],
          },
          {
            id: "5224",
            includeItems: true,
            includeAttributesByCodes: [
              "NAME",
              "SHORT_NAME",
              "SORT_ORDER",
              "SE_TYPE",
            ],
          },
          {
            id: "5234",
            includeItems: true,
            includeAttributesByCodes: [
              "NAME",
              "SHORT_NAME",
              "SORT_ORDER",
              "UNIT",
              "IMPLEMENTATION_PERIOD",
              "IND_CODE",
            ],
          },
        ],
        indicators: {
          id: "5239",
          items: [
            {
              id: "5240",
              aggregationFunction: "sum",
            },
            {
              id: "5241",
              aggregationFunction: "sum",
            },
            {
              id: "5243",
              aggregationFunction: "sum",
            },
            {
              id: "5244",
              aggregationFunction: "sum",
            },
            {
              id: "5277",
              stringAggregation: true,
              separator: ";",
            },
          ],
        },
        dataRequestCaching: true,
        dataFilter: {
          type: "AND",
          version: 1,
          operands: [
            {
              type: "AND",
              version: 1,
              operands: [
                {
                  type: "STARTER",
                  version: 1,
                  dim_id: "5214",
                },
                {
                  type: "STARTER",
                  version: 1,
                  dim_id: "5219",
                },
                {
                  type: "STARTER",
                  version: 1,
                  dim_id: "5224",
                },
              ],
            },
            {
              type: "AND",
              version: 1,
              operands: [
                {
                  type: "EQ",
                  version: 1,
                  operands: [
                    {
                      type: "DIM",
                      version: 2,
                      id: "5234",
                      attributeCode: "IND_CODE",
                    },
                    {
                      type: "TEXT",
                      version: 2,
                      values: [params.id],
                    },
                  ],
                },
                {
                  type: "PARENT",
                  version: 1,
                  dim_id: "5211",
                  values: [params.period.year],
                },
              ],
            },
          ],
        },
      },
    },
  ];

  return olapDimensionRequestData(request, GET_INDICATOR_GRAPH);
};
