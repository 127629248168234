import { Stack, styled } from "@mui/material";
import { ESources } from "SRC/constants";

export const Wrapper = styled(Stack)(({ theme }) => {
  const {
    background: { paper },
    complementary: { darkBlueLight, linearDarkBlue2 },
    primary: { contrastText },
    main: { darkBlue, gray },
    text: { opinion },
  } = theme.palette;

  return {
    position: "relative",
    cursor: "pointer",

    "> .program-icon": {
      position: "absolute",
      zIndex: "2",
      top: "50%",
      transform: "translate(0, -50%)",
      width: "2rem",
      paddingTop: "0.25rem",
      display: "flex",
      flexDirection: "column",
      borderRadius: "1.5rem",
      border: `2px solid ${paper}`,
      fontSize: "0.875rem",
      lineHeight: "1.25rem",
      fontWeight: "600",
      textAlign: "center",
      backgroundColor: darkBlue,
      color: gray,

      img: {
        width: "100%",
      },
    },

    "> .info": {
      display: "flex",
      flexDirection: "row",
      height: "3.75rem",
      marginLeft: "0.75rem",
      alignItems: "center",
      borderRadius: "0.5rem",
      border: `2px solid ${paper}`,
      backgroundColor: darkBlueLight,
      overflow: "hidden",

      "> .title": {
        flex: "1",
        padding: "0.75rem 1rem 0.75rem 1.5rem",
        fontSize: "1rem",
        lineHeight: "1.125rem",
        overflow: "hidden",

        "> .name": {
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        },

        svg: {
          width: "1rem",
          height: "1rem",
        },
      },

      "> .secret-program-icon": {
        flex: "0 0 calc(4ch + 2rem)",
        display: "flex",
        height: "100%",
        alignItems: "center",
        borderLeft: `2px solid ${linearDarkBlue2}`,

        "> span": {
          flex: "1",
          textAlign: "center",
        },

        svg: {
          width: "1.4rem",
          height: "1.4rem",
        },
      },

      "> button": {
        width: "2rem",
        height: "100%",
        minWidth: "2rem",
        borderRadius: "0",
        backgroundColor: `${paper}C0`,
        color: contrastText,
      },

      [`> .progress-${ESources.OM}`]: {
        color: opinion,
      },
    },
  };
});
