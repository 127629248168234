import { sortColumn } from "./sort-column";

export const sortStructureElementsFactColumn = (isOM: boolean) => {
  return ({ original: rowA }: any, { original: rowB }: any, ...rest: any[]) => {
    const [, isDesc] = rest;

    return sortColumn(
      {
        id: rowA.id,
        hasFeature: !!rowA.secretProgram,
        value: isOM ? rowA.structureElementsOMFact : rowA.structureElementsFact,
      },
      {
        id: rowB.id,
        hasFeature: !!rowB.secretProgram,
        value: isOM ? rowB.structureElementsOMFact : rowB.structureElementsFact,
      },
      isDesc
    );
  };
};
