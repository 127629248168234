import { getDataSumByCodeMin } from "SRC/constants";
import { IMinistriesTableItem } from "SRC/hooks/summaryTable/interfaces";
import { generalRowsSummaryTableData } from "SRC/hooks/summaryTable/utils/utils";
import { TMinistry } from "SRC/redux/slices/main/hooks/useMinistries";
import { CustomRowProps } from "SRC/types/data/data";
import { performanceColumn } from "SRC/types/ministry/ministryTab";

export const createMinistriesRow = (item: TMinistry): IMinistriesTableItem => {
  const data = item?.data[0];
  const { info } = item;
  const elem = { ...data, data: Object.values(item.data) };
  return {
    id: info.code,
    isMinistry: true,
    name: info.code,
    iconUrl: info.icon_url,
    govPrograms: getDataSumByCodeMin(elem, 1782) || 0,

    eventsPlan: getDataSumByCodeMin(elem, 1785) || 0,
    eventsPlanTotal: getDataSumByCodeMin(elem, 5434) || 0,
    eventsOMPlan: getDataSumByCodeMin(elem, 5390) || 0,
    eventsOMPlanTotal: getDataSumByCodeMin(elem, 5434) || 0,

    eventsFact: getDataSumByCodeMin(elem, 1786) || 0,
    eventsFactTotal: getDataSumByCodeMin(elem, 5435) || 0,
    eventsOMFact: getDataSumByCodeMin(elem, 5389) || 0,
    eventsOMFactTotal: getDataSumByCodeMin(elem, 5438) || 0,

    eventsRisk: getDataSumByCodeMin(elem, 1787) || 0,
    eventsRiskTotal: getDataSumByCodeMin(elem, 5437) || 0,
    // TODO: дублирование кода
    eventsOMRisk: getDataSumByCodeMin(elem, 1787) || 0,
    // TODO: дублирование кода
    eventsOMRiskTotal: getDataSumByCodeMin(elem, 5437) || 0,

    eventsFail: getDataSumByCodeMin(elem, 5412) || 0,
    eventsFailTotal: getDataSumByCodeMin(elem, 5436) || 0,
    eventsOMFail: getDataSumByCodeMin(elem, 5413) || 0,
    eventsOMFailTotal: getDataSumByCodeMin(elem, 5439) || 0,
    // Показатели выполнено/факт
    indicatorsFact: getDataSumByCodeMin(elem, 1784) || 0,
    indicatorsFactTotal: getDataSumByCodeMin(elem, 5598) || 0,
    indicatorsOMFact: getDataSumByCodeMin(elem, 5387) || 0,
    indicatorsOMFactTotal: getDataSumByCodeMin(elem, 5426) || 0,
    // Показатели план
    indicatorsPlan: getDataSumByCodeMin(elem, 1783) || 0,
    indicatorsPlanTotal: getDataSumByCodeMin(elem, 5423) || 0,
    indicatorsOMPlan: getDataSumByCodeMin(elem, 5388) || 0,
    indicatorsOMPlanTotal: getDataSumByCodeMin(elem, 5425) || 0,
    // Показатели не выполнено
    indicatorsFail: getDataSumByCodeMin(elem, 5404) || 0,
    indicatorsFailTotal: getDataSumByCodeMin(elem, 5424) || 0,
    indicatorsOMFail: getDataSumByCodeMin(elem, 5405) || 0,
    indicatorsOMFailTotal: getDataSumByCodeMin(elem, 5427) || 0,

    performance: getDataSumByCodeMin(elem, 1845) || 0,
    performanceOM: getDataSumByCodeMin(elem, 5268) || 0,
    performanceTotal: getDataSumByCodeMin(elem, 5275) || 0,
    performanceOMTotal: getDataSumByCodeMin(elem, 5273) || 0,
  };
};

export const createMinistriesTableData = (
  data: TMinistry[]
): IMinistriesTableItem[] =>
  data.map((elem: TMinistry) => {
    return createMinistriesRow(elem);
  });

export const createMinistryRowsTableData = (
  data: CustomRowProps[],
  isOM: boolean
) => {
  return data.map((row: CustomRowProps, index) => {
    const {
      id,
      name,
      eventsRisk: atRisk,
      govPrograms: numOfPrograms,
    } = row.original;
    return {
      id,
      atRisk,
      name,
      ...generalRowsSummaryTableData(row, isOM),
      // Важен порядок
      numOfPrograms,
    };
  });
};

export const createSortedMinistriesData = (
  isOM: boolean,
  data: IMinistriesTableItem[]
) => {
  return [...data].sort((a, b) => {
    return isOM
      ? (a[performanceColumn.ACHIEVE_FOIV] || 0) -
          (b[performanceColumn.ACHIEVE_FOIV] || 0)
      : (a[performanceColumn.ACHIEVE_OM] || 0) -
          (b[performanceColumn.ACHIEVE_OM] || 0);
  });
};
