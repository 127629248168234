import executor from "ASSETS/non-usage/executors/Ministery_RF.svg";
import { UseTableRowProps } from "react-table";
import { getDataSumByCodeGP } from "SRC/constants";
import { isSecret } from "SRC/helpers/dataTesters";
import { getPercentValue } from "SRC/helpers/getPercentValue";
import { roundNumbersToFixed } from "SRC/helpers/roundNumbersToFixed";
import {
  IEventExportItem,
  IEventItem,
} from "SRC/pages/Program/common/EventsSection/util/interfaces";
import { IndicatorItem } from "SRC/pages/Program/common/IndicatorsTab/interface";
import {
  IIndicatorSEExportItem,
  IIndicatorsGPExportItem,
  IStatus,
  IStructureGPExportItem,
  ITasksSEExportItem,
} from "SRC/pages/Program/common/ProgramSummary/interfaces";
import { ISubject } from "SRC/redux/slices/event/slices/subjects";
import { IProgramEvent } from "SRC/redux/slices/gosprogram/slices/events";
import { IGpStructure } from "SRC/redux/slices/gosprogram/slices/gpStructure";
import { IProgramIndicator } from "SRC/redux/slices/gosprogram/slices/indicators";
import { IProgram } from "SRC/redux/slices/gosprogram/slices/program";
import { IStructureElementsTask } from "SRC/redux/slices/gosprogram/slices/structureElements/structureElementsTasks";
import {
  EVENT_CODE,
  EVENT_STATUS,
  EVENT_STATUSES_DEFAULT,
  ICodeItem,
  IEventStatuses,
  INDICATOR_STATUSES_DEFAULT,
  indicatorsStatusesConverter,
  IProgramGoal,
  SUBJECT_STATUS,
  SUBJECT_STATUSES_DEFAULT,
  TEventStatus,
  TSEIndicatorStatus,
  TStatuses,
} from "SRC/types";

import { IGpStructureItem } from "./common/StructureTab/interface";

export interface ISubjectsTableData {
  id: string;
  statusLabel?: string;
  timeFactColor?: string;
  objectFactColor?: string;
  nameSubject: string;
  done: boolean;
  notDone: boolean;
  plannedItems?: number;
  reliasedItems?: number;
  inReliaseItems?: number;
  doneItems?: number;
  notDoneItems?: number;
  atRiskItems?: number;
  noDataItems?: number;
  totalItems?: number;
  timePlan: string;
  timeFact: string;
  countObjectPlan: string;
  countObjectFact: string;
}

export const structureElementTypes: Record<string, string> = {
  "Федеральные проекты": "ФП",
  "Ведомственные проекты": "ВП",
  "Комплексы процессных мероприятий": "КПМ",
};

export const isStatusMatch = (selectedStatus: string[], item: IEventItem) => {
  return selectedStatus.every((selectedStatus) => {
    return item.status[selectedStatus as TEventStatus];
  });
};

export const getEventStatuses = (eventData: ICodeItem[]) => {
  return Object.entries(EVENT_CODE).reduce(
    (acc: IEventStatuses, [key, code]) => {
      const keySum = eventData.find((item) => item.code === code)?.sum || 0;

      return {
        ...acc,
        [EVENT_STATUS[key as keyof typeof EVENT_STATUS]]: keySum,
      };
    },
    EVENT_STATUSES_DEFAULT
  );
};
const statuses: Record<keyof typeof SUBJECT_STATUS, TStatuses> = {
  DONE: "doneItems",
  IN_PROGRESS: "inReliaseItems",
  AT_RISK: "atRiskItems",
  NOT_DONE: "notDoneItems",
  PLANNED: "plannedItems",
  IMPLEMENTED: "reliasedItems",
  NO_REPORTING: "noDataItems",
};

export const getSubjectStatuses = (
  tableData: ISubjectsTableData[]
): Record<TStatuses, number> => {
  const totalByStatus = tableData.reduce((acc: any, item) => {
    const resObj: any = {};
    for (const key in statuses) {
      const status = statuses[key as keyof typeof SUBJECT_STATUS];
      resObj[status] =
        //@ts-ignore
        acc[status] + ((item[status] as Partial<ISubjectsTableData>) ?? 0);
    }
    return {
      ...resObj,
      totalItems: tableData.length,
    };
  }, SUBJECT_STATUSES_DEFAULT);

  return totalByStatus;
};

const features = {
  34: "Признак_основные",
  35: "Признак_не основные",
  36: "Признак_ОКС онлайн",
  37: "Признак_Реализация в субъектах",
  38: "Признак_СЗП",
  39: "Признак_ФХД",
  40: "Признак_КТ",
  41: "Признак_ОКС",
  42: "Признак_ЛЮГ",
  43: "Признак_СМНП",
};

export const createGpStructureTableData = (
  data: IGpStructure[]
): IGpStructureItem[] =>
  data.map((item: IGpStructure, index) => ({
    id: item.code,
    index: index + 1,
    type: item.SE_TYPE,
    name: item.NAME,
    performance: getDataSumByCodeGP(item, 5384) || 0,
    performanceOpinion: getDataSumByCodeGP(item, 5385) || 0,
    cash: getDataSumByCodeGP(item, 5386) || 0,
    inPerformance: getDataSumByCodeGP(item, 5523) || 0,
    inPerformanceOM: getDataSumByCodeGP(item, 5524) || 0,
  }));

const getColor = (serverColor: string | undefined) => {
  if (!serverColor) {
    return "gray";
  }

  switch (serverColor) {
    case "Gr":
      return "gray";
    case "G":
      return "green";
    case "R":
      return "red";
  }
};

export const createSubjectsTableData = (
  data: ISubject[]
): ISubjectsTableData[] =>
  data.map((item) => {
    const timePlan = item.data[1423]?.text;
    const timeFact = item.data[1424]?.text;

    const timeFactColor = getColor(item.data[1794]?.text);
    const objectFactColor = getColor(item.data[1795]?.text);

    return {
      id: item.info.code,
      statusLabel: item.data[1794]?.text && item.data[1795]?.text,
      timeFactColor,
      objectFactColor,
      nameSubject: item.info.name,
      done: timeFactColor === "green" && objectFactColor === "green",
      notDone: timeFactColor === "red" || objectFactColor === "red",
      plannedItems: item.data[1446]?.sum,
      reliasedItems: item.data[1447]?.sum,
      inReliaseItems: item.data[1448]?.sum,
      doneItems: item.data[1449]?.sum,
      notDoneItems: item.data[1450]?.sum,
      atRiskItems: item.data[1451]?.sum,
      noDataItems: item.data[5602]?.sum,
      totalItems: data.length,
      timePlan: timePlan || "",
      timeFact: timeFact || "",
      countObjectPlan: String(item.data[1421]?.sum),
      countObjectFact: String(item.data[1422]?.sum),
    };
  });

export const createEventsTableData = (data: IProgramEvent[]): IEventItem[] =>
  data.map((item: IProgramEvent, index) => ({
    id: item.code,
    index: index + 1,
    name: item.NAME,
    units: item["Ед. измерения результата"],
    structureElement: item.data.find((it) => Number(it.code) === 120)?.text,
    structureElementName: item["Наименование проекта"],
    structureTaskName: item["Наименование задачи"],
    eventTypeNameShort: item["Тип мероприятия, короткое название от АЦ"],
    eventTypeNameFull: item["Тип мероприятия, полное наименование"],
    inPerformance: Boolean(item["Участвует в расчёте УД, ФОИВ"]),
    inPerformanceOM: Boolean(item["Участвует в расчёте УД, ОМ"]),
    typeCalcPerformance: Number(item["Тип расчета УД"]),
    notDeliveredFunds: roundNumbersToFixed(
      Number(item.data.find((it) => Number(it.code) === 126)?.sum) / 1000000,
      0
    ),
    notAcceptedFunds: roundNumbersToFixed(
      Number(item.data.find((it) => Number(it.code) === 127)?.sum) / 1000000,
      0
    ),
    plan: roundNumbersToFixed(
      Number(item.data.find((it) => Number(it.code) === 0)?.sum),
      0
    ),
    fact: roundNumbersToFixed(
      Number(item.data.find((it) => Number(it.code) === 1)?.sum),
      0
    ),
    factOM: roundNumbersToFixed(
      Number(item.data.find((it) => Number(it.code) === 52)?.sum),
      0
    ),
    performanceOM: getPercentValue(
      Number(item.data.find((it) => Number(it.code) === 53)?.sum)
    ),
    performance: roundNumbersToFixed(
      Number(item.data.find((it) => Number(it.code) === 2)?.sum),
      0
    ),
    effect: roundNumbersToFixed(
      Number(item.data.find((it) => Number(it.code) === 29)?.sum),
      0
    ),
    atRisk: item.data.find((it) => Number(it.code) === 17)?.sum || 0,
    status: getEventStatuses(item.data),
    features: Object.entries(features)
      .map(([code, feat]) =>
        item.data.find((it) => Number(it.code) === +code)?.sum ? feat : ""
      )
      .filter(Boolean),
  }));

export const createEventRowsTableData = (
  data: UseTableRowProps<any>[],
  isOM: boolean
): IEventExportItem[] => {
  return data.map((item: UseTableRowProps<any>) => {
    const {
      id,
      structureElement,
      structureElementName,
      eventTypeNameFull,
      name,
      units,
      plan,
      fact,
      done,
      factOM,
      factOMPercent,
      effect,
      notDeliveredFunds,
      notAcceptedFunds,
    } = item.original;

    return {
      id,
      structureElement,
      structureElementName,
      eventTypeNameFull,
      name,
      units,
      plan,
      fact: isOM ? factOM : fact,
      done: isOM ? factOMPercent : done,
      effect,
      notDeliveredFunds,
      notAcceptedFunds,
    };
  });
};

export const createIndicatorsSERowsTableData = (
  data: UseTableRowProps<any>[],
  isOM: boolean
): IIndicatorSEExportItem[] =>
  data.map((item: UseTableRowProps<any>) => ({
    id: item.original.id,
    se_gp_type: item.original.se_gp_type,
    name: item.original.name,
    unit: item.original.unit,
    implementation_period: item.original.implementation_period,
    plan: item.original.plan,
    plan_year: item.original.plan_year,
    fact: isOM ? item.original.factOM : item.original.fact,
    performance: isOM ? item.original.performanceOM : item.original.performance,
  }));

export const createTasksSERowsTableData = (
  data: UseTableRowProps<any>[],
  isOM: boolean
): ITasksSEExportItem[] =>
  data.map((item: UseTableRowProps<any>) => ({
    name: item.original.name,
    indicatorsCount: item.original.indicatorsCount,
    eventsCount: item.original.eventsCount,
  }));

export const createStructureGPRowsTableData = (
  data: UseTableRowProps<any>[],
  isOM: boolean
): IStructureGPExportItem[] =>
  data.map((item: UseTableRowProps<any>) => ({
    type: item.original.type,
    name: item.original.name,
    performance:
      (isOM ? item.original.performanceOpinion : item.original.performance) ||
      0,
    cash: item.original.cash,
  }));

export const createIndicatorsGPTableData = (
  data: IProgramGoal[],
  isOM: boolean
): IIndicatorsGPExportItem[] =>
  data.map((item) => {
    return {
      goal: item["Имя Цели"] || "",
      indicator: item["Имя показателя"],
      performance:
        (isOM ? item["УД по данным ОМ МЭР"] : item["Уровень достижения"]) || 0,
      plan:
        (isOM
          ? item["УД показателя ОМ МЭР, План"]
          : item["УД показателя, План"]) || 0,
      fact:
        (isOM
          ? item["УД показателя ОМ МЭР, Факт"]
          : item["УД показателя, Факт"]) || 0,
      unit: item["Ед измерения показателя"],
    };
  });

// TODO [заполнить]: подставлены тестовые данные
export const createIndicatorsTableData = (
  data: IProgramIndicator[]
): IndicatorItem[] =>
  data.map((item: IProgramIndicator, index) => ({
    id: item.code,
    index: index + 1,
    name: item.NAME,
    units: item["Ед. измерения результата"],
    structureElement: "Тип",
    structureElementName: item["Наименование проекта"],
    plan: 0,
    yearPlan: 0,
    fact: 0,
    done: 0,
    period: 0,
    status: EVENT_STATUS.NOT_DONE,
  }));

export const getProgramIconUrl = (program: IProgram | null): string => {
  return program?.gp_icon_url || "icon_url";
};

export const getProgramType = (program: IProgram | null): string => {
  return Number(program?.["Комплексная"])
    ? "Комплексная"
    : Number(program?.["Отраслевая"])
    ? "Отраслевая"
    : isSecret(program)
    ? "Скрытая"
    : "";
};

export const getHeaderData = (
  program: IProgram | null,
  programType: string,
  programIconUrl: string
) => {
  return {
    header: {
      id: program?.gp_code,
      name: program?.gp_name,
      programType: programType,
      icon: programIconUrl,
      programSecret: isSecret(program),
    },
    curator: {
      name: program?.["Вице-премьер"],
      photo: program?.["Вице-премьер, фото"],
      executorPhoto: executor, //TODO получить урл фото исполнителя госпрограммы
      executorName: program?.["Министерство"],
    },
  };
};

const indicatorKeys = {
  // Период
  5211: "period",
  // Статус показателя
  5214: "status",
  // Структурный элемент ГП
  5219: "se_gp",
  // Тип структурного элемента ГП
  5224: "se_gp_type",
  // Задача структурного элемента ГП
  5228: "se_task",
  // Показатель структурного элемента ГП
  5234: "se_indicator",
  // План
  5240: "plan",
  // Факт
  5241: "fact",
  // Факт, цвет
  5242: "fact_color",
  // План на год
  5243: "plan_year",
  // Уровень достижения
  5244: "performance",
  // Уровень достижения, цвет
  5245: "achievement_color",
  // Показатели
  5239: "indicators",
  // Факт ОМ
  5470: "fact_om",
  // Уровень достижения ОМ
  5471: "performanceOM",
  // Участвует в УД ОМ
  5521: "inPerformanceOM",
  // Участвует в УД ФОИВ
  5522: "inPerformance",
  //Спущено с уровня ГП
  5533: "is_under_gp_foiv",
  //Спущено с уровня ГП ОМ
  5534: "is_under_gp_om",
};

export const getIndicatorStatuses = (tableData: any[]) => {
  const totalByStatus = tableData.reduce(
    (acc: Partial<Record<TSEIndicatorStatus, IStatus>>, item) => {
      const status = indicatorsStatusesConverter[
        item.status
      ] as TSEIndicatorStatus;
      return {
        ...acc,
        [status]: {
          status,
          amount: (acc[status]?.amount || 0) + 1,
        },
      };
    },
    INDICATOR_STATUSES_DEFAULT
  );

  return Object.values(totalByStatus);
};

const parseIndicators = (indicatorVals: any) => {
  const defaultValues = Object.values(indicatorKeys).reduce((acc, key) => {
    return {
      ...acc,
      [key]: 0,
    };
  }, {});

  return Object.entries(indicatorVals || {}).reduce((acc, [key, value]) => {
    const indicatorValue = value as Record<any, any>;

    return {
      ...acc,
      [indicatorKeys[key as unknown as keyof typeof indicatorKeys]]:
        indicatorValue.sum || indicatorValue.text || 0,
    };
  }, defaultValues);
};

const getDimension = (dictionary: any, key: string, value: any) => {
  return dictionary[key as keyof typeof dictionary][value as string];
};

const parseDims = (dims: any, dictionary: any) => {
  return Object.entries(dims || {}).reduce((acc, [key, value]) => {
    const indicatorKey = key as unknown as keyof typeof indicatorKeys;
    const { code, attributeVals: attribute } = getDimension(
      dictionary,
      key,
      value
    );

    let dimValue;

    if (Number(key) === 5234) {
      // Показатель структурного элемента ГП
      dimValue = {
        name: attribute.NAME,
        unit: attribute.UNIT,
        implementation_period: attribute.IMPLEMENTATION_PERIOD,
        id: code,
      };
    } else if (Number(key) === 5211) {
      // Период
      dimValue = {
        [indicatorKeys[indicatorKey]]: attribute.PERIOD2,
      };
    } else if (Number(key) === 5224) {
      // Тип структурного элемента ГП
      dimValue = {
        [indicatorKeys[indicatorKey]]: attribute.SE_TYPE,
      };
    } else {
      dimValue = {
        [indicatorKeys[indicatorKey]]: attribute.NAME,
      };
    }

    return {
      ...acc,
      ...dimValue,
    };
  }, {});
};

export const createSeIndicatorsTableData = (items: any) => {
  const { data = [], dimensionItems: dictionary } = items || {};
  return data.map((item: any, index: number) => {
    const { indicatorVals, dims } = item;

    return {
      index: index + 1,
      ...parseIndicators(indicatorVals),
      ...parseDims(dims, dictionary),
    };
  });
};

export interface ISETaskTableElement {
  index: number;
  name: string;
  se_type: string;
  se_name: string;
  indicatorsCount: string | number;
  eventsCount: string | number;
}

export const createSeTasksTableData = (
  items: IStructureElementsTask[]
): ISETaskTableElement[] => {
  return items.map(({ info, se, data }, index: number) => {
    return {
      index: index + 1,
      name: info.name,
      se_type: se.type,
      se_name: se.name,
      indicatorsCount: data[5399].sum || 0,
      eventsCount: data[5400].sum || 0,
    };
  });
};

export const structureElementTypesMap = Object.fromEntries(
  Object.entries(structureElementTypes).map(([key, value]) => {
    return [value, key];
  })
);
