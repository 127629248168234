import { styled } from "@mui/material";

export const Wrapper = styled("div")(({ theme }) => ({
  "> .title": {
    fontSize: "0.8rem",
    color: theme.palette.complementary.lightWhite,
  },
  "> .value": {
    fontSize: "1.5rem",
  },
}));
