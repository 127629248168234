import { ICustomTableProps } from "SRC/types";

export const getIndicatorsPlanTotal = (
  tableProps: ICustomTableProps,
  isOM: boolean
) => {
  let { indicatorsPlanTotal, indicatorsOMPlanTotal } =
    tableProps?.rows?.[0]?.original || {};

  return {
    indicatorsPlanTotal: isOM ? indicatorsOMPlanTotal : indicatorsPlanTotal,
  };
};
