import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getProgramsMain } from "SRC/redux/list/programs-main/api";
import { IPeriod } from "SRC/redux/slices/global/slices/filters";

export enum EDataProgram {
  REGION = "Код региона",
  UNDER_CONSTRUCTION = "Объектов строится",
  OBJECTS_PLAN = "Объекты. План на период",
  OBJECTS_FACT = "Объекты. Факт на период",
  MINISTRY = "Ответственные ФОИВ",
  OBJECT_PERIOD = "Период объектов",
  TYPES_OF_GP = "Типы ГП",
  APPROVED = "Утверждена",
}

export enum EIndicators {
  INDICATORS_PLAN = "Количество показателей",
  INDICATORS_PLAN_OM = "Количество показателей, ОМ",
  INDICATORS_FACT_OM = "Количество показателей, ОМ, выполнено",
  INDICATORS_FACT = "Количество показателей, выполнено",
  EVENTS_FACT = "Мероприятий выполнено",
  EVENTS_PLAN = "Мероприятий, всего",
  EVENTS_FACT_OM = "Мероприятий выполнено ОМ",
  EVENTS_PLAN_OM = "Мероприятий, всего, ОМ",
  EVENTS_RISK = "Мероприятий, под риском",
  PERFORMANCE_TOTAL = "Уровень достижения",
  PERFORMANCE = "Уровень достижения 2",
  PERFORMANCE_COLOR = "Уровень достижения 2, Цвет",
  PERFORMANCE_OM = "Уровень достижения ОМ",
  PERFORMANCE_OM_COLOR = "Уровень достижения ОМ, Цвет",
  PERFORMANCE_TOTAL_COLOR = "Уровень достижения, Цвет",
}

export type TIndicatorsMap = {
  [EIndicators.INDICATORS_PLAN]: number;
  [EIndicators.INDICATORS_PLAN_OM]: number;
  [EIndicators.INDICATORS_FACT_OM]: number;
  [EIndicators.INDICATORS_FACT]: number;
  [EIndicators.EVENTS_FACT]: number;
  [EIndicators.EVENTS_FACT_OM]: number;
  [EIndicators.EVENTS_PLAN]: number;
  [EIndicators.EVENTS_PLAN_OM]: number;
  [EIndicators.EVENTS_RISK]: number;
  [EIndicators.PERFORMANCE]: number;
  [EIndicators.PERFORMANCE_OM]: number;
  [EIndicators.PERFORMANCE_TOTAL]: number;
  [EIndicators.PERFORMANCE_COLOR]: string;
  [EIndicators.PERFORMANCE_OM_COLOR]: string;
  [EIndicators.PERFORMANCE_TOTAL_COLOR]: string;
};

export type TTypesOFGP = "Комплексная" | "Отраслевая" | "Секретная";

export type TDataProgram = {
  data: TIndicatorsMap | null;
  gp_code: string;
  gp_icon_url: string;
  gp_name: string;
  gp_short_name: string;
  list_nt: string | null;
  vp_code: string;
  vp_name: string;
  vp_photo_url: string;
  vp_post: string;
  [EDataProgram.REGION]: number;
  [EDataProgram.UNDER_CONSTRUCTION]: number;
  [EDataProgram.OBJECTS_PLAN]: number;
  [EDataProgram.OBJECTS_FACT]: number;
  [EDataProgram.MINISTRY]: string[];
  [EDataProgram.OBJECT_PERIOD]: number;
  [EDataProgram.TYPES_OF_GP]: Record<TTypesOFGP, number>;
  [EDataProgram.APPROVED]: number;
};

export interface IMainProgramsState {
  items: TDataProgram[];
  fetching: boolean;
  fetched: boolean;
  error: string;
}

export const extraActions = {
  get: createAsyncThunk(
    "mainPrograms/get",
    async (period: IPeriod) => await getProgramsMain(period)
  ),
};

const initialState: IMainProgramsState = {
  items: [],
  fetching: false,
  fetched: false,
  error: "",
};

export const slice = createSlice({
  name: "mainPrograms",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActions.get.pending, (state) => {
        state.items = [];
        state.fetching = true;
        state.fetched = false;
      })
      .addCase(
        extraActions.get.fulfilled,
        (state: IMainProgramsState, action) => {
          const { data } = action.payload.data;

          state.items = data as unknown as TDataProgram[];
          state.fetching = false;
          state.fetched = true;
        }
      )
      .addCase(extraActions.get.rejected, (state, action) => {
        state.fetching = false;
        state.fetched = true;
        // state.error = action.error;
      });
  },
});

export const actions = {
  ...slice.actions,
  ...extraActions,
};

export default slice;
