import { useMemo } from "react";
import { indicatorsData } from "SRC/pages/Ministry/common/utils/tableData";
import { getIndicatorStatuses } from "SRC/pages/Program/utils";
import { useFilters } from "SRC/redux/slices/ministry/hooks/useFilters";

type TGpIndicatorsTableData = {
  gpIndicatorsStatuses: any;
  gpIndicatorsTableData: any;
};

export const useGpIndicatorsTableData = (): TGpIndicatorsTableData => {
  let data = indicatorsData;
  const { selectedStatus } = useFilters();

  const { gpIndicatorsTableData, gpIndicatorsStatuses } = useMemo(() => {
    let filteredData = data.filter((item: any) => {
      const statusFilter =
        !selectedStatus.length || selectedStatus.includes(item.status);

      return statusFilter;
    });

    return {
      gpIndicatorsTableData: filteredData,
      gpIndicatorsStatuses: getIndicatorStatuses(filteredData),
    };
  }, [data, selectedStatus]);

  return {
    gpIndicatorsTableData,
    gpIndicatorsStatuses,
  };
};
