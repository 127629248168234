import { Box, SxProps, useTheme } from "@mui/material";
import { WarningTriangle } from "ASSETS/svg";

import { css, Wrapper } from "./StatusLabel.styled";

interface IStatusProps {
  name: string;
  label: "done" | "risk" | "fail";
  sources?: boolean | undefined;
  className?: string;
}

export const StatusLabel = ({
  name,
  label,
  sources,
  className,
}: IStatusProps) => {
  const theme = useTheme();
  return (
    <Wrapper className={className}>
      {label !== "risk" ? (
        <Box sx={css.color(label, sources, theme) as SxProps} />
      ) : (
        <WarningTriangle />
      )}
      <div>{name}</div>
    </Wrapper>
  );
};
