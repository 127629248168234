import { Box, SxProps, Tab, Tabs } from "@mui/material";
import * as CSS from "csstype";
import React, {
  CSSProperties,
  Key,
  ReactNode,
  useCallback,
  useMemo,
} from "react";
import { WIPTag } from "SRC/components/WIP/WIP";

import { css } from "./ExtendedTabs.styled";

export interface ITab<V extends Key> {
  value: V;
  label: string | number | JSX.Element;
  numberOfItems?: number;
  borderColor?: string;
  disabled?: boolean;
  wip?: boolean;
  sx?: SxProps;
  component: ReactNode;
  isSmall?: boolean;
}

interface IExtendedTabs<V extends Key> {
  value: V;
  onChange(value: V): void;
  tabs: ITab<V>[];
  withBgr?: boolean;
  stretch?: boolean;
  contained?: boolean;
  additional?: ReactNode;
  innerTab?: boolean;
  borderStyle?: CSS.Property.BorderBlockStyle;
  actions?: ReactNode[];
  style?: SxProps | CSSProperties;
}

interface ITabPanelProps {
  value?: string | number;
  index?: string | number;
  children?: any;
  show?: boolean;
  sx?: SxProps;
}

const TabPanel = (props: ITabPanelProps) => {
  const { children, index, sx, show } = props;
  if (!show) {
    return null;
  }

  return (
    <Box
      style={{
        flex: 1,
        minHeight: 0,
        paddingTop: "1rem",
        display: "flex",
      }}
      sx={sx}
      role="tabpanel"
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {children}
    </Box>
  );
};

export const ExtendedTabs = <Value extends Key>({
  value,
  onChange,
  tabs,
  innerTab = false,
  withBgr = false,
  stretch = false,
  contained = false,
  additional = null,
  style,
  borderStyle,
  actions = [],
}: IExtendedTabs<Value>) => {
  const components: ReactNode[] = useMemo(
    () =>
      tabs.map(({ component, value: tabValue }: ITab<Value>) => (
        <TabPanel
          key={tabValue}
          index={tabValue as string}
          show={value === tabValue}
        >
          {component}
        </TabPanel>
      )),
    [tabs, value]
  );

  const handleOnChange = useCallback(
    (_: React.SyntheticEvent, value: Value) => onChange(value),
    [onChange]
  );

  return (
    <Box sx={css.container}>
      <Box sx={css.head(borderStyle, withBgr)}>
        <Tabs
          value={value}
          onChange={handleOnChange}
          sx={{ ...css.tabs(contained), ...(style || {}) }}
          orientation="horizontal"
        >
          {tabs?.map((item: ITab<Value>, i: number) => (
            <Tab
              key={item.value}
              value={item.value}
              disabled={item.disabled}
              sx={css.tab(value === item.value, stretch)}
              id={`tab-${i}`}
              label={
                <Box sx={css.label}>
                  {item.label}
                  {typeof item.numberOfItems === "number" ? (
                    <Box
                      sx={css.labelNumber(
                        item.borderColor || "",
                        !!innerTab,
                        !!item.isSmall
                      )}
                    >
                      {item.numberOfItems}
                    </Box>
                  ) : null}
                  {item.wip && <WIPTag />}
                </Box>
              }
            />
          ))}
        </Tabs>
        {additional && <Box sx={css.additional}>{additional}</Box>}
        {!!actions?.length && <Box sx={css.actions}>{actions}</Box>}
      </Box>
      {components}
    </Box>
  );
};
