import { Theme } from "@mui/material";

export const css = {
  progressBar: {
    position: "relative",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "0.4rem",
  },
  uiProgressBarStyle: (isDarkBg?: boolean) => ({
    backgroundColor: ({ palette: { background } }: Theme) =>
      isDarkBg ? background.progressDarkBlue : background.default,
    borderRadius: 10,
    margin: "auto",
    maxWidth: "100%",
    width: "100%",
    minHeight: "0.5rem",
    position: "relative",
  }),
  progressLine: (value: number, progressColor?: string) => ({
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    minHeight: "0.5rem",
    width: `${value}%`,
    borderRadius: 4,
    backgroundColor: (theme: Theme) =>
      progressColor
        ? progressColor
        : value && value === 100
        ? theme.palette.success.main
        : theme.palette.primary.contrastText,
  }),
  circleLabel: {
    position: "absolute",
    top: "50%",
    right: 0,
    transform: "translate(50%, -50%)",
    // width: circleLabel && circleLabel < 100 ? "1.8rem" : "unset",
    minWidth: "0.75rem",
    minHeight: "0.75rem",
    borderRadius: "1rem",
    backgroundColor: "inherit",
    padding: "0.1rem 0.3rem",
    textAlign: "center",
    fontSize: "0.9rem",
    lineHeight: "1.4em",
    color: (theme: Theme) => theme.palette.background.paper,

    "&:hover": {
      zIndex: 2,
    },
  },
  percentLabel: {
    display: "flex",
    flexDirection: "row",
    gap: "0.1rem",
    position: "absolute",
    width: "100%",
    justifyContent: "center",
    bottom: "1.5rem",
    fontSize: "1rem",
    color: (theme: Theme) => theme.palette.primary.contrastText,
    alignItems: "baseline",

    "> span": {
      fontSize: "0.875rem",
    },
  },
  percentLabelContainer: {
    width: "100%",
  },
  bottomLabelContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  bottomLabel: {
    display: "flex",
    fontSize: "0.875rem",
    lineHeight: "1em",
    fontWeight: 400,
    color: (theme: Theme) => theme.palette.complementary.lightWhite,
  },
};
