import { olapDimensionRequestData } from "CORE/api/core";
import { PREMIERS_MODEL_UUID } from "CORE/scheme/olap";
import { getVpIndicators } from "SRC/constants";
import { IPeriod } from "SRC/redux/slices/global/slices/filters";

const GET_ALL_PREMIERS_INFO = "get-all-premiers-info";

const vpIndicators = getVpIndicators(
  1572,
  1573,
  1574,
  1693,
  1694,
  1695,
  1696,
  1697,
  1698,
  1699,
  1700,
  1701,
  1702,
  1703,
  1704,
  1705,
  1706,
  1707,
  1708,
  1709,
  1710,
  1711,
  1712,
  1713,
  1714,
  1715,
  1716,
  1717,
  1718,
  1719,
  1720,
  1721,
  1722,
  1723,
  1724,
  1725,
  1726,
  1727,
  1728,
  1742,
  1743,
  1744,
  1745,
  1746,
  1747,
  1748,
  1749,
  1758,
  1759,
  1760,
  1761,
  1762,
  1763,
  1764,
  1765,
  1766,
  1767,
  1768,
  1797,
  1840,
  1841,
  5207,
  5208,
  5209,
  5248,
  // Контрольные точки, план
  5253,
  // Контрольные точки, факт
  5254,
  // Контрольные точки, невыполнено
  5255,
  // Контрольные точки, план, итого
  5256,
  // Контрольные точки, факт, итого
  5257,
  // Контрольные точки, невыполнено, итого
  5258,
  // Уровень достижения особое мнение
  5266,
  // Уровень достижения особое мнение итого
  5272,
  // Показатель СЭ, Факт
  5300,
  5301,
  5302,
  5304,
  5305,
  5374,
  // Показатель СЭ, Факт Итого
  5375,
  // Показатели ОМ Факт
  5391,
  // Показатели ОМ План
  5392,
  // Мероприятия ОМ Факт
  5393,
  // Мероприятия ОМ План
  5394,
  // Показатели, ОМ, Не выполнено
  5447,
  // Структурные элементы, ОМ, План
  5448,
  // Структурные элементы, ОМ, Факт
  5449,
  // Структурные элементы, ОМ, Не выполнено
  5450,
  // Мероприятия, ОМ, Не выполнено
  5451,
  // Показатели, ОМ, План, Итого
  5455,
  // Показатели, ОМ, Факт, Итого
  5456,
  // Показатели, ОМ, Не выполнено, Итого
  5457,
  // Структурные элементы, ОМ, План, Итого
  5458,
  // Структурные элементы, ОМ, Факт, Итого
  5459,
  // Структурные элементы, ОМ, Не выполнено, Итого
  5460,
  // Мероприятия ОМ, Факт, Итого
  5462,
  // Контрольные точки, ОМ, План
  5452,
  // Контрольные точки, ОМ, Факт
  5453,
  // Контрольные точки, ОМ, Не выполнено
  5454,
  // Мероприятия ОМ, Не выполнено, Итого
  5463,
  // Контрольные точки, ОМ, План, Итого
  5464,
  // Контрольные точки, ОМ, Факт, Итого
  5465,
  // Контрольные точки, ОМ, Не выполнено, Итого
  5466,
  // Мероприятия ОМ, План, Итого
  5488,
  5498,
  5499,
  5500,
  5501,
  5502,
  5503
);

export const getAllPremiersInfo = (period: IPeriod) => {
  return olapDimensionRequestData(
    [
      {
        requestId: "4145f492-0dff-4658-ab3d-7a2f010acf5d",
        type: "data2",
        params: {
          modelUuid: PREMIERS_MODEL_UUID,
          dashboardUuid: "",
          includeGaps: false,
          dimensions: [
            {
              id: "1683",
              includeItems: true,
              includeAttributesByCodes: [
                "NAME",
                "SHORT_NAME",
                "SORT_ORDER",
                "PERIOD_CODE",
              ],
            },
            {
              id: "1686",
              includeItems: true,
              includeAttributesByCodes: [
                "NAME",
                "SHORT_NAME",
                "SORT_ORDER",
                "VP_CODE",
                "VP_SHORT_NAME",
                "VP_PHOTO_URL",
                "VP_NAME",
              ],
            },
          ],
          indicators: {
            id: "1692",
            items: vpIndicators,
          },
          dataRequestCaching: true,
          dataSort: [
            {
              dim_id: "1683",
              sortField: "name",
              sortOrder: "asc",
            },
            {
              dim_id: "1686",
              sortField: "name",
              sortOrder: "asc",
            },
          ],
          dataFilter: {
            type: "AND",
            version: 1,
            operands: [
              {
                type: "STARTER",
                version: 1,
                dim_id: "1686",
              },
              {
                type: "EQ",
                version: 1,
                operands: [
                  {
                    type: "DIM",
                    version: 1,
                    id: "1683",
                  },
                  {
                    type: "CONST",
                    version: 1,
                    values: [period.apiV2],
                  },
                ],
              },
            ],
          },
        },
      },
    ],
    GET_ALL_PREMIERS_INFO
  );
};
