import theme from "SRC/theme";

export const css = {
  snackbar: {
    borderRadius: "50%",
  },
  snackbarContent: {
    fontSize: "1.3rem",
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.complementary.grayBlue,
  },
  progress: { marginRight: "1rem" },
  errorAlert: {
    backgroundColor: theme.palette.error.dark,
    color: theme.palette.charts.whiteTransparent,
    width: "100%",
    fontSize: "1.3rem",
  },
};
