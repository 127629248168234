import { ICustomTableProps } from "SRC/types";

export const getIndicatorsFactTotal = (
  tableProps: ICustomTableProps,
  isOM: boolean
) => {
  let { indicatorsFactTotal, indicatorsOMFactTotal } =
    tableProps?.rows?.[0]?.original || {};

  return {
    indicatorsFactTotal: isOM ? indicatorsOMFactTotal : indicatorsFactTotal,
  };
};
