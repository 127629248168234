import { WarningTriangle } from "ASSETS/svg";

import { Wrapper } from "./AtRiskCounter.styled";

interface IAtRiskCounterProps {
  value: number | JSX.Element;
}
export const AtRiskCounter = ({ value }: IAtRiskCounterProps) => {
  return (
    <Wrapper>
      <WarningTriangle />
      {value}
    </Wrapper>
  );
};
