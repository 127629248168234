import { olapRequestData3 } from "CORE/api/core";
import { MINISTRY_MODEL_UUID } from "CORE/scheme/olap";
import { getMinistriesIndicators } from "SRC/constants";
import { IPeriod } from "SRC/redux/slices/global/slices/filters";

const GET_TOP_WORST_MINISTRIES = "get-top-worst-ministries";

export const getTopWorstMinistries = (period: IPeriod) => {
  return olapRequestData3(
    [
      {
        requestId: "cad97218-83f5-47ec-b8cc-120ef4a63ff2",
        type: "data2",
        params: {
          modelUuid: MINISTRY_MODEL_UUID,
          dashboardUuid: "",
          includeGaps: false,
          dimensions: [
            {
              id: "1770",
              includeItems: true,
              includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
            },
            {
              id: "1774",
              includeItems: true,
              includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
            },
          ],
          indicators: {
            id: "1781",
            items: getMinistriesIndicators(1788),
            limit: {
              id: "1788",
              order: "asc",
              type: "TOP",
              value: 5,
            },
          },
          dataRequestCaching: true,
          dataFilter: {
            type: "AND",
            version: 1,
            operands: [
              {
                type: "STARTER",
                version: 1,
                dim_id: "1770",
              },
              {
                type: "EQ",
                version: 1,
                operands: [
                  {
                    type: "DIM",
                    version: 1,
                    id: "1774",
                  },
                  {
                    type: "CONST",
                    version: 1,
                    values: [period.apiV2],
                  },
                ],
              },
            ],
          },
        },
      },
    ],
    GET_TOP_WORST_MINISTRIES
  );
};
