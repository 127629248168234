import { useTheme } from "@mui/material";
import ReactECharts, { EChartsInstance } from "echarts-for-react";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { IncidentReport } from "SRC/components/IncidentReport";
import { ESources } from "SRC/constants/globals";
import { roundNumbersToFixed } from "SRC/helpers/roundNumbersToFixed";
import {
  IGlobalFilters,
  useGlobalFilters,
} from "SRC/redux/slices/global/hooks/useFilters";
import { IFilters, useFilters } from "SRC/redux/slices/main/hooks/useFilters";
import { IProgram, usePrograms } from "SRC/redux/slices/main/hooks/usePrograms";
import themeConfig from "SRC/theme";

import Tooltip from "./common/Tooltip";
import { css, EqualizerWrapper } from "./Equalizer.styled";
import { getOption } from "./options";

interface IChartEvent {
  name: string;
  event: { offsetX: number; offsetY: number };
  value: number;
}

const Equalizer = () => {
  const theme = useTheme<typeof themeConfig>();
  const { items, load } = usePrograms();
  const { program, filteredPrograms, setSelectedProgram }: IFilters =
    useFilters();
  const { sources } = useGlobalFilters();
  const { period, isSamePeriod }: IGlobalFilters = useGlobalFilters();

  const chartRef = useRef<EChartsInstance | null>(null);
  const [hovered, setHovered] = useState<string>("");
  const [coords, setCoords] = useState<[number, number]>([0, 0]);
  const { withSecret } = useGlobalFilters();
  const [codes, values]: [string[], number[]] = useMemo(
    () =>
      items
        .filter((item) => withSecret || !Number(item.info.secret))
        .reduce(
          (acc, item: IProgram) => {
            return [
              [...acc[0], String(item.info.code)],
              [
                ...acc[1],
                sources[ESources.OM]
                  ? roundNumbersToFixed(item.data[5265]?.sum || 0, 2)
                  : roundNumbersToFixed(item.data[1796]?.sum || 0, 2),
              ],
            ];
          },
          [[], []] as [string[], number[]]
        ),
    [items, sources, withSecret]
  );

  useEffect(() => {
    if (isSamePeriod(period.value)) {
      load(period);
    }
  }, [period]);

  const onHover = useCallback(
    (e: IChartEvent) => {
      if (hovered !== e.name && chartRef?.current) {
        const {
          ele: { clientHeight, clientWidth },
        } = chartRef.current;
        const {
          event: { offsetX, offsetY },
          name,
        } = e;

        setCoords([
          offsetX < clientWidth / 2 ? offsetX + 15 : offsetX - 310,
          offsetY < clientHeight / 2 ? offsetY : offsetY - clientHeight / 2,
        ]);
        setHovered((state) => (state === name ? state : name));
      }
    },
    [hovered, filteredPrograms]
  );

  const chartEvents = useMemo(
    () => ({
      click: (e: { name: string }) => {
        setSelectedProgram(program !== e.name ? e.name : "");
      },
      mouseover: (e: IChartEvent) => {
        if (!filteredPrograms.includes(e.name)) return;
        onHover(e);
      },
    }),
    [items, filteredPrograms, program]
  );

  const onBlur = useCallback(() => {
    setHovered("");
    setCoords([0, 0]);
  }, []);

  const options = useMemo(
    () =>
      getOption(
        theme,
        codes,
        values,
        filteredPrograms as string[],
        program,
        sources.OM
      ),
    [theme, codes, values, program, filteredPrograms, sources.OM]
  );

  return (
    <EqualizerWrapper>
      <IncidentReport className="incident-container">
        <ReactECharts
          option={options}
          style={css.graph}
          onEvents={chartEvents}
          ref={chartRef}
        />
      </IncidentReport>
      <Tooltip coords={coords} selected={hovered} onBlur={onBlur} />
    </EqualizerWrapper>
  );
};

export default Equalizer;
