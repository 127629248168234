import { Step, styled } from "@mui/material";

export const StepWrapper = styled(Step)(({ theme }) => {
  const {
    main: { gray },
    success: { main: green },
    error: { main: red },
    background: { default: dark },
    text: { secondary },
    primary: { contrastText },
  } = theme.palette;

  return {
    position: "relative",

    "& .step-label-date": {
      position: "absolute",
      right: 0,
      left: "0.5rem",
      fontSize: "1.125rem",
      marginBottom: "0.625rem",
      display: "flex",
      textAlign: "center",
      justifyContent: "center",
      fontWeight: "bold",
      color: green,
    },

    "& .date-plan": {
      color: secondary,
    },

    "& .date-finish": {
      minWidth: "6rem",
      display: "flex",
      justifyContent: "flex-end",

      "&.is-red": {
        color: red,
      },

      "&.is-green": {
        color: green,
      },

      "&.is-gray": {
        color: gray,
      },

      "&.is-dark": {
        color: dark,
      },

      "&:before": {
        content: "'/'",
        padding: "0 1ch",
      },
    },

    "& .deviation": {
      marginLeft: "1rem",
      fontSize: "1rem",
      alignSelf: "center",

      "&.is-red": {
        color: red,
      },

      "&.is-green": {
        color: green,
      },

      "&.is-gray": {
        color: gray,
      },

      "&.is-dark": {
        color: dark,
      },
    },

    "& .color-step": {
      position: "relative",
      left: 0,
      zIndex: 1,
      margin: "0.7rem 0px",
      width: "2rem",
      height: "2rem",
      borderRadius: "50%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      background: contrastText,

      "&.is-red": {
        border: `0.4rem solid ${red}`,
      },

      "&.is-green": {
        border: `0.4rem solid ${green}`,
      },

      "&.is-gray": {
        border: `0.4rem solid ${gray}`,
      },

      "&.is-dark": {
        border: `0.4rem solid ${dark}`,
      },
    },

    "& .expand-icon": {
      marginLeft: "0.5rem",
      width: "0.8rem",
      height: "0.8rem",
      cursor: "pointer",
    },

    "& .stepper-connector": {
      position: "absolute",
      width: "100%",
      height: "0.7rem",
      left: "-50%",

      "&.is-red": {
        backgroundColor: red,
      },

      "&.is-green": {
        backgroundColor: green,
      },

      "&.is-gray": {
        backgroundColor: gray,
      },

      "&.is-dark": {
        backgroundColor: dark,
      },
    },
  };
});
