import { useMemo } from "react";
import { useSubjects } from "SRC/redux/slices/event/hooks/useSubjects";

import { createSubjectsTableData, getSubjectStatuses } from "../utils";

export const useSubjectTableData = () => {
  const { items: subjects } = useSubjects();

  const subjectsTableData = useMemo(() => {
    return createSubjectsTableData(subjects);
  }, [subjects]);

  const subjectsStatuses = useMemo(() => {
    return getSubjectStatuses(subjectsTableData);
  }, [subjects]);

  return {
    subjectsTableData,
    subjectsStatuses,
  };
};
