import { getIsSecret } from "SRC/pages/Summary/common/SummaryComplexGrid/utils";
import { ICustomTableProps } from "SRC/types";

export const getStructureElementsFact = (
  tableProps: ICustomTableProps,
  isOM: boolean
) => {
  const { structureElementsFact, structureElementsOMFact } =
    tableProps.row.original;

  return {
    isSecret: getIsSecret(tableProps),
    structureElementsFact: isOM
      ? structureElementsOMFact
      : structureElementsFact,
  };
};
