import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  SxProps,
} from "@mui/material";
import { SmallWhiteArrowDown } from "ASSETS/svg/arrow";
import { ReactComponent as CloseButton } from "ASSETS/svg/close/close.svg";
import { CSSProperties, Key, useMemo } from "react";
import { useGlobalFilters } from "SRC/redux/slices/global/hooks/useFilters";

import { css } from "./Select.styled";

export interface IOption<Value extends Key> {
  value: Value;
  label: string | number | undefined;
  disabled?: boolean;
}

interface IStyle {
  labelStyle?: SxProps;
  selectStyle?: SxProps;
  optionStyle?: SxProps;
}

interface ISelect<T extends Key> {
  value?: T;
  onChange(value?: T): void;
  options: IOption<T>[];
  hasAllOption?: boolean;
  label?: string;
  staticLabel?: boolean;
  inline?: boolean;
  colorizeActiveOption?: boolean;
  style?: IStyle;
}

const BaseSelect = <Value extends Key>({
  value,
  onChange,
  options,
  staticLabel = true,
  inline = false,
  label,
  hasAllOption = true,
  colorizeActiveOption = true,
  style = {} as IStyle,
}: ISelect<Value>) => {
  const optionsJSX: JSX.Element[] = useMemo(
    () =>
      options.map((item: IOption<Value>) => (
        <MenuItem
          disabled={item.disabled}
          sx={style.optionStyle}
          key={item.value}
        >
          {item.label}
        </MenuItem>
      )),
    [options, style.optionStyle]
  );
  const { withSecret, toggleSecret } = useGlobalFilters();
  const handleSelectProgram = (event: SelectChangeEvent) => {
    onChange(event.target.value as Value);
  };

  const handleCloseFilter = () => {
    if (value !== "") onChange(undefined);
    if (value === "secret") toggleSecret(!withSecret);
  };

  return (
    <FormControl variant="standard" sx={css.element(inline)}>
      {label && staticLabel ? (
        <Box sx={{ ...css.label, ...style.labelStyle }}>{label}</Box>
      ) : (
        <InputLabel>{label}</InputLabel>
      )}
      <Select
        value={value as string}
        onChange={handleSelectProgram}
        label={label}
        sx={
          css.select(value, colorizeActiveOption, style.selectStyle) as SxProps
        }
        displayEmpty={hasAllOption}
        IconComponent={() => (
          <Box sx={css.iconComponent as SxProps}>
            {value !== "" ? (
              <Box onClick={handleCloseFilter}>
                <CloseButton style={css.closeButton as CSSProperties} />
              </Box>
            ) : null}
            <Box sx={{ display: "flex" }}>
              <SmallWhiteArrowDown style={css.arrowButton as CSSProperties} />
            </Box>
          </Box>
        )}
      >
        {hasAllOption && (
          <MenuItem
            value=""
            key=""
            sx={{ ...css.allItem, ...style.optionStyle } as SxProps}
          >
            Все
          </MenuItem>
        )}
        {optionsJSX}
      </Select>
    </FormControl>
  );
};

export default BaseSelect;
