import { useMemo } from "react";
import { IAchievementData } from "SRC/components/AchievementsTile/interfaces";
import { useTopBestGP2 } from "SRC/redux/slices/summary/hooks/useTopBestGP2";
import { useTopBestGpOM } from "SRC/redux/slices/summary/hooks/useTopBestGpOM";
import { useTopBestMinistries2 } from "SRC/redux/slices/summary/hooks/useTopBestMinistries2";
import { useTopBestMinistriesOM } from "SRC/redux/slices/summary/hooks/useTopBestMinistriesOM";
import { useTopBestVP2 } from "SRC/redux/slices/summary/hooks/useTopBestVP2";
import { useTopBestVpOpinion } from "SRC/redux/slices/summary/hooks/useTopBestVpOpinion";
import { useTopWorstGP2 } from "SRC/redux/slices/summary/hooks/useTopWorstGP2";
import { useTopWorstGpOM } from "SRC/redux/slices/summary/hooks/useTopWorstGpOM";
import { useTopWorstMinistries2 } from "SRC/redux/slices/summary/hooks/useTopWorstMinistries2";
import { useTopWorstMinistriesOM } from "SRC/redux/slices/summary/hooks/useTopWorstMinistriesOM";
import { useTopWorstVP2 } from "SRC/redux/slices/summary/hooks/useTopWorstVP2";
import { useTopWorstVpOpinion } from "SRC/redux/slices/summary/hooks/useTopWorstVpOpinion";

import {
  createBestGP2Data,
  createBestGpOMData,
  createBestVP2Data,
  createTopMinistriesData,
  createWorstGP2Data,
  createWorstGpOMData,
  createWorstVP2Data,
  sortRatingDataBySum,
} from "./utils";

interface IUseRatingData {
  bestGPData: IAchievementData[];
  worstGPData: IAchievementData[];
  bestVPData: IAchievementData[];
  worstVPData: IAchievementData[];
  bestMinistriesData: IAchievementData[];
  bestMinistriesOMData: IAchievementData[];
  worstMinistriesData: IAchievementData[];
  worstMinistriesOMData: IAchievementData[];
  bestGpOMData: IAchievementData[];
  worstGpOMData: IAchievementData[];
  dataWorstVpOpinion: IAchievementData[];
  dataBestVpOpinion: IAchievementData[];
  worstVpOpinionFetching: boolean;
  bestVpOpinionFetching: boolean;
  bestGPFetching: boolean;
  worstGPFetching: boolean;
  bestVPFetching: boolean;
  worstVPFetching: boolean;
  bestMinistriesFetching: boolean;
  bestMinistriesOMFetching: boolean;
  worstMinistriesFetching: boolean;
  worstMinistriesOMFetching: boolean;
  bestGpOMFetching: boolean;
  worstGpOMFetching: boolean;
}

export const useRatingData = (): IUseRatingData => {
  const { items: itemsWorstGP2, fetching: worstGP2Fetching } = useTopWorstGP2();
  const { items: itemsBestGP2, fetching: bestGP2Fetching } = useTopBestGP2();
  const { items: itemsBestGpOM, fetching: bestGpOMFetching } = useTopBestGpOM();
  const { items: itemsWorstGpOM, fetching: worstGpOMFetching } =
    useTopWorstGpOM();

  const { items: itemsWorstVP2, fetching: worstVP2Fetching } = useTopWorstVP2();
  const { items: itemsBestVP2, fetching: bestVP2Fetching } = useTopBestVP2();
  const { items: itemsTopBestVpOpinion, fetching: bestVpOpinionFetching } =
    useTopBestVpOpinion();
  const { items: itemsTopWorstVpOpinion, fetching: worstVpOpinionFetching } =
    useTopWorstVpOpinion();

  const { items: itemsWorstMinistries2, fetching: worstMinistries2Fetching } =
    useTopWorstMinistries2();
  const { items: itemsBestMinistries2, fetching: bestMinistries2Fetching } =
    useTopBestMinistries2();
  const { items: itemsBestMinistriesOM, fetching: bestMinistriesOMFetching } =
    useTopBestMinistriesOM();
  const { items: itemsWorstMinistriesOM, fetching: worstMinistriesOMFetching } =
    useTopWorstMinistriesOM();

  // Получение лидирующих Министерств по результативности
  const dataBestMinistries2 = useMemo(
    () => createTopMinistriesData(itemsBestMinistries2),
    [itemsBestMinistries2]
  );

  // Получение лидирующих Министерств по результативности OM
  const dataBestMinistriesOM = useMemo(
    () => createTopMinistriesData(itemsBestMinistriesOM),
    [itemsBestMinistriesOM]
  );

  // Получение отстающих Министерств по результативности
  const dataWorstMinistries2 = useMemo(
    () => createTopMinistriesData(itemsWorstMinistries2),
    [itemsWorstMinistries2]
  );

  // Получение отстающих Министерств по результативности
  const dataWorstMinistriesOM = useMemo(
    () => createTopMinistriesData(itemsWorstMinistriesOM),
    [itemsWorstMinistriesOM]
  );

  // Получение лидирующих ГП по результативности
  const dataBestGP2 = useMemo(
    () => createBestGP2Data(itemsBestGP2),
    [itemsBestGP2]
  );
  // Получение лидирующих ГП без Особого мнения
  const dataBestGpOM = useMemo(
    () => createBestGpOMData(itemsBestGpOM),
    [itemsBestGpOM]
  );
  // Получение отстающих ГП без Особого мнения
  const dataWorstGpOM = useMemo(
    () => createWorstGpOMData(itemsWorstGpOM),
    [itemsWorstGpOM]
  );

  // Получение отстающих ГП по результативности
  const dataWorstGP2 = useMemo(
    () => createWorstGP2Data(itemsWorstGP2),
    [itemsWorstGP2]
  );

  // Лидирующие Министерства в зависимости от метода расчёта
  const bestMinistriesData = useMemo(() => {
    return sortRatingDataBySum(dataBestMinistries2);
  }, [dataBestMinistries2]);

  // Лидирующие OM Министерства в зависимости от метода расчёта
  const bestMinistriesOMData = useMemo(() => {
    return sortRatingDataBySum(dataBestMinistriesOM);
  }, [dataBestMinistriesOM]);

  // Отстающие OM Министерства в зависимости от метода расчёта
  const worstMinistriesOMData = useMemo(() => {
    return sortRatingDataBySum(dataWorstMinistriesOM);
  }, [dataWorstMinistriesOM]);

  // Отстающие Министерства в зависимости от метода расчёта
  const worstMinistriesData = useMemo(() => {
    return sortRatingDataBySum(dataWorstMinistries2);
  }, [dataWorstMinistries2]);

  // Лидирующие ГП без особого мнения
  const bestGpOMData = useMemo(() => {
    return sortRatingDataBySum(dataBestGpOM);
  }, [dataBestGpOM]);

  // Отстающие ГП без особого мнения
  const worstGpOMData = useMemo(() => {
    return sortRatingDataBySum(dataWorstGpOM);
  }, [dataWorstGpOM]);

  const bestGPData = useMemo(() => {
    return sortRatingDataBySum(dataBestGP2);
  }, [dataBestGP2]);

  // Отстающие ГП в зависимости от метода расчёта
  const worstGPData = useMemo(() => {
    return sortRatingDataBySum(dataWorstGP2);
  }, [dataWorstGP2]);

  // Получение лидирующих ВП по результативности
  const dataBestVP2 = useMemo(
    () => createBestVP2Data(itemsBestVP2),
    [itemsBestVP2]
  );

  // Получение отстающих ВП по результативности
  const dataWorstVP2 = useMemo(
    () => createWorstVP2Data(itemsWorstVP2),
    [itemsWorstVP2]
  );

  const dataBestVpOpinion = useMemo(
    () => createBestVP2Data(itemsTopBestVpOpinion),
    [itemsTopBestVpOpinion]
  );

  const dataWorstVpOpinion = useMemo(
    () => createWorstVP2Data(itemsTopWorstVpOpinion),
    [itemsTopWorstVpOpinion]
  );

  return {
    bestGPData,
    worstGPData,
    bestVPData: dataBestVP2,
    worstVPData: dataWorstVP2,
    bestMinistriesData,
    worstMinistriesData,
    bestGpOMData,
    worstGpOMData,
    bestGPFetching: bestGP2Fetching,
    worstGPFetching: worstGP2Fetching,
    bestVPFetching: bestVP2Fetching,
    worstVPFetching: worstVP2Fetching,
    bestMinistriesFetching: bestMinistries2Fetching,
    worstMinistriesFetching: worstMinistries2Fetching,
    bestGpOMFetching,
    worstGpOMFetching,
    dataBestVpOpinion,
    dataWorstVpOpinion,
    bestVpOpinionFetching,
    worstVpOpinionFetching,
    bestMinistriesOMData,
    worstMinistriesOMData,
    bestMinistriesOMFetching,
    worstMinistriesOMFetching,
  };
};
