import { styled, Theme } from "@mui/material";

export const Wrapper = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  gap: "0.25rem",
  ".status-name": {
    fontSize: "0.8rem",
  },
}));

export const css = {
  color: (label: string, opinion: boolean | undefined, theme: Theme) => ({
    width: "0.5rem",
    height: "0.5rem",
    flex: "0 0 auto",
    borderRadius: "50%",
    backgroundColor: () => {
      if (opinion) {
        return label === "done"
          ? theme.palette.pale.paleGreen
          : label === "fail"
          ? theme.palette.pale.paleRed
          : "unset";
      } else {
        return label === "done"
          ? theme.palette.pale.green
          : label === "fail"
          ? theme.palette.error.light
          : "unset";
      }
    },
  }),
};
