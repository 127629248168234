import React, { useEffect, useMemo, useState } from "react";
import { TableInstance } from "react-table";
import { OpenerButton, TableExportButton } from "SRC/components/Buttons";
import { ExtendedTabs, ITab } from "SRC/components/ExtendedTabs/ExtendedTabs";
import { useTableData as useTableDataMinistries } from "SRC/hooks/summaryTable/useSummaryMinistries";
import { useTableData as useTableDataPremiers } from "SRC/hooks/summaryTable/useSummaryPremiers";
import { useTableData as useTableDataPrograms } from "SRC/hooks/summaryTable/useSummaryPrograms";
import { GovProgramsFilters } from "SRC/pages/Main/common/ComplexGrid/filterComponents/GovProgramsFilters";
import { MinistriesFilters } from "SRC/pages/Main/common/ComplexGrid/filterComponents/MinistriesFilters";
import { VicePremiersFilters } from "SRC/pages/Main/common/ComplexGrid/filterComponents/VicePremiersFilters";
import { GovProgramsTab } from "SRC/pages/Main/common/ComplexGrid/GovProgramsTab/GovProgramsTab";
import { MinistriesTab } from "SRC/pages/Main/common/ComplexGrid/MinistriesTab/MinistriesTab";
import { VicePremiersTab } from "SRC/pages/Main/common/ComplexGrid/VicePremiersTab/VicePremiersTab";
import { IFilters, useFilters } from "SRC/redux/slices/main/hooks/useFilters";
import { EGridTabs } from "SRC/redux/slices/summary/slices/filters";
import { IExportData } from "SRC/types";

type TExportData<T> = {
  [key: string]: IExportData<T>;
};

export const ComplexGrid = () => {
  const {
    setSelectedComplexGridTab,
    complexGridTab,
    setSelectTypeProgram,
    setSelectedProgram,
  }: IFilters = useFilters();
  const { programsMainExport: programsExport } = useTableDataPrograms();
  const { premiersMainExport: premiersExport } = useTableDataPremiers();
  const { ministriesMainExport: ministriesExport } = useTableDataMinistries();
  const [modalOpen, setModalOpen] = useState(false);
  const [tableInstance, setTableInstance] = useState<TableInstance | undefined>(
    undefined
  );

  useEffect(() => {
    setSelectTypeProgram("");
    setSelectedProgram("");

    return () => {
      setSelectedProgram("");
      setSelectTypeProgram("");
    };
  }, []);
  const onOpenModal = (): void => setModalOpen(true);

  const tabsHandler = (value: EGridTabs) => {
    setModalOpen(false);
    setTableInstance(undefined);
    setSelectedComplexGridTab(value);
    setSelectedProgram("");
    setSelectTypeProgram("");
  };

  const tabs: ITab<EGridTabs>[] = [
    {
      value: EGridTabs.PROGRAMS,
      label: "Госпрограммы",
      component: (
        <GovProgramsTab
          isModalOpen={modalOpen}
          setModalOpen={setModalOpen}
          instance={tableInstance}
          setInstance={setTableInstance}
        />
      ),
    },
    {
      value: EGridTabs.PREMIERS,
      label: "Вице-премьеры",
      component: (
        <VicePremiersTab
          isModalOpen={modalOpen}
          setModalOpen={setModalOpen}
          instance={tableInstance}
          setInstance={setTableInstance}
        />
      ),
    },
    {
      value: EGridTabs.MINISTRIES,
      label: "Министерства",
      component: (
        <MinistriesTab
          isModalOpen={modalOpen}
          setModalOpen={setModalOpen}
          instance={tableInstance}
          setInstance={setTableInstance}
        />
      ),
    },
  ];

  const rightSideFilters = useMemo(() => {
    switch (complexGridTab) {
      case EGridTabs.PROGRAMS:
        return <GovProgramsFilters instance={tableInstance} />;
      case EGridTabs.PREMIERS:
        return <VicePremiersFilters instance={tableInstance} />;
      case EGridTabs.MINISTRIES:
        return <MinistriesFilters instance={tableInstance} />;
    }
  }, [tableInstance, complexGridTab]);

  const tabExportData: IExportData<Record<string, any>> = useMemo(() => {
    const exportData: TExportData<Record<string, any>> = {
      [EGridTabs.PROGRAMS]: programsExport,
      [EGridTabs.PREMIERS]: premiersExport,
      [EGridTabs.MINISTRIES]: ministriesExport,
    };
    return exportData[complexGridTab];
  }, [complexGridTab, programsExport, premiersExport, ministriesExport]);

  return (
    <ExtendedTabs
      value={complexGridTab}
      onChange={tabsHandler}
      tabs={tabs}
      contained
      additional={rightSideFilters}
      actions={[
        <TableExportButton {...tabExportData} key="1" />,
        <OpenerButton open={modalOpen} setOpen={onOpenModal} key="2" />,
      ]}
    />
  );
};
