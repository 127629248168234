export const css = {
  tabs: {
    fontSize: "1.2rem",
    fontWeight: "bold",
  },
  WIPTag: {
    display: "inline-block",
    height: "2rem",
    lineHeight: "2rem",
    marginLeft: "0.5rem",
    verticalAlign: "super",
  },
};
