import { Box, Stack, SxProps, useMediaQuery, useTheme } from "@mui/material";
import React, {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useCallback,
  useMemo,
} from "react";
import { Column, TableInstance } from "react-table";
import { TableModal } from "SRC/components/FullPageTableModal/FullPageTableModal";
import { IncidentReport } from "SRC/components/IncidentReport";
import { SecretSwitcher } from "SRC/components/SecretSwitcher";
import { ColumnsController } from "SRC/components/Table";
import Table from "SRC/components/Table";
import { TableActionsButtons } from "SRC/components/TableActionsButtons/TableActionsButtons";
import { useTableData } from "SRC/hooks/summaryTable/useSummaryPremiers";
import { createPremierRowsTableData } from "SRC/hooks/summaryTable/utils/summaryData/summaryDataVP";
import { RatingTabs } from "SRC/pages/Summary/common/Rating/RatingTabs/RatingTabs";
import { css } from "SRC/pages/Summary/common/SummaryComplexGrid/SummaryComplexGrid.styled";
import { useRatingData } from "SRC/pages/Summary/useRatingData";
import {
  IGlobalFilters,
  useGlobalFilters,
} from "SRC/redux/slices/global/hooks/useFilters";
import { useFilters } from "SRC/redux/slices/summary/hooks/useFilters";
import { usePremiers } from "SRC/redux/slices/summary/hooks/usePremiers";
import { CustomRowProps } from "SRC/types/data/data";

import { getGeneralInfoPremiersColumns } from "./tableColumns";

interface IGeneralInfoPremiersTab {
  posTable?: string;
  forwardRef?: MutableRefObject<HTMLElement | undefined>;
  onScroll?: (scroll: any) => void;
  isModalOpen?: boolean;
  setModalOpen?: (value: boolean) => void;
  instance: TableInstance | undefined;
  setInstance: Dispatch<SetStateAction<TableInstance<{}> | undefined>>;
}

const sortedColumns = [
  "№",
  "Кол-во госпрограмм",
  "Вице - премьер",
  "План, млрд ₽",
  "Факт, %",
  "План",
  "Факт",
  "Не выполнено",
  "Уровень достижения, %",
];
export const staticColumns = [
  "icon",
  "expander",
  "id",
  "vicePremierPhoto",
  "vicePremier",
  "govPrograms",
];

export const RenderTable = ({
  posTable,
  forwardRef,
  onScroll,
  isModalOpen,
  setModalOpen,
  instance,
  setInstance,
}: IGeneralInfoPremiersTab) => {
  const theme = useTheme();
  const { sources, withSecret }: IGlobalFilters = useGlobalFilters();
  const { setSearchRows } = useFilters();
  const { fetching } = usePremiers();
  const {
    premiersSummaryData: premiersData,
    premiersSummaryExport: premiersExport,
  } = useTableData();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));
  const columns: Column[] = useMemo(() => {
    return getGeneralInfoPremiersColumns(sources.OM);
  }, [sources.OM]);

  const { allColumns } = instance || {};

  const tableHeight: string | undefined = useMemo(() => {
    if (isModalOpen) return;
    return matches ? css.tableHeightLong : css.tableHeight;
  }, [isModalOpen, matches]);

  const onChangeFilterRows = useCallback(
    (rows: CustomRowProps[]) => {
      setSearchRows(createPremierRowsTableData(rows, sources.OM, withSecret));
    },
    [sources.OM, withSecret]
  );

  return (
    <>
      {isModalOpen && (
        <Box sx={css.modalActionButtonsContainer as SxProps}>
          <SecretSwitcher />
          <TableActionsButtons
            open={isModalOpen}
            setOpen={setModalOpen}
            {...premiersExport}
          />
          {allColumns && (
            <ColumnsController
              allColumns={allColumns}
              statics={staticColumns}
            />
          )}
        </Box>
      )}
      <Box sx={css.tablesWrapper}>
        <IncidentReport>
          <Table
            data={premiersData}
            columns={columns}
            loading={fetching}
            sortedColumns={sortedColumns}
            positionTable={posTable}
            tableHeight={tableHeight}
            forwardRef={forwardRef}
            onScroll={onScroll}
            noDataCondition={premiersData?.length === 0}
            getInstance={setInstance}
            classes={{
              tr: "table-row",
              tfoot: "table-footer",
              tbody: "table-body",
            }}
            sticky
            expandable
            onFilterRows={onChangeFilterRows}
          />
        </IncidentReport>
      </Box>
    </>
  );
};

export const GeneralFullInfoPremiersTab = (props: IGeneralInfoPremiersTab) => {
  const { isModalOpen, setModalOpen } = props;

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));
  const {
    bestVPData,
    worstVPData,
    dataBestVpOpinion,
    dataWorstVpOpinion,
    bestVpOpinionFetching,
    worstVpOpinionFetching,
    bestVPFetching,
    worstVPFetching,
  } = useRatingData();

  const { sources }: IGlobalFilters = useGlobalFilters();

  const topBest = useMemo(
    () =>
      sources.OM
        ? { data: dataBestVpOpinion, fetching: bestVpOpinionFetching }
        : { data: bestVPData, fetching: bestVPFetching },
    [
      bestVPData,
      bestVPFetching,
      bestVpOpinionFetching,
      dataBestVpOpinion,
      sources.OM,
    ]
  );
  const topWorst = useMemo(
    () =>
      sources.OM
        ? { data: dataWorstVpOpinion, fetching: worstVpOpinionFetching }
        : { data: worstVPData, fetching: worstVPFetching },
    [
      dataWorstVpOpinion,
      sources.OM,
      worstVPData,
      worstVPFetching,
      worstVpOpinionFetching,
    ]
  );

  const { data: dataWorst, fetching: worstFetching } = topWorst;
  const { data: dataBest, fetching: bestFetching } = topBest;

  return (
    <Stack style={css.tabContainer}>
      <Box sx={{ pb: "1rem" }}>
        <RatingTabs
          dataBest={dataBest}
          dataWorst={dataWorst}
          worstFetching={worstFetching}
          bestFetching={bestFetching}
        />
      </Box>
      <RenderTable {...props} />
      {!matches && (
        <TableModal
          open={isModalOpen}
          setOpen={setModalOpen}
          tableName="Сводная таблица. Вице-премьеры"
        >
          <RenderTable {...props} />
        </TableModal>
      )}
    </Stack>
  );
};
