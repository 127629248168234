import React, { useEffect, useState } from "react";

import { getEnpvAuthToken } from "./utils";

type TEnpvVideo = {
  url: string;
};
export const EnpvVideo = ({ url }: TEnpvVideo) => {
  const [cameraId, setCameraId] = useState<string>("");

  const authToken = getEnpvAuthToken();

  useEffect(() => {
    try {
      const urlObj = new URL(url);

      setCameraId(urlObj.searchParams.get("id") || "");
    } catch (e) {
      console.error(e);
    }
  }, [url]);

  if (!authToken || !cameraId) {
    return null;
  }

  return (
    <iframe
      src={`https://enpv.ru/embed/byKey?at=${authToken}&id=${cameraId}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded"
    />
  );
};
