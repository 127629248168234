import { getIsSecret } from "SRC/pages/Summary/common/SummaryComplexGrid/utils";
import { ICustomTableProps, PROGRAM_TABS } from "SRC/types";
import { createProgramCardUrl, PROGRAM_VIEW_TAB } from "SRC/urls";

export const getIndicatorsSEFail = (
  tableProps: ICustomTableProps,
  isOM: boolean
) => {
  const { id, indicatorsSEFail, indicatorsSEOMFail } = tableProps.row.original;

  const url = createProgramCardUrl(
    PROGRAM_VIEW_TAB,
    id,
    PROGRAM_TABS.INDICATORS
  );

  return {
    url,
    isSecret: getIsSecret(tableProps),
    indicatorsSEFail: isOM ? indicatorsSEOMFail : indicatorsSEFail,
  };
};
