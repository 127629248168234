import { Theme } from "@mui/material";

export const css = {
  filledInput: {
    fontSize: "1.2rem",
    "::before, ::after": {
      borderBottom: "none!important",
    },
    backgroundColor: (theme: Theme) =>
      `${theme.palette.background.paper} !important`,
  },
};
