export const thousandSeparator = (str: string) => {
  const notNumberArr = ["null", "undefined", "NaN"];
  if (notNumberArr.includes(str)) return 0;
  if (Number(str) < 1000) return str;
  let [mainPart, decimalPart = ""] = str.split(".");
  const reversed = mainPart.split("").reverse().join("");

  const thousandPartsReversed = reversed.match(/.{1,3}/g) || [];

  const thousandParts = thousandPartsReversed.map((part) =>
    part.split("").reverse().join("")
  );

  if (decimalPart) {
    decimalPart = "." + decimalPart;
  }

  return thousandParts.reverse().join(" ") + decimalPart;
};
