import { createTheme } from "@mui/material";
import * as React from "react";

declare module "@mui/material/styles" {
  interface mainPalette {
    gray: string;
    red: string;
    blue: string;
    darkBlue: string;
    yellow: string;
  }
  interface compPalette {
    vividRed: string;
    darkRed: string;
    darkRedTransparent: string;
    whiteTransparent: string;
    redTransparent: string;
    blue: string;
    blueHover: string;
    darkBlue: string;
    darkBlueTransparent: string;
    darkBlueLight: string;
    darkBlueLightTransparent: string;
    darkBlueHover: string;
    grayBlue: string;
    grayBorder: string;
    darkGrayBlue: string;
    grayGreen: string;
    darkViolet: string;
    lightViolet: string;
    darkGreenTransparent: string;
    greenTransparent: string;
    lightShadow: string;
    lightWhite: string;
    lightWhite2: string;
    unitsGray: string;
    linearDarkBlue: string;
    linearDarkBlue2: string;
  }
  interface palePalette {
    red: string;
    green: string;
    paleRed: string;
    paleGreen: string;
    blue: string;
    gray: string;
    yellow: string;
  }
  interface chartsPalette {
    splitLine: string;
    grayBlue: string;
    white: string;
    blueArea: string;
    blueAreaTransparent: string;
    lightBlue: string;
    green: string;
    red: string;
    opacityGray: string;
    grayTransparent: string;
    greenTransparent: string;
    redTransparent: string;
    whiteTransparent: string;
    blueAreaOpacity: string;
    darkBlueBackground: string;
  }
  interface labelPalette {
    violet: string;
    blue: string;
    pink: string;
  }
  interface Palette {
    charts: chartsPalette;
    main: mainPalette;
    complementary: compPalette;
    pale: palePalette;
    labelTab: labelPalette;
  }
  interface PaletteOptions {
    main: mainPalette;
    complementary: compPalette;
    charts: chartsPalette;
    pale: palePalette;
    labelTab: labelPalette;
  }
}
declare module "@mui/material/styles/createPalette" {
  interface TypeBackground {
    header: string;
    default: string;
    defaultTransparent: string;
    paperTransparent: string;
    paperGray: string;
    paperDarkBlue: string;
    paperDarkGray: string;
    opacityWhite: string;
    paperBorderGray: string;
    buttonDarkBlue: string;
    progressDarkBlue: string;
    headerControl: string;
    headerControlActive: string;
    rowHoverWhite: string;
    rowHoverWhiteMain: string;
  }

  interface TypeText {
    onAccent: string;
    opinion: string;
  }
}

declare module "@mui/material/styles/createTypography" {
  interface Typography {
    fontWeightSemiBold: React.CSSProperties["fontWeight"];
  }

  interface TypographyOptions {
    fontWeightSemiBold?: React.CSSProperties["fontWeight"];
  }
}

declare module "@mui/material/styles/createMixins" {
  interface Mixins {
    borderValue: (width?: string) => string;
    border: (width?: string) => {
      border: string;
    };
  }
}

let theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 1000,
      md: 1900,
      lg: 3800,
      xl: 7600,
    },
  },
  palette: {
    mode: "dark",
    main: {
      red: "#e93438",
      darkBlue: "#212735",
      blue: "#4a79d8",
      gray: "#a0b0d0",
      yellow: "#E7DE4D",
    },
    complementary: {
      vividRed: "#D22F33",
      darkRed: "#921D35",
      darkRedTransparent: "#9D343FB3",
      whiteTransparent: "#FFFFFF26",
      redTransparent: "#CC0C31C0",
      blue: "#063dad",
      blueHover: "#3168D7",
      darkBlue: "#2E364A",
      darkBlueTransparent: "#2E364AAA",
      darkBlueLight: "#262D3E",
      darkBlueLightTransparent: "#262D3Eff",
      darkBlueHover: "#31394e",
      grayBlue: "#58637B",
      grayBorder: "#4D5567",
      darkGrayBlue: "#363E53",
      grayGreen: "#30404b",
      darkViolet: "#3a3648",
      lightViolet: "#393B5D",
      darkGreenTransparent: "#409758B3",
      greenTransparent: "#4CD961A6",
      lightShadow: "#6E799433",
      lightWhite: "#FFFFFF80",
      lightWhite2: "#FFFFFF0C",
      unitsGray: "#8391A0",
      linearDarkBlue: "#04040540",
      linearDarkBlue2: "#0E101459",
    },
    pale: {
      red: "#FF6682",
      green: "#45E595",
      paleRed: "#FFB3C0",
      paleGreen: "#B3FFE6",
      blue: "#79A7EB",
      gray: "#A0B0D1",
      yellow: "#FFAC54",
    },
    primary: {
      main: "#4a79d8",
      contrastText: "#FFFFFF",
    },
    error: {
      main: "#cc0c31",
      light: "#FF6682",
    },
    success: {
      main: "#4cd961",
      light: "#45E595",
    },
    background: {
      default: "#212735",
      defaultTransparent: "#21273580",
      header: "#cc0c31",
      paper: "#2e364a",
      paperTransparent: "#2e364aff",
      paperGray: "#282D40",
      paperDarkBlue: "#272e3e",
      paperDarkGray: "#252b3b",
      opacityWhite: "#FFFFFF19",
      paperBorderGray: "#53647C",
      buttonDarkBlue: "#091A2B47",
      progressDarkBlue: "#131529",
      headerControl: "#00000080",
      headerControlActive: "#FA758E",
      rowHoverWhite: "#444B5D",
    },
    text: {
      primary: "#a0b0d0",
      secondary: "#58637B",
      disabled: "#3f4859",
      onAccent: "#ffffff",
      opinion: "#B2B9FF",
    },
    divider: "#58637b",
    charts: {
      grayBlue: "#58637B",
      white: "#f7f7f7",
      splitLine: "#A0B0D019",
      blueArea: "#4A79D8E6",
      blueAreaTransparent: "#4A79D800",
      blueAreaOpacity: "#4A79D819",
      lightBlue: "#32769F",
      green: "#4CD961",
      red: "#CC0C31",
      opacityGray: "#32769F0C",
      grayTransparent: "#A0B0D019",
      greenTransparent: "#4CD961B3",
      whiteTransparent: "#FFFFFFB3",
      redTransparent: "#E93438B3",
      darkBlueBackground: "#0D132159",
    },
    labelTab: {
      violet: "#6A32DB",
      blue: "#48ABE8",
      pink: "#D6387F",
    },
  },
  typography: {
    fontFamily: "Oswald, sans-serif",
    h1: {
      fontSize: "2rem",
      fontWeight: "bold",
    },
    h2: {
      fontSize: "1.5rem",
      fontWeight: 500,
    },
    h3: {
      fontSize: "1rem",
      fontWeight: 400,
    },
    h4: {
      fontWeight: "bold",
      fontSize: "1rem",
      lineHeight: 1.4,
    },
    h5: {
      fontWeight: "bold",
      fontSize: "0.8rem",
      lineHeight: 1.4,
    },
    h6: {
      fontWeight: "bold",
      fontSize: "0.66rem",
      lineHeight: 1.4,
    },
    caption: {
      fontSize: "0.66rem",
    },
    subtitle1: {
      fontSize: "0.66rem",
      lineHeight: 1.4,
    },
    subtitle2: {
      fontSize: "0.5rem",
    },
  },
  mixins: {
    borderValue: (width?: string) =>
      `${width || "1px"} solid ${theme.palette.divider}`,
    border: (width?: string) => ({
      border: theme.mixins.borderValue(width),
    }),
  },
  shape: {
    borderRadius: 8,
  },
});

theme = createTheme(theme, {
  components: {
    MuiSelect: {
      styleOverrides: {
        root: {
          flex: 1,
        },
        select: {
          fontSize: "1rem",
          lineHeight: "1.5em",
          padding: "0.5rem 0.9rem",
          borderRadius: "0.5rem",
          backgroundColor: "#2E364A",
          maxWidth: "100%",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          fontSize: "1rem",
          lineHeight: "1.4em",
          borderRadius: "0.5rem",
          backgroundColor: "#2E364A",
          maxWidth: "100%",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          color: "#FFFFFF",
        },
        padding: {
          padding: "0.5rem",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          borderRadius: "0.25rem",
          fontSize: "1rem",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: "0.5rem",
          backgroundColor: "#2E364A",
          backgroundImage: "none",
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          "&:after": {
            content: "none",
          },

          "&:before": {
            content: "none",
          },
        },
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {
          color: theme.palette.primary.contrastText,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        clearIndicator: {
          fontSize: "1em !important",
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          display: "flex",
          minHeight: 0,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          maxWidth: "100%",
          minWidth: "fit-content",
          flex: "0 0 auto",
          minHeight: 0,
          padding: "0.25rem 1rem",
          fontWeight: "inherit",
          fontSize: "inherit",
          textTransform: "none",
          lineHeight: "1.6em",
          zIndex: 1,
          color: theme.palette.text.secondary,

          "&.Mui-selected": {
            color: theme.palette.text.onAccent,
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          padding: "1rem",
          maxWidth: "30vw",
          fontSize: "1rem",
          lineHeight: "1.25rem",
          borderRadius: "1rem",
          background: theme.palette.complementary.grayBlue,
          color: theme.palette.main.gray,
          whiteSpace: "break-spaces",
          fontWeight: "400",
        },
      },
    },
  },
});

export default theme;
