import {
  olapRequestData2,
  olapRequestData3,
  olapRequestData4,
  olapRequestData5,
} from "CORE/api/core";
import {
  MODEL_UUID,
  PROGRAMS_MODEL_UUID,
  SECTION_NAMES,
} from "CORE/scheme/olap";
import { getGpIndicators } from "SRC/constants/dataCodes";
import { IPeriod } from "SRC/redux/slices/global/slices/filters";
import { IParams } from "SRC/redux/slices/gosprogram/slices/events";
import { IGpStructureParams } from "SRC/redux/slices/gosprogram/slices/gpStructure";
import { IGoalsParams } from "SRC/redux/slices/gosprogram/slices/programGoals";
import { IEventParams } from "SRC/types";

const GET_PROGRAM = "get-program";
const GET_PROGRAM_GOALS = "get-program-goals";
const GET_PROGRAM_EVENTS = "get-program-events";
const GET_PROGRAM_INDICATORS = "get-program-indicators";
const GET_STATISTICS = "get-statistics";
const GET_RESULT_CHARACTERISTIC = "get-result-characteristic";
const GET_GOAL_CONNECT = "get-goal-connect";
const GET_TAB_IMPL_LVL = "get-tab-impl-lvl";
const GET_GP_STRUCTURE = "get-gp-structure";
const GET_REPORTS = "get-reports";

export const getProgram = (period: IPeriod, gp_code: string) => {
  return olapRequestData4(
    [
      {
        requestId: "29968ac9-ab0e-468b-b55c-aebb90030864",
        type: "data2",
        params: {
          modelUuid: PROGRAMS_MODEL_UUID,
          dashboardUuid: "",
          includeGaps: false,
          dimensions: [
            {
              id: "1562",
              includeItems: true,
              includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
            },
            {
              id: "1565",
              includeItems: true,
              includeAttributesByCodes: [
                "NAME",
                "SHORT_NAME",
                "SORT_ORDER",
                "gp_short_name",
                "Вице-премьер",
                "gp_name",
                "Министерство",
              ],
            },
          ],
          indicators: {
            id: "1571",
            items: getGpIndicators(
              1578,
              1579,
              1580,
              1584,
              1585,
              1586,
              1679,
              1680,
              1590,
              1753,
              1754,
              1755,
              1756,
              1757,
              1769,
              1796,
              5265
            ),
          },
          dataRequestCaching: true,
          dataFilter: {
            type: "AND",
            version: 1,
            operands: [
              {
                type: "EQ",
                version: 1,
                operands: [
                  {
                    type: "DIM",
                    version: 1,
                    id: "1562",
                  },
                  {
                    type: "CONST",
                    version: 1,
                    values: [period.apiV2],
                  },
                ],
              },
              {
                type: "EQ",
                version: 1,
                operands: [
                  {
                    type: "DIM",
                    version: 2,
                    id: "1565",
                    attributeCode: "gp_code",
                  },
                  {
                    type: "TEXT",
                    version: 2,
                    values: [gp_code],
                  },
                ],
              },
            ],
          },
        },
      },
    ],
    GET_PROGRAM
  );
};

export const getProgramGoals = (params: IGoalsParams) => {
  return olapRequestData2(
    {
      dimensions: [],
      indicators: {},
      section_name: SECTION_NAMES.PROGRAMGOALS,
      modelUuid: MODEL_UUID,
      filters: {
        period_code: params.period_code,
        gp_code: params.gp_code,
      },
    },
    GET_PROGRAM_GOALS
  );
};

export const getProgramEvents = (params: IParams) => {
  return olapRequestData2(
    {
      dimensions: [],
      indicators: {},
      section_name: SECTION_NAMES.PROGRAMEVENTS,
      modelUuid: MODEL_UUID,
      filters: {
        period_code: params.period_code || null,
        gp_code: params.id,
        fp_code: params.fp_code,
        dp_code: params.dp_code,
        cpm_code: params.cpm_code,
      },
    },
    GET_PROGRAM_EVENTS
  );
};

export const getProgramIndicators = (params: IParams) => {
  return olapRequestData2(
    {
      dimensions: [],
      indicators: {},
      section_name: SECTION_NAMES.PROGRAM_INDICATORS,
      modelUuid: MODEL_UUID,
      filters: {
        period_code: params.period_code || null,
        gp_code: params.id,
        fp_code: params.fp_code,
        dp_code: params.dp_code,
        cpm_code: params.cpm_code,
      },
    },
    GET_PROGRAM_INDICATORS
  );
};

export const getStatistics = ({ id, period }: IEventParams) => {
  return olapRequestData2(
    {
      dimensions: [],
      indicators: {},
      section_name: SECTION_NAMES.STATS,
      modelUuid: MODEL_UUID,
      filters: {
        period_code: period.filterValue,
        gp_code: id,
      },
    },
    GET_STATISTICS
  );
};

export const getGpStructure = ({ period, gp_code }: IGpStructureParams) => {
  return olapRequestData3(
    [
      {
        requestId: "6980e6bf-d37b-4f0f-80ef-ed6f913f1390",
        type: "data2",
        params: {
          modelUuid: "2c254539-3926-46df-86c8-70768c7465f8",
          dashboardUuid: "",
          includeGaps: false,
          dimensions: [
            {
              id: "5379",
              includeItems: true,
              includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
            },
            {
              id: "5380",
              includeItems: true,
              includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
            },
            {
              id: "5381",
              includeItems: true,
              includeAttributesByCodes: [
                "NAME",
                "SHORT_NAME",
                "SORT_ORDER",
                "SE_TYPE",
              ],
            },
          ],
          indicators: {
            id: "5383",
            items: [
              {
                id: "5384",
                aggregationFunction: "sum",
              },
              {
                id: "5385",
                aggregationFunction: "sum",
              },
              {
                id: "5386",
                aggregationFunction: "sum",
              },
              //Участвует в УД ФОИВ
              {
                id: "5523",
                aggregationFunction: "sum",
              },
              //Участвует в УД ОМ
              {
                id: "5524",
                aggregationFunction: "sum",
              },
            ],
          },
          dataRequestCaching: true,
          dataFilter: {
            type: "AND",
            version: 1,
            operands: [
              {
                type: "STARTER",
                version: 1,
                dim_id: "5381",
              },
              {
                type: "AND",
                version: 1,
                operands: [
                  {
                    type: "EQ",
                    version: 1,
                    operands: [
                      {
                        type: "DIM",
                        version: 1,
                        id: "5379",
                      },
                      {
                        type: "CONST",
                        version: 1,
                        values: [period.apiV2],
                      },
                    ],
                  },
                  {
                    type: "EQ",
                    version: 1,
                    operands: [
                      {
                        type: "DIM",
                        version: 2,
                        id: "5380",
                        attributeCode: "gp_code",
                      },
                      {
                        type: "TEXT",
                        version: 2,
                        values: [gp_code],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        },
      },
    ],
    GET_GP_STRUCTURE
  );
};

export const getResultCharacteristic = (params: IEventParams) => {
  return olapRequestData3(
    [
      {
        requestId: "4dcc7514-b4c3-4015-87c0-bc6bdd0c1fd3",
        type: "dimension_data",
        params: {
          modelUuid: "f0c00f6b-d2fa-4a9a-9f57-258a0e88806d",
          dimensionId: "1344",
          includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
          dataFilter: {
            type: "EQ",
            version: 1,
            operands: [
              {
                type: "DIM",
                version: 1,
                id: "1344",
              },
              {
                type: "CONST",
                version: 1,
                values: [params.period],
              },
            ],
          },
        },
      },
      {
        requestId: "a80a0421-1fb0-49e4-90e8-a27c9346eeb5",
        type: "dimension_data",
        params: {
          modelUuid: "f0c00f6b-d2fa-4a9a-9f57-258a0e88806d",
          dimensionId: "1348",
          includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
          dataFilter: {
            type: "EQ",
            version: 1,
            operands: [
              {
                type: "DIM",
                version: 1,
                id: "1348",
              },
              {
                type: "CONST",
                version: 1,
                values: ["10103"],
              },
            ],
          },
        },
      },
    ],
    GET_RESULT_CHARACTERISTIC
  );
};

export const getGoalConnect = (gp_code: string | number) => {
  return olapRequestData3(
    [
      {
        requestId: "cd9f0aca-4c83-415d-863a-c14bccc1de94",
        type: "data2",
        params: {
          modelUuid: MODEL_UUID,
          dimensions: [],
          indicators: {},
          section_name: "2.0.3 Связь показателей ГП и НЦ",
          dataRequestCaching: true,
          filters: { gp_code },
        },
      },
    ],
    GET_GOAL_CONNECT
  );
};

export const getTabImplLvl = (
  gp_code: string | number,
  tab_name: string,
  period_code: string,
  is_om: boolean
) => {
  return olapRequestData5(
    [
      {
        requestId: "8fceab2f-a982-4d27-aee4-a7ebb2545568",
        type: "data2",
        params: {
          modelUuid: MODEL_UUID,
          dimensions: [],
          indicators: {},
          section_name: "Выгрузка подложек",
          filters: {
            gp_code,
            tab_name,
            period_code,
            is_om,
          },
        },
      },
    ],
    GET_TAB_IMPL_LVL
  );
};

export const getReports = (
  tab_name: string,
  period_code: string,
  is_om: boolean
) => {
  return olapRequestData5(
    [
      {
        requestId: "8fceab2f-a982-4d27-aee4-a7ebb2545568",
        type: "data2",
        params: {
          modelUuid: MODEL_UUID,
          dimensions: [],
          indicators: {},
          section_name: "Выгрузка отчетов",
          filters: {
            tab_name,
            period_code,
            is_om,
          },
        },
      },
    ],
    GET_REPORTS
  );
};
