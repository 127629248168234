import { Stack, useTheme } from "@mui/material";
import React from "react";
import { OverlayBlock } from "SRC/components/OverlayBlock";
import { IValue, WideProgress } from "SRC/components/ProgressBar";
import { useIndicatorData } from "SRC/redux/slices/indicator/hooks/useIndicatorData";

import { css } from "./ValueTab.styled";

export const ValueTab: React.FC = () => {
  const theme = useTheme();
  const { items: data, fetching: loading } = useIndicatorData();

  const PlanV1: IValue = {
    value: data?.data[5243].sum || 0,
    name: "План на Январь 2022г.",
  };

  const PlanV2: IValue = {
    value: data?.data[5240].sum || 0,
    name: "План",
  };

  const FactV1: IValue = {
    value: data?.data[5241].sum || 0,
    name: "Факт",
    color: theme.palette.charts.red,
    legend: "Не совпадает с планом",
  };

  const FactV2: IValue = {
    value: data?.data[5240].sum || 0,
    name: "Прогноз",
    color: theme.palette.charts.green,
    legend: "Совпадает с планом",
  };

  return (
    <OverlayBlock isFetching={loading} hasData={Boolean(data)}>
      <Stack sx={css.container}>
        <WideProgress title="Плановое значение" values={[PlanV1, PlanV2]} />
        <WideProgress
          title="Факт и прогноз"
          values={[FactV1, FactV2]}
          color={theme.palette.charts.blueArea}
          hasLegend
        />
      </Stack>
    </OverlayBlock>
  );
};
