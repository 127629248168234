import { Button } from "@mui/material";

interface ICreateIncidentBtnProps {
  handleIncidentModalOpen: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
}

export const CreateIncidentBtn = ({
  handleIncidentModalOpen,
}: ICreateIncidentBtnProps) => {
  return (
    <Button
      className="incident-btn"
      onClick={(e) => {
        handleIncidentModalOpen(e);
      }}
    >
      +
    </Button>
  );
};
