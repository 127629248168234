import { Theme } from "@mui/material";

export const css = {
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    flex: 1,
    height: "21rem",
    // maxHeight: "340px",
    padding: "1.5rem",
  },
  head: {
    display: "flex",
    flex: "0 1 auto",
    justifyContent: "space-between",
  },
  chart: {
    flex: 1,
    minHeight: "0",
    height: "100%",
  },
  title: {
    fontSize: "1.4rem",
    lineHeight: "1em",
  },
  legend: {
    display: "flex",
    flex: "0 0 auto",
    gap: "2rem",
    alignItems: "center",
    fontSize: "1rem",
    color: (theme: Theme) => theme.palette.main.gray,
  },
  legendItem: {
    display: "flex",
    gap: "0.5rem",
    alignItems: "baseline",

    "& p.white": {
      color: (theme: Theme) => theme.palette.text.onAccent,
    },
    "&.center": {
      alignItems: "center",
    },
  },
  styledPoint: {
    width: "0.7rem",
    height: "0.7rem",
    borderRadius: "50%",

    "&.green": {
      background: (theme: Theme) => theme.palette.success.main,
    },
    "&.red": {
      background: (theme: Theme) => theme.palette.error.main,
    },
    "&.blue": {
      background: (theme: Theme) => theme.palette.main.blue,
    },
    "&.white": {
      background: (theme: Theme) => theme.palette.primary.contrastText,
    },
  },
};
