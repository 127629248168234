import axios from "axios";
import { setAuthToken } from "CORE/api/testCore";

import { checkResponseStatus } from "./interceptors";

const instance = axios.create({
  withCredentials: true,
  baseURL: "./",
});

instance.interceptors.response.use(checkResponseStatus);

export const setAuthHeader = (access_token: string) => {
  instance.defaults.headers.Authorization = `Bearer ${access_token}`;
  setAuthToken(access_token);
};

export default instance;
