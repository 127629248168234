import { Box, SxProps, Tooltip } from "@mui/material";
import React from "react";
import { statusIcons } from "SRC/constants/globals";
import { statusesConverter, TStatuses } from "SRC/types";

import { css } from "./TableStatusCardNew.styled";

interface IProps {
  status: string;
  amount: number;
  onClick?(): void;
  selected: boolean;
  disabled?: boolean;
}

const TableStatusCardNew = ({
  disabled,
  status,
  amount,
  onClick,
  selected,
}: IProps) => {
  let title = "";
  if (status === "Выполнено") {
    title = "Уровень достижения 100%";
  } else if (status === "Не выполнено" || status === "Не достигнуто") {
    title = "Уровень достижения ниже 100%";
  } else if (status === "Не начато") {
    title = "Не участвует в УД";
  }

  return (
    <Tooltip title={title}>
      <Box
        onClick={!disabled ? onClick : undefined}
        sx={css.cardContainer(selected, disabled) as SxProps}
      >
        <Box sx={css.statusIcon as SxProps}>{statusIcons[status]}</Box>
        <Box sx={css.statusText as SxProps}>
          {statusesConverter[status as TStatuses]}
        </Box>
        <Box sx={css.statusAmount}>{amount}</Box>
      </Box>
    </Tooltip>
  );
};

export default TableStatusCardNew;
