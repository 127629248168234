import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Breadcrumbs, Grid } from "@mui/material";
import { AgnosticRouteMatch } from "@remix-run/router/dist/utils";
import * as React from "react";
import { useMemo } from "react";
import { matchRoutes, useLocation } from "react-router-dom";
import { BreadcrumbItem } from "SRC/components/Breadcrumbs/BreadcrumbItem/BreadcrumbItem";
import { ILink } from "SRC/types/breadcrumbs";
import { IRoute, MAIN, routeList } from "SRC/urls";

import { css } from "./Breadcrumbs.styled";

interface ICrumbsProps {
  links: ILink[];
}

const Crumbs = ({ links }: ICrumbsProps) => {
  const location = useLocation();
  const match: AgnosticRouteMatch<string, IRoute>[] | null = matchRoutes(
    routeList,
    location.pathname
  );

  const breadcrumbs = useMemo(
    () => [{ link: MAIN, title: "Главная" }, ...links].filter(Boolean),
    [links]
  );

  if (!match?.length || match[0].route.nav) {
    return null;
  }

  return (
    <Grid container sx={css.container}>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="medium" />}
        aria-label="breadcrumb"
      >
        {breadcrumbs.map(({ link, title }) => (
          <BreadcrumbItem link={link} title={title} key={title + link} />
        ))}
      </Breadcrumbs>
    </Grid>
  );
};

export default Crumbs;
