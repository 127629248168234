import { Tooltip } from "@mui/material";
import React from "react";
import { CheckSecretData } from "SRC/components/CheckSecretData";
import { TableCell } from "SRC/components/TableCell";
import { sortEventsPlanColumn } from "SRC/helpers";
import { ICustomTableProps } from "SRC/types";

import { Value } from "./components/Value";
import { getEventsPlan, getEventsPlanTotal } from "./utils";

export const eventsPlan = (isOM: boolean) => ({
  Header: "План",
  sortType: sortEventsPlanColumn(isOM),
  width: 130,
  accessor: isOM ? "eventsOMPlan" : "eventsPlan",
  Cell: (tableProps: ICustomTableProps) => {
    const { eventsPlan, isSecret } = getEventsPlan(tableProps, isOM);

    return (
      <TableCell color={isOM ? "blue" : "white"}>
        <CheckSecretData isSecret={isSecret}>
          <Value value={eventsPlan} />
        </CheckSecretData>
      </TableCell>
    );
  },
  Footer: (tableProps: ICustomTableProps) => {
    const { eventsPlanTotal } = getEventsPlanTotal(tableProps, isOM);

    return (
      <Tooltip title="В расчете участвуют уникальные элементы">
        <TableCell color={isOM ? "blue" : "white"}>{eventsPlanTotal}</TableCell>
      </Tooltip>
    );
  },
});
