import { combineReducers } from "@reduxjs/toolkit";
import structureFiltersSlice, {
  IFiltersState,
} from "SRC/redux/slices/structure/slices/filters";

export interface IStructureReducer {
  filters: IFiltersState;
}

const reducer = combineReducers<IStructureReducer>({
  filters: structureFiltersSlice.reducer,
});

export default reducer;
