import { Box } from "@mui/material";
import { CSSProperties } from "@mui/styles";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { Column } from "react-table";
import { CardsAnalitic } from "SRC/components/CardsAnalitic/CardsAnalitic";
import { IncidentReport } from "SRC/components/IncidentReport";
import { OverlayBlock } from "SRC/components/OverlayBlock";
import Table from "SRC/components/Table";
import { RightTitle } from "SRC/pages/Main/common/Analitics/Analitics";
import { ProgramList } from "SRC/pages/Main/common/Analitics/ProgramList/ProgramList";
import { getNationalGoalsColumns } from "SRC/pages/Main/common/NationalGoalsTable/tableData";
import {
  IGlobalFilters,
  useGlobalFilters,
} from "SRC/redux/slices/global/hooks/useFilters";
import { IFilters, useFilters } from "SRC/redux/slices/main/hooks/useFilters";
import { useGoals } from "SRC/redux/slices/main/hooks/useGoals";
import { usePrograms } from "SRC/redux/slices/main/hooks/usePrograms";
import { PROGRAMS } from "SRC/urls";

import { INationalGoalsDataItem } from "./interfaces";
import { css, StackWrapper } from "./NationalGoalsTable.styled";

export const NationalGoalsTable = () => {
  const { items, load } = useGoals();
  const { period, calendarDate }: IGlobalFilters = useGlobalFilters();
  const { goal, setSelectedGoal, filteredGoals }: IFilters = useFilters();
  const [hasChanges, setHasChanges] = useState<boolean | undefined>();
  useEffect(() => {
    setHasChanges(!moment(period.value).isSame(calendarDate));
  }, [period, calendarDate]);

  useEffect(() => {
    if (hasChanges) {
      load(period);
    }
  }, [period, calendarDate]);

  const columns: Column[] = useMemo(() => getNationalGoalsColumns(), []);

  const data = useMemo<INationalGoalsDataItem[]>(
    () =>
      items
        .filter((goal) => filteredGoals.includes(goal.NT_CODE))
        .map((goal, index) => ({
          id: goal.NT_CODE,
          icon: require(`ASSETS/icons/nationals/icon${index + 1}.gif`).default,
          goalTitle: goal.NAME,
          performance: goal.data["Уровень достижения"],
          numOfPrograms: goal.data["Количество ГП"],
        })),
    [items, filteredGoals]
  );

  const { fetching, fetched } = usePrograms();

  return (
    <StackWrapper>
      <Box className="programList">
        <CardsAnalitic
          headerLeft="Список госпрограмм"
          headerRight={
            fetched && (
              <RightTitle isDetail isTitle isActiveButton link={PROGRAMS} />
            )
          }
          style={css.programListContainer as CSSProperties}
        >
          <OverlayBlock isFetching={fetching} hasData={Boolean(items.length)}>
            <ProgramList />
          </OverlayBlock>
        </CardsAnalitic>
      </Box>
      <IncidentReport className="goals-table">
        <Box>
          <Table
            columns={columns}
            data={data}
            sortedColumns={[
              "Уровень достижения Национальной цели",
              "Количество Госпрограмм",
            ]}
            selectedRow={goal}
            setSelectedRow={setSelectedGoal}
            classes={{ table: "ng-table" }}
            tableHeight="22rem"
            scrollHide={true}
          />
        </Box>
      </IncidentReport>
    </StackWrapper>
  );
};
