import { Button } from "@mui/material";
import { OpenInFull } from "ASSETS/svg";
import React from "react";

import { css } from "./Buttons.styles";

interface IOpenButton {
  open?: boolean;
  setOpen?(): void;
}
export const OpenerButton = ({ open, setOpen }: IOpenButton) => (
  <Button sx={css.button(open)} onClick={setOpen}>
    <OpenInFull />
  </Button>
);
