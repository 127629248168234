import { olapDimensionRequestData } from "CORE/api/core";
import { PROGRAMS_MODEL_UUID } from "CORE/scheme/olap";
import { getGpIndicators } from "SRC/constants";
import { IPeriod } from "SRC/redux/slices/global/slices/filters";

export const GET_PROGRAMS = "get-programs";

export const getRequest = (period: IPeriod["apiV2"]) => ({
  requestId: "29968ac9-ab0e-468b-b55c-aebb90030864",
  type: "data2",
  params: {
    modelUuid: PROGRAMS_MODEL_UUID,
    dashboardUuid: "",
    includeGaps: false,
    dimensions: [
      {
        id: "1562",
        includeItems: true,
        includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
      },
      {
        id: "1565",
        includeItems: true,
        includeAttributesByCodes: [
          "NAME",
          "SHORT_NAME",
          "SORT_ORDER",
          "gp_short_name",
          "Вице-премьер",
          "gp_name",
          "Министерство",
        ],
      },
    ],
    indicators: {
      id: "1571",
      items: getGpIndicators(
        1578,
        1579,
        1580,
        1581,
        1582,
        1584,
        1585,
        1586,
        1587,
        1588,
        1589,
        1679,
        1680,
        1590,
        1753,
        1754,
        1755,
        1756,
        1757,
        1769,
        1796,
        5246,
        5265,
        5269,
        5306,
        5283,
        5284,
        5286,
        5287,
        5289,
        5290,
        5291,
        5292,
        5293,
        5294
      ),
    },
    dataRequestCaching: true,
    dataSort: [
      {
        dim_id: "1562",
        sortField: "name",
        sortOrder: "asc",
      },
      {
        dim_id: "1565",
        sortField: "name",
        sortOrder: "asc",
      },
    ],
    dataFilter: {
      type: "AND",
      version: 1,
      operands: [
        {
          type: "STARTER",
          version: 1,
          dim_id: "1565",
        },
        {
          type: "EQ",
          version: 1,
          operands: [
            {
              type: "DIM",
              version: 1,
              id: "1562",
            },
            {
              type: "CONST",
              version: 1,
              values: [period],
            },
          ],
        },
      ],
    },
  },
});

export const getPrograms = (period: IPeriod) => {
  return olapDimensionRequestData([getRequest(period.apiV2)], GET_PROGRAMS);
};
