import { ICustomTableProps } from "SRC/types";

export const getCheckpointsPlanTotal = (
  tableProps: ICustomTableProps,
  isOM: boolean
) => {
  let { checkpointsPlanTotal, checkpointsOMPlanTotal } =
    tableProps?.rows?.[0]?.original || {};

  return {
    checkpointsPlanTotal: isOM ? checkpointsOMPlanTotal : checkpointsPlanTotal,
  };
};
