import { styled } from "@mui/material";

export const Wrapper = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "0.25rem",
  marginLeft: "auto",
  padding: "0rem 0.5rem",
  maxWidth: "3.5rem",
  backgroundColor: theme.palette.complementary.whiteTransparent,
  borderRadius: "1rem",
}));
