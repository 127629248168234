import { InputLabel } from "@mui/material";
import React, { useMemo } from "react";
import { IOption } from "SRC/components/SelectWithSearch/SelectWithSearch";
import SelectWithSearchGP from "SRC/components/SelectWithSearch/SelectWithSearchGP";
import { useFilters } from "SRC/redux/slices/priorities/hooks/useFilters";
import { usePrograms } from "SRC/redux/slices/priorities/hooks/usePrograms";

import { Wrapper } from "./IncidentGP.styled";

interface IIncidentGPProps {
  selectStyle: any;
}

export const IncidentGP = ({ selectStyle }: IIncidentGPProps) => {
  const { setSelectedProgram, program } = useFilters();
  const { items: programs } = usePrograms();
  const optionsGP: IOption[] = useMemo(
    () =>
      programs.map((program) => {
        return {
          value: program.info.name,
          label: program.info.shortName,
        };
      }),
    [programs]
  );
  return (
    <Wrapper>
      <InputLabel>Государственная программа</InputLabel>
      <SelectWithSearchGP
        value={program}
        label="Выберите государственную программу"
        staticLabel={false}
        onChange={setSelectedProgram}
        options={optionsGP}
        style={selectStyle}
      />
    </Wrapper>
  );
};
