import { actions as ministries } from "./slices/ministries";
import { actions as premiers } from "./slices/premiers";
import { actions as programs } from "./slices/programs";

export interface IPrioritiesActions {
  ministries: typeof ministries;
  programs: typeof programs;
  premiers: typeof premiers;
}

const actions: IPrioritiesActions = {
  programs,
  premiers,
  ministries,
};

export default actions;
