import { Button, SxProps } from "@mui/material";
import React from "react";
import { css } from "SRC/pages/Structure/common/StructureTabs/EventsTab/EventsTab.styled";

interface IFilterButtonReset {
  handleReset: () => void;
}

export const FilterButtonReset = ({ handleReset }: IFilterButtonReset) => {
  return (
    <Button sx={css.filterButton as SxProps} onClick={handleReset}>
      Сбросить фильтры
    </Button>
  );
};
