import CropOriginalIcon from "@mui/icons-material/CropOriginal";
import { Box, Button, Link, useTheme } from "@mui/material";
import { Close } from "ASSETS/svg/close";
import React, { useMemo, useRef, useState } from "react";
import { css } from "SRC/components/FilterForms/MainHeaderFilter/MainHeaderFilter.styled";
import { TooltipText } from "SRC/components/TooltipText";
import themeConfig from "SRC/theme";

import { IncidentDescription } from "../IncidentDescription/IncidentDescription";
import { IncidentFileDefault } from "../IncidentFileDefault/IncidentFileDefault";
import { IncidentFileList } from "../IncidentFileList/IncidentFileList";
import { IncidentGP } from "../IncidentGP/IncidentGP";
import { IncidentLevel } from "../IncidentLevel/IncidentLevel";
import { IncidentLink } from "../IncidentLink/IncidentLink";
import { IncidentPeriod } from "../IncidentPeriod/IncidentPeriod";
import { IncidentType } from "../IncidentType/IncidentType";
import { ModalWrapper } from "./IncidentModal.styled";

interface IIncidentModalProps {
  handleIncidentModalClose: () => void;
  handleScreenshot: () => void;
}

export const IncidentModal = ({
  handleIncidentModalClose,
  handleScreenshot,
}: IIncidentModalProps) => {
  const theme = useTheme<typeof themeConfig>();
  const selectStyle = useMemo(() => css.selector(theme), [theme]);

  const [incidentLevel, setIncidentLevel] = useState<string>("");
  const [incidentType, setIncidentType] = useState<string>("");
  const [fileNames, setFileNames] = useState<any>([]);
  const [descriptionValue, setDescriptionValue] = useState("");
  const [id, setId] = useState<number>(0);

  const uploadInputRef = useRef<HTMLInputElement | null>(null);

  const onChangeFiles = (e: any) => {
    setId((prev) => ++prev);
    setFileNames((prev: any) => [
      ...prev,
      { id: id, label: e.target.files[0].name },
    ]);
  };

  const onChangeSelectedIncidentLevel = (value: string) => {
    setIncidentLevel(value);
  };

  const onChangeSelectedIncidentType = (value: string) => {
    setIncidentType(value);
  };

  const onChangeDescriptionValue = (e: any) => {
    setDescriptionValue(e.target.value);
  };

  const handleDeleteFile = (id: number) => {
    const resultFileNames = fileNames.filter((file: any) => file.id !== id);
    setFileNames(resultFileNames);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  const handleReset = () => {
    setIncidentLevel("");
    setIncidentType("");
    setDescriptionValue("");
    setFileNames([]);
  };

  return (
    <ModalWrapper>
      <div className="modal-header">
        <div className="title">Создать инцидент</div>
        <div
          className="modal-header header-icons"
          onClick={handleIncidentModalClose}
        >
          <TooltipText arrow hint="Скриншот элемента" variant="h2">
            <CropOriginalIcon
              className="icon-crop"
              onClick={handleScreenshot}
            />
          </TooltipText>
          <Close className="icon-close" />
        </div>
      </div>
      <Box
        className="container"
        onSubmit={handleSubmit}
        component="form"
        autoComplete="off"
      >
        <div className="columns">
          <div className="column">
            <IncidentLevel
              incidentLevel={incidentLevel}
              selectStyle={selectStyle}
              onChangeSelectedIncidentLevel={onChangeSelectedIncidentLevel}
            />
            <IncidentPeriod />
          </div>
          <div className="column">
            <IncidentType
              incidentType={incidentType}
              selectStyle={selectStyle}
              onChangeSelectedIncidentType={onChangeSelectedIncidentType}
            />
            <IncidentGP selectStyle={selectStyle} />
          </div>
        </div>
        <IncidentLink />
        <IncidentDescription
          descriptionValue={descriptionValue}
          onChangeDescriptionValue={onChangeDescriptionValue}
        />
        {fileNames.length === 0 && (
          <IncidentFileDefault
            uploadInputRef={uploadInputRef}
            onChangeFiles={onChangeFiles}
          />
        )}
        {fileNames.length > 0 && (
          <IncidentFileList
            handleDeleteFile={handleDeleteFile}
            fileNames={fileNames}
            uploadInputRef={uploadInputRef}
            onChangeFiles={onChangeFiles}
          />
        )}
        <div className="modal-footer">
          <Link
            href="https://gasu-office.roskazna.ru/pages/cabinet"
            underline="always"
            color="inherit"
          >
            Информация об инцидентах
          </Link>
          <div className="footer-buttons">
            <Button variant="contained" color="inherit" onClick={handleReset}>
              Отменить
            </Button>
            <Button variant="contained" type="submit">
              Направить
            </Button>
          </div>
        </div>
      </Box>
    </ModalWrapper>
  );
};
