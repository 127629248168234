import "./index.scss";
import "moment/locale/ru";

import { ThemeProvider } from "@mui/material";
import moment from "moment";
import React from "react";
import { createRoot } from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import { HashRouter } from "react-router-dom";

import App from "./pages/App";
import store from "./redux/store";
import theme from "./theme";

moment.locale("ru");

// eslint-disable-next-line no-console
console.log(`v${process.env.REACT_APP_VERSION}`);

const queryClient = new QueryClient();

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <HashRouter>
          <QueryClientProvider client={queryClient}>
            <App />
          </QueryClientProvider>
        </HashRouter>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
);
