import { SxProps } from "@mui/material";
import React, { FC, useState } from "react";
import { ExtendedTabs, ITab } from "SRC/components/ExtendedTabs/ExtendedTabs";
import { CashTab } from "SRC/pages/Event/common/EventTabs/CashTab/CashTab";
import { CharacterTab } from "SRC/pages/Event/common/EventTabs/CharacterTab/CharacterTab";
import { ObjectTabWrapper } from "SRC/pages/Event/common/EventTabs/ObjectTab/ObjectTabWrapper";
import { RelatedIndicators } from "SRC/pages/Event/common/EventTabs/RelatedIndicators/RelatedIndicators";
import { relatedIndicatorsColumns } from "SRC/pages/Event/common/EventTabs/RelatedIndicators/util/relatedIndicatorsTableData";
import { SubjectTab } from "SRC/pages/Event/common/EventTabs/SubjectTab/SubjectTab";
import { subjectColumns } from "SRC/pages/Event/common/EventTabs/SubjectTab/util/subjectTableData";

import { css } from "./EventTabs.styled";

export const EventTabs: FC = () => {
  const [activeTab, setActiveTab] = useState<number>(0);

  const tabs: ITab<number>[] = [
    {
      value: 0,
      label: "Кассовое исполнение",
      component: <CashTab />,
    },
    {
      value: 1,
      label: "Характеристика результата",
      component: <CharacterTab />,
    },
    {
      value: 2,
      label: "Реализация в субъектах",
      component: <SubjectTab columns={subjectColumns} />,
    },
    {
      value: 3,
      label: "Перечень объектов",
      component: <ObjectTabWrapper />,
    },
    {
      value: 4,
      label: "Связанные показатели",
      // @ts-ignore
      component: <RelatedIndicators columns={relatedIndicatorsColumns} />,
      wip: true,
    },
  ];

  const onChangeTab = (value: number): void => setActiveTab(value);

  return (
    <ExtendedTabs
      value={activeTab}
      onChange={onChangeTab}
      tabs={tabs}
      stretch
      style={css.tabs as SxProps}
      borderStyle="solid"
    />
  );
};
