import { Button } from "@mui/material";
import React from "react";

import { Wrapper } from "./CheckpointsButtons.styled";

export const CheckpointsButtons = () => {
  return (
    <Wrapper>
      <Button>Контрольные точки</Button>
      <Button disabled>Причина отклонения</Button>
      <Button disabled>Корректировка значения/срока</Button>
    </Wrapper>
  );
};
