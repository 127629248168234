import { EWorkbookRow, TCellStyles, TConfig, txtColors } from "SRC/types";

export const rowStyles: Record<EWorkbookRow, TCellStyles> = {
  [EWorkbookRow.VP]: {
    bold: true,
    alignment: {
      horizontal: "left",
      vertical: "center",
      wrapText: true,
    },
  },
  [EWorkbookRow.DATA]: {},
  [EWorkbookRow.TOTAL]: {
    size: 16,
    bold: true,
  },
  [EWorkbookRow.COMMENT]: {
    fill: "yellow",
    size: 12,
    alignment: {
      horizontal: "left",
      vertical: "center",
      wrapText: true,
    },
  },
};

// стили для синей формы
export const globalStyles: TConfig = {
  title: {
    bold: true,
    color: "blue",
    size: 28,
    alignment: {
      horizontal: "center",
      vertical: "center",
      wrapText: true,
    },
  },
  cell: {
    size: 16,
    alignment: {
      horizontal: "center",
      vertical: "center",
      wrapText: true,
    },
    border: {
      top: { style: "medium", color: { rgb: txtColors.blue } },
      bottom: { style: "medium", color: { rgb: txtColors.blue } },
      left: { style: "medium", color: { rgb: txtColors.blue } },
      right: { style: "medium", color: { rgb: txtColors.blue } },
    },
  },
  header: {
    bold: true,
    color: "reverse",
    fill: "blue",
    size: 20,
    alignment: {
      horizontal: "center",
      vertical: "center",
      wrapText: true,
    },
    border: {
      top: { style: "medium", color: { rgb: txtColors.reverse } },
      bottom: { style: "medium", color: { rgb: txtColors.reverse } },
      left: { style: "medium", color: { rgb: txtColors.reverse } },
      right: { style: "medium", color: { rgb: txtColors.reverse } },
    },
  },
};
