import { styled } from "@mui/material";

type ProgressProps = {
  value: number;
  opinion: boolean;
};
export const Wrapper = styled("div")<ProgressProps>(
  ({ theme, value, opinion }) => {
    const {
      complementary: {
        darkBlue,
        darkBlueTransparent,
        darkGreenTransparent,
        linearDarkBlue2,
        whiteTransparent,
      },
      primary: { contrastText },
      pale: { paleGreen },
    } = theme.palette;

    const gradientBg = `linear-gradient(
      0deg,
      ${darkBlue} -20%,
      ${value < 100 ? whiteTransparent : darkGreenTransparent} ${value}%,
      ${darkBlueTransparent} ${value}%,
      ${darkBlueTransparent} 100%
    )`;
    const gradientBgOpinion = `linear-gradient(
      0deg,
      ${darkBlue} -20%,
      ${value < 100 ? whiteTransparent : `${paleGreen}99`} ${value}%,
      ${darkBlueTransparent} ${value}%,
      ${darkBlueTransparent} 100%
    )`;

    return {
      flex: "0 0 calc(4ch + 2rem)",
      height: "100%",
      display: "flex",
      alignItems: "center",
      borderLeft: `2px solid ${linearDarkBlue2}`,
      background: opinion ? gradientBgOpinion : gradientBg,
      backgroundClip: "padding-box",

      "& .tooltip-box": {
        margin: "0 auto",
      },

      "& .program-progress": {
        "::before": {
          content: "'%'",
          display: "block",
          fontSize: "0.875rem",
          textAlign: "center",
          lineHeight: "1rem",
          color: "#FFFFFF80",
        },
      },
    };
  }
);
