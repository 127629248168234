import { getFilterParams } from "SRC/store/api/planeta/utils";
import { TGetSpheresParams } from "SRC/store/spheres/types";

import { DIMENSIONS } from "./dimensions";
import { INDICATORS } from "./indicators";

const PROGRAMS_MODEL_UUID = "c116bb29-cc0d-4f3a-a050-392dd7f41db8";
const GET_SPHERES = "get-spheres";

export const getSpheresRequest = (params: TGetSpheresParams) => {
  const { period, foiv, vpCode } = params;
  const filterParams = getFilterParams([
    {
      id: "1565",
      code: "Министерство",
      value: foiv,
    },
    {
      id: "1565",
      code: "Вице-премьер, код",
      value: vpCode,
    },
  ]);

  return {
    url: `?${GET_SPHERES}`,
    method: "POST",
    body: {
      requests: [
        {
          requestId: "dbd3e35e-b26a-4ed3-8082-b802f8a91450",
          type: "data2",
          params: {
            modelUuid: PROGRAMS_MODEL_UUID,
            dashboardUuid: "",
            includeGaps: false,
            dimensions: DIMENSIONS,
            indicators: {
              id: "1571",
              items: INDICATORS,
            },
            dataRequestCaching: true,
            dataSort: [
              {
                dim_id: "1562",
                sortField: "name",
                sortOrder: "asc",
              },
              {
                dim_id: "1835",
                sortField: "name",
                sortOrder: "asc",
              },
            ],
            dataFilter: {
              type: "AND",
              version: 1,
              operands: [
                {
                  type: "STARTER",
                  version: 1,
                  dim_id: "1835",
                },
                {
                  type: "EQ",
                  version: 1,
                  operands: [
                    {
                      type: "DIM",
                      version: 1,
                      id: "1562",
                    },
                    {
                      type: "CONST",
                      version: 1,
                      values: period,
                    },
                  ],
                },
                ...filterParams,
              ],
            },
          },
        },
      ],
    },
  };
};
