import { useEffect, useMemo } from "react";
import { getInfoRowsSummaryMinistries } from "SRC/helpers/getInfoRows";
import { useTableExportParams } from "SRC/hooks";
import { getMinistriesData } from "SRC/hooks/summaryTable/utils/utils";
import { useGlobalFilters } from "SRC/redux/slices/global/hooks/useFilters";
import {
  IFilters as IMainFilters,
  useFilters,
  useFilters as useMainFilters,
} from "SRC/redux/slices/main/hooks/useFilters";
import { useMinistries as useMainMinistries } from "SRC/redux/slices/main/hooks/useMinistries";
import { usePrograms as useMainPrograms } from "SRC/redux/slices/main/hooks/usePrograms";
import {
  IFilters as ISummaryFilters,
  useFilters as useSummaryFilters,
} from "SRC/redux/slices/summary/hooks/useFilters";
import { useMinistries as useSummaryMinistries } from "SRC/redux/slices/summary/hooks/useMinistries";
import { usePrograms as useSummaryPrograms } from "SRC/redux/slices/summary/hooks/usePrograms";
import { IExportData } from "SRC/types";

import { IMinistriesExportData, IMinistriesTableItem } from "./interfaces";
import { createProgramsTableData as createProgramsMainTableData } from "./utils/mainData/mainDataGP";
import { createMinistriesTableData as createMinistriesMainTableData } from "./utils/mainData/mainDataMinistries";
import { createProgramsTableData } from "./utils/summaryData/summaryDataGP";
import { createMinistriesTableData } from "./utils/summaryData/summaryDataMinistries";

interface IUseTableData {
  ministriesSummaryData: IMinistriesTableItem[];
  ministriesSummaryExport: IExportData<Record<string, any>>;
  ministriesMainData: IMinistriesTableItem[];
  ministriesMainExport: IExportData<Record<string, any>>;
}

export const useTableData = (): IUseTableData => {
  const { vp } = useFilters();
  const { period, withSecret } = useGlobalFilters();
  const { selectedSearchRows: exportSummaryData }: ISummaryFilters =
    useSummaryFilters();
  const {
    selectedSearchRows: exportMainData,
    filteredMinistries,
  }: IMainFilters = useMainFilters();

  const { items: summaryPrograms } = useSummaryPrograms();
  const { items: mainPrograms } = useMainPrograms();
  const { items: summaryMinistries } = useSummaryMinistries();
  const { load: loadMainMinistries, items: mainMinistries } =
    useMainMinistries();
  const createExportParams = useTableExportParams;

  useEffect(() => {
    loadMainMinistries(period);
  }, [period.value]);

  const programsSummaryTableData = useMemo(
    () => createProgramsTableData(summaryPrograms),
    [summaryPrograms]
  );

  const programsMainTableData = useMemo(
    () => createProgramsMainTableData(mainPrograms),
    [mainPrograms]
  );

  const ministriesSummaryTableData = useMemo(() => {
    return createMinistriesTableData(summaryMinistries);
  }, [summaryMinistries]);

  const ministriesMainTableData = useMemo(
    () => createMinistriesMainTableData(mainMinistries),
    [mainMinistries]
  );

  const getMinistriesExport = (
    data: Record<string, any>[],
    tableName: string
  ) => {
    return {
      csvData: data,
      fileName: "Сводная таблица. Министерства",
      infoRows: getInfoRowsSummaryMinistries(period),
      ...createExportParams(data, tableName),
    };
  };

  const exportMinistriesSummaryData = useMemo(() => {
    return exportSummaryData.map((item) => {
      return {
        name: item.name,
        numOfPrograms: item.numOfPrograms,
        performance: item.performance,
        cashPlan: item.cashPlan,
        cashFact: item.cashFact,
        indicatorsPlan: item.indicatorsPlan,
        indicatorsFact: item.indicatorsFact,
        indicatorsFail: item.indicatorsFail,
        structureElementsPlan: item.structureElementsPlan,
        structureElementsFact: item.structureElementsFact,
        structureElementsFail: item.structureElementsFail,
        indicatorsSEPlan: item.indicatorsSEPlan,
        indicatorsSEFact: item.indicatorsSEFact,
        indicatorsSEFail: item.indicatorsSEFail,
        eventsPlan: item.eventsPlan,
        eventsFact: item.eventsFact,
        eventsFail: item.eventsFail,
        checkpointsPlan: item.checkpointsPlan,
        checkpointsFact: item.checkpointsFact,
        checkpointsFail: item.checkpointsFail,
      };
    });
  }, [exportSummaryData]);

  const ministriesSummaryData = useMemo(() => {
    return getMinistriesData(
      ministriesSummaryTableData,
      programsSummaryTableData,
      withSecret
    );
  }, [ministriesSummaryTableData, programsSummaryTableData, withSecret]);

  const ministriesMainData = useMemo(() => {
    return getMinistriesData(
      ministriesMainTableData,
      programsMainTableData,
      withSecret
    )
      .filter((ministry) => filteredMinistries.includes(ministry.id))
      .reduce((acc, { subRows, ...ministry }) => {
        const filteredSubRows = !vp
          ? subRows
          : subRows
              .filter((row) => row.vp === vp)
              .filter(({ secretProgram }) => withSecret || !secretProgram);

        if (filteredSubRows.length) {
          acc.push({ ...ministry, subRows: filteredSubRows });
        }

        return acc;
      }, [] as IMinistriesExportData[]);
  }, [
    filteredMinistries,
    ministriesMainTableData,
    programsMainTableData,
    vp,
    withSecret,
  ]);

  const ministriesSummaryExport = useMemo(() => {
    return getMinistriesExport(
      exportMinistriesSummaryData,
      "summaryMinistries"
    );
  }, [exportMinistriesSummaryData, getMinistriesExport]);

  const ministriesMainExport = useMemo(() => {
    const data = exportMainData.map(
      ({
        name,
        eventsPlan,
        eventsFact,
        indicatorsPlan,
        indicatorsFact,
        atRisk,
        numOfPrograms,
        performance,
      }) => ({
        ministryName: name,
        numOfPrograms,
        indicatorsPlan,
        indicatorsFact,
        plan: eventsPlan,
        fact: eventsFact,
        atRisk,
        performance,
      })
    );

    return getMinistriesExport(data, "ministries");
  }, [exportMainData, getMinistriesExport]);

  return {
    ministriesSummaryData,
    ministriesSummaryExport,
    ministriesMainData,
    ministriesMainExport,
  };
};
