import { Theme } from "@mui/material";
import programLargeBgr from "ASSETS/svg/backgrounds/programLargeBgr.png";

export const css = {
  centerContentWrapper: (theme: Theme) => ({
    [theme.breakpoints.down("lg")]: {
      "& .program-card, & .program-state": {
        maxWidth: theme.breakpoints.values.md,
        minWidth: "90rem",
        margin: "auto",
      },
    },
    [theme.breakpoints.up("lg")]: {
      display: "flex",
      flexDirection: "column",
    },
    [theme.breakpoints.up("xl")]: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      background: theme.palette.complementary.darkBlueTransparent,
      backgroundImage: `url(${programLargeBgr})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "contain",
      borderRadius: "3rem",

      "& .program-card": {
        flex: "0 0 auto",
        maxWidth: "40%",
        boxShadow: `40px 0px 60px -20px ${theme.palette.background.default}`,
      },

      "& .program-state": {
        flex: "0 1 100%",
        paddingLeft: "2rem",
        width: "100%",
      },
    },
  }),
  goalsContainer: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
  },
  goalsHeaderContainer: {
    display: "flex",
    padding: "1rem",
    alignItems: "center",
  },
  goalsCount: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "1rem 2rem",
    background: (theme: Theme) => theme.palette.background.default,
    borderRadius: "1rem",
    fontSize: "2rem",
    lineHeight: "1.8rem",
    color: (theme: Theme) => theme.palette.main.gray,
    fontWeight: "bold",
    marginRight: "1.5rem",
  },
  goalsTitle: {
    color: (theme: Theme) => theme.palette.primary.contrastText,
    fontSize: "1.5rem",
    lineHeight: "1.5rem",
    fontWeight: "bold",
  },
  isSecret: {
    display: "flex",
    width: "100%",
    height: "35rem",
    alignItems: "center",
    justifyContent: "center",
    color: (theme: Theme) => theme.palette.complementary.grayBlue,
  },
};
